/* eslint-disable max-len */
import React from 'react';


const AttentionRed = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 21L16 20.3333" stroke="#F15438" strokeWidth="2" strokeLinecap="round" />
        <path d="M16 17L16 10.6667" stroke="#F15438" strokeWidth="2" strokeLinecap="round" />
        <circle cx="15.9989" cy="15.9999" r="11.7879" stroke="#F15438" strokeWidth="2" />
    </svg>
);

export default AttentionRed;
