import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { App } from '@flowhealth/app';
import { BackArrow } from '@flowhealth/icons';
import cn from 'classnames';

import Message from 'components/Message';
import { Container, ActionIcon } from 'components/Container';

import Policy from 'containers/Policy';
import MetaData from 'containers/App/metaData';
import Header from 'containers/App/Header';
import { makeSelectRole, makeSelectUserData } from 'containers/User/selector';

import { ELECTRONIC_SIGNATURE_TERMS_PATH, USER_SETTINGS_PATH } from 'configuration/paths';

import { selectIsMobile } from './selectors';

const styles = ({ palette = {} } = {}) => ({
    electronicSignatureTerms: {
        marginRight: 15,
        justifyContent: 'start !important',
    },
    main: {
        display: 'flex',
        flex: '1 0 auto',
        backgroundColor: palette.white.main,
    },
    landing: {
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        paddingTop: 70,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fullWidth: { width: '100%' },
    app: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0',
        maxWidth: 'calc(100% - 264px)',
        transition: 'all 0.05s ease',
        zIndex: 1201,
        marginLeft: 264,

        '.c-mainMenu--hidden + &': {
            marginLeft: '64px',
            maxWidth: 'calc(100% - 58px)',
        },

        '&.without-main-menu': {
            maxWidth: 'unset',
            marginLeft: 'unset',
        },
    },
    content__inner: {
        flex: '1 0 auto',
    },
});


class BlockingRoute extends React.Component {
    get showLogo() {
        return !this.isAuthenticated;
    }

    get isAuthenticated() {
        const { userData } = this.props;
        const token = App.getInstance().getToken();
        return !!(token && userData);
    }

    render() {
        const { classes, ...other } = this.props;
        const { role, path, history } = other;

        const content = (
            <div className={cn(classes.landing, classes.classRolePatient, {
                [classes.electronicSignatureTerms]: path === ELECTRONIC_SIGNATURE_TERMS_PATH,
            })}
            >
                <Policy {...other} />
                <Message id="success" variant="success" vertical="top" horizontal="center" />
                <Message id="error" variant="error" vertical="top" horizontal="right" />
                <Message id="warning" variant="warning" vertical="top" horizontal="center" />
            </div>
        );

        switch (role) {
            case 'patient': return (
                <Container
                    leftAction={(
                        <ActionIcon
                            type={BackArrow}
                            onClick={() => history.push(USER_SETTINGS_PATH)}
                        />
                    )}
                >
                    <MetaData />
                    {content}
                </Container>
            );
            default: return (
                <div className={cn(classes.app, 'c-app')}>
                    <MetaData />
                    <main className={classes.main}>
                        <div className={cn(classes.fullWidth, 'without-main-menu')}>
                            <Header
                                showLogo={this.showLogo}
                                showNotification={this.isAuthenticated}
                            />
                            <div className={classes.content__inner}>
                                {content}
                            </div>
                        </div>
                    </main>
                </div>
            );
        }
    }
}


const mapStateToProps = createStructuredSelector({
    isMob: selectIsMobile,
    role: makeSelectRole(),
    userData: makeSelectUserData(),
});

const withConnect = connect(mapStateToProps);

export default compose(
    withStyles(styles),
    withRouter,
    withConnect,
)(BlockingRoute);
