export const ROLES = {
    SUPER_ADMIN_ROLE: 'super_admin_role',
    ADMIN_ROLE: 'admin_role',
    LAB_ROLE: 'lab_role',
    PRACTICIONER_ROLE: 'practitioner_role',
    STAFF_ROLE: 'staff_role',
    MARKETING_ROLE: 'marketing_role',
    PATIENT_ROLE: 'patient_role',
    COLLECTOR_ROLE: 'collector_role',
    CS_ROLE: 'cs_role',
    ACCESSION_ROLE: 'accession_role',
    BILLING_ROLE: 'billing_role',
    DEV_ROLE: 'dev_role',
};
