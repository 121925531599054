import { call } from 'redux-saga/effects';
import API from '@flowhealth/api';

import * as KEYS from './keys';

export const configuration = {
    [KEYS.USER_KEY]: {
        initialState: undefined,
        request: () => {},
        selectParams: () => {},
    },
    [KEYS.PATIENTS_KEY]: {
        initialState: undefined,
        request: () => {},
        selectParams: () => {},
    },
    [KEYS.PERSONS_KEY]: {
        initialState: undefined,
        request: () => {},
        selectParams: () => {},
    },
    [KEYS.LOCATIONS_KEY]: {
        initialState: undefined,
        request: function* (params) {
            return yield call(API.getInstance().search, params);
        },
        selectParams: () => ({ object: 'collection_location' }),
    }
};
