/* eslint-disable max-len */
import React from 'react';


const Logout = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M14.673 5.726l-.004.003a.995.995 0 0 0-.049 1.408l.957 1.021a.5.5 0 0 1-.365.842H9a1 1 0 1 0 0 2h6.197a.5.5 0 0 1 .361.845l-.928.974a.994.994 0 0 0 1.441 1.367l2.689-2.848a2 2 0 0 0-.007-2.753l-2.686-2.816a.993.993 0 0 0-1.394-.043zM1.778 0h6.445C9.2 0 10 .68 10 2.111v.896A.993.993 0 0 1 9.007 4h-.013A.994.994 0 0 1 8 3.007v-.014A.993.993 0 0 0 7.007 2H2.994A.994.994 0 0 0 2 2.993v14.014c0 .548.445.993.994.993h4.013A.993.993 0 0 0 8 17.007v-.014c0-.548.445-.993.994-.993h.013c.549 0 .993.445.993.993v1.118C10 19.293 9.153 20 8.223 20H1.778A1.778 1.778 0 0 1 0 18.222V1.778C0 .796.796 0 1.778 0z" clipRule="evenodd" />
    </svg>
);

export default Logout;
