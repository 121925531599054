export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_MENU_CLICKED_ON = 'SET_MENU_CLICKED_ON';

export const OPEN_LOADER = 'OPEN_LOADER';
export const CLOSE_LOADER = 'CLOSE_LOADER';

export const OPEN_SUCCESS = 'OPEN_SUCCESS';
export const CLOSE_SUCCESS = 'CLOSE_SUCCESS';

export const GET_LOGO_REQUEST = 'GET_LOGO_REQUEST';
export const GET_LOGO_SUCCESS = 'GET_LOGO_SUCCESS';
export const GET_LOGO_FAILURE = 'GET_LOGO_FAILURE';

export const LARGE_WINDOW_RESOLUTION = 1280;

export const MAILING_KIT_ID_LENGTH = 14;

export const SWITCH_MOBILE = 'SWITCH_MOBILE';
