import { set } from 'immutable';
import { createReducer, safeGet } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';


const initialState = {
    isLoading: false,
    batch: undefined,
    fields: undefined,
    kitId: undefined,
    imgSrc: undefined,
    file: undefined,
    testResult: undefined,
    patient: undefined,
    copyPatientId: undefined,
    action: undefined,
    error: null,
};

const resetState = () => ({ ...initialState });

const setIsLoading = value => state => set(state, 'isLoading', value);

const setPatient = (state, { payload = {} }) => set(state, 'patient', payload);
const setBatch = (state, { payload = {} }) => set(state, 'batch', payload?.batch);
const setFields = (state, { payload = {} }) => set(state, 'fields', payload?.fields);

const setKitId = (state, { payload = {} }) => set(state, 'kitId', payload?.kitId);
const setImgSrc = (state, { payload = {} }) => set(state, 'imgSrc', payload?.imgSrc);
const setFile = (state, { payload = {} }) => set(state, 'file', payload?.file);

const setTestResult = (state, { payload = {} } = {}) => set(
    state, 'testResult', safeGet(payload, 'testResult', payload),
);

const setCopyPatientId = (state, { payload = {} } = {}) => set(
    state, 'copyPatientId', safeGet(payload, 'copyPatientId'),
);

const setRequisition = (state, { payload = {} } = {}) => set(
    state, 'requisition', safeGet(payload, 'requisition'),
);

const setTestKit = (state, { payload = {} } = {}) => set(
    state, 'testKit', safeGet(payload, 'testKit'),
);

const setPocAction = (state, { payload = {} } = {}) => set(
    state, 'action', safeGet(payload, 'action'),
);

const setError = (state, { payload = {} } = {}) => set(
    state, 'error', safeGet(payload, 'error'),
);

const resetScannerState = state => {
    const withoutError = set(state, 'error', null);
    const withoutPocAction = set(withoutError, 'action', undefined);
    return set(withoutPocAction, 'testKit', undefined);
};


export default createReducer(initialState, {
    [ACTION_TYPES.RESET_STATE]: resetState,
    [ACTION_TYPES.RESET_SCANNER_STATE]: resetScannerState,

    [ACTION_TYPES.SET_ERROR]: setError,
    [ACTION_TYPES.SET_LOADING]: setIsLoading(),
    [ACTION_TYPES.SET_PATIENT]: setPatient,
    [ACTION_TYPES.SET_BATCH]: [setBatch, setFields],
    [ACTION_TYPES.SET_KIT_ID]: setKitId,
    [ACTION_TYPES.SET_IMG_SRC]: setImgSrc,
    [ACTION_TYPES.SET_FILE]: setFile,
    [ACTION_TYPES.SET_TEST_RESULT]: setTestResult,

    [ACTION_TYPES.SUBMIT_TEST_REQUEST]: setIsLoading(true),
    [ACTION_TYPES.SUBMIT_TEST_SUCCESS]: setIsLoading(false),
    [ACTION_TYPES.SUBMIT_TEST_FAILURE]: setIsLoading(false),

    [ACTION_TYPES.COPY_TO_PRACTICE_SUCCESS]: setCopyPatientId,
    [ACTION_TYPES.COPY_TO_PRACTICE_FINISHED]: setCopyPatientId,
    [ACTION_TYPES.WS_COPY_TO_PRACTICE_SUCCESS]: setCopyPatientId,

    [ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_REQUEST]: setIsLoading(true),
    [ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_SUCCESS]: [setPocAction, setIsLoading(false)],
    [ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_FAILURE]: [setError, setIsLoading(false)],
    [ACTION_TYPES.WS_POC_ACTION_CHANGE_SUCCESS]: [setRequisition, setTestResult, setTestKit],
    [ACTION_TYPES.WS_POC_ACTION_CHANGE_FAILED]: setIsLoading(false),
});
