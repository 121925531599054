import { PlusCircle } from '@flowhealth/icons';

import { HEALTH_PASSPORT_PATH } from 'containers/PatientPortal/HealthPassport/constants';

import {
    ADD_TEST_PATH,
    USER_SETTINGS_PATH,
    ORDER_BY_ID_PATH,
    ORDERS_PATH
} from 'configuration/paths';

import { ReactComponent as Home } from 'imgs/patientPortal/navigation/Home.svg';
import { ReactComponent as HealthPassport } from 'imgs/patientPortal/navigation/HealthPassport.svg';
import { ReactComponent as Settings } from 'imgs/patientPortal/navigation/Settings.svg';


export const NAVIGATION_ITEMS = [{
    path: '/',
    title: 'Home',
    iconType: Home,
}, {
    path: ADD_TEST_PATH,
    title: 'Add',
    iconType: PlusCircle,
}, {
    path: HEALTH_PASSPORT_PATH,
    title: 'Health Passport',
    iconType: HealthPassport,
}, {
    path: USER_SETTINGS_PATH,
    title: 'Settings',
    iconType: Settings,
}];

export const NAVIGATION_ITEMS_ACTIVE_STATE = {
    '/': ['/', ORDER_BY_ID_PATH, ORDERS_PATH],
    [ADD_TEST_PATH]: [ADD_TEST_PATH],
    [HEALTH_PASSPORT_PATH]: [HEALTH_PASSPORT_PATH],
    [USER_SETTINGS_PATH]: [USER_SETTINGS_PATH],
};
