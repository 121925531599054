import { round, isEmpty, isNumber, isString } from 'lodash';
import { AllHtmlEntities as Entities } from 'html-entities';
import parsePhoneNumber from 'libphonenumber-js';
import { KEY_CODES } from '@flowhealth/constants';
import { onlyDigits, isNum } from '@flowhealth/utils';

import chain from 'utils/chain';


const NUMBER_PRECISION = 2;
const MAX_POSTAL_CODE_LENGTH_US = 5;

export const normalizePhone = value => {
    if (!isString(value)) return value;

    const onlyNums = onlyDigits(value);
    if (!onlyNums.length) return value;

    let phoneNumber;
    if (onlyNums.length === 10) {
        phoneNumber = parsePhoneNumber(`+1${onlyNums}`);
    } else if (onlyNums.length >= 11) {
        phoneNumber = parsePhoneNumber(`+${onlyNums}`);
    }
    if (!phoneNumber) return value;

    const isValid = phoneNumber.isValid();
    if (!isValid) return value;
    if (phoneNumber.country === 'US') return phoneNumber.formatNational();
    return phoneNumber.formatInternational();
};

export const normalizeMoney = value => {
    if (!value) return value;
    const getNumsOnly = str => str.replace(/[^\d]/g, '');
    const valueStr = `${value}`;

    const splitValue = valueStr.split('.');
    const intValue = getNumsOnly(splitValue.shift());
    const floatValue = getNumsOnly(splitValue.join(''));

    return `${(+intValue).toLocaleString().replace(/\s/g, ',')}${splitValue.length ? `.${floatValue}` : ''}`
        .replace(/^[0\D]+/, '');
};

export const normalizeTaxId = value => {
    if (!value) return value;
    const substr = value.replace(/[\D_]/g, '').substr(0, 9);
    const beforeHyphen = substr.substr(0, 2);
    const afterHyphen = substr.substr(2);
    return `${beforeHyphen}${substr.length > 2 ? `-${afterHyphen}` : ''}`;
};

export const normalizeAlphaNumeric = (value, maxLen) => {
    if (!value) return value;
    return value.replace(/[\W_]/g, '').substr(0, maxLen);
};

export const normalizeNumeric = (value, maxLen) => {
    if (!value) return value;
    return value.replace(/\D/g, '').substr(0, maxLen);
};

export const normalizeNumber = value => {
    if (!value || !isNum(value)) return value;

    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const normalizeDomain = value => {
    if (!value) return value;
    // eslint-disable-next-line
    return value.replace(/[^a-zA-Z0-9-\.]+/, '');
};

export const normalizeDateTime = (value, type) => {
    let maxLength = 12;
    if (type === 'date') maxLength = 8;
    const v = value.replace(/\D/g, '').slice(0, maxLength);
    const mm = v.slice(0, 2);
    const dd = v.slice(2, 4);
    const yyyy = v.slice(4, 8);

    if (v.length >= 8 && type !== 'date') {
        return `${mm}/${dd}/${yyyy} ${value.slice(11, 19)}`;
    }

    if (v.length >= 5) {
        return `${mm}/${dd}/${yyyy}`;
    }

    if (v.length >= 3) {
        return `${mm}/${dd}`;
    }

    return v;
};

const COLON = 186;
const MAX_HOURS = 12;
const MAX_MINUTES = 59;

const isDeletionKey = keyCode => keyCode === KEY_CODES.KEY_CODE_BACKSPACE || keyCode === KEY_CODES.KEY_CODE_DELETE;

const isColon = keyCode => keyCode === COLON;

const onlyNums = val => val.replace(/\D/g, '');

const fetchHours = (val = '') => {
    if (+val > 1 && +val <= MAX_HOURS) return `${val}:`;
    if (+val > MAX_HOURS) return `${MAX_HOURS}:`;
    if (+val[0] === 0 && val.length === 2) return `${val[1]}:`;
    return isNaN(val) ? '0:' : `${+val}`;
};

const fetchMinutes = (val = '') => {
    if (isNaN(val)) return '00';
    if (+val > MAX_MINUTES) return '00';
    if (+val < 10 && val.length >= 2) return `0${+val}`;
    return `${+val}`;
};

const fetchTime = val => {
    if (val.includes(':')) {
        const hours = onlyNums(val.split(':')[0]);
        const minutes = onlyNums(val.split(':')[1]);
        const time = `${fetchHours(hours)}:${fetchMinutes(minutes)}`.replace('::', ':').trim();
        return fetchMinutes(minutes).length === 2 ? `${time} ` : time;
    }
    return fetchHours(onlyNums(val));
};

export const normalizeTime = (value, keyCode) => {
    if (isDeletionKey(keyCode) || isColon(keyCode)) return value;
    if (value.includes(' ')) {
        const ampm = value.replace('  ', ' ').split(' ')[1]; // eslint-disable-line prefer-destructuring
        return `${fetchTime(value)} ${ampm}`.replace('  ', ' ');
    }
    return fetchTime(value);
};

export const normalizeDate = (value, keyCode) => {
    if (isDeletionKey(keyCode) || !isString(value)) return value;

    const v = value.replace(/\D/g, '').slice(0, 8);
    const mm = v.slice(0, 2);
    const dd = v.slice(2, 4);

    if (v.length >= 5) {
        return `${mm}/${dd}/${v.slice(4, 8)}`;
    } else if (v.length >= 3) {
        return `${mm}/${dd}${keyCode === KEY_CODES.KEY_CODE_SLASH ? '/' : ''}`;
    }

    return `${v}${keyCode === KEY_CODES.KEY_CODE_SLASH ? '/' : ''}`;
};


export const normalizePostalCode = (value, isUS = true) => {
    if (!value) return value;
    const str = isUS ? value.replace(/[^\d]/g, '') : value;
    const endSlice = isUS ? MAX_POSTAL_CODE_LENGTH_US : undefined;
    return `${str.slice(0, endSlice)}`;
};


export const normalizePercent = value => {
    if (!value) return value;
    const onlyNums = value.replace(/^0/, '').replace(/[^\d]/g, '');

    return onlyNums.slice(0, 2);
};


export const normalizeMacAddress = value => {
    if (!value) return value;
    const allowed = value.replace(/[^a-fA-F\d]/g, '');

    return allowed.slice(0, 12);
};


export const normalizeSSN = value => {
    if (!value) return value;

    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length <= 3) {
        return `${onlyNums}`;
    }

    if (onlyNums.length <= 5) {
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(5, 9)}`;
};


export const normalizeLabCode = value => {
    if (!value) return value;
    const allowed = value.replace(/[^a-zA-Z0-9]/g, '');

    return allowed.slice(0, 3);
};


export const normalizePhoneExt = value => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, '');

    return `${onlyNums.slice(0, 5)}`;
};

export const normalizeNPI = value => {
    if (!value) return value;

    return value.slice(0, 10);
};

export const normalizeTEXT = (value, validateChar) => {
    const entities = new Entities();
    if (typeof value === 'string') {
        let text = value;
        if (validateChar) {
            text = validateChar(value);
        }

        return entities.decode(text);
    }

    return value;
};

export const normalizeNumberPrecision = value => {
    if (typeof value === 'string') {
        return isEmpty(value) ? value : round(value, NUMBER_PRECISION);
    }
    if (typeof value === 'number') {
        return isNumber(value) ? round(value, NUMBER_PRECISION) : value;
    }
};

export const normalizeLength = length => value => value.slice(0, length);

export const normalizeAccession = (data = '') => chain(
    data,
    value => value.trim(),
    value => value.replace(/[a-zIO]|[E]/g, ''),
    normalizeLength(10),
);
