import { fromJS } from 'immutable';

import {
    GET_VALUES_SUCCESS,
    GET_VALUES_FAILURE,
    GET_VALUES_BULK_SUCCESS,
    GET_VALUES_BULK_FAILURE,
} from './constants';

const initialState = fromJS({});

const valueSetReducer = (state = initialState, { type, payload }) => {
    switch (type) {
    case GET_VALUES_SUCCESS:
    case GET_VALUES_FAILURE:
        return state
            .set(payload.file, payload.data);

    case GET_VALUES_BULK_SUCCESS:
    case GET_VALUES_BULK_FAILURE:
    {
        const { errors, value_sets } = payload.data;
        const state_with_errors = errors
            ? Object
                .keys(errors)
                .reduce((state, key) => state.set(key, value_sets[key]), state)
            : state;

        const state_with_vs = value_sets
            ? Object
                .keys(value_sets)
                .reduce((state, key) => state.set(key, value_sets[key]), state_with_errors)
            : state_with_errors;

        return state_with_vs;
    }

    default:
        return state;
    }
};

export default valueSetReducer;
