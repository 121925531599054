import { createUseStyles } from '@flowhealth/ui-components';

export const classes = {
    locationName: 'location-name',
    locationSchedule: 'location-schedule',
    locationOpenedHours: 'location-opened-hours',
    locationClosed: 'location-closed',
    locationOpen: 'location-open',
    locationContinueButton: 'location-continue-button',
};

export const useStyles = createUseStyles(({ palette, typography, spacing }) => ({
    mapContainer: ({ isMobile }) => {
        const styles = {
            position: 'relative',
            height: '85%',

            [`& .${classes.locationName}`]: {
                ...typography['20B'],
                margin: `0 0 ${spacing(3)}`,
            },
            [`& .${classes.locationSchedule}`]: {
                display: 'flex',
                justifyContent: 'space-between',
                margin: `0 0 ${spacing(3)}`,
            },
            [`& .${classes.locationOpenedHours}`]: {
                ...typography['16M'],
            },
            [`& .${classes.locationOpen}`]: {
                ...typography['16R'],
                color: palette.naviBlue[300],
            },
            [`& .${classes.locationClosed}`]: {
                ...typography['16R'],
                color: palette.red[500],
            },
            [`& .${classes.locationContinueButton}`]: {
                ...typography['16M'],
                appearance: 'none',
                border: 'none',
                background: palette.naviBlue[300],
                color: palette.white.main,
                borderRadius: 6,
                height: 48,
                width: '100%',
                cursor: 'pointer',
                transition: 'background-color 0.2s ease 0s',

                '&:hover': {
                    background: palette.naviBlue[400],
                },
                '&:active': {
                    background: palette.naviBlue[600],
                },
            },
        };

        if (isMobile) {
            styles.height = '100%';
            styles.width = '100vw';
            styles.marginLeft = '50%';
            styles.transform = 'translateX(-50%)';
        }

        return styles;
    },
    map: ({ isMobile }) => {
        const styles = {
            height: '100%',
            borderRadius: 8,

            '& .gm-style': {
                font: 'inherit'
            },
            '& .gm-ui-hover-effect': {
                display: 'none !important'
            },
            '& .gm-style-iw-tc': {
                display: 'none !important'
            },
            '& .gm-style-iw-c': {
                padding: `${spacing(4, 5)}`,
                boxShadow: '0px 8px 24px rgba(36, 71, 123, 0.1)'
            },

            '& .gm-style-iw-d': {
                overflow: 'visible !important',
                padding: `0 ${spacing(5)} ${spacing(5)} 0`
            },
        };

        if (isMobile) {
            styles.borderRadius = 0;
        }

        return styles;
    },
    popup: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        background: palette.white.main,
        padding: `${spacing(4)} ${spacing(5)} ${spacing(5)}`,
        zIndex: 1,
        borderRadius: '6px 6px 0 0',
    }
}));
