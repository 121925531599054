import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { bluredBG } from './styles';


const styles = ({ palette = {} } = {}) => ({
    root: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        background: palette.gray[85],
        overflow: 'hidden',
        zIndex: '1000',
    },
    inner: {
        padding: '25px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    head: {
        width: '128px',
        height: '36px',
        borderRadius: '4px',
        display: 'flex',
        marginBottom: '30px',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        margin: '15px 0',
        width: '100%',
    },
    field: {
        width: '30%',
        height: '36px',
        borderRadius: '4px',
        display: 'flex',
        marginRight: '15px',
    },
    button: {
        width: '80px',
        height: '36px',
        borderRadius: '4px',
        display: 'flex',
        marginTop: 'auto',
    },
    ...bluredBG,
});


class FormLoader extends React.PureComponent {
    static propTypes = {
        title: PropTypes.bool,
        action: PropTypes.bool,
        className: PropTypes.string,
    };

    static defaultProps = {
        title: true,
        action: true,
    };

    render() {
        const { classes, className, title, action } = this.props;
        return (
            <div className={cn(className, classes.root)}>
                <div className={classes.inner}>
                    {title && <div className={cn(classes.animate, classes.head)} />}
                    <div className={classes.row}>
                        <div className={cn(classes.animate, classes.field)} />
                        <div className={cn(classes.animate, classes.field)} />
                        <div className={cn(classes.animate, classes.field)} />
                    </div>
                    <div className={classes.row}>
                        <div className={cn(classes.animate, classes.field)} />
                        <div className={cn(classes.animate, classes.field)} />
                    </div>

                    {action && <div className={cn(classes.animate, classes.button)} />}
                </div>
            </div>
        );
    }
}


export default withStyles(styles)(FormLoader);
