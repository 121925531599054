import React from 'react';

const InfoFilled = () => (
    <svg fill="none" viewBox="0 0 20 20">
        <circle cx="10" cy="10" r="9" fill="#F6AB2F" />
        <path
            fill="#fff"
            fillRule="evenodd"
            // eslint-disable-next-line max-len
            d="M10 14c.552 0 1-.448 1-1v-3c0-.552-.448-1-1-1s-1 .448-1 1v3c0 .552.448 1 1 1zm0-6c.552 0 1-.448 1-1s-.448-1-1-1-1 .448-1 1 .448 1 1 1z"
            clipRule="evenodd"
        />
    </svg>
);

export default InfoFilled;
