const styles = ({ palette = {} } = {}) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    label: {
        display: 'flex',
        alignSelf: 'flex-start',
        marginBottom: 4,
        fontSize: 12,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        color: palette.coal[500],
        '&.error': { color: palette.red.main },
        '& .required': {
            marginLeft: 4,
            color: palette.red[700],
        },
    },
    inputContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '8px 16px',
        border: `1px solid ${palette.ash[400]}`,
        borderRadius: 4,
        position: 'relative',
        backgroundColor: palette.white.main,
        '&.error': {
            borderColor: palette.red.main,
            boxShadow: `0 0 0 1px ${palette.red.main}`,
        },
        '&.focused': {
            borderColor: palette.naviBlue.dark,
            boxShadow: `0 0 0 1px ${palette.naviBlue.dark}`,
        },
        '&.noInput': { cursor: 'pointer' },
        '&.disabled': {
            cursor: 'not-allowed',
            backgroundColor: palette.ash[300],
            color: palette.coal.disable,
        },
        '&.leftIcon': { paddingLeft: 17 },
        '&.rightIcon': { paddingRight: 17 },
        '&.preview': {
            backgroundColor: 'inherit',
            border: 'none',
            padding: '8px 16px 8px 0',
        },
        '&.hoverable:hover': {
            border: `1px solid ${palette.ash.main}`,
            backgroundColor: palette.ash[300],
            cursor: 'pointer',
            '& .input': { cursor: 'pointer' },
        },
    },
    input: {
        color: palette.coal.main,
        fontSize: 14,
        minHeight: 20,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        backgroundColor: 'inherit',
        border: 0,
        outline: 0,
        padding: 0,
        margin: 0,
        flex: 1,
        width: '100%',
        minWidth: 50,
        '&::placeholder': { color: palette.coal[500] },
        '&:focus': {
            cursor: 'auto !important',
            caretColor: palette.naviBlue.dark,
        },
    },
    leftIcon: { marginRight: 13 },
    rightIcon: {
        marginLeft: 13,
        '& .clearable svg': { fill: palette.coal.disable },
    },
    error: {
        marginTop: 10,
        fontSize: 12,
        lineHeight: 1.43,
        color: palette.red.main,
    },
    errorWithoutText: { marginTop: 'unset' },
    suggestion: {
        minHeight: 20,
        position: 'absolute',
        left: 16,
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        color: palette.coal[500],
        opacity: 0.5,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&.withIcon': { left: 50 },
    },
    calendar: { color: palette.coal[500] },
    content: {
        display: 'flex',
        '& > *:not(:last-child)': { marginRight: 8 },
        '& .date': { flex: 2 },
    },
    date: { flex: 1 },
    time: {
        flex: 1,
        minWidth: 100,
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    setCurrent: {
        height: 9,
        padding: 0,
        display: 'flex',
        alignSelf: 'flex-start',
        fontSize: 12,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        marginBottom: 4,
        marginRight: 58,
        lineHeight: 1.5,
        textTransform: 'none',
        letterSpacing: 'normal',
        background: 'none !important',
    },
});

export default styles;
