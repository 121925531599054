import { isString } from 'lodash';

export const formatStringToCase = type => string => {
    if (!isString(string)) {
        return string;
    }

    switch (type) {
    case 'up':
        return string.toUpperCase();
    case 'down':
    default:
        return string.toLowerCase();
    }
};
