import React from 'react';
import { createUseStyles, Loading } from '@flowhealth/ui-components';

import { Panel } from 'components/Panel';

import { checkCorporateLocation } from 'utils/appointment';

import { convertMetersToMiles, formatDistanceText } from '../../utils';

import { CollectionLocationInfo } from './CollectionLocationInfo';

const useStyles = createUseStyles(({ palette, typography }) => ({
    panel: {
        '&:hover': {
            cursor: 'pointer',
            background: palette.coal[200],
        },
    },
    locationHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    locationName: {
        ...typography['20B'],
        color: palette.coal.main,
        marginBottom: 12,
    },
    locationDistance: {
        ...typography['16M'],
        textAlign: 'right',
        minWidth: 100,
    },
    address: {
        ...typography['16M'],
    }
}));

export const Location = ({ location, distancesLoading, distancesFromMyLocation, onSelect }) => {
    const isCorporate = checkCorporateLocation(location);
    const classes = useStyles();

    const collectionLocation = isCorporate ? location.collection_location : location;
    const distanceFromMyLocation = distancesFromMyLocation[collectionLocation?.id];

    const handlePanelClick = () => {
        onSelect(location);
    };

    return (
        <Panel className={classes.panel} onClick={handlePanelClick}>
            <div className={classes.locationHeader}>
                <div className={classes.locationName}>{location.name}</div>
                <div className={classes.locationDistance}>
                    {distancesLoading
                        ? <Loading template="text"/>
                        : distanceFromMyLocation
                        && formatDistanceText(
                            convertMetersToMiles(distanceFromMyLocation)
                        )
                    }
                </div>
            </div>
            {collectionLocation ? (
                <CollectionLocationInfo location={collectionLocation}/>
            ) : (
                <div className={classes.address}>{location.address}</div>
            )}
        </Panel>
    );
};
