/* eslint-disable max-len */
import React from 'react';

const Saliva = () => (
    <svg viewBox="0 0 32 32" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M20.1673 28.875C21.9622 28.875 23.4173 27.4199 23.4173 25.625L23.4173 7.77054L24.334 7.77054C24.8863 7.77054 25.334 7.32283 25.334 6.77054L25.334 3.66597C25.334 3.11369 24.8863 2.66597 24.334 2.66597L11.6673 2.66597C11.115 2.66597 10.6673 3.11369 10.6673 3.66597L10.6673 6.77054C10.6673 7.32283 11.115 7.77054 11.6673 7.77054L12.584 7.77054L12.584 11.6414C13.1173 12.3072 13.64 13.0071 14.084 13.6882L14.084 7.77054L21.9173 7.77054L21.9173 12.0114C21.8664 12.0035 21.8142 11.9993 21.7611 11.9993L18.4277 11.9993C17.8754 11.9993 17.4277 12.447 17.4277 12.9993C17.4277 13.5516 17.8755 13.9993 18.4277 13.9993L21.7611 13.9993C21.8142 13.9993 21.8664 13.9952 21.9173 13.9872L21.9173 16.0114C21.8664 16.0035 21.8142 15.9993 21.7611 15.9993L18.4277 15.9993C17.8754 15.9993 17.4277 16.447 17.4277 16.9993C17.4277 17.5516 17.8755 17.9993 18.4277 17.9993L21.7611 17.9993C21.8142 17.9993 21.8664 17.9952 21.9173 17.9872L21.9173 25.625C21.9173 26.5915 21.1338 27.375 20.1673 27.375L15.834 27.375C14.8675 27.375 14.084 26.5915 14.084 25.625L14.084 21.0099C13.6597 21.5931 13.1485 22.0601 12.584 22.418L12.584 25.625C12.584 27.4199 14.0391 28.875 15.834 28.875L20.1673 28.875ZM13.334 17.066C13.334 19.4224 11.5431 21.3326 9.33398 21.3326C7.12484 21.3326 5.33398 19.4224 5.33398 17.066C5.33398 15.137 8.01452 12.0773 8.98692 11.0309C9.17634 10.827 9.49163 10.827 9.68104 11.0309C10.6534 12.0773 13.334 15.137 13.334 17.066ZM23.834 4.16597L23.834 6.27054L12.1673 6.27054L12.1673 4.16597L23.834 4.16597Z" fill="#2F8FE3" />
    </svg>
);

export default Saliva;
