import { isFunction } from 'lodash';

export const DELAY_HOOK_MS = 1000;
export const RETRY_COUNT = 10;

const sleep = ms => new Promise(resolve => {
    setTimeout(resolve, ms);
});

export async function runWhile(
    asyncFunction,
    condition,
    options = {},
) {
    if (!isFunction(asyncFunction) || !isFunction(condition)) {
        return;
    }

    const { retryTimes = RETRY_COUNT, delay = DELAY_HOOK_MS } = options;

    const response = await asyncFunction();

    const newRetryTimes = retryTimes - 1;

    if (newRetryTimes <= 0 || !condition(response)) {
        return response;
    }

    await sleep(delay);

    return runWhile(asyncFunction, condition, { ...options, retryTimes: newRetryTimes, delay });
}

export default runWhile;
