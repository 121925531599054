import { isBoolean } from 'lodash';
import { createReducer, safeGet } from '@flowhealth/utils';

import setIn from 'utils/setIn';

import validate from '../validate';
import format from '../format';

import * as ACTION_TYPES from './constants';
import initialState from './initialState';


const setInputValue = (state, { payload: { form, data } }) => setIn(state, [form, data.id, 'value'], data.value);

const setInputTouched = (state, { payload: { form, data } }) => setIn(state, [form, data.id, 'touched'], true);

const setInputValid = (state, { payload: { form, data } }) => setIn(state, [form, data.id, 'valid'], data.value);

const validateValue = (state, { payload: { form, data } }) => setIn(
    state,
    [form, data.id, 'valid'],
    validate({
        id: data.id,
        value: format(data),
        formData: state[form],
    }),
);

const setInsuranceData = (state, { payload: { form, data } }) => data
    .reduce((state, field) => setIn(state, [form, field.id, 'value'], field.value), state);

const setPerson = (state, { payload: { form, data } }) => setIn(state, [form, 'person'], data);

const setPhotos = (state, { payload: { form, data } }) => {
    const stateWithFront = setIn(state, [form, 'photoFrontInitial'], data.photoFrontInitial);
    return setIn(stateWithFront, [form, 'photoBackInitial'], data.photoBackInitial);
};

const setErrorMessage = (state, { payload: { form, data, error } }) => {
    let { message } = error;
    let updatedState = state;
    if ([403, 404, 503].includes(error.status)) {
        message = 'We could not verify your credentials. Please double-check and try again.';
        for (const key of Object.keys(data)) {
            updatedState = setIn(state, [form, key, 'valid'], false);
        }
    }
    return setIn(updatedState, [form, 'error', 'message'], message);
};

const resetError = (state, { payload: { form } }) => setIn(state, [form, 'error'], initialState[form].error);

const resetState = () => initialState;

const setLoading = value => (state, { payload: { form = ACTION_TYPES.INSURANCE_FORM } = {} } = {}) =>
    setIn(state, [form, 'loading'], isBoolean(value) ? value : safeGet(payload, 'value', false)); // eslint-disable-line

export default createReducer(initialState, {
    [ACTION_TYPES.SET_INPUT_VALUE]: [setInputValue, validateValue],
    [ACTION_TYPES.SET_INPUT_TOUCHED]: [setInputTouched, validateValue],
    [ACTION_TYPES.SET_INPUT_VALID]: setInputValid,
    [ACTION_TYPES.SET_INSURANCE_DATA]: setInsuranceData,
    [ACTION_TYPES.SET_PERSON]: setPerson,
    [ACTION_TYPES.RESET_STATE]: resetState,
    [ACTION_TYPES.INSURANCE_REQUEST]: [setLoading(true), resetError],
    [ACTION_TYPES.INSURANCE_REQUEST_SUCCESS]: setLoading(false),
    [ACTION_TYPES.INSURANCE_REQUEST_FAILURE]: [setLoading(false), setErrorMessage],
    [ACTION_TYPES.GET_PHOTOS_REQUEST]: [setLoading(true), resetError],
    [ACTION_TYPES.GET_PHOTOS_REQUEST_SUCCESS]: [setLoading(false), setPhotos],
    [ACTION_TYPES.GET_PHOTOS_REQUEST_FAILURE]: [setLoading(false), setErrorMessage],
});
