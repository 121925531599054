import { UNIQ_ERROR_CODE } from 'API/constants';

const normalize = text => {
    if (!text) return null;
    if (typeof text !== 'string') return 'Something went wrong';
    return text.includes(`${UNIQ_ERROR_CODE}`)
        ? text.replace(`${UNIQ_ERROR_CODE}: `, '')
        : text;
};

export default normalize;
