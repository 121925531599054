/* eslint-disable max-len */
import React from 'react';
import { Redirect } from 'react-router-dom';

import { HEALTH_PASSPORT_PATH } from 'containers/PatientPortal/HealthPassport/constants';
import { USER_CHANGE_NAME_PATH } from 'containers/PatientPortal/UserChangeName/constants';
import { USER_CHANGE_EMAIL_PATH } from 'containers/PatientPortal/UserChangeEmail/constants';
import { USER_CHANGE_PASSWORD } from 'containers/PatientPortal/UserChangePassword/constants';
import { DELETE_ACCOUNT_PATH } from 'containers/PatientPortal/DeleteAccount/constants';

import { lazyWithRetry } from 'utils/lazyWithRetry';

import * as PATHS from './paths';

const Booking = lazyWithRetry(() => import('containers/PatientPortal/Booking/Booking'));
const PrivacyPolicyPage = lazyWithRetry(() => import('containers/Policy/privacyPolicy'));
const NoticePrivacyPractices = lazyWithRetry(() => import('containers/Legal/NoticePrivacyPractices'));
const ElectronicSignatureTerms = lazyWithRetry(() => import('containers/Legal/ElectronicSignatureTerms'));

const PatientPortalAgreement = lazyWithRetry(() => import('containers/Legal/PatientPortalAgreement'));
const PrivacyPolicy = lazyWithRetry(() => import('containers/Legal/PrivacyPolicy'));
const TermsOfUse = lazyWithRetry(() => import('containers/Legal/TermsOfUse'));

const MailingKit = lazyWithRetry(() => import('containers/PatientPortal/HTK/MailingKit'));
const Covid19PCR = lazyWithRetry(() => import('containers/PatientPortal/HTK/Covid19PCR'));
const MonkeyPox = lazyWithRetry(() => import('containers/PatientPortal/HTK/MonkeyPox'));
const BinaxKit = lazyWithRetry(() => import('containers/PatientPortal/HTK/BinaxNowKit'));
const CareStartKit = lazyWithRetry(() => import('containers/PatientPortal/HTK/CareStartKit'));
const QuickVueKit = lazyWithRetry(() => import('containers/PatientPortal/HTK/QuickVueKit'));
const LuciraKit = lazyWithRetry(() => import('containers/PatientPortal/HTK/LuciraKit'));
const FlowFlexKit = lazyWithRetry(() => import('containers/PatientPortal/HTK/FlowFlexKit'));
const PCROrderKit = lazyWithRetry(() => import('containers/PatientPortal/HTK/PCROrderKit'));
const HomeTestKit = lazyWithRetry(() => import('containers/PatientPortal/HTK'));
const PatientPortalHTKCode = lazyWithRetry(() => import('containers/Auth/forms/HTKCodeForm'));
const PatientPortalHTKCodeExistance = lazyWithRetry(() => import('containers/Auth/pages/HTKCodeExistance'));
const PatientPortalOrders = lazyWithRetry(() => import('containers/PatientPortal/Orders'));
const PatientPortalEmployeeIDForm = lazyWithRetry(() => import('containers/Auth/forms/EmployeeIDForm'));
const PatientPortalInsurance = lazyWithRetry(() => import('containers/PatientPortal/Insurance'));
const PatientPortalInsuranceForm = lazyWithRetry(() => import('containers/PatientPortal/Insurance/form'));
const PatientPortalCreatePassword = lazyWithRetry(() => import('containers/Auth/forms/CreatePasswordForm'));
const PatientPortalLogin = lazyWithRetry(() => import('containers/Auth/forms/LoginForm'));
const PatientPortalVerification = lazyWithRetry(() => import('containers/Auth/forms/VerificationForm'));
const PatientPortalEnterCode = lazyWithRetry(() => import('containers/Auth/forms/EnterCodeForm'));
const PatientPortalUserExists = lazyWithRetry(() => import('containers/Auth/forms/UserExistsForm'));
const PatientPortalLinked = lazyWithRetry(() => import('containers/Auth/forms/LinkedForm'));
const PatientPortalConsent = lazyWithRetry(() => import('containers/PatientPortal/components/Consent'));
const PatientPortalRegisterPatient = lazyWithRetry(() => import('containers/Auth/forms/RegisterPatientForm'));
const PatientPortalAddInsurance = lazyWithRetry(() => import('containers/Auth/forms/AddInsurance'));
const ConnectToPracticePage = lazyWithRetry(() => import('containers/PatientPortal/ConnectToPracticePage'));
const ConnectToPractice = lazyWithRetry(() => import('containers/PatientPortal/ConnectToPractice'));
const ProfilesPage = lazyWithRetry(() => import('containers/PatientPortal/Profiles/Profiles'));
const AddProfilePage = lazyWithRetry(() => import('containers/PatientPortal/Profiles/AddProfile'));
const ProfilesSelectPractice = lazyWithRetry(() => import('containers/PatientPortal/Profiles/SelectPractice'));
const PersonPatients = lazyWithRetry(() => import('containers/PatientPortal/Profiles/Patients'));
const StiOrderInfo = lazyWithRetry(() => import('containers/PatientPortal/orderInfo/StiOrderInfo/components/OrderInfo/StiOrderInfo'));

const AddTest = lazyWithRetry(() => import('containers/PatientPortal/AddTest'));
const OrderPackageInfo = lazyWithRetry(() => import('containers/PatientPortal/OrderPackageInfo'));
const OrderPackageSuccess = lazyWithRetry(() => import('containers/PatientPortal/OrderPackageSuccess'));
const OrderPackageSelect = lazyWithRetry(() => import('containers/PatientPortal/OrderPackageSelect'));

const AppointmentDetails = lazyWithRetry(() => import('containers/PatientPortal/components/Appointment/AppointmentDetails'));
const AppointmentQR = lazyWithRetry(() => import('containers/PatientPortal/AppointmentQR'));
const Appointments = lazyWithRetry(() => import('containers/PatientPortal/components/Appointment/Appointments'));
const AppointmentDecline = lazyWithRetry(() => import('containers/PatientPortal/AppointmentDecline'));
const AppointmentHTKTest = lazyWithRetry(() => import('containers/PatientPortal/AppointmentHTKTest'));
const UserAccount = lazyWithRetry(() => import('containers/PatientPortal/Account'));
const UserSettings = lazyWithRetry(() => import('containers/PatientPortal/UserSettings'));
const UserChangeName = lazyWithRetry(() => import('containers/PatientPortal/UserChangeName'));
const UserChangeEmail = lazyWithRetry(() => import('containers/PatientPortal/UserChangeEmail'));
const UserChangePassword = lazyWithRetry(() => import('containers/PatientPortal/UserChangePassword'));
const HealthPassport = lazyWithRetry(() => import('containers/PatientPortal/HealthPassport'));
const DeleteAccount = lazyWithRetry(() => import('containers/PatientPortal/DeleteAccount'));

const RedirectToPatientPortal = () => {
    const to = `${location.pathname.replace('/patient', '')}${location.search}`;
    return <Redirect to={to} />;
};

const RedirectToMainPage = () => <Redirect to="/" />;

export const routes = [
    {
        type: 'public',
        exact: true,
        path: PATHS.LOGIN_PATH,
        children: PatientPortalLogin,
    },
    {
        type: 'patient',
        exact: true,
        path: HEALTH_PASSPORT_PATH,
        layout: 'navigation',
        children: HealthPassport,
    },
    {
        type: 'public',
        exact: true,
        path: PATHS.CONNECT_PATIENT_PATH,
        layout: 'patient',
        children: ConnectToPracticePage,
    },
    {
        type: 'patient',
        exact: true,
        path: PATHS.CONNECT_TO_PRACTICE_PATH,
        layout: 'patient',
        children: ConnectToPractice,
    },
    {
        type: 'public',
        exact: true,
        path: PATHS.CREATE_PATH,
        layout: 'patient',
        children: PatientPortalRegisterPatient,
    },

    {
        type: 'patient',
        exact: true,
        path: PATHS.PATIENT_PROFILES_PATH,
        children: ProfilesPage,
        layout: 'patient',
    },
    {
        type: 'patient',
        exact: true,
        path: PATHS.PERSON_PATIENTS_PATH,
        children: PersonPatients,
        layout: 'patient',
    },
    {
        type: 'patient',
        exact: true,
        path: PATHS.ADD_PROFILE_PATH,
        children: AddProfilePage,
        layout: 'patient',
    },
    {
        type: 'patient',
        exact: true,
        path: PATHS.SELECT_PRACTICE_PATH,
        children: ProfilesSelectPractice,
        layout: 'patient',
    },
    {
        type: 'patient',
        exact: true,
        path: PATHS.INSURANCE_PATH,
        children: PatientPortalInsurance,
        layout: 'patient',
    },
    {
        type: 'patient',
        exact: true,
        path: PATHS.INSURANCE_CREATE_PATH,
        children: PatientPortalInsuranceForm,
        layout: 'patient',
    },
    {
        type: 'patient',
        exact: true,
        path: PATHS.INSURANCE_VIEW_BY_ID_PATH,
        children: PatientPortalInsuranceForm,
        layout: 'patient',
    },
    {
        type: 'public',
        exact: true,
        path: PATHS.REGISTER_PATH,
        children: PatientPortalVerification,
    },
    {
        type: 'public',
        exact: true,
        path: PATHS.ENTER_CODE_PATH,
        children: PatientPortalEnterCode,
    },
    {
        type: 'public',
        exact: true,
        path: PATHS.ADD_INSURANCE_PATH,
        children: PatientPortalAddInsurance,
    },
    {
        type: 'public',
        exact: true,
        path: PATHS.EXISTS_PATH,
        children: PatientPortalUserExists,
    },
    {
        type: 'patient',
        exact: true,
        path: PATHS.LINKED_PATH,
        children: PatientPortalLinked,
    },
    {
        type: 'patient',
        exact: true,
        path: PATHS.CONSENT_PATH,
        children: PatientPortalConsent,
        roles: ['patient'],
    },
    {
        type: 'public',
        exact: true,
        path: PATHS.PASSWORD_CREATE_PATH,
        children: PatientPortalCreatePassword,
    },
    {
        type: 'public',
        exact: true,
        path: PATHS.HTK_CODE_EXISTANCE_PAGE,
        children: PatientPortalHTKCodeExistance,
    },
    {
        type: 'public',
        exact: true,
        path: PATHS.HTK_CODE_PATH,
        children: PatientPortalHTKCode,
    },
    {
        type: 'public',
        exact: true,
        path: PATHS.CID_PATH,
        children: PatientPortalEmployeeIDForm,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.MAILING_KIT_PATH,
        children: MailingKit,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.COVID19_PCR_PATH,
        children: Covid19PCR,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.MONKEY_POX_PATH,
        children: MonkeyPox,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.BINAX_KIT_PATH,
        children: BinaxKit,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.CARESTART_KIT_PATH,
        children: CareStartKit,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.QUICKVUE_KIT_PATH,
        children: QuickVueKit,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.LUCIRA_KIT_PATH,
        children: LuciraKit,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.FLOW_FLEX_KIT_PATH,
        children: FlowFlexKit,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.PCR_ORDER_PATH,
        children: PCROrderKit,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.HTK_PATH,
        children: HomeTestKit,
    },
    {
        type: 'patient',
        layout: 'navigation',
        exact: true,
        path: PATHS.ADD_TEST_PATH,
        children: AddTest,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.ORDER_PACKAGE_INFO_PATH,
        children: OrderPackageInfo,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.ORDER_PACKAGE_SELECT_PATH,
        children: OrderPackageSelect,
        roles: ['patient'],
    },
    {
        type: 'patient',
        layout: false,
        exact: true,
        path: PATHS.ORDER_PACKAGE_SUCCESS_PATH,
        children: OrderPackageSuccess,
    },
    {
        type: 'patient',
        layout: 'navigation',
        exact: true,
        path: PATHS.ORDER_BY_ID_PATH,
        children: PatientPortalOrders,
        roles: ['patient'],
    },
    {
        type: 'patient',
        layout: 'patient',
        path: PATHS.STI_ORDER_BY_ID,
        children: StiOrderInfo,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.BOOKING_PATH,
        children: Booking,
    },
    {
        type: 'patient',
        layout: 'navigation',
        exact: true,
        path: '/',
        children: PatientPortalOrders,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.APPOINTMENTS_PATH,
        children: Appointments,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.APPOINTMENT_PATH,
        children: AppointmentDetails,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.APPOINTMENT_QR_PATH,
        children: AppointmentQR,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.APPOINTMENT_DECLINE_PATH,
        children: AppointmentDecline,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.APPOINTMENT_TAKE_TEST_PATH,
        children: AppointmentHTKTest,
    },
    {
        type: 'patient',
        layout: 'navigation',
        exact: true,
        path: PATHS.USER_SETTINGS_PATH,
        children: UserSettings,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: PATHS.USER_ACCOUNT_PATH,
        children: UserAccount,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: USER_CHANGE_NAME_PATH,
        children: UserChangeName,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: USER_CHANGE_EMAIL_PATH,
        children: UserChangeEmail,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: USER_CHANGE_PASSWORD,
        children: UserChangePassword,
    },
    {
        type: 'patient',
        layout: 'patient',
        exact: true,
        path: DELETE_ACCOUNT_PATH,
        children: DeleteAccount,
    },
    {
        type: 'blocking',
        exact: true,
        path: PATHS.PRIVACY_POLICY_PATH,
        children: PrivacyPolicy,
    },
    {
        type: 'blocking',
        exact: true,
        path: PATHS.TERMS_OF_SERVICE_PATH,
        children: TermsOfUse,
    },
    {
        type: 'blocking',
        exact: true,
        path: PATHS.NOTICE_PRIVACE_PRACTICE_PATH,
        children: NoticePrivacyPractices,
    },
    {
        type: 'blocking',
        exact: true,
        path: PATHS.HEALTHCARE_PROVIDER_USER_AGREEMENT_PATH,
        children: PrivacyPolicyPage,
    },
    {
        type: 'blocking',
        exact: true,
        path: PATHS.ELECTRONIC_SIGNATURE_TERMS_PATH,
        children: ElectronicSignatureTerms,
    },
    {
        type: 'blocking',
        exact: true,
        path: PATHS.SMS_TERMS_SERVICE_PATH,
        children: PrivacyPolicyPage,
    },
    {
        type: 'blocking',
        exact: true,
        path: PATHS.PORTAL_AGREEMENT_PATH,
        children: PatientPortalAgreement,
    },

    // apple-app-site-association
    {
        exact: true,
        path: PATHS.APPLE_APP_SITE_ASSOCIATION,
        onEnter: () => window.location.reload(),
    },
    {
        path: '/patient/*',
        children: RedirectToPatientPortal,
    },
    {
        path: '/*',
        children: RedirectToMainPage,
    },
];

export default routes;
