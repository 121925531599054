export const getPositionOfMaskOnVideo = (video, {
    maskWidth,
    maskHeight,
    maskLeftOffset,
    maskTopOffset,
}) => {
    const { videoWidth, videoHeight, clientWidth, clientHeight } = video || {};

    const leftMaskOffset = ((clientWidth - maskWidth) / 2 + maskLeftOffset);
    const topMaskOffset = ((clientHeight - maskHeight) / 2 + maskTopOffset);

    const videoWidthRatio = clientWidth / videoWidth;
    const videoHeightRatio = clientHeight / videoHeight;

    const videoRatio = Math.min(videoWidthRatio, videoHeightRatio);

    let leftBlackOffset = 0;
    let topBlackOffset = 0;
    if (videoWidthRatio > videoHeightRatio) {
        leftBlackOffset = (clientWidth - videoWidth * videoRatio) / 2;
    } else {
        topBlackOffset = (clientHeight - videoHeight * videoRatio) / 2;
    }

    const leftScanAreaPos = (leftMaskOffset - leftBlackOffset) / videoRatio;
    const topScanAreaPos = (topMaskOffset - topBlackOffset) / videoRatio;
    const scanAreaWidth = maskWidth / videoRatio;
    const scanAreaHeight = maskHeight / videoRatio;

    return [leftScanAreaPos, topScanAreaPos, scanAreaWidth, scanAreaHeight];
};

export const drawVideoFrame = (video, canvas, config) => {
    const context = canvas.getContext('2d');

    if (!config) {
        const { videoWidth, videoHeight } = video || {};
        canvas.width = videoWidth;
        canvas.height = videoHeight;
        context.drawImage(video, 0, 0);
    } else {
        const { canvasWidth, canvasHeight } = config;
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        context.drawImage(video,
            ...getPositionOfMaskOnVideo(video, config),
            0, 0, canvas.width, canvas.height,
        );
    }
};
