import { select, put, take } from 'redux-saga/effects';
import { safeGet } from '@flowhealth/utils';

import { makeSelectUserData } from 'containers/User/selector';

import selectStore from 'utils/select';

import { getPatientsRequest, getPersonsRequest } from './actions';
import {
    KEY_PATIENT_PORTAL,
    GET_PATIENTS_SUCCESS,
    GET_PATIENTS_FAILURE,
    GET_PERSONS_SUCCESS,
    GET_PERSONS_FAILURE,
} from './constants';

export function* fetchPatientsByCurrentUser() {
    const user = yield select(makeSelectUserData());
    const userId = safeGet(user, 'id');
    yield put(getPatientsRequest({ userId }));
    yield take([GET_PATIENTS_SUCCESS, GET_PATIENTS_FAILURE]);
    return yield select(selectStore(KEY_PATIENT_PORTAL, 'patients'));
}

export function* fetchPersonsByCurrentUser() {
    const user = yield select(makeSelectUserData());
    const userId = safeGet(user, 'id');
    yield put(getPersonsRequest({ userId }));
    yield take([GET_PERSONS_SUCCESS, GET_PERSONS_FAILURE]);
    return yield select(selectStore(KEY_PATIENT_PORTAL, 'persons'));
}
