import { get, getIn } from 'immutable';
import isFunction from 'lodash/isFunction';
import { createSelector } from 'reselect';
import { decodeString } from '@flowhealth/utils';

import { CREATE_PASSWORD_FORM, PATIENT_PORTAL_AUTH_KEY } from './constants';
import initialState from './initialState';


const selectPortalState = state => get(state, PATIENT_PORTAL_AUTH_KEY, {});


const selectField = (field, initialValue = undefined, formatter) => createSelector(
    selectPortalState,
    state => {
        const result = get(state, field, initialValue);
        return isFunction(formatter) ? formatter(result) : result;
    },
);

export const selectCreatePasswordForm = state => getIn(
    state,
    [PATIENT_PORTAL_AUTH_KEY, CREATE_PASSWORD_FORM],
    initialState[CREATE_PASSWORD_FORM],
);

export const selectPassword = createSelector(
    selectCreatePasswordForm,
    data => get(data, 'password', initialState[CREATE_PASSWORD_FORM].password),
);

export const selectLoading = selectField('loading', false);
export const selectPatientId = selectField('patient_id');
export const selectPatientPhone = selectField('patient_phone');
export const selectResetToken = selectField('reset_token');
export const selectRedirectUrl = selectField('redirect_url');
export const selectPortalUseCase = selectField('portalUseCase');
export const selectPatientRequest = selectField('patientRequest');

export const selectPatientName = selectField('patient_name', '', decodeString);
export const selectPracticeId = selectField('practice_id', '', decodeString);
export const selectPracticeName = selectField('practice_name', '', decodeString);

export const selectFormField = (form, field) => createSelector(
    selectPortalState,
    data => getIn(data, [form, field], initialState[form][field]),
);
