import React, { useState, useEffect, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import IntlTelInput from 'react-intl-tel-input';
import parsePhoneNumber from 'libphonenumber-js';
import { isFunction } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { safeGet, onlyDigits } from '@flowhealth/utils';
import cn from 'classnames';

import 'react-intl-tel-input/dist/main.css';

import FlowIcon from 'components/FlowIcon';
import { ICONS_TYPES } from 'components/FlowIcon/constants';

import styles from './styles';
import { createPlaceholder } from './utils';


function FlowPhone({
    id,
    label,
    labelClassName,
    required,
    disabled,
    preview,
    className = '',
    onlyUS = false,
    useNativeSelect = false,
    meta = {},
    classes = {},
    placeholder,
    dataTestId,
    ...props
}) {
    const [focused, setFocused] = useState(false);
    const [touched, setTouched] = useState(false);
    const [error, setError] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        const submitFailed = safeGet(meta, 'submitFailed', false);
        const metaError = safeGet(meta, 'error');

        if (submitFailed && !focused && metaError) setError(metaError);
        if (!focused && !metaError) setError(null);
    }, [meta, focused]);


    const handleChange = (_, value, country) => {
        const { input, onChange } = props;
        if (!touched) return;

        if (country.iso2 === 'us') {
            const digits = onlyDigits(value);

            if (digits.length > 10 && digits[0] !== '1') {
                // eslint-disable-next-line no-param-reassign
                value = digits.slice(0, 10);
            }
            if (digits.length > 11 && digits[0] === '1') {
                // eslint-disable-next-line no-param-reassign
                value = digits.slice(0, 11);
            }
        }
        if (isFunction(onChange)) onChange(value, country);

        input && isFunction(input.onChange) && input.onChange(value);
    };

    const handleFocus = (_, value, { iso2: country = '' } = {}) => {
        const { input, onFocus } = props;
        setError(null);
        setFocused(true);
        if (!touched) setTouched(true);
        if (isFunction(onFocus)) onFocus();

        if (!value) return;
        const parsed = parsePhoneNumber(value, country.toUpperCase());
        if (!parsed) return;

        input && isFunction(input.onChange) && input.onChange(parsed.country === 'US'
            ? parsed.nationalNumber
            : parsed.number);
    };

    const handleBlur = (_, value, { iso2: country = '' } = {}) => {
        const { input, onBlur } = props;
        const metaError = safeGet(meta, 'error');
        if (metaError) setError(metaError);
        setFocused(false);
        if (isFunction(onBlur)) onBlur();

        if (!value) return;
        const parsed = parsePhoneNumber(value, country.toUpperCase());
        if (!parsed) return;

        input && isFunction(input.onChange) && input.onChange(parsed.country === 'US'
            ? parsed.formatNational()
            : parsed.formatInternational());
    };

    const focusInput = () => {
        const tel = safeGet(ref, 'current.tel');
        !disabled && tel && isFunction(tel.focus) && tel.focus();
        handleFocus(null, props.input.value);
    };

    const updateFlag = useCallback(event => ref.current?.setFlag(event?.target?.value), [ref]);

    return (
        <div className={cn(classes.root, className)}>
            <div
                className={cn(classes.label, labelClassName)}
                onClick={focusInput}
                style={{ marginBottom: preview && '8px' }}
            >
                {label}
                {required && <span className="required">*</span>}
            </div>
            {preview ? (
                <span className={cn('preview', classes.preview)}>
                    {props.input.value}
                </span>
            ) : (
                <IntlTelInput
                    containerClassName={cn('intl-tel-input', classes.container, {
                        [classes.hideFlag]: onlyUS,
                        focused,
                        disabled,
                        error,
                        invalid: !focused && props.invalid,
                    })}
                    telInputProps={{
                        'data-tid': dataTestId,
                    }}
                    inputClassName={cn(classes.input, { disabled })}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={props.input.value}
                    allowDropdown={!onlyUS && !useNativeSelect}
                    nationalMode={onlyUS || !safeGet(props, 'input.value', '').includes('+')}
                    defaultCountry="us"
                    onlyCountries={onlyUS ? ['us'] : []}
                    preferredCountries={['us', 'ca']}
                    ref={ref}
                    fieldId={id}
                    fieldName={props.input.name}
                    formatOnInit={false}
                    customPlaceholder={createPlaceholder}
                    onPhoneNumberChange={handleChange}
                    onPhoneNumberFocus={handleFocus}
                    onPhoneNumberBlur={handleBlur}
                />
            )}
            {error && (
                <div className={cn('error inputError', classes.error)}>
                    {error.message || error}
                </div>
            )}
            {useNativeSelect && ref.current && createPortal((
                <>
                    <select
                        className={classes.customDropdown}
                        onChange={updateFlag}
                        tabIndex="-1"
                    >
                        {ref.current && ref.current.countries.map((country, idx) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <option key={idx} value={country.iso2}>{country.name}</option>
                        ))}
                    </select>
                    <FlowIcon
                        className={classes.arrow}
                        type={ICONS_TYPES.play}
                        size={8}
                        rotate={90}
                    />
                </>
            ), ref.current.flagDropDown.querySelector('.selected-flag'))}
        </div>
    );
}

FlowPhone.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    className: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    preview: PropTypes.bool,
    onlyUS: PropTypes.bool,
    input: PropTypes.object,
    meta: PropTypes.object,
    invalid: PropTypes.bool,
    useNativeSelect: PropTypes.bool,

    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
};

export default withStyles(styles)(FlowPhone);
