import { getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';
import { isObject, safeGet } from '@flowhealth/utils';


import { US_NUMBER_PLACEHOLDER } from './constants';

export const createPlaceholder = (_, country) => {
    if (!isObject(country) || safeGet(country, 'iso2') === 'us') return US_NUMBER_PLACEHOLDER;
    try {
        const parsed = getExampleNumber(country.iso2.toUpperCase(), examples);

        const nationalPartRegExp = RegExp(`(?<=\\+${country.dialCode})[0-9]+`, 'g');
        const [nationalPart] = parsed.number.match(nationalPartRegExp);

        const formatedRegExp = RegExp(nationalPart.split('').join('\\s?'), 'g');
        const formated = parsed.formatInternational();

        const nationalWithX = formated.match(formatedRegExp)[0].replace(/\d/g, 'X');

        return formated.replace(formatedRegExp, nationalWithX);
    } catch (e) {
        return US_NUMBER_PLACEHOLDER;
    }
};
