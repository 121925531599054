/* eslint-disable max-len */
import React from 'react';


const CheckInCircle = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.95952 14.8356C10.35 14.4451 10.9832 14.4451 11.3737 14.8356L14.4379 17.8997L21.2732 11.0644C21.6638 10.6739 22.2969 10.6739 22.6874 11.0644C23.078 11.4549 23.078 12.0881 22.6874 12.4786L15.145 20.0211L14.4379 20.7282L13.7308 20.0211L9.95952 16.2498C9.56899 15.8593 9.56899 15.2261 9.95952 14.8356Z" fill="#419D4E" />
        <circle cx="15.9989" cy="15.9999" r="11.7879" stroke="#419D4E" strokeWidth="2" />
    </svg>
);

export default CheckInCircle;
