/* eslint-disable max-len */
import React from 'react';

const Phone = () => (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.69801 6.74767e-07H6.54785C6.84046 -0.00028053 7.12642 0.0873369 7.36868 0.251506C7.61094 0.415674 7.79835 0.64883 7.90661 0.920772L9.017 3.65385C9.12392 3.91952 9.15045 4.21075 9.0933 4.49138C9.03616 4.772 8.89786 5.02965 8.69557 5.23231L7.15775 6.78511C7.14634 6.79662 7.14122 6.81297 7.14408 6.82892C7.38977 8.20123 8.04885 9.4659 9.03332 10.4533C10.0178 11.4407 11.2802 12.1033 12.6514 12.3527C12.6674 12.3556 12.6838 12.3505 12.6953 12.3391L14.2621 10.7862C14.4678 10.586 14.7278 10.4509 15.0097 10.3976C15.2916 10.3444 15.5829 10.3753 15.8474 10.4865L18.6014 11.59C18.8692 11.7017 19.0976 11.8907 19.2576 12.1327C19.4177 12.3748 19.502 12.659 19.5 12.9492V16.8077C19.5 17.3891 19.2691 17.9468 18.8581 18.3579C18.4471 18.769 17.8896 19 17.3084 19H17.1842C2.8295 18.1742 0.791349 6.05808 0.506446 2.36039C0.461918 1.7807 0.649397 1.20706 1.02764 0.765647C1.40589 0.32423 1.94393 0.0511886 2.52341 0.00657981C2.58137 0.00219519 2.63956 6.74767e-07 2.69801 6.74767e-07ZM6.09193 5.72959C5.7388 6.08617 5.57817 6.5942 5.66752 7.09327C5.96708 8.76643 6.77066 10.3084 7.97105 11.5124C9.17142 12.7163 10.7108 13.5244 12.3829 13.8285C12.8834 13.9195 13.3936 13.7589 13.7512 13.4044L15.2901 11.8792L17.9999 12.9649V16.8077C17.9999 16.9915 17.927 17.1676 17.7972 17.2974C17.6675 17.4272 17.4916 17.5 17.3084 17.5H17.2279C10.5725 17.1055 6.91174 14.1431 4.82629 10.9389C2.68408 7.64756 2.13593 3.98309 2.00201 2.2455C1.98804 2.0624 2.04728 1.88096 2.16664 1.74167C2.28585 1.60255 2.45522 1.51652 2.63755 1.50223C2.65732 1.50076 2.67744 1.5 2.69798 1.5H6.52284L7.5246 3.9657L6.09193 5.72959Z" fill="#4F5862" />
    </svg>
);

export default Phone;

