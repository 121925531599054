import { put, takeLatest, take, call, all, takeEvery, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { safeGet, isFullObject } from '@flowhealth/utils';
import API from '@flowhealth/api';
import dayjs from 'dayjs';

import { webSocketSubscribe, webSocketUnsubscribe } from 'API/websocket/actions';
import { WEBSOCKET_GET_MESSAGE } from 'API/websocket/constants';

import { openErrorMessage } from 'components/Message/actions';
import { ERROR_KIT_ALREADY_USED } from 'components/CameraScanner';

import storeSelect from 'utils/select';
import { DATE_TIME_FORMAT } from 'utils/constants';

import {
    COPY_TO_PRACTICE_ERROR,
    SOCKET_WAIT_TIME,
    COPY_TO_PRACTICE_WS_TYPE,
    POC_ACTION_CHANGED_WS_TYPE,
} from '../../constants';

import { LUCIRA_KIT_KEY } from './constants';
import * as ACTION_TYPES from './actionTypes';
import * as ACTIONS from './actions';
import {
    selectCopyPatientId,
    selectKitBatch,
    selectKitBatchPractice,
    selectKitBatchPatient,
    selectKitId,
    selectFields,
} from './selectors';

function* getRefRanges({ payload }) {
    const { practice_id } = payload;

    try {
        const { panel_code } = yield select(selectKitBatch);
        const response = yield call(API.getInstance().search, {
            object: 'test_panel',
            filters: `code_sort:${panel_code}`,
        });

        const panelId = safeGet(response, 'results[0].id');

        if (panelId) {
            const refRange = yield call(API.getInstance().getReferenceRange, {
                practice_id,
                test_panel_id: panelId,
            });

            if (refRange?.id) {
                yield put(ACTIONS.getRefRangesSuccess({ refRange, panelId }));
            } else {
                yield put(ACTIONS.getRefRangesFailure());
            }
        } else {
            yield put(ACTIONS.getRefRangesFailure());
        }
    } catch (error) {
        yield put(ACTIONS.getRefRangesFailure());
    }
}

function* submitTest({ payload }) {
    try {
        const { file, onSuccess } = payload;
        const copyPatientId = yield select(selectCopyPatientId);

        if (copyPatientId) {
            yield delay(SOCKET_WAIT_TIME);

            const currentCopyPatientId = yield select(selectCopyPatientId);

            if (currentCopyPatientId) {
                yield put(ACTIONS.submitTestFailure({ error: COPY_TO_PRACTICE_ERROR }));
                yield put(openErrorMessage(COPY_TO_PRACTICE_ERROR));
                return;
            }
        }

        const fileObj = yield call(API.getInstance().newFile, {
            mime_type: safeGet(file, 'type', 'undefined'),
            title: safeGet(file, 'name', dayjs().format(DATE_TIME_FORMAT)),
            kind: '',
        });
        const uploadUrl = fileObj?.upload_url;
        const fileId = fileObj?.id;

        yield call(API.getInstance().uploadFile, uploadUrl, file);

        const panelId = yield select(storeSelect(LUCIRA_KIT_KEY, 'panelId'));
        const batch = yield select(selectKitBatch);
        const { appointment } = yield select(selectFields) ?? {};
        const kitBatchPatient = yield select(selectKitBatchPatient);
        const kit_id = yield select(selectKitId);

        const { role: collector } = kitBatchPatient;
        const { practice, location, kit_batch } = batch;

        const patient = yield select(selectKitBatchPatient);

        const data = {
            patient: patient?.id,
            practice,
            location,
            panel: panelId,
            collector,
            kit_id,
            file: fileId,
            object_type: 'poc_action',
        };
        if (appointment) {
            data.appointment = appointment.id;
        } else {
            data.kit_batch = kit_batch.id;
        }
        
        const pocAction = yield call(API.getInstance().createGraph, data);
        yield put(ACTIONS.wsSubscribe({ group: pocAction.id, type: POC_ACTION_CHANGED_WS_TYPE }));

        if (onSuccess) {
            yield take([
                ACTION_TYPES.WS_POC_ACTION_CHANGE_SUCCESS,
                ACTION_TYPES.WS_POC_ACTION_CHANGE_FAILURE,
            ]);
            const testKit = yield select(storeSelect(LUCIRA_KIT_KEY, 'testKit'));
            if (isFullObject(testKit)) {
                onSuccess();
            } else {
                throw new Error('testKit dosn\'t exist');
            }
        }
        yield put(ACTIONS.submitTestSuccess());
    } catch (error) {
        yield put(ACTIONS.submitTestFailure({ error }));
    }
}

function* addResultToPocAction({
    test,
    reqTest,
    requisitionId,
}) {
    const range = yield select(storeSelect(LUCIRA_KIT_KEY, 'testResult'));

    const data = {
        object_type: 'test_result',
        requisition: requisitionId,
        test,
        range,
    };

    const testResult = yield call(API.getInstance().createGraphOnEdge, reqTest, 'results', data);
    return testResult;
}

function* onWSPocActionChanged(message) {
    const { group, delta, type } = message || {};
    if (type !== POC_ACTION_CHANGED_WS_TYPE || !delta) return;

    try {
        const pocActionId = group;
        const { requisition, test_kit } = delta;

        const invalidTestResult = yield call(API.getInstance().getGraph, test_kit?.result);

        const testResult = yield call(addResultToPocAction, {
            requisitionId: requisition?.id,
            test: invalidTestResult?.test,
            reqTest: invalidTestResult?.req_test,
            pocActionId,
        });

        yield call(API.getInstance().patchGraph, test_kit.id, { status: 'pending', result: testResult?.id });

        yield put(ACTIONS.wsPocActionChangeSuccess({
            requisition,
            testKit: { ...test_kit, result: testResult },
            testResult: testResult?.range,
        }));
    } catch (error) {
        yield put(ACTIONS.wsPocActionChangeFailure({ error }));
    }

    yield put(ACTIONS.wsUnsubscribe({ group, type }));
}

function* copyToPractice({ payload = {} } = {}) {
    yield put(ACTIONS.setError());
    try {
        const { patient, practiceId } = payload;
        if (!patient?.practice) return;

        const copyPatient = yield call(API.getInstance().createGraph, {
            object_type: 'copy_patient',
            patient: patient.id,
            practice: patient.practice.id,
        });

        yield put(ACTIONS.copyToPracticeSuccess({ copyPatientId: copyPatient.id }));

        yield put(ACTIONS.wsSubscribe({ group: copyPatient.id, type: COPY_TO_PRACTICE_WS_TYPE }));

        yield call(API.getInstance().addGraphToEdgeBulk, copyPatient.id, 'to_practices', [practiceId]);

        yield call(API.getInstance().patchGraph, copyPatient.id, { status: 'processing' });
    } catch (error) {
        yield put(ACTIONS.copyToPracticeFailure({ error }));
    }
}

function* onWSCopyToPractice(message) {
    const { group, delta, type } = message || {};
    if (type !== COPY_TO_PRACTICE_WS_TYPE || !delta) return;

    try {
        const copyPatientId = yield select(selectCopyPatientId);
        const batchPractice = yield select(selectKitBatchPractice);

        if (copyPatientId !== group) return;

        const { failed_practices } = delta;
        const isFailed = failed_practices.includes(batchPractice);

        if (!isFailed) {
            const copiedPatientResponse = yield call(API.getInstance().getGraph, group, 'copy_patients');
            const copiedPatient = safeGet(copiedPatientResponse, 'copy_patients.results[0]');

            yield put(ACTIONS.setPatient(copiedPatient));
            yield put(ACTIONS.copyToPracticeFinished());
        } else {
            yield put(openErrorMessage(COPY_TO_PRACTICE_ERROR));
        }
    } catch (error) {
        yield put(ACTIONS.wsFailure({ error }));
    }

    yield put(ACTIONS.wsUnsubscribe({ group, type }));
}

function* onWS({ payload: { message } } = {}) {
    const { delta, type } = message || {};
    if (!delta) return;

    if (type === COPY_TO_PRACTICE_WS_TYPE) {
        yield call(onWSCopyToPractice, message);
    } else if (type === POC_ACTION_CHANGED_WS_TYPE) {
        yield call(onWSPocActionChanged, message);
    }
}

function* wsSubscribe({ payload: { group, type } = {} } = {}) {
    if (!group) return;

    yield put(webSocketSubscribe({
        payload: [{
            group,
            type: [type],
        }],
    }));
}

function* wsUnsubscribe({ payload: { group, type } = {} } = {}) {
    if (!group) return;

    yield put(webSocketUnsubscribe({
        payload: [{
            group,
            type: [type],
        }],
    }));
}

function* checkUniquePocAction({ payload = {} } = {}) {
    const { kitId: kit_id, onSuccess, onFailed } = payload;
    try {
        const copyPatientId = yield select(selectCopyPatientId);

        if (copyPatientId) {
            yield take(ACTION_TYPES.COPY_TO_PRACTICE_FINISHED);

            const currentCopyPatientId = yield select(selectCopyPatientId);

            if (currentCopyPatientId) {
                throw new Error(COPY_TO_PRACTICE_ERROR);
            }
        }

        const { kit_batch } = yield select(selectKitBatch);

        const params = {
            kit_id,
            panel_id: kit_batch?.panel?.id,
        };

        const response = yield call(API.getInstance().pocActionAllowed, params);

        if (response.result) {
            yield put(ACTIONS.checkUniquePocActionSuccess({ kitId: kit_id }));
            onSuccess();
        } else {
            throw new Error(ERROR_KIT_ALREADY_USED);
        }
    } catch (error) {
        yield put(ACTIONS.checkUniquePocActionFailure({ pocActionError: error }));
        onFailed();
    }
}

export default function* Saga() {
    yield all([
        takeLatest(ACTION_TYPES.GET_REF_RANGES_REQUEST, getRefRanges),
        takeLatest(ACTION_TYPES.SUBMIT_TEST_REQUEST, submitTest),
        takeLatest(ACTION_TYPES.CHECK_UNIQUE_POC_ACTION_REQUEST, checkUniquePocAction),
        takeLatest(ACTION_TYPES.COPY_TO_PRACTICE_REQUEST, copyToPractice),

        takeEvery(WEBSOCKET_GET_MESSAGE, onWS),
        takeEvery(ACTION_TYPES.WS_SUBSCRIBE, wsSubscribe),
        takeEvery(ACTION_TYPES.WS_UNSUBSCRIBE, wsUnsubscribe),
    ]);
}
