export const bluredBG = {
    animate: {
        background: 'linear-gradient(to right, #f5f7f9 20%, #E2E7EE 50%, #f5f7f9 80%)',
        backgroundSize: '500px 100px',
        animationName: 'movingGradient',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
        animationFillMode: 'forwards',
    },
    '@keyframes movingGradient': {
        '0%': { 'background-position': '-250px 0' },
        '100%': { 'background-position': '250px 0' },
    },
};

export const loaderPathStyles = {
    loaderPath: {
        'stroke-dasharray': '1, 200',
        'stroke-dashoffset': 0,
        animation: 'dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite',
        'stroke-linecap': 'round',
    },
    '@keyframes dash': {
        '0%': {
            'stroke-dasharray': '1, 200',
            'stroke-dashoffset': 0,
        },
        '50%': {
            'stroke-dasharray': '89, 200',
            'stroke-dashoffset': '-35px',
        },
        '100%': {
            'stroke-dasharray': '89, 200',
            'stroke-dashoffset': '-124px',
        },
    },
    '@keyframes color': {
        '100%, 0%': {
            stroke: '#d62d20',
        },
        '40%': {
            stroke: '#0057e7',
        },
        '66%': {
            stroke: '#008744',
        },
        '80%, 90%': {
            stroke: '#ffa700',
        },
    },
};

const circularRotateStyles = {
    circularLoader: {
        animation: 'rotate 2s linear infinite',
        height: '100%',
        transformOrigin: 'center center',
        width: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
    },
    '@keyframes rotate': {
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
};

export const styles = ({ palette = {} } = {}) => ({
    root: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        background: palette.gray[85],
        overflow: 'hidden',
        zIndex: '1000',
    },
    defaultRoot: {
        marginTop: -10,
    },
    loader: {
        position: 'relative',
        margin: '0 auto',
        width: '50px',
        '&:before': {
            content: '',
            display: 'block',
            paddingTop: '100%',
        },
    },
    ...circularRotateStyles,
    ...loaderPathStyles,
});
