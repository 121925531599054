/* eslint-disable max-len */
import * as React from 'react';

const CommonPass = () => (
    <svg viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 19.2a7.2 7.2 0 100-14.4 7.2 7.2 0 000 14.4zm0 4.8c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
            fill="#1C429F"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.092 6.91l-.001-.001 3.394-3.394.003.003a12.002 12.002 0 01-.003 16.967l-3.394-3.394a7.2 7.2 0 000-10.181z"
            fill="#2CC37C"
        />
    </svg>
);

export default CommonPass;
