import React from 'react';
import cn from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { isFunction, isString } from 'lodash';
import PropTypes from 'prop-types';
import { KEY_CODES } from '@flowhealth/constants';

import { ICONS_TYPES } from 'components/FlowIcon/constants';
import FlowIcon from 'components/FlowIcon';

import chain from 'utils/chain';

import styles from '../styles';
import { EMPTY_VALUE } from '../constants';

export class Input extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        preview: PropTypes.bool,
        error: PropTypes.any,
        showError: PropTypes.bool,
        leftIcon: PropTypes.any,
        rightIcon: PropTypes.any,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onKeyDown: PropTypes.func,
        onClick: PropTypes.func,
        onClear: PropTypes.func,
        getInputRef: PropTypes.func,
        changeHandlers: PropTypes.arrayOf(PropTypes.func),
        deleteHandlers: PropTypes.arrayOf(PropTypes.func),
        blurHandlers: PropTypes.arrayOf(PropTypes.func),
        suggestion: PropTypes.string,
        clearable: PropTypes.bool,
        value: PropTypes.string,
        setValue: PropTypes.func,
        checkValue(props, propName, componentName) { // eslint-disable-line
            if (isString(props.value) && !isFunction(props.setValue)) {
                return new Error(`[${componentName}] Prop "setValue" of type function is required for "value" prop`);
            }
        },
    };

    static defaultProps = {
        classes: {},
        changeHandlers: [],
        deleteHandlers: [],
        blurHandlers: [],
        error: '',
        showError: true,
        clearable: false,
    };

    state = {
        value: '',
        focused: false,
    };

    componentDidMount() {
        const { initialValue = '', value: propsValue, setValue } = this.props;
        this.getInputRef();
        if (!initialValue || initialValue === EMPTY_VALUE) return;
        isString(propsValue) ? setValue(initialValue) : this.setState({ value: initialValue });
    }

    componentDidUpdate(prevProps) {
        const { initialValue, value: propsValue, setValue, onChange } = this.props;
        if (prevProps.initialValue !== initialValue) {
            const value = initialValue !== EMPTY_VALUE ? initialValue || '' : '';
            if (isString(propsValue)) setValue(value);
            else {
                this.setState({ value });
                isFunction(onChange) && onChange(value, undefined, true);
            }
        }
        this.getInputRef();
    }

    getInputRef() {
        const { getInputRef } = this.props;
        isFunction(getInputRef) && getInputRef(this.inputRef);
    }

    handleChange = event => {
        const { onChange, changeHandlers, deleteHandlers, value: propsValue, setValue } = this.props;
        const newValue = event.target.value.length >= this.value.length
            ? chain(event.target.value, ...changeHandlers)
            : chain(event.target.value, ...deleteHandlers);
        isString(propsValue) ? setValue(newValue) : this.setState({ value: newValue });
        isFunction(onChange) && onChange(newValue, event);
    };

    handleFocus = event => {
        const { onFocus, disabled } = this.props;
        if (disabled) {
            event && event.preventDefault();
            event && event.stopPropagation();
            return;
        }
        this.setState({ focused: true });
        isFunction(onFocus) && onFocus(event, this);
    };

    handleBlur = (event, options = {}) => {
        const { onBlur, blurHandlers, value: propsValue, setValue } = this.props;
        const newValue = chain(this.value, ...blurHandlers);
        if (isString(propsValue)) {
            setValue(newValue);
            this.setState({ focused: false });
        } else this.setState({ focused: false, value: newValue });
        isFunction(onBlur) && onBlur(newValue, event, options);
    };

    handleClick = event => {
        const { onClick, disabled } = this.props;
        const { focused } = this.state;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        !disabled && !focused && this.inputRef && this.inputRef.focus();
        isFunction(onClick) && onClick(this.inputRef, event);
    };

    handleClear = event => {
        const { onClear, onChange, value: propsValue, setValue } = this.props;
        const callback = () => {
            isFunction(onChange) && onChange(this.value, event);
            isFunction(onClear) && onClear(event);
        };
        isString(propsValue) ? setValue('', callback) : this.setState({ value: '' }, callback);
    };

    handleKeyDown = event => {
        const { onKeyDown } = this.props;
        const { keyCode } = event;
        if (keyCode !== KEY_CODES.KEY_CODE_ENTER) return isFunction(onKeyDown) && onKeyDown(event);
        event && event.preventDefault();
        this.handleBlur(event, { triggerFormSubmit: true });
        isFunction(onKeyDown) && onKeyDown(event);
    };

    clearValue = () => {
        const { value: propsValue, setValue } = this.props;
        isString(propsValue) ? setValue('') : this.setState({ value: '' });
    };

    get leftIcon() {
        const { leftIcon, classes } = this.props;
        if (!leftIcon) return null;
        return <div className={cn('leftIcon', classes.leftIcon)}>{leftIcon}</div>;
    }

    get rightIcon() {
        const { rightIcon, classes, clearable, preview } = this.props;
        if (clearable && this.value.length > 0 && !preview) {
            return (
                <div className={cn('rightIcon', classes.rightIcon)}>
                    <FlowIcon
                        className="clearable"
                        type={ICONS_TYPES.clear}
                        size={20}
                        onClick={this.handleClear}
                    />
                </div>
            );
        }
        if (!rightIcon) return null;
        return <div className={cn('rightIcon', classes.rightIcon)}>{rightIcon}</div>;
    }

    get suggestion() {
        const { suggestion, classes, leftIcon } = this.props;
        if (!suggestion) return null;
        return (
            <div className={cn('suggestion', classes.suggestion, { withIcon: leftIcon })}>
                {suggestion}
            </div>
        );
    }

    get error() {
        const { showError, classes, error } = this.props;
        const errText = (error && error.message) || error;
        if (!showError || !errText || !isString(errText)) return null;
        return (
            <div className={cn('error inputError', classes.error)}>
                {errText}
            </div>
        );
    }

    get value() {
        const { value: propsValue } = this.props;
        const { value: stateValue = '' } = this.state;
        return isString(propsValue) ? propsValue : stateValue;
    }

    render() {
        const {
            classes,
            className,
            disabled,
            preview,
            error,
            leftIcon,
            rightIcon,
            clearable,
            ...props
        } = this.props;
        const { focused } = this.state;
        return (
            <>
                <div
                    className={cn(
                        'inputContainer',
                        classes.inputContainer,
                        {
                            disabled,
                            preview: preview && !focused,
                            focused,
                            error,
                            leftIcon,
                            rightIcon: rightIcon || clearable,
                            hoverable: !focused && !disabled,
                        },
                        className,
                    )}
                    onClick={this.handleClick}
                >
                    {this.leftIcon}
                    <input
                        type="text"
                        autoComplete="off"
                        ref={ref => {
                            this.inputRef = ref;
                        }}
                        {...props}
                        className={cn(
                            'input',
                            classes.input,
                            {
                                preview,
                                hoverable: !focused && !disabled,
                            },
                        )}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        onKeyDown={this.handleKeyDown}
                        value={this.value}
                        disabled={preview || disabled}
                    />
                    {this.suggestion}
                    {this.rightIcon}
                </div>
                {this.error}
            </>
        );
    }
}

export default withStyles(styles)(Input);
