/* eslint-disable max-len */
import React from 'react';

const CloudDown = () => (
    <svg viewBox="0 0 20 20">
        <path
            fillRule="evenodd"
            d="M13.995 14.056c-.551 0-.998-.45-.998-1.003 0-.555.447-1.004.998-1.004h.893c4.254 0 3.946-6.153.006-6.01-1.346-6.704-11.681-4.285-9.65 2.439C3.169 6.82.678 10.028 2.799 11.61c.662.495 1.559.472 2.354.452 1.007-.025 1.852-.046 1.852.99 0 1.075-1.036 1.046-2.189 1.014-.2-.005-.404-.011-.607-.011-3.331 0-5.385-3.572-3.477-6.304.912-1.304 2.342-1.627 2.342-1.627.659-5.13 6.49-7.84 10.807-4.936 1.854 1.248 2.557 3.07 2.557 3.07a5.04 5.04 0 0 1 2.66 1.938c2.143 3.076.478 7.859-5.103 7.859zm-3.95-8.028l.005.001a.02.02 0 0 0 .005.001l.006-.001a.02.02 0 0 1 .005 0h-.022zm-.985 10.49l-.004-6.81c0-.38.288-.708.65-.708h.558c.363 0 .676.32.676.7v6.848l1.552-1.47a.62.62 0 0 1 .457-.201c.176 0 .341.071.465.201l.394.413c.124.13.192.302.192.487a.7.7 0 0 1-.192.487l-3.341 3.334a.638.638 0 0 1-.468.201.638.638 0 0 1-.466-.201l-3.341-3.334A.7.7 0 0 1 6 15.978c0-.185.069-.357.192-.487l.395-.413a.643.643 0 0 1 .935 0l1.538 1.44z"
            clipRule="evenodd"
        />
    </svg>
);

export default CloudDown;
