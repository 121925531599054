import { createSelector } from 'reselect';
import {
    getFormValues,
    getFormInitialValues,
    getFormSyncErrors,
} from 'redux-form/immutable';
import { safeGet } from '@flowhealth/utils';
import { isFunction } from 'lodash';


export default (reducer, property, defaultValue) => createSelector(
    state => {
        const { [reducer]: immutableReducer } = state;
        if (!immutableReducer) return immutableReducer;
        if (Array.isArray(property)) {
            return immutableReducer.getIn ? immutableReducer.getIn(property) : safeGet(immutableReducer, property);
        }
        return immutableReducer.get ? immutableReducer.get(property) : safeGet(immutableReducer, property);
    },
    value => (value && value.toJS ? value.toJS() : (value || defaultValue)),
);


export const getFlowFormValues = form => {
    const formValues = getFormValues(form);

    return createSelector(
        formValues,
        value => (value && value.toJS ? value.toJS() : value),
    );
};

export const getFlowFormInitialValues = form => {
    const formValues = getFormInitialValues(form);

    return createSelector(
        formValues,
        value => (value && value.toJS ? value.toJS() : value),
    );
};

export const getFlowSyncErrors = form => {
    const formValues = getFormSyncErrors(form);

    return createSelector(
        formValues,
        values => (values && values.toJS ? values.toJS() : values),
    );
};


export const convertToMutable = data => {
    if (isFunction(data.toJS)) return data.toJS();
    return data;
};
