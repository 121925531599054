import React, { memo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import dayjs from 'dayjs';
import isFunction from 'lodash/isFunction';
import withStyles from '@material-ui/core/styles/withStyles';

import FlowIcon from 'components/FlowIcon';
import { ICONS_TYPES } from 'components/FlowIcon/constants';


const styles = ({ palette }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 12px',
        fontSize: 14,
        lineHeight: 1.43,
        color: palette.coal.tags,
        background: palette.ash[300],
        borderRadius: 4,
    },
    closeIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 6,
        borderRadius: '50%',
        '& svg': {
            fill: palette.coal[500],
        },
        '&:hover': {
            backgroundColor: palette.ash.main,
        },
    },
});


const FlowChip = props => {
    const { classes, className, label, onClick, onDelete, loading } = props;

    const getLabel = () => {
        if (loading) {
            return (
                <FlowIcon
                    size={20}
                    type={ICONS_TYPES.threeDotsLoading}
                />
            );
        }

        return label instanceof dayjs
            ? label.toISOString()
            : label;
    };

    const getIcon = () => {
        if (!isFunction(onDelete) || loading) return undefined;
        return (
            <FlowIcon
                className={classes.closeIcon}
                type={ICONS_TYPES.close}
                size={14}
                onClick={onDelete}
            />
        );
    };

    return (
        <div onClick={onClick} className={cn(classes.root, className)}>
            {getLabel()}
            {getIcon()}
        </div>
    );
};

FlowChip.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    onDelete: PropTypes.func,
    onClick: PropTypes.func,
};


export default compose(
    withStyles(styles),
    memo,
)(FlowChip);
