import { set } from 'immutable';
import { createReducer, safeGet } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';


const initialState = {
    order: undefined,
    loading: false,
    searchError: undefined,
};

const setLoading = value => state => set(state, 'loading', value);

const resetValue = value => state => set(state, value, undefined);

const setOrder = (state, { payload = {} } = {}) => set(
    state, 'order', safeGet(payload, 'order', payload),
);

const setSearchError = (state, { payload = {} } = {}) => set(
    state, 'searchError', safeGet(payload, 'searchError'),
);

const setPatchError = (state, { payload = {} } = {}) => set(
    state, 'patchError', safeGet(payload, 'patchError'),
);

const resetState = () => ({ ...initialState });


export default createReducer(initialState, {
    [ACTION_TYPES.RESET_STATE]: resetState,

    [ACTION_TYPES.SET_LOADING]: setLoading(),

    [ACTION_TYPES.SEARCH_ORDER_REQUEST]: setLoading(true),
    [ACTION_TYPES.SEARCH_ORDER_SUCCESS]: [setOrder, setLoading(false)],
    [ACTION_TYPES.SEARCH_ORDER_FAILURE]: [setSearchError, setLoading(false)],

    [ACTION_TYPES.PATCH_ORDER_REQUEST]: resetValue('patchError'),
    [ACTION_TYPES.PATCH_ORDER_SUCCESS]: setOrder,
    [ACTION_TYPES.PATCH_ORDER_FAILURE]: setPatchError,
});
