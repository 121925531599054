export const FLOW_DIALOG_KEY = 'flowDialog';

const DIALOG_WIDTH_SMALL = 'small';
const DIALOG_WIDTH_SMALLEST = 'smallest';
const DIALOG_WIDTH_MEDIUM = 'medium';
const DIALOG_WIDTH_BIG = 'big';

export const OPEN_FLOW_DIALOG = `OPEN_FLOW_DIALOG__${FLOW_DIALOG_KEY}`;
export const CLOSE_FLOW_DIALOG = `CLOSE_FLOW_DIALOG__${FLOW_DIALOG_KEY}`;

export const TOGGLE_IS_CONFIRM_OPEN = `TOGGLE_IS_CONFIRM_OPEN__${FLOW_DIALOG_KEY}`;

export const DIALOG_WIDTH = {
    [DIALOG_WIDTH_SMALL]: DIALOG_WIDTH_SMALL,
    [DIALOG_WIDTH_SMALLEST]: DIALOG_WIDTH_SMALLEST,
    [DIALOG_WIDTH_MEDIUM]: DIALOG_WIDTH_MEDIUM,
    [DIALOG_WIDTH_BIG]: DIALOG_WIDTH_BIG,
};
