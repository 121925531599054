import { createMuiTheme } from '@material-ui/core/styles';
import 'styles/fonts.css';
import { theme as uiTheme } from '@flowhealth/ui-components';

import overrides from './overrides';
import { typography } from './typography';

const { palette } = uiTheme;

const theme = createMuiTheme({
    palette,
    typography,
    overrides: overrides(palette),
});


export default theme;
