import React from 'react';
import Helmet from 'react-helmet';


class MetaData extends React.Component {
    render() {
        return (
            <Helmet titleTemplate="%s - Flow Health" defaultTitle="Flow Health">
                <html lang="en-US" />
                <meta charSet="utf-8" />
                <meta name="description" content="Flow Health" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"
                    data-react-helmet="true"
                />
            </Helmet>
        );
    }
}

export default MetaData;
