import {
    GET_VALUES_REQUEST,
    GET_VALUES_SUCCESS,
    GET_VALUES_FAILURE,
    GET_VALUES_BULK_REQUEST,
    GET_VALUES_BULK_SUCCESS,
    GET_VALUES_BULK_FAILURE,
} from './constants';

export const getValuesRequest = payload => ({
    type: GET_VALUES_REQUEST, payload,
});

export const getValuesSuccess = payload => ({
    type: GET_VALUES_SUCCESS, payload,
});

export const getValuesFailure = payload => ({
    type: GET_VALUES_FAILURE, payload,
});

export const getValuesBulkRequest = payload => ({
    type: GET_VALUES_BULK_REQUEST, payload,
});

export const getValuesBulkSuccess = payload => ({
    type: GET_VALUES_BULK_SUCCESS, payload,
});

export const getValuesBulkFailure = payload => ({
    type: GET_VALUES_BULK_FAILURE, payload,
});
