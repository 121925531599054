import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import { isNil, camelCase, isFunction } from 'lodash';

import FlowSimpleIcon from 'components/FlowIcon/FlowSimpleIcon';

import chain from 'utils/chain';
import { capitalize } from 'utils/stringFormats';

import { ReactComponent as Complete } from 'icons/Complete.svg';
import { ReactComponent as Attention } from 'icons/Attention.svg';

import icon_done from 'imgs/icon_done2.svg';

import { COMPLEXITY_LEVELS } from '../constants';


const styles = ({ palette } = {}) => ({
    root: {
        '&.hidden': { display: 'none' },
    },
    action: {
        color: palette.naviBlue.dark,
        userSelect: 'none',
        fontSize: 14,
        fontWeight: 600,
    },
    label: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '1.33',
        color: palette.coal[500],
        '& .complexity': {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
        },
        '& .icon': {
            position: 'absolute',
            left: -28,
        },
        '& .tooWeak': {
            color: palette.red.main,
            '& svg': { fill: palette.red.main },
        },
        '& .notSecure': {
            color: palette.gold.main,
            '& svg': { fill: palette.gold.main },
        },
        '& .good': {
            color: palette.green[300],
            '& svg': { fill: palette.green[300] },
        },
        '& .perfect': {
            color: palette.green.main,
            '& svg': { fill: palette.green.main },
        },
        '& .required': {
            marginLeft: 4,
            color: palette.red[700],
        },
    },
    input: {
        position: 'relative',
        '& input': {
            width: '100%',
            height: 40,
            padding: '10px 44px 10px 16px',
            border: `solid 1px ${palette.ash[400]}`,
            borderRadius: 4,
            backgroundColor: palette.white.main,
            color: palette.coal.main,
            '@media (max-width:480px)': { height: 44 },
            '&.invalid': { border: `solid 2px ${palette.red.main}` },
            '&:focus': {
                outline: 'none',
                border: `solid 2px ${palette.naviBlue.dark}`,
                caretColor: palette.naviBlue.dark,
            },
            '&:disabled': {
                backgroundColor: palette.ash[300],
                borderColor: palette.ash[300],
                color: palette.coal.tags,
            },
            '&::placeholder': { color: palette.coal[500] },
        },
        '& img': {
            position: 'absolute',
            top: 10,
            right: 12,
        },
    },
    bars: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        height: 4,
        marginTop: 4,
        '& div': {
            width: '24%',
            borderRadius: 4,
            backgroundColor: palette.ash[300],
        },
        '& .tooWeak': { backgroundColor: palette.red.main },
        '& .notSecure': { backgroundColor: palette.gold.main },
        '& .good': { backgroundColor: palette.green[300] },
        '& .perfect': { backgroundColor: palette.green.main },
    },
});


class Input extends React.PureComponent {
    handleChange = event => {
        const { onChange, config } = this.props;
        if (isFunction(onChange)) {
            onChange({ id: config.id, value: event.target.value });
        }
    };

    handleFocus = event => {
        const { onFocus, config } = this.props;
        if (isFunction(onFocus)) {
            onFocus({ id: config.id, value: event.target.value });
        }
    };

    get isComplexity() {
        const { complexity } = this.props;
        return !isNil(complexity) && complexity >= 0 && complexity <= 100;
    }

    get complexityClass() {
        const { complexity } = this.props;
        switch (true) {
        case complexity > 0 && complexity < 33: return COMPLEXITY_LEVELS.tooWeak;
        case complexity >= 33 && complexity < 66: return COMPLEXITY_LEVELS.notSecure;
        case complexity >= 66 && complexity < 100: return COMPLEXITY_LEVELS.good;
        case complexity === 100: return COMPLEXITY_LEVELS.perfect;
        default: return null;
        }
    }

    get bars() {
        const { complexity, classes } = this.props;
        if (!this.isComplexity) return null;
        const complexityIdx = Math.floor(complexity * 3 / 100);
        const bars = Array.from({ length: 4 }, (_, idx) => (
            <div
                key={idx}
                className={cn({
                    [camelCase(this.complexityClass)]: this.isComplexity && complexityIdx >= idx,
                })}
            />
        ));
        return <div className={classes.bars}>{bars}</div>;
    }

    get field() {
        const { classes, value, config = {}, touched, valid, onKeyDown, disabled, dataTestId } = this.props;
        return (
            <div className={classes.input}>
                <input
                    className={cn({
                        invalid: touched && !valid,
                    })}
                    value={value}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onKeyDown={onKeyDown}
                    autoComplete="off"
                    disabled={disabled}
                    data-tid={dataTestId}
                    {...config}
                />
                {this.bars}
                {this.valid}
            </div>
        );
    }

    get valid() {
        const { touched, valid, shouldShowIcon } = this.props;
        if (!touched || !valid) return null;
        if (shouldShowIcon) return <img src={icon_done} alt="done" />;
        return undefined;
    }

    get complexityIcon() {
        switch (this.complexityClass) {
        case COMPLEXITY_LEVELS.tooWeak:
        case COMPLEXITY_LEVELS.notSecure: return Attention;
        case COMPLEXITY_LEVELS.good:
        case COMPLEXITY_LEVELS.perfect: return Complete;
        default: return null;
        }
    }

    get complexityLabel() {
        if (!this.isComplexity) return null;

        return (
            <div className={cn('complexity', camelCase(this.complexityClass))}>
                {this.complexityIcon && (
                    <FlowSimpleIcon
                        className="icon"
                        icon={this.complexityIcon}
                        size={20}
                        color={null}
                    />
                )}
                {this.complexityClass
                && chain(
                    this.complexityClass,
                    val => val.replace('-', ' '),
                    capitalize,
                )}
            </div>
        );
    }

    render() {
        const { classes, className, config, required, hidden, actionLabel, actionOnclick } = this.props;

        return (
            <div className={cn(classes.root, className, { hidden })}>
                <label htmlFor={config.id} className={classes.label}>
                    <span>
                        {config.label}
                        {required && <span className="required">*</span>}
                    </span>
                    <span onClick={actionOnclick} className={classes.action}>
                        {actionLabel}
                    </span>
                    {this.complexityLabel}
                </label>
                {this.field}
            </div>
        );
    }
}

export default withStyles(styles)(Input);
