import isString from 'lodash/isString';

export const downloadFile = (url, readAsText = false) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = function onLoad() {
        const reader = new FileReader();
        reader.onloadend = function onLoadEnd() {
            resolve(reader.result);
        };
        reader.onerror = reject;
        if (readAsText) {
            reader.readAsText(xhr.response);
        } else {
            reader.readAsDataURL(xhr.response);
        }
    };

    xhr.open('GET', url);
    xhr.onerror = reject;
    xhr.responseType = 'blob';
    xhr.send();
});

export default async (url, name) => {
    if (!url || !isString(url)) return;

    const data = await downloadFile(url);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = data;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(data);
};
