export const WEBSOCKET_CONNECT_REQUEST = 'WEBSOCKET_CONNECT_REQUEST';
export const WEBSOCKET_CONNECT_SUCCESS = 'WEBSOCKET_CONNECT_SUCCESS';
export const WEBSOCKET_CONNECT_FAILURE = 'WEBSOCKET_CONNECT_FAILURE';

export const WEBSOCKET_DISCONNECT = 'WEBSOCKET_DISCONNECT';

export const WEBSOCKET_SUBSCRIBE = 'WEBSOCKET_SUBSCRIBE';
export const WEBSOCKET_UNSUBSCRIBE = 'WEBSOCKET_UNSUBSCRIBE';

export const WEBSOCKET_SEND_MESSAGE = 'WEBSOCKET_SEND_MESSAGE';
export const WEBSOCKET_GET_MESSAGE = 'WEBSOCKET_GET_MESSAGE';

// export const WEBSOCKET_CONNECTING = 'WEBSOCKET_CONNECTING';

export const WEBSOCKET_FAILURE = 'WEBSOCKET_FAILURE';
