/* eslint-disable max-len */
import React from 'react';


const ClockBack = () => (
    <svg viewBox="0 0 20 20">
        <path d="M10.035 8C9.48 8 9.03 8.448 9.03 9v3h5.021c.555 0 1.005-.5 1.005-1.052v-.027c0-.552-.45-.921-1.005-.921H11.04V9c0-.552-.45-1-1.004-1m6.465 3c-.276 0-.498.224-.524.5C15.699 14.323 13.123 17 10 17c-3.308 0-6-2.692-6-6s2.692-6 6-6v1.647c0 .155.17.251.303.171l3.982-2.39c.324-.194.324-.663 0-.857l-3.982-2.39c-.133-.08-.303.017-.303.172V3c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.269 0 7.757-2.8 7.988-7.5.013-.276-.212-.5-.488-.5h-1z" />
    </svg>
);

export default ClockBack;
