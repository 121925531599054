/* eslint-disable max-len */
import React from 'react';


const QRCodeSmall = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M6.571 2.4c0-.22-.179-.4-.4-.4H2.4c-.22 0-.4.18-.4.4v3.771c0 .221.18.4.4.4h3.771c.221 0 .4-.179.4-.4V2.4zM18 2.4c0-.22-.18-.4-.4-.4h-3.772c-.22 0-.4.18-.4.4v3.771c0 .221.18.4.4.4H17.6c.22 0 .4-.179.4-.4V2.4zM2 13.828c0-.22.18-.4.4-.4h3.771c.221 0 .4.18.4.4V17.6c0 .22-.179.4-.4.4H2.4c-.22 0-.4-.18-.4-.4v-3.772zm16 0c0-.22-.18-.4-.4-.4h-.38c-.027 0-.054-.003-.081-.007-.054-.009-.108-.013-.164-.013h-3.147c-.22 0-.4-.18-.4-.4V6.97c0-.22-.179-.4-.4-.4h-1.874c-.006 0-.011.005-.011.012 0 .006-.005.01-.011.01H6.98c-.22 0-.4.18-.4.4v1.472c0 .221.18.4.4.4h3.763c.22 0 .4.18.4.4v1.471c0 .221-.18.4-.4.4H6.98c-.22 0-.4.18-.4.4v1.472c0 .22.18.4.4.4h3.758c.221 0 .4.179.4.4v1.47c0 .222.18.4.4.4h3.776c.221 0 .4.18.4.4V17.6c0 .22.18.4.4.4H17.6c.22 0 .4-.18.4-.4v-3.772zM4.286 9.258c0-.222.179-.4.4-.4H6.17c.221 0 .4.178.4.4v1.485c0 .22-.179.4-.4.4H4.686c-.221 0-.4-.18-.4-.4V9.257zm13.714 0c0-.222-.18-.4-.4-.4h-1.486c-.22 0-.4.178-.4.4v1.485c0 .22.18.4.4.4H17.6c.22 0 .4-.18.4-.4V9.257zM8.857 2.4c0-.22.18-.4.4-.4h1.486c.22 0 .4.18.4.4v1.486c0 .22-.18.4-.4.4H9.257c-.22 0-.4-.18-.4-.4V2.4zm2.286 13.714c0-.22-.18-.4-.4-.4H9.257c-.22 0-.4.18-.4.4V17.6c0 .22.18.4.4.4h1.486c.22 0 .4-.18.4-.4v-1.486z" clipRule="evenodd" />
    </svg>
);

export default QRCodeSmall;
