/* eslint-disable max-len */
import React from 'react';


const Check = () => (
    <svg viewBox="0 0 14 11">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.960881 4.62976C1.25377 4.33687 1.72865 4.33687 2.02154 4.62976L5.32562 7.93384L12.4641 0.795353C12.757 0.50246 13.2319 0.50246 13.5248 0.795353C13.8177 1.08825 13.8177 1.56312 13.5248 1.85601L5.85595 9.52483L5.32562 10.0552L4.79529 9.52483L0.960881 5.69042C0.667988 5.39753 0.667988 4.92265 0.960881 4.62976Z" />
    </svg>
);

export default Check;
