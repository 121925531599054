import { all, call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { safeGet } from '@flowhealth/utils';
import API from '@flowhealth/api';
import { delay } from 'redux-saga';

import { openSuccessMessage } from 'components/Message/actions';

import { errorMessage } from 'containers/Errors/handleErrors';

import { setPatients } from '../../../store/actions';

import * as ACTION_TYPES from './constants';
import {
    addPatientInsuranceRequestSuccess,
    addPatientInsuranceRequestFailure,
    getPhotosRequestSuccess,
    getPhotosRequestFailure,
} from './actions';

function* getPhotos({ payload: { form, insurance } }) {
    try {
        const { photo_front, photo_back } = insurance;

        const data = { photoFrontInitial: undefined, photoBackInitial: undefined };

        if (photo_front) {
            const response = yield call(API.getInstance().getGraph, photo_front);
            data.photoFrontInitial = response.url;
        }

        if (photo_back) {
            const response = yield call(API.getInstance().getGraph, photo_back);
            data.photoBackInitial = response.url;
        }

        yield put(getPhotosRequestSuccess({ form, data }));
    } catch (e) {
        yield put(getPhotosRequestFailure({ form, error: errorMessage(e) }));
    }
}

function* addPatientInsurance({ payload: { form, data, photoFront, photoBack, redirectUrl } = {} } = {}) {
    try {
        if (photoFront) {
            const fileObj = yield call(API.getInstance().newFile, {
                mime_type: safeGet(photoFront, 'type', 'undefined'),
                title: safeGet(photoFront, 'name', ''),
                kind: '',
            });
            const uploadUrl = fileObj?.upload_url;
            const fileId = fileObj?.id;

            yield call(API.getInstance().uploadFile, uploadUrl, photoFront);
            for (let i = 0; i < data.length; i++) {
                data[i].photo_front = fileId;
            }
        }

        if (photoBack) {
            const fileObj = yield call(API.getInstance().newFile, {
                mime_type: safeGet(photoBack, 'type', 'undefined'),
                title: safeGet(photoBack, 'name', ''),
                kind: '',
            });
            const uploadUrl = fileObj?.upload_url;
            const fileId = fileObj?.id;

            yield call(API.getInstance().uploadFile, uploadUrl, photoBack);
            for (let i = 0; i < data.length; i++) {
                data[i].photo_back = fileId;
            }
        }

        yield call(API.getInstance().createGraph, data);
        yield delay(1000);
        yield put(addPatientInsuranceRequestSuccess());
        yield put(openSuccessMessage('Insurance created'));
        yield put(setPatients({ patients: undefined }));
        yield put(push(redirectUrl));
    } catch (e) {
        yield put(addPatientInsuranceRequestFailure({ form, error: errorMessage(e) }));
    }
}

function* Saga() {
    yield all([
        takeEvery(ACTION_TYPES.INSURANCE_REQUEST, addPatientInsurance),
        takeEvery(ACTION_TYPES.GET_PHOTOS_REQUEST, getPhotos),
    ]);
}

export default Saga;
