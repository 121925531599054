/* eslint-disable max-len */
import React from 'react';

const PlusInCircle = () => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.9998 25.3334C20.2591 25.3334 25.3332 20.2593 25.3332 14.0001C25.3332 7.74085 20.2591 2.66675 13.9998 2.66675C7.74061 2.66675 2.6665 7.74085 2.6665 14.0001C2.6665 20.2593 7.74061 25.3334 13.9998 25.3334ZM13.9998 27.3334C21.3636 27.3334 27.3332 21.3639 27.3332 14.0001C27.3332 6.63628 21.3636 0.666748 13.9998 0.666748C6.63604 0.666748 0.666504 6.63628 0.666504 14.0001C0.666504 21.3639 6.63604 27.3334 13.9998 27.3334ZM6.33317 14.0001C6.33317 13.4478 6.78089 13.0001 7.33317 13.0001H12.9998V7.33341C12.9998 6.78113 13.4476 6.33341 13.9998 6.33341C14.5521 6.33341 14.9998 6.78113 14.9998 7.33341V13.0001H20.6665C21.2188 13.0001 21.6665 13.4478 21.6665 14.0001C21.6665 14.5524 21.2188 15.0001 20.6665 15.0001H14.9998V20.6667C14.9998 21.219 14.5521 21.6667 13.9998 21.6667C13.4476 21.6667 12.9998 21.219 12.9998 20.6667V15.0001H7.33317C6.78089 15.0001 6.33317 14.5524 6.33317 14.0001Z" fill="#2F8FE3" />
    </svg>
);

export default PlusInCircle;
