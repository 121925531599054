import { fromJS } from 'immutable';

import {
    WEBSOCKET_CONNECT_REQUEST,
    WEBSOCKET_CONNECT_SUCCESS,
    WEBSOCKET_CONNECT_FAILURE,

    WEBSOCKET_DISCONNECT,

    WEBSOCKET_SUBSCRIBE,
    WEBSOCKET_UNSUBSCRIBE,

    WEBSOCKET_FAILURE,
} from './constants';

const initialState = fromJS({
    error: null,
    open: false,
    scheduledOpen: false,
});


const messageReducer = (state = initialState, { type, payload }) => {
    switch (type) {
    case WEBSOCKET_CONNECT_REQUEST: {
        return state
            .set('scheduledOpen', true);
    }

    case WEBSOCKET_CONNECT_SUCCESS: {
        return state
            .set('scheduledOpen', false)
            .set('open', true);
    }

    case WEBSOCKET_CONNECT_FAILURE: {
        return state
            .set('scheduledOpen', false)
            .set('open', false);
    }

    case WEBSOCKET_DISCONNECT: {
        return state
            .set('open', false);
    }


    case WEBSOCKET_SUBSCRIBE: {
        return state; // todo describe subscription
    }

    case WEBSOCKET_UNSUBSCRIBE: {
        return state; // todo describe subscription
    }


    case WEBSOCKET_FAILURE: {
        return state
            .set('error', payload);
    }

    default:
        return state;
    }
};

export default messageReducer;
