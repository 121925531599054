/* eslint-disable max-len */
import React from 'react';


const AlignLeft = () => (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.7998 4.59997C2.7998 4.2686 3.06843 3.99997 3.3998 3.99997H16.5998C16.9312 3.99997 17.1998 4.2686 17.1998 4.59997V4.89997C17.1998 5.23134 16.9312 5.49997 16.5998 5.49997H3.3998C3.06843 5.49997 2.7998 5.23134 2.7998 4.89997V4.59997ZM3.3998 11C3.06843 11 2.7998 11.2686 2.7998 11.6V11.9C2.7998 12.2313 3.06843 12.5 3.3998 12.5H16.5998C16.9312 12.5 17.1998 12.2313 17.1998 11.9V11.6C17.1998 11.2686 16.9312 11 16.5998 11H3.3998ZM3.3998 14.5C3.06843 14.5 2.7998 14.7686 2.7998 15.1V15.4C2.7998 15.7313 3.06843 16 3.3998 16H11.3998C11.7312 16 11.9998 15.7313 11.9998 15.4V15.1C11.9998 14.7686 11.7312 14.5 11.3998 14.5H3.3998ZM3.3998 7.49997C3.06843 7.49997 2.7998 7.7686 2.7998 8.09997V8.39997C2.7998 8.73134 3.06843 8.99997 3.3998 8.99997H11.3998C11.7312 8.99997 11.9998 8.73134 11.9998 8.39997V8.09997C11.9998 7.7686 11.7312 7.49997 11.3998 7.49997H3.3998Z" fill="#788697" />
    </svg>
);

export default AlignLeft;
