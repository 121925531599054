import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
    AppBar,
    Toolbar,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { App } from '@flowhealth/app';

import User from 'containers/User';
import { makeSelectRole } from 'containers/User/selector';

import Logo from 'imgs/logo.svg';

import styles from './styles';


const getUserButton = () => {
    const token = App.getInstance().getToken();
    if (!token) return null;

    return <User />;
}

export class Header extends React.Component {
    get logo() {
        const { showLogo, classes } = this.props;
        if (!showLogo) return null;
        return (
            <Link to="/" className={classes.logoLink}>
                <img id="logoComponentId" className={classes.logo} src={Logo} alt="Flow Health" />
            </Link>
        );
    }

    render() {
        const { classes } = this.props;
        return (
            <AppBar position="sticky" className={classes.root}>
                <Toolbar className={classes.inner}>
                    {this.logo}
                    <div className={classes.right}>
                        {getUserButton()}
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    role: makeSelectRole(),
});

const withConnect = connect(mapStateToProps);

export default compose(
    withRouter,
    withConnect,
    withStyles(styles),
)(Header);
