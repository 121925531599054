import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = ({ palette }) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: palette.white.main,
        borderRadius: 4,
        boxShadow: `0 8px 16px 0 ${palette.gray.main}`,
        '@media (max-width:1048px)': {
            flexDirection: 'column-reverse',
        },
    },
    isFixedSize: {
        '@media (min-width:1048px)': {
            width: 1000,
            height: 652,
        },
    },
});


class Wrapper extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        isFixedSize: PropTypes.bool,
    };

    static defaultProps = {
        isFixedSize: false,
    };

    render() {
        const { children, classes, className, isFixedSize } = this.props;
        return <div className={cn(classes.root, className, { [classes.isFixedSize]: isFixedSize })}>{children}</div>;
    }
}

export default withStyles(styles)(Wrapper);
