import {
    WEBSOCKET_CONNECT_REQUEST,
    WEBSOCKET_CONNECT_SUCCESS,
    WEBSOCKET_CONNECT_FAILURE,

    WEBSOCKET_DISCONNECT,

    WEBSOCKET_SUBSCRIBE,
    WEBSOCKET_UNSUBSCRIBE,

    WEBSOCKET_SEND_MESSAGE,

    WEBSOCKET_GET_MESSAGE,

    WEBSOCKET_FAILURE,
} from './constants';


export const webSocketConnectRequest = ({ payload, meta }) => ({
    type: WEBSOCKET_CONNECT_REQUEST, payload, meta,
});

export const webSocketConnectSuccess = ({ payload, meta }) => ({
    type: WEBSOCKET_CONNECT_SUCCESS, payload, meta,
});

export const webSocketConnectFailure = ({ payload, meta }) => ({
    type: WEBSOCKET_CONNECT_FAILURE, payload, meta,
});

export const webSocketDisconnect = ({ payload, meta }) => ({
    type: WEBSOCKET_DISCONNECT, payload, meta,
});


export const webSocketSubscribe = ({ payload, meta }) => ({
    type: WEBSOCKET_SUBSCRIBE, payload, meta,
});

export const webSocketUnsubscribe = ({ payload, meta }) => ({
    type: WEBSOCKET_UNSUBSCRIBE, payload, meta,
});


export const webSocketGetMessage = ({ payload, meta }) => ({
    type: WEBSOCKET_GET_MESSAGE, payload, meta,
});

export const webSocketSendMessage = ({ payload, meta }) => ({
    type: WEBSOCKET_SEND_MESSAGE, payload, meta,
});


export const webSocketFailure = ({ payload, meta }) => ({
    type: WEBSOCKET_FAILURE, payload, meta,
});
