import {
    OPEN_FLOW_LOADER,
    CLOSE_FLOW_LOADER,
    RESET_FLOW_LOADER,
} from './constants';


export const openFlowLoader = item => ({
    type: OPEN_FLOW_LOADER, payload: item,
});

export const closeFlowLoader = item => ({
    type: CLOSE_FLOW_LOADER, payload: item,
});

export const resetFlowLoader = item => ({
    type: RESET_FLOW_LOADER, payload: item,
});
