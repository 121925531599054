import React, { createContext, memo, useEffect, useRef, useState, useContext, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config';


const FirebaseContext = createContext({
    app: null,
    removeConfig: null,
});

const FirebaseProvider = ({ children, firebaseConfig, onFetch }) => {
    const [, setState] = useState(0);
    const appRef = useRef(null);
    const remoteConfigRef = useRef(null);

    useEffect(() => {

        // forceUpdate to throw new object to value with actualized values of refs
        const forceUpdate = () => setState(prevValue => prevValue + 1);

        const app = initializeApp(firebaseConfig);
        const remoteConfig = getRemoteConfig(app);
        appRef.current = app;
        remoteConfigRef.current = remoteConfig;
        forceUpdate();

        fetchAndActivate({
            ...remoteConfig,
            settings: { ...remoteConfig.settings, minimumFetchIntervalMillis: 60 }
        })
            .finally(() => {
                onFetch(remoteConfig);
            })
            .finally(forceUpdate);
    }, [firebaseConfig, onFetch]);

    return (
        <FirebaseContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                app: appRef.current,
                removeConfig: remoteConfigRef.current,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

export default memo(FirebaseProvider);

export const useRemoteConfig = selector => {
    const context = useContext(FirebaseContext);

    if (!context) {
        throw new Error('Use it hook inside FirebaseProvider');
    }
    const { removeConfig } = context;

    return useMemo(() => selector(removeConfig), [removeConfig, selector]);
};
