import React, { useMemo } from 'react';
import isFunction from 'lodash/isFunction';
import { createUseStyles, Icon } from '@flowhealth/ui-components';
import { Close } from '@flowhealth/icons';
import { palette } from 'theme/palette';

import { useMobileBreakpoint } from 'utils/breakpoints';


const useStyles = createUseStyles(({ palette }) => ({
    wrap: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100%',
        zIndex: 5000,
        background: 'rgba(27, 33, 39, 0.75)',
    },
    closeWrap: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 8000,
    },
    modal: ({ isMobile }) => {
        const styles = {
            position: 'absolute',
            padding: '40px 24px 24px 24px',
            background: palette.white.main,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            zIndex: 9000,
        };


        if (isMobile) {
            styles['bottom'] = 0;
            styles['borderRadius'] = '13px 13px 0 0';
            styles['width'] = '100vw';
        } else {
            styles['top'] = '50%';
            styles['transform'] = 'translate(-50%, -50%)';
            styles['width'] = 480;
            styles['maxHeight'] = 288;
            styles['left'] = '50%';
            styles['top'] = '50%';
            styles['borderRadius'] = 13;
        }

        return styles;
    },
    close: {
        position: 'absolute !important',
        zIndex: 9002,

        top: 40,
        right: 40,
        cursor: 'pointer',

        width: 20,
        height: 20,
        background: palette.transparent,
        '& svg': {
            fill: palette.white.main,
        },
        '&:hover': {
            display: 'flex',
            alignItems: 'center',
            background: palette.ash[400],
            borderRadius: '50%',
        },
    },
}));


const Modal = ({
    onClose,
    children,
    hasCloseIcon,
}) => {
    const isMobile = useMobileBreakpoint();
    const classes = useStyles({ isMobile });

    const closeIcon = useMemo(() => {
        if (!isFunction(onClose)) return;
        return (
            <Icon
                id="ModalCloseId"
                className={classes.close}
                type={Close}
                color={palette.white.main}
                onClick={onClose}
                size={24}
            />
        );
    }, [classes.close, onClose]);

    return (
        <>
            <div className={classes.wrap} onClick={onClose} />
            <div>
                {hasCloseIcon && closeIcon}
                <div className={classes.modal}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Modal;
