import { isObject } from '@flowhealth/utils';
import { isEqual } from 'lodash';

export const mergeDataExpanded = (
    previous,
    current,
) => {
    // merge objects to add new keys
    if (!isObject(previous) || !isObject(current)) {
        return;
    }

    const merged = Object.assign({}, previous, current); // for some reason spread operator makes mistakes during merge

    return Object.fromEntries(
        Object.keys(merged).map(key => [
            key,
            typeof previous[key] === 'object' && typeof merged[key] === 'string'
                ? previous[key]
                : merged[key],
        ]),
    );
};

export const getObjectsDiff = (
    previous,
    current,
) => {
    if (!isObject(previous) || !isObject(current)) {
        return;
    }

    const merged = { ...previous, ...current };

    return Object.keys(merged).reduce((memo, key) => {
        if (previous[key] === current[key]) {
            return memo;
        }

        if (
            typeof previous[key] === 'object'
            && typeof current[key] === 'object'
            && isEqual(previous[key], current[key])
        ) {
            return memo;
        }

        return {
            ...memo,
            [key]: merged[key],
        };
    }, {});
};

export const getObjectFromStr = str => {
    try {
        return JSON.parse(str.replace(/^"|"$/g, '\''));
    } catch {
        return undefined;
    }
};

export const mergeObjects = (...objects) => objects.reduce((result, current) => {
    if (!isObject(current)) {
        return result;
    }

    return { ...result, ...current };
}, {});
