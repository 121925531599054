export const ADD_INSURANCE_PATH = '/add-insurance';
export const ADD_PROFILE_PATH = `/profiles/add`;
export const ADD_TEST_PATH = '/add-test';
export const APPLE_APP_SITE_ASSOCIATION = '/apple-app-site-association';
export const APPOINTMENTS_PATH = '/appointments';
export const APPOINTMENT_PATH = `${APPOINTMENTS_PATH}/:appointmentId`;
export const APPOINTMENT_QR_PATH = `${APPOINTMENT_PATH}/qr`;
export const APPOINTMENT_DECLINE_PATH = `${APPOINTMENT_PATH}/decline`;
export const APPOINTMENT_TAKE_TEST_PATH = `${APPOINTMENT_PATH}/take-test`;
export const BOOKING_PATH = '/booking';
export const BINAX_KIT_PATH = '/htk/binax';
export const CARESTART_KIT_PATH = '/htk/carestart';
export const CID_PATH = '/cid';
export const CONSENT_PATH = '/consent';
export const CONNECT_PATIENT_PATH = '/connect';
export const CONNECT_TO_PRACTICE_PATH = '/connect-to-practice';
export const CREATE_PATH = '/create';
export const COVID19_PCR_PATH = '/htk/covid19';
export const ENTER_CODE_PATH = '/enter-code';
export const ELECTRONIC_SIGNATURE_TERMS_PATH = '/electronic-signature-terms';
export const EXISTS_PATH = '/exists';
export const FLOW_FLEX_KIT_PATH = '/htk/flowflex';
export const HEALTHCARE_PROVIDER_USER_AGREEMENT_PATH = '/healthcare-provider-user-agreement';
export const HTK_CODE_EXISTANCE_PAGE = '/htk-code-existance';
export const HTK_CODE_PATH = '/htk-code';
export const HTK_PATH = '/htk';
export const INSURANCE_CREATE_PATH = `/insurance/create`;
export const INSURANCE_PATH = '/insurance';
export const INSURANCE_VIEW_PATH = `/insurance/view`;
export const INSURANCE_VIEW_BY_ID_PATH = `/insurance/view/:insuranceId`;
export const LINKED_PATH = '/linked';
export const LOGIN_PATH = '/login';
export const LUCIRA_KIT_PATH = '/htk/lucira';
export const MAILING_KIT_PATH = '/htk/mailing-kit';
export const MONKEY_POX_PATH = '/htk/monkeypox';
export const NOTICE_PRIVACE_PRACTICE_PATH = '/notice-of-privacy-practices';
export const ORDERS_PATH = '/orders'
export const ORDER_BY_ID_PATH = `/orders/:orderId?`;
export const ORDER_PACKAGE_INFO_PATH = '/order-saliva-kits';
export const ORDER_PACKAGE_SELECT_PATH = '/order-package/select';
export const ORDER_PACKAGE_SUCCESS_PATH = '/order-package/success';
export const PASSWORD_CREATE_PATH = '/password/create';
export const PATIENT_PROFILES_PATH = '/profiles';
export const PERSON_PATIENTS_PATH = `/profiles/:id/patients`;
export const PCR_ORDER_PATH = '/htk/prc-order-kit';
export const PRIVACY_POLICY_PATH = '/privacy-policy';
export const PORTAL_AGREEMENT_PATH = '/portal-agreement';
export const QUICKVUE_KIT_PATH = '/htk/quickvue';
export const REGISTER_PATH = '/register';
export const USER_ACCOUNT_PATH = '/account';
export const USER_SETTINGS_PATH = '/settings';
export const SELECT_PRACTICE_PATH = `/profiles/select-practice`;
export const SMS_TERMS_SERVICE_PATH = '/sms-terms-of-service';
export const TERMS_OF_SERVICE_PATH = '/terms-of-service';
export const STI_ORDER_BY_ID = '/sti/:orderId';
