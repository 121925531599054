import { humanName, capitalize, getArray, normalizeDate } from '@flowhealth/utils';

import { isUuid } from 'utils/isUuid';


export const isValid = config => config.fields.every((field, _index, array) => {
    if (field.notVisible) return true;
    if (!field.value && !field.config.required && array.some(el => el.config.required)) return true;

    return field.valid;
});

export const getInsurance = (patients, insuranceId) => (isUuid(insuranceId)
    ? patients
        .flatMap(patient => patient.insurances)
        .find(insurance => insurance.id === insuranceId)
    : null);

export const getPerson = (persons, insurance) => persons
    .find(person => person.insurances.find(i => i.id === insurance?.id));

export const getAllInsurances = patients => getArray(patients).flatMap(patient => patient?.insurances || []);

export const getPersons = patients => getArray(patients).reduce((persons, patient) => {
    const { person } = patient;
    const updatedPerson = persons.find(p => p.id === person?.id) || person;
    if (!updatedPerson) return persons;

    updatedPerson.patients = [
        ...getArray(updatedPerson.patients).filter(p => p.id !== patient.id), patient,
    ];
    updatedPerson.insurances = [
        ...getArray(updatedPerson.insurances)
            .filter(insurance => !getArray(patient?.insurances).map(i => i?.id).includes(insurance?.id)),
        ...getArray(patient.insurances).map(insurance => ({ ...insurance, practice: patient.practice })),
    ];

    return [...persons.filter(p => p.id !== updatedPerson.id), updatedPerson];
}, []);

const setPolicyHolder = (insurance, persons) => {
    if (insurance.relation === 'self') return 'Self';
    if (persons?.find(person => person.name?.given === insurance.policy_holder?.given
        && person.name?.family === insurance.policy_holder?.family)) {
        return humanName(insurance.policy_holder);
    }
    return 'Another Person';
};

export const prepareInitialData = (insurance, person, persons) => ([
    {
        id: 'person',
        value: humanName(person.name),
    },
    {
        id: 'policy_holder',
        value: setPolicyHolder(insurance, persons),
    },
    {
        id: 'given',
        value: insurance.policy_holder?.given,
    },
    {
        id: 'family',
        value: insurance.policy_holder?.family,
    },
    {
        id: 'birth_date',
        value: normalizeDate(insurance.holder_birth_date),
    },
    {
        id: 'relation',
        value: capitalize(insurance.relation),
    },
    {
        id: 'payor',
        value: insurance.payor?.name,
    },
    {
        id: 'member_id',
        value: insurance.member_id,
    },
    {
        id: 'group_number',
        value: insurance.group_number,
    },
]);
