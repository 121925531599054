
export const searchAll = async request => {
    const makeRequests = (from, count) => Promise.all(Array.from(
        { length: count },
        (_, index) => request(from + index),
    ));

    const { count, results } = await request(0);
    const resultsCount = results.length;
    if (resultsCount === count) {
        return results;
    }

    const COUNT_REQUESTS_IN_PACK = 5;
    const countRequests = Math.ceil((count - resultsCount) / resultsCount);
    const countPacksOfRequests = Math.ceil(countRequests / COUNT_REQUESTS_IN_PACK);

    const responses = [];
    for (let packNumber = 0; packNumber < countPacksOfRequests; packNumber++) {
        const from = COUNT_REQUESTS_IN_PACK * packNumber;
        const count = countRequests - from > COUNT_REQUESTS_IN_PACK ? COUNT_REQUESTS_IN_PACK : countRequests - from;

        // eslint-disable-next-line no-await-in-loop
        const currentResponses = await makeRequests(
            from + 1,
            count,
        );
        responses.push(...currentResponses);
    }

    for (const response of responses) {
        results.push(...response.results);
    }

    return results;
};
