import { put, takeLatest, call, all, takeEvery, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { safeGet } from '@flowhealth/utils';
import API from '@flowhealth/api';
import dayjs from 'dayjs';

import { webSocketSubscribe, webSocketUnsubscribe } from 'API/websocket/actions';
import { WEBSOCKET_GET_MESSAGE } from 'API/websocket/constants';

import { openErrorMessage } from 'components/Message/actions';

import { DATE_TIME_FORMAT } from 'utils/constants';

import { COPY_TO_PRACTICE_ERROR, SOCKET_WAIT_TIME, COPY_TO_PRACTICE_WS_TYPE } from '../../constants';

import * as ACTION_TYPES from './actionTypes';
import * as ACTIONS from './actions';
import { selectCopyPatientId, selectFields, selectKitBatch, selectKitBatchPractice } from './selectors';


function* getRefRanges({ payload }) {
    const { practice_id } = payload;

    try {
        const { panel_code } = yield select(selectKitBatch);
        const response = yield call(API.getInstance().search, {
            object: 'test_panel',
            filters: `code_sort:${panel_code}`,
        });

        const panelId = safeGet(response, 'results[0].id');

        if (panelId) {
            const refRange = yield call(API.getInstance().getReferenceRange, {
                practice_id,
                test_panel_id: panelId,
            });

            if (refRange?.id) {
                yield put(ACTIONS.getRefRangesSuccess({ refRange, panelId }));
            } else {
                yield put(ACTIONS.getRefRangesFailure());
            }
        } else {
            yield put(ACTIONS.getRefRangesFailure());
        }
    } catch (error) {
        yield put(ACTIONS.getRefRangesFailure());
    }
}

function* submitTest({ payload }) {
    const { patient, panelId, rangeId, file, range, onSuccess } = payload;
    const copyPatientId = yield select(selectCopyPatientId);

    if (copyPatientId) {
        yield delay(SOCKET_WAIT_TIME);

        const currentCopyPatientId = yield select(selectCopyPatientId);

        if (currentCopyPatientId) {
            yield put(ACTIONS.submitTestFailure({ error: COPY_TO_PRACTICE_ERROR }));
            yield put(openErrorMessage(COPY_TO_PRACTICE_ERROR));
            return;
        }
    }

    try {
        const batch = yield select(selectKitBatch);
        const { practice, location, kit_batch } = batch;
        const { appointment } = yield select(selectFields) ?? {};
        const fileObj = yield call(API.getInstance().newFile, {
            mime_type: safeGet(file, 'type', 'undefined'),
            title: safeGet(file, 'name', dayjs().format(DATE_TIME_FORMAT)),
            kind: '',
        });
        const uploadUrl = fileObj?.upload_url;
        const fileId = fileObj?.id;

        yield call(API.getInstance().uploadFile, uploadUrl, file);

        const data = {
            practice,
            location,
            range,
            file: fileId,
            object_type: 'collector_action',
            patient: patient?.id,
            panel: panelId,
            reference_range: rangeId,
            collector: patient?.role,
        };
        if (appointment) {
            data.appointment = appointment.id;
        } else {
            data.kit_batch = kit_batch.id;
        }
        yield call(API.getInstance().createGraph, data);

        yield put(ACTIONS.submitTestSuccess());
        onSuccess?.();
    } catch (error) {
        yield put(ACTIONS.submitTestFailure({ error }));
    }
}

function* copyToPractice({ payload = {} } = {}) {
    try {
        const { patient, practiceId } = payload;
        if (!patient?.practice) return;

        const copyPatient = yield call(API.getInstance().createGraph, {
            object_type: 'copy_patient',
            patient: patient.id,
            practice: patient.practice.id,
        });

        yield put(ACTIONS.copyToPracticeSuccess({ copyPatientId: copyPatient.id }));

        yield put(ACTIONS.wsSubscribe({ group: copyPatient.id }));

        yield call(API.getInstance().addGraphToEdgeBulk, copyPatient.id, 'to_practices', [practiceId]);

        yield call(API.getInstance().patchGraph, copyPatient.id, { status: 'processing' });
    } catch (error) {
        yield put(ACTIONS.copyToPracticeFailure({ error }));
    }
}

function* wsSubscribe({ payload: { group } = {} } = {}) {
    if (!group) return;

    yield put(webSocketSubscribe({
        payload: [{
            group,
            type: [COPY_TO_PRACTICE_WS_TYPE],
        }],
    }));
}

function* wsUnsubscribe({ payload: { group } = {} } = {}) {
    if (!group) return;

    yield put(webSocketUnsubscribe({
        payload: [{
            group,
            type: [COPY_TO_PRACTICE_WS_TYPE],
        }],
    }));
}

function* onWS({ payload: { message } } = {}) {
    if (message?.type !== COPY_TO_PRACTICE_WS_TYPE) return;

    const copyPatientId = yield select(selectCopyPatientId);
    const batchPractice = yield select(selectKitBatchPractice);
    const { group, delta } = message;

    if (!delta || copyPatientId !== group) return;

    const { failed_practices } = delta;
    const isFailed = failed_practices.includes(batchPractice);

    if (!isFailed) {
        const copiedPatientResponse = yield call(API.getInstance().getGraph, group, 'copy_patients');
        const copiedPatient = safeGet(copiedPatientResponse, 'copy_patients.results[0]');

        yield put(ACTIONS.setPatient(copiedPatient));
        yield put(ACTIONS.copyToPracticeFinished({ copyPatientId: undefined }));
    } else {
        yield put(openErrorMessage(COPY_TO_PRACTICE_ERROR));
    }

    yield put(ACTIONS.wsUnsubscribe({ group }));
}

export default function* Saga() {
    yield all([
        takeLatest(ACTION_TYPES.GET_REF_RANGES_REQUEST, getRefRanges),
        takeLatest(ACTION_TYPES.SUBMIT_TEST_REQUEST, submitTest),
        takeLatest(ACTION_TYPES.COPY_TO_PRACTICE_REQUEST, copyToPractice),
        takeEvery(WEBSOCKET_GET_MESSAGE, onWS),
        takeEvery(ACTION_TYPES.WS_SUBSCRIBE, wsSubscribe),
        takeEvery(ACTION_TYPES.WS_UNSUBSCRIBE, wsUnsubscribe),
    ]);
}
