import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SpinLoader } from '@flowhealth/ui-components';

import Button from 'components/Button';

import Form from 'containers/Auth/components/Form';

import AdaptivePopup from '../AdaptivePopup';

import submit from './submit';
import useFields from './useFields';
import { isValid, getPersons } from './utils';
import { INSURANCE_FORM } from './constants';


const styles = ({ palette = {} } = {}) => ({
    root: {
        width: '100%',
        margin: '0 auto',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    inner: {
        width: 660,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        '@media (max-width:800px)': {
            width: '100%',
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
    form: {
        width: '100%',
        margin: 0,
    },
    button: {
        marginTop: 24,
        width: '100%',
        textTransform: 'capitalize',
        '& > button': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            fontSize: 16,
            lineHeight: 1.5,
            height: 48,
            fontWeight: 500,
            color: palette.white.main,
        },
    },
    flowPhone: {
        '& .intl-tel-input': { height: 44 },
        '& .selected-flag': { width: '44px !important' },
    },
    flowSelect: {
        '& div[id$="inputContainer"]': {
            height: 44,
            display: 'flex',
            alignItems: 'center',
        },
        '& span[id$="label"]': {
            display: 'flex',
            alignSelf: 'flex-start',
            marginBottom: 2,
            fontSize: 12,
            fontWeight: 500,
            lineHeight: 1.33,
            letterSpacing: 'normal',
            textTransform: 'none !important',
            color: palette.coal[500],
        },
    },
    flowDateTime: {
        '& div.inputContainer': {
            height: 44,
            display: 'flex',
            alignItems: 'center',
        },
    },
});

const createStyleMap = classes => ({
    flowPhone: classes.flowPhone,
    flowSelect: classes.flowSelect,
    flowDateTime: classes.flowDateTime,
    text: classes.text,
});

function AddInsurance({
    classes,
    getPatient,
    resetState,

    setFormFieldValue,
    setFormFieldToched,

    onAddInsurance,
    onClose,
    onBack,

    formData,
    loading,
    user,
    error,
    isView,
    patients,
}) {
    const persons = useMemo(() => getPersons(patients), [patients]);

    useEffect(() => {
        if (!patients) getPatient({ userId: user?.id });
    }, [patients, getPatient]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => () => resetState(), []); // eslint-disable-line react-hooks/exhaustive-deps

    const checkView = useCallback(fieldId => (typeof isView === 'function' ? isView(fieldId) : isView), [isView]);

    const [photoFront, setPhotoFront] = useState();
    const [photoBack, setPhotoBack] = useState();
    const { photoFrontInitial, photoBackInitial } = formData || {};
    const config = useFields(
        formData,
        createStyleMap(classes),
        persons,
        checkView,
        {
            photo_front: { value: photoFront, onChange: setPhotoFront, file: photoFrontInitial },
            photo_back: { value: photoBack, onChange: setPhotoBack, file: photoBackInitial },
        },
        setFormFieldValue,
        setFormFieldToched,
    );

    const handleAddInsurance = () => {
        if (!patients) return;
        const { insurances } = persons.find(person => person.id === formData.person.value[0]?.code) || {};
        onAddInsurance({
            form: INSURANCE_FORM,
            data: submit({ position: insurances?.length || 1, persons, fields: formData }),
            photoFront,
            photoBack,
        });
    };

    return (
        <AdaptivePopup
            title="Insurance"
            onClose={onClose}
            onBackIconClick={onBack}
            hasBackIcon
        >
            <div className={classes.root}>
                <div className={classes.inner}>
                    <Form
                        className={classes.form}
                        config={config}
                        loading={loading}
                        error={error}
                        showLogo={false}
                    />
                    <Button
                        className={classes.button}
                        size={Button.BUTTON_SIZES.large}
                        color={Button.BUTTON_COLORS.blue}
                        onClick={handleAddInsurance}
                        disabled={!isValid(config) || loading}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
            {loading && <SpinLoader className={classes.loader} />}
        </AdaptivePopup>
    );
}


export default withStyles(styles)(AddInsurance);
