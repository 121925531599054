import { theme } from '@flowhealth/ui-components';

const BUTTON_COLOR_GRAY = 'gray';
const BUTTON_COLOR_GOLD = 'gold';
const BUTTON_COLOR_GREEN = 'green';
const BUTTON_COLOR_BLUE = 'blue';
const BUTTON_COLOR_NAVI_BLUE_300 = 'naviBlue300';
const BUTTON_COLOR_RED = 'red';
const BUTTON_COLOR_RED_30 = 'red30';
const BUTTON_COLOR_TRANSPARENT = 'transparent';
const BUTTON_COLOR_TRANSPARENT_BLUE = 'transparentBlue';
const BUTTON_COLOR_TRANSPARENT_NAVI_BLUE_300 = 'transparentNaviBlue300';

const BUTTON_SIZE_LARGE = 'large';
const BUTTON_SIZE_REGULAR = 'regular';
const BUTTON_SIZE_SMALL = 'small';

const { palette } = theme;

export const BUTTON_COLORS = {
    [BUTTON_COLOR_GRAY]: BUTTON_COLOR_GRAY,
    [BUTTON_COLOR_GOLD]: BUTTON_COLOR_GOLD,
    [BUTTON_COLOR_GREEN]: BUTTON_COLOR_GREEN,
    [BUTTON_COLOR_BLUE]: BUTTON_COLOR_BLUE,
    [BUTTON_COLOR_NAVI_BLUE_300]: BUTTON_COLOR_NAVI_BLUE_300,
    [BUTTON_COLOR_RED]: BUTTON_COLOR_RED,
    [BUTTON_COLOR_RED_30]: BUTTON_COLOR_RED_30,
    [BUTTON_COLOR_TRANSPARENT]: BUTTON_COLOR_TRANSPARENT,
    [BUTTON_COLOR_TRANSPARENT_BLUE]: BUTTON_COLOR_TRANSPARENT_BLUE,
    [BUTTON_COLOR_TRANSPARENT_NAVI_BLUE_300]: BUTTON_COLOR_TRANSPARENT_NAVI_BLUE_300,
};

export const BUTTON_SIZES = {
    [BUTTON_SIZE_LARGE]: BUTTON_SIZE_LARGE,
    [BUTTON_SIZE_REGULAR]: BUTTON_SIZE_REGULAR,
    [BUTTON_SIZE_SMALL]: BUTTON_SIZE_SMALL,
};

export const BUTTON_THEMES = {
    [BUTTON_COLORS.blue]: {
        backgroundColor: palette.naviBlue.dark,
        color: palette.white.main,

        '&:not($loading):hover': {
            backgroundColor: palette.naviBlue[200],
        },

        '&:not($loading):active': {
            backgroundColor: palette.naviBlue.dark,
        },

        '& svg': {
            fill: palette.white.main,
        },
    },
    [BUTTON_COLORS.naviBlue300]: {
        backgroundColor: palette.naviBlue.dark,
        color: palette.white.main,

        '&:not($loading):hover': {
            backgroundColor: palette.naviBlue[400],
        },

        '&:not($loading):active': {
            backgroundColor: palette.naviBlue[600],
        },

        '& svg': {
            fill: palette.white.main,
        },
    },
    [BUTTON_COLORS.blueInvariant]: {
        color: palette.naviBlue.dark,
        backgroundColor: palette.white.main,

        '&:not($loading):hover': {
            color: palette.naviBlue[150],
            '& svg': {
                fill: palette.naviBlue[150],
            },
        },

        '&:not($loading):active': {
            color: palette.naviBlue.dark,
        },

        '& svg': {
            fill: palette.naviBlue.dark,
        },
    },
    [BUTTON_COLORS.transparentBlue]: {
        backgroundColor: palette.transparent,
        color: palette.naviBlue.dark,

        '&:not($loading):hover': {
            backgroundColor: palette.ash[300],
        },

        '&:not($loading):active': {
            backgroundColor: palette.ash[600],
        },

        '& svg': {
            color: palette.naviBlue.dark,
        },
    },
    [BUTTON_COLORS.transparentNaviBlue300]: {
        backgroundColor: palette.transparent,
        color: palette.naviBlue.dark,

        '&:not($loading):hover': {
            backgroundColor: palette.ash[300],
        },

        '&:not($loading):active': {
            backgroundColor: palette.ash[600],
        },

        '& svg': {
            color: palette.naviBlue.dark,
        },
    },
    [BUTTON_COLORS.gray]: {
        backgroundColor: palette.ash[300],
        color: palette.coal.tags,

        '&:not($loading):hover': {
            backgroundColor: palette.ash[400],
        },

        '&:not($loading):active': {
            backgroundColor: palette.ash.main,
        },

        '& svg': {
            fill: palette.coal.tags,
        },
    },
    [BUTTON_COLORS.grayInvariant]: {
        color: palette.ash[300],
        backgroundColor: palette.white.main,

        '&:not($loading):hover': {
            color: palette.ash[200],
            '& svg': {
                fill: palette.ash[200],
            },
        },

        '&:not($loading):active': {
            color: palette.ash.main,
        },

        '& svg': {
            fill: palette.ash[300],
        },
    },
    [BUTTON_COLORS.red]: {
        backgroundColor: palette.red.main,
        color: palette.white.main,

        '&:not($loading):hover': {
            backgroundColor: palette.red[400],
        },

        '&:not($loading):active': {
            backgroundColor: palette.red[600],
        },

        '& svg': {
            fill: palette.white.main,
        },
    },
    [BUTTON_COLORS.red30]: {
        backgroundColor: palette.red[30],
        color: palette.red.main,

        '&:not($loading):hover': {
            backgroundColor: palette.red[50],
        },

        '&:not($loading):active': {
            backgroundColor: palette.red[30],
        },

        '& svg': {
            fill: palette.red.main,
        },
    },
    [BUTTON_COLORS.redInvariant]: {
        color: palette.red.main,
        backgroundColor: palette.white.main,

        '&:not($loading):hover': {
            color: palette.red[300],
            '& svg': {
                fill: palette.red[300],
            },
        },

        '&:not($loading):active': {
            color: palette.red[600],
        },

        '& svg': {
            fill: palette.red.main,
        },
    },
    [BUTTON_COLORS.gold]: {
        backgroundColor: palette.gold.main,
        color: palette.white.main,

        '&:not($loading):hover': {
            backgroundColor: palette.gold[400],
        },

        '&:not($loading):active': {
            backgroundColor: palette.gold[600],
        },

        '& svg': {
            fill: palette.white.main,
        },
    },
    [BUTTON_COLORS.goldInvariant]: {
        color: palette.gold.main,
        backgroundColor: palette.white.main,

        '&:not($loading):hover': {
            color: palette.gold[300],
            '& svg': {
                fill: palette.gold[300],
            },
        },

        '&:not($loading):active': {
            color: palette.gold[600],
        },

        '& svg': {
            fill: palette.gold.main,
        },
    },
    [BUTTON_COLORS.green]: {
        backgroundColor: palette.green.main,
        color: palette.white.main,

        '&:not($loading):hover': {
            backgroundColor: palette.green[400],
        },

        '&:not($loading):active': {
            backgroundColor: palette.green[600],
        },

        '& svg': {
            fill: palette.white.main,
        },
    },
    [BUTTON_COLORS.greenInvariant]: {
        color: palette.green.main,
        backgroundColor: palette.white.main,

        '&:not($loading):hover': {
            color: palette.green[300],
            '& svg': {
                fill: palette.green[300],
            },
        },

        '&:not($loading):active': {
            color: palette.green[600],
        },

        '& svg': {
            fill: palette.green.main,
        },
    },
    [BUTTON_COLORS.transparent]: {
        backgroundColor: palette.transparent,
        color: palette.coal[500],

        '&:not($loading):hover': {
            backgroundColor: palette.transparent,
        },

        '&:not($loading):active': {
            backgroundColor: palette.transparent,
        },

        '& svg': {
            fill: palette.coal[500],
        },
    },
};


export const getButtonThemeClasses = (selectorPrefix = '') => {
    const keys = Object.values(BUTTON_COLORS);

    return keys.reduce((themeClasses, themeKey) => ({
        ...themeClasses,
        [`${selectorPrefix}${themeKey}`]: BUTTON_THEMES[themeKey],
    }), {});
};

export const FILTER_BUTTON_PROPS = {
    size: BUTTON_SIZES.large,
    style: { paddingLeft: 16, paddingRight: 16 },
};
