import { createSelector } from 'reselect';
import { safeGet } from '@flowhealth/utils';

import { COVID19_PCR_KEY } from './constants';


const selectState = state => safeGet(state, COVID19_PCR_KEY, {});

export const selectKitBatch = createSelector(
    selectState,
    state => safeGet(state, 'batch'),
);

export const selectFields = createSelector(
    selectState,
    state => safeGet(state, 'fields')
);

export const selectKitBatchPractice = createSelector(
    selectKitBatch,
    state => safeGet(state, 'practice'),
);

export const selectKitBatchPatient = createSelector(
    selectState,
    state => safeGet(state, 'patient'),
);

export const selectLoading = createSelector(
    selectState,
    state => safeGet(state, 'isLoading'),
);

export const selectCopyPatientId = createSelector(
    selectState,
    state => safeGet(state, 'copyPatientId'),
);

export const selectSpecimenKitId = createSelector(
    selectState,
    state => safeGet(state, 'specimenKitId'),
);

export const selectRequisitionProcessingById = id => createSelector(
    selectState,
    state => safeGet(state, `requisitionsProcessings[${id}]`),
);

export const selectPatient = createSelector(selectState, state => safeGet(state, 'patient'));
export const selectPractice = createSelector(selectPatient, patient => safeGet(patient, 'practice'));
export const selectInsurance = createSelector(selectKitBatchPatient, patient => safeGet(
    patient,
    'insurances.results[0]',
    null,
));

export const selectLocationId = createSelector(selectKitBatch, kitBatch => safeGet(kitBatch, 'location'));
