import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { bluredBG } from './styles';


const styles = ({ palette = {} } = {}) => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        overflowX: 'hidden',
        overflowY: 'auto',
        minHeight: '100%',
        zIndex: '1000',
        background: palette.white.main,
    },
    inner: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '32px 20px',

    },
    home: {
        margin: 'auto',

        display: 'flex',
        flexDirection: 'column',
        '@media (max-width:600px)': {
            width: '100%',
        },
    },
    ...bluredBG,
    button: {
        background: palette.white.main,
        borderRadius: 4,
        boxShadow: `0 1px 3px 0 ${palette.gray['04']}`,
        border: `1px solid ${palette.ash[400]}`,
        padding: '32px 16px',
        width: '320px',
        '@media (max-width:600px)': {
            width: '100%',
        },
    },
    row: {
        display: 'flex',
    },
    columnSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    label: {
        width: 141,
        height: 20,
        borderRadius: 4,
        margin: 0,
    },
    welcome: {
        height: '16px',
        width: '88px',
    },
    welcomeName: {
        height: '20px',
        width: '235px',
    },
    welcomeComment: {
        height: '12px',
        width: '154px',
    },
    icon: {
        height: '48px',
        width: '48px',
        marginRight: '16px',
    },
    labelComment: {
        height: '12px',
        width: '154px',
    },
    mb15: { marginBottom: 15 },
    mb16: { marginBottom: 16 },
    mb23: { marginBottom: 23 },
    mb31: { marginBottom: 31 },
    mt32: { marginTop: 32 },
});


const PatientPortalHomeLoader = ({ classes, className }) => (
    <div className={cn(className, classes.root)}>
        <div className={classes.inner}>
            <div className={classes.home}>
                <div className={cn(classes.mb31)}>
                    <div className={cn(classes.animate, classes.label, classes.welcome, classes.mb15)} />
                    <div className={cn(classes.animate, classes.label, classes.welcomeName, classes.mb23)} />
                    <div className={cn(classes.animate, classes.label, classes.welcomeComment)} />
                </div>

                {[...Array(3)].map((e, index) => (
                    <div key={index} className={cn(classes.button, classes.mb16)}>
                        <div className={cn(classes.row)}>
                            <div className={cn(classes.animate, classes.label, classes.icon)} />
                            <div className={classes.columnSpaceBetween}>
                                <div className={cn(classes.animate, classes.label)} />
                                <div className={cn(classes.animate, classes.label, classes.labelComment)} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

PatientPortalHomeLoader.propTypes = {
    className: PropTypes.string,
};

export default withStyles(styles)(PatientPortalHomeLoader);
