export const PP_INSURANCE_KEY = 'patientPortalInsurance';

export const INSURANCE_FORM = 'insuranceForm';


export const SET_INSURANCE_DATA = `SET_INSURANCE_DATA__${PP_INSURANCE_KEY}`;
export const SET_INPUT_VALUE = `SET_INPUT_VALUE__${PP_INSURANCE_KEY}`;
export const SET_INPUT_TOUCHED = `SET_INPUT_TOUCHED__${PP_INSURANCE_KEY}`;
export const SET_INPUT_VALID = `SET_INPUT_VALID__${PP_INSURANCE_KEY}`;
export const SET_PERSON = `SET_PERSON__${PP_INSURANCE_KEY}`;

export const RESET_STATE = `RESET_STATE__${PP_INSURANCE_KEY}`;

export const INSURANCE_REQUEST = `INSURANCE_REQUEST__${INSURANCE_FORM}__${PP_INSURANCE_KEY}`;
// eslint-disable-next-line max-len
export const INSURANCE_REQUEST_SUCCESS = `INSURANCE_REQUEST_SUCCESS__${INSURANCE_FORM}__${PP_INSURANCE_KEY}`;
// eslint-disable-next-line max-len
export const INSURANCE_REQUEST_FAILURE = `INSURANCE_REQUEST_FAILURE__${INSURANCE_FORM}__${PP_INSURANCE_KEY}`;

export const GET_PHOTOS_REQUEST = `GET_PHOTOS_REQUEST__${INSURANCE_FORM}__${PP_INSURANCE_KEY}`;
// eslint-disable-next-line max-len
export const GET_PHOTOS_REQUEST_SUCCESS = `GET_PHOTOS_REQUEST_SUCCESS__${INSURANCE_FORM}__${PP_INSURANCE_KEY}`;
// eslint-disable-next-line max-len
export const GET_PHOTOS_REQUEST_FAILURE = `GET_PHOTOS_REQUEST_FAILURE__${INSURANCE_FORM}__${PP_INSURANCE_KEY}`;
