/* eslint-disable max-len */
import React from 'react';


const Legal = () => (
    <svg viewBox="0 0 20 20" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.4344 2.2344L17.7656 6.5656C17.916 6.716 18 6.9192 18 7.1312V9.52779C16.9385 8.57771 15.5367 8 14 8C13.485 8 12.9851 8.06489 12.5081 8.18695C11.9173 7.95155 11.5 7.37427 11.5 6.7V3.5H4.3C3.8584 3.5 3.5 3.8584 3.5 4.3V15.5C3.5 15.9416 3.8584 16.3 4.3 16.3H8.45664C8.71732 16.9276 9.08147 17.5013 9.52779 18H3.6C2.716 18 2 17.2832 2 16.4V3.6C2 2.716 2.716 2 3.6 2H12.8688C13.0808 2 13.284 2.084 13.4344 2.2344ZM8.27468 12.2C8.11526 12.7076 8.02147 13.2442 8.00327 13.8H5.8C5.3584 13.8 5 13.4416 5 13C5 12.5584 5.3584 12.2 5.8 12.2H8.27468ZM10.6822 9C10.0434 9.42475 9.49061 9.96866 9.05564 10.6H5.8C5.3584 10.6 5 10.2416 5 9.8C5 9.3584 5.3584 9 5.8 9H10.6822Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14 19C16.7614 19 19 16.7614 19 14C19 11.2386 16.7614 9 14 9C11.2386 9 9 11.2386 9 14C9 16.7614 11.2386 19 14 19ZM14.1848 16L17.3672 12.8176C17.6872 12.4984 17.6872 11.9795 17.3672 11.6603C17.048 11.3402 16.529 11.3402 16.2098 11.6603L13.8959 13.9742C13.575 14.2942 13.0553 14.2942 12.7352 13.9742L12.1574 13.3963C11.8382 13.0771 11.32 13.0771 11 13.3963C10.6808 13.7164 10.6808 14.2345 11 14.5537L12.4463 16C12.9268 16.4796 13.7044 16.4796 14.1848 16Z" />
    </svg>
);

export default Legal;
