import { isString, isNumber, isNil } from 'lodash';

export const capitalize = (str = '') => (isString(str)
    ? str.replace(/([A-z])/, (match, group) => group.toUpperCase()) : undefined);

export const replaceString = (textArg = '', replaceTextArg = '', indexArg, replaceLengthArg) => {
    if (isNil(textArg) || isNil(replaceTextArg)) return;

    const text = isString(textArg) ? textArg : textArg.toString();
    const replaceText = isString(replaceTextArg) ? replaceTextArg : replaceTextArg.toString();
    if (!isString(text) || !isString(replaceText)) return;

    const index = isNil(indexArg) ? text.length : indexArg;

    if (!isString(text)
        || !isString(replaceText)
        || !isNumber(index)
        || index < 0
        || index > text.length
    ) return;

    const replaceLength = isNil(replaceLengthArg) ? replaceText.length : replaceLengthArg;

    return text.slice(0, index) + replaceText + text.slice(index + replaceLength);
};
