import memoize from 'lodash/memoize';

import { code2display } from 'components/ValueSet';

import getBirthDateWithAge from 'utils/getBirthDateWithAge';

import { CREATE_FORM_DIALOG_POSTFIX, SELECT_UNDEFINED_FILTER_ITEM, VIEW_FORM_DIALOG_POSTFIX } from './constants';


export const getSingleValue = formValue => {
    if (Array.isArray(formValue)) return formValue[0];

    return formValue;
};

export const getCreateFormDialogId = selectId => `${selectId}${CREATE_FORM_DIALOG_POSTFIX}`;
export const getViewFormDialogId = selectId => `${selectId}${VIEW_FORM_DIALOG_POSTFIX}`;

export const getPatientSubText = memoize(genders => ({ code, gender, birth_date } = {}) => {
    if (code === SELECT_UNDEFINED_FILTER_ITEM.code) return null;

    const age = getBirthDateWithAge(birth_date)?.toLowerCase();
    const genderValue = code2display(genders, gender);

    return [code, genderValue, age].filter(Boolean).join(', ');
});
