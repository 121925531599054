/* eslint-disable max-len */
import React from 'react';


const Collect = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16 7.60005C16 7.26868 15.7314 7.00005 15.4 7.00005L14.6 7.00005C14.2686 7.00005 14 7.26868 14 7.60005L14 16.4C14 16.7314 14.2686 17 14.6 17L15.4 17C15.7314 17 16 16.7314 16 16.4L16 7.60005ZM16 3.60005C16 3.26867 15.7314 3.00005 15.4 3.00005L14.6 3.00005C14.2686 3.00005 14 3.26867 14 3.60005L14 5.40005C14 5.73142 14.2686 6.00005 14.6 6.00005L15.4 6.00005C15.7314 6.00005 16 5.73142 16 5.40005L16 3.60005ZM3.5 3.5455C3.22386 3.5455 3 3.76936 3 4.0455L3 4.68186C3 4.958 3.22386 5.18186 3.5 5.18186L4.63636 5.18186L4.63636 5.5455L4.63636 14.591C4.63636 16.1725 5.91846 17.4546 7.5 17.4546C9.08154 17.4546 10.3636 16.1725 10.3636 14.591L10.3636 5.5455L10.3636 5.18186L11.5 5.18186C11.7761 5.18186 12 4.958 12 4.68186L12 4.0455C12 3.76936 11.7761 3.5455 11.5 3.5455L10.3636 3.5455L8.36364 3.5455L6.63636 3.5455L4.63636 3.5455L3.5 3.5455ZM6.63636 5.5455L8.36364 5.5455L8.36364 14.591C8.36364 15.0679 7.97697 15.4546 7.5 15.4546C7.02303 15.4546 6.63636 15.0679 6.63636 14.591L6.63636 5.5455Z" fill="#788697" />
    </svg>
);

export default Collect;
