import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';

import { closeFlowDialog } from 'components/FlowDialog/actions';
import FlowDialog from 'components/FlowDialog';
import Button from 'components/Button';

import styles from '../styles';


const ViewFromWrapper = ({
    dispatch,
    classes,

    dialogId,

    viewForm,
    viewItem,

    onSelect,

    getIdBasedOnSelectId,
}) => {
    const cancelButtonClickHandler = () => dispatch(closeFlowDialog({ id: dialogId }));
    const selectButtonClickHandler = () => {
        onSelect(viewItem);
        dispatch(closeFlowDialog({ id: dialogId }));
    };

    return (
        <FlowDialog id={dialogId} width="big">
            {viewItem && viewForm(viewItem)}
            <div className={classes.viewDialogActions}>
                <Button
                    id={getIdBasedOnSelectId('viewFormSelectButton')} // needed for tests
                    onClick={selectButtonClickHandler}
                >
                    Select
                </Button>
                <Button
                    className={classes.viewCancelButton}
                    id={getIdBasedOnSelectId('viewFormCancelButton')} // needed for tests
                    onClick={cancelButtonClickHandler}
                    color={Button.BUTTON_COLORS.gray}
                >
                    Cancel
                </Button>
            </div>
        </FlowDialog>
    );
};

const mapStateToProps = createStructuredSelector({});

const withConnect = connect(mapStateToProps);

export default compose(
    withStyles(styles),
    withConnect,
)(ViewFromWrapper);
