/* eslint-disable max-len */
import React from 'react';


const User = () => (
    <svg viewBox="0 0 20 20">
        <path d="M10 10.678c-2.047 0-3.706-1.97-3.706-4.401S6.84 1.875 10 1.875c3.16 0 3.706 1.97 3.706 4.402 0 2.43-1.66 4.401-3.706 4.401zM17 17.399c0-.149 0-.042 0 0zM3.001 17.515c-.002-.04 0-.282 0 0zM3.01 17.221c.068-4.235.633-5.441 4.961-6.205 0 0 .61.76 2.03.76s2.029-.76 2.029-.76c4.28.755 4.88 1.944 4.959 6.068.006.336.01.354.01.315v.445s-1.03 2.031-6.999 2.031c-5.968 0-6.998-2.031-6.998-2.031v-.33c0 .025.003-.022.007-.293z" />
    </svg>
);

export default User;
