import { createAction } from '@flowhealth/utils';

import {
    SELECT_SEARCH_REQUEST,
    SELECT_SEARCH_SUCCESS,
    SELECT_SEARCH_FAILURE,

    SELECT_EDGE_REQUEST,
    SELECT_EDGE_SUCCESS,
    SELECT_EDGE_FAILURE,

    SELECT_ADD_GRAPH_REQUEST,
    SELECT_ADD_GRAPH_SUCCESS,
    SELECT_ADD_GRAPH_FAILURE,

    SELECT_GET_MISSING_VALUE_REQUEST,
    SELECT_GET_MISSING_VALUE_SUCCESS,
    SELECT_GET_MISSING_VALUE_FAILURE,

    SELECT_CLEAR,
} from './constants';


export const selectSearchRequest = createAction(SELECT_SEARCH_REQUEST);
export const selectSearchSuccess = createAction(SELECT_SEARCH_SUCCESS);
export const selectSearchFailure = createAction(SELECT_SEARCH_FAILURE);

export const selectEdgeRequest = createAction(SELECT_EDGE_REQUEST);
export const selectEdgeSuccess = createAction(SELECT_EDGE_SUCCESS);
export const selectEdgeFailure = createAction(SELECT_EDGE_FAILURE);

export const selectAddGraphRequest = createAction(SELECT_ADD_GRAPH_REQUEST);
export const selectAddGraphSuccess = createAction(SELECT_ADD_GRAPH_SUCCESS);
export const selectAddGraphFailure = createAction(SELECT_ADD_GRAPH_FAILURE);

export const selectGetMissingValueRequest = createAction(SELECT_GET_MISSING_VALUE_REQUEST);
export const selectGetMissingValueSuccess = createAction(SELECT_GET_MISSING_VALUE_SUCCESS);
export const selectGetMissingValueFailure = createAction(SELECT_GET_MISSING_VALUE_FAILURE);

export const selectClear = createAction(SELECT_CLEAR);
