import React from 'react';
import cn from 'classnames';

import { checkCorporateLocation } from 'utils/appointment';

import { checkClosedNow, checkClosedToday, formatDayOpenedHours } from '../../utils';

import { POPUP_CONTINUE_BUTTON_ID } from './constants';
import { classes } from './styles';

export const Location = ({ location, onSelect }) => {
    const { id, name } = location;
    const isCorporate = checkCorporateLocation(location);

    const collectionLocation = isCorporate ? location.collection_location : location;
    const openedHours = formatDayOpenedHours(collectionLocation);
    const isClosedToday = checkClosedToday(collectionLocation);
    const isClosedNow = checkClosedNow(collectionLocation);

    return (
        <div key={`location-${id}`} id={`location-${id}`}>
            <h2 className={classes.locationName}>{name}</h2>
            <div className={classes.locationSchedule}>
                <span
                    className={
                        cn(classes.locationOpenedHours, {
                            [classes.locationClosed]: isClosedToday
                        })
                    }
                >
                    Today {isClosedToday ? 'Closed' : openedHours}
                </span>
                {!isClosedToday && (
                    <span className={cn({
                        [classes.locationClosed]: isClosedNow,
                        [classes.locationOpen]: !isClosedNow
                    })}>
                    {isClosedNow ? 'Closed Now' : 'Open Now'}
                </span>
                )}
            </div>
            <button
                type="button"
                id={POPUP_CONTINUE_BUTTON_ID}
                className={classes.locationContinueButton}
                onClick={() => onSelect(location)}
            >
                Continue
            </button>
        </div>
    );
};
