import React from 'react';
import cn from 'classnames';
import { FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const styles = ({ palette = {} } = {}) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginTop: 10, // field label empty space
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        minHeight: 38,
        marginTop: 8,
        '& $inputContainer': {
            '&:not(:first-child)': {
                marginLeft: 32,
            },
        },
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: 1,
        marginTop: 8,
        '& $inputContainer': {
            '&:not(:first-child)': {
                marginTop: 16,
            },
        },
    },
    inputContainer: {
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',

    },
    radioContainer: {
        width: 16,
        height: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        borderRadius: '50%',
        border: `solid 1px ${palette.ash[400]}`,
    },
    checked: {
        border: `solid 1px ${palette.ash[200]}`,
        backgroundColor: palette.naviBlue.dark,
        '&:after': {
            content: '""',
            borderRadius: '50%',
            width: 6,
            height: 6,
            background: palette.white.main,
        },
    },
    label: {
        fontSize: 14,
        lineHeight: 1.43,
        color: palette.coal.main,
        marginLeft: 12,
        whiteSpace: 'nowrap',
    },
    propLabel: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.5,
        color: palette.coal[500],
    },
    disabledInputContainer: {
        cursor: 'default',
    },
    disabledRadio: {
        backgroundColor: palette.ash[300],
        border: `solid 1px ${palette.ash.main}`,
        '&:after': {
            backgroundColor: palette.ash.main,
        },
    },
    disabledLabel: {
        color: palette.coal.third,
    },
    error: {
        color: 'red',
    },
});


class RadioField extends React.PureComponent {
    state = { checked: this.props.initial_value };

    componentDidUpdate(prevProps) {
        const { meta: { initial } } = this.props;
        if (initial !== prevProps.meta.initial) {
            this.setState({ checked: initial });
        }
    }

    onChange = (value, item) => e => {
        if (e) e.stopPropagation();
        this.setState({ checked: value });
        this.props.input?.onChange?.(value, item);
    };

    getRadio = (item, index) => {
        const {
            required,
            classes,
            labelClassName,
            meta: {
                touched,
                error,
            } = {},
            preview,
            disabled,
        } = this.props;

        const { code: value, display: label } = item;

        const checked = this.state.checked === value;
        const unAvailable = preview || disabled;

        return (
            <div
                key={index}
                className={cn(classes.inputContainer, unAvailable && classes.disabledInputContainer)}
                onClick={e => !unAvailable && this.onChange(value, item)(e)}
            >
                <div
                    className={cn(
                        classes.radioContainer,
                        checked && classes.checked,
                        unAvailable && classes.disabledRadio,
                    )}
                />
                {label
                    && (
                        <span
                            className={cn(
                                classes.label,
                                labelClassName,
                                !!error && touched && classes.error,
                                unAvailable && classes.disabledLabel,
                            )}
                        >
                            {required ? `${label} *` : label}
                        </span>
                    )
                }
            </div>
        );
    };

    render() {
        const {
            id,
            classes,
            className,
            data,
            meta: {
                touched,
                error,
            } = {},
            label,
            isColumn = false,
        } = this.props;

        if (!data || !data.length) return null;

        return (
            <label htmlFor={id} className={cn(classes.root, className)}>
                <div className={classes.propLabel}>{label}</div>
                <div className={classes[isColumn ? 'column' : 'row']}>
                    {data.map(this.getRadio)}
                </div>
                {touched && error
                    && (
                        <FormHelperText
                            id={`${id}-error`}
                            className={classes.error}
                        >
                            {error}
                        </FormHelperText>
                    )
                }
            </label>
        );
    }
}


export default withStyles(styles)(RadioField);
