/* eslint-disable max-len */
import React from 'react';


const LegalWithCheck = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M19.3334 29.1255C21.7266 29.1255 23.6668 27.1854 23.6668 24.7922L23.6668 7.77102L23.9994 7.77102C24.7358 7.77102 25.3328 7.17406 25.3328 6.43768L25.3328 3.99978C25.3328 3.2634 24.7358 2.66644 23.9994 2.66644L11.9994 2.66644C11.2631 2.66644 10.6661 3.2634 10.6661 3.99978L10.6661 6.43768C10.6661 7.17406 11.2631 7.77101 11.9994 7.77101L12.3334 7.77101L12.3334 11.3331C13.056 12.2162 13.7785 13.1767 14.3334 14.0841L14.3334 7.77101L21.6668 7.77101L21.6668 11.9998L18.4265 11.9998C17.8742 11.9998 17.4265 12.4475 17.4265 12.9998C17.4265 13.5521 17.8742 13.9998 18.4265 13.9998L21.6668 13.9998L21.6668 15.9998L18.4265 15.9998C17.8742 15.9998 17.4265 16.4475 17.4265 16.9998C17.4265 17.5521 17.8742 17.9998 18.4265 17.9998L21.6668 17.9998L21.6668 24.7922C21.6668 26.0808 20.6221 27.1255 19.3334 27.1255L16.6668 27.1255C15.3781 27.1255 14.3334 26.0808 14.3334 24.7922L14.3334 20.6365C13.8103 21.4892 13.116 22.1272 12.3334 22.5679L12.3334 24.7922C12.3334 27.1854 14.2735 29.1255 16.6667 29.1255L19.3334 29.1255ZM13.3321 17.0665C13.3321 19.423 11.5413 21.3332 9.33213 21.3332C7.12299 21.3332 5.33213 19.423 5.33213 17.0665C5.33213 15.2086 7.81875 12.3019 8.87083 11.1552C9.12166 10.8818 9.5426 10.8818 9.79342 11.1552C10.8455 12.3019 13.3321 15.2086 13.3321 17.0665ZM23.3328 4.66644L23.3328 5.77101L12.6661 5.77101L12.6661 4.66644L23.3328 4.66644Z" fill="#2F8FE3" />
    </svg>
);

export default LegalWithCheck;
