import { formatPhone } from '@flowhealth/utils';

const format = ({ id, value }) => {
    switch (id) {
    case 'phone': return formatPhone(value);
    default: return value;
    }
};

export default format;
