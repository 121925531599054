/* eslint-disable max-len */
import React from 'react';


const NewAttention = () => (
    <svg viewBox="0 0 24 24">
        <path d="M12 15.75L12 15.25" strokeWidth="1.5" strokeLinecap="round" stroke="#99A7BC" />
        <path d="M12 12.75L12 8" strokeWidth="1.5" strokeLinecap="round" stroke="#99A7BC" />
        <circle fillOpacity="0" cx="12" cy="12" r="10.25" strokeWidth="1.5" stroke="#99A7BC" />
    </svg>

);
export default NewAttention;
