import { set } from 'immutable';
import { isBoolean } from 'lodash';
import { createReducer, safeGet } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';


const initialState = {
    orders: undefined,
    ordersOffset: 0,
    isAllFetched: false,
    loading: true,
    VC: undefined,
};

const setLoading = value => (state, { payload = {} } = {}) =>
    set(state, 'loading', isBoolean(value) ? value : safeGet(payload, 'value', false)); // eslint-disable-line

const setOrders = (state, { payload = {} } = {}) => set(state, 'orders', safeGet(payload, 'orders'));

const setOrdersOffset = (state, { payload = {} } = {}) => set(
    state,
    'ordersOffset',
    safeGet(payload, 'ordersOffset', 0),
);

const setIsAllFetched = (state, { payload = {} } = {}) => set(
    state,
    'isAllFetched',
    safeGet(payload, 'isAllFetched', false),
);

const resetState = () => ({ ...initialState });

const setVC = (state, { payload = {} } = {}) => set(state, 'VC', payload.VC);


export default createReducer(initialState, {
    [ACTION_TYPES.RESET_STATE]: resetState,

    [ACTION_TYPES.GET_ORDERS_REQUEST]: setLoading(true),
    [ACTION_TYPES.GET_ORDERS_SUCCESS]: [
        setOrders,
        setOrdersOffset,
        setIsAllFetched,
        setLoading(false),
    ],
    [ACTION_TYPES.GET_ORDERS_FAILURE]: setLoading(false),

    [ACTION_TYPES.GET_VC_SUCCESS]: setVC,
});
