import { call, put, takeLatest, all, takeEvery, select, take } from 'redux-saga/effects';
import { safeGet, composeFilters, isFullArray } from '@flowhealth/utils';
import API from '@flowhealth/api';
import { isEmpty, isFunction, unionBy } from 'lodash';
import { isDesktop, isIOS } from 'react-device-detect';
import { delay } from 'redux-saga';
import dayjs from 'dayjs';
import { push } from 'redux-first-history';

import { getValuesRequest } from 'components/ValueSet/actions';
import { openSuccessMessage } from 'components/Message/actions';

import { collectInfoByPatient, collectInfoByPerson, findPatientByUser} from "configuration/Posthog";

import selectStore from 'utils/select';
import { downloadFile } from 'utils/saveFile';
import getBirthDateWithAge from 'utils/getBirthDateWithAge';
import { searchAll } from 'utils/searchAll';

import { makeSelectUserData } from "../../User/selector";

import * as BINAX_ACTION_TYPES from '../HTK/BinaxNowKit/store/actionTypes';
import * as CARESTART_ACTION_TYPES from '../HTK/CareStartKit/store/actionTypes';
import { SCANNER_STEP, REVIEW_RESULT_STEP, ALREADY_USED_STEP } from '../HTK/constants';

import * as ACTION_TYPES from './constants';
import { KEY_PATIENT_PORTAL, APPOINTMENTS_OBJECTS_EXPAND, PATCH_APPOINTMENT_SUCCESS } from './constants';
import * as ACTIONS from './actions';
import {
    approveAppointmentFailure,
    approveAppointmentSuccess,
    cancelAppointmentFailure,
    cancelAppointmentSuccess,
    changeAppointmentDateFailure,
    changeAppointmentDateSuccess,
    deleteAccountFailure,
    deleteAccountSuccess,
    getAppointmentsFailure,
    getAppointmentsSuccess,
    patchAppointmentFailure,
    patchAppointmentRequest,
    patchAppointmentSuccess,
} from './actions';


function* getPatients({ payload = {} } = {}) {
    try {
        const { userId, onSuccess } = payload;
        if (!userId) return;
        const patientRole = yield call(API.getInstance().getAggregate, userId, { a: 'patient_insurances' });

        const patients = safeGet(patientRole, 'patients', []);

        yield put(ACTIONS.getPatientsSuccess({ ...payload, patients }));
        if (isFunction(onSuccess)) onSuccess();

        const user = yield select(makeSelectUserData());
        yield collectInfoByPatient(patients, user);
    } catch (error) {
        yield put(ACTIONS.getPatientsFailure({ ...payload, error }));
    }
}

function* getPersons({ payload = {} } = {}) {
    try {
        const { userId } = payload;
        if (!userId) return;

        const { results: persons } = yield call(API.getInstance().search, {
            object: 'person',
            filters: `role:${userId}`,
            a: 'person_passport',
        });

        for (let i = 0; i < persons.length; i++) {
            if (persons[i].patients.length === 0) {
                persons[i].reqTests = [];
                continue;
            }

            const { results: pocReqTests } = yield call(API.getInstance().search, {
                object: 'req_test',
                a: 'patient_result',
                sort: 'collected_at(desc)',
                filters: composeFilters({
                    defaultFilter: 'status:accept"AND"(test_type:poc"OR"test_type:naat)',
                    delimiter: 'OR',
                    options: [
                        {
                            query: 'patient_id',
                            value: persons[i].patients.map(patient => patient.id),
                        },
                    ],
                }),
            });

            const { results: pcrReqTests } = yield call(API.getInstance().search, {
                object: 'req_test',
                a: 'patient_result',
                sort: 'collected_at(desc)',
                filters: composeFilters({
                    defaultFilter: 'status:accept"AND"test_type:rpp',
                    delimiter: 'OR',
                    options: [
                        {
                            query: 'patient_id',
                            value: persons[i].patients.map(patient => patient.id),
                        },
                    ],
                }),
            });

            persons[i].pocReqTests = pocReqTests;
            persons[i].pcrReqTests = pcrReqTests;
        }

        yield put(ACTIONS.getPersonsSuccess({ ...payload, persons }));
        const patients = yield select(selectStore(KEY_PATIENT_PORTAL, 'patients'));
        const user = yield select(makeSelectUserData());
        const patient = findPatientByUser(user, patients);
        collectInfoByPerson(persons, patient);
    } catch (error) {
        yield put(ACTIONS.getPersonsFailure({ ...payload, error }));
    }
}

function checkOnScannerIOS(action) {
    if (!isIOS) return;

    const location = window.location.href;
    switch (action.type) {
    case BINAX_ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_SUCCESS:
    case CARESTART_ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_SUCCESS:
        // BINAX_KIT_STEPS and CARESTART_KIT_STEPS are identical, so we can use one step for both
        window.location.href = location.replace(SCANNER_STEP, REVIEW_RESULT_STEP);
        break;
    case BINAX_ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_FAILURE:
    case CARESTART_ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_FAILURE:
        window.location.href = location.replace(SCANNER_STEP, ALREADY_USED_STEP);
        break;
    default:
    }
}

function* checkAssignConsent(person, consent) {
    const { count } = yield call(API.getInstance().search, {
        object: 'signed_consent',
        filters: `person_id:${person}"AND"version_id:${consent.active_version.id}`,
    });
    if (count) return;

    if (consent.active_version.id !== consent.required_to_sign_version.id) {
        const { count } = yield call(API.getInstance().search, {
            object: 'signed_consent',
            filters: `person_id:${person}"AND"version_id:${consent.required_to_sign_version.id}`,
        });

        if (count) return;
    }

    if (!consent.active_version.file) return;

    const file = yield call(API.getInstance().getGraph, consent.active_version.file);
    const text = yield call(downloadFile, file.url, true);
    const consentWithHTMLBody = consent;
    consentWithHTMLBody.active_version.file = text;

    return { person, ...consentWithHTMLBody };
}

function* getAssignConsents({ payload: { consents } }) {
    const consentsWasSigned = yield select(selectStore(KEY_PATIENT_PORTAL, 'consentsWasSigned'));

    // if bug https://flowhealth.atlassian.net/browse/FS-45824 will reproduce on mobile in future remove isDesktop
    if (consentsWasSigned && isDesktop) {
        yield delay(2000);
    }

    try {
        const persons = Object.keys(consents);
        const needToAssignConsents = yield all(persons
            .filter(person => !isEmpty(consents[person]))
            .map(person => consents[person]
                .map(consent => checkAssignConsent(person, consent)),
            ));
        const preparedConsents = needToAssignConsents
            .flat(1)
            .filter(consent => !!consent);
        yield put(ACTIONS.checkAssignConsentsSuccess(preparedConsents));
    } catch {
        yield put(ACTIONS.checkAssignConsentsFailure());
    }
}

function* getConsents({ payload: { id } = {} } = {}) {
    try {
        const consents = yield call(API.getInstance().getAggregate, id, { a: 'patient_consents' });

        const personsConsents = {};

        consents.patients.forEach(({ person, birth_date }) => {
            if (!person) return;
            if (personsConsents[person]) return;
            const personPatientsArray = consents?.patients.filter(patient => patient.person === person);
            const personPatientsConsentsArray = personPatientsArray.map(patient => {
                const practiceConsents = patient?.practice?.consents ?? [];
                const practiceGroupConsents = patient?.practice?.group?.consents ?? [];
                const allConsents = [...practiceConsents, ...practiceGroupConsents];
                return allConsents.map(consent => {
                    consent.patient = patient;
                    return consent;
                });
            });
            personsConsents[person] = unionBy(...personPatientsConsentsArray, 'id')
                .filter(consent => (consent.active_version || consent.required_to_sign_version) && !consent.deprecated)
                .filter(consent => {
                    const personAge = +getBirthDateWithAge(birth_date, new Date(), true)
                        .replace(/\D/gm, '');
                    return !!(personAge > 18 && !consent.minor) || !!(personAge < 18 && consent.minor);
                });
        });
        yield put(ACTIONS.checkAssignConsentsRequest({ consents: personsConsents }));
    } catch (err) {
        yield put(ACTIONS.consentsRequestFailure());
    }
}

function* getAppointments() {
    try {
        const user = yield select(makeSelectUserData());
        let patients = yield select(selectStore(KEY_PATIENT_PORTAL, 'patients'));
        if (!patients) {
            const { payload } = yield take(ACTION_TYPES.GET_PATIENTS_SUCCESS);
            patients = payload?.patients;
        }

        const date = dayjs().subtract(1, 'day').toISOString();
        const dateAfter2Weeks = dayjs().add(14, 'days').toISOString();
        const composedFilters = composeFilters({
            delimiter: '"AND"',
            options: [
                {
                    query: 'patient_id',
                    value: patients.map(patient => patient.id),
                },
                {
                    query: 'status',
                    value: ['approved_by_patient', 'notified', 'scheduled', 'created'],
                },
                {
                    query: 'test_type',
                    value: ['poc', 'rpp', 'naat', 'rtm'],
                },
            ],
        });

        const filters = composedFilters + `"AND"(event_id:"OR"created_by_id:${user.id})`;

        const makeRequestData = index => {
            const COUNT = 100;
            return {
                count: COUNT,
                after: index * COUNT,
                object: 'appointment',
                expand: APPOINTMENTS_OBJECTS_EXPAND,
                range: `date_time:${date}*${dateAfter2Weeks}`,
                filters,
            };
        }


        const request = index => API.getInstance().search(makeRequestData(index));
        const appointments = yield call(searchAll, request)

        if (isFullArray(appointments)) yield put(getValuesRequest({ file: 'test-types' }));
        yield put(getAppointmentsSuccess({ appointments }));
    } catch (error) {
        yield put(getAppointmentsFailure({ error }));
    }
}

function* getAppointment({ payload: { id } }) {
    try {
        const appointment = yield call(API.getInstance().getGraph, id, APPOINTMENTS_OBJECTS_EXPAND);
        yield put(getAppointmentsSuccess({ appointments: [appointment] }));
    } catch(error) {
        yield put(getAppointmentsFailure({ appointmentsError: error }));
    }
}

function* changeAppointmentDate({ payload: { date, id } }) {
    try {
        const appointment = yield call(API.getInstance().patchGraph, id, { date }, APPOINTMENTS_OBJECTS_EXPAND);

        yield put(changeAppointmentDateSuccess(appointment));
        yield put(openSuccessMessage('Appointment date was successfully changed'));
    } catch (error) {
        yield put(changeAppointmentDateFailure({ error }));
    }
}

function* cancelAppointment({ payload: { id } }) {
    try {
        const appointment = yield call(API.getInstance().patchGraph, id, { status: 'declined' });

        yield put(cancelAppointmentSuccess(appointment));
        yield put(openSuccessMessage('Appointment was successfully canceled'));
    } catch (error) {
        yield put(cancelAppointmentFailure({ error }));
    }
}

function* patchAppointment({ payload: { id, data, onSuccess } }) {
    try {
        const appointment = yield call(
            API.getInstance().patchGraph,
            id,
            data,
            APPOINTMENTS_OBJECTS_EXPAND);
        yield put(patchAppointmentSuccess(appointment));

        if (isFunction(onSuccess)) yield onSuccess();
    } catch (error) {
        yield put(patchAppointmentFailure({ error }));
    }
}

function* approveAppointment({ payload: { appointment, location: selectedLocation, test, date } }) {
    const { id, testing_location } = appointment;
    try {
        if (selectedLocation?.id && testing_location?.id !== selectedLocation?.id) {
            const data = { testing_location: selectedLocation.id };
            yield put(patchAppointmentRequest({ id, data }));
            yield take(PATCH_APPOINTMENT_SUCCESS);
        }
        const { testing_location: location } = yield call(
            API.getInstance().patchGraph, id, { status: 'approved_by_patient' },
            APPOINTMENTS_OBJECTS_EXPAND,
        );
        yield put(approveAppointmentSuccess());
        yield put(push('/'));
        yield put(openSuccessMessage(
            `You have approved to perform ${test} testing on ${date} at ${location.name}`,
        ));
    } catch (error) {
        yield put(approveAppointmentFailure({ error }));
    }
}

function* deleteAccount({ payload: { id, onSuccess } }) {
    try {
        yield call(API.getInstance().removeGraph, id);
        yield put(deleteAccountSuccess());
        yield onSuccess?.();
    } catch (error) {
        yield put(deleteAccountFailure({ error }))
    }
}

export default function* Saga() {
    yield all([
        takeLatest(ACTION_TYPES.GET_PATIENTS_REQUEST, getPatients),
        takeLatest(ACTION_TYPES.GET_PERSONS_REQUEST, getPersons),
        takeLatest([
            BINAX_ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_SUCCESS,
            CARESTART_ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_SUCCESS,
            BINAX_ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_FAILURE,
            CARESTART_ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_FAILURE,
        ], checkOnScannerIOS),
        takeLatest(ACTION_TYPES.GET_CONSENTS_REQUEST, getConsents),
        takeEvery(ACTION_TYPES.CHECK_ASSIGN_CONSENTS_REQUEST, getAssignConsents),
        takeLatest(ACTION_TYPES.GET_APPOINTMENT_REQUEST, getAppointment),
        takeLatest(ACTION_TYPES.GET_APPOINTMENTS_REQUEST, getAppointments),
        takeLatest(ACTION_TYPES.CHANGE_APPOINTMENT_DATE_REQUEST, changeAppointmentDate),
        takeLatest(ACTION_TYPES.CANCEL_APPOINTMENT_REQUEST, cancelAppointment),
        takeLatest(ACTION_TYPES.PATCH_APPOINTMENT_REQUEST, patchAppointment),
        takeLatest(ACTION_TYPES.APPROVE_APPOINTMENT_REQUEST, approveAppointment),
        takeLatest(ACTION_TYPES.DELETE_ACCOUNT_REQUEST, deleteAccount),
    ]);
}
