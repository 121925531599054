/* eslint-disable max-len */
import React from 'react';

const QuickVue = () => (
    <svg viewBox="0 0 32 32" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M18.084 25.625C18.084 27.42 16.6289 28.875 14.834 28.875H10.5007C8.70572 28.875 7.25065 27.42 7.25065 25.625L7.25065 7.77059L6.33398 7.77059C5.7817 7.77059 5.33398 7.32287 5.33398 6.77059L5.33399 3.66602C5.33399 3.11373 5.7817 2.66602 6.33399 2.66602L19.0007 2.66602C19.5529 2.66602 20.0007 3.11373 20.0007 3.66602V6.77059C20.0007 7.32287 19.5529 7.77059 19.0007 7.77059H18.084L18.084 25.625ZM8.75065 25.625L8.75065 7.77059L16.584 7.77059V11.2494H14.334C13.3675 11.2494 12.584 12.0329 12.584 12.9994V21.666C12.584 22.6325 13.3675 23.416 14.334 23.416L16.584 23.416V25.625C16.584 26.5915 15.8005 27.375 14.834 27.375H10.5006C9.53415 27.375 8.75065 26.5915 8.75065 25.625ZM16.584 21.916L16.584 12.7494H14.334C14.1959 12.7494 14.084 12.8613 14.084 12.9994V21.666C14.084 21.8041 14.1959 21.916 14.334 21.916L16.584 21.916ZM18.5007 6.27059L18.5007 4.16602L6.83399 4.16602L6.83398 6.27059L18.5007 6.27059ZM25.6673 25.666C25.6673 26.2183 25.2196 26.666 24.6673 26.666C24.115 26.666 23.6673 26.2183 23.6673 25.666L23.6673 13.0651C23.0695 12.7193 22.6673 12.073 22.6673 11.3327L22.6673 7.33269C22.6673 6.22812 23.5627 5.33269 24.6673 5.33269C25.7719 5.33269 26.6673 6.22812 26.6673 7.33269V11.3327C26.6673 12.073 26.2651 12.7193 25.6673 13.0651V25.666ZM25.1673 10.7998C25.0202 10.7147 24.8495 10.666 24.6673 10.666C24.4852 10.666 24.3144 10.7147 24.1673 10.7998L24.1673 7.33269C24.1673 7.05655 24.3912 6.83269 24.6673 6.83269C24.9435 6.83269 25.1673 7.05655 25.1673 7.33269V10.7998Z" fill="#2F8FE3" />
    </svg>
);

export default QuickVue;
