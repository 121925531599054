import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';


export const resetState = createAction(ACTION_TYPES.RESET_STATE);
export const resetScannerState = createAction(ACTION_TYPES.RESET_SCANNER_STATE);

export const setLoading = createAction(ACTION_TYPES.SET_LOADING);
export const setPatient = createAction(ACTION_TYPES.SET_PATIENT);
export const setBatch = createAction(ACTION_TYPES.SET_BATCH);
export const setKitId = createAction(ACTION_TYPES.SET_KIT_ID);
export const setImgSrc = createAction(ACTION_TYPES.SET_IMG_SRC);
export const setFile = createAction(ACTION_TYPES.SET_FILE);
export const setTestResult = createAction(ACTION_TYPES.SET_TEST_RESULT);

export const submitTestRequest = createAction(ACTION_TYPES.SUBMIT_TEST_REQUEST);
export const submitTestSuccess = createAction(ACTION_TYPES.SUBMIT_TEST_SUCCESS);
export const submitTestFailure = createAction(ACTION_TYPES.SUBMIT_TEST_FAILURE);

export const wsSubscribe = createAction(ACTION_TYPES.WS_SUBSCRIBE);
export const wsUnsubscribe = createAction(ACTION_TYPES.WS_UNSUBSCRIBE);

export const copyToPracticeRequest = createAction(ACTION_TYPES.COPY_TO_PRACTICE_REQUEST);
export const copyToPracticeSuccess = createAction(ACTION_TYPES.COPY_TO_PRACTICE_SUCCESS);
export const copyToPracticeFailure = createAction(ACTION_TYPES.COPY_TO_PRACTICE_FAILURE);
export const copyToPracticeFinished = createAction(ACTION_TYPES.COPY_TO_PRACTICE_FINISHED);
export const wsCopyToPracticeSuccess = createAction(ACTION_TYPES.WS_COPY_TO_PRACTICE_SUCCESS);
export const wsCopyToPracticeFailed = createAction(ACTION_TYPES.WS_COPY_TO_PRACTICE_FAILED);

export const createPocActionWithPhotoRequest = createAction(ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_REQUEST);
export const createPocActionWithPhotoSuccess = createAction(ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_SUCCESS);
export const createPocActionWithPhotoFailure = createAction(ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_FAILURE);
export const wsPocActionChangeSuccess = createAction(ACTION_TYPES.WS_POC_ACTION_CHANGE_SUCCESS);
export const wsPocActionChangeFailed = createAction(ACTION_TYPES.WS_POC_ACTION_CHANGE_FAILED);

export const setError = createAction(ACTION_TYPES.SET_ERROR);
