import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEmpty, isRegExp, isString, memoize } from 'lodash';
import { isFullObject } from '@flowhealth/utils';

dayjs.extend(utc);

export const getCurrentDate = (isUTC = false, format) => (isUTC ? dayjs.utc().format(format) : dayjs().format(format));

export const validateDate = memoize((formats = {}) => value => {
    if (!isFullObject(formats)) return false;

    if (!isString(value) || isEmpty(value)) {
        return false;
    }

    for (const format in formats) {
        if (Object.prototype.hasOwnProperty.call(formats, format)) {
            const regex = formats[format];
            const isDayjsValid = dayjs(value, format, true).isValid();

            if (isDayjsValid && !isRegExp(regex)) {
                return true;
            }

            if (isDayjsValid && regex.test(value)) {
                return true;
            }
        }
    }

    return false;
});

