import React from 'react';
import cn from 'classnames';
import { createUseStyles } from '@flowhealth/ui-components';


const useStyles = createUseStyles(({ palette, typography }) => ({
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 0',
        width: '100%',
    },
    button: {
        padding: 0,
        border: 'none',
        background: 'none',
        cursor: 'pointer',

        '&:focus, &:hover': {
            background: palette.ash[200],
        },

        '& $text': {
            textDecoration: 'underline',
        },
    },
    link: {
        textDecoration: 'none',

        '&:focus, &:hover': {
            background: palette.ash[200],
        },

        '& $text': {
            textDecoration: 'underline',
        },
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        ...typography['14R'],
        color: palette.coal[500],
        textAlign: 'left',
    },
    text: {
        ...typography['16R'],
        color: palette.coal[700],
        textAlign: 'left',
    },
}));

export const Item = ({ onClick, href, text, label, icon }) => {
    const classes = useStyles();

    const content = (
        <>
            <div className={classes.column}>
                <div className={classes.label}>{label}</div>
                <div className={classes.text}>{text}</div>
            </div>
            {icon}
        </>
    );

    if (onClick) {
        return <button className={cn(classes.item, classes.button)} onClick={onClick}>{content}</button>
    }
    return href
        ? <a className={cn(classes.item, classes.link)} href={href}>{content}</a>
        : <div className={classes.item}>{content}</div>;
} ;
