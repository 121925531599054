import dayjs from 'dayjs';

import { getSingleValue } from 'components/FlowSelect/utils';


const submit = ({ position = 1, persons, fields }) => {
    const person = persons.find(person => person.id === getSingleValue(fields.person.value)?.code);
    const relation = getSingleValue(fields.policy_holder.value)?.code === 'self'
        ? 'self'
        : getSingleValue(fields.relation.value)?.code;

    const data = {
        object_type: 'insurance',
        payor: getSingleValue(fields.payor.value)?.id,
        member_id: fields.member_id?.value,
        group_number: fields.group_number?.value,
        relation,
        position,
    };

    if (getSingleValue(fields.policy_holder.value)?.code === 'another') {
        data.policy_holder = {
            given: fields.given.value,
            family: fields.family.value,
        };
        data.holder_birth_date = dayjs.utc(fields.birth_date.value).add(12, 'hour').toISOString();
    }

    if (!['self', 'another'].includes(getSingleValue(fields.policy_holder.value)?.code)) {
        const holderPerson = persons.find(person => person.id === getSingleValue(fields.policy_holder.value)?.code);
        data.policy_holder = {
            given: holderPerson.name.given,
            family: holderPerson.name.family,
        };
        data.holder_birth_date = dayjs.utc(holderPerson.birth_date).add(12, 'hour').toISOString();
    }

    return person.patients.map(patient => ({ ...data, beneficiary: patient.id }));
};


export default submit;
