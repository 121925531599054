import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isString from 'lodash/isString';
import withStyles from '@material-ui/core/styles/withStyles';
import { isObject, getNameFromParams } from '@flowhealth/utils';

import FlowSimpleIcon from './FlowSimpleIcon';
import {
    ICONS_TYPES,
    ICON_COMPS,
    LABEL_POSITION_TYPES,
    ROTATE_TYPES,
    ROTATE_VALUES,
} from './constants';

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'relative',
    },
    svgWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        order: '2',
        height: '100%',
        width: '100%',
        transition: 'all 300ms cubic-bezier(0.23, 1, 0.32, 1)',
        flexShrink: '0',

        '& > svg': {
            height: '100%',
            width: '100%',
        },
    },
    left: {
        order: '1',
        marginRight: '20px',
    },
    right: {
        order: '3',
        marginLeft: '20px',
    },
    label: {
        fontSize: '14px',
        fontWeight: 500,
    },
};

class FlowIcon extends PureComponent {
    static propTypes = {
        size: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.oneOf(Object.keys(ICONS_TYPES)),
            PropTypes.node,
            PropTypes.func,
        ]).isRequired,
        rotate: PropTypes.oneOfType([
            PropTypes.oneOf(Object.values(ROTATE_TYPES)),
            PropTypes.number,
        ]),
        label: PropTypes.node,
        labelPosition: PropTypes.oneOf(Object.keys(LABEL_POSITION_TYPES)),
        className: PropTypes.string,
        labelClassName: PropTypes.string,
        id: PropTypes.string,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        ref: PropTypes.object,
        style: PropTypes.object,
        dataTestId: PropTypes.string,
    };

    static defaultProps = {
        className: '',
        labelClassName: '',
        rotate: ROTATE_TYPES.up,
        labelPosition: LABEL_POSITION_TYPES.right,
        disabled: false,
    };

    render() {
        const {
            classes,
            id,

            size,
            width,
            height,
            mr,
            type,
            className,
            labelClassName,
            rotate,
            label,
            labelPosition,
            disabled,
            onClick,
            style,
            scale,
            dataTestId,
        } = this.props;

        const inlineStyle = {
            transform: getNameFromParams(
                `rotate(${isString(rotate) ? ROTATE_VALUES[rotate] : rotate}deg)`,
                scale && 'scale(-1, 1)',
            ),
            width: size || width,
            height: size || height,

        };
        isObject(style) && Object.assign(inlineStyle, style);

        return (
            <div
                {...{
                    id,
                    className: cn(classes.root, className),
                    onClick: disabled ? undefined : onClick,
                    style: {
                        cursor: !disabled && onClick && 'pointer',
                        marginRight: mr,
                    },
                }}
                data-tid={dataTestId}
            >
                <div {...{
                    className: classes.svgWrapper,
                    style: inlineStyle,
                }}
                >
                    {!!type && React.createElement(ICON_COMPS[type] || type)}
                </div>
                {label
                  && (
                      <div {...{
                          className: cn(classes.label, labelClassName, classes[labelPosition]),
                      }}
                      >
                          {label}
                      </div>
                  )
                }
            </div>
        );
    }
}

export { FlowSimpleIcon };

export default withStyles(styles)(FlowIcon);
