/* eslint-disable max-len */
import React from 'react';

const CommonFile = () => (
    <svg viewBox="0 0 19 20">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.8 14c0 .442-.358.8-.8.8H6c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8h8c.442 0 .8.358.8.8zM6 10h8c.442 0 .8.358.8.8 0 .442-.358.8-.8.8H6c-.442 0-.8-.358-.8-.8 0-.442.358-.8.8-.8zm10.4 5.6c0 .442-.358.8-.8.8H4.4c-.442 0-.8-.358-.8-.8V4.4c0-.442.358-.8.8-.8h7.2v3.2c0 .883.716 1.6 1.6 1.6h3.2v7.2zm1.366-9.034l-4.332-4.332c-.15-.15-.353-.234-.565-.234H3.6C2.716 2 2 2.716 2 3.6v12.8c0 .883.716 1.6 1.6 1.6h12.8c.884 0 1.6-.717 1.6-1.6V7.131c0-.212-.084-.415-.234-.565z" />
    </svg>
);

export default CommonFile;
