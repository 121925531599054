/* eslint-disable max-len */
import React from 'react';


const Distributors = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M13.874 5H17.2c.9 0 1.8.812 1.8 1.806v10.397c0 .994-.805 1.797-1.8 1.797H2.8c-.994 0-1.8-.8-1.8-1.794V6.803C1 5.809 1.806 5 2.8 5h3.326C6.57 3.275 8.136 2 10 2c1.864 0 3.43 1.275 3.874 3zM8.268 5h3.464c-.345-.598-.992-1-1.732-1s-1.387.402-1.732 1zM17 16.125c0 .483-.392.875-.875.875H3.875C3.392 17 3 16.608 3 16.125v-8.25C3 7.392 3.392 7 3.875 7h12.25c.483 0 .875.392.875.875v8.25zM11 11V9H9v2H7v2h2v2h2v-2h2v-2h-2z" clipRule="evenodd" />
    </svg>
);

export default Distributors;
