/* eslint-disable max-len */
import React from 'react';


const CeckCircle = () => (
    <svg viewBox="0 0 20 20">
        <circle cx="10" cy="10" r="8" fill="inherit" />
        <path fill="#fff" d="M14.072 8.707l-3.888 3.888a1.503 1.503 0 0 1-2.124 0l-1.768-1.767a1 1 0 0 1 1.415-1.414l.705.706a1.004 1.004 0 0 0 1.418 0l2.828-2.827a.999.999 0 1 1 1.414 1.414z" />
    </svg>
);

export default CeckCircle;
