import { takeEvery } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import API from '@flowhealth/api';

import {requestAndSetStiOrder, setIsLoading, setOrder} from './slice';

function* requestAndSetStiOrderWorker({ payload }) {
    try {
        yield put(setIsLoading(true));
        const order = yield call(API.getInstance().getAggregate, payload, {
            a: 'patient_result_list'
        });
        yield put(setOrder(order))
    } catch (e) {
        yield put(setOrder(null));
    } finally {
        yield put(setIsLoading(false));
    }
}

export function* Saga() {
    yield takeEvery(requestAndSetStiOrder.type, requestAndSetStiOrderWorker);
}

export default Saga;
