import { createSelector } from 'reselect';
import { safeGet } from '@flowhealth/utils';

import { LUCIRA_KIT_KEY } from './constants';

const selectState = state => safeGet(state, LUCIRA_KIT_KEY, {});

export const selectKitBatch = createSelector(
    selectState,
    state => safeGet(state, 'batch'),
);

export const selectFields = createSelector(
    selectState,
    state => safeGet(state, 'fields')
);

export const selectKitId = state => safeGet(selectState(state), 'kitId');

export const selectPocActionFile = state => safeGet(selectState(state), 'pocActionFile');

export const selectKitBatchPractice = createSelector(
    selectKitBatch,
    state => safeGet(state, 'practice'),
);

export const selectKitBatchPatient = createSelector(
    selectState,
    state => safeGet(state, 'patient'),
);

export const selectLoading = createSelector(
    selectState,
    state => safeGet(state, 'isLoading'),
);

export const selectCopyPatientId = createSelector(
    selectState,
    state => safeGet(state, 'copyPatientId'),
);
