/* eslint-disable max-len */
import React from 'react';


const ScanBarcode = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M1 0C.448 0 0 .448 0 1v3h1V1.5c0-.276.224-.5.5-.5H4V0H1zM0 19c0 .552.448 1 1 1h3v-1H1.5c-.276 0-.5-.224-.5-.5V16H0v3zm20 0c0 .552-.448 1-1 1h-3v-1h2.5c.276 0 .5-.224.5-.5V16h1v3zm0-18c0-.552-.448-1-1-1h-3v1h2.5c.276 0 .5.224.5.5V4h1V1zM6.25 4.5c0-.276.224-.5.5-.5h.5c.276 0 .5.224.5.5V7h-1.5V4.5zM5.5 7V4.5c0-.276-.224-.5-.5-.5h-.5c-.276 0-.5.224-.5.5V7h1.5zM4 11h1.5v4.167c0 .276-.224.5-.5.5h-.5c-.276 0-.5-.224-.5-.5V11zm2.25 0h1.5v2.833c0 .277-.224.5-.5.5h-.5c-.276 0-.5-.223-.5-.5V11zm2.41 0v2.958c0 .207.168.375.375.375s.375-.168.375-.375V11h-.75zm1.553 0v4.167c0 .276.224.5.5.5h.5c.277 0 .5-.224.5-.5V11h-1.5zm1.5-4h-1.5V4.5c0-.276.224-.5.5-.5h.5c.277 0 .5.224.5.5V7zm.831 4v2.958c0 .207.168.375.375.375s.375-.168.375-.375V11h-.75zm0-4V4.375c0-.207.168-.375.375-.375s.375.168.375.375V7h-.75zm1.206 4v4.167c0 .276.224.5.5.5h1.25c.276 0 .5-.224.5-.5V11h-2.25zM16 7h-2.25V4.5c0-.276.224-.5.5-.5h1.25c.276 0 .5.224.5.5V7zM9.41 7h-.75V4.375c0-.207.168-.375.375-.375s.375.168.375.375V7zM2 8.2c0-.11.09-.2.2-.2h15.6c.11 0 .2.09.2.2v.6c0 .11-.09.2-.2.2H2.2c-.11 0-.2-.09-.2-.2v-.6z" clipRule="evenodd" />
    </svg>
);

export default ScanBarcode;
