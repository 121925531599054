/* eslint-disable max-len */
import React from 'react';


const CloseThin = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.16657 5.10541C5.87368 4.81252 5.3988 4.81252 5.10591 5.10541C4.81301 5.39831 4.81301 5.87318 5.10591 6.16607L10.9389 11.999L5.10444 17.8334C4.81154 18.1263 4.81154 18.6012 5.10444 18.8941C5.39733 19.187 5.8722 19.187 6.1651 18.8941L11.9995 13.0597L17.8338 18.894C18.1267 19.1869 18.6016 19.1869 18.8945 18.894C19.1874 18.6011 19.1874 18.1262 18.8945 17.8333L13.0602 11.999L18.893 6.16619C19.1859 5.87329 19.1859 5.39842 18.893 5.10553C18.6001 4.81263 18.1252 4.81263 17.8324 5.10553L11.9995 10.9384L6.16657 5.10541Z" />
    </svg>
);

export default CloseThin;
