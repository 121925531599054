// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyBg5-FIkqKlPdj_eDAhVdGT0-PLCj8htjc',
    authDomain: 'flow-health-1610969279274.firebaseapp.com',
    projectId: 'flow-health-1610969279274',
    storageBucket: 'flow-health-1610969279274.appspot.com',
    messagingSenderId: '648501063170',
    appId: '1:648501063170:web:a01a90ced0ef34cd5bc11b',
    measurementId: 'G-2Y0X7ZDF0B',
};
