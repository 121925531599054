import { fromJS } from 'immutable';
import { isObject, toNum, createReducer, safeGet, isArray } from '@flowhealth/utils';
import { isString, isEmpty } from 'lodash';

import isUrl from 'utils/isUrl';

import * as ACTION_TYPES from './constants';


export const initialZipState = {
    progress: {
        total: undefined,
        ready: undefined,
        failed: undefined,
    },
    loading: false,
    failedIds: [],
};

const initialState = fromJS({
    meta: {},
    loading: false,
    error: null,
    printingImgs: [],
    zip: initialZipState,
    loadingFilesIds: [],
    files: {},
});

const resetState = () => initialState;

const setMeta = (state, { payload = {} } = {}) => state.set('meta', payload);

const setLoading = value => state => state.set('loading', value);

const resetError = state => state.set('error', null);

const setError = (state, { payload = {} } = {}) => state.set('error', payload);

const addPrintingImgs = (state, { payload = {} } = {}) => state.updateIn(['printingImgs'], printingImgs => {
    const imgsIds = safeGet(payload, 'imgs', []).map(img => safeGet(img, 'id')).filter(isString);
    return printingImgs.concat(imgsIds);
});

const removePrintingImgs = (state, { payload = {} } = {}) => state.updateIn(['printingImgs'], printingImgs => {
    const imgsIds = safeGet(payload, 'imgs', []).map(img => safeGet(img, 'id')).filter(isString);
    return printingImgs.filter(imgId => !imgsIds.includes(imgId));
});

const resetZipState = state => state.set('zip', initialZipState);

const initZipProgress = (state, { payload = {} } = {}) => {
    const { data = [], mimeTypes } = payload;
    if (!isArray(data) || isEmpty(data)) return state;
    let ready = 0;
    let failed = 0;
    const failedIds = [];
    for (const item of data) {
        if (isObject(item) && !item.url) {
            if (!isArray(mimeTypes) || isEmpty(mimeTypes)) {
                ready += 1;
            } else {
                if (mimeTypes.includes(item.type)) ready++; // eslint-disable-line
                else {
                    item.id && failedIds.push(item.id);
                    failed += 1;
                }
            }
        } else if (!isUrl(item) && !isObject(item)) {
            failed += 1;
        }
    }
    return state
        .setIn(['zip', 'progress'], {
            total: data.length,
            ready,
            failed,
        })
        .setIn(['zip', 'failedIds'], failedIds);
};

const incrementZipProgress = type => state => state.updateIn(['zip', 'progress', type], value => toNum(value) + 1);

const setZipLoading = value => state => state.setIn(['zip', 'loading'], value);

const addFailedId = (state, { payload = {} } = {}) => (payload.graphFileId
    ? state.updateIn(['zip', 'failedIds'], (failedIds = []) => failedIds.concat(payload.graphFileId))
    : state);

const addLoadingFileId = (state, { payload = {} } = {}) =>
    state.updateIn(['loadingFilesIds'], (loadingFilesIds = []) => isString(payload.id) ? loadingFilesIds.concat(payload.id) : loadingFilesIds); // eslint-disable-line

const removeLoadingFileId = (state, { payload = {} } = {}) =>
    state.updateIn(['loadingFilesIds'], (loadingFilesIds = []) => loadingFilesIds.filter(id => id !== payload.id)); // eslint-disable-line

const setFile = (state, { payload = {} } = {}) => state.setIn(['files', payload.id], payload.file);

export default createReducer(initialState, {
    [ACTION_TYPES.FILES_RESET]: resetState,
    [ACTION_TYPES.FILES_SET_META]: setMeta,

    [ACTION_TYPES.FILES_GET_FILE_URL_REQUEST]: [resetError, setLoading(true)],
    [ACTION_TYPES.FILES_GET_FILE_URL_FAILURE]: [setError, setLoading(false)],

    [ACTION_TYPES.FILES_UPLOAD_FILE_REQUEST]: [resetError, setLoading(true)],
    [ACTION_TYPES.FILES_UPLOAD_FILE_FAILURE]: [setError, setLoading(false)],

    [ACTION_TYPES.FILES_ADD_FILE_REQUEST]: [resetError, setLoading(true)],
    [ACTION_TYPES.FILES_ADD_FILE_SUCCESS]: [resetError, setLoading(false)],
    [ACTION_TYPES.FILES_ADD_FILE_FAILURE]: [setError, setLoading(false)],

    [ACTION_TYPES.FILES_GET_FILE_REQUEST]: addLoadingFileId,
    [ACTION_TYPES.FILES_GET_FILE_SUCCESS]: [removeLoadingFileId, setFile],
    [ACTION_TYPES.FILES_GET_FILE_FAILURE]: [removeLoadingFileId, setError, setLoading(false)],

    [ACTION_TYPES.FILES_GET_PRINT_IMAGES_REQUEST]: addPrintingImgs,
    [ACTION_TYPES.FILES_GET_PRINT_IMAGES_SUCCESS]: removePrintingImgs,
    [ACTION_TYPES.FILES_GET_PRINT_IMAGES_FAILURE]: removePrintingImgs,

    [ACTION_TYPES.FILES_RESET_ZIP_STATE]: resetZipState,
    [ACTION_TYPES.FILES_GET_ZIP_CANCEL]: resetZipState,

    [ACTION_TYPES.FILES_GET_ZIP_REQUEST]: [resetZipState, initZipProgress, setZipLoading(true)],
    [ACTION_TYPES.FILES_GET_ZIP_SUCCESS]: setZipLoading(false),
    [ACTION_TYPES.FILES_GET_ZIP_FAILURE]: setZipLoading(false),

    [ACTION_TYPES.FILES_ZIP_INCREMENT_READY]: incrementZipProgress('ready'),

    [ACTION_TYPES.FILES_ZIP_INCREMENT_FAILED]: [addFailedId, incrementZipProgress('failed')],
});
