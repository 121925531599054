import { createSelector } from 'reselect';

const selectWebsocket = state => state.websocket;
export const selectWebsocketOpen = createSelector(
    selectWebsocket,
    state => state.get('open'),
);

export const selectWebsocketScheduleOpen = createSelector(
    selectWebsocket,
    state => state.get('scheduledOpen'),
);
