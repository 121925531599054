import React, { useRef, useMemo } from 'react';
import { createUseStyles } from '@flowhealth/ui-components';
import cn from 'classnames';

import { MAX_WIDTH, SIDE_PADDING, ACTIONS_INDENT } from './constants';
import { useContainerBreakpoint } from './hooks/useContainerBreakpoint';
import { useContentStyle } from './hooks/useContentStyle';

const useStyles = createUseStyles(({ palette, typography }) => ({
    container: ({ isMobile }) => {
        const styles = {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            margin: '0 auto',
            maxWidth: MAX_WIDTH,
            width: '100%',
            zIndex: '3000',
        };

        if (isMobile) {
            styles['padding'] = `0 ${SIDE_PADDING}px`;
        }

        return styles;
    },
    navBar: ({ isMobile }) => {
        const styles = {
            paddingBottom: isMobile ? 8 : 18,
            position: 'relative',
        };

        if (!isMobile) {
            styles['paddingTop'] = 32;
        }

        return styles;
    },
    headerActions: ({ isMobile, isLightMode }) => {
        const styles = {
            alignItems: 'center',
            background: isLightMode ? 'transparent' : palette.white[100],
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: 44,
        };

        if (isMobile) {
            styles['marginTop'] = 32;
        }

        return styles;
    },
    title: ({ isLightMode }) => ({
        ...typography['16M'],
        color: isLightMode ? palette.white[100] : palette.coal[700],
        margin: 0,
        padding: 0,
        flexGrow: 1,
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
    }),
    bigTitle: ({ isMobile }) => {
        const styles = {
            ...typography['32B'],
            color: palette.coal[700],
            margin: 0,
        };

        if (isMobile) {
            styles['padding'] = '32px 0 0';
        } else {
            styles['padding'] = '8px 0 0';
        }

        return styles;
    },
    subTitle: ({ isLightMode }) => ({
        ...typography['16R'],
        color: isLightMode ? palette.white[100] : palette.coal[700],
        padding: '16px 0 0',
    }),
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    actions: ({ isMobile, isActionFixedToBottom }) => {
        const styles = {
            background: palette.white[100],
        };

        if (isActionFixedToBottom) {
            styles['position'] = 'fixed';
            styles['width'] = 'inherit';
            styles['maxWidth'] = MAX_WIDTH;
            styles['left'] = '50%';
            styles['transform'] = 'translate(-50%, 0)';
            styles['bottom'] = 0;
            styles['paddingBottom'] = '12px';
        }

        if (isMobile) {
            styles['width'] = '100%';
            styles['position'] = 'fixed';
            styles['bottom'] = 0;
            styles['right'] = 0;
            styles['padding'] = '0 20px 8px';
        } else {
            styles['marginTop'] = ACTIONS_INDENT;
        }
        return styles;
    },
}));

export const Container = ({
    children,
    className,
    leftAction,
    rightAction,
    title,
    bigTitle,
    actions,
    isLightMode,
    isActionFixedToBottom = false,
}) => {
    const isMobile = useContainerBreakpoint();
    const classes = useStyles({ isMobile, isLightMode, isActionFixedToBottom });
    const actionsRef = useRef(null);
    const contentStyle = useContentStyle(actionsRef, isActionFixedToBottom);

    const actionsBlock = useMemo(() => {
        if (!actions) {
            return undefined;
        }
        return (
            <div ref={actionsRef} className={classes.actions}>
                {actions}
            </div>
        );
    }, [actions, classes.actions]);

    return (
        <div className={classes.container}>
            <div className={classes.navBar}>
                <div className={classes.headerActions}>
                    <div>{leftAction}</div>
                    <h3 className={classes.title}>{title}</h3>
                    <div>{rightAction}</div>
                </div>
                {bigTitle && <h1 className={classes.bigTitle}>{bigTitle}</h1>}
            </div>
            <div
                className={cn(classes.content, className)}
                style={contentStyle}
            >
                {children}
                {!isMobile && actionsBlock}
            </div>
            {isMobile && actionsBlock}
        </div>
    );
};
