/* eslint-disable max-len */

export const validateEmailForDevRole = email => {
    const regex = /^(\S+)@flowhealth.com$/i;
    if (!regex.test(email)) return 'Enter valid email with @flowhealth.com domain';
};

export const validateEmail = (email, user) => {
    const userData = user?.data;
    const userRole = userData?.object_type;
    const isUserCreatedByDevRole = userData?.user.created_by?.developer;
    if (userRole !== 'dev_role' && !isUserCreatedByDevRole) {
        const regex = /^(\S+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
        if (!regex.test(email)) return 'Enter valid email';
    } else {
        return validateEmailForDevRole(email);
    }
};

export const validateURL = textVal => {
    const urlRegex = /^((https?|ftps?):\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    if (!urlRegex.test(textVal)) return 'Enter valid url';
};
