import React from 'react';
import cn from 'classnames';
import { createUseStyles } from '@flowhealth/ui-components';


const useStyles = createUseStyles(({ palette = {} }) => ({
    panel: {
        background: palette.white[100],
        border: `0.33px solid ${palette.ash[500]}`,
        boxShadow: '0px 8px 24px rgba(36, 71, 123, 0.1)',
        borderRadius: 8.09668,
        padding: '16px 20px',
    },
}));

export const Panel = ({ children, className, ...props }) => {
    const classes = useStyles();
    return (
        <div className={cn(classes.panel, className)} {...props}>{children}</div>
    );
};

