import React, { useRef } from 'react';
import ReactDOMServer from 'react-dom/server';

import { POPUP_CONTINUE_BUTTON_ID } from '../constants';
import { Location } from '../Location';

export const useLocationPopup = ({ map, onContinueClick }) => {
    const infoWindow = useRef(new window.google.maps.InfoWindow({
        pixelOffset: new window.google.maps.Size(0, 8),
        minWidth: 335
    }));

    const onPopupOpen = (location, anchor) => {
        infoWindow.current.open({
            anchor,
            map,
            shouldFocus: false,
        });

        const content = ReactDOMServer.renderToString(<Location location={location} />);
        infoWindow.current.setContent(content);

        window.google.maps.event.addListenerOnce(infoWindow.current, 'domready', () => {
            document
                .getElementById(POPUP_CONTINUE_BUTTON_ID)
                ?.addEventListener('click', onContinueClick);
        });
    };

    const onPopupClose = () => {
        infoWindow.current.close();
        window.google.maps.event.clearInstanceListeners(infoWindow.current);

        document
            .getElementById(POPUP_CONTINUE_BUTTON_ID)
            ?.removeEventListener('click', onContinueClick);
    };

    return {
        onPopupOpen,
        onPopupClose,
    };
};
