/**
 * The global state selectors
 */

import { createSelector } from 'reselect';

export const selectGlobal = state => state.global;
export const selectAuth = state => state.auth;
export const selectSearch = state => state.search;

export const makeSelectLoading = () => createSelector(
    selectGlobal,
    globalState => globalState.get('loading'),
);

export const makeSelectLogo = () => createSelector(
    selectGlobal,
    globalState => globalState.get('logoUrl'),
);

export const makeSelectLogoIsLoading = () => createSelector(
    selectGlobal,
    globalState => globalState.get('logoIsLoading'),
);

export const makeSelectMainMenu = () => createSelector(
    selectGlobal,
    globalState => globalState.get('isMainMenuOpen'),
);

export const makeSelectMainMeniClickedOn = () => createSelector(
    selectGlobal,
    globalState => globalState.get('menuClickedOn'),
);

export const makeSelectError = () => createSelector(
    selectGlobal,
    globalState => globalState.get('error'),
);

export const makeSelectSearch = () => createSelector(
    selectSearch,
    searchState => searchState.toJS(),
);

export const selectIsMobile = createSelector(
    selectGlobal,
    globalState => globalState && globalState.get('mobile'),
);

export const selectCamera = createSelector(
    selectGlobal,
    globalState => globalState && globalState.get('camera'),
);

