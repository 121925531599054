import { useEffect, useMemo, useRef, useState } from 'react';

import { checkCorporateLocation } from 'utils/appointment';

import baseMarker from 'icons/BaseMapMarker.svg';
import activeMarker from 'icons/ActiveMapMarker.svg';

import { useInitializeCenter } from './useInitializeCenter';

const updateMarkerIcon = ({ marker, active }) => {
    const icon = active ? activeMarker : baseMarker;
    marker?.setOptions({
        icon: {
            url: icon,
            scaledSize: new window.google.maps.Size(40, 40),
        },
    });
};

export const useMarkers = ({
    locations,
    map,
    selectedLocation,
    setSelectedLocation,
}) => {
    const markers = useMemo(() => map ? locations.map(location => {
            const collectionLocation = checkCorporateLocation(location)
                ? location.collection_location
                : location;
            const position = {
                lat: collectionLocation.geo_location.lat,
                lng: collectionLocation.geo_location.lon
            };

            return {
                location,
                position,
                marker: new window.google.maps.Marker({
                    position,
                    map,
                    icon: {
                        url: baseMarker,
                        scaledSize: new window.google.maps.Size(40, 40),
                    },
                }),
            };
        }) : [], [locations, map]);

    useInitializeCenter(markers, map);

    const selectedLocationId = useRef(selectedLocation?.id);

    useEffect(() => {
        for (const { marker, location } of markers) {
            marker?.addListener('click', () => setSelectedLocation(location));

            marker?.addListener('mouseover', () => updateMarkerIcon({ marker, active: true }));
            marker?.addListener('mouseout', () => {
                if (location.id === selectedLocationId.current) {
                    return;
                }

                updateMarkerIcon({ marker, active: false });
            });
        }
    }, [markers, setSelectedLocation])

    useEffect(() => {
        markers.forEach(({ marker, location }) => {
            const active = location.id === selectedLocation?.id;

            if (active) {
                selectedLocationId.current = selectedLocation.id;
            }

            updateMarkerIcon({ marker, active });
        });
    }, [selectedLocation, markers]);

    return { markers };
};
