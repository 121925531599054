/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import { ANDROID_APP_LINK, IOS_APP_LINK } from 'constants/appLinks';

import React, { memo, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isMobile, isDesktop, isAndroid } from 'react-device-detect';
import { Button, theme, createUseStyles } from '@flowhealth/ui-components';

import { openCamera } from 'containers/App/actions';


const useStyles = createUseStyles(({ palette }) => ({
    title: {
        ...theme.typography['20B'],
        color: palette.coal[700],
        marginBottom: 12,
    },
    subtitle: {
        ...theme.typography['16R'],
        color: palette.coal[600],
        marginBottom: 24,
        '@media (min-width:661px)': {
            marginBottom: 16,
            fontWeight: 500,
            color: palette.black[100],
        },
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '@media (min-width:661px)': {
            flexDirection: 'row',
            justifyContent: 'space-between',

            '& > button': {
                whiteSpace: 'nowrap',
            },
        },
    },
    link: {
        width: '100%',
        marginBottom: 16,
        textDecoration: 'none',
        '@media (min-width:661px)': {
            marginBottom: 0,
            marginRight: 12,
            minWidth: 'calc(50% - 6px)',
        },

        '& > button': { width: '100%' },
    },
    bottomButton: {
        width: '100%',
        background: 'transparent !important',
        color: `${palette.naviBlue[300]} !important`,
        fontSize: '16px !important',
        fontWeight: '600  !important',
        lineHeight: '24px !important',
        '& > button': {
            color: `${palette.naviBlue[300]} !important`,
            background: 'transparent !important',
        },
    },
}));

const ScannerInfo = ({ dispatch }) => {
    const classes = useStyles();
    const link = isAndroid ? ANDROID_APP_LINK : IOS_APP_LINK;

    const textContent = useMemo(() => {
        if (isMobile) {
            return (
                <div className={classes.subtitle}>
                    If the camera doesn't detect your test, check and update your mobile app to the latest version. <br />
                    If that still doesn't work, try to pull back your phone and then move it closer again.
                </div>
            );
        }

        return (
            <div className={classes.subtitle}>
                If the camera doesn't detect your test, try to pull back your phone and then move it closer again. <br />
                If that still doesn't work, please use our mobile applications to submit your result. It's much more convenient there!
            </div>
        );
    }, [classes.subtitle]);

    const linkButton = useMemo(() => {
        if (isDesktop) return null;

        return (
            <a className={classes.link} href={link}>
                <Button label="Take me to the app" />
            </a>
        );
    }, [classes.link, link]);

    const handleTakePhoto = () => {
        dispatch(openCamera());
    };

    return (
        <>
            <div className={classes.title}>Issues with scanning?</div>
            {textContent}
            <div className={classes.buttonContainer}>
                {linkButton}
                <Button
                    className={classes.bottomButton}
                    theme="text"
                    label="Take a Photo Manually"
                    onClick={handleTakePhoto}
                />
            </div>
        </>
    );
};

const withConnect = connect();

export default compose(
    withConnect,
    memo,
)(ScannerInfo);
