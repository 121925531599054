import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';

export const logoutRequest = payload => ({
    type: ACTION_TYPES.LOGOUT_REQUEST,
    payload,
});

export const logoutSuccess = () => ({
    type: ACTION_TYPES.LOGOUT_SUCCESS,
    payload: '',
});

export const logoutFailure = message => ({
    type: ACTION_TYPES.LOGOUT_FAILURE,
    payload: message,
});

export const setFormLoading = createAction(ACTION_TYPES.SET_FORM_LOADING);
export const setInputValue = createAction(ACTION_TYPES.SET_INPUT_VALUE);
export const setInputTouched = createAction(ACTION_TYPES.SET_INPUT_TOUCHED);
export const setErrorMessage = createAction(ACTION_TYPES.SET_ERROR_MESSAGE);
export const resetState = createAction(ACTION_TYPES.RESET_STATE);

export const authRequest = createAction(ACTION_TYPES.AUTH_REQUEST);
export const authRequestSuccess = createAction(ACTION_TYPES.AUTH_REQUEST_SUCCESS);
export const authRequestFailure = createAction(ACTION_TYPES.AUTH_REQUEST_FAILURE);

export const setPortalUseCase = createAction(ACTION_TYPES.SET_PORTAL_USE_CASE);
export const setPatientId = createAction(ACTION_TYPES.SET_PATIENT_ID);
export const setPatientName = createAction(ACTION_TYPES.SET_PATIENT_NAME);
export const setPatientPhone = createAction(ACTION_TYPES.SET_PATIENT_PHONE);
export const setRedirectUrl = createAction(ACTION_TYPES.SET_REDIRECT_URL);
export const setPracticeName = createAction(ACTION_TYPES.SET_PRACTICE_NAME);
export const setPracticeId = createAction(ACTION_TYPES.SET_PRACTICE_ID);

export const verifyPatientRequest = createAction(ACTION_TYPES.VERIFY_PATIENT_REQUEST);
export const verifyPatientRequestSuccess = createAction(ACTION_TYPES.VERIFY_PATIENT_REQUEST_SUCCESS);
export const verifyPatientRequestFailure = createAction(ACTION_TYPES.VERIFY_PATIENT_REQUEST_FAILURE);

export const sendCodeRequest = createAction(ACTION_TYPES.SEND_CODE_REQUEST);
export const sendCodeSuccess = createAction(ACTION_TYPES.SEND_CODE_SUCCESS);
export const sendCodeFailure = createAction(ACTION_TYPES.SEND_CODE_FAILURE);

export const verifyCodeRequest = createAction(ACTION_TYPES.VERIFY_CODE_REQUEST);
export const verifyCodeSuccess = createAction(ACTION_TYPES.VERIFY_CODE_SUCCESS);
export const verifyCodeFailure = createAction(ACTION_TYPES.VERIFY_CODE_FAILURE);

export const resetPasswordRequest = createAction(ACTION_TYPES.RESET_PASSWORD_REQUEST);
export const resetPasswordRequestSuccess = createAction(ACTION_TYPES.RESET_PASSWORD_REQUEST_SUCCESS);
export const resetPasswordRequestFailure = createAction(ACTION_TYPES.RESET_PASSWORD_REQUEST_FAILURE);

export const setRegisterFormValue = createAction(ACTION_TYPES.SET_REGISTER_FORM_VALUE);

export const getAddressRequest = createAction(ACTION_TYPES.GET_ADDRESS_REQUEST);
export const getAddressSuccess = createAction(ACTION_TYPES.GET_ADDRESS_SUCCESS);
export const getAddressFailure = createAction(ACTION_TYPES.GET_ADDRESS_FAILURE);

export const registerPatientRequest = createAction(ACTION_TYPES.REGISTER_PATIENT_REQUEST);
export const registerPatientSuccess = createAction(ACTION_TYPES.REGISTER_PATIENT_SUCCESS);
export const registerPatientFailure = createAction(ACTION_TYPES.REGISTER_PATIENT_FAILURE);

export const setPatientRequest = createAction(ACTION_TYPES.SET_PATIENT_REQUEST);

export const resetSearchError = createAction(ACTION_TYPES.RESET_SEARCH_ERROR);

export const searchBatchRequest = createAction(ACTION_TYPES.SEARCH_BATCH_REQUEST);
export const searchBatchSuccess = createAction(ACTION_TYPES.SEARCH_BATCH_SUCCESS);
export const searchBatchFailure = createAction(ACTION_TYPES.SEARCH_BATCH_FAILURE);

export const setEmployeeID = createAction(ACTION_TYPES.SET_EMPLOYEE_ID);
