/* eslint-disable max-len */
import React from 'react';

const Delivery = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.2823 9.21967C19.9894 8.92678 19.5145 8.92678 19.2216 9.21967C18.9287 9.51256 18.9287 9.98744 19.2216 10.2803L20.9413 12H15.752C15.3377 12 15.002 12.3358 15.002 12.75C15.002 13.1642 15.3377 13.5 15.752 13.5H20.9413L19.2216 15.2197C18.9287 15.5126 18.9287 15.9874 19.2216 16.2803C19.5145 16.5732 19.9894 16.5732 20.2823 16.2803L23.2823 13.2803C23.5752 12.9874 23.5752 12.5126 23.2823 12.2197L20.2823 9.21967Z" fill="#4F5862" />
        <path d="M10.7401 3.5177C10.271 3.29879 9.72904 3.29879 9.25995 3.5177L2.75995 6.55103C2.14382 6.83856 1.75 7.45693 1.75 8.13685V16.8631C1.75 17.543 2.14382 18.1614 2.75995 18.4489L9.25995 21.4823C9.72903 21.7012 10.271 21.7012 10.74 21.4823L17.24 18.4489C17.8562 18.1614 18.25 17.543 18.25 16.8631V15H16.75V16.8631C16.75 16.9603 16.6937 17.0486 16.6057 17.0897L10.75 19.8223V11.4776L16.75 8.67763V11H18.25V8.13685C18.25 7.45693 17.8562 6.83856 17.24 6.55103L10.7401 3.5177ZM9.89428 4.87697C9.96129 4.8457 10.0387 4.8457 10.1057 4.87697L15.7265 7.49999L10 10.1723L4.27353 7.49999L9.89428 4.87697ZM9.25 11.4776V19.8223L3.39428 17.0897C3.30626 17.0486 3.25 16.9603 3.25 16.8631V8.67763L9.25 11.4776Z" fill="#4F5862" />
    </svg>
);

export default Delivery;
