import { useMemo } from 'react';
import { humanName } from '@flowhealth/utils';

import FlowSelect from 'components/FlowSelect';
import { getSingleValue } from 'components/FlowSelect/utils';

import { INSURANCE_FORM } from './constants';

const useFields = (
    formData = {},
    styleMap = {},
    persons,
    checkView,
    photos,

    setInputValue,
    setInputTouched,
) => useMemo(() => { // eslint-disable-line max-params
    const {
        person = {}, policy_holder = {},
        given = {}, family = {}, birth_date = {}, relation = {},
        payor = {}, member_id = {}, group_number = {},
    } = formData;

    const isAnotherPerson = getSingleValue(policy_holder.value)?.code === 'another'
        || policy_holder.value === 'Another Person';
    const isRelation = policy_holder.value
        && getSingleValue(policy_holder.value)?.code !== 'self'
        && policy_holder.value !== 'Self';
    const policyHolderValues = person.value ? [
        { code: 'self', display: 'Self' },
        ...persons
            .filter(p => p.id !== person?.value?.[0]?.code)
            .map(person => ({ code: person.id, display: humanName(person.name) })),
        { code: 'another', display: 'Another Person' },
    ] : [
        ...persons.map(person => ({ code: person.id, display: humanName(person.name) })),
        { code: 'another', display: 'Another Person' },
    ];

    return {
        fields: [
            {
                config: checkView('person') ? {
                    label: 'Patient Covered by Insurance',
                    id: 'person',
                    type: 'flowSelect',
                    disabled: true,
                } : {
                    label: 'Patient Covered by Insurance',
                    id: 'person',
                    type: 'flowSelect',
                    selectType: FlowSelect.SELECT_TYPES.raw,
                    rawData: persons.map(person => ({ code: person.id, display: humanName(person.name) })),
                    clearable: false,
                    required: true,
                    className: styleMap.flowSelect,
                },
                disabled: checkView('person'),
                onChange: data => {
                    setInputValue({ form: INSURANCE_FORM, data });
                    if (policy_holder.value
                        && getSingleValue(data.value)?.code === getSingleValue(policy_holder.value)?.code) {
                        setInputValue({
                            form: INSURANCE_FORM,
                            data: {
                                id: 'policy_holder',
                                value: [{ code: 'self', display: 'Self' }],
                            },
                        });
                    }
                },
                onFocus: data => setInputTouched({ form: INSURANCE_FORM, data }),
                value: person.value,
                touched: person.touched,
                valid: person.valid,
            },
            {
                config: checkView('policy_holder') ? {
                    label: 'Policy Holder',
                    id: 'policy_holder',
                    type: 'text',
                    disabled: true,
                } : {
                    label: 'Policy Holder',
                    id: 'policy_holder',
                    type: 'flowSelect',
                    selectType: FlowSelect.SELECT_TYPES.raw,
                    rawData: policyHolderValues,
                    clearable: false,
                    required: true,
                    className: styleMap.flowSelect,
                },
                disabled: checkView('policy_holder'),
                onChange: data => setInputValue({ form: INSURANCE_FORM, data }),
                onFocus: data => setInputTouched({ form: INSURANCE_FORM, data }),
                value: policy_holder.value,
                touched: policy_holder.touched,
                valid: policy_holder.valid,
            },
            {
                config: {
                    label: 'First Name',
                    id: 'given',
                    type: 'text',
                    required: isAnotherPerson && !checkView('given'),
                    disabled: checkView('given'),
                },
                notVisible: !isAnotherPerson,
                onChange: data => setInputValue({ form: INSURANCE_FORM, data }),
                onFocus: data => setInputTouched({ form: INSURANCE_FORM, data }),
                value: given.value,
                touched: given.touched,
                valid: given.valid,
            },
            {
                config: {
                    label: 'Last Name',
                    id: 'family',
                    type: 'text',
                    required: isAnotherPerson && !checkView('family'),
                    disabled: checkView('family'),
                },
                notVisible: !isAnotherPerson,
                onChange: data => setInputValue({ form: INSURANCE_FORM, data }),
                onFocus: data => setInputTouched({ form: INSURANCE_FORM, data }),
                value: family.value,
                touched: family.touched,
                valid: family.valid,
            },
            {
                config: checkView('birth_date') ? {
                    label: 'Date of Birth',
                    id: 'birth_date',
                    type: 'text',
                    disabled: true,
                } : {
                    label: 'Date of Birth',
                    id: 'birth_date',
                    type: 'flowDateTime',
                    fieldType: 'date',
                    required: isAnotherPerson && !checkView('birth_date'),
                    className: styleMap.flowDateTime,
                    disabled: checkView('birth_date'),
                },
                notVisible: !isAnotherPerson,
                onChange: data => setInputValue({ form: INSURANCE_FORM, data }),
                onFocus: data => setInputTouched({ form: INSURANCE_FORM, data }),
                value: birth_date.value,
                touched: birth_date.touched,
                valid: birth_date.valid,
            },
            {
                config: checkView('relation') ? {
                    label: 'Relationship to Insured',
                    id: 'relation',
                    type: 'text',
                    disabled: true,
                } : {
                    label: 'Relationship to Insured',
                    id: 'relation',
                    type: 'flowSelect',
                    selectType: FlowSelect.SELECT_TYPES.valueSet,
                    itemsFilter: ({ code }) => code !== 'self',
                    file: 'policyholder-relationship',
                    clearable: false,
                    required: isRelation,
                    className: styleMap.flowSelect,
                },
                disabled: checkView('relation'),
                notVisible: !isRelation,
                onChange: data => setInputValue({ form: INSURANCE_FORM, data }),
                onFocus: data => setInputTouched({ form: INSURANCE_FORM, data }),
                value: relation.value,
                touched: relation.touched,
                valid: relation.valid,
            },

            {
                config: checkView('payor') ? {
                    label: 'Insurance',
                    id: 'payor',
                    type: 'text',
                    disabled: true,
                } : {
                    label: 'Insurance',
                    id: 'payor',
                    type: 'flowSelect',
                    selectType: FlowSelect.SELECT_TYPES.search,
                    object: 'payor',
                    clearable: false,
                    required: true,
                    className: styleMap.flowSelect,
                },
                disabled: checkView('payor'),
                onChange: data => setInputValue({ form: INSURANCE_FORM, data }),
                onFocus: data => setInputTouched({ form: INSURANCE_FORM, data }),
                value: payor.value,
                touched: payor.touched,
                valid: payor.valid,
            },
            {
                config: {
                    label: 'Member ID',
                    id: 'member_id',
                    type: 'text',
                    required: true && !checkView('member_id'),
                    disabled: checkView('member_id'),
                },
                onChange: data => setInputValue({ form: INSURANCE_FORM, data }),
                onFocus: data => setInputTouched({ form: INSURANCE_FORM, data }),
                value: member_id.value,
                touched: member_id.touched,
                valid: member_id.valid,
            },
            {
                config: {
                    label: 'Group Number',
                    id: 'group_number',
                    type: 'text',
                    disabled: checkView('group_number'),
                },
                notVisible: checkView('group_number') && !group_number.value,
                onChange: data => setInputValue({ form: INSURANCE_FORM, data }),
                onFocus: data => setInputTouched({ form: INSURANCE_FORM, data }),
                value: group_number.value,
                touched: group_number.touched,
                valid: group_number.valid,
            },
            {
                config: {
                    label: 'Upload Photo of Insurance Card',
                    id: 'photo_front',
                    type: 'photoUpload',
                    title: 'Front Side',
                    file: checkView('photo_front') ? photos.photo_front.file : undefined,
                    required: true && !checkView('photo_front'),
                    previewOnly: checkView('photo_front'),
                },
                notVisible: checkView('photo_front') && !photos.photo_front.file,
                onChange: photos.photo_front.onChange,
                value: photos.photo_front.value,
                touched: photos.photo_front.value,
                valid: photos.photo_front.value,
            },
            {
                config: {
                    label: checkView('photo_back') && !photos.photo_front.file
                        ? 'Upload Photo of Insurance Card'
                        : undefined,
                    id: 'photo_back',
                    type: 'photoUpload',
                    title: 'Back Side',
                    file: checkView('photo_back') ? photos.photo_back.file : undefined,
                    required: true && !checkView('photo_back'),
                    previewOnly: checkView('photo_back'),
                },
                notVisible: checkView('photo_back') && !photos.photo_back.file,
                onChange: photos.photo_back.onChange,
                value: photos.photo_back.value,
                touched: photos.photo_back.value,
                valid: photos.photo_back.value,
            },
        ],
        styleMap,
    };
},
[setInputValue, setInputTouched, formData, persons, styleMap, checkView, photos]);

export default useFields;
