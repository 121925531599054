export const OPEN_SUCCESS_MESSAGE = 'OPEN_SUCCESS_MESSAGE';
export const CLOSE_SUCCESS_MESSAGE = 'CLOSE_SUCCESS_MESSAGE';

export const OPEN_ERROR_MESSAGE = 'OPEN_ERROR_MESSAGE';
export const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE';

export const OPEN_WARNING_MESSAGE = 'OPEN_WARNING_MESSAGE';
export const CLOSE_WARNING_MESSAGE = 'CLOSE_WARNING_MESSAGE';

export const OPEN_CUSTOM_MESSAGE = 'OPEN_CUSTOM_MESSAGE';
export const CLOSE_CUSTOM_MESSAGE = 'CLOSE_CUSTOM_MESSAGE';

const MESSAGE_TYPE_SUCCESS = 'success';
const MESSAGE_TYPE_ERROR = 'error';
const MESSAGE_TYPE_WARNING = 'warning';
const MESSAGE_TYPE_CUSTOM = 'custom';

export const MESSAGE_TYPES = {
    [MESSAGE_TYPE_SUCCESS]: MESSAGE_TYPE_SUCCESS,
    [MESSAGE_TYPE_ERROR]: MESSAGE_TYPE_ERROR,
    [MESSAGE_TYPE_WARNING]: MESSAGE_TYPE_WARNING,
    [MESSAGE_TYPE_CUSTOM]: MESSAGE_TYPE_CUSTOM,
};
