import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';


export const resetState = createAction(ACTION_TYPES.RESET_STATE);
export const resetScannerState = createAction(ACTION_TYPES.RESET_SCANNER_STATE);

export const setLoading = createAction(ACTION_TYPES.SET_LOADING);
export const setPatient = createAction(ACTION_TYPES.SET_PATIENT);
export const setTestResult = createAction(ACTION_TYPES.SET_TEST_RESULT);
export const setBatch = createAction(ACTION_TYPES.SET_BATCH);

export const getRefRangesRequest = createAction(ACTION_TYPES.GET_REF_RANGES_REQUEST);
export const getRefRangesSuccess = createAction(ACTION_TYPES.GET_REF_RANGES_SUCCESS);
export const getRefRangesFailure = createAction(ACTION_TYPES.GET_REF_RANGES_FAILURE);

export const submitTestRequest = createAction(ACTION_TYPES.SUBMIT_TEST_REQUEST);
export const submitTestSuccess = createAction(ACTION_TYPES.SUBMIT_TEST_SUCCESS);
export const submitTestFailure = createAction(ACTION_TYPES.SUBMIT_TEST_FAILURE);
export const wsPocActionChangeSuccess = createAction(ACTION_TYPES.WS_POC_ACTION_CHANGE_SUCCESS);
export const wsPocActionChangeFailure = createAction(ACTION_TYPES.WS_POC_ACTION_CHANGE_FAILURE);

export const wsSubscribe = createAction(ACTION_TYPES.WS_SUBSCRIBE);
export const wsUnsubscribe = createAction(ACTION_TYPES.WS_UNSUBSCRIBE);
export const wsSuccess = createAction(ACTION_TYPES.WS_SUCCESS);
export const wsFailure = createAction(ACTION_TYPES.WS_FAILURE);

export const copyToPracticeRequest = createAction(ACTION_TYPES.COPY_TO_PRACTICE_REQUEST);
export const copyToPracticeSuccess = createAction(ACTION_TYPES.COPY_TO_PRACTICE_SUCCESS);
export const copyToPracticeFailure = createAction(ACTION_TYPES.COPY_TO_PRACTICE_FAILURE);
export const copyToPracticeFinished = createAction(ACTION_TYPES.COPY_TO_PRACTICE_FINISHED);

export const checkUniquePocActionRequest = createAction(ACTION_TYPES.CHECK_UNIQUE_POC_ACTION_REQUEST);
export const checkUniquePocActionSuccess = createAction(ACTION_TYPES.CHECK_UNIQUE_POC_ACTION_SUCCESS);
export const checkUniquePocActionFailure = createAction(ACTION_TYPES.CHECK_UNIQUE_POC_ACTION_FAILURE);

export const setError = createAction(ACTION_TYPES.SET_ERROR);
