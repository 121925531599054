import { createRef, useEffect, useState } from 'react';

import { MAP_CENTER, MAP_OPTIONS } from '../constants';

export const useMap = myLocation => {
    const [map, setMap] = useState();
    const ref = createRef();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {
                ...MAP_OPTIONS,
                center: myLocation ?? MAP_CENTER,
                zoomControlOptions: {
                    position: window.google.maps.ControlPosition.RIGHT_CENTER,
                },
            }));
        }
    }, [map, ref]);

    return { map, ref };
};
