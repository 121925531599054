import { put, takeLatest, call, all, takeEvery, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import dayjs from 'dayjs';
import API from '@flowhealth/api';
import { safeGet } from '@flowhealth/utils';
import { NPI } from '@flowhealth/constants';

import { WEBSOCKET_GET_MESSAGE } from 'API/websocket/constants';
import { webSocketSubscribe, webSocketUnsubscribe } from 'API/websocket/actions';

import { openErrorMessage } from 'components/Message/actions';

import { generateContainerCode } from 'utils/isInvalid';
import { isSpectrumDNA } from 'utils/isSpectrumDNA';
import runWhile from 'utils/runWhile';

import { COPY_TO_PRACTICE_ERROR, SOCKET_WAIT_TIME, COPY_TO_PRACTICE_WS_TYPE } from '../../constants';

import * as SELECTORS from './selectors';
import * as ACTION_TYPES from './actionTypes';
import * as ACTIONS from './actions';

const isNasalSWAB = specimenKitId => !isSpectrumDNA(specimenKitId);

const processingStatuses = {
    creatingRequest: 'creating-request',
    changeStatusToCollected: 'change-status-to-collected',
    laboratoryIdentification: 'laboratory-identification',
    containerTypeIdentification: 'container-type-identification',
    generateContainerCode: 'generate-container-code',
    creatingContainer: 'creating-container',
    addingTestPanel: 'adding-test-panel',
    successfullyCreated: 'successfully-created',
};

function* sendRequisition({ payload }) {
    const { onSuccess } = payload;

    const api = API.getInstance();
    const specimenKitId = yield select(SELECTORS.selectSpecimenKitId);
    const kitBatch = yield select(SELECTORS.selectKitBatch);
    const { appointment } = yield select(SELECTORS.selectFields) ?? {};
    let requisitionProcessing = yield select(SELECTORS.selectRequisitionProcessingById(specimenKitId));
    if (!requisitionProcessing) {
        requisitionProcessing = {
            id: specimenKitId,
            status: processingStatuses.creatingRequest,
        };
    }

    try {
        const copyPatientId = yield select(SELECTORS.selectCopyPatientId);

        if (copyPatientId) {
            yield delay(SOCKET_WAIT_TIME);

            const currentCopyPatientId = yield select(SELECTORS.selectCopyPatientId);

            if (currentCopyPatientId) {
                yield put(ACTIONS.sendRequisitionFailure({ error: COPY_TO_PRACTICE_ERROR }));
                yield put(openErrorMessage(COPY_TO_PRACTICE_ERROR));
                return;
            }
        }

        if (requisitionProcessing.status === processingStatuses.creatingRequest) {
            const patient = yield select(SELECTORS.selectPatient);
            const practice = yield select(SELECTORS.selectPractice);
            const insurance = yield select(SELECTORS.selectInsurance);
            const locationId = yield select(SELECTORS.selectLocationId);
            const practiceId = practice?.id || practice;

            const practitionerRoles = yield call(
                api.search,
                {
                    object: 'practitioner',
                    filters: `practice_ids:${practiceId}"AND"clinician:true`,
                },
            );

            const requisitionRequestData = {
                object_type: 'requisition',
                source_type: 'patient_portal',
                patient: patient.id,
                practice: practiceId,
                location: locationId,
                external_id: specimenKitId,
                collector: patient.role,
                collected_at: dayjs().toISOString(),
                payment: insurance ? { primary: { coverage: insurance.id } } : { self_pay: true },
            };
            if (appointment) {
                requisitionRequestData.appointment = appointment.id;
            }

            if (practitionerRoles.count === 1) {
                const firstPractitionerRoleId = practitionerRoles.first;
                requisitionRequestData.requester = firstPractitionerRoleId;
            }

            const createdRequisition = yield call(api.createGraph, requisitionRequestData);
            const requisitionId = safeGet(createdRequisition, 'id');

            yield put(ACTIONS.addRequisitionProcessing({
                id: specimenKitId,
                status: processingStatuses.changeStatusToCollected,
                requisitionId,
            }));
        }

        requisitionProcessing = yield select(SELECTORS.selectRequisitionProcessingById(specimenKitId));
        if (requisitionProcessing.status === processingStatuses.changeStatusToCollected) {
            yield call(
                api.createGraphOnEdge,
                requisitionProcessing.requisitionId,
                'statuses',
                {
                    object_type: 'req_status',
                    status: 'collected',
                },
            );

            yield put(ACTIONS.setRequisitionProcessingStatus({
                id: specimenKitId,
                status: processingStatuses.laboratoryIdentification,
            }));
        }

        requisitionProcessing = yield select(SELECTORS.selectRequisitionProcessingById(specimenKitId));
        if (requisitionProcessing.status === processingStatuses.laboratoryIdentification) {
            const practice = yield select(SELECTORS.selectPractice);

            const fhLabResponse = yield call(
                api.search,
                {
                    object: 'laboratory',
                    filters: `npi:${NPI.FHL_LABORATORY}`,
                },
            );

            const lab = safeGet(
                practice,
                'primary_laboratory.id',
                safeGet(practice, 'primary_laboratory', safeGet(fhLabResponse, 'results[0].id')),
            );

            yield put(ACTIONS.setRequisitionProcessingLab({
                id: specimenKitId,
                lab,
            }));
            yield put(ACTIONS.setRequisitionProcessingStatus({
                id: specimenKitId,
                status: processingStatuses.containerTypeIdentification,
            }));
        }

        requisitionProcessing = yield select(SELECTORS.selectRequisitionProcessingById(specimenKitId));
        if (
            requisitionProcessing.status === processingStatuses.containerTypeIdentification && (
                isSpectrumDNA(specimenKitId)
            )
        ) {
            const spectrumDNAType = yield call(api.search, {
                object: 'container_type',
                q: 'SpectrumDNA',
            });
            const containerType = safeGet(spectrumDNAType, 'results[0].id');

            /* eslint-disable max-depth */
            if (!containerType) {
                const error = new Error('Failed to create order. Please try again');
                yield put(openErrorMessage(error.message));
                yield put(ACTIONS.sendRequisitionFailure({ error }));
                return;
            }
            /* eslint-enable max-depth */

            yield put(ACTIONS.setRequisitionProcessingContainerType({
                id: specimenKitId,
                containerType,
            }));
            yield put(ACTIONS.setRequisitionProcessingStatus({
                id: specimenKitId,
                status: processingStatuses.generateContainerCode,
            }));
        }

        requisitionProcessing = yield select(SELECTORS.selectRequisitionProcessingById(specimenKitId));
        if (
            requisitionProcessing.status === processingStatuses.containerTypeIdentification && (
                isNasalSWAB(specimenKitId)
            )
        ) {
            const nasalSwabContainerTypes = yield call(
                api.search,
                {
                    object: 'container_type',
                    filters: 'specimen_type:nasal_swab',
                },
            );
            const containerTypes = safeGet(nasalSwabContainerTypes, 'results', []);
            const nasalSwabContainerType = containerTypes.find(containerType => (
                containerType.volume === 3 && containerType.units === 'ml'
            ));
            const containerType = safeGet(nasalSwabContainerType, 'id');

            /* eslint-disable max-depth */
            if (!containerType) {
                const error = new Error('Failed to create order. Please try again');
                yield put(openErrorMessage(error.message));
                yield put(ACTIONS.sendRequisitionFailure({ error }));
                return;
            }
            /* eslint-enable max-depth */

            yield put(ACTIONS.setRequisitionProcessingContainerType({
                id: specimenKitId,
                containerType,
            }));
            yield put(ACTIONS.setRequisitionProcessingStatus({
                id: specimenKitId,
                status: processingStatuses.generateContainerCode,
            }));
        }

        requisitionProcessing = yield select(SELECTORS.selectRequisitionProcessingById(specimenKitId));
        if (requisitionProcessing.status === processingStatuses.generateContainerCode) {
            const requisitionData = yield call(
                runWhile,
                () => api.getGraph(requisitionProcessing.requisitionId),
                response => !response.barcode && !response.accession,
            );

            const code = generateContainerCode(requisitionData.accession, 0);

            yield put(ACTIONS.setRequisitionProcessingCode({
                id: specimenKitId,
                code,
            }));
            yield put(ACTIONS.setRequisitionProcessingStatus({
                id: specimenKitId,
                status: processingStatuses.creatingContainer,
            }));
        }

        requisitionProcessing = yield select(SELECTORS.selectRequisitionProcessingById(specimenKitId));
        if (requisitionProcessing.status === processingStatuses.creatingContainer) {
            const config = [{
                object_type: 'container',
                performing_lab: requisitionProcessing.lab,
                receiving_lab: requisitionProcessing.lab,
                type: requisitionProcessing.containerType,
                requisition: requisitionProcessing.requisitionId,
                code: requisitionProcessing.code,
            }];

            yield call(api.createGraphOnEdge, requisitionProcessing.requisitionId, 'containers', config);
            yield put(ACTIONS.setRequisitionProcessingStatus({
                id: specimenKitId,
                status: processingStatuses.addingTestPanel,
            }));
        }

        requisitionProcessing = yield select(SELECTORS.selectRequisitionProcessingById(specimenKitId));
        if (requisitionProcessing.status === processingStatuses.addingTestPanel) {
            const covidPanelsResponse = yield call(api.search, {
                object: 'test_panel',
                filters: 'name_sort:COVID-19 PCR',
            });

            yield call(
                api.addGraphToEdge,
                requisitionProcessing.requisitionId,
                'selected',
                safeGet(covidPanelsResponse, 'results[0].id'),
            );

            yield put(ACTIONS.setRequisitionProcessingStatus({
                id: specimenKitId,
                status: processingStatuses.successfullyCreated,
            }));
        }

        requisitionProcessing = yield select(SELECTORS.selectRequisitionProcessingById(specimenKitId));
        if (requisitionProcessing.status === processingStatuses.successfullyCreated) {
            if (!appointment) {
                yield call(
                    api.addGraphToEdge,
                    safeGet(kitBatch, 'kit_batch.id'),
                    'requisitions',
                    requisitionProcessing.requisitionId,
                );
            }

            yield put(ACTIONS.sendRequisitionSuccess({ ...payload }));
            yield put(ACTIONS.removeRequisitionProcessing({ id: specimenKitId }));

            onSuccess();
        }
    } catch (error) {
        yield put(ACTIONS.sendRequisitionFailure({ error, showMessageIfUniqError: true }));
    }
}

function* copyToPractice({ payload = {} } = {}) {
    try {
        const {
            patient,
            practiceId,
        } = payload;

        if (!patient?.practice) {
            return;
        }

        const copyPatient = yield call(
            API.getInstance().createGraph,
            {
                object_type: 'copy_patient',
                patient: patient.id,
                practice: patient.practice.id,
            },
        );

        yield put(ACTIONS.copyToPracticeSuccess({ copyPatientId: copyPatient.id }));

        yield put(ACTIONS.wsSubscribe({ group: copyPatient.id, type: COPY_TO_PRACTICE_WS_TYPE }));

        yield call(
            API.getInstance().addGraphToEdgeBulk,
            copyPatient.id,
            'to_practices',
            [practiceId],
        );

        yield call(
            API.getInstance().patchGraph,
            copyPatient.id,
            {
                status: 'processing',
            },
        );
    } catch (error) {
        yield put(ACTIONS.copyToPracticeFailure({ error }));
    }
}

function* onWS({ payload: { message } } = {}) {
    const { group, delta, type } = message || {};
    if (type !== COPY_TO_PRACTICE_WS_TYPE || !delta) return;

    try {
        const copyPatientId = yield select(SELECTORS.selectCopyPatientId);
        const batchPractice = yield select(SELECTORS.selectKitBatchPractice);

        if (copyPatientId !== group) return;

        const { failed_practices } = delta;
        const isFailed = failed_practices.includes(batchPractice);

        if (!isFailed) {
            const copiedPatientResponse = yield call(API.getInstance().getGraph, group, 'copy_patients');
            const copiedPatient = safeGet(copiedPatientResponse, 'copy_patients.results[0]');

            yield put(ACTIONS.setPatient(copiedPatient));
            yield put(ACTIONS.copyToPracticeFinished());
        } else {
            yield put(openErrorMessage(COPY_TO_PRACTICE_ERROR));
        }
    } catch (error) {
        yield put(ACTIONS.wsFailure({ error }));
    }

    yield put(ACTIONS.wsUnsubscribe({ group, type }));
}

function* wsSubscribe({ payload: { group, type } = {} } = {}) {
    if (!group) return;

    yield put(webSocketSubscribe({
        payload: [{
            group,
            type: [type],
        }],
    }));
}

function* wsUnsubscribe({ payload: { group, type } = {} } = {}) {
    if (!group) return;

    yield put(webSocketUnsubscribe({
        payload: [{
            group,
            type: [type],
        }],
    }));
}

export default function* Saga() {
    yield all([
        takeLatest(ACTION_TYPES.SEND_REQUISITION_REQUEST, sendRequisition),
        takeLatest(ACTION_TYPES.COPY_TO_PRACTICE_REQUEST, copyToPractice),
        takeEvery(ACTION_TYPES.WS_SUBSCRIBE, wsSubscribe),
        takeEvery(ACTION_TYPES.WS_UNSUBSCRIBE, wsUnsubscribe),
        takeEvery(WEBSOCKET_GET_MESSAGE, onWS),
    ]);
}
