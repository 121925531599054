import { useMediaQuery } from 'react-responsive';

export const BREAKPOINTS_VARIABLES = {
    sm: 768,
    md: 1104,
    lg: 1920,
};

const getBreakpointBorder = breakpoint => breakpoint - 1;

export const useMobileBreakpoint = () => useMediaQuery({
    maxWidth: getBreakpointBorder(BREAKPOINTS_VARIABLES.sm),
});

export const useTabletBreakpoint = () => useMediaQuery({
    minWidth: BREAKPOINTS_VARIABLES.sm,
    maxWidth: getBreakpointBorder(BREAKPOINTS_VARIABLES.md),
});

export const useDesktopBreakpoint = () => useMediaQuery({
    minWidth: BREAKPOINTS_VARIABLES.md,
    maxWidth: getBreakpointBorder(BREAKPOINTS_VARIABLES.lg),
});

export const useBigDesktopBreakpoint = () => useMediaQuery({
    minWidth: BREAKPOINTS_VARIABLES.lg,
});
