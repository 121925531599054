import { getBoolean, getString } from "firebase/remote-config";
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import { tryToParse } from 'utils/tryParse';

import * as KEYS from './keys';


const selectBoolean = key => config => config ? getBoolean(config, key) : undefined;
const selectString = key => config => config ? getString(config, key) : undefined;

const selectJSON = key => config => {
    const value = selectString(key)(config)?.trim();
    return !isNil(value) && !isEmpty(value) ? tryToParse(value) : undefined;
}

export const selectAnalyticsPosthog = selectBoolean(KEYS.ANALYTICS_POSTHOG_KEY);
export const selectSupportQuestions = selectJSON(KEYS.SUPPORT_QUESTIONS_KEY);
export const selectOrderPriority = selectJSON(KEYS.ORDER_PRIORITY_KEY);
export const selectHotlinePhone = selectString(KEYS.HOTLINE_PHONE_KEY);
export const selectRequisitionStatus = selectJSON(KEYS.REQUISITION_STATUS_KEY);
export const selectTestResult = selectJSON(KEYS.TEST_RESULT_KEY);
