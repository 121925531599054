import React, { useRef, memo } from 'react';
import Webcam from 'react-webcam';
import { isMobile, isIOS } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { isFunction } from 'lodash';
import { IMAGE_PNG } from '@flowhealth/constants';
import { createUseStyles } from '@flowhealth/ui-components';

import FlowIcon from 'components/FlowIcon';
import { ICONS_TYPES } from 'components/FlowIcon/constants';

import { closeCamera } from './actions';

const useStyles = createUseStyles(({ palette }) => ({
    camera: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100vw',
        height: isMobile && isIOS ? '-webkit-fill-available' : '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10000,
        background: palette.black[100],
        '& video': {
            width: '100%',
        },
    },
    webcamBtn: {
        width: 56,
        height: 56,
        margin: 'auto',
        background: palette.white.main,
        border: `1px solid ${palette.gray.main}`,
        borderRadius: '50%',
        position: 'fixed',
        bottom: 25,
        transform: 'translate(-50%, -50%)',
        left: '50%',
        cursor: 'pointer',
        '&::before': {
            width: 72,
            height: 72,
            content: "''",
            display: 'block',
            border: `2px solid ${palette.white.main}`,
            transform: 'inherit',
            borderRadius: 'inherit',
            position: 'absolute',
            left: 'inherit',
        },
    },
    webcamCloseIcon: {
        position: 'absolute',
        top: 25,
        right: 25,
        cursor: 'pointer',
        zIndex: 2001,
        '& svg': {
            fill: palette.white.main,
        },
    },
}));

const Camera = ({ onTakePhoto, LensComponent, onImageReady, onClose }) => {
    const classes = useStyles();
    const webcamRef = useRef();
    const dispatch = useDispatch();

    const handleTakePhotoDesktop = () => {
        const photo = webcamRef.current.getScreenshot();

        if (!photo) return;

        if (isFunction(onTakePhoto)) onTakePhoto(photo);

        onImageReady?.(photo);
        dispatch(closeCamera());
    };

    return (
        <div className={classes.camera}>
            <FlowIcon
                className={classes.webcamCloseIcon}
                size={25}
                type={ICONS_TYPES.close}
                onClick={() => {
                    onClose();
                    dispatch(closeCamera());
                }}
            />
            <Webcam
                ref={webcamRef}
                screenshotFormat={IMAGE_PNG}
                showLens={false}
                audio={false}
                videoConstraints={{
                    facingMode: { ideal: 'environment' },
                }}
                forceScreenshotSourceSize
            />
            {LensComponent && <LensComponent />}
            <button className={classes.webcamBtn} type="button" onClick={handleTakePhotoDesktop} />
        </div>
    );
};

export default memo(Camera);
