/* eslint-disable max-len */
import React from 'react';

const Calendar = () => (
    <svg viewBox="0 0 20 20">
        <path
            fillRule="evenodd"
            d="M16.971 9H2.997V5.971A.97.97 0 0 1 3.967 5h1.027v2H6.99V5h5.988v2h1.996V5h.999a1 1 0 0 1 .998 1v3zm0 7c0 .55-.449 1-.998 1H3.995a1 1 0 0 1-.998-1v-5H16.97v5zm-15.907.761c0 1.104.957 2.239 2.06 2.239h13.974C18.201 19 19 17.979 19 16.761c0-.389-.032-10.401-.032-11.053 0-2.082-.28-2.708-3.994-2.708V1h-1.996v2H6.99V1H4.994v2H2.997C1.899 3 1 3.9 1 5l.064 11.761z"
            clipRule="evenodd"
        />
    </svg>
);

export default Calendar;
