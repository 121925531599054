/* eslint-disable max-len */
import React from 'react';


const Edit = () => (
    <svg viewBox="0 0 16 16">
        <path
            fillRule="evenodd"
            d="M13.318 3.62l-1.13-1.13-.917.915 1.13 1.13.917-.915zm-4.16-.365L3.04 9.373c-.093.094-.093.246 0 .34l3.055 3.055c.094.093.246.093.34 0l8.015-8.016.05-.052.164-.164c.625-.625.625-1.638 0-2.263L13.533 1.14c-.625-.624-1.638-.624-2.263 0L9.177 3.235l-.019.02zm2.113 2.413L10.14 4.537 5.133 9.543l1.132 1.132 5.006-5.007zm-9.248 8.753c-.287.071-.55-.184-.487-.473l.768-3.517c.04-.185.27-.253.404-.118l2.858 2.857c.132.133.069.358-.112.403l-3.43.848z"
            clipRule="evenodd"
        />
    </svg>
);

export default Edit;
