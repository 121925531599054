import { createUseStyles } from '@flowhealth/ui-components';

const CONTENT_WIDTH = 480;
const NAVIGATION_DESKTOP_WIDTH = 312;
const NAVIGATION_TABLET_WIDTH = 144;

export const useStyles = createUseStyles(({ palette, typography }) => ({
    container: ({
        isTablet,
        isDesktop,
        isBigDesktop,
    }) => {
        const styles = {
            width: '100vw',
            height: '100vh',
        };

        if (isTablet) {
            styles['display'] = 'grid';
            styles['justifyContent'] = 'space-between';
            styles['gridTemplateColumns'] = `${NAVIGATION_TABLET_WIDTH}px ${CONTENT_WIDTH}px ${NAVIGATION_TABLET_WIDTH}px`;
        } else if (isDesktop) {
            styles['display'] = 'grid';
            styles['justifyContent'] = 'space-between';
            styles['gridTemplateColumns'] = `${NAVIGATION_DESKTOP_WIDTH}px ${CONTENT_WIDTH}px ${NAVIGATION_DESKTOP_WIDTH}px`;
        } else if (isBigDesktop) {
            styles['display'] = 'grid';
            styles['justifyContent'] = 'center';
            styles['gridTemplateColumns'] = `${NAVIGATION_DESKTOP_WIDTH}px ${CONTENT_WIDTH}px ${NAVIGATION_DESKTOP_WIDTH}px`;
            styles['column-gap'] = 96;
        }

        return styles;
    },
    sidebar: ({ isMobile }) => {
        const styles = {
            zIndex: 10,
        };
        if (isMobile) {
            styles['background'] = palette.white[100]; // eslint-disable-line prefer-destructuring
            styles['boxShadow'] = 'inset 0px 0.5px 0px rgba(0, 0, 0, 0.1)';
            styles['position'] = 'fixed';
            styles['height'] = 56;
            styles['bottom'] = 0;
            styles['left'] = 0;
            styles['right'] = 0;
        } else {
            styles['position'] = 'relative';
        }
        return styles;
    },
    navigation: ({ isMobile }) => {
        const styles = {
            position: 'sticky',
        };

        if (isMobile) {
            styles['height'] = '100%';
        } else {
            styles['position'] = 'sticky';
            styles['padding'] = 44;
            styles['top'] = 0;
        }

        return styles;
    },
    logoLink: ({ isTablet }) => ({
        display: 'block',
        height: 32,
        marginBottom: 24,
        width: isTablet ? 56 : 224,

        '& > img': {
            width: '100%',
            height: '100%',
        }
    }),
    navList: ({ isMobile }) => {
        const styles = {
            padding: 0,
            margin: 0,
        };

        if (isMobile) {
            styles['display'] = 'flex';
            styles['justifyContent'] = 'space-between';
        }
        
        return styles;
    },
    content: ({ isMobile }) => {
        const styles = {
            display: 'flex',
            flexDirection: 'column',
        };
        if (isMobile) {
            styles['padding'] = '32px 20px 56px';
        } else {
            styles['padding'] = '40px 0 0';
        }

        return styles;
    }
}));
