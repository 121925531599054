import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';


export const resetState = createAction(ACTION_TYPES.RESET_STATE);

export const setLoading = createAction(ACTION_TYPES.SET_LOADING);
export const setPatient = createAction(ACTION_TYPES.SET_PATIENT);
export const setTestResult = createAction(ACTION_TYPES.SET_TEST_RESULT);
export const setBatch = createAction(ACTION_TYPES.SET_BATCH);

export const getRefRangesRequest = createAction(ACTION_TYPES.GET_REF_RANGES_REQUEST);
export const getRefRangesSuccess = createAction(ACTION_TYPES.GET_REF_RANGES_SUCCESS);
export const getRefRangesFailure = createAction(ACTION_TYPES.GET_REF_RANGES_FAILURE);

export const submitTestRequest = createAction(ACTION_TYPES.SUBMIT_TEST_REQUEST);
export const submitTestSuccess = createAction(ACTION_TYPES.SUBMIT_TEST_SUCCESS);
export const submitTestFailure = createAction(ACTION_TYPES.SUBMIT_TEST_FAILURE);

export const wsSubscribe = createAction(ACTION_TYPES.WS_SUBSCRIBE);
export const wsUnsubscribe = createAction(ACTION_TYPES.WS_UNSUBSCRIBE);

export const copyToPracticeRequest = createAction(ACTION_TYPES.COPY_TO_PRACTICE_REQUEST);
export const copyToPracticeSuccess = createAction(ACTION_TYPES.COPY_TO_PRACTICE_SUCCESS);
export const copyToPracticeFailure = createAction(ACTION_TYPES.COPY_TO_PRACTICE_FAILURE);
export const copyToPracticeFinished = createAction(ACTION_TYPES.COPY_TO_PRACTICE_FINISHED);
