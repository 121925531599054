import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
    safeGet,
    formatPhone,
} from '@flowhealth/utils';
import { App } from '@flowhealth/app';
import { isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import { getBoolean } from 'firebase/remote-config';

import { NavigationLayout } from 'components/NavigationLayout';

import { makeSelectRoleId } from 'containers/User/selector';
import { logoutRequest } from 'containers/Auth/actions';
import * as PATIENT_PORTAL_ACTIONS from 'containers/Auth/actions';
import saga from 'containers/Auth/saga';
import {
    selectPatientPhone,
    selectPatientId,
    selectResetToken,
    selectPortalUseCase,
    selectRedirectUrl,
} from 'containers/Auth/selector';
import {
    PATIENT_PORTAL_AUTH_KEY,
    PATIENT_PORTAL_USE_CASES,
} from 'containers/Auth/constants';
import { Firebase } from 'containers/Firebase';
import { HEALTH_PASSPORT_PATH } from 'containers/PatientPortal/HealthPassport/constants';
import { USER_CHANGE_NAME_PATH } from 'containers/PatientPortal/UserChangeName/constants';
import { USER_CHANGE_EMAIL_PATH } from 'containers/PatientPortal/UserChangeEmail/constants';
import { USER_CHANGE_PASSWORD } from 'containers/PatientPortal/UserChangePassword/constants';
import { DELETE_ACCOUNT_PATH } from 'containers/PatientPortal/DeleteAccount/constants';

import {
    APPOINTMENTS_PATH,
    CONSENT_PATH ,
    CONNECT_PATIENT_PATH,
    CONNECT_TO_PRACTICE_PATH,
    LOGIN_PATH,
    PATIENT_PROFILES_PATH,
    ADD_TEST_PATH,
    INSURANCE_PATH,
    REGISTER_PATH,
    LINKED_PATH,
    HTK_PATH,
    BOOKING_PATH,
    COVID19_PCR_PATH,
    PASSWORD_CREATE_PATH,
    ORDER_PACKAGE_INFO_PATH,
    ORDER_PACKAGE_SELECT_PATH,
    ORDER_PACKAGE_SUCCESS_PATH,
    ORDERS_PATH,
    USER_ACCOUNT_PATH,
    USER_SETTINGS_PATH,
} from 'configuration/paths';
import { FIREBASE_CONFIG } from 'configuration/patientPortal/firebaseConfig';
import { Posthog } from 'configuration/Posthog';

import injectSaga from 'utils/injectSaga';
import { DAEMON } from 'utils/constants';
import { parse } from 'utils/query-string';
import select from 'utils/select';
import { redirectToResetPassword } from 'utils/navigate';
import { checkTokenExistence } from 'utils/checkTokenExistence';
import { isPublicURL } from 'utils/getPublicURL';

import { makeSelectGetUserLoading, makeSelectRole, makeSelectUserData } from '../User/selector';
import { userRequest } from '../User/actions';
import { consentsRequest } from '../PatientPortal/store/actions';
import { KEY_PATIENT_PORTAL } from '../PatientPortal/store/constants';

import PatientLayout from './patientLayout';


const isAuthorizedPath = pathname => (
    pathname === '/'
    || pathname.startsWith(COVID19_PCR_PATH)
    || pathname.startsWith(ORDERS_PATH)
    || pathname.startsWith(LINKED_PATH)
    || pathname.startsWith(PATIENT_PROFILES_PATH)
    || pathname.startsWith(CONNECT_PATIENT_PATH)
    || pathname.startsWith(CONNECT_TO_PRACTICE_PATH)
    || pathname.startsWith(ORDER_PACKAGE_INFO_PATH)
    || pathname.startsWith(ORDER_PACKAGE_SELECT_PATH)
    || pathname.startsWith(ORDER_PACKAGE_SUCCESS_PATH)
    || pathname.startsWith(CONSENT_PATH)
    || pathname.startsWith(HTK_PATH)
    || pathname.startsWith(BOOKING_PATH)
    || pathname.startsWith('/insurance')
    || pathname.startsWith(INSURANCE_PATH)
    || pathname.startsWith(ADD_TEST_PATH)
    || pathname.startsWith(APPOINTMENTS_PATH)
    || pathname.startsWith(USER_SETTINGS_PATH)
    || pathname.startsWith(HEALTH_PASSPORT_PATH)
    || pathname.startsWith(USER_SETTINGS_PATH)
    || pathname.startsWith(USER_ACCOUNT_PATH)
    || pathname.startsWith(USER_CHANGE_NAME_PATH)
    || pathname.startsWith(USER_CHANGE_EMAIL_PATH)
    || pathname.startsWith(USER_CHANGE_PASSWORD)
    || pathname.startsWith(DELETE_ACCOUNT_PATH)
    || pathname.startsWith('/sti')
);

const redirectIfHaveCookies = history => {
    const redirectUrl = Cookies.get('redirectUrl');

    if (redirectUrl) {
        history.push(redirectUrl);
        return Cookies.remove('redirectUrl');
    }
};


class PatientRouter extends React.PureComponent {
    constructor(props) {
        super(props);
        const { portalUseCase, history, location: { pathname } = {} } = props;
        if (!portalUseCase) this.initialize();
        if (!checkTokenExistence()) {
            this.redirect();
        } else {
            if ([LOGIN_PATH, REGISTER_PATH].includes(pathname)) {
                return;
            }

            redirectIfHaveCookies(history);
        }
    }

    componentDidMount() {
        const { dispatch, userData, consentsLoading, location: { pathname } = {} } = this.props;

        if (!userData && checkTokenExistence()) {
            dispatch(userRequest());
        }

        if (userData && pathname !== CONSENT_PATH && !consentsLoading) {
            dispatch(consentsRequest({
                id: userData.id,
            }));
        }
    }

    componentDidUpdate() {
        const {
            dispatch,
            userData, patientId,
            patientPhone, history, consents,
            role,
            location: { pathname } = {},
            userLoading,
        } = this.props;

        if (!userLoading && !userData && checkTokenExistence()) {
            dispatch(userRequest());
        }

        if (checkTokenExistence() && role && role === 'patient' && userData) {
            const mobileTelecom = safeGet(userData, 'telecom', [])
                .find(item => safeGet(item, 'system') === 'phone' && safeGet(item, 'use') === 'mobile');
            const patientRolePhone = safeGet(mobileTelecom, 'value', null);

            if (patientPhone && patientRolePhone && patientPhone !== patientRolePhone) {
                dispatch(logoutRequest());
                this.initialize();
                return;
            }

            if (patientId) {
                const hasPatient = safeGet(userData, 'patients.results', [])
                    .some(item => safeGet(item, 'id') === patientId);
                if (!hasPatient) {
                    return;
                }
            }

            if (!isAuthorizedPath(pathname) && !pathname.startsWith(CONNECT_PATIENT_PATH)) {
                const { redirect_url } = this.query;
                history.push(redirect_url || '/');
            }
        } else if (!checkTokenExistence()) {
            this.redirect();
        }

        if (!isEmpty(consents) && pathname !== CONSENT_PATH) history.push(CONSENT_PATH);
    }

    redirect = () => {
        const { location: { pathname, search } = {}, history } = this.props;

        if ([CONNECT_PATIENT_PATH, LOGIN_PATH].includes(pathname)) {
            const { practice_id, practice_name } = this.query;
            if (practice_id && practice_name) {
                Cookies.set('redirectUrl', `${CONNECT_PATIENT_PATH}${search}`);
            }

            if (pathname === CONNECT_PATIENT_PATH) {
                return history.push(`/register${search}`);
            } else if (pathname === '/login' && !search) {
                return App.getInstance().redirectToLoginPortal();
            }
        }

        if (pathname === HTK_PATH) {
            Cookies.set('redirectUrl', HTK_PATH);
        }

        this.redirectToLogin();
    };

    redirectToLogin = () => {
        const { location: { pathname } = {} } = this.props;

        if (isAuthorizedPath(pathname) && !isPublicURL(pathname)) {
            App.getInstance().redirectToLoginPortal();
        }
    };

    get query() {
        const { location: { search } = {} } = this.props;
        return parse(search);
    }

    initialize = () => {
        this.setQueryParams();
        this.setPortalUseCase();
        this.setRedirectUrlToCookies();
    };

    setQueryParams = () => {
        const { dispatch } = this.props;
        const { phone, patient_id, practice_name, redirect_url, patient_name, practice_id } = this.query;

        if (phone) dispatch(PATIENT_PORTAL_ACTIONS.setPatientPhone(phone));
        if (patient_id) dispatch(PATIENT_PORTAL_ACTIONS.setPatientId(patient_id));
        if (patient_name) dispatch(PATIENT_PORTAL_ACTIONS.setPatientName(patient_name));
        if (practice_name) dispatch(PATIENT_PORTAL_ACTIONS.setPracticeName(practice_name));
        if (redirect_url) dispatch(PATIENT_PORTAL_ACTIONS.setRedirectUrl(redirect_url));
        if (practice_id) dispatch(PATIENT_PORTAL_ACTIONS.setPracticeId(practice_id));
    };

    setRedirectUrlToCookies = async () => {
        const { redirect_url } = this.query;
        if (redirect_url) await Cookies.set('redirectUrl', redirect_url);
    };

    setPortalUseCase = () => {
        const { dispatch, location } = this.props;
        const { patient_id, redirect_url, self, practice_id, practice_name } = this.query;
        const pathname = safeGet(location, 'pathname');
        let useCase = null;
        if (pathname === CONNECT_PATIENT_PATH && practice_id) {
            useCase = PATIENT_PORTAL_USE_CASES.selfPatientRegister;
        } else if (pathname === REGISTER_PATH) {
            if (redirect_url) {
                useCase = PATIENT_PORTAL_USE_CASES.newReportRegister;
            } else if (self) {
                useCase = PATIENT_PORTAL_USE_CASES.homeTestKit;
            } else if (practice_id) {
                useCase = PATIENT_PORTAL_USE_CASES.patientLink;
            } else {
                useCase = PATIENT_PORTAL_USE_CASES.patientRegister;
            }
        } else if (pathname === LOGIN_PATH) {
            if (redirect_url) {
                if (patient_id) {
                    useCase = PATIENT_PORTAL_USE_CASES.newReportLink;
                } else {
                    useCase = PATIENT_PORTAL_USE_CASES.newReportLogin;
                }
            } else if (practice_id || practice_name) {
                useCase = PATIENT_PORTAL_USE_CASES.patientLink;
            }
        }
        if (useCase) dispatch(PATIENT_PORTAL_ACTIONS.setPortalUseCase(useCase));

        return useCase;
    };

    get checkAccess() {
        const {
            children: Component,
            location: { pathname } = {},
            history,
            role,
            resetToken,
            patientPhone,
        } = this.props;
        const token = App.getInstance().getToken();

        if (pathname === PASSWORD_CREATE_PATH && !resetToken) {
            redirectToResetPassword({ login: formatPhone(patientPhone) });
            return <React.Fragment />;
        }

        if (token && role && role !== 'patient') {
            return history.push('/');
        }

        if (!token
            // eslint-disable-next-line max-len
            && (pathname.startsWith(ORDERS_PATH) || pathname === '/')
        ) {
            return <React.Fragment />;
        }

        return <Component />;
    };

    // eslint-disable-next-line class-methods-use-this
    onInitPostHog = remoteConfig => {
        if (getBoolean(remoteConfig, 'common_pp_analytics_posthog')) {
            Posthog.getInstance().init();
        }
    }

    getLayout = () => {
        const { layout } = this.props;
        switch(layout) {
            case 'patient':
                return PatientLayout;
            case 'navigation':
                return NavigationLayout;
            default:
                return React.Fragment;
        }
    }

    render() {
        const Layout = this.getLayout();

        return (
            <Firebase firebaseConfig={FIREBASE_CONFIG} onFetch={this.onInitPostHog}>
                <Layout>
                    {this.checkAccess}
                </Layout>
            </Firebase>
        );
    }
}


const mapStateToProps = createStructuredSelector({
    userLoading: makeSelectGetUserLoading(),
    user: makeSelectUserData(),
    role: makeSelectRole(),
    roleId: makeSelectRoleId(),
    userData: makeSelectUserData(),
    patientPhone: selectPatientPhone,
    patientId: selectPatientId,
    resetToken: selectResetToken,
    portalUseCase: selectPortalUseCase,
    redirectUrl: selectRedirectUrl,
    consents: select(KEY_PATIENT_PORTAL, 'consents'),
    consentsLoading: select(KEY_PATIENT_PORTAL, 'consentsLoading'),
});

const withConnect = connect(mapStateToProps);
const withSaga = injectSaga({ key: PATIENT_PORTAL_AUTH_KEY, saga, mode: DAEMON });

export default compose(
    withRouter,
    withSaga,
    withConnect,
)(PatientRouter);
