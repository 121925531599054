import React from 'react';
import cn from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import dayjs from 'dayjs';
import { memoize, isString } from 'lodash';
import { getDaysInMonth, toNum, safeGet } from '@flowhealth/utils';

import { ICONS_TYPES } from 'components/FlowIcon/constants';
import FlowIcon from 'components/FlowIcon';

import chain from 'utils/chain';
import { DATE_FORMAT, DATE_SEPARATOR } from 'utils/constants';

import styles from '../styles';
import { FIELD_TYPES, PREVIOUS_CENTURY_YEAR, CURRENT_CENTURY_YEAR } from '../constants';

import Input from './Input';


const compile = (month, day, year) => {
    let res = '';
    if (month) res += `${month}${DATE_SEPARATOR}`;
    if (day) res += `${day}${DATE_SEPARATOR}`;
    if (year) res += year;
    return res;
};

const filter = value => value.replace(/[^\d|/]/g, '');

const getYear = year => {
    if (!year) return dayjs().format('YYYY');
    if (isString(year) && year.length === 2) {
        let currYear = dayjs().format('YYYY');
        currYear = toNum(currYear.slice(2));
        const typedYear = toNum(year);
        const currentCentury = isString(currYear) ? currYear.slice(0, 2) : CURRENT_CENTURY_YEAR;
        return typedYear > currYear ? `${PREVIOUS_CENTURY_YEAR}${year}` : `${currentCentury}${year}`;
    }
    return year;
};

const prevalidate = value => {
    let [month = '', day = '', year = ''] = value.split(DATE_SEPARATOR);
    if (year && year.length > 4) {
        year = year.slice(0, 4);
        return compile(month, day, year);
    }
    return value;
};


export class FlowDate extends React.PureComponent {
    state = {
        inputRef: undefined, // eslint-disable-line
        error: undefined,
        suggestion: undefined,
    };

    getInputRef = inputRef => {
        const { getInputRef } = this.props;
        this.setState({ inputRef }); // eslint-disable-line
        getInputRef && getInputRef(inputRef);
    };

    rearrange = (...date) => {
        const { noRearrange } = this.props;
        let [month, day, year] = date;
        const daysInMonth = getDaysInMonth(month, getYear(year));
        const fsDay = chain(day.charAt(0), toNum);
        const ssDay = chain(day.charAt(1), toNum);
        if (fsDay > 3 || (fsDay === 3 && ssDay > 1)) {
            year = day.slice(1) + year;
            day = day.charAt(0);
        } else if (day.length > 2) {
            year = day.slice(2) + year;
            day = day.slice(0, 2);
        } else if (year && toNum(day) > daysInMonth && !noRearrange) {
            day = daysInMonth;
        }
        if (day.length < 2 && fsDay <= 3 && !ssDay && !year) return compile(month) + day;
        return compile(month, day, year);
    };

    separate = value => {
        let [month = '', day = '', year = ''] = value.split(DATE_SEPARATOR);
        const fsMonth = chain(month.charAt(0), toNum);
        if (fsMonth > 1) {
            day = month.slice(1) + day;
            month = month.charAt(0);
            return this.rearrange(month, day, year);
        } else if (month.length > 2) {
            day = month.slice(2) + day;
            month = month.slice(0, 2);
            return this.rearrange(month, day, year);
        } else if (month.length === 2) {
            return this.rearrange(month, day, year);
        }
        return value;
    };

    suggest = value => {
        if (value === '') {
            this.setState({ suggestion: undefined });
            return value;
        }
        const [month = '', day = '', year = ''] = value.split(DATE_SEPARATOR);
        const [sMonth = '', sDay = '', sYear = ''] = DATE_FORMAT.split(DATE_SEPARATOR);
        this.setState({ suggestion: compile(month || sMonth, day || sDay, year || sYear) });
        return value;
    };

    changeHandlers = [String.trim, filter, this.separate, prevalidate, this.suggest];

    unsuggest = value => {
        value === '' && this.setState({ suggestion: undefined });
        return value;
    };

    deleteHandlers = [this.unsuggest];

    setError = memoize(error => () => {
        const { onError } = this.props;
        this.setState({ error });
        onError && onError(error, FIELD_TYPES.date);
    });

    normalize = value => {
        const { onNormalize, yearAutoFill } = this.props;
        let [month = '', day = '', year = ''] = value.split(DATE_SEPARATOR);
        if (month && month.length < 2) month = `0${month}`;
        if (day && day.length < 2) day = `0${day}`;
        if (yearAutoFill && month && day) year = getYear(year);
        const result = compile(month, day, year);
        onNormalize && onNormalize(result);
        return result;
    };

    validate = value => {
        const { required, onValidDate, isValidDate } = this.props;
        let isValid = dayjs(value, DATE_FORMAT).isValid();
        const [month = '', day = '', year = ''] = value.split(DATE_SEPARATOR);
        if (!month || (isString(month) && month.length < 2)) isValid = false;
        if (isValid && (!day || (isString(day) && day.length < 2))) isValid = false;
        if (isValid && (!year || (isString(year) && year.length < 4))) isValid = false;
        if (isValidDate && isValid) isValid = isValidDate(value);
        if (value === '') required && !isValid && this.setError('Invalid Date')();
        else !isValid && this.setError('Invalid Date')();
        onValidDate && onValidDate(isValid, FIELD_TYPES.date);
        return value;
    };

    disadvise = value => {
        this.setState({ suggestion: undefined });
        return value;
    };

    blurHandlers = [this.normalize, this.validate, this.disadvise];

    handleFocus = event => {
        const { onFocus } = this.props;
        this.setError(undefined)();
        onFocus && onFocus(event);
    };

    handleBlur = (value, event, options) => {
        const { onBlur } = this.props;
        onBlur && onBlur(value, event, options);
    };

    render() {
        const { classes = {}, className, initialValue, showError, preview, disabled, onChange, onKeyDown } = this.props;
        const { suggestion } = this.state;
        const error = safeGet(this.state, 'error', safeGet(this.props, 'error', false));
        return (
            <div className={cn('date', classes.date, className)}>
                <Input
                    leftIcon={(
                        <FlowIcon
                            className={classes.calendar}
                            type={ICONS_TYPES.calendar}
                            size={20}
                        />
                    )}
                    placeholder={DATE_FORMAT}
                    changeHandlers={this.changeHandlers}
                    deleteHandlers={this.deleteHandlers}
                    blurHandlers={this.blurHandlers}
                    getInputRef={this.getInputRef}
                    onChange={onChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onKeyDown={onKeyDown}
                    initialValue={initialValue}
                    showError={showError}
                    error={error}
                    suggestion={suggestion}
                    preview={preview}
                    disabled={disabled}
                />
            </div>
        );
    }
}

export default withStyles(styles)(FlowDate);
