import { LUCIRA_KIT_KEY } from './constants';

export const RESET_STATE = `RESET_STATE__${LUCIRA_KIT_KEY}`;
export const RESET_SCANNER_STATE = `RESET_SCANNER_STATE__${LUCIRA_KIT_KEY}`;

export const SET_LOADING = `SET_LOADING__${LUCIRA_KIT_KEY}`;
export const SET_PATIENT = `SET_PATIENT__${LUCIRA_KIT_KEY}`;
export const SET_TEST_RESULT = `SET_TEST_RESULT__${LUCIRA_KIT_KEY}`;
export const SET_BATCH = `SET_BATCH__${LUCIRA_KIT_KEY}`;

export const GET_REF_RANGES_REQUEST = `GET_REF_RANGES_REQUEST__${LUCIRA_KIT_KEY}`;
export const GET_REF_RANGES_SUCCESS = `GET_REF_RANGES_SUCCESS__${LUCIRA_KIT_KEY}`;
export const GET_REF_RANGES_FAILURE = `GET_REF_RANGES_FAILURE__${LUCIRA_KIT_KEY}`;

export const SUBMIT_TEST_REQUEST = `SUBMIT_TEST_REQUEST__${LUCIRA_KIT_KEY}`;
export const SUBMIT_TEST_SUCCESS = `SUBMIT_TEST_SUCCESS__${LUCIRA_KIT_KEY}`;
export const SUBMIT_TEST_FAILURE = `SUBMIT_TEST_FAILURE__${LUCIRA_KIT_KEY}`;

export const WS_POC_ACTION_CHANGE_SUCCESS = `WS_POC_ACTION_CHANGE_SUCCESS__${LUCIRA_KIT_KEY}`;
export const WS_POC_ACTION_CHANGE_FAILURE = `WS_POC_ACTION_CHANGE_FAILURE__${LUCIRA_KIT_KEY}`;

export const WS_SUBSCRIBE = `WS_SUBSCRIBE__${LUCIRA_KIT_KEY}`;
export const WS_UNSUBSCRIBE = `WS_UNSUBSCRIBE__${LUCIRA_KIT_KEY}`;
export const WS_SUCCESS = `WS_SUCCESS__${LUCIRA_KIT_KEY}`;
export const WS_FAILURE = `WS_FAILURE__${LUCIRA_KIT_KEY}`;

export const COPY_TO_PRACTICE_REQUEST = `COPY_TO_PRACTICE_REQUEST__${LUCIRA_KIT_KEY}`;
export const COPY_TO_PRACTICE_SUCCESS = `COPY_TO_PRACTICE_SUCCESS__${LUCIRA_KIT_KEY}`;
export const COPY_TO_PRACTICE_FAILURE = `COPY_TO_PRACTICE_FAILURE__${LUCIRA_KIT_KEY}`;
export const COPY_TO_PRACTICE_FINISHED = `COPY_TO_PRACTICE_FINISHED__${LUCIRA_KIT_KEY}`;

export const CHECK_UNIQUE_POC_ACTION_REQUEST = `CHECK_UNIQUE_POC_ACTION_REQUEST__${LUCIRA_KIT_KEY}`;
export const CHECK_UNIQUE_POC_ACTION_SUCCESS = `CHECK_UNIQUE_POC_ACTION_SUCCESS__${LUCIRA_KIT_KEY}`;
export const CHECK_UNIQUE_POC_ACTION_FAILURE = `CHECK_UNIQUE_POC_ACTION_FAILURE__${LUCIRA_KIT_KEY}`;

export const SET_ERROR = `SET_ERROR__${LUCIRA_KIT_KEY}`;
