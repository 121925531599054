/* eslint-disable max-len */
import React from 'react';


const Keyboard = () => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_20840_5913)">
            <path fillRule="evenodd" clipRule="evenodd" d="M25 -1H24V0H25V-1ZM21 6.5H3C2.72386 6.5 2.5 6.72386 2.5 7V17C2.5 17.2761 2.72386 17.5 3 17.5H21C21.2761 17.5 21.5 17.2761 21.5 17V7C21.5 6.72386 21.2761 6.5 21 6.5ZM3 5C1.89543 5 1 5.89543 1 7V17C1 18.1046 1.89543 19 3 19H21C22.1046 19 23 18.1046 23 17V7C23 5.89543 22.1046 5 21 5H3ZM6 9C5.44772 9 5 9.44771 5 10V10.01C5 10.5623 5.44772 11.01 6 11.01H6.01C6.56228 11.01 7.01 10.5623 7.01 10.01V10C7.01 9.44771 6.56228 9 6.01 9H6ZM6 13C5.44772 13 5 13.4477 5 14V14.01C5 14.5623 5.44772 15.01 6 15.01H6.01C6.56228 15.01 7.01 14.5623 7.01 14.01V14C7.01 13.4477 6.56228 13 6.01 13H6ZM10 9C9.44772 9 9 9.44771 9 10V10.01C9 10.5623 9.44772 11.01 10 11.01H10.01C10.5623 11.01 11.01 10.5623 11.01 10.01V10C11.01 9.44771 10.5623 9 10.01 9H10ZM14 9C13.4477 9 13 9.44771 13 10V10.01C13 10.5623 13.4477 11.01 14 11.01H14.01C14.5623 11.01 15.01 10.5623 15.01 10.01V10C15.01 9.44771 14.5623 9 14.01 9H14ZM18 9C17.4477 9 17 9.44771 17 10V10.01C17 10.5623 17.4477 11.01 18 11.01H18.01C18.5623 11.01 19.01 10.5623 19.01 10.01V10C19.01 9.44771 18.5623 9 18.01 9H18ZM18 13C17.4477 13 17 13.4477 17 14V14.01C17 14.5623 17.4477 15.01 18 15.01H18.01C18.5623 15.01 19.01 14.5623 19.01 14.01V14C19.01 13.4477 18.5623 13 18.01 13H18ZM8 14C8 13.4477 8.44772 13 9 13L15 13C15.5523 13 16 13.4477 16 14C16 14.5523 15.5523 15 15 15H9C8.44772 15 8 14.5523 8 14Z" />
        </g>
    </svg>

);

export default Keyboard;
