import { ROLES } from 'constants/roles';

import { call, put, takeLatest, all, takeEvery, take, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import API from '@flowhealth/api';
import { isFullObject } from '@flowhealth/utils';
import { App } from '@flowhealth/app';
import { isUndefined } from 'lodash';

import { closeErrorMessage, openErrorMessage } from 'components/Message/actions';

import { errorMessage } from 'containers/Errors/handleErrors';
import { userRequest } from 'containers/User/actions';
import { USER_SUCCESS } from 'containers/User/constants';

import {
    REGISTER_PATH,
    CREATE_PATH,
    ENTER_CODE_PATH,
    ADD_INSURANCE_PATH,
    EXISTS_PATH,
    LINKED_PATH,
    PASSWORD_CREATE_PATH,
    HTK_CODE_EXISTANCE_PAGE,
    CID_PATH,
} from 'configuration/paths';
import { ZIP_CODE_ERR_MSG } from 'configuration/constants/errorMessages';
import { Posthog } from "configuration/Posthog";

import ReCAPTCHA from 'utils/ReCaptcha';

import fetchValues from './utils/fetchValues';
import * as ACTION_TYPES from './actionTypes';
import * as ACTIONS from './actions';
import {
    ERROR_CODES,
    PATIENT_PORTAL_USE_CASES,
    REGISTER_FORM,
} from './constants';
import {
    selectRedirectUrl,
    selectPracticeId,
    selectPortalUseCase,
} from './selector';


function* logout({ payload = {} } = {}) {
    try {
        yield App.getInstance().logout();
        App.getInstance().setRedirectInfo(null);
        Posthog.getInstance().clearData();
    } catch (error) {
        yield put(ACTIONS.logoutFailure({ ...payload, error }));
    }
}

function* verifyPatientRequest({ payload: { form, data } }) {
    try {
        const { status, reset_token = '' } = yield call(API.getInstance().verifyPatient, fetchValues(data));
        yield put(ACTIONS.verifyPatientRequestSuccess(reset_token));

        if (status === 'already_linked') {
            App.getInstance().redirectToLoginPortal();
            return;
        }

        if (status === 'linked') {
            yield put(userRequest());
            yield put(push(LINKED_PATH));
            return;
        }

        yield put(push(PASSWORD_CREATE_PATH));
    } catch (e) {
        yield put(ACTIONS.verifyPatientRequestFailure({ form, data, error: errorMessage(e) }));
    }
}

const getSearchParams = (params = {}) => {
    if (!isFullObject(params)) return '';
    return `?${Object
        .keys(params)
        .filter(key => !isUndefined(params[key]))
        .map(key => (`${key}=${params[key]}`))
        .join('&')}`;
};

function* sendCodeRequest({ payload: { form, data } }) {
    try {
        const basePath = ENTER_CODE_PATH;
        const search = getSearchParams({
            phone: data.phone,
            practice_id: data.practice_id,
        });
        const captcha_token = ReCAPTCHA.getInstance().token;

        yield call(API.getInstance().sendAuthCode, { ...fetchValues(data), captcha_token });

        yield put(ACTIONS.sendCodeSuccess(data.phone));
        yield put(push(`${basePath}${search}`));
    } catch (error) {
        yield put(ACTIONS.sendCodeFailure({ form, error: errorMessage(error) }));
    }
    return ReCAPTCHA.getInstance()?.reset();
}

function* verifyCodeRequest({ payload: { form, data } }) {
    try {
        const practiceId = yield select(selectPracticeId);
        const portalUseCase = yield select(selectPortalUseCase);
        yield call(API.getInstance().verifyCode, fetchValues(data));

        yield put(ACTIONS.verifyCodeSuccess({ form: REGISTER_FORM, code: data.code }));
        if (portalUseCase === PATIENT_PORTAL_USE_CASES.homeTestKit) {
            yield put(push(`${HTK_CODE_EXISTANCE_PAGE}?phone=${data.phone}`));
        } else {
            yield put(push(`/create?practice_id=${practiceId}&phone=${data.phone}`));
        }
    } catch (error) {
        if (errorMessage(error).code === 'PatientAlreadyRegistered') {
            yield put(push(EXISTS_PATH));
        }

        yield put(ACTIONS.verifyCodeFailure({ form, error: errorMessage(error) }));
    }
    return ReCAPTCHA.getInstance()?.reset();
}

function* authRequest({ payload: { form, data } = {} } = {}) {
    try {
        const response = yield call(API.getInstance().loginV2, fetchValues(data));
        const redirect_url = yield select(selectRedirectUrl);
        const portalUseCase = yield select(selectPortalUseCase);
        const { token } = response;
        API.getInstance().setToken(token);
        App.getInstance().setToken(token);
        App.getInstance().setRole(ROLES.PATIENT_ROLE);

        yield put(ACTIONS.authRequestSuccess());
        yield put(userRequest());
        if (portalUseCase === PATIENT_PORTAL_USE_CASES.patientLink
            || portalUseCase === PATIENT_PORTAL_USE_CASES.newReportLink) {
            const location = yield select(state => state.router.location);
            yield put(push(`${REGISTER_PATH}${location.search}`));
        }
        else if (redirect_url) {
            yield put(push(redirect_url));
        }
        else if (!portalUseCase) {
            yield put(push('/'));
        }
    } catch (e) {
        yield put(ACTIONS.authRequestFailure({ form, data, error: errorMessage(e) }));
        yield put(closeErrorMessage());
    }
}

function* resetPasswordRequest({ payload: { form, data } }) {
    try {
        const { token } = yield call(API.getInstance().resetPasswordV2, ...Object.values(fetchValues(data)));
        const redirect_url = yield select(selectRedirectUrl);
        API.getInstance().setToken(token);
        App.getInstance().setToken(token);
        App.getInstance().setRole(ROLES.PATIENT_ROLE);

        yield all([
            put(ACTIONS.resetPasswordRequestSuccess()),
            put(userRequest()),
        ]);
        yield put(userRequest());
        yield take(USER_SUCCESS);

        if (redirect_url) yield put(push(redirect_url));
    } catch (e) {
        yield put(ACTIONS.resetPasswordRequestFailure({ form, data, error: errorMessage(e) }));
    }
}

function* getAddressData({ payload = {} } = {}) {
    try {
        const response = yield call(
            API.getInstance().search,
            { object: 'zip_code', q: payload },
        );
        const { results: [result] } = response;

        if (result) {
            yield put(ACTIONS.setRegisterFormValue({ id: 'zip_code', value: payload, valid: true, touched: true }));
            yield put(ACTIONS.setRegisterFormValue({ id: 'city', value: result.city, valid: true, touched: true }));
            yield put(ACTIONS.setRegisterFormValue({ id: 'state', value: result.state, valid: true, touched: true }));
        } else {
            yield put(openErrorMessage(ZIP_CODE_ERR_MSG));
            yield put(ACTIONS.setRegisterFormValue({ id: 'zip_code', value: payload, valid: false, touched: true }));
            yield put(ACTIONS.setRegisterFormValue({ id: 'city', value: '', valid: false, touched: true }));
            yield put(ACTIONS.setRegisterFormValue({ id: 'state', value: '', valid: false, touched: true }));
        }

        yield put(ACTIONS.getAddressSuccess());
    } catch (error) {
        yield put(ACTIONS.getAddressFailure({ error: errorMessage(error) }));
    }
}

function* registerPatient({
    payload: {
        patient,
        search = '',
    },
} = {}) {
    try {
        const { reset_token } = yield call(API.getInstance().registerPatient, patient);

        yield put(ACTIONS.resetState({ form: REGISTER_FORM }));
        yield put(ACTIONS.registerPatientSuccess(reset_token));

        yield put(ACTIONS.setRedirectUrl('/'));
        yield put(push(PASSWORD_CREATE_PATH));
    } catch (error) {
        const errorMessageCode = errorMessage(error).code;

        yield put(ACTIONS.setPatientRequest(patient));
        yield put(ACTIONS.registerPatientFailure({}));

        if (errorMessageCode === ERROR_CODES.insuranceRequired) {
            yield put(push(`${ADD_INSURANCE_PATH}${search}`));
        } else if (errorMessageCode === ERROR_CODES.employeeIdRequired) {
            yield put(push(`${CID_PATH}${search}`));
        } else {
            yield put(ACTIONS.registerPatientFailure({ error }));
            if (errorMessageCode === 'UniqueConstraintViolated') {
                yield put(openErrorMessage(errorMessage(error).message));
            }
        }
    }
}

function* searchBatchByKitCode({ payload }) {
    const { code } = payload;

    try {
        const batch = yield call(API.getInstance().getKitBatch, { code });

        if (batch?.message) {
            throw new Error(batch.message);
        } else {
            yield put(ACTIONS.searchBatchSuccess());
            yield put(ACTIONS.setPracticeId(batch.practice));
            yield put(push(CREATE_PATH));
        }
    } catch (error) {
        yield put(ACTIONS.searchBatchFailure({ searchError: errorMessage(error) }));
    }
}


function* Saga() {
    yield all([
        takeLatest(ACTION_TYPES.LOGOUT_REQUEST, logout),
        takeEvery(ACTION_TYPES.VERIFY_PATIENT_REQUEST, verifyPatientRequest),
        takeEvery(ACTION_TYPES.SEND_CODE_REQUEST, sendCodeRequest),
        takeEvery(ACTION_TYPES.VERIFY_CODE_REQUEST, verifyCodeRequest),
        takeEvery(ACTION_TYPES.AUTH_REQUEST, authRequest),
        takeEvery(ACTION_TYPES.RESET_PASSWORD_REQUEST, resetPasswordRequest),
        takeEvery(ACTION_TYPES.GET_ADDRESS_REQUEST, getAddressData),
        takeEvery(ACTION_TYPES.REGISTER_PATIENT_REQUEST, registerPatient),
        takeLatest(ACTION_TYPES.SEARCH_BATCH_REQUEST, searchBatchByKitCode),
    ]);
}

export default Saga;
