// more info in https://stackoverflow.com/questions/6150289/how-to-convert-image-into-base64-string-using-javascript
// canvas is blocked by CORS
export default url => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = function onLoad() {
        const reader = new FileReader();
        reader.onloadend = function onLoadEnd() {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(xhr.response);
    };

    xhr.open('GET', url);
    xhr.onerror = reject;
    xhr.responseType = 'blob';
    xhr.send();
});
