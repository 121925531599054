import { set } from 'immutable';
import { createReducer, safeGet } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';

const initialState = {
    isLoading: false,
    batch: undefined,
    fields: undefined,
    testResult: undefined,
    patient: undefined,
    copyPatientId: undefined,
    kitId: undefined,
    pocActionFile: undefined,
    testKit: undefined,
    pocAction: undefined,
    error: null,
};

const resetState = () => ({ ...initialState });

const setIsLoading = value => state => set(state, 'isLoading', value);

const setPatient = (state, { payload = {} }) => set(state, 'patient', payload);

const setBatch = (state, { payload = {} }) => set(state, 'batch', payload?.batch);
const setFields = (state, { payload = {} }) => set(state, 'fields', payload?.fields);

const setResultRefRange = (state, { payload = {} } = {}) => set(
    state, 'resultRefRange', safeGet(payload, 'refRange', payload),
);

const setTestResult = (state, { payload = {} } = {}) => set(
    state, 'testResult', safeGet(payload, 'testResult', payload),
);

const setPanelId = (state, { payload = {} } = {}) => set(
    state, 'panelId', safeGet(payload, 'panelId', payload),
);

const setCopyPatientId = (state, { payload = {} } = {}) => set(
    state, 'copyPatientId', safeGet(payload, 'copyPatientId'),
);

const setKitId = (state, { payload = {} } = {}) => set(state, 'kitId', safeGet(payload, 'kitId'));

const setPocActionFile = (state, { payload = {} } = {}) => set(
    state, 'pocActionFile', safeGet(payload, 'pocActionFile'),
);

const setTestKit = (state, { payload = {} } = {}) => set(
    state, 'testKit', safeGet(payload, 'testKit'),
);

const setError = (state, { payload = {} } = {}) => set(
    state, 'error', safeGet(payload, 'pocActionError'),
);

const setRequisition = (state, { payload = {} } = {}) => set(
    state, 'requisition', safeGet(payload, 'requisition'),
);

const resetScannerState = state => {
    const withoutError = set(state, 'error', null);
    const withoutPocAction = set(withoutError, 'pocAction', undefined);
    const withoutTestKit = set(withoutPocAction, 'testKit', undefined);
    const withoutKitId = set(withoutTestKit, 'kitId', undefined);
    return set(withoutKitId, 'pocActionType', undefined);
};


export default createReducer(initialState, {
    [ACTION_TYPES.RESET_STATE]: resetState,
    [ACTION_TYPES.RESET_SCANNER_STATE]: resetScannerState,

    [ACTION_TYPES.SET_LOADING]: setIsLoading(),
    [ACTION_TYPES.SET_PATIENT]: setPatient,
    [ACTION_TYPES.SET_BATCH]: [setBatch, setFields],

    [ACTION_TYPES.GET_REF_RANGES_SUCCESS]: [setResultRefRange, setPanelId],

    [ACTION_TYPES.SET_TEST_RESULT]: setTestResult,

    [ACTION_TYPES.SUBMIT_TEST_REQUEST]: setIsLoading(true),
    [ACTION_TYPES.SUBMIT_TEST_SUCCESS]: setIsLoading(false),
    [ACTION_TYPES.SUBMIT_TEST_FAILURE]: setIsLoading(false),
    [ACTION_TYPES.WS_POC_ACTION_CHANGE_SUCCESS]: [setRequisition, setTestResult, setTestKit],

    [ACTION_TYPES.COPY_TO_PRACTICE_SUCCESS]: setCopyPatientId,
    [ACTION_TYPES.COPY_TO_PRACTICE_FINISHED]: setCopyPatientId,

    [ACTION_TYPES.CHECK_UNIQUE_POC_ACTION_REQUEST]: setIsLoading(true),
    [ACTION_TYPES.CHECK_UNIQUE_POC_ACTION_SUCCESS]: [
        setKitId,
        setPocActionFile,
        setIsLoading(false)],
    [ACTION_TYPES.CHECK_UNIQUE_POC_ACTION_FAILURE]: [setError, setIsLoading(false)],

    [ACTION_TYPES.WS_SUCCESS]: [setRequisition, setTestKit, setTestResult, setIsLoading(false)],
    [ACTION_TYPES.WS_FAILURE]: setIsLoading(false),
});
