import { safeGet } from '@flowhealth/utils';

import { SELECT_LOCATION_KEY } from './constants';

const selectState = state => safeGet(state, SELECT_LOCATION_KEY);

export const selectIsLoadingLocations = state => selectState(state).isLoadingLocations;

export const selectLocations = state => selectState(state).locations;

export const selectEventLocations = state => selectState(state).eventLocations;
