import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import {
    ROTATE_TYPES,
    ROTATE_VALUES,
} from './constants';


const styles = {
    FlowSimpleIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',

        '& > svg': {
            height: '100%',
            width: '100%',
        },
    },
};

class FlowSimpleIcon extends PureComponent {
    static propTypes = {
        Icon: PropTypes.func,
        size: PropTypes.number,
        rotate: PropTypes.oneOfType([
            PropTypes.oneOf(Object.values(ROTATE_TYPES)),
            PropTypes.number,
        ]),
        className: PropTypes.string,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        unsetColor: PropTypes.bool,
    };

    static defaultProps = {
        className: '',
        size: 20,
        rotate: ROTATE_TYPES.up,
        disabled: false,
        color: 'black',
        unsetColor: false,
    };

    render() {
        const {
            classes, className,
            icon: Icon,
            size,
            rotate,
            color,
            disabled,
            onClick,
            unsetColor,
            ...props
        } = this.props;

        const initCursor = disabled ? 'default' : 'pointer';
        const cursor = onClick ? initCursor : undefined;
        const style = unsetColor ? undefined : { fill: color };

        return (
            <div
                className={cn(classes.FlowSimpleIcon, className)}
                onClick={disabled ? undefined : onClick}
                style={{
                    transform: `rotate(${typeof rotate === 'string' ? ROTATE_VALUES[rotate] : rotate}deg)`,
                    width: size,
                    height: size,
                    minWidth: size,
                    minHeight: size,
                    cursor,
                }}
            >
                <Icon {...props} style={style} />
            </div>
        );
    }
}

export default withStyles(styles)(FlowSimpleIcon);
