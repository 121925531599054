import React, { useCallback, useState, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { createUseStyles } from '@flowhealth/ui-components';
import { BackArrow } from '@flowhealth/icons';

import { Container, ActionIcon } from 'components/Container';

import { checkCorporateLocation } from 'utils/appointment';

import { ReactComponent as ListIcon } from 'imgs/patientPortal/appointment/list.svg';
import { ReactComponent as MapIcon } from 'imgs/patientPortal/appointment/map.svg';

import { useDistancesFromMyLocation } from '../../hooks';
import { filterLocationsWithGeoCoordinates, sortLocations } from '../../utils';

import { LocationsMap } from '../LocationsMap';
import { NoLocations } from '../NoLocations';

import { Location } from './Location';

const useStyles = createUseStyles(() => ({
    locations: {
        '& > *:not(:last-child)': {
            marginBottom: 16,
        },
    },
}));

const Locations = ({ locations = [], patient, onSelect, onBack }) => {
    const classes = useStyles();
    const hasLocations = !isEmpty(locations);

    const [isMap, setIsMap] = useState(false);

    const handleToggleMap = useCallback(() => {
        setIsMap(prev => !prev);
    }, []);

    const locationsWithGeoCoordinates = useMemo(() =>
        filterLocationsWithGeoCoordinates(locations),
        [locations]
    );

    const collectionLocationsWithGeoCoordinates = useMemo(() =>
        locationsWithGeoCoordinates.map(location =>
            checkCorporateLocation(location) ? location.collection_location : location
        ), [locationsWithGeoCoordinates]
    );

    const {
        distances: distancesFromMyLocation,
        loading: distancesLoading,
        myLocation,
    } = useDistancesFromMyLocation(collectionLocationsWithGeoCoordinates);

    const content = isMap ? (
        <LocationsMap
            myLocation={myLocation}
            locations={locationsWithGeoCoordinates}
            onSelect={onSelect}
        />
    ) : (
        <div className={classes.locations}>
            {sortLocations(locations, patient).map(location => (
                <Location
                    key={location.id}
                    location={location}
                    distancesLoading={distancesLoading}
                    distancesFromMyLocation={distancesFromMyLocation}
                    onSelect={onSelect}
                />
            ))}
        </div>
    );

    return (
        <Container
            title="Select Location"
            leftAction={<ActionIcon type={BackArrow} onClick={onBack}/>}
            rightAction={!isEmpty(locationsWithGeoCoordinates)
                && <ActionIcon type={isMap ? ListIcon : MapIcon} onClick={handleToggleMap}/>
            }
        >
            {hasLocations ? content : <NoLocations />}
        </Container>
    );
};

export default Locations;
