import {
    GRAPH_CHANGE_PRIORITY_FAILURE,
    GRAPH_CHANGE_PRIORITY_REQUEST,
    GRAPH_CHANGE_PRIORITY_SUCCESS,
} from './constants';

export const graphChangePriorityRequest = (table, payload, onSuccessAction, updateOnSuccess) => ({
    type: GRAPH_CHANGE_PRIORITY_REQUEST, table, payload, onSuccessAction, updateOnSuccess,
});

export const graphChangePrioritySuccess = (table, payload) => ({
    type: GRAPH_CHANGE_PRIORITY_SUCCESS, table, payload,
});

export const graphChangePriorityFailure = (table, payload) => ({
    type: GRAPH_CHANGE_PRIORITY_FAILURE, table, payload,
});
