import { createSelector } from 'reselect';

export const selectValueSetFromState = state => state.valuesets;

export const selectValueSet = file => createSelector(
    (state, props) => state.valuesets && state.valuesets.get(file || props.file),
    values => values,
);

export const selectAllValueSets = createSelector(
    selectValueSetFromState,
    valueSet => typeof valueSet.toJS === 'function' && valueSet.toJS(),
);
