import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import FormLoader from './formLoader';
import { bluredBG } from './styles';


const styles = ({ palette = [] } = {}) => ({
    root: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        background: palette.gray[85],
        overflow: 'hidden',
        zIndex: '1000',
        flexDirection: 'column',
    },
    formRoot: {
        position: 'relative',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '15px 0',
        padding: '25px',
        width: '100%',
    },
    field: {
        width: '30%',
        height: '36px',
        borderRadius: '4px',
        display: 'flex',
    },
    ...bluredBG,
});


class TabLoader extends React.PureComponent {
    static propTypes = {
        title: PropTypes.bool,
        action: PropTypes.bool,
    };

    static defaultProps = {
        title: true,
        action: true,
    };

    render() {
        const { classes, title, action } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.row}>
                    <div className={cn(classes.animate, classes.field)} />
                    <div className={cn(classes.animate, classes.field)} />
                    <div className={cn(classes.animate, classes.field)} />
                </div>
                <FormLoader className={classes.formRoot} title={title} action={action} />
            </div>
        );
    }
}


export default withStyles(styles)(TabLoader);
