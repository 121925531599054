/* eslint-disable max-len */
import React from 'react';

const ArrowThin = () => (
    <svg viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 6L6 1L1 6" stroke="#4F5862" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default ArrowThin;
