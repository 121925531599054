/* eslint-disable max-len */
import React from 'react';


const Camera = () => (
    <svg viewBox="0 0 24 24">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.02164 5.75294C9.44492 5.12585 10.1521 4.75 10.9087 4.75H13.0913C13.8479 4.75 14.5551 5.12585 14.9784 5.75294L15.0046 5.79187C15.6904 6.80778 16.836 7.41667 18.0617 7.41667C19.2703 7.41667 20.25 8.39641 20.25 9.60498V16C20.25 17.7949 18.7949 19.25 17 19.25H7C5.20507 19.25 3.75 17.7949 3.75 16V9.60497C3.75 8.3964 4.72974 7.41667 5.93831 7.41667C7.164 7.41667 8.30962 6.80778 8.99536 5.79187L9.02164 5.75294ZM10.9087 3.25C9.6536 3.25 8.48053 3.87348 7.77836 4.91373L7.75209 4.95266C7.34523 5.55541 6.66552 5.91667 5.93831 5.91667C3.90131 5.91667 2.25 7.56798 2.25 9.60497V16C2.25 18.6234 4.37665 20.75 7 20.75H17C19.6234 20.75 21.75 18.6234 21.75 16V9.60498C21.75 7.56798 20.0987 5.91667 18.0617 5.91667C17.3345 5.91667 16.6548 5.55541 16.2479 4.95266L16.2216 4.91373C15.5195 3.87348 14.3464 3.25 13.0913 3.25H10.9087ZM8.75 13C8.75 11.2051 10.2051 9.75 12 9.75C13.7949 9.75 15.25 11.2051 15.25 13C15.25 14.7949 13.7949 16.25 12 16.25C10.2051 16.25 8.75 14.7949 8.75 13ZM12 8.25C9.37665 8.25 7.25 10.3766 7.25 13C7.25 15.6234 9.37665 17.75 12 17.75C14.6234 17.75 16.75 15.6234 16.75 13C16.75 10.3766 14.6234 8.25 12 8.25Z" />
    </svg>
);

export default Camera;
