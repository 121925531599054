import React, { useMemo, memo } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { isObject } from '@flowhealth/utils';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import FlowIcon from 'components/FlowIcon';
import Button from 'components/Button';
import { ICONS_TYPES } from 'components/FlowIcon/constants';

import { HTK_PATH } from 'configuration/paths';

import styles from './styles';
import { LINK_POSITIONS } from './constants';


const AdaptiveHeader = ({
    id,
    classes,
    className,
    title,
    onClose,
    fixed,
    iconType = ICONS_TYPES.backArrow,
    onBackIconClick,
    hasBackIcon,
    link: linkProp = {},
    hasDivider,
}) => {
    const location = useLocation();
    const history = useHistory();
    const isHTKFlow = useMemo(() => {
        location.pathname.startsWith(HTK_PATH);
    }, [location.pathname]);

    const getBackIcon = useMemo(() => {
        if (!isHTKFlow && !hasBackIcon) return;
        return (
            <FlowIcon
                className={classes.backIcon}
                size={20}
                type={iconType}
                onClick={onBackIconClick || history.goBack}
            />
        );
    }, [classes.backIcon, hasBackIcon, history.goBack, iconType, isHTKFlow, onBackIconClick]);

    const getTitle = useMemo(() => {
        if (!title) {
            if ((isObject(linkProp) && linkProp?.position) || isFunction(onClose)) {
                return <div className={classes.hidden}>1</div>;
            }

            return null;
        }
        return (
            <div className={classes.titleContainer}>
                {getBackIcon}
                <span className={classes.title}>
                    {title}
                </span>
            </div>
        );
    }, [classes, getBackIcon, linkProp, onClose, title]);

    const closeIcon = useMemo(() => {
        if (!isFunction(onClose)) return;
        return (
            <FlowIcon
                id={`${id}CloseId`}
                className={cn(classes.actionBtn, classes.close, classes[`${LINK_POSITIONS.right}ActionBtn`])}
                type={ICONS_TYPES.close}
                color={Button.BUTTON_COLORS.gray}
                onClick={onClose}
            />
        );
    }, [classes, id, onClose]);

    const link = (position = LINK_POSITIONS.left) => {
        if (!linkProp.to) return;
        return (
            <Link
                className={cn(classes.actionBtn, classes[`${position}ActionBtn`], classes.link, linkProp.linkProp)}
                {...linkProp}
            />
        );
    };

    const leftActionBtn = () => {
        if (isObject(linkProp) && linkProp.position === LINK_POSITIONS.left) return link(LINK_POSITIONS.left);
        return null;
    };

    const rightActionBtn = () => {
        if (isFunction(onClose)) return closeIcon;
        if (isObject(linkProp) && linkProp.position === LINK_POSITIONS.right) return link(LINK_POSITIONS.right);
        return null;
    };

    if (!leftActionBtn() && !getTitle && !rightActionBtn()) return null;

    return (
        <header className={cn(classes.root, { [classes.fixed]: fixed, [classes.divider]: hasDivider }, className)}>
            {leftActionBtn()}
            {getTitle}
            {rightActionBtn()}
        </header>
    );
};

AdaptiveHeader.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    onClose: PropTypes.func,
    onBackIconClick: PropTypes.func,
    fixed: PropTypes.bool,
    iconType: PropTypes.string,
    hasBackIcon: PropTypes.bool,
};

AdaptiveHeader.defaultProps = {
    fixed: false,
};

export default compose(
    withStyles(styles),
    memo,
)(AdaptiveHeader);
