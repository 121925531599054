import { MAILING_KIT_KEY } from './constants';


export const RESET_STATE = `RESET_STATE__${MAILING_KIT_KEY}`;

export const SET_LOADING = `SET_LOADING__${MAILING_KIT_KEY}`;

export const SEARCH_ORDER_REQUEST = `SEARCH_ORDER_REQUEST__${MAILING_KIT_KEY}`;
export const SEARCH_ORDER_SUCCESS = `SEARCH_ORDER_SUCCESS__${MAILING_KIT_KEY}`;
export const SEARCH_ORDER_FAILURE = `SEARCH_ORDER_FAILURE__${MAILING_KIT_KEY}`;

export const PATCH_ORDER_REQUEST = `PATCH_ORDER_REQUEST__${MAILING_KIT_KEY}`;
export const PATCH_ORDER_SUCCESS = `PATCH_ORDER_SUCCESS__${MAILING_KIT_KEY}`;
export const PATCH_ORDER_FAILURE = `PATCH_ORDER_FAILURE__${MAILING_KIT_KEY}`;
