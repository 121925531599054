import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';


export const resetState = createAction(ACTION_TYPES.RESET_STATE);

export const setLoading = createAction(ACTION_TYPES.SET_LOADING);
export const setPatient = createAction(ACTION_TYPES.SET_PATIENT);
export const setBatch = createAction(ACTION_TYPES.SET_BATCH);

export const wsSubscribe = createAction(ACTION_TYPES.WS_SUBSCRIBE);
export const wsUnsubscribe = createAction(ACTION_TYPES.WS_UNSUBSCRIBE);
export const wsSuccess = createAction(ACTION_TYPES.WS_SUCCESS);
export const wsFailure = createAction(ACTION_TYPES.WS_FAILURE);

export const copyToPracticeRequest = createAction(ACTION_TYPES.COPY_TO_PRACTICE_REQUEST);
export const copyToPracticeSuccess = createAction(ACTION_TYPES.COPY_TO_PRACTICE_SUCCESS);
export const copyToPracticeFailure = createAction(ACTION_TYPES.COPY_TO_PRACTICE_FAILURE);
export const copyToPracticeFinished = createAction(ACTION_TYPES.COPY_TO_PRACTICE_FINISHED);

export const setSpecimenKitId = createAction(ACTION_TYPES.SET_SPECIMEN_KIT_ID);

export const sendRequisitionRequest = createAction(ACTION_TYPES.SEND_REQUISITION_REQUEST);
export const sendRequisitionSuccess = createAction(ACTION_TYPES.SEND_REQUISITION_SUCCESS);
export const sendRequisitionFailure = createAction(ACTION_TYPES.SEND_REQUISITION_FAILURE);

export const addRequisitionProcessing = createAction(ACTION_TYPES.ADD_REQUISITION_PROCESSING);
export const removeRequisitionProcessing = createAction(ACTION_TYPES.REMOVE_REQUISITION_PROCESSING);
export const setRequisitionProcessingStatus = createAction(ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_STATUS);

export const setRequisitionProcessingLab = createAction(ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_LAB);
export const setRequisitionProcessingContainerType = createAction(
    ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_CONTAINER_TYPE,
);
export const setRequisitionProcessingCode = createAction(ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_CODE);
