export function isEqualObjects(...args) {
    let leftChain;
    let rightChain;

    function compare2Objects(x, y) {
        if (
            Number.isNaN(x)
        && Number.isNaN(y)
        && typeof x === 'number'
        && typeof y === 'number'
        ) {
            return true;
        }

        if (x === y) {
            return true;
        }

        if (
            (typeof x === 'function' && typeof y === 'function')
            || (x instanceof Date && y instanceof Date)
            || (x instanceof RegExp && y instanceof RegExp)
            || (x instanceof String && y instanceof String)
            || (x instanceof Number && y instanceof Number)
        ) {
            return x.toString() === y.toString();
        }

        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        if (leftChain.includes(x) || rightChain.includes(y)) {
            return false;
        }

        for (const p in y) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        for (const p in x) {
            if (Object.prototype.hasOwnProperty.call(x, p)) {
                if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                    return false;
                } else if (typeof y[p] !== typeof x[p]) {
                    return false;
                }

                switch (typeof x[p]) {
                case 'object':
                case 'function':
                    leftChain.push(x);
                    rightChain.push(y);

                    if (
                        !compare2Objects(
                            x[p],
                            y[p],
                        )
                    ) {
                        return false;
                    }

                    leftChain.pop();
                    rightChain.pop();
                    break;

                default:
                    if (x[p] !== y[p]) {
                        return false;
                    }

                    break;
                }
            }
        }

        return true;
    }

    if (args.length === 0) {
        return true;
    }

    for (let i = 1; i < args.length; i++) {
        leftChain = [];
        rightChain = [];

        if (!compare2Objects(args[0], args[i])) {
            return false;
        }
    }

    return true;
}

export default isEqualObjects;
