import { createUseStyles } from '@flowhealth/ui-components';

export const useStyles = createUseStyles(({ palette, typography, spacing }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        paddingBottom: spacing(20),
    },
    image: {
        width: 208,
        marginBottom: spacing(3)
    },
    title: {
        ...typography['22B'],
        margin: spacing(0, 0, 3),
    },
    description: {
        ...typography['16R'],
        fontWeight: 500,
        margin: spacing(0, 0, 7),
        textAlign: 'center',
        color: palette.coal[600]
    },
    hotlineContainer: {
        ...typography['16R'],
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        color: palette.coal[600],
    },
    hotlineIcon: {
        marginRight: 10
    },
    hotline: {
        color: palette.naviBlue[300],
        textDecoration: 'none',
        marginLeft: spacing(1),
    }
}));
