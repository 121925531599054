import { BOOKING_KEY } from '../constants';


export const INIT_PAGE = `INIT_PAGE__${BOOKING_KEY}`;
export const RESET_PAGE = `RESET_PAGE__${BOOKING_KEY}`;
export const SET_IS_LOADING = `SET_IS_LOADING__${BOOKING_KEY}`;
export const SET_ANSWERS = `SET_ANSWERS__${BOOKING_KEY}`;
export const SET_PRACTICE = `SET_PRACTICE__${BOOKING_KEY}`;
export const SET_PERSON = `SET_PERSON__${BOOKING_KEY}`;
export const SET_PATIENT = `SET_PATIENT__${BOOKING_KEY}`;
export const SET_DATE = `SET_DATE__${BOOKING_KEY};`;
export const SET_LOCATION = `SET_LOCATION__${BOOKING_KEY};`;

export const SELECT_PATIENT_BY_PRACTICE = `SELECT_PATIENT_BY_PRACTICE__${BOOKING_KEY}`;

export const SELECT_PATIENT_WITH_MAIN_PRACTICE = `SELECT_PATIENT_WITH_MAIN_PRACTICE__${BOOKING_KEY}`;
export const SELECT_PATIENT_WITH_MAIN_PRACTICE_SUCCESS = `SELECT_PATIENT_WITH_MAIN_PRACTICE_SUCCESS__${BOOKING_KEY}`;
export const SELECT_PATIENT_WITH_MAIN_PRACTICE_FAILURE = `SELECT_PATIENT_WITH_MAIN_PRACTICE_FAILURE__${BOOKING_KEY}`;

export const COPY_TO_PRACTICE_REQUEST = `COPY_TO_PRACTICE_REQUEST__${BOOKING_KEY}`;
export const COPY_TO_PRACTICE_SUCCESS = `COPY_TO_PRACTICE_SUCCESS__${BOOKING_KEY}`;
export const COPY_TO_PRACTICE_FAILURE = `COPY_TO_PRACTICE_FAILURE__${BOOKING_KEY}`;
export const COPY_TO_PRACTICE_FINISHED = `COPY_TO_PRACTICE_FINISHED__${BOOKING_KEY}`;

export const CREATE_APPOINTMENT_REQUEST = `CREATE_APPOINTMENT_REQUEST__${BOOKING_KEY}`;
export const CREATE_APPOINTMENT_SUCCESS = `CREATE_APPOINTMENT_SUCCESS__${BOOKING_KEY}`;
export const CREATE_APPOINTMENT_FAILURE = `CREATE_APPOINTMENT_FAILURE__${BOOKING_KEY}`;

export const WS_SUBSCRIBE = `WS_SUBSCRIBE__${BOOKING_KEY}`;
export const WS_UNSUBSCRIBE = `WS_UNSUBSCRIBE__${BOOKING_KEY}`;
export const WS_SUCCESS = `WS_SUCCESS__${BOOKING_KEY}`;
export const WS_FAILURE = `WS_FAILURE__${BOOKING_KEY}`;

export const SET_COMMON_PRACTICE = `SET_COMMON_PRACTICE__${BOOKING_KEY}`;
export const SET_INSURANCE = `SET_INSURANCE__${BOOKING_KEY}`;
