import { Wrapper as GoogleMapsWrapper } from '@googlemaps/react-wrapper';
import React, { useCallback, useState, memo, useEffect } from 'react';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { isNil } from 'lodash';
import { SpinLoader } from '@flowhealth/ui-components';

import injectSaga from 'utils/injectSaga';

import { SELECT_LOCATION_KEY } from './store/constants';
import saga from './store/saga';
import { selectLocations, selectEventLocations, selectIsLoadingLocations } from './store/selectors';
import {
    fetchLocationsRequest,
    fetchEventLocationsRequest,
    resetState
} from './store/actions';
import { Locations, ConfirmSelected } from './components';

const SelectLocation = ({ onSuccess, patient, eventId, onBack }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector(selectIsLoadingLocations);
    const locations = useSelector(eventId ? selectEventLocations : selectLocations);

    const [selectedLocation, setSelectedLocation] = useState();

    useEffect(() => {
        if (isNil(locations)) {
            const fetch = eventId
                ? fetchEventLocationsRequest({ id: eventId })
                : fetchLocationsRequest();
            dispatch(fetch)
        }
    }, [dispatch, eventId, locations]);

    useEffect(() => () => dispatch(resetState()), [dispatch])

    const handleConfirmClose = useCallback(() => {
        setSelectedLocation(undefined);
    }, []);

    const handleConfirmSuccess = useCallback(() => {
        onSuccess(selectedLocation);
    }, [onSuccess, selectedLocation]);

    if (isLoading) return <SpinLoader />

    return selectedLocation ? (
        <ConfirmSelected
            location={selectedLocation}
            onSuccess={handleConfirmSuccess}
            onBack={handleConfirmClose}
        />
    ) : (
        <GoogleMapsWrapper
            apiKey={process.env.REACT_APP_GMAPS_API_KEY}
            libraries={['geometry']}
            render={() => <SpinLoader />}
        >
            <Locations
                locations={locations}
                patient={patient}
                onBack={onBack}
                onSelect={setSelectedLocation}
            />
        </GoogleMapsWrapper>
    );
};

const withSaga = injectSaga({ key: SELECT_LOCATION_KEY, saga });

export default compose(
    memo,
    withSaga,
)(SelectLocation);
