import { call, put, takeLatest, all, take } from 'redux-saga/effects';
import API from '@flowhealth/api';

import { openErrorMessage, openSuccessMessage } from 'components/Message/actions';

import { userRequest } from 'containers/User/actions';
import { USER_SUCCESS } from 'containers/User/constants';
import { fetchPersonsByCurrentUser } from 'containers/PatientPortal/store/utils';

import { ZIP_CODE_ERR_MSG } from 'configuration/constants/errorMessages';

import * as ACTION_TYPES from './actionTypes';
import * as ACTIONS from './actions';


function* addProfile({ payload = {} } = {}) {
    try {
        const { patient, onSuccess, updatePersons = false } = payload;
        yield call(API.getInstance().addPatientDependent, patient);

        yield put(userRequest(onSuccess, false, false));

        if (updatePersons) {
            yield take(USER_SUCCESS);
            yield call(fetchPersonsByCurrentUser);
        }
        yield put(ACTIONS.addProfileSuccess());
        yield put(openSuccessMessage('Profile was created successfully!'));
    } catch (error) {
        yield put(ACTIONS.addProfileFailure({ error }));
    }
}


function* getAddress({ payload = {} } = {}) {
    try {
        const response = yield call(
            API.getInstance().search,
            { object: 'zip_code', q: payload.zip_code },
        );
        const { results: [result] } = response;

        if (result) {
            yield put(ACTIONS.setInputValue({
                form: payload.form,
                data: {
                    id: 'zip_code',
                    value: payload.zip_code,
                    valid: true,
                },
                formData: payload.formData,
            }));
            yield put(ACTIONS.setInputValue({
                form: payload.form,
                data: {
                    id: 'city',
                    value: result.city,
                },
            }));
            yield put(ACTIONS.setInputValue({
                form: payload.form,
                data: {
                    id: 'state',
                    value: result.state,
                },
            }));
        } else {
            yield put(openErrorMessage(ZIP_CODE_ERR_MSG));
            yield put(ACTIONS.setInputValue({
                form: payload.form,
                data: {
                    id: 'zip_code',
                    value: payload.zip_code,
                    valid: false,
                },
                formData: payload.formData,
            }));
            yield put(ACTIONS.setInputValue({
                form: payload.form,
                data: {
                    id: 'city',
                    value: '',
                },
            }));
            yield put(ACTIONS.setInputValue({
                form: payload.form,
                data: {
                    id: 'state',
                    value: '',
                },
            }));
            return yield put(ACTIONS.getAddressFailure());
        }

        yield put(ACTIONS.getAddressSuccess());
    } catch (error) {
        yield put(ACTIONS.getAddressFailure({ error }));
    }
}

export default function* Saga() {
    yield all([
        takeLatest(ACTION_TYPES.ADD_PROFILE_REQUEST, addProfile),
        takeLatest(ACTION_TYPES.GET_ADDRESS_REQUEST, getAddress),
    ]);
}
