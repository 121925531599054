/* eslint-disable max-len */
import React from 'react';


const Attention = () => (
    <svg viewBox="0 0 20 20">
        <path d="M8.276 2.93c.773-1.314 2.675-1.314 3.448 0l6.503 11.056C19.011 15.319 18.05 17 16.503 17H3.497c-1.547 0-2.508-1.68-1.724-3.014L8.276 2.931z" />
        <path fill="#fff" fillRule="evenodd" d="M10 7a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0V8a1 1 0 0 0-1-1zm0 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" clipRule="evenodd" />
    </svg>
);

export default Attention;
