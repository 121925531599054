import { KEY_PP_ORDERS } from '../constants';

export const RESET_STATE = `RESET_STATE__${KEY_PP_ORDERS}`;

export const SET_LOADING = `SET_LOADING__${KEY_PP_ORDERS}`;

export const GET_ORDERS_REQUEST = `GET_ORDERS_REQUEST__${KEY_PP_ORDERS}`;
export const GET_ORDERS_SUCCESS = `GET_ORDERS_SUCCESS__${KEY_PP_ORDERS}`;
export const GET_ORDERS_FAILURE = `GET_ORDERS_FAILURE__${KEY_PP_ORDERS}`;

export const GET_VC_REQUEST = `GET_VC_REQUEST__${KEY_PP_ORDERS}`;
export const GET_VC_SUCCESS = `GET_VC_SUCCESS__${KEY_PP_ORDERS}`;
export const GET_VC_FAILURE = `GET_VC_FAILURE__${KEY_PP_ORDERS}`;
