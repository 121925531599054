import React from 'react';
import cn from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import FormLoader from './formLoader';
import LogInLoader from './formLogInLoader';
import TableLoader from './tableLoader';
import TabLoader from './tabLoader';
import PatientPortalHomeLoader from './patientPortalHomeLoader';
import PatientPortalPopupLoader from './patientPortalPopupLoader';
import ReqPlateLoader from './reqPlateLoader';
import ReqTestsPanelsLoader from './reqTestsPanelsLoader';
import PatientAuthLoader from './PatientAuthLoader';
import RunLoader from './runLoader';
import SignatureLoader from './signatureLoader';
import { styles } from './styles';


export const LoadingWrapper = withStyles(styles)(({ children, classes }) => (
    <div className={classes.root}>
        <div className={classes.loader}>
            {children}
        </div>
    </div>
));


export const LoadingSpinner = withStyles(styles)(({ classes }) => (
    <svg className={classes.circularLoader} viewBox="25 25 50 50">
        <circle
            className={classes.loaderPath}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
        />
    </svg>
));


class Loading extends React.PureComponent {
    get defaultLoader() {
        const { classes } = this.props;
        return (
            <div className={classes.loader}>
                <LoadingSpinner />
            </div>
        );
    }


    get loaderType() {
        const { type, title, rows, isSorting, infiniteScroll, pageForScroll } = this.props;

        switch (type) {
        case 'patientAuth':
            return <PatientAuthLoader />;
        case 'logIn':
            return <LogInLoader />;
        case 'form':
            return <FormLoader title={title} />;
        case 'table':
            return <TableLoader isSorting={isSorting} infiniteScroll={infiniteScroll} pageForScroll={pageForScroll} />;
        case 'tab':
            return <TabLoader />;
        case 'reqPlate':
            return <ReqPlateLoader />;
        case 'patientPortalHome':
            return <PatientPortalHomeLoader />;
        case 'patientPortalPopup':
            return <PatientPortalPopupLoader />;
        case 'reqTestsPanels':
            return <ReqTestsPanelsLoader />;
        case 'run':
            return <RunLoader rows={rows} />;
        case 'signature':
            return <SignatureLoader />;
        default:
            return this.defaultLoader;
        }
    }

    render() {
        const { id, classes, className, infiniteScroll, pageForScroll } = this.props;
        return (
            <div
                id={id}
                className={
                    cn(infiniteScroll && pageForScroll > 0 ? classes.defaultRoot : classes.root, className)
                }
            >
                {this.loaderType}
            </div>
        );
    }
}


export default withStyles(styles)(Loading);
