import { useState, useEffect } from 'react';

import { ACTIONS_INDENT } from '../constants';

import { useContainerBreakpoint } from './useContainerBreakpoint';

export const useContentStyle = (actionsRef, isActionFixedToBottom) => {
    const isMobile = useContainerBreakpoint();
    const [contentStyle, setContentStyle] = useState({});

    useEffect(() => {
        const element = actionsRef.current;

        if (element) {
            setContentStyle(
                isMobile || isActionFixedToBottom
                    ? {
                          paddingBottom:
                              element?.getBoundingClientRect().height +
                              ACTIONS_INDENT,
                          width: isActionFixedToBottom ? 'inherit' : undefined,
                      }
                    : {}
            );
        }
    }, [actionsRef, isMobile, isActionFixedToBottom]);

    return contentStyle;
};
