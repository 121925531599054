/* eslint-disable max-len */
import React from 'react';

const Sort = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
        <path fill="inherit" fillRule="evenodd" d="M5.6 6a.6.6 0 0 0-.6.6v.8a.6.6 0 0 0 .6.6h8.8a.6.6 0 0 0 .6-.6v-.8a.6.6 0 0 0-.6-.6H5.6zM5.6 9a.6.6 0 0 0-.6.6v.8a.6.6 0 0 0 .6.6h6.8a.6.6 0 0 0 .6-.6v-.8a.6.6 0 0 0-.6-.6H5.6zM5.6 12a.6.6 0 0 0-.6.6v.8a.6.6 0 0 0 .6.6h4.8a.6.6 0 0 0 .6-.6v-.8a.6.6 0 0 0-.6-.6H5.6z" clipRule="evenodd" />
    </svg>
);

export default Sort;
