import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import dayjs from 'dayjs';
import { createStructuredSelector } from 'reselect';
import saga from 'saga';
import * as Sentry from '@sentry/react';
import { GAIdentifier } from 'index';
import {
    safeGet, getNameFromParams,
    PrintManager as PrintManagerCreator,
} from '@flowhealth/utils';
import { withStyles } from '@material-ui/core/styles';
import { App } from '@flowhealth/app';
import { theme, ThemeProvider } from '@flowhealth/ui-components';

import { FH_DOMAINS } from 'API/constants';

import { LoadingSpinner, LoadingWrapper } from 'components/Loading';

import { userRequest } from 'containers/User/actions';
import { makeSelectRole, makeSelectUserId, makeSelectUser, makeSelectGetUserLoading } from 'containers/User/selector';

import routes from 'configuration/routes';

import injectSaga from 'utils/injectSaga';
import { parse } from 'utils/query-string';
import { mergeObjects } from 'utils/objects';

import BlockingRoute from './blockingRouter';
import PatientRoute from './patientRouter';
import { getLogoRequest } from './actions';
import globalStyles from './globalStyles';


export const PrintManager = new PrintManagerCreator();

const Loader = () => (
    <LoadingWrapper>
        <LoadingSpinner />
    </LoadingWrapper>
);

const getRoute = route => {
    switch (route.type) {
    case 'blocking': return <BlockingRoute {...route} />;
    case 'public':
    case 'patient':
    default:
        return <PatientRoute {...route} />;
    }
};

export class Application extends React.Component {
    componentDidMount() {
        const { dispatch, role, location, user } = this.props;
        const token = App.getInstance().getToken();
        const domain = window.location.hostname;
        const isFHDomain = FH_DOMAINS.some(fhDomain => domain.includes(fhDomain));

        if (GAIdentifier) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }

        if (!isFHDomain) {
            if (!token) {
                const { token: queryParamToken } = parse(location.search);
                if (queryParamToken) {
                    dispatch(userRequest());
                } else {
                    // get logo on auth pages
                    dispatch(getLogoRequest(domain));
                }
            }
        }
        const timezone = dayjs.tz.guess();
        const offset = dayjs().tz(timezone).format('Z');
        window.user = mergeObjects(safeGet(user, 'data.user'), { role, timezone: getNameFromParams(timezone, offset) });
        Sentry.setUser({
            id: safeGet(window, 'user.id'),
            email: safeGet(window, 'user.email'),
            role: safeGet(window, 'user.role'),
        });
    }

    render() {
        const { isUserLoading } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Suspense fallback={<Loader />}>
                    {isUserLoading && <Loader />}
                    {!isUserLoading && (
                        <Switch>
                            {routes && routes.map((route, index) => (
                                <Route key={index} {...route}>
                                    {getRoute(route)}
                                </Route>
                            ))}
                        </Switch>
                    )}
                </Suspense>
            </ThemeProvider>
        );
    }
}


const mapStateToProps = createStructuredSelector({
    user: makeSelectUser(),
    role: makeSelectRole(),
    userId: makeSelectUserId(),
    isUserLoading: makeSelectGetUserLoading(),
});


const withSaga = injectSaga({ key: 'app', saga });

const withConnect = connect(mapStateToProps);

export default compose(
    withSaga,
    withRouter,
    withConnect,
    withStyles(globalStyles),
)(Application);
