/* eslint-disable max-len */
import React from 'react';


const NewArrowRight = () => (
    <svg viewBox="0 0 20 20">
        <path d="M10.5615 9.93852L7.13959 13.4167C7.00973 13.5407 6.9385 13.7085 6.9385 13.8847C6.9385 14.0609 7.00973 14.2276 7.13959 14.3518L7.55221 14.7461C7.68196 14.87 7.85486 14.9385 8.03924 14.9385C8.22372 14.9385 8.39673 14.8705 8.52648 14.7466L12.8605 10.4056C12.9907 10.2812 13.062 10.1155 13.0615 9.93917C13.062 9.7619 12.9907 9.59609 12.8605 9.47188L8.52648 5.13047C8.39673 5.00665 8.22383 4.93852 8.03924 4.93852C7.85486 4.93852 7.68196 5.00674 7.55221 5.13047L7.13959 5.52474C7.00973 5.64846 6.9385 5.81369 6.9385 5.98988C6.9385 6.16597 7.00973 6.32248 7.13959 6.44631L10.5615 9.93852Z" />
    </svg>
);
export default NewArrowRight;
