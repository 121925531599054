export const checkCorporateLocation = location => {
    const { object_type } = location ?? {};
    return object_type === 'corporate_location'
};

export const getTestName = testType => {
    switch (testType) {
        case 'rtm': return 'Respiratory Pathogens Panel'
        case 'naat':
        case 'poc':
        case 'rpp': return 'Covid Testing'
        default: return null
    }
}

export const prepareTestName = (currentTestType, testTypes) => {
    const prepareValue = value => {
        if (value === 'rat' || value === 'poc') {
            return 'Antigen';
        } else if (value === 'naat') {
            return 'Molecular';
        } else if (value === 'rtm') {
            return 'RPP'
        } else if (value === 'rpp') {
            return 'PCR'
        }
        return null;
    }
    let value = prepareValue(currentTestType?.toLowerCase());
    if (!value) {
        const testType = testTypes.find(test => test.code?.toLowerCase() === currentTestType)?.display
        value = prepareValue(testType?.toLowerCase()) ?? testType;
    }
    return value;
};
