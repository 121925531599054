import { createSelector } from 'reselect';
import { safeGet } from '@flowhealth/utils';

import { FLOW_FLEX_KIT_KEY } from './constants';


const selectState = state => safeGet(state, FLOW_FLEX_KIT_KEY, {});

export const selectKitBatch = createSelector(
    selectState,
    state => safeGet(state, 'batch'),
);

export const selectFields = createSelector(
    selectState,
    state => safeGet(state, 'fields')
);

export const selectKitBatchPractice = createSelector(
    selectKitBatch,
    state => safeGet(state, 'practice'),
);

export const selectKitId = createSelector(
    selectState,
    state => safeGet(state, 'kitId'),
);

export const selectImgSrc = createSelector(
    selectState,
    state => safeGet(state, 'imgSrc'),
);

export const selectFile = createSelector(
    selectState,
    state => safeGet(state, 'file'),
);

export const selectKitBatchPatient = createSelector(
    selectState,
    state => safeGet(state, 'patient'),
);

export const selectLoading = createSelector(
    selectState,
    state => safeGet(state, 'isLoading'),
);

export const selectCopyPatientId = createSelector(
    selectState,
    state => safeGet(state, 'copyPatientId'),
);

export const selectTestResult = createSelector(
    selectState,
    state => safeGet(state, 'testResult'),
);

export const selectAction = createSelector(
    selectState,
    state => safeGet(state, 'action'),
);
