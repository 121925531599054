import { isFunction } from 'lodash';
import * as Sentry from '@sentry/react';

class ReCAPTCHA {
    static instance;

    static token = null;

    static renderElement = null;

    static getInstance(siteKey, element, changeCallback, successCallback) {
        try {
            if ((!ReCAPTCHA.instance && element) || (element && this.renderElement !== element)) {
                if (!siteKey) {
                    throw new Error('"siteKey" doesn\'t exist');
                }
                ReCAPTCHA.instance = new ReCAPTCHA(siteKey, element, changeCallback, successCallback);
            }
        } catch (err) {
            Sentry.captureException(err);
        }
        return ReCAPTCHA.instance;
    }

    reset = () => {
        this.token = null;
        try {
            this.changeCallback(null);
            window.grecaptcha.reset();
        } catch (e) {
            return e;
        }
    };

    loadReCaptcha = (siteKey, element) => {
        window.grecaptcha.ready(() => {
            window.grecaptcha.render(element, {
                sitekey: siteKey,
                callback: token => {
                    this.changeCallback(token);
                    this.token = token;
                    isFunction(this.successCallback) && this.successCallback(token);
                },
                'expired-callback': () => {
                    this.changeCallback(null);
                    this.token = null;
                },
                'error-callback': () => {
                    this.changeCallback(null);
                    this.token = null;
                },
            });
        });
    };

    waitLoad = (siteKey, element) => {
        if (!window.grecaptcha?.ready) {
            setTimeout(() => this.waitLoad(siteKey, element), 250);
        } else this.loadReCaptcha(siteKey, element);
    };

    constructor(siteKey, element, changeCallback, successCallback) {
        this.changeCallback = changeCallback;
        this.successCallback = successCallback;
        this.renderElement = element;
        this.token = null;
        this.waitLoad(siteKey, element);
    }
}

export default ReCAPTCHA;
