/* eslint-disable max-len */
import React from 'react';

const Info = () => (
    <svg fill="none" viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M10 17c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zm0-16c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm1 12c0 .552-.448 1-1 1s-1-.448-1-1v-3c0-.552.448-1 1-1s1 .448 1 1v3zm0-6c0 .552-.448 1-1 1s-1-.448-1-1c0 3.547 0-.547 0 0 0-.552.448-1 1-1s1 .448 1 1c0 3.547 0-.547 0 0z" clipRule="evenodd" />
    </svg>

);

export default Info;
