import { set } from 'immutable';
import { createReducer, safeGet } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';


const initialState = {
    isLoading: false,
    batch: undefined,
    fields: undefined,
    testResult: undefined,
    patient: undefined,
    copyPatientId: undefined,
};

const resetState = () => ({ ...initialState });

const setIsLoading = value => state => set(state, 'isLoading', value);

const setPatient = (state, { payload = {} }) => set(state, 'patient', payload);

const setBatch = (state, { payload = {} }) => set(state, 'batch', payload?.batch);
const setFields = (state, { payload = {} }) => set(state, 'fields', payload?.fields);

const setResultRefRange = (state, { payload = {} } = {}) => set(
    state, 'resultRefRange', safeGet(payload, 'refRange', payload),
);

const setTestResult = (state, { payload = {} } = {}) => set(
    state, 'testResult', safeGet(payload, 'testResult', payload),
);

const setPanelId = (state, { payload = {} } = {}) => set(
    state, 'panelId', safeGet(payload, 'panelId', payload),
);

const setCopyPatientId = (state, { payload = {} } = {}) => set(
    state, 'copyPatientId', safeGet(payload, 'copyPatientId'),
);


export default createReducer(initialState, {
    [ACTION_TYPES.RESET_STATE]: resetState,

    [ACTION_TYPES.SET_LOADING]: setIsLoading(),
    [ACTION_TYPES.SET_PATIENT]: setPatient,
    [ACTION_TYPES.SET_BATCH]: [setBatch, setFields],

    [ACTION_TYPES.GET_REF_RANGES_SUCCESS]: [setResultRefRange, setPanelId],

    [ACTION_TYPES.SET_TEST_RESULT]: setTestResult,

    [ACTION_TYPES.SUBMIT_TEST_REQUEST]: setIsLoading(true),
    [ACTION_TYPES.SUBMIT_TEST_SUCCESS]: setIsLoading(false),
    [ACTION_TYPES.SUBMIT_TEST_FAILURE]: setIsLoading(false),

    [ACTION_TYPES.COPY_TO_PRACTICE_SUCCESS]: setCopyPatientId,
    [ACTION_TYPES.COPY_TO_PRACTICE_FINISHED]: setCopyPatientId,
});
