import { call, put, takeEvery, all } from 'redux-saga/effects';
import { isObject, isFullObject, isArray } from '@flowhealth/utils';
import API from '@flowhealth/api';

import {
    GET_VALUES_REQUEST,
    GET_VALUES_BULK_REQUEST,
} from './constants';
import {
    getValuesSuccess,
    getValuesFailure,
    getValuesBulkSuccess,
    getValuesBulkFailure,
} from './actions';


const requests = new Set();

export function* getValues({ payload: { file } }) {
    if (requests.has(file)) return null;

    requests.add(file);
    try {
        const data = yield call(API.getInstance().getValueSet, file);
        if (!data || (!isObject(data) && !isArray(data))) throw new Error('JSON is not valid');
        yield put(getValuesSuccess({ file, data }));
    } catch (error) {
        requests.delete(file);
        yield put(getValuesFailure({ file, data: error }));
    }
}

export function* getValuesBulk({ payload: { files } }) {
    if (requests.has(files)) return null;

    requests.add(files);
    try {
        const data = yield call(API.getInstance().getValueSetBulk, { vs: files.join(',') });
        if (!isFullObject(data)) throw new Error('JSON is not valid');
        yield put(getValuesBulkSuccess({ files, data }));
    } catch (error) {
        requests.delete(files);
        yield put(getValuesBulkFailure({ files, data: error }));
    }
}

function* Saga() {
    yield all([
        takeEvery(GET_VALUES_REQUEST, getValues),
        takeEvery(GET_VALUES_BULK_REQUEST, getValuesBulk),
    ]);
}

export default Saga;
