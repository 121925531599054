import React, { memo, useState, useCallback, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import { Icon } from '@flowhealth/ui-components';
import { compose } from 'redux';
import { ActionsPlus, View, Close } from '@flowhealth/icons';
import { isString, isFunction } from 'lodash';


const styles = ({ palette = {} }) => ({
    root: {
        width: '100%',
        '& input': { display: 'none' },
        '&.hidden': { display: 'none' },
    },
    addPhotoButton: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: palette.ash['250'],
        color: palette.coal['500'],
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '24px',
        borderRadius: 8,
        cursor: 'pointer',

        '& > svg > path': {
            fill: 'currentColor',
        },
    },
    previewImageContainer: {
        height: 40,
        width: 40,
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        right: 12,
        borderRadius: 4,
        overflow: 'hidden',
    },
    previewImage: {
        maxWidth: '100%',
        height: 'auto',
        objectFit: 'contain',
    },
    menu: {
        position: 'absolute',
        width: 180,
        background: palette.white.main,
        border: `1px solid ${palette.ash[300]}`,
        boxShadow: '0px 4px 8px -4px rgba(36, 71, 123, 0.15)',
        borderRadius: '4px',
    },
    menuItem: {
        padding: '12px 16px',
        cursor: 'pointer',
        '&:hover': {
            background: palette.naviBlue.dark,
            color: palette.white.main,
        },
    },
    bar: {
        padding: '0 16px',
        height: 48,
        width: '100%',
        background: palette.ash[200],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    button: { cursor: 'pointer' },
    buttonLabel: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '24px',
        color: palette.naviBlue.dark,
    },
    title: {
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '24px',
        color: palette.coal.main,
    },
    label: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '1.33',
        color: palette.coal[500],
        '& .complexity': {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
        },
        '& .icon': {
            position: 'absolute',
            left: -28,
        },
        '& .tooWeak': {
            color: palette.red.main,
            '& svg': { fill: palette.red.main },
        },
        '& .notSecure': {
            color: palette.gold.main,
            '& svg': { fill: palette.gold.main },
        },
        '& .good': {
            color: palette.green[300],
            '& svg': { fill: palette.green[300] },
        },
        '& .perfect': {
            color: palette.green.main,
            '& svg': { fill: palette.green.main },
        },
        '& .required': {
            marginLeft: 4,
            color: palette.red[700],
        },
    },
    preview: {
        width: '100vw',
        background: '#000',
        position: 'fixed',
        height: '100vh',
        top: 0,
        left: 0,
        zIndex: 9000,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
    previewContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 20px',
        marginTop: 32,
        marginBottom: 100,
    },
    previewTitle: {
        fontWeight: 'bold',
        fontSize: 18,
        lineHeight: '28px',
        color: '#FFFFFF',
    },
});

const UploadPhotoField = ({
    id,
    classes,
    file,
    className,
    label,
    title,
    required,
    previewOnly = false,
    hidden,
    onChange,
    onFocus,
    useBase64 = false,
}) => {
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [openPreview, setOpenPreview] = useState(false);
    const updatePreview = file => {
        const reader = new FileReader();

        reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
    };

    useEffect(() => {
        if (file) {
            if (isString) setImagePreviewUrl(file);
            else updatePreview(file);
        } else setImagePreviewUrl('');
    }, [file]);

    const runOnChange = useCallback((reader = {}, file) => {
        if (!isFunction(onChange)) return;
        if (useBase64) {
            return onChange({ id, value: reader.result });
        }

        onChange(file);
    }, [id, onChange, useBase64]);

    const handleImageChange = useCallback(e => {
        const reader = new FileReader();
        const [file] = e.target.files;

        reader.onloadend = () => {
            runOnChange(reader, file);
            setImagePreviewUrl(reader.result);
        };

        reader.readAsDataURL(file);
    }, [runOnChange]);

    const openPreviewAction = () => previewOnly && imagePreviewUrl && setOpenPreview(true);

    const fieldLabel = () => {
        if (!label) return;

        return (
            <label htmlFor={id} className={classes.label}>
                <span>
                    {label}
                    {required && <span className="required">*</span>}
                </span>
            </label>
        );
    };

    const uploadBar = () => {
        if (previewOnly || imagePreviewUrl) {
            return (
                <div className={classes.bar} onClick={openPreviewAction}>
                    <div className={classes.container}>
                        <div className={classes.previewImageContainer}>
                            {imagePreviewUrl && (
                                <img className={classes.previewImage} src={imagePreviewUrl} alt="uploaded" />
                            )}
                        </div>
                        <span className={classes.title}>{title}</span>
                    </div>
                    {previewOnly ? (
                        <Icon type={View} size={24} color="#4F5862" />
                    ) : (
                        <div className={classes.buttonLabel}>Edit</div>
                    )}
                </div>
            );
        }

        return (
            <div className={classes.bar}>
                <div className={classes.buttonLabel}>{title}</div>
                <Icon type={ActionsPlus} size={24} color="#2F8FE3" />
            </div>
        );
    };

    const input = () => {
        if (previewOnly) return;

        return (
            <input
                id={id}
                accept="image/giff,image/jpg,image/jpeg,image/tiff,image/png"
                type="file"
                onChange={handleImageChange}
            />
        );
    };

    const preview = () => {
        if (!previewOnly || !openPreview || !imagePreviewUrl) return;
        return (
            <div className={classes.preview}>
                <div className={classes.previewContainer}>
                    <div />
                    <div className={classes.previewTitle}>{title}</div>
                    <Icon
                        size={20}
                        type={Close}
                        color="#FFFFFF"
                        onClick={() => setOpenPreview(false)}
                    />
                </div>
                <img
                    className={classes.previewImage}
                    src={imagePreviewUrl}
                    alt="uploaded"
                />
            </div>
        );
    };
    return (
        <div className={cn(classes.root, className, { hidden })} onClick={() => onFocus && onFocus({ id })}>
            {fieldLabel()}
            <label htmlFor={id}>
                {uploadBar()}
                {input()}
            </label>
            {preview()}
        </div>
    );
};

export default compose(
    withStyles(styles),
    memo,
)(UploadPhotoField);
