/* eslint-disable max-len */
import React from 'react';


const CsvDownload = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M13.42 2.249l4.36 4.586c.142.149.22.347.22.551V9.2c0 .442-.358.8-.8.8-.442 0-.8-.358-.8-.8v-.8h-3.2c-.883 0-1.6-.716-1.6-1.6V3.6H4.4c-.442 0-.8.358-.8.8v4.8c0 .442-.358.8-.8.8-.442 0-.8-.358-.8-.8V3.6C2 2.717 2.717 2 3.6 2h9.24c.22 0 .429.09.58.249zm2.788 10.046c.05-.397.387-.695.787-.695.478 0 .847.418.788.893l-.6 4.806c-.051.401-.391.701-.795.701H14.6c-.404 0-.744-.3-.794-.7l-.6-4.807c-.06-.475.309-.893.786-.893.401 0 .739.298.788.695l.513 4.105h.4l.514-4.105zM3.622 15.068c.127.787.867 1.332 1.663 1.332H6c.442 0 .8.358.8.8 0 .441-.358.8-.8.8h-.663c-1.67 0-3.164-1.222-3.322-2.886C1.833 13.205 3.329 11.6 5.2 11.6H6c.442 0 .8.358.8.8 0 .441-.358.8-.8.8h-.8c-.971 0-1.741.866-1.578 1.868zM9.6 14c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2c.442 0 .8-.358.8-.8 0-.442-.358-.8-.8-.8H9.2c-.883 0-1.6.717-1.6 1.6v.8c0 .884.717 1.6 1.6 1.6h1.2c.22 0 .4.18.4.4 0 .22-.18.4-.4.4h-2c-.442 0-.8.358-.8.8 0 .442.358.8.8.8h2.417c.874 0 1.583-.709 1.583-1.583v-.834c0-.874-.709-1.583-1.583-1.583H9.6z" clipRule="evenodd" />
    </svg>
);

export default CsvDownload;
