/* eslint-disable max-len */
import React from 'react';

const CloudUp = () => (
    <svg viewBox="0 0 20 20">
        <path
            fillRule="evenodd"
            d="M14.298 13.004l-.293-.001c-1.32 0-1.322 2 0 2 7.467 0 7.575-8.201 2.443-9.76 0 0-.702-1.816-2.557-3.058C9.57-.708 3.736 1.99 3.076 7.102c0 0-1.432.32-2.344 1.62-1.908 2.721.146 6.28 3.48 6.28.203 0 .407.006.607.011 1.154.032 2.19.061 2.19-1.01 0-1.032-.845-1.01-1.852-.986-.797.02-1.694.042-2.357-.45-2.1-1.562.355-4.786 2.448-3.122-2.064-6.8 8.324-9.034 9.656-2.43 2.986-.107 4.24 3.786 1.826 5.437-.824.563-1.524.558-2.432.552zm-3.358-2.022l.004 6.81c0 .38-.288.708-.65.708h-.558c-.363 0-.676-.32-.676-.7v-6.848l-1.552 1.47a.62.62 0 0 1-.457.201.638.638 0 0 1-.465-.201l-.394-.413A.701.701 0 0 1 6 11.522a.7.7 0 0 1 .192-.487l3.341-3.334a.638.638 0 0 1 .468-.201c.176 0 .342.07.466.201l3.341 3.334a.7.7 0 0 1 .192.487.701.701 0 0 1-.192.487l-.395.413a.643.643 0 0 1-.935 0l-1.538-1.44z"
            clipRule="evenodd"
        />
    </svg>
);

export default CloudUp;
