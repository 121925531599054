import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@flowhealth/ui-components';
import cn from 'classnames';


const styles = ({ palette = {} } = {}) => ({
    root: {
        background: palette.coal[800],
        position: 'absolute',
        top: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 9999,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 22,
        lineHeight: '28px',
        textAlign: 'center',
        color: palette.white.main,
    },
    subtitle: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '20px',
        textAlign: 'center',
        color: palette.coal[500],

        marginTop: 24,
    },
    button: {
        marginTop: 64,
    },
});

const ErrorScreen = ({ classes, className, title, subtitle, retryAction }) => (
    <div className={cn(classes.root, className)}>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{subtitle}</div>
        <Button
            className={classes.button}
            theme="text"
            label="Update Again"
            onClick={retryAction}
        />
    </div>
);

export default withStyles(styles)(ErrorScreen);
