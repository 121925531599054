import dayjs from 'dayjs';
import { safeGet } from '@flowhealth/utils';

import { COVID_STATUS, COVID_TEST_TYPES, POSITIVE_TEST_CODES, NEGATIVE_TEST_CODES } from './constants';

export const getLatestReqTest = (pocReqTest, pcrReqTest) => {
    const pocCollectedAt = safeGet(pocReqTest, 'requisition.collected_at', null);
    const pcrCollectedAt = safeGet(pcrReqTest, 'requisition.collected_at', null);

    if (pocCollectedAt === null && pcrCollectedAt === null) return [];
    if (pocCollectedAt === null && pcrCollectedAt !== null) return [pcrReqTest, [COVID_TEST_TYPES.PCR]];
    if (pocCollectedAt !== null && pcrCollectedAt === null) {
        return [pocReqTest, [COVID_TEST_TYPES.POC, COVID_TEST_TYPES.NAAT]];
    }

    if (dayjs.utc(pocCollectedAt).isBefore(dayjs.utc(pcrCollectedAt))) {
        return [pcrReqTest, [COVID_TEST_TYPES.PCR]];
    }
    return [pocReqTest, [COVID_TEST_TYPES.POC, COVID_TEST_TYPES.NAAT]];
};

export const isReqTestNegative = reqTest => {
    const testCode = safeGet(reqTest, 'result.range.code');
    return NEGATIVE_TEST_CODES.includes(testCode);
};

export const isReqTestPositive = reqTest => {
    const testCode = safeGet(reqTest, 'result.range.code');
    return POSITIVE_TEST_CODES.includes(testCode);
};

export const isPcrExpired = pcrReqTest => {
    const collectedAt = safeGet(pcrReqTest, 'requisition.collected_at');
    const collectedAtDayjs = dayjs.utc(collectedAt);
    const isCollectedAtFriday = collectedAtDayjs.isoWeekday() === 5;

    if (isCollectedAtFriday) {
        const is72HoursPassed = collectedAtDayjs.isBefore(dayjs.utc().subtract(3, 'day'));
        if (!collectedAt || is72HoursPassed) return true;
    } else {
        const is48HoursPassed = collectedAtDayjs.isBefore(dayjs.utc().subtract(2, 'day'));
        if (!collectedAt || is48HoursPassed) return true;
    }

    return false;
};

export const pcrCheck = pcrReqTest => {
    if (isReqTestPositive(pcrReqTest)) return COVID_STATUS.positive;

    if (isPcrExpired(pcrReqTest)) return COVID_STATUS.notCleared;

    if (isReqTestNegative(pcrReqTest)) return COVID_STATUS.negative;

    return COVID_STATUS.notCleared;
};

export const isPocExpired = pocReqTest => {
    const collectedAt = safeGet(pocReqTest, 'requisition.collected_at');
    const is24HoursPassed = dayjs.utc(collectedAt).isBefore(dayjs.utc().subtract(1, 'day'));

    if (!collectedAt || is24HoursPassed) return true;

    return false;
};

export const pocCheck = pocReqTest => {
    if (isReqTestPositive(pocReqTest)) return COVID_STATUS.positive;

    if (isPocExpired(pocReqTest)) return COVID_STATUS.notCleared;

    if (isReqTestNegative(pocReqTest)) return COVID_STATUS.negative;

    return COVID_STATUS.notCleared;
};

export const reqTestCheck = (reqTest, types = []) => {
    if (types.includes(COVID_TEST_TYPES.PCR)) return pcrCheck(reqTest);
    if (types.some(type => type === COVID_TEST_TYPES.POC || type === COVID_TEST_TYPES.NAAT)) {
        return pocCheck(reqTest);
    }

    return COVID_STATUS.notCleared;
};

export const isReqTestExprired = (reqTest, type) => {
    if (type === COVID_TEST_TYPES.PCR) return isPcrExpired(reqTest);
    if (type === COVID_TEST_TYPES.POC || COVID_TEST_TYPES.NAAT) return isPocExpired(reqTest);

    return true;
};

export const getStatus = (pocReqTest, pcrReqTest) => {
    const [latestReqTest, types] = getLatestReqTest(pocReqTest, pcrReqTest);

    if (types?.includes(COVID_TEST_TYPES.PCR)) {
        return pcrCheck(pcrReqTest);
    } else if (!isPocExpired(pocReqTest)) {
        return pocCheck(pocReqTest);
    } else if (
        pcrReqTest
        && isReqTestNegative(pocReqTest)
        && !isPcrExpired(pcrReqTest)
    ) {
        return pcrCheck(pcrReqTest);
    }

    return reqTestCheck(latestReqTest, types);
};
