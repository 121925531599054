import { useCallback, useState } from 'react';
import { isFullObject } from '@flowhealth/utils';

import FlowSelect from 'components/FlowSelect';

import { US_ZIP_LENGTH, DEFAULT_COUNTRY } from 'containers/Auth/constants';

import { setInputTouched, setInputValue, getAddressRequest } from '../../store/actions';

import { ADD_PROFILE_FORM } from './constants';


export default ({
    dispatch,
    formData,
    loading,
}) => {
    const {
        tab,
        given,
        family,
        birth_date,
        gender,
        race,
        ethnicity,
        email,
        country,
        line1,
        line2,
        zip_code,
        city,
        state,
    } = formData;
    const [isVisible, setIsVisible] = useState(false);

    const isUSA = useCallback(item => {
        let country = item;

        if (isFullObject(item)) country = item.value;

        return country === DEFAULT_COUNTRY;
    }, []);

    const checkValue = useCallback(item => {
        if (isFullObject(item)) return item;

        return { value: item || '', valid: !!item };
    }, []);

    const onChange = useCallback(data => {
        dispatch(setInputValue({
            form: ADD_PROFILE_FORM,
            data,
            formData,
        }));
    }, [dispatch, formData]);

    const onFocus = useCallback((data = {}) => {
        dispatch(setInputTouched({
            form: ADD_PROFILE_FORM,
            data,
            formData,
        }));
    }, [dispatch, formData]);

    const getParams = useCallback(item => ({
        onChange,
        onFocus,
        ...checkValue(item),
    }), [checkValue, onChange, onFocus]);

    return ({
        showArrows: false,
        expandFields: true,
        description: 'Please enter your personal information to create a patient profile',
        fields: [
            {
                config: {
                    type: 'fieldsSet',
                    label: 'Basic',
                    index: 1,
                },
                ...checkValue(tab),
                fieldArray: [
                    {
                        config: {
                            type: 'text',
                            label: 'First Name',
                            id: 'given',
                            required: true,
                        },
                        ...getParams(given),
                    },
                    {
                        config: {
                            type: 'text',
                            label: 'Last Name',
                            id: 'family',
                            required: true,
                        },
                        ...getParams(family),
                    },
                    {
                        config: {
                            type: 'flowDateTime',
                            fieldType: 'date',
                            label: 'Date of Birth',
                            id: 'birth_date',
                            required: true,
                        },
                        ...getParams(birth_date),
                    },
                    {
                        config: {
                            type: 'flowSelect',
                            selectType: FlowSelect.SELECT_TYPES.valueSet,
                            label: 'Gender',
                            id: 'gender',
                            file: 'gender',
                            required: true,
                            clearable: false,
                            inputPlaceholder: ' ',
                        },
                        ...getParams(gender),
                    },
                    {
                        config: {
                            type: 'flowSelect',
                            selectType: FlowSelect.SELECT_TYPES.valueSet,
                            id: 'race',
                            label: 'Race',
                            file: 'patient-race',
                            clearable: false,
                            inputPlaceholder: ' ',
                        },
                        ...getParams(race),
                    },
                    {
                        config: {
                            type: 'flowSelect',
                            selectType: FlowSelect.SELECT_TYPES.valueSet,
                            id: 'ethnicity',
                            label: 'Ethnicity',
                            file: 'ethnicity',
                            clearable: false,
                            inputPlaceholder: ' ',
                        },
                        ...getParams(ethnicity),
                    },
                ],
            },
            {
                config: {
                    type: 'fieldsSet',
                    label: 'Email & Address',
                    index: 2,
                },
                ...checkValue(tab),
                fieldArray: [
                    {
                        config: {
                            type: 'text',
                            label: 'Email',
                            id: 'email',
                        },
                        ...getParams(email),
                        touched: email.value === '' ? false : email.touched,
                    },
                    {
                        config: {
                            label: 'Country',
                            id: 'country',
                            type: 'flowSelect',
                            selectType: FlowSelect.SELECT_TYPES.valueSet,
                            file: 'countries',
                            clearable: false,
                            required: true,
                        },
                        ...getParams(country),
                    },
                    {
                        config: {
                            label: 'ZIP Code',
                            id: 'zip_code',
                            type: 'text',
                            disabled: loading,
                            required: true,
                        },
                        ...getParams(zip_code),
                        onChange: (data = {}) => {
                            if (isUSA(country) && data.value?.length > US_ZIP_LENGTH) return null;
                            onChange(data);

                            if (isUSA(country) && data.value?.length === US_ZIP_LENGTH) {
                                dispatch(getAddressRequest({
                                    form: ADD_PROFILE_FORM,
                                    zip_code: data.value,
                                    formData,
                                }));
                            }
                        },
                    },
                    {
                        config: {
                            label: 'Street Address',
                            id: 'line1',
                            type: 'text',
                            required: true,
                        },
                        ...getParams(line1),
                        actionLabel: !isVisible ? 'Show Line 2' : 'Hide Line 2',
                        actionOnClick: () => setIsVisible(!isVisible),
                    },
                    {
                        config: {
                            label: 'Line 2',
                            id: 'line2',
                            type: 'text',
                            disabled: !line1?.value,
                        },
                        ...getParams(line2),
                        touched: line2.value === '' ? false : line2.touched,
                        notVisible: !isVisible,
                    },
                    {
                        config: {
                            label: 'City',
                            id: 'city',
                            type: 'text',
                            disabled: isUSA(country) || loading,
                            required: !isUSA(country),
                        },
                        ...getParams(city),
                    },
                    {
                        config: {
                            label: 'State',
                            id: 'state',
                            type: 'text',
                            disabled: isUSA(country) || loading,
                            required: !isUSA(country),
                        },
                        ...getParams(state),
                    },
                ],
            },
        ],
    });
};
