/* eslint-disable max-len */
import React from 'react';


const Success = () => (
    <svg viewBox="0 0 208 208">
        <circle cx="104" cy="104" r="54" stroke="#F5F7F9" strokeWidth="12" />
        <circle cx="104" cy="104" r="47.5" stroke="#E2E7EE" fill="#fff" />
        <path fillRule="evenodd" clipRule="evenodd" d="M124.796 89.878C126.284 91.3657 126.284 93.7778 124.796 95.2655L102.333 117.728C100.102 119.96 96.4837 119.96 94.2522 117.728L84.1705 107.646C82.6828 106.159 82.6828 103.747 84.1705 102.259C85.6582 100.771 88.0702 100.771 89.558 102.259L97.5857 110.287C97.9762 110.677 98.6094 110.677 98.9999 110.287L119.409 89.878C120.896 88.3903 123.308 88.3903 124.796 89.878Z" fill="#419D4E" />
    </svg>
);

export default Success;
