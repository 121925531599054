/* eslint-disable max-nested-callbacks */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { change } from 'redux-form/immutable';
import reduxFormActions from 'redux-form/lib/actions';
import withStyles from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import { isString, isFunction, memoize, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import {
    isDSTforUs,
    submitToLocalTime as sTLT,
    normalizeToLocalTime as nTLT,
    safeGet,
    isObject,
    isFullObject,
    isArray,
    isFullArray,
} from '@flowhealth/utils';
import dayjs, { isDayjs } from 'dayjs';
import { removeNBSP } from '@flowhealth/ui-components';
import { withRouter } from 'react-router-dom';

import Button from 'components/Button';
import { selectValueSet } from 'components/ValueSet';
import { getValuesRequest } from 'components/ValueSet/actions';

import {
    TIME_FORMAT_12,
    DATE_TIME_FORMAT,
    DATE_FORMAT,
    DATE_FILTER_FORMAT,
} from 'utils/constants';
import { capitalize } from 'utils/stringFormats';
import { isISO } from 'utils/isISO';

import {
    EMPTY_VALUE,
    FIELD_TYPES,
    AUTOFILL_DELAY,
    SUBMIT_FORM_DELAY,
} from './constants';
import styles from './styles';
import { selectSyncErrors } from './selector';
import FlowDate from './components/Date';
import FlowTime from './components/Time';
import TimeZone from './components/TimeZone';


const getInitialValues = props => {
    const { isUTC, normalizeToLocalTime, location, usTimeZones, fieldType } = props;
    const initial_value = safeGet(props, 'initial_value', safeGet(props, 'meta.initial'));
    let initialDate;
    let initialTime;
    let formatType;
    if (fieldType === FIELD_TYPES.date) {
        formatType = DATE_FORMAT;
    } else if (fieldType === FIELD_TYPES.time) {
        formatType = TIME_FORMAT_12;
    } else {
        formatType = DATE_TIME_FORMAT;
    }
    if (initial_value) {
        let initialValue = isUTC ? dayjs.utc(initial_value, formatType) : dayjs(initial_value);
        if (isISO(initial_value)) {
            initialValue = isUTC ? dayjs.utc(initial_value) : dayjs(initial_value);
        }
        initialDate = initialValue.format(DATE_FORMAT);
        initialTime = initialValue.format(TIME_FORMAT_12);
        if (!initialValue.isSame(initialDate) && initialValue.diff(initialDate, 'day') > 0) {
            initialDate = initialValue.add(initialValue.diff(initialDate), 'milliseconds').format(DATE_FORMAT);
        }
        if (isDayjs(initialValue)) {
            initialValue = initialValue.format();
        }
        if (normalizeToLocalTime && isFullObject(location) && isFullArray(usTimeZones)) {
            let normalizedDateTime = nTLT({
                value: initialValue,
                location,
                usTimeZones,
                hideTimeZone: true,
                format: DATE_TIME_FORMAT,
            });
            if (normalizedDateTime) {
                // eslint-disable-next-line max-depth
                if (!(normalizedDateTime instanceof dayjs)) {
                    // eslint-disable-next-line max-depth
                    if (isUTC) {
                        normalizedDateTime = dayjs.utc(normalizedDateTime);
                    } else {
                        normalizedDateTime = dayjs(removeNBSP(normalizedDateTime))
                    }
                }
                initialDate = normalizedDateTime.format(DATE_FORMAT);
                initialTime = normalizedDateTime.format(TIME_FORMAT_12);
            }
        }
    }
    return { initialDate, initialTime };
};


export class FlowDateTime extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        className: PropTypes.string,
        errorClassName: PropTypes.string,
        id: PropTypes.string.isRequired,
        fieldType: PropTypes.oneOf(Object.values(FIELD_TYPES)).isRequired,

        input: PropTypes.object, // ReduxForm shape
        meta: PropTypes.object, // ReduxForm shape
        syncErrors: PropTypes.object, // ReduxForm shape
        initial_value: PropTypes.string, // eslint-disable-line

        label: PropTypes.string,
        showLabel: PropTypes.bool,
        required: PropTypes.bool,
        isUTC: PropTypes.bool,
        yearAutoFill: PropTypes.bool,
        timeAutoFill: PropTypes.bool,
        dateAutoFill: PropTypes.bool,
        disabled: PropTypes.bool,
        isFilter: PropTypes.bool,
        invalid: PropTypes.bool,
        hasSetCurrentBtn: PropTypes.bool,

        onValidation: PropTypes.func,
        onFocus: PropTypes.func,

        submitToLocalTime: PropTypes.bool,
        normalizeToLocalTime: PropTypes.bool,
        location: PropTypes.shape({
            dst: PropTypes.bool,
            time_zone: PropTypes.string,
        }),
        checkLocation(props, propName, componentName) { // eslint-disable-line
            if (!props.submitToLocalTime && !props.normalizeToLocalTime) return null;
            if (!isObject(props.location)) {
                return new Error(`[${componentName}:${props.id}] Prop "location" of type object is required for "${props.submitToLocalTime ? 'submitToLocalTime' : 'normalizeToLocalTime'}"`); // eslint-disable-line
            }
        },
    };

    static defaultProps = {
        fieldType: FIELD_TYPES.datetime,
        meta: {},
        showLabel: true,
        required: false,
        disabled: false,
        isUTC: true,
        isFilter: false,
        hasSetCurrentBtn: false,
        yearAutoFill: true,
        timeAutoFill: true,
        dateAutoFill: true,
        submitToLocalTime: false,
        normalizeToLocalTime: false,
        invalid: false,
        noRearrange: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            inputRef: undefined,
            data: {
                [FIELD_TYPES.date]: undefined,
                [FIELD_TYPES.time]: undefined,
            },
            touched: false,
            focused: undefined,
            setCurrentDisabled: props.disabled,
            fieldError: {
                [FIELD_TYPES.date]: undefined,
                [FIELD_TYPES.time]: undefined,
            },
            fieldValid: {
                [FIELD_TYPES.date]: true,
                [FIELD_TYPES.time]: true,
            },
            current: {
                [FIELD_TYPES.date]: undefined,
                [FIELD_TYPES.time]: undefined,
            },
        };
    }

    componentDidMount() {
        const { dispatch, submitToLocalTime, usTimeZones, normalizeToLocalTime, initial_value } = this.props;
        const initialValues = getInitialValues(this.props);
        if (initial_value || initialValues.initialDate || initialValues.initialTime) {
            this.setDate(initialValues.initialDate);
            this.setTime(initialValues.initialTime);
        }
        (submitToLocalTime || normalizeToLocalTime)
        && (!isArray(usTimeZones) || (isArray(usTimeZones) && usTimeZones.length === 0))
        && dispatch(getValuesRequest({ file: 'us_time_zones' }));
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            fieldType, dispatch, id,
            meta, location, isFilter,
            syncErrors, input, required,
            timeAutoFill, dateAutoFill, disabled,
            touched: propsTouched, valid, hasSetCurrentBtn,
        } = this.props;
        const { data = {}, fieldValid, fieldError, touched, setCurrentDisabled } = this.state;
        const prevInitialValues = getInitialValues(prevProps);
        const initialValues = getInitialValues(this.props);
        const wasSetCurrentClicked = isEqual(prevState.setCurrentDisabled, setCurrentDisabled)
            && !isEqual(prevState.data, data);
        const wasLocationChanged = !isEqual(prevProps.location, location);

        if (!isEqual(prevProps?.input, input) && input?.value === '' && data) {
            data.date !== '' && this.setDate('');
            data.time !== '' && this.setTime('');
        }

        if (disabled !== prevProps.disabled) {
            this.setState({ setCurrentDisabled: disabled });
        }
        if (!disabled && (wasSetCurrentClicked || wasLocationChanged)) {
            this.setState({ setCurrentDisabled: false });
        }
        if (!isEqual(prevInitialValues, initialValues)) {
            this.setDate(initialValues.initialDate);
            this.setTime(initialValues.initialTime);
        }
        if (!isEqual(prevProps.touched, propsTouched) || !isEqual(prevProps.valid, valid)) {
            return this.setState(prevState => ({
                touched: propsTouched,
                fieldValid: { ...prevState.fieldValid, [fieldType]: valid },
                fieldError: { ...prevState.fieldError, [fieldType]: !valid && '' },
            }));
        }
        if (!isEqual(prevState.data, data)
            || (isEqual(prevState.data, data) && prevState.touched !== touched)
            || (!prevProps.location && isObject(location))
            || (isObject(prevProps.location) && isObject(location) && !isEqual(prevProps.location, location))) { // eslint-disable-line
            if (fieldType === FIELD_TYPES.datetime) {
                if ((!fieldValid[FIELD_TYPES.date] || !fieldValid[FIELD_TYPES.time])
                    && !timeAutoFill && !dateAutoFill) return;
                const value = this.getFormFieldValue();
                if ((touched || (value && isFilter))
                    && ((data[FIELD_TYPES.date] && data[FIELD_TYPES.time])
                        || (!data[FIELD_TYPES.date] && !data[FIELD_TYPES.time]))) {
                    meta.form && dispatch(change(meta.form, id, value));
                    isFunction(input.onChange) && input.onChange(value);
                }
            }
            if (fieldType === FIELD_TYPES.date) {
                const value = this.getFormFieldValue();
                if (value && !fieldValid[FIELD_TYPES.date]) return;
                meta.form && dispatch(change(meta.form, id, value));
                isFunction(input.onChange) && input.onChange(value);
            }
            if (fieldType === FIELD_TYPES.time) {
                if (!fieldValid[FIELD_TYPES.time] && !isFilter) return;
                meta.form && dispatch(change(meta.form, id, data[FIELD_TYPES.time]));
            }
        }
        if (meta.error && !hasSetCurrentBtn) return;

        const date = data[FIELD_TYPES.date] || initialValues.initialDate;
        const time = data[FIELD_TYPES.time] || initialValues.initialTime;
        let error;

        if (touched
            && fieldType === FIELD_TYPES.datetime
            && ((!date && time)
                || (!time && date))) {
            error = `Required ${date ? FIELD_TYPES.time : FIELD_TYPES.date}`;
        }
        if (required
            && ((fieldType === FIELD_TYPES.date && !date)
                || (fieldType === FIELD_TYPES.time && !time)
                || (fieldType === FIELD_TYPES.datetime && !date && !time))) {
            error = 'Required';
        }
        if (touched
            && fieldType === FIELD_TYPES.datetime
            && !required
            && !date
            && !time) {
            error = undefined;
        }

        const errors = {
            ...syncErrors,
            [id]: fieldError[FIELD_TYPES.date] || fieldError[FIELD_TYPES.time] || error,
        };

        const shouldUpdateSyncErrors = meta.form
            && (errors[id] || (hasSetCurrentBtn && setCurrentDisabled))
            && !isEqual(errors[id], safeGet(syncErrors, id));

        if (shouldUpdateSyncErrors) {
            dispatch(reduxFormActions.updateSyncErrors(meta.form, errors));
        }
    }

    componentWillUnmount() {
        this.dateAutoFillTimeout && clearTimeout(this.dateAutoFillTimeout);
        this.timeAutoFillTimeout && clearTimeout(this.timeAutoFillTimeout);
        this.submitFormTimeout && clearTimeout(this.submitFormTimeout);
    }

    static FIELD_TYPES = FIELD_TYPES;

    getInputRef = inputRef => this.setState({ inputRef });

    focusInput = () => {
        const { disabled } = this.props;
        const { inputRef } = this.state;
        !disabled && inputRef && inputRef.focus();
    };

    blurInput = () => {
        const { disabled } = this.props;
        const { inputRef } = this.state;
        !disabled && inputRef && inputRef.blur();
    };

    setDate = date => this.setState(prevState => ({ ...prevState, data: { ...prevState.data, date } }));

    setTime = time => this.setState(prevState => ({ ...prevState, data: { ...prevState.data, time } }));

    setCurrentTime = currentTime => this.setState(prevState => ({
        ...prevState,
        data: {
            ...prevState.data,
            [FIELD_TYPES.time]: currentTime,
        },
        current: {
            ...prevState.current,
            [FIELD_TYPES.time]: currentTime,
        },
    }));

    setCurrentDate = currentDate => this.setState(prevState => ({
        ...prevState,
        data: {
            ...prevState.data,
            [FIELD_TYPES.date]: currentDate,
        },
        current: {
            ...prevState.current,
            [FIELD_TYPES.date]: currentDate,
        },
    }));

    setCurrentDateTime = (currentDate, currentTime) => this.setState(prevState => ({
        ...prevState,
        data: {
            ...prevState.data,
            [FIELD_TYPES.date]: currentDate,
            [FIELD_TYPES.time]: currentTime,
        },
        current: {
            ...prevState.current,
            [FIELD_TYPES.date]: currentDate,
            [FIELD_TYPES.time]: currentTime,
        },
        setCurrentDisabled: true,
        touched: true,
    }));

    handleFocus = memoize(fieldType => () => {
        const { isFilter, onFocus } = this.props;
        if (isFilter) return;
        this.setState({ touched: false, focused: fieldType });

        if (isFunction(onFocus)) onFocus();
    });

    handleBlur = memoize(fType => (newValue, event, options) => {
        const { input = {}, meta, id, dispatch, timeAutoFill, dateAutoFill, fieldType, isFilter } = this.props;
        const { value } = input;
        if (isFilter) return;
        this.setState(prevState => ({ ...prevState, data: { ...prevState.data, [fType]: newValue } }), () => {
            this.setState({ touched: true, focused: undefined }, () => {
                if (fieldType !== FIELD_TYPES.datetime) return;
                if (dateAutoFill) {
                    this.dateAutoFillTimeout = setTimeout(() => {
                        const { focused, data, current, fieldError } = this.state;
                        const currentDate = dayjs().format(DATE_FORMAT);
                        if (!data[FIELD_TYPES.time]
                            || (current[FIELD_TYPES.date] && !newValue)
                            || fieldError[FIELD_TYPES.date]) return;
                        if ((fType === FIELD_TYPES.time && focused !== FIELD_TYPES.date && !data[FIELD_TYPES.date])
                            || (fType === FIELD_TYPES.date && !data[FIELD_TYPES.date])) {
                            this.setCurrentDate(currentDate);
                        }
                    }, AUTOFILL_DELAY);
                }
                if (timeAutoFill) {
                    this.timeAutoFillTimeout = setTimeout(() => {
                        const { focused, data, current } = this.state;
                        const currentTime = dayjs().format(TIME_FORMAT_12);
                        if (!data[FIELD_TYPES.date]
                            || (current[FIELD_TYPES.time] && !newValue)
                            || (focused === FIELD_TYPES.date && !newValue)) return;
                        if ((fType === FIELD_TYPES.date && focused !== FIELD_TYPES.time && !data[FIELD_TYPES.time])
                            || (fType === FIELD_TYPES.time && !data[FIELD_TYPES.time])) {
                            this.setCurrentTime(currentTime);
                        }
                    }, AUTOFILL_DELAY);
                }
            });
        });
        meta.form && dispatch(change(meta.form, id, value, true));
        options.triggerFormSubmit && this.submitForm();
    });

    handleError = (error, fieldType) => this.setState(prevState => ({
        ...prevState,
        fieldError: { ...prevState.fieldError, [fieldType]: error },
    }));

    handleValidation = (isValid, fieldType) => this.setState(prevState => ({
        ...prevState,
        fieldValid: { ...prevState.fieldValid, [fieldType]: isValid },
    }), () => {
        const { onValidation } = this.props;
        if (typeof onValidation === 'function') {
            onValidation(isValid, fieldType);
        }
    });

    submitForm = () => {
        const { dispatch, meta = {} } = this.props;
        this.submitFormTimeout = setTimeout(
            () => meta.form && dispatch(reduxFormActions.submit(meta.form)),
            SUBMIT_FORM_DELAY,
        );
    };

    getFormFieldValue() {
        const { fieldType, isUTC, submitToLocalTime, location, usTimeZones, format, isFilter } = this.props;
        const { data: { date, time } } = this.state;
        let result;
        if (fieldType === FIELD_TYPES.datetime) {
            if (!date || !time) return '';
            if ((time && !(dayjs(time, TIME_FORMAT_12).isValid()))) return '';
            result = isUTC
                ? dayjs.utc(`${date} ${time}`, DATE_TIME_FORMAT, true).toISOString()
                : dayjs(`${date} ${time}`).toISOString();
        }
        if (fieldType === FIELD_TYPES.date) {
            if (!date) return '';
            try {
                result = isUTC
                    ? dayjs.utc(date, DATE_FORMAT).add(12, 'hour').toISOString()
                    : dayjs(date).toISOString();
            } catch (e) {
                this.handleError(e, fieldType);
            }
            if (isFilter) result = dayjs.utc(result).format(DATE_FILTER_FORMAT);
        }
        if (submitToLocalTime && location) {
            const localDateTime = sTLT({
                value: result,
                usTimeZones,
                isISO: true,
                format,
                location,
            });
            result = localDateTime || result;
        }
        if (isFilter && isString(result) && result.includes('Invalid')) result = undefined;
        return result;
    }

    get label() {
        const { classes = {}, label, showLabel, required } = this.props;
        if (!showLabel) return null;
        return (
            <div
                className={cn('label', classes.label)}
                onClick={this.focusInput}
            >
                {label}
                {required && <span className="required">*</span>}
            </div>
        );
    }

    getCurInitValue = (type = '') => {
        const { hasSetCurrentBtn, disabled } = this.props;
        const { current, data, setCurrentDisabled } = this.state;

        if (!hasSetCurrentBtn) return undefined;
        if (setCurrentDisabled && !disabled) return current[FIELD_TYPES[type]];
        if (data[FIELD_TYPES[type]]) return data[FIELD_TYPES[type]];

        return EMPTY_VALUE;
    };

    getDateTimeInitValue = (type = '') => {
        const { dateAutoFill } = this.props;
        const { current } = this.state;

        return this.getCurInitValue(type)
            || getInitialValues(this.props)[`initial${capitalize(type)}`]
            || (dateAutoFill && current[FIELD_TYPES[type]]);
    };

    get submitFailed() {
        const { meta } = this.props;
        return safeGet(meta, 'submitFailed', false);
    }

    get error() {
        const { id, syncErrors = {}, invalid } = this.props;
        const metaError = safeGet(this.props, 'meta.error');
        const dateValue = safeGet(this.state, `data.${FIELD_TYPES.date}`);
        const dateError = safeGet(this.state, `fieldError.${FIELD_TYPES.date}`);
        const timeValue = safeGet(this.state, `data.${FIELD_TYPES.time}`);
        const timeError = safeGet(this.state, `fieldError.${FIELD_TYPES.time}`);
        return (
            invalid
            || (dateValue && dateError)
            || (timeValue && timeError)
            || safeGet(syncErrors, id)
            || metaError
        );
    }

    get errorContainer() {
        const { classes = {}, errorClassName } = this.props;
        if (!(this.touched || this.submitFailed) || !this.error) return null;
        return <div className={cn(classes.error, errorClassName)}>{this.error}</div>;
    }

    get touched() {
        return safeGet(this.state, 'touched', safeGet(this.props, 'meta.error', false));
    }

    getCurrentDateTime = () => {
        const { location, usTimeZones } = this.props;
        const usTimezoneObj = Array.isArray(usTimeZones)
            && usTimeZones.find(obj => obj && obj.code === location?.time_zone);

        let offsetInMinutes = 60 * usTimezoneObj?.offset;
        if (location?.dst && isDSTforUs(dayjs().utc())) {
            offsetInMinutes += 60;
        }

        return location?.time_zone ? dayjs().utc().utcOffset(offsetInMinutes) : dayjs();
    };

    setCurrentAction = () => {
        const current = this.getCurrentDateTime();

        this.setCurrentDateTime(
            current.format(DATE_FORMAT),
            current.format(TIME_FORMAT_12),
        );
        this.focusInput();
        setTimeout(() => this.blurInput(), 1);
    };

    get setCurrentButton() {
        const { classes } = this.props;
        const { setCurrentDisabled } = this.state;

        return (
            <Button
                className={classes.setCurrent}
                color={Button.BUTTON_COLORS.transparentNaviBlue300}
                size={Button.BUTTON_SIZES.small}
                disabled={setCurrentDisabled}
                onClick={this.setCurrentAction}
            >
                Set to Current
            </Button>
        );
    }

    get content() {
        const {
            fieldType, classes, required,
            disabled, isValidDate, preview,
            yearAutoFill,
            location,
            noRearrange,
        } = this.props;
        const { data } = this.state;
        const showTimeZone = Object.keys(this.props).includes('location');
        switch (fieldType) {
        case FIELD_TYPES.date: {
            return (
                <FlowDate
                    getInputRef={this.getInputRef}
                    onValidDate={this.handleValidation}
                    onNormalize={this.setDate}
                    initialValue={getInitialValues(this.props).initialDate}
                    onFocus={this.handleFocus(FIELD_TYPES.date)}
                    onBlur={this.handleBlur(FIELD_TYPES.date)}
                    onError={this.handleError}
                    isValidDate={isValidDate}
                    error={(this.touched || this.submitFailed) && this.error}
                    preview={preview}
                    required={required}
                    yearAutoFill={yearAutoFill}
                    disabled={disabled}
                    noRearrange={noRearrange}
                />
            );
        }
        case FIELD_TYPES.time: {
            return (
                <FlowTime
                    getInputRef={this.getInputRef}
                    onValidTime={this.handleValidation}
                    onNormalize={this.setTime}
                    initialValue={getInitialValues(this.props).initialTime}
                    onFocus={this.handleFocus(FIELD_TYPES.time)}
                    onBlur={this.handleBlur(FIELD_TYPES.time)}
                    onError={this.handleError}
                    error={(this.touched || this.submitFailed) && this.error}
                    preview={preview}
                    required={required}
                    disabled={disabled}
                />
            );
        }
        case FIELD_TYPES.datetime: {
            return (
                <>
                    <div className={cn('content', classes.content)}>
                        <FlowDate
                            getInputRef={this.getInputRef}
                            onValidDate={this.handleValidation}
                            onNormalize={this.setDate}
                            initialValue={this.getDateTimeInitValue(FIELD_TYPES.date)}
                            onFocus={this.handleFocus(FIELD_TYPES.date)}
                            onBlur={this.handleBlur(FIELD_TYPES.date)}
                            onError={this.handleError}
                            isValidDate={isValidDate}
                            showError={!((this.touched || this.submitFailed) && this.error)}
                            error={(this.touched || this.submitFailed) && this.error}
                            preview={preview}
                            required={required}
                            yearAutoFill={yearAutoFill}
                            disabled={disabled}
                            noRearrange={noRearrange}
                        />
                        <FlowTime
                            onValidTime={this.handleValidation}
                            onNormalize={this.setTime}
                            initialValue={this.getDateTimeInitValue(FIELD_TYPES.time)}
                            onFocus={this.handleFocus(FIELD_TYPES.time)}
                            onBlur={this.handleBlur(FIELD_TYPES.time)}
                            onError={this.handleError}
                            showError={!((this.touched || this.submitFailed) && this.error)}
                            error={(this.touched || this.submitFailed) && this.error}
                            preview={preview}
                            required={required}
                            disabled={disabled}
                        />
                        {showTimeZone && <TimeZone location={location} date={data.date} />}
                    </div>
                    {this.errorContainer}
                </>
            );
        }
        default: return null;
        }
    }

    render() {
        const { classes = {}, hasSetCurrentBtn, className } = this.props;

        return (
            <div className={cn('root', classes.root, className)}>
                {hasSetCurrentBtn ? (
                    <div className={classes.container}>
                        {this.label}
                        {this.setCurrentButton}
                    </div>
                ) : this.label}
                {this.content}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    usTimeZones: selectValueSet('us_time_zones'),
    syncErrors: selectSyncErrors,
});

const withConnect = connect(mapStateToProps);

export default compose(
    withStyles(styles),
    withRouter,
    withConnect,
)(FlowDateTime);
