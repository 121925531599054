import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import { bluredBG } from './styles';


const styles = ({ palette = {} } = {}) => ({
    root: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        background: palette.white.main,
        overflow: 'hidden',
        zIndex: '1000',
    },
    inner: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '26px',
    },
    head: {
        marginBottom: '46px !important',
    },
    label: {
        width: '103px',
        height: '8px',
        borderRadius: '4px',
        display: 'flex',
        marginBottom: '15px',
    },
    field: {
        width: '100%',
        height: '32px',
        borderRadius: '4px',
        display: 'flex',
        marginBottom: '22px',
    },
    ...bluredBG,
});


class LogInLoader extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        const { classes, className } = this.props;
        return (
            <div className={cn(className, classes.root)}>
                <div className={classes.inner}>
                    <div className={cn(classes.animate, classes.label, classes.head)} />
                    <div className={cn(classes.animate, classes.label)} />
                    <div className={cn(classes.animate, classes.field)} />
                    <div className={cn(classes.animate, classes.label)} />
                    <div className={cn(classes.animate, classes.field)} />
                </div>
            </div>
        );
    }
}


export default withStyles(styles)(LogInLoader);
