import { createSelector } from 'reselect';
import { safeGet } from '@flowhealth/utils';

import { GRAPH_FILES_KEY } from './constants';

const selectGraphFilesState = state => state && state[GRAPH_FILES_KEY];

export const makeSelectGraphFiles = createSelector(
    selectGraphFilesState,
    graphFiles => (graphFiles ? graphFiles.toJS() : null),
);

export const makeSelectFilesMeta = createSelector(
    makeSelectGraphFiles,
    data => (data ? data.meta : null),
);

export const makeSelectFilesLoading = createSelector(
    makeSelectGraphFiles,
    data => (data ? data.loading : null),
);

export const makeSelectFilesError = createSelector(
    makeSelectGraphFiles,
    data => (data ? data.error : null),
);

export const selectZipState = createSelector(
    selectGraphFilesState,
    state => state && state.get && state.get('zip'),
);

export const selectZipProgress = createSelector(
    selectZipState,
    state => safeGet(state, 'progress'),
);

export const selectZipLoading = createSelector(
    selectZipState,
    state => safeGet(state, 'loading', false),
);
