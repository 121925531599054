import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import { bluredBG } from './styles';


const styles = ({ palette = {} } = {}) => ({
    root: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        background: palette.gray[85],
        overflow: 'hidden',
        zIndex: '1000',
    },
    inner: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: `0 6px 10px 0 ${palette.gray['05']}`,
        borderBottom: `solid 1px ${palette.ash[400]}`,
        padding: '0 32px 15px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 32px',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        padding: '15px 0',
        width: '100%',
        borderBottom: `solid 1px ${palette.ash[400]}`,
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    field: {
        width: '50%',
        height: '12px',
        borderRadius: '4px',
        display: 'flex',
        margin: 5,
    },
    fieldHeader: {
        width: '30%',
    },
    filedSmall: {
        width: '25%',
    },
    fieldHeaderSmall: {
        width: '17%',
    },
    ...bluredBG,
});


class RunLoader extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        rows: PropTypes.number,
    };

    static defaultProps = {
        title: true,
        action: true,

        rows: 4,
    };

    render() {
        const { classes, className, rows } = this.props;
        const rowsArray = (new Array(rows)).fill(0);

        return (
            <div className={cn(className, classes.root)}>
                <div className={classes.inner}>
                    <div className={classes.header}>
                        <div className={cn(classes.animate, classes.field, classes.fieldHeader)} />
                        <div className={cn(classes.animate, classes.field, classes.fieldHeaderSmall)} />
                    </div>
                    <div className={classes.content}>
                        {rowsArray.map((_, index) => (
                            <div key={index} className={classes.row}>
                                <div className={classes.column}>
                                    <div className={cn(classes.animate, classes.field)} />
                                    <div className={cn(classes.animate, classes.field, classes.filedSmall)} />
                                </div>
                                <div className={classes.column}>
                                    <div className={cn(classes.animate, classes.field)} />
                                </div>
                                <div className={classes.column} />
                                <div className={classes.column} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}


export default withStyles(styles)(RunLoader);
