import React, { memo, useMemo, useCallback, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import cn from 'classnames';
import { SpinLoader } from '@flowhealth/ui-components';
import { formatAliasName, isFullArray, isFullObject, safeGet } from '@flowhealth/utils';
import { sortBy } from 'lodash';

import Button from 'components/Button';
import FlowIcon from 'components/FlowIcon';
import { ICONS_TYPES } from 'components/FlowIcon/constants';

import { makeSelectUserData } from 'containers/User/selector';
import AdaptivePopup from 'containers/PatientPortal/components/AdaptivePopup';
import AdaptiveContainer from 'containers/PatientPortal/components/AdaptiveContainer';

import select from 'utils/select';

import { PATIENT_PROFILES_KEY } from '../../store/constants';
import { addProfileRequest } from '../../store/actions';

import { useStyles } from './styles';


const SelectPractice = ({
    dispatch,
    mediaClassName,
    onSuccess,
    onShowAddProfile,
    userData = {},
    newProfile,
    className,
    isLoading,
}) => {
    const classes = useStyles();
    const [selectedPractice, setSelectedPractice] = useState({});
    const patients = safeGet(userData, 'patients.results', []);

    const handleClickNextBtn = () => {
        dispatch(addProfileRequest({
            patient: {
                ...newProfile,
                practice: selectedPractice.id,
            },
            onSuccess,
            updatePersons: true,
        }));
    };

    const actionBtn = (
        <Button
            size="large"
            onClick={handleClickNextBtn}
            fullWidth
            noUpperCase
            disabled={!isFullObject(selectedPractice) || isLoading}
        >
            Done
        </Button>
    );

    const getCheckbox = useCallback(id => {
        if (!isFullObject(selectedPractice) || selectedPractice.id !== id) return;
        return (
            <div className={classes.checkboxContainer}>
                <div className={classes.checkbox}>
                    <FlowIcon
                        className={classes.checkmark}
                        size={24}
                        type={ICONS_TYPES.checkmark}
                    />
                </div>
            </div>
        );
    }, [classes, selectedPractice]);

    const getPractice = useCallback(practice => (
        <div
            className={classes.practice}
            onClick={() => setSelectedPractice(practice)}
            key={practice?.id}
        >
            <div className={classes.practiceInfo}>
                {formatAliasName(practice)}
            </div>
            {getCheckbox(practice?.id)}
        </div>
    ), [classes, getCheckbox]);

    const getPractices = useMemo(() => {
        if (!isFullArray(patients)) return;
        const practices = patients.reduce((acc, patient) => {
            if (!acc.find(p => p.id === patient.practice?.id)) {
                return [...acc, patient.practice];
            }
            return acc;
        }, []);
        const sortedPractices = sortBy(practices, practice => practice.name);

        return sortedPractices.map(practice => getPractice(practice));
    }, [getPractice, patients]);

    if (!patients && isLoading) return <SpinLoader className={classes.loading} />;

    // if (!patients || !newProfile) return <Redirect to={PATIENT_PROFILES_PATH} />;

    return (
        <AdaptivePopup
            title="Select Your Practice"
            hasBackIcon
            onBackIconClick={onShowAddProfile}
        >
            <AdaptiveContainer mediaClassName={mediaClassName} className={classes.container} actions={actionBtn}>
                <div className={cn(classes.root, className)}>
                    {getPractices}
                </div>
                {isLoading && <SpinLoader className={classes.loading} />}
            </AdaptiveContainer>
        </AdaptivePopup>
    );
};

const mapStateToProps = createStructuredSelector({
    userData: makeSelectUserData(),
    isLoading: select(PATIENT_PROFILES_KEY, 'loading'),
    newProfile: select(PATIENT_PROFILES_KEY, 'newProfile'),
});

const withConnect = connect(mapStateToProps);

export default compose(
    withConnect,
    memo,
)(SelectPractice);
