import { call, put, takeEvery, all, select, takeLatest, fork, cancel, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { isEmpty, isString, flatten, isFunction } from 'lodash';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import API from '@flowhealth/api';
import { makeRequest } from '@flowhealth/api/src/http/request';
import {
    safeGet,
    formatFileName,
    isArray,
    isFullArray,
    isObject,
} from '@flowhealth/utils';

import { PrintManager } from 'containers/App';

import saveFile from 'utils/saveFile';
import isUrl from 'utils/isUrl';
import { printBarcodes } from 'utils/printBarcodes';
import { getCurrentDate } from 'utils/datetime';

import * as ACTION_TYPES from './constants';
import * as ACTIONS from './actions';
import { makeSelectFilesMeta } from './selector';
import { getFileNameFactory, hasNameExtension } from './utils';


export function* getMeta() {
    return yield select(state => makeSelectFilesMeta(state));
}

export function* fileGetUrl({ payload: { params, isImage, file } }) {
    try {
        let response;
        if (isImage) {
            response = yield call(API.getInstance().newImage, params);
        } else {
            response = yield call(API.getInstance().newFile, params);
        }

        const { id, upload_url } = response;
        yield put(ACTIONS.filesGetFileUrlSuccess({ id, upload_url, file }));
    } catch (error) {
        yield put(ACTIONS.filesGetFileUrlFailure({ error }));
    }
}

export function* fileUpload({ payload: { id, upload_url, file } }) {
    try {
        yield call(API.getInstance().uploadFile, upload_url, file);
        yield put(ACTIONS.filesUploadFileSuccess({ id }));
    } catch (error) {
        yield put(ACTIONS.filesUploadFileFailure({ error }));
    }
}

export function* fileAddToEdge({ payload: { id } }) {
    try {
        const { graphId, edgeName } = yield getMeta();
        yield call(API.getInstance().addGraphToEdge, graphId, edgeName, id);
        yield put(ACTIONS.filesAddFileSuccess({ id }));
    } catch (error) {
        yield put(ACTIONS.filesAddFileFailure({ error }));
    }
}

export function* fileGet({ payload = {} } = {}) {
    try {
        const { id, base64, accession, name, open, save, isNewWindow = true } = payload;
        let newWindow;
        if (open && isNewWindow) newWindow = window.open('', '_blank');
        // need delay after opening new blank window, otherwise popup is blocked by browser
        // file loading time is enough

        const file = id ? yield call(API.getInstance().getGraph, id) : base64;
        const fileName = `Barcode_${accession}`;
        yield put(ACTIONS.filesGetFileSuccess({ ...payload, file }));

        if (open) {
            if (isNewWindow && newWindow) newWindow.location.href = file.url || base64;
            else window.location.href = file.url || base64;
        }
        if (save) yield saveFile(file.url || base64, formatFileName(name || file.title || fileName));
    } catch (error) {
        yield put(ACTIONS.filesGetFileFailure({ ...payload, error }));
    }
}

function* filesGetPrintImages({ payload = {} } = {}) {
    try {
        const { imgs = [], base64, duplicateNum = 1 } = payload;

        if (!isEmpty(imgs)) {
            const response = yield all(
                imgs
                    .map(img => safeGet(img, 'id'))
                    .filter(isString)
                    .map(imgId => call(API.getInstance().getGraph, imgId)),
            );
            const urls = response.map(img => safeGet(img, 'url')).filter(isString);

            printBarcodes(PrintManager, urls, duplicateNum);
        } else {
            printBarcodes(PrintManager, base64, duplicateNum);
        }
        yield put(ACTIONS.filesGetPrintImagesSuccess(payload));
    } catch (error) {
        yield put(ACTIONS.filesGetPrintImagesFailure({ ...payload, error }));
    }
}

function* getGraphFileZip({ graphFile, mimeTypes, jsZip, getFileName, onSuccess }) {
    for (let i = 0; i <= ACTION_TYPES.REQUESTS_ATTEMPTS_COUNT; i++) {
        try {
            const { mime_type, url, title, id } = graphFile;
            if (isArray(mimeTypes) && !isEmpty(mimeTypes) && !mimeTypes.includes(mime_type)) {
                yield put(ACTIONS.filesZipIncrementFailed({ graphFileId: id }));
                return;
            }
            const file = yield call(makeRequest, {
                url,
                method: 'GET',
                responseType: 'blob',
            });
            jsZip.file(getFileName({
                name: formatFileName(title),
                mimeType: mime_type,
            }), file);
            yield put(ACTIONS.filesZipIncrementReady());

            if (isFunction(onSuccess)) {
                yield put(onSuccess());
            }

            break;
        } catch (e) {
            if (i < ACTION_TYPES.REQUESTS_ATTEMPTS_COUNT) {
                yield call(API.getInstance().getGraph, graphFile.id);
                yield delay(ACTION_TYPES.REQUESTS_ATTEMPTS_DELAY);
            } else yield put(ACTIONS.filesZipIncrementFailed({ graphFileId: graphFile.id }));
        }
    }
}

function* getUrlFileZip({ url, mimeTypes, jsZip, getFileName }) {
    try {
        if (!isArray(mimeTypes) || isEmpty(mimeTypes)) {
            yield put(ACTIONS.filesZipIncrementFailed());
            return;
        }
        const file = yield call(makeRequest, {
            url,
            method: 'GET',
            responseType: 'blob',
        });
        jsZip.file(getFileName({ mimeType: mimeTypes[0] }), file);
        yield put(ACTIONS.filesZipIncrementReady());
    } catch (e) {
        yield put(ACTIONS.filesZipIncrementFailed());
    }
}

function* downloadZipFile({ payload = {} } = {}) {
    try {
        const { jsZip, zipName = `Archive ${getCurrentDate()}.zip` } = payload;
        const zipBlob = yield jsZip.generateAsync({ type: 'blob' });

        yield saveAs(
            zipBlob,
            hasNameExtension(zipName) ? formatFileName(zipName) : formatFileName(`${zipName}.zip`),
        );
        yield put(ACTIONS.filesDownloadZipSuccess(payload));
    } catch (error) {
        yield put(ACTIONS.filesDownloadZipFailure({ error }));
    }
}

function splitFileDataByFormat({ data, jsZip, mimeTypes }) {
    const jsZipFile = jsZip || new JSZip();
    const getFileName = getFileNameFactory();
    const urls = [];
    const graphFiles = [];

    for (const item of data) {
        if (isObject(item)) {
            // eslint-disable-next-line max-depth
            if (item?.url) {
                graphFiles.push(item);
            } else if (!isFullArray(mimeTypes) || mimeTypes.includes(item.type)) {
                jsZipFile.file(getFileName({
                    name: formatFileName(item.name),
                    mimeType: item.type,
                }), item);
                continue;
            }
        }
        isUrl(item) && urls.push(item);
    }

    return { graphFiles, urls, jsZipFile };
}

function* addFilesToZip({ payload = {} } = {}) {
    try {
        const { data, mimeTypes, jsZip, onSuccess, onLoadStep } = payload;
        const getFileName = getFileNameFactory();
        const { graphFiles, urls } = splitFileDataByFormat({ data, jsZip, mimeTypes });

        yield all(flatten([
            graphFiles.map(graphFile => call(getGraphFileZip, {
                graphFile, mimeTypes, jsZip, getFileName, onSuccess: onLoadStep,
            })),
            urls.map(url => call(getUrlFileZip, { url, mimeTypes, jsZip, getFileName })),
        ]));
        yield put(ACTIONS.filesAddToZipSuccess(payload));

        if (isFunction(onSuccess)) {
            yield put(onSuccess());
        }
    } catch (error) {
        yield put(ACTIONS.filesAddToZipFailure({ error }));
    }
}

function* filesGetZip({ payload = {} } = {}) {
    try {
        const { data, mimeTypes, zipName = `Archive ${getCurrentDate()}.zip` } = payload;
        const getFileName = getFileNameFactory();
        const { graphFiles, urls, jsZip } = splitFileDataByFormat({ data, mimeTypes });

        yield all(flatten([
            graphFiles.map(graphFile => call(getGraphFileZip, { graphFile, mimeTypes, jsZip, getFileName })),
            urls.map(url => call(getUrlFileZip, { url, mimeTypes, jsZip, getFileName })),
        ]));

        const zipBlob = yield jsZip.generateAsync({ type: 'blob' });
        yield saveAs(
            zipBlob,
            hasNameExtension(zipName) ? formatFileName(zipName) : formatFileName(`${zipName}.zip`),
        );
        yield put(ACTIONS.filesGetZipSuccess(payload));
    } catch (error) {
        yield put(ACTIONS.filesGetZipFailure({ ...payload, error }));
    }
}

function* filesGetZipMain(action) {
    const task = yield fork(filesGetZip, action);
    yield take(ACTION_TYPES.FILES_GET_ZIP_CANCEL);
    yield cancel(task);
}

function* addFilesToZipMain(action) {
    const task = yield fork(addFilesToZip, action);
    yield take(ACTION_TYPES.FILES_ADD_TO_ZIP_CANCEL);
    yield cancel(task);
}


function* Saga() {
    yield all([
        takeEvery(ACTION_TYPES.FILES_GET_FILE_URL_REQUEST, fileGetUrl),
        takeEvery(ACTION_TYPES.FILES_UPLOAD_FILE_REQUEST, fileUpload),
        takeEvery(ACTION_TYPES.FILES_ADD_FILE_REQUEST, fileAddToEdge),
        takeEvery(ACTION_TYPES.FILES_GET_FILE_REQUEST, fileGet),
        takeEvery(ACTION_TYPES.FILES_GET_PRINT_IMAGES_REQUEST, filesGetPrintImages),
        takeLatest(ACTION_TYPES.FILES_GET_ZIP_REQUEST, filesGetZipMain),

        takeEvery(ACTION_TYPES.FILES_GET_FILE_URL_SUCCESS, fileUpload),
        takeEvery(ACTION_TYPES.FILES_UPLOAD_FILE_SUCCESS, fileAddToEdge),

        takeEvery(ACTION_TYPES.FILES_ADD_TO_ZIP_REQUEST, addFilesToZipMain),
        takeEvery(ACTION_TYPES.FILES_DOWNLOAD_ZIP, downloadZipFile),
    ]);
}

export default Saga;
