import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { head, isString } from 'lodash';
import dayjs from 'dayjs';
import { safeGet } from '@flowhealth/utils';
import { SpinLoader } from '@flowhealth/ui-components';

import Button from 'components/Button';

import { makeSelectUserData } from 'containers/User/selector';
import AdaptivePopup from 'containers/PatientPortal/components/AdaptivePopup';
import AdaptiveContainer from 'containers/PatientPortal/components/AdaptiveContainer';
import { DEFAULT_COUNTRY } from 'containers/Auth/constants';
import fetchValues from 'containers/Auth/utils/fetchValues';
import Wrapper from 'containers/Auth/components/Wrapper';
import Form from 'containers/Auth/components/Form';

import select from 'utils/select';
import { isInvalid } from 'utils/isInvalid';

import { PATIENT_PROFILES_KEY } from '../../store/constants';
import { resetState, addProfileRequest, setField } from '../../store/actions';

import useFields from './useFields';
import { useStyles } from './styles';
import { ADD_PROFILE_FORM } from './constants';


const AddProfile = ({
    mediaClassName,
    dispatch,
    formData = {},
    isLoading,
    userData,
    onBack,
    onSuccess,
    onShowSelectPractice,
    hasBackIcon = true,
}) => {
    const classes = useStyles();
    const patients = safeGet(userData, 'patients.results', []);

    const onBackIconClick = () => {
        dispatch(resetState({ from: ADD_PROFILE_FORM }));
        onBack();
    };

    const handleSubmit = () => {
        const params = fetchValues(formData);
        const patient = {
            object_type: 'patient',
            name: {
                given: params.given,
                family: params.family,
                use: 'official',
            },
            birth_date: dayjs.utc(params.birth_date).add(12, 'hour').toISOString(),
            gender: head(params.gender)?.code,
            address: {
                country: head(params.country)?.code || DEFAULT_COUNTRY,
                city: params.city,
                state: params.state,
                postal_code: params.zip_code,
                use: 'work',
            },
        };
        const firstPatientPracticeId = safeGet(patients, '[0].practice.id', '');
        // eslint-disable-next-line max-len
        const isEveryPatientSamePractice = patients.every((patient = {}) => patient.practice.id === firstPatientPracticeId);

        if (params.email) {
            patient.telecom = [{
                system: 'email',
                value: params.email,
                use: 'work',
            }];
        }
        if (params.race) {
            const value = head(params.race)?.code;
            patient.race = isString(value) ? value : null;
        }
        if (params.ethnicity) {
            const value = head(params.ethnicity)?.code;
            patient.ethnicity = isString(value) ? value : null;
        }

        const line = [];
        if (params.line1) line.push(params.line1);
        if (params.line1 && params.line2) line.push(params.line2);
        if (line.length) patient.address.line = line;

        if (patients.length === 1 || isEveryPatientSamePractice) {
            dispatch(addProfileRequest({
                patient: {
                    ...patient,
                    practice: firstPatientPracticeId,
                },
                onSuccess,
            }));
        } else {
            dispatch(setField({
                field: 'newProfile',
                data: patient,
            }));
            onShowSelectPractice();
        }
    };

    const config = useFields({
        dispatch,
        formData,
        isLoading,
    });

    const isSubmitDisabled = isInvalid(config) || isLoading;

    const actionBtn = (
        <Button
            size="large"
            onClick={handleSubmit}
            fullWidth
            noUpperCase
            disabled={isSubmitDisabled}
            loading={isLoading}
        >
            Confirm
        </Button>
    );

    if (!patients && isLoading) return <SpinLoader className={classes.loading} />;

    if (!patients) return null;

    return (
        <AdaptivePopup
            title="Patient Demographics"
            hasBackIcon={hasBackIcon}
            {...{ onBackIconClick }}
        >
            <AdaptiveContainer mediaClassName={mediaClassName} actions={actionBtn}>
                <Wrapper className={classes.wrapper}>
                    <Form
                        className={classes.form}
                        config={config}
                        showLogo={false}
                        onSubmit={!isSubmitDisabled && handleSubmit}
                    />
                </Wrapper>
            </AdaptiveContainer>
        </AdaptivePopup>
    );
};

const mapStateToProps = createStructuredSelector({
    formData: select(PATIENT_PROFILES_KEY, ADD_PROFILE_FORM),
    isLoading: select(PATIENT_PROFILES_KEY, 'loading'),
    userData: makeSelectUserData(),
});

const withConnect = connect(mapStateToProps);

export default withConnect(AddProfile);
