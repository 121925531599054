/* eslint-disable max-len */
import React from 'react';


const Arrow = () => (
    <svg viewBox="0 0 12 7">
        <path d="M1 1L6 6L11 1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default Arrow;
