import React from 'react';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import FlowIcon from 'components/FlowIcon';
import { ICONS_TYPES } from 'components/FlowIcon/constants';


const styles = ({ palette = {} } = {}) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    circle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 28,
        height: 28,
        margin: '0 12px 0 0',
        padding: 4,
        borderRadius: 73.7,
        border: `solid 1px ${palette.ash.main}`,

        fontSize: 12,
        fontWeight: 600,
        color: palette.coal.main,
        letterSpacing: -0.7,
    },
    filled: {
        background: palette.naviBlue.dark,
        color: palette.white.main,
    },
    label: {
        fontSize: 18,
        fontWeight: 600,
        color: palette.coal.main,
    },
    asterisk: { color: palette.red.main },
});

function Milestone({ classes, index, label, required = false, filled = true, checked = false }) {
    return (
        <div className={classes.root}>
            <span className={cn(classes.circle, { [classes.filled]: filled })}>
                {checked ? (
                    <FlowIcon type={ICONS_TYPES.done} size={20} />
                ) : index}
            </span>
            <span className={classes.label}>{label}</span>
            {required && (<span className={classes.asterisk}>*</span>)}
        </div>
    );
}

export default withStyles(styles)(Milestone);
