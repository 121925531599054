/* eslint-disable max-len */
import React from 'react';

const ImgCount = () => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M29 7H28V8H29V7ZM24.998 22C25.55 22 26 21.469 26 20.917V10.917C26 10.365 25.55 10 24.998 10H14.998C14.446 10 14 10.365 14 10.917V12H21.995C23.101 12 24 12.814 24 13.92V22H24.998ZM14.002 8H25.995C27.101 8 28 8.814 28 9.92V21.914C28 23.02 27.101 24 25.995 24H24V25.914C24 27.02 23.101 28 21.995 28H10.002C8.895 28 8 27.02 8 25.914V13.92C8 12.814 8.895 12 10.002 12H12V9.92C12 8.814 12.895 8 14.002 8Z" fill="white" />
    </svg>
);

export default ImgCount;
