/* eslint-disable max-len */
import React from 'react';


const SimpleCheckBlue = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.95952 14.8354C10.35 14.4448 10.9832 14.4448 11.3737 14.8354L14.4379 17.8995L21.2732 11.0641C21.6638 10.6736 22.2969 10.6736 22.6874 11.0641C23.078 11.4547 23.078 12.0878 22.6874 12.4783L15.145 20.0208L14.4379 20.7279L13.7308 20.0208L9.95952 16.2496C9.56899 15.8591 9.56899 15.2259 9.95952 14.8354Z" fill="#2F8FE3" />
        <circle cx="15.9989" cy="16.0001" r="11.7879" stroke="#2F8FE3" strokeWidth="2" />
    </svg>
);

export default SimpleCheckBlue;
