import { takeLatest, all, put, call } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'redux-first-history';
import ReactGA from 'react-ga';
import API from '@flowhealth/api';

import imgToBase64 from 'utils/imgToBase64';

import { GAIdentifier } from "../..";

import * as ACTION_TYPES from './constants';
import * as ACTIONS from './actions';


// eslint-disable-next-line
function* GoogleAnalytic() {
    if (GAIdentifier) {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
}

function* getLogo({ payload }) {
    try {
        const logoUrl = yield call(API.getInstance().getLabLogoByDomain, payload);
        const logoUrlBase64 = yield imgToBase64(logoUrl);

        yield put(ACTIONS.getLogoSuccess(logoUrlBase64));
    } catch (error) {
        yield put(ACTIONS.getLogoFailure({ error }));
    }
}


function* Saga() {
    yield all([
        takeLatest(LOCATION_CHANGE, GoogleAnalytic),
        takeLatest(ACTION_TYPES.GET_LOGO_REQUEST, getLogo),
    ]);
}

export default Saga;
