import { PCR_ORDER_KIT } from './constants';

export const INIT_PAGE = `INIT_PAGE__${PCR_ORDER_KIT}`;
export const RESET_STATE = `RESET_STATE__${PCR_ORDER_KIT}`;

export const SET_LOADING = `SET_LOADING__${PCR_ORDER_KIT}`;
export const SET_PATIENT = `SET_PATIENT__${PCR_ORDER_KIT}`;
export const SET_PERSON = `SET_PERSON__${PCR_ORDER_KIT}`;
export const SET_PRACTICE = `SET_PRACTICE__${PCR_ORDER_KIT}`;
export const SET_KIT_ORDER = `SET_KIT_ORDER__${PCR_ORDER_KIT}`;

export const WS_SUBSCRIBE = `WS_SUBSCRIBE__${PCR_ORDER_KIT}`;
export const WS_UNSUBSCRIBE = `WS_UNSUBSCRIBE__${PCR_ORDER_KIT}`;
export const WS_SUCCESS = `WS_SUCCESS__${PCR_ORDER_KIT}`;
export const WS_FAILURE = `WS_FAILURE__${PCR_ORDER_KIT}`;

export const SELECT_PATIENT_BY_PERSON = `SELECT_PATIENT_BY_PERSON__${PCR_ORDER_KIT}`;
export const SELECT_PATIENT_BY_PERSON_SUCCESS = `SELECT_PATIENT_BY_PERSON_SUCCESS__${PCR_ORDER_KIT}`;
export const SELECT_PATIENT_BY_PERSON_FAILURE = `SELECT_PATIENT_BY_PERSON_FAILURE__${PCR_ORDER_KIT}`;

export const COPY_TO_PRACTICE_REQUEST = `COPY_TO_PRACTICE_REQUEST__${PCR_ORDER_KIT}`;
export const COPY_TO_PRACTICE_SUCCESS = `COPY_TO_PRACTICE_SUCCESS__${PCR_ORDER_KIT}`;
export const COPY_TO_PRACTICE_FAILURE = `COPY_TO_PRACTICE_FAILURE__${PCR_ORDER_KIT}`;
export const COPY_TO_PRACTICE_FINISHED = `COPY_TO_PRACTICE_FINISHED__${PCR_ORDER_KIT}`;

export const SET_SPECIMEN_KIT_ID = `SET_SPECIMEN_KIT_ID__${PCR_ORDER_KIT}`;
export const SET_COMMON_PRACTICE = `SET_COMMON_PRACTICE__${PCR_ORDER_KIT}`;

export const SEND_REQUISITION_REQUEST = `SEND_REQUISITION_REQUEST__${PCR_ORDER_KIT}`;
export const SEND_REQUISITION_SUCCESS = `SEND_REQUISITION_SUCCESS__${PCR_ORDER_KIT}`;
export const SEND_REQUISITION_FAILURE = `SEND_REQUISITION_FAILURE__${PCR_ORDER_KIT}`;

export const ADD_REQUISITION_PROCESSING = `ADD_REQUISITION_PROCESSING__${PCR_ORDER_KIT}`;
export const REMOVE_REQUISITION_PROCESSING = (
    `REMOVE_REQUISITION_PROCESSING__${PCR_ORDER_KIT}`
);
export const UPDATE_REQUISITION_PROCESSING_STATUS = (
    `UPDATE_REQUISITION_PROCESSING_STATUS__${PCR_ORDER_KIT}`
);
export const UPDATE_REQUISITION_PROCESSING_LAB = (
    `UPDATE_REQUISITION_PROCESSING_LAB__${PCR_ORDER_KIT}`
);
export const UPDATE_REQUISITION_PROCESSING_CONTAINER_TYPE = (
    `UPDATE_REQUISITION_PROCESSING_CONTAINER_TYPE__${PCR_ORDER_KIT}`
);
export const UPDATE_REQUISITION_PROCESSING_CODE = (
    `UPDATE_REQUISITION_PROCESSING_CODE__${PCR_ORDER_KIT}`
);

export const GET_QUESTIONS_REQUEST = (
    `GET_QUESTIONS_REQUEST__${PCR_ORDER_KIT}`
);

export const GET_QUESTIONS_SUCCESS = (
    `GET_QUESTIONS_SUCCESS__${PCR_ORDER_KIT}`
);

export const GET_QUESTIONS_FAILURE = (
    `GET_QUESTIONS_FAILURE__${PCR_ORDER_KIT}`
);

export const SET_ANSWERS = `SET_ANSWERS__${PCR_ORDER_KIT}`;

export const SET_INSURANCE = `SET_INSURANCE__${PCR_ORDER_KIT}`;
export const SET_INSURANCE_FORM_DATA = `SET_INSURANCE_FORM_DATA__${PCR_ORDER_KIT}`;

// form
export const RESET_FORM_STATE = `RESET_FORM_STATE__${PCR_ORDER_KIT}`;
export const SET_FORM_FIELD_DATA = `SET_FORM_FIELD_DATA__${PCR_ORDER_KIT}`;
export const SET_FORM_FIELD_VALUE = `SET_FORM_FIELD_VALUE__${PCR_ORDER_KIT}`;
export const SET_FORM_FIELD_TOUCHED = `SET_FORM_FIELD_TOUCHED__${PCR_ORDER_KIT}`;
