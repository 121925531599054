/* eslint-disable max-len */
import React from 'react';

const FilePdf = () => (
    <svg viewBox="0 0 19 20">
        <path fillRule="evenodd" d="M12.9161 2.2904L16.9107 6.9184C17.0403 7.0672 17.1201 7.264 17.1201 7.4696V9.2832C17.1201 9.7248 16.7884 10 16.3834 10H16.3798C15.9756 10 15.6556 9.7248 15.6556 9.2832V8.4H12.7111C11.9019 8.4 11.2619 7.7672 11.2619 6.8832V3.6H4.65606C4.25184 3.6 3.93916 4.0416 3.93916 4.4832V9.2832C3.93916 9.7248 3.60744 10 3.20249 10H3.19883C2.79461 10 2.47461 9.7248 2.47461 9.2832V3.6832C2.47461 2.8 3.11462 2 3.92378 2H12.3816C12.5822 2 12.7777 2.1312 12.9161 2.2904ZM6.84995 13.8704C6.91586 15.2544 5.90825 16.4 4.65606 16.4H3.93916V17.2832C3.93916 17.7248 3.60744 18 3.20249 18H3.19883C2.79461 18 2.47461 17.7248 2.47461 17.2832V12.4832C2.47461 12.0416 2.78729 11.6 3.19151 11.6H4.53157C5.72152 11.6 6.78771 12.5728 6.84995 13.8704ZM5.38796 14.0831C5.38796 13.6415 5.0599 13.2831 4.65569 13.2831H3.92341V14.8831H4.65569C5.0599 14.8831 5.38796 14.5247 5.38796 14.0831ZM11.9942 15.6V14C11.9942 12.68 11.0056 11.6 9.79739 11.6H8.31747C7.9213 11.6 7.60057 11.9504 7.60057 12.3832V17.2832C7.60057 17.6792 7.89348 18 8.25595 18H9.79739C11.0056 18 11.9942 16.92 11.9942 15.6ZM9.78236 13.1999C10.1866 13.1999 10.53 13.6415 10.53 14.0831V15.6831C10.53 16.1247 10.1866 16.3999 9.78236 16.3999H9.06546V13.1999H9.78236ZM17.1201 12.4416V12.4208C17.1201 11.9784 16.7767 11.6 16.3724 11.6H13.4433C13.0391 11.6 12.7264 12.0416 12.7264 12.4832V17.2832C12.7264 17.7248 13.0464 18 13.4507 18H13.4543C13.8593 18 14.191 17.7248 14.191 17.2832V16.4H16.3724C16.7767 16.4 17.1201 16.0832 17.1201 15.6416V15.6208C17.1201 15.1784 16.7767 14.8 16.3724 14.8H14.191V13.2H16.3724C16.7767 13.2 17.1201 12.8832 17.1201 12.4416Z" clipRule="evenodd" />
    </svg>
);

export default FilePdf;
