import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { memoize, isFunction, isEmpty } from 'lodash';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
    safeGet,
    isObject,
    getNameFromParams,
    getUserName,
    getUserEmail,
} from '@flowhealth/utils';

import FlowIcon from 'components/FlowIcon';
import { ICONS_TYPES } from 'components/FlowIcon/constants';
import FlowLink from 'components/FlowLink';
import { selectValueSet } from 'components/ValueSet';
import { getValuesRequest } from 'components/ValueSet/actions';

import { logoutRequest } from 'containers/Auth/actions';
import { KEY_PATIENT_PORTAL } from 'containers/PatientPortal/store/constants';
import { selectIsMobile } from 'containers/App/selectors';

import { PRIVACY_POLICY_PATH } from 'configuration/paths';

import isEqualObjects from 'utils/isEqualObjects';
import select from 'utils/select';

import {
    makeSelectUser,
    makeSelectData,
    makeSelectRole,
    makeSelectUserId,
    selectRoles,
} from './selector';
import { userRequest } from './actions';
import styles from './styles';
import { LIST_POSITION_START, LIST_TOP_POSITION_OFFSET, elemBoundingRectKeys } from './constants';


const handleNavLinkOnClick = (link, e) => {
    if (link.disabled) e.preventDefault();
};


class User extends React.PureComponent {
    static propTypes = {
        fullWidth: PropTypes.bool,
        config: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string.isRequired,
            content: PropTypes.string,
            icon: PropTypes.node,
            onClick: PropTypes.func,
        })),
        topOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        dataTestId: PropTypes.string,
        dropdownDataTestId: PropTypes.string,
    };

    static defaultProps = {
        listPositionStart: LIST_POSITION_START.right,
        dataTestId: 'userHeaderMenu',
        disabled: false,
        compact: false,
    };

    state = {
        isMenuOpen: false,
        listAnchorBounds: {},
        app: null,
    };

    componentDidMount() {
        const { user, dispatch, rolesVS, productsVS } = this.props;
        if (isEmpty(rolesVS)) dispatch(getValuesRequest({ file: 'roles' }));
        if (isEmpty(productsVS)) dispatch(getValuesRequest({ file: 'products' }));
        if (isObject(user) && !user.data) dispatch(userRequest());
        if (this.listAnchor) {
            const currentBounds = elemBoundingRectKeys.reduce((memo, key) => ({
                ...memo,
                [key]: this.listAnchor.getBoundingClientRect()[key],
            }), {});

            this.setState({ listAnchorBounds: currentBounds });
        }
        this.mounted = true;
        this.setAppNode();
    }

    componentDidUpdate(prevProps, prevState) {
        const { isMenuOpen = false, app } = this.state;
        const isPrevMenuOpen = safeGet(prevState, 'isMenuOpen');
        if (isMenuOpen && !isPrevMenuOpen) document.body.addEventListener('click', this.handleClickOutside);
        else if (!isMenuOpen && isPrevMenuOpen) document.body.removeEventListener('click', this.handleClickOutside);

        const currentBounds = elemBoundingRectKeys.reduce((memo, key) => ({
            ...memo,
            [key]: this.listAnchor.getBoundingClientRect()[key],
        }), {});

        if (!isEqualObjects(prevState.listAnchorBounds, currentBounds)) {
            this.setState({ listAnchorBounds: currentBounds });
        }

        if (app && !prevState.app) this.state.app.addEventListener('scroll', this.handleCloseList, false);
    }

    componentWillUnmount() {
        this.appTimeout && clearTimeout(this.appTimeout);
        this.state.app && this.state.app.removeEventListener('scroll', this.handleCloseList, false);
        document.body && document.body.removeEventListener('click', this.handleClickOutside);
        this.mounted = false;
    }

    setAppNode = () => {
        this.appTimeout = setTimeout(
            () => this.setState({ app: document.querySelector('.c-app') }),
            100,
        );
    };

    handleClickOutside = event => {
        if ((this.listAnchor && this.listAnchor.contains(event.target))
            || (this.actionsContainerRef && this.actionsContainerRef.contains(event.target))) return;
        this.handleCloseList();
        this.mounted && this.setState({ menuIsOpen: false });
    };

    handleCloseList = () => this.setState({ isMenuOpen: false });

    toggleMenu = () => this.setState(prevState => ({
        ...prevState,
        isMenuOpen: !prevState.isMenuOpen,
    }));

    handleItemClick = memoize((itemOnClick, item) => event => {
        if (safeGet(item, 'disabled')) return null;
        isFunction(itemOnClick) && itemOnClick(event);
        this.handleCloseList();
    });

    listAnchor = null;

    actionsContainerRef = null;

    get menuConf() {
        return [
            {
                to: PRIVACY_POLICY_PATH,
                icon: 'legalIc',
                label: 'Legal',
            },
            {
                id: 'logout',
                onClick: () => this.props.dispatch(logoutRequest()),
                icon: 'logout',
                label: 'Logout',
            },
        ];
    }

    get menuProfile() {
        const { classes = {} } = this.props;
        const data = safeGet(this, 'props.user.data');
        const name = getUserName(data);
        const firstName = name?.given;
        const lastName = name?.family;
        const email = getUserEmail(data);
        const fullName = getNameFromParams(firstName, lastName);
        return (
            <div className={classes.menuProfile}>
                <div className={classes.menuProfileHead}>{fullName}</div>
                <FlowLink className={classes.menuProfileSubHead}>{email}</FlowLink>
            </div>
        );
    }

    get menuItems() {
        const { classes = {}, role } = this.props;
        return this.menuConf.filter(item => item.id !== 'logout').map((item, idx) => {
            const { label, to, showIf, onClick } = item;
            if (showIf && !showIf(role)) return null;
            const menuItem = (
                <div
                    className={cn(classes.listItem, { [classes.disabledMenuItem]: item.disabled })}
                    onClick={this.handleItemClick(onClick, item)}
                    key={idx}
                >
                    <span className={classes.itemText}>{label}</span>
                </div>
            );
            if (to) {
                return (
                    <NavLink
                        className={cn(classes.link, { [classes.disabledMenuItem]: item.disabled })}
                        onClick={e => handleNavLinkOnClick(item, e)}
                        to={to}
                        key={label}
                    >
                        {menuItem}
                    </NavLink>
                );
            }
            return menuItem;
        });
    }

    getElement({ type, ...item }, idx) {
        const { classes } = this.props;
        const logoutAction = this.menuConf.find(item => item.id === 'logout');

        switch (type) {
        case 'divider':
            return <div className={classes.divider} key={idx} />;
        case 'version':
            return <div className={classes.appVersion} key={idx}>v{window.ver}</div>;
        case 'button':
            return (
                <div
                    className={cn(classes.listItem, classes.alignCenter)}
                    onClick={() => { item.onClick(); this.toggleMenu(); }}
                    key={idx}
                >
                    {item.icon ? <FlowIcon className={classes.btnIcon} type={item.icon} size={20} /> : null}
                    <span className={classes.itemText}>{item.content}</span>
                </div>
            );
        case 'buttonLink':
            return (
                <NavLink
                    className={
                        cn(
                            classes.listItem,
                            classes.alignCenter,
                            classes.link,
                            { [classes.disabledMenuItem]: item.disabled },
                        )}
                    onClick={e => handleNavLinkOnClick(item, e)}
                    to={item.to}
                    key={idx}
                >
                    {item.icon ? <FlowIcon className={classes.btnIcon} type={item.icon} size={20} /> : null}
                    <span className={classes.itemText}>{item.content}</span>
                </NavLink>
            );
        case 'buttonLogout':
            return logoutAction ? (
                <div
                    className={cn(classes.listItem, classes.alignCenter)}
                    onClick={this.handleItemClick(logoutAction.onClick)}
                    key={idx}
                    data-tid="logoutBtn"
                >
                    <FlowIcon className={classes.btnIcon} type={ICONS_TYPES.logout} size={20} />
                    <span className={classes.itemText}>{logoutAction.label}</span>
                </div>
            ) : null;
        default:
            return null;
        }
    }

    get dropdownBody() {
        const { classes = {}, config, roles } = this.props;
        const logoutAction = this.menuConf.find(item => item.id === 'logout');

        if (config) return config.map((item, idx) => this.getElement(item, idx));
        return (
            <>
                {this.menuProfile}
                <div className={classes.divider} />
                {this.menuItems}
                <div className={classes.appVersion}>v{window.ver}</div>
                <div className={classes.divider} />
                {roles && roles.length > 1 && (
                    <>
                        <div className={classes.roles}>Roles</div>
                        {this.roleItems}
                        <div className={classes.divider} />
                    </>
                )}
                {logoutAction && (
                    <div
                        className={classes.listItem}
                        onClick={this.handleItemClick(logoutAction.onClick)}
                        data-tid="logoutBtn"
                    >
                        <span className={classes.itemText}>{logoutAction.label}</span>
                    </div>
                )}
            </>
        );
    }

    get dropdown() {
        const { classes = {}, listPositionStart, fullWidth, topOffset, dropdownDataTestId } = this.props;
        const { isMenuOpen, listAnchorBounds } = this.state;
        if (!isMenuOpen || !this.listAnchor) return null;
        const resultTopOffset = LIST_TOP_POSITION_OFFSET + topOffset;

        return createPortal(
            <div
                className={classes.popup}
                data-tid={dropdownDataTestId}
                ref={ref => { this.actionsContainerRef = ref; }}
                style={fullWidth ? {
                    left: 0,
                    maxWidth: '100vw',
                    width: '100vw',
                    top: listAnchorBounds.top + listAnchorBounds.height + resultTopOffset,
                    borderRadius: 0,
                } : {
                    [listPositionStart]: listPositionStart === LIST_POSITION_START.right
                        ? window.innerWidth - listAnchorBounds.right
                        : listAnchorBounds.left,
                    top: listAnchorBounds.top + listAnchorBounds.height + LIST_TOP_POSITION_OFFSET,
                }}
            >
                {this.dropdownBody}
            </div>,
            document.body,
        );
    }

    get userInfo() {
        const { classes = {} } = this.props;
        return (
            <div className={classes.patientUserInfo}>
                <FlowIcon
                    className={classes.patientUserIcon}
                    type={ICONS_TYPES.user}
                    size={20}
                />
            </div>
        );
    }

    render() {
        const { classes = {}, dataTestId } = this.props;
        return (
            <div ref={ref => { this.listAnchor = ref; }}>
                <div
                    className={classes.header}
                    onClick={this.toggleMenu}
                    data-tid={dataTestId}
                >
                    {this.userInfo}
                </div>
                {this.dropdown}
            </div>
        );
    }
}


const mapStateToProps = createStructuredSelector({
    user: makeSelectUser(),
    data: makeSelectData(),
    role: makeSelectRole(),
    userId: makeSelectUserId(),
    roles: selectRoles,
    rolesVS: selectValueSet('roles'),
    productsVS: selectValueSet('products'),
    currentPatient: select(KEY_PATIENT_PORTAL, 'currentPatient'),
    mobileSwitcher: selectIsMobile,
});

const withConnect = connect(mapStateToProps);

export default compose(
    withStyles(styles),
    withRouter,
    withConnect,
)(User);
