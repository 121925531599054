import { call } from 'redux-saga/effects';
import dayjs from "dayjs";
import sha256 from 'crypto-js/sha256';
import { uniqBy } from "lodash";
import API from '@flowhealth/api';
import { isFullArray, isFullObject, safeGet } from "@flowhealth/utils";

import {
    getLatestReqTest,
    getStatus,
    isPcrExpired,
    isPocExpired
} from "containers/PatientPortal/HealthPassport/utils";
import { COVID_STATUS, COVID_TEST_TYPES } from "containers/PatientPortal/HealthPassport/constants";

import { Posthog } from "./Posthog";

function getTestResult(person) {
    const pocReqTest = safeGet(person, 'pocReqTests[0]');
    const pcrReqTest = safeGet(person, 'pcrReqTests[0]');

    if (!pcrReqTest && !pocReqTest) {
        return 'NC'
    }
    const [latestReqTest, testType] = getLatestReqTest(pocReqTest, pcrReqTest);
    if (testType === COVID_TEST_TYPES.PCR && isPcrExpired(latestReqTest)) {
        return 'Exp'
    }
    if (testType === COVID_TEST_TYPES.POC && isPocExpired(latestReqTest)) {
        return 'Exp'
    }
    const status = getStatus(pocReqTest, pcrReqTest);
    if (status === COVID_STATUS.positive) {
        return 'Pos'
    } else if (status === COVID_STATUS.negative) {
        return 'Neg'
    }
}

const findFirstCreatedPatient = patients => {
    if (!patients || !isFullArray) {
        return null;
    }
    const [patient] = patients.sort((a, b) => {
        if (dayjs(a.created_at).isAfter(dayjs(b.created_at))) {
            return 1
        }
        return -1
    });
    return patient;
}

export const findPatientByUser = (user, patients) => {
    const userBio = user?.user.name;
    const userName = userBio?.given;
    const userFamily = userBio?.family;

    let patient = patients
        ?.find(({ name: { given, family } }) => given === userName && family === userFamily);

    if (!patient) {
        patient = findFirstCreatedPatient(patients);
    }

    return patient;
}

export function* collectInfoByPatient(patients, user) {
    if (!isFullArray(patients) || !isFullObject(user)) return;

    const patient = findPatientByUser(user, patients);

    const id = sha256(user.user.id).toString();
    const { gender, race, birth_date } = patient;
    const age = dayjs().diff(dayjs(birth_date), 'years');
    const smokingStatus = !!patient?.smoking_status;
    const numberOfPatients = patients.length;
    const unSortedPractices = patients.map(({ practice }) => practice);
    const numberOfPractices = uniqBy(unSortedPractices, 'id').length;
    const unSortedInsurances = patients.map(({ insurances }) => insurances).flat(1);
    const numberOfInsurances = uniqBy(unSortedInsurances, 'member_id').length;

    const { insurance_required, employee_id_required } =
        yield call(API.getInstance().practiceRequirements, { practice_id: patient?.practice?.id });
    const posthog = Posthog.getInstance();

    posthog.identify(id);
    posthog.appendData({
        gender,
        race,
        age,
        smokingStatus,
        numberOfPatients,
        insuranceDataRequired: insurance_required,
        employeeDataRequired: employee_id_required,
        numberOfPractices,
        numberOfInsurances,
    })
}

export function collectInfoByPerson(persons, patient) {
    if (!persons || !isFullArray(persons) || !patient) return;

    const numberOfPersons = persons.length;
    const person = persons.find(({ patients }) => patients.some(({ id }) => id === patient.id));
    const vac = person?.vaccinated !== 'unknown';
    const test = getTestResult(person);

    Posthog.getInstance().appendData({
        vac,
        test,
        numberOfPersons,
    })
}
