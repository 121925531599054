const SELECT_TYPE_RAW = 'raw';
const SELECT_TYPE_BOOLEAN = 'boolean';
const SELECT_TYPE_VALUE_SET = 'valueSet';
const SELECT_TYPE_SEARCH = 'search';
const SELECT_TYPE_EDGE = 'edge';

export const SELECT_TYPES = {
    [SELECT_TYPE_RAW]: SELECT_TYPE_RAW,
    [SELECT_TYPE_BOOLEAN]: SELECT_TYPE_BOOLEAN,
    [SELECT_TYPE_VALUE_SET]: SELECT_TYPE_VALUE_SET,
    [SELECT_TYPE_SEARCH]: SELECT_TYPE_SEARCH,
    [SELECT_TYPE_EDGE]: SELECT_TYPE_EDGE,
};

const SELECT_LIST_X_POSITION_START_RIGHT = 'right';
const SELECT_LIST_X_POSITION_START_LEFT = 'left';

export const SELECT_LIST_X_POSITION_START = {
    [SELECT_LIST_X_POSITION_START_RIGHT]: SELECT_LIST_X_POSITION_START_RIGHT,
    [SELECT_LIST_X_POSITION_START_LEFT]: SELECT_LIST_X_POSITION_START_LEFT,
};

export const SELECT_SEARCH_REQUEST = 'SELECT_SEARCH_REQUEST';
export const SELECT_SEARCH_SUCCESS = 'SELECT_SEARCH_SUCCESS';
export const SELECT_SEARCH_FAILURE = 'SELECT_SEARCH_FAILURE';

export const SELECT_EDGE_REQUEST = 'SELECT_EDGE_REQUEST';
export const SELECT_EDGE_SUCCESS = 'SELECT_EDGE_SUCCESS';
export const SELECT_EDGE_FAILURE = 'SELECT_EDGE_FAILURE';

export const SELECT_ADD_GRAPH_REQUEST = 'SELECT_ADD_GRAPH_REQUEST';
export const SELECT_ADD_GRAPH_SUCCESS = 'SELECT_ADD_GRAPH_SUCCESS';
export const SELECT_ADD_GRAPH_FAILURE = 'SELECT_ADD_GRAPH_FAILURE';

export const SELECT_GET_MISSING_VALUE_REQUEST = 'SELECT_GET_MISSING_VALUE_REQUEST';
export const SELECT_GET_MISSING_VALUE_SUCCESS = 'SELECT_GET_MISSING_VALUE_SUCCESS';
export const SELECT_GET_MISSING_VALUE_FAILURE = 'SELECT_GET_MISSING_VALUE_FAILURE';

export const SELECT_CLEAR = 'SELECT_CLEAR';

export const SELECT_INPUT_DELAY = 500;
export const SELECT_INPUT_DEFAULT_WIDTH = '100%';
export const SELECT_SCROLL_FIRE_ON_PERCENT = 90;
export const SELECT_LIST_DEFAULT_MAX_WIDTH = 320;
export const LIST_TOP_POSITION_OFFSET = 10;
export const LIST_TOP_POSITION_OFFSET_PATIENT_PORTAL = 8;

export const SELECT_INPUT_DEFAULT_PLACEHOLDER = 'Search...';
export const SELECT_NO_INPUT_DEFAULT_PLACEHOLDER = 'Select something...';

export const SELECT_TRUE_ITEM = {
    code: true,
    display: 'True',
};

export const SELECT_FALSE_ITEM = {
    code: false,
    display: 'False',
};

export const SELECT_BOOLEAN_ITEMS = [
    SELECT_TRUE_ITEM,
    SELECT_FALSE_ITEM,
];

export const CREATE_FORM_DIALOG_POSTFIX = 'CreateFormDialog';
export const VIEW_FORM_DIALOG_POSTFIX = 'ViewFormDialog';

export const elemBoundingRectKeys = ['x', 'y', 'width', 'height', 'top', 'right', 'bottom', 'left'];

export const SELECT_UNDEFINED_FILTER_ITEM = { code: '!', display: 'Empty' };
export const SELECT_UNDEFINED_PRIORITY_FILTER_ITEM = { code: '!', display: 'No priority', color: 'F5F7F9' };
export const PRIORITY_FILTER_ID = 'priority';
