import { call, put, takeLatest, all } from 'redux-saga/effects';
import { isFunction } from 'lodash';
import { safeGet } from '@flowhealth/utils';
import API from '@flowhealth/api';

import { MAILING_KIT_ERR_MSG } from 'configuration/constants/errorMessages';

import * as ACTION_TYPES from './actionTypes';
import * as ACTIONS from './actions';


function* searchOrderByKitId({ payload }) {
    const { kitId } = payload;
    try {
        const response = yield call(API.getInstance().search, {
            object: 'requisition',
            expand: 'patient',
            filters: `kit_id_sort:${kitId}`,
        });
        const resultsCount = safeGet(response, 'count');
        const order = safeGet(response, 'results[0]', {});

        if (resultsCount && !order.collected_at) {
            yield put(ACTIONS.searchOrderSuccess({ order }));
        } else {
            yield put(ACTIONS.searchOrderFailure({ searchError: MAILING_KIT_ERR_MSG.unknownId }));
        }
    } catch (error) {
        yield put(ACTIONS.searchOrderFailure({ searchError: MAILING_KIT_ERR_MSG.unknownId }));
    }
}

function* patchOrder({ payload = {} } = {}) {
    try {
        const { graphId, data, expand, onSuccess } = payload;
        const orderUpload = yield call(API.getInstance().patchGraph, graphId, data, expand);
        yield put(ACTIONS.patchOrderSuccess(orderUpload));

        if (isFunction(onSuccess)) onSuccess();
    } catch (error) {
        yield put(ACTIONS.patchOrderFailure({ ...payload, error }));
    }
}


export default function* Saga() {
    yield all([
        takeLatest(ACTION_TYPES.SEARCH_ORDER_REQUEST, searchOrderByKitId),
        takeLatest(ACTION_TYPES.PATCH_ORDER_REQUEST, patchOrder),
    ]);
}
