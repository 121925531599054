/* eslint-disable max-len */
import React from 'react';


const LegalWithCheck = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.66675 10.6668C7.66675 9.0099 9.0099 7.66675 10.6667 7.66675H10.8149V5.66675H10.6667C7.90532 5.66675 5.66675 7.90533 5.66675 10.6668V24.0001C5.66675 26.7615 7.90532 29.0001 10.6667 29.0001H18.4314C18.0323 28.3951 17.729 27.7212 17.5434 27.0001H10.6667C9.00989 27.0001 7.66675 25.6569 7.66675 24.0001V10.6668ZM24.3334 18.6749C25.034 18.7094 25.7061 18.8521 26.3334 19.0865V10.6667C26.3334 7.90532 24.0948 5.66675 21.3334 5.66675H21.1853V7.66675H21.3334C22.9903 7.66675 24.3334 9.00989 24.3334 10.6667V18.6749Z" fill="#2F8FE3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M24.0001 30.6667C26.9456 30.6667 29.3334 28.2789 29.3334 25.3333C29.3334 22.3878 26.9456 20 24.0001 20C21.0546 20 18.6667 22.3878 18.6667 25.3333C18.6667 28.2789 21.0546 30.6667 24.0001 30.6667ZM27.0723 24.363C27.3437 24.0528 27.3237 23.5709 27.0276 23.2865C26.7315 23.0022 26.2715 23.0231 26.0001 23.3333L23.8222 25.8912L22.5318 24.8616C22.2604 24.5514 21.8003 24.5304 21.5042 24.8148C21.2081 25.0991 21.1881 25.581 21.4596 25.8912L23.2861 27.5336C23.4239 27.691 23.6184 27.7807 23.8222 27.7807C24.0261 27.7807 24.2206 27.691 24.3583 27.5336L27.0723 24.363Z" fill="#2F8FE3" />
        <rect x="10.6667" y="4" width="10.6667" height="5.33333" rx="2.66667" stroke="#2F8FE3" strokeWidth="2" />
        <path d="M12 16H20" stroke="#2F8FE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 20H17.3333" stroke="#2F8FE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

export default LegalWithCheck;
