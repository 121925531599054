/* eslint-disable max-len */
import React from 'react';


const Dashboard = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M6 18H3c-.552 0-1-.448-1-1s.448-1 1-1h3c.552 0 1 .448 1 1s-.448 1-1 1zm1-4H2c-1.105 0-2 .895-2 2v2c0 1.105.895 2 2 2h5c1.105 0 2-.895 2-2v-2c0-1.105-.895-2-2-2zM17 4h-3c-.552 0-1-.448-1-1s.448-1 1-1h3c.552 0 1 .448 1 1s-.448 1-1 1zm1-4h-5c-1.105 0-2 .895-2 2v2c0 1.105.895 2 2 2h5c1.105 0 2-.895 2-2V2c0-1.105-.895-2-2-2zm0 17c0 .552-.448 1-1 1h-3c-.552 0-1-.448-1-1v-6c0-.552.448-1 1-1h3c.552 0 1 .448 1 1v6zm0-9h-5c-1.105 0-2 .895-2 2v8c0 1.105.895 2 2 2h5c1.105 0 2-.895 2-2v-8c0-1.105-.895-2-2-2zM7 9c0 .552-.448 1-1 1H3c-.552 0-1-.448-1-1V3c0-.552.448-1 1-1h3c.552 0 1 .448 1 1v6zm0-9H2C.895 0 0 .895 0 2v8c0 1.105.895 2 2 2h5c1.105 0 2-.895 2-2V2c0-1.105-.895-2-2-2z" clipRule="evenodd" />
    </svg>
);

export default Dashboard;
