export const colors = {
    green100: '#eafced',
    green400: '#48bf58',
    green500: '#34aa44',
    green600: '#249133',
    ash200: '#fcfcfc',
    ash300: '#f0f2f6',
    ash400: '#e2e7ee',
    ash500: '#cdd6e1',
    ash700: '#b4bfce',
    ash800: '#99a7bc',
    coal200: '#c4cad1',
    coal300: '#a8b2bd',
    coal400: '#949da7',
    coal500: '#758190',
    coal600: '#4f5862',
    blueOld: '#0093cb',
    coal700: '#2b3138',
    blue100: '#ecfaff',
    blue500: '#00a7e7',
    blue600: '#0288bb',
    red30: '#fbf4f4',
    red100: '#ffdcd6',
    red400: '#f15438',
    red500: '#e6492d',
    red600: '#d63d22',
    red650: '#f50057',
    red700: '#c8351b',
    gold100: '#fff9f0',
    gold700: '#d68b0e',
    gold500: '#f6ab2f',
    white: '#fff',
    white100: '#ffffff',

    blue: '#00a7e7',
    green: '#34aa44',
    coal: '#29313A',
    ash: '#cdd6e1',
    gold: '#f6ab2f',
    red: '#e6492d',
    violet: '#fff',
    navi: '#fff',
};

export const palette = {
    transparent: 'rgba(0, 0, 0, 0)',
    gray: {
        main: 'rgba(0, 0, 0, 0.1)', //  Main color equals to 100
        '04': 'rgba(0, 0, 0, .04)',
        '05': 'rgba(0, 0, 0, .05)',
        '08': 'rgba(0, 0, 0, .08)',
        25: 'rgba(0, 0, 0, .25)',
        75: 'rgba(0, 0, 0, .75)',
        85: 'rgba(255, 255, 255, 0.85)',
        125: 'rgba(0, 0, 0, .125)',
        200: 'rgba(0, 0, 0, 0.2)',
        300: 'rgba(0, 0, 0, 0.3)',
        400: 'rgba(0, 0, 0, 0.4)',
        500: 'rgba(0, 0, 0, 0.5)',
        600: 'rgba(0, 0, 0, 0.6)',
        700: 'rgba(0, 0, 0, 0.7)',
        800: 'rgba(0, 0, 0, 0.8)',
        900: 'rgba(0, 0, 0, 0.9)',
        100: 'rgba(0, 0, 0, 1)',
    },
    white: {
        main: '#fff',
        hover: '#4d89df',
        active: '#83aae4',
    },
    waterBlue: {
        main: '#1665d8',
        hover: '#4d89df',
        active: '#83aae4',
    },
    lightGray: {
        main: '#e6eaf1',
    },
    ghost: {
        main: colors.ash300,
        hover: colors.ash400,
        active: colors.ash500,
    },
    blue: {
        main: colors.blue,
        100: '#ecfaff',
        200: '#c8efff',
        300: '#5ecffb',
        400: '#14b3f1',
        // Main color equals to 500
        600: '#0288bb',
        700: '#006c95',
        800: '#004b67',
        900: '#002f41',
    },
    naviBlue: {
        main: '#055FAE',
        50: '#EFF7FC',
        100: '#D6ECFF',
        150: '#BFE1FD',
        200: '#78C0FF',
        300: '#2F8FE3',
        400: '#1475CA',
        // Main color equals to 500
        600: '#00539B',
        700: '#00437D',
    },
    green: {
        main: colors.green,
        100: '#eafced',
        200: '#9deba7',
        300: '#6cd179',
        400: '#48bf58',
        // Main color equals to 500
        600: '#249133',
        700: '#177724',
        800: '#0f5b1a',
        900: '#0b4613',
    },
    coal: {
        main: colors.coal,
        200: '#c4cad1',
        300: '#8b8f98',
        '300-third': '#a8b2bd',
        400: '#949da7',
        500: '#788697',
        600: '#4b4d52',
        '600tags': '#4f5862',
        // Main color equals to 700
        800: '#1d2125',
    },
    ash: {
        main: colors.ash,
        200: '#f9fAfB',
        250: '#f5f7f9',
        300: '#f0f2f6',
        400: '#e2e7ee',
        // Main color equals to 500
        600: '#c9d0db',
        700: '#b4bfce',
        800: '#99a7bc',
    },
    gold: {
        main: colors.gold,
        100: '#fff6e7',
        200: '#ffe7c0',
        300: '#ffd187',
        400: '#fac061',
        // Main color equals to 500
        600: '#e4991e',
        700: '#d68b0e',
        800: '#c77e05',
        900: '#b06e02',
    },
    red: {
        main: colors.red,
        50: '#fbf1ef',
        30: '#FBF4F3',
        100: '#ffdcd6',
        200: '#ff9f8e',
        300: '#f67760',
        400: '#f15438',
        // Main color equals to 500
        600: '#d63d22',
        700: '#c8351b',
        800: '#b82c13',
        900: '#a4240d',
    },
    violet: {
        main: colors.violet,
        100: '#eeebff',
        200: colors.violet,
        300: colors.violet,
        400: colors.violet,
        // Main color equals to 500
        600: colors.violet,
        700: '#7166ab',
        800: colors.violet,
        900: colors.violet,
    },
    navi: {
        main: colors.navi,
        100: colors.navi,
        200: colors.navi,
        300: '#2F8FE3',
        400: colors.navi,
        // Main color equals to 500
        600: colors.navi,
        700: colors.navi,
        800: colors.navi,
        900: colors.navi,
    },
};
