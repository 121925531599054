import { set, setIn } from 'immutable';
import { isBoolean } from 'lodash';
import { createReducer, safeGet } from '@flowhealth/utils';

import format from 'containers/Auth/utils/format';

import * as ACTION_TYPES from './actionTypes';
import initialState from './initialState';
import validate from './validate';


const resetState = () => initialState;

const setInputValue = (state, { payload: { form, data } }) => setIn(state, [form, data.id, 'value'], data.value);

const validateValue = (state, { payload: { form, data, formData } }) => setIn(
    state,
    [form, data.id, 'valid'],
    validate(
        formData,
        {
            id: data.id,
            value: format(data),
            valid: data.valid,
        },
    ),
);

const setDisabled = (state, { payload: { form, data } }) => setIn(state, [form, data.id, 'disabled'], data.disabled);

const setInputTouched = (state, { payload: { form, data } }) => setIn(state, [form, data.id, 'touched'], true);

const resetError = (state, { payload: { form } }) => setIn(state, [form, 'error'], initialState[form].error);

const setField = field => (state, { payload = {} } = {}) => (
    set(state, field || payload.field, payload?.data || payload)
);

const setLoading = value => (state, { payload = {} } = {}) => set(
    state,
    'loading',
    isBoolean(value) ? value : safeGet(payload, 'value', false),
);


export default createReducer(initialState, {
    [ACTION_TYPES.RESET_STATE]: resetState,

    [ACTION_TYPES.SET_FIELD]: setField(),

    [ACTION_TYPES.SET_INPUT_VALUE]: [setInputValue, validateValue, setDisabled],
    [ACTION_TYPES.SET_INPUT_TOUCHED]: [setInputTouched, validateValue, resetError],

    [ACTION_TYPES.ADD_PROFILE_REQUEST]: setLoading(true),
    [ACTION_TYPES.ADD_PROFILE_SUCCESS]: [setLoading(false), resetState],
    [ACTION_TYPES.ADD_PROFILE_FAILURE]: setLoading(false),

    [ACTION_TYPES.GET_ADDRESS_REQUEST]: setLoading(true),
    [ACTION_TYPES.GET_ADDRESS_SUCCESS]: setLoading(false),
    [ACTION_TYPES.GET_ADDRESS_FAILURE]: setLoading(false),
});
