/* eslint-disable max-len */
import React from 'react';


const Settings = () => (
    <svg viewBox="0 0 20 20">
        <path d="M9.5 10l3.422-3.478c.13-.124.201-.292.201-.468 0-.176-.071-.343-.201-.467l-.413-.395C12.38 5.07 12.207 5 12.022 5c-.184 0-.357.068-.487.192l-4.334 4.34c-.13.125-.202.291-.201.467 0 .178.07.343.201.468l4.334 4.341c.13.124.303.192.487.192.185 0 .357-.068.487-.192l.413-.394c.13-.124.201-.29.201-.465 0-.176-.071-.333-.201-.457L9.5 10z" />
    </svg>

);

export default Settings;
