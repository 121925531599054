import { createSelector } from 'reselect';
import { formatTelecom, getUserHumanName, safeGet, isFullObject } from '@flowhealth/utils';
import { App } from '@flowhealth/app';


const selectUser = state => state.user;

const getRoleFromState = userState => {
    if (!userState) return null;
    const data = userState.get('data');
    if (!data) return null;
    const role = data.object_type;
    return role ? role.split('_')[0] : null;
};

const getTrimmedRole = role => {
    if (!role) {
        return null;
    }

    const roleName = role.split('_');
    roleName.pop();

    return roleName.join('_');
};

const getRoles = userState => {
    if (!userState) return null;
    return userState.get('roles');
};

const getRoleId = userState => {
    if (!userState) return null;
    const data = userState.get('data');
    if (!data) return null;
    return data.id;
};

const getRoleExtension = userState => {
    if (!userState) return null;
    const data = userState.get('data');
    if (!data) return null;
    const extension = data.role_extension && data.role_extension[0];
    if (data.role === 'marketing' && !extension) return 'staff'; // marketing staff has no role extension
    return extension;
};

const getLabReferrals = userState => {
    if (!userState) return null;
    const data = userState.get('data');
    if (!data) return null;
    return Boolean(data.referrals && data.referrals.length);
};

const getId = userState => {
    if (!userState) return null;
    const data = userState.get('data');
    if (!data) return null;
    return isFullObject(data.user) ? data.user.id : data.user;
};

const normalizeData = state => {
    if (!state) return null;
    const data = state.get('data');
    if (!data) return null;

    const role = data.object_type;
    const { user } = data;

    return {
        role: role ? role.split('_')[0].replace(/\b\w/g, l => l.toUpperCase()) : '',
        first_name: safeGet(data, 'name.given', ''),
        last_name: safeGet(data, 'name.family', ''),
        avatar: safeGet(user, 'avatar'),
        email: safeGet(user, 'email', ''),
        phone: formatTelecom(data.telecom, 'phone'),
    };
};

const getLaboratory = userState => {
    const data = userState.get('data');
    return data && data.laboratory;
};

const getLaboratoryId = userState => {
    const data = userState.get('data');
    return data && data.laboratory ? data.laboratory.id || data.laboratory : undefined;
};

const getLaboratoryLogoId = (userState, role) => {
    const data = userState.get('data');
    const mapRoleToLab = {
        lab: data && data.laboratory,
        practitioner: userState.get('primaryLaboratory'),
    };
    const lab = mapRoleToLab[role];
    return {
        logoId: (lab && lab.logo) ? (lab.logo.id || lab.logo) : undefined,
        logoShortId: (lab && lab.short_logo) ? (lab.short_logo.id || lab.short_logo) : undefined,
    };
};

const getDistributorId = userState => {
    const data = userState.get('data');
    return data && data.distributor ? data.distributor.id || data.distributor : undefined;
};

const getDistributor = userState => {
    const data = userState.get('data');
    return data && data.distributor ? data.distributor : undefined;
};

const makeSelectUser = () => createSelector(
    selectUser,
    userState => userState && userState.toJS(),
);

const makeSelectData = () => createSelector(
    selectUser,
    userState => normalizeData(userState),
);

const makeSelectUserData = () => createSelector(
    selectUser,
    userState => userState && userState.get('data'),
);

const makeSelectUserLabLogoIsLoading = () => createSelector(
    selectUser,
    userState => userState && userState.get('labLogoIsLoading'),
);

const makeSelectRole = () => () => getTrimmedRole(App.getInstance().getRole());

const makeSelectRoleId = () => createSelector(
    selectUser,
    userState => getRoleId(userState),
);

const makeSelectRoleExtension = () => createSelector(
    selectUser,
    userState => getRoleExtension(userState),
);

const makeSelectLabReferrals = () => createSelector(
    selectUser,
    userState => getLabReferrals(userState),
);

const makeSelectUserId = () => createSelector(
    selectUser,
    userState => getId(userState),
);

const makeSelectLaboratory = () => createSelector(
    selectUser,
    userState => getLaboratory(userState),
);

const makeSelectLaboratoryId = () => createSelector(
    selectUser,
    userState => getLaboratoryId(userState),
);

const makeSelectLaboratoryLogoId = () => createSelector(
    selectUser,
    userState => getLaboratoryLogoId(userState, getRoleFromState(userState)),
);

const makeSelectLaboratoryLogo = () => createSelector(
    selectUser,
    userState => userState && userState.get('labLogo'),
);

const makeSelectLaboratoryLogoShort = () => createSelector(
    selectUser,
    userState => userState && userState.get('labLogoShort'),
);

const makeSelectPractices = () => createSelector(
    selectUser,
    userState => userState && userState.get('practices'),
);

const makeSelectPracticesLoading = () => createSelector(
    selectUser,
    userState => userState && userState.get('practicesLoading'),
);

const makeSelectDistributorId = () => createSelector(
    selectUser,
    userState => getDistributorId(userState),
);

const makeSelectDistributor = () => createSelector(
    selectUser,
    userState => getDistributor(userState),
);

export const selectUserRole = createSelector(
    selectUser,
    userData => userData?.getIn(['data', 'object_type'], null),
);

export const getUserTitle = data => getUserHumanName(data);

export const selectUserTitle = createSelector(
    makeSelectUserData(),
    getUserTitle,
);

export const selectUploadingAvatar = createSelector(
    selectUser,
    userData => userData?.get('uploadingAvatar'),
);

export const selectUserPatchLoading = createSelector(
    selectUser,
    userData => userData?.get('userPatchLoading'),
);

export const selectRolePatchLoading = createSelector(
    selectUser,
    userData => userData?.get('rolePatchLoading'),
);

export const selectRoles = createSelector(
    selectUser,
    getRoles,
);

export const makeSelectGetUserLoading = () => createSelector(
    selectUser,
    data => data?.get('getUserLoading'),
);

export {
    makeSelectUser,
    makeSelectData,
    makeSelectUserData,
    makeSelectRole,
    makeSelectUserId,
    makeSelectRoleId,
    makeSelectRoleExtension,
    makeSelectLaboratory,
    makeSelectLaboratoryId,
    makeSelectLaboratoryLogoId,
    makeSelectLaboratoryLogo,
    makeSelectLaboratoryLogoShort,
    makeSelectLabReferrals,
    makeSelectDistributorId,
    makeSelectDistributor,
    makeSelectUserLabLogoIsLoading,
    makeSelectPractices,
    makeSelectPracticesLoading,
};
