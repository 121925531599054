import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './constants';

export const openSuccessMessage = createAction(ACTION_TYPES.OPEN_SUCCESS_MESSAGE);
export const closeSuccessMessage = createAction(ACTION_TYPES.CLOSE_SUCCESS_MESSAGE);

export const openErrorMessage = createAction(ACTION_TYPES.OPEN_ERROR_MESSAGE);
export const closeErrorMessage = createAction(ACTION_TYPES.CLOSE_ERROR_MESSAGE);

export const openWarningMessage = createAction(ACTION_TYPES.OPEN_WARNING_MESSAGE);
export const closeWarningMessage = createAction(ACTION_TYPES.CLOSE_WARNING_MESSAGE);

export const openCustomMessage = createAction(ACTION_TYPES.OPEN_CUSTOM_MESSAGE);
export const closeCustomMessage = createAction(ACTION_TYPES.CLOSE_CUSTOM_MESSAGE);
