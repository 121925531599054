import React, { useState, useCallback, memo } from 'react';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Button, createUseStyles } from '@flowhealth/ui-components';

import Modal from 'components/Modal';

import Camera from 'containers/App/Camera/Camera';
import { selectCamera } from 'containers/App/selectors';

import ScannerInfo from './ScannerInfo';


const useStyles = createUseStyles(() => ({
    hintButton: {
        position: 'absolute !important',
        zIndex: 3001,
        bottom: 24,
        left: '50%',
        transform: 'translateX(-50%)',
        whiteSpace: 'nowrap',
    },
}));

const IssuesHint = ({ camera, onTakePhoto, LensComponent }) => {
    const [showScanHint, setShowScanHint] = useState(false);
    const classes = useStyles();

    const handleToggle = useCallback(() => {
        setShowScanHint(previosValue => !previosValue);
    }, []);

    return !showScanHint ? (
        <Button
            className={classes.hintButton}
            label="Take a Photo Manually"
            onClick={handleToggle}
        />
    ) : (
        <>
            {camera.isOpen && <Camera onTakePhoto={onTakePhoto} LensComponent={LensComponent} />}
            <Modal onClose={() => setShowScanHint(false)}>
                <ScannerInfo />
            </Modal>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    camera: selectCamera,
});

const withConnect = connect(mapStateToProps);

export default compose(
    withConnect,
    memo,
)(IssuesHint);
