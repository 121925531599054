import React from 'react';

export const withMasks = (maskInactive, maskActive) => Component => {
    const WrapperComponent = props => (
        <Component {...props} maskActive={maskActive} maskInactive={maskInactive} />
    );

    WrapperComponent.displayName = `withMasks(${Component.displayName ?? Component.name ?? 'Component'})`;

    return WrapperComponent;
};

export const DefaultLens = ({ classes, maskActive, maskInactive, captured, error }) => (
    <div className={classes.lens}>
        <img src={captured && !error ? maskActive : maskInactive} alt="Mask" />
    </div>
);
