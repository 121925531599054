import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';


const styles = () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '100%',
        height: '100%',
        padding: '0 20px 32px',

    },
    rootMedia: {
        '@media (min-width:441px)': {
            width: 334,
            margin: '0 auto',
            padding: '0 0 32px',
        },
    },
    actions: {
        display: 'flex',
        flexDirection: 'column',

        '& > *:first-child': {
            marginTop: 40,
        },

        '& button': {
            width: '100%',
            height: 48,
            fontSize: 16,
            lineHeight: 1.5,
            fontWeight: 600,
            letterSpacing: 0.5,
            cursor: 'pointer',
        },
        '& a:not(:last-child)': {
            marginBottom: 12,
        },
    },
    actionsBottom: {
        marginTop: 'auto',
    },
});

const AdaptiveContainer = props => {
    const {
        classes,
        mediaClassName,
        className,
        actions,
        children,
        actionsToBottom = true,
        actionsClassName,
        ...restProps
    } = props;

    return (
        <div
            className={cn(classes.root, mediaClassName || classes.rootMedia, className)}
            {...restProps}
        >
            {children}

            {
                actions && (
                    <div
                        className={cn(classes.actions, actionsClassName, { [classes.actionsBottom]: actionsToBottom })}
                    >
                        {actions}
                    </div>
                )
            }
        </div>
    );
};

AdaptiveContainer.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    mediaClassName: PropTypes.string,
};

export default compose(
    withStyles(styles),
    memo,
)(AdaptiveContainer);
