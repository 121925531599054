import { PATIENT_PROFILES_KEY } from './constants';


export const RESET_STATE = `RESET_STATE__${PATIENT_PROFILES_KEY}`;

export const SET_FIELD = `SET_FIELD__${PATIENT_PROFILES_KEY}`;

export const SET_INPUT_VALUE = `SET_INPUT_VALUE__${PATIENT_PROFILES_KEY}`;
export const SET_INPUT_TOUCHED = `SET_INPUT_TOUCHED__${PATIENT_PROFILES_KEY}`;

export const ADD_PROFILE_REQUEST = `ADD_PROFILE_REQUEST__${PATIENT_PROFILES_KEY}`;
export const ADD_PROFILE_SUCCESS = `ADD_PROFILE_SUCCESS__${PATIENT_PROFILES_KEY}`;
export const ADD_PROFILE_FAILURE = `ADD_PROFILE_FAILURE__${PATIENT_PROFILES_KEY}`;

export const GET_ADDRESS_REQUEST = `GET_ADDRESS_REQUEST__${PATIENT_PROFILES_KEY}`;
export const GET_ADDRESS_SUCCESS = `GET_ADDRESS_SUCCESS__${PATIENT_PROFILES_KEY}`;
export const GET_ADDRESS_FAILURE = `GET_ADDRESS_FAILURE__${PATIENT_PROFILES_KEY}`;
