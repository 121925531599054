const styles = ({ palette = {} } = {}) => ({
    root: {},
    container: {
        display: 'flex !important',
        border: `1px solid ${palette.ash[400]}`,
        borderRadius: 4,
        position: 'relative',
        backgroundColor: palette.white.main,
        '&.error': {
            borderColor: palette.red.main,
            boxShadow: `0 0 0 1px ${palette.red.main}`,
        },
        '&.focused': {
            borderColor: palette.naviBlue.dark,
            boxShadow: `0 0 0 1px ${palette.naviBlue.dark}`,
        },
        '&.noInput': { cursor: 'pointer' },
        '&.disabled': {
            cursor: 'not-allowed',
            backgroundColor: palette.ash[300],
            color: palette.coal.disable,
            '& .flag-container:hover': { cursor: 'not-allowed !important' },
            '& .flag-container:hover .selected-flag': {
                backgroundColor: `${palette.ash[300]} !important`,
            },
        },
        '&.leftIcon': { paddingLeft: 17 },
        '&.rightIcon': { paddingRight: 17 },
        '&.preview': {
            backgroundColor: 'inherit',
            border: 'none',
            padding: '8px 16px 8px 0',
        },
        '&.hoverable:hover': {
            border: `1px solid ${palette.ash.main}`,
            backgroundColor: palette.ash[300],
            cursor: 'pointer',
            '& .input': { cursor: 'pointer' },
        },
        '& .flag-container': {
            position: 'static !important',
            maxWidth: 46,
            border: 'none',
            padding: '0 !important',
        },
        '& .selected-flag': {
            outline: 'none',
            borderRight: `1px solid ${palette.ash[400]}`,
        },
        '& .country-list': {
            borderRadius: 4,
            margin: '4px 0 0 -1px !important',
            borderColor: palette.gray.main,
            maxHeight: '312px !important',
            width: '100%',
        },
        '& .country-list .divider': {
            paddingBottom: '8px !important',
            margin: '0 15px 8px !important',
            borderColor: palette.gray.main,
        },
        '& .flag-box': { marginRight: '12px !important' },
        '& .country': {
            display: 'flex !important',
            alignItems: 'center',
            padding: '8px 10px !important',
            fontSize: 14,
            lineHeight: 1.43,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
        '& .country-name': {
            color: palette.coal.main,
            textOverflow: 'ellipsis !important',
            overflow: 'hidden !important',
        },
        '& .dial-code': { color: palette.coal[500] },
        '&.invalid': {
            borderColor: palette.red.main,
            boxShadow: `0 0 0 1px ${palette.red.main}`,
        },
    },
    input: {
        padding: 8,
        paddingLeft: '8px !important',

        flexGrow: 1,
        border: 'none',
        borderRadius: 4,
        maxWidth: 'calc(100% - 46px)',
        fontSize: 14,
        lineHeight: 1.43,
        color: palette.coal.main,
        '&.disabled': { color: palette.coal.disable },
        '&:focus': { outline: 'none' },
        '&::placeholder': { color: palette.coal[500] },
    },
    label: {
        display: 'flex',
        alignSelf: 'flex-start',
        marginBottom: 2,
        fontSize: 12,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        color: palette.coal[500],
        '& .required': {
            marginLeft: 4,
            color: palette.red[700],
        },
    },
    hideFlag: {
        '& .flag-container': { display: 'none' },
    },
    error: {
        marginTop: 10,
        fontSize: 12,
        lineHeight: 1.43,
        color: palette.red.main,
    },
    arrow: {
        position: 'relative',
        left: 4,
        '& svg': {
            fill: palette.coal[500],
        },
    },
    customDropdown: {
        position: 'absolute',
        opacity: 0,
        width: '100%',
    },
});

export default styles;
