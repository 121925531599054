/* eslint-disable max-len */
import React from 'react';


const Drop = () => (
    <svg viewBox="0 0 3 24">
        <path fillRule="evenodd" d="M3 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM1.5 17a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM3 22.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" clipRule="evenodd" />
    </svg>
);

export default Drop;
