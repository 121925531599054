import dayjs from 'dayjs';
import isString from 'lodash/isString';
import { DATE_TIME_FORMATS, NBSP } from '@flowhealth/constants';
import { normalizeDate } from '@flowhealth/utils';

import { pluralize } from 'utils/pluralNames';


export default function getBirthDateWithAge(date, compareDate, onlyAge = false) {
    if (!isString(date) || !date.length) return '';

    const age = dayjs(compareDate).diff(date, 'year');
    const years = pluralize(
        {
            many: 'Years',
            few: 'Years',
            one: 'Year',
            zero: 'Years',
        },
        age,
    ).replace(/\s/g, NBSP);

    if (onlyAge) return `(${years})`;

    return `${normalizeDate(date, DATE_TIME_FORMATS.DATE_2, false)} (${years})`;
}
