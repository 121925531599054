import React, { Component } from 'react';
import copy from 'clipboard-copy';
import { withStyles } from '@material-ui/core/styles';
import { store } from 'configureStore';
import { theme } from '@flowhealth/ui-components';
// needs to import because of use order this component in /src/index.js
import { safeGet, sentry } from '@flowhealth/utils';

import { makeSelectRole } from 'containers/User/selector';

import ErrorImg from 'imgs/error.svg';

const { palette } = theme;

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    title: {
        fontSize: 26,
        color: palette.coal.main,
        fontWeight: 500,
        lineHeight: 1.54,
        marginBottom: 12,
    },
    url: {
        fontSize: 14,
        color: palette.coal.main,
        lineHeight: 1.43,
        marginBottom: 20,
        marginTop: 0,
    },
    errorUrl: {
        marginBottom: 24,
        textDecoration: 'none',
        fontWeight: 400,
        color: palette.coal[500],
        '&:hover': { color: palette.coal.third },
        '&:active': { color: palette.coal[500] },
        '&:focus': { color: palette.coal[500] },
    },
    text: {
        fontSize: 16,
        color: palette.coal[500],
        lineHeight: 1.5,
        maxWidth: 570,
        margin: 0,
    },
    button: {
        padding: '8px 32px',
        backgroundColor: palette.ash[300],
        color: palette.coal.tags,
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 4,
        cursor: 'pointer',

        '&:hover': {
            backgroundColor: palette.ash[400],
        },
    },
    copyResult: {
        fontSize: 14,
        fontWeight: 400,
        color: palette.green.main,
    },
    errorInput: {
        width: '100%',
        maxWidth: 624,
        height: 144,
        resize: 'none',
        border: `1px solid ${palette.ash.main}`,
        marginBottom: 24,
        padding: '12px 16px',
        borderRadius: 4,
    },
};

const COPY_SUCCESS_MESSAGE = 'Copied successfully!';


class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.copyToClipboard = this.copyToClipboard.bind(this);

        this.state = {
            errorText: null,
            errorUrl: null,
            copyResult: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        const stack = safeGet(errorInfo, 'componentStack');

        this.setState({
            errorText: `${error.message}\n${errorInfo.componentStack}`,
        });

        sentry({
            error,
            callBack: eventId => this.setState({
                errorUrl: `${process.env.REACT_APP_SENTRY_URL}?query=${eventId}`,
            }),
            setExtra: { ...errorInfo, stack },
        });
    }

    copyToClipboard() {
        copy(this.state.errorText);
        this.setState({ copyResult: COPY_SUCCESS_MESSAGE });
    }

    render() {
        const { classes } = this.props;
        const { errorUrl, copyResult, errorText } = this.state;
        const role = makeSelectRole()(store.getState());

        if (errorText && role !== 'patient') {
            return (
                <div className={classes.root}>
                    <img src={ErrorImg} alt="error" className={classes.img} />
                    <h1 className={classes.title}>Something went wrong!</h1>
                    <p className={classes.url}>{window.location.href}</p>
                    {errorUrl && (
                        <a className={classes.errorUrl} href={errorUrl}>{errorUrl}</a>
                    )}
                    <textarea {...{
                        value: errorText,
                        className: classes.errorInput,
                        disabled: true,
                    }}
                    />
                    <div {...{
                        className: classes.button,
                        onClick: this.copyToClipboard,
                    }}
                    >
                        Copy To Clipboard
                    </div>
                    {copyResult && <p className={classes.copyResult}>{copyResult}</p>}
                </div>
            );
        }

        if (errorText && role === 'patient') {
            return (
                <div className={classes.root}>
                    <img src={ErrorImg} alt="error" className={classes.img} />
                    <h1 className={classes.title}>Something went wrong!</h1>
                    <p className={classes.text}>Something went wrong at our end.</p>
                    <p className={classes.text}>{'Don\'t worry, it\'s not you - it\'s us. Sorry about that.'}</p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default withStyles(styles)(ErrorBoundary);
