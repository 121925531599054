/* eslint-disable max-len */
import React from 'react';


const Delete = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M7 15.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v7zm4 0a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v7zm-6 2a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v11zM7 4h6V2.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0-.5.5V4zm8.5 0a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-.5.5h-4a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 1 .5.5V19a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-4z" clipRule="evenodd" />
    </svg>
);

export default Delete;
