import setIn from 'utils/setIn';

import validate from './validate';
import format from './format';

export const setFormFieldData = (state, { payload: { form, field, data } }) => setIn(state, [form, field], data);

export const setFormFieldValue = (state, { payload: { form, data } }) => setIn(
    state,
    [form, data.id, 'value'],
    data.value,
);

export const setFormFieldTouched = (state, { payload: { form, data } }) => setIn(
    state,
    [form, data.id, 'touched'],
    true,
);

export const validateValue = (state, { payload: { form, data } }) => setIn(
    state,
    [form, data.id, 'valid'],
    validate({
        id: data.id,
        value: format(data),
        formData: state[form],
    }),
);
