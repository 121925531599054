/* eslint-disable max-len */
import React from 'react';

const LegalIc = () => (
    <svg viewBox="0 0 20 20">
        <path fill="inherit" d="M10 1C6.14 1 3 4.05 3 7.799c0 5.327 6.342 10.828 6.612 11.059.111.095.25.142.388.142s.277-.047.388-.142c.27-.232 6.612-5.732 6.612-11.06C17 4.05 13.86 1 10 1zm0 10.5c-2.144 0-3.889-1.682-3.889-3.75S7.856 4 10 4c2.144 0 3.889 1.682 3.889 3.75S12.144 11.5 10 11.5z" />
    </svg>
);

export default LegalIc;
