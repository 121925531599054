/* eslint-disable max-len */
import React from 'react';


const UserWithCheckMark = () => (
    <svg viewBox="0 0 208 208">
        <rect x="40" y="40" width="128" height="128" rx="64" fill="#F9FAFB" />
        <rect x="40" y="40" width="128" height="128" rx="64" fill="#F5F7F9" />
        <rect x="40.6386" y="40.6386" width="126.723" height="126.723" rx="63.3614" fill="#F5F7F9" stroke="#F5F7F9" strokeWidth="1.27723" />
        <path d="M126.597 85.2883C126.597 72.9809 116.489 63 104.015 63C91.5472 63 81.4393 72.9809 81.4393 85.2883C81.4393 97.5987 91.5472 107.577 104.015 107.577C116.489 107.577 126.597 97.5987 126.597 85.2883Z" fill="#B4BFCE" />
        <path d="M71.3763 148.125C80.1649 155.528 91.5592 160 104 160C116.444 160 127.841 155.528 136.627 148.122C138.771 146.319 140 143.682 140 140.905C140 128.423 129.805 118.434 117.154 118.434H90.8816C78.2339 118.434 68 128.423 68 140.905C68 143.679 69.2354 146.322 71.3763 148.125Z" fill="#B4BFCE" />
        <rect x="143" y="141.857" width="18" height="19.2857" rx="3" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M152 166.4C159.953 166.4 166.4 159.953 166.4 152C166.4 144.047 159.953 137.6 152 137.6C144.047 137.6 137.6 144.047 137.6 152C137.6 159.953 144.047 166.4 152 166.4ZM152.293 156.152L158.514 149.932C159.14 149.308 159.14 148.293 158.514 147.669C157.89 147.044 156.876 147.044 156.252 147.669L151.728 152.192C151.101 152.818 150.085 152.818 149.46 152.192L148.33 151.063C147.706 150.439 146.693 150.439 146.068 151.063C145.444 151.688 145.444 152.701 146.068 153.325L148.895 156.152C149.834 157.09 151.354 157.09 152.293 156.152Z" fill="#419D4E" />
    </svg>

);

export default UserWithCheckMark;
