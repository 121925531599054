/* eslint-disable react/no-unused-prop-types */
import { PATIENT_PORTAL_TEST_ID } from 'constants/dataTestId';

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import cn from 'classnames';
import { isEqual, isEmpty, escapeRegExp, isFunction, debounce, isString } from 'lodash';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { KEY_CODES, searchFields } from '@flowhealth/constants';
import {
    safeGet,
    humanName,
    isArray,
    getArray,
    isFullArray,
    isObject,
} from '@flowhealth/utils';

import FlowIcon from 'components/FlowIcon';
import { ICONS_TYPES } from 'components/FlowIcon/constants';
import { selectValueSet } from 'components/ValueSet/selector';
import { getValuesRequest } from 'components/ValueSet/actions';
import FlowDialog, { DIALOG_WIDTH } from 'components/FlowDialog';
import { openFlowDialog, closeFlowDialog } from 'components/FlowDialog/actions';
import { makeSelectDialogOpen } from 'components/FlowDialog/selector';
import Loading from 'components/Loading';

import {
    SELECT_INPUT_DELAY,
    SELECT_TYPES,
    SELECT_BOOLEAN_ITEMS,
    SELECT_SCROLL_FIRE_ON_PERCENT,
    SELECT_LIST_X_POSITION_START,
    SELECT_LIST_DEFAULT_MAX_WIDTH,
    SELECT_INPUT_DEFAULT_PLACEHOLDER,
    SELECT_INPUT_DEFAULT_WIDTH,
    SELECT_NO_INPUT_DEFAULT_PLACEHOLDER,
    LIST_TOP_POSITION_OFFSET,
    elemBoundingRectKeys,
    SELECT_UNDEFINED_FILTER_ITEM,
    LIST_TOP_POSITION_OFFSET_PATIENT_PORTAL,
} from './constants';
import {
    selectFlowSelectData,
    selectFlowSelectLoading,
    selectFlowSelectAddGraphLoading,
    selectFlowSelectMaxItems,
    selectFlowSelectMissingValue,
    selectFlowSelectMissingValueLoading,
    selectFlowSelectMeta,
} from './selector';
import {
    selectSearchRequest,
    selectEdgeRequest,
    selectAddGraphRequest,
    selectGetMissingValueRequest,
    selectClear, selectSearchSuccess,
} from './actions';
import { getCreateFormDialogId, getViewFormDialogId } from './utils';
import styles from './styles';
import ViewFormWrapper from './components/ViewFormWrapper';
import List from './components/List';
import Input from './components/Input';


export class FlowSelect extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        className: PropTypes.string,
        id: PropTypes.string.isRequired,
        selectType: PropTypes.oneOf(Object.values(SELECT_TYPES)),
        listPositionStart: PropTypes.oneOf(Object.values(SELECT_LIST_X_POSITION_START)),

        input: PropTypes.object, // ReduxForm shape
        meta: PropTypes.object, // ReduxForm shape
        syncErrors: PropTypes.object, // ReduxForm shape
        initial_value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]), // ReduxForm prop

        rawData: PropTypes.arrayOf(PropTypes.shape({
            code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            display: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })),
        valueSet: PropTypes.arrayOf(PropTypes.shape({
            code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            display: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })),
        data: PropTypes.arrayOf(PropTypes.object),
        missingValue: PropTypes.object,
        maxItems: PropTypes.number,
        count: PropTypes.number,
        loading: PropTypes.bool,
        loadingInput: PropTypes.bool,
        missingValueLoading: PropTypes.bool,
        requestMeta: PropTypes.object,

        // Props for API interaction
        file: PropTypes.string,
        object: PropTypes.string,
        q: PropTypes.string,
        fields: PropTypes.string,
        filters: PropTypes.string,
        sort: PropTypes.string,
        expand: PropTypes.string,
        graphId: PropTypes.string,
        edge: PropTypes.string,

        showInput: PropTypes.bool,
        showInputPlaceholder: PropTypes.bool,
        showInputInDropDown: PropTypes.bool,
        clearable: PropTypes.bool,
        autoFocus: PropTypes.bool,
        autoSelect: PropTypes.bool,
        showClearableInPreview: PropTypes.bool,
        multi: PropTypes.bool,
        preventClickOnSelected: PropTypes.bool,
        showChips: PropTypes.bool,
        sortChips: PropTypes.func,
        showInputText: PropTypes.bool,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        preview: PropTypes.bool,
        label: PropTypes.string,
        listLabel: PropTypes.string,
        hint: PropTypes.string,
        inputPlaceholder: PropTypes.string,
        listMaxWidth: PropTypes.number,
        listMinWidth: PropTypes.number,
        noItemsNode: PropTypes.node,
        emptySearch: PropTypes.bool,
        hidden: PropTypes.bool,

        itemText: PropTypes.func,
        itemComponent: PropTypes.func,
        inputText: PropTypes.func,
        itemSubtext: PropTypes.func,
        itemHide: PropTypes.func,
        itemsSort: PropTypes.func,
        itemsFilter: PropTypes.func,
        itemDisabled: PropTypes.func,
        chipTooltip: PropTypes.func,

        addGraphToEdge: PropTypes.bool,
        addGraphToEdgeOnSuccess: PropTypes.func,
        addGraphToEdgeSuccessMessage: PropTypes.string,
        component: PropTypes.element,
        createForm: PropTypes.element,
        viewForm: PropTypes.func,
        isCreateDialogOpen: PropTypes.bool,
        isViewDialogOpen: PropTypes.bool,
        firstItem: PropTypes.object,

        onListContainerRefAssigned: PropTypes.func,
        fieldError: PropTypes.string,
        colorIndicator: PropTypes.bool,
        noResultSearch: PropTypes.func,
        showEmptyItem: PropTypes.bool,

        createFormDialogWidth: PropTypes.oneOf(Object.values(DIALOG_WIDTH)),

        // e2e test
        dataTestId: PropTypes.string,
    };

    static defaultProps = {
        selectType: SELECT_TYPES.raw,
        listPositionStart: SELECT_LIST_X_POSITION_START.left,
        showInput: true,
        showChips: true,
        showInputText: true,
        inputPreviewClass: true,
        showInputPlaceholder: true,
        clearable: true,
        autoFocus: false,
        autoSelect: false,
        showClearableInPreview: true,
        emptySearch: true,
        hidden: false,
        showEmptyItem: false,
        count: 25,

        buttonProps: {},
        input: {},
        meta: {},
        syncErrors: {},

        noItemsNode: 'There are no options',
        colorIndicator: true,
        createFormDialogWidth: DIALOG_WIDTH.big,
    };

    constructor(props) {
        super(props);

        this.state = {
            listOpened: false,
            inputValue: '',
            viewItem: null,
            listAnchorBounds: {},
            listContainerHeight: 0,
            preSelectedId: null,
            isFocused: false,
            inputUnderlineText: '',
        };
        this.forceUpdateListOnNextOpen = {};

        this.handleFocus = this.handleFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleOpenList = this.handleOpenList.bind(this);
        this.handleCloseList = this.handleCloseList.bind(this);
        this.handleClearAll = this.handleClearAll.bind(this);
        this.handleClearItem = this.handleClearItem.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleListScroll = this.handleListScroll.bind(this);
        this.handleViewClick = this.handleViewClick.bind(this);
        this.getListItemText = this.getListItemText.bind(this);
        this.addGraphToEdge = this.addGraphToEdge.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.openCreateDialog = this.openCreateDialog.bind(this);
        this.getIdBasedOnSelectId = this.getIdBasedOnSelectId.bind(this);
        this.getItems = this.getItems.bind(this);
        this.fetchMultiChips = this.fetchMultiChips.bind(this);
        this.focusInput = this.focusInput.bind(this);

        this.debouncedSearch = debounce(
            (...agrs) => props.dispatch(selectSearchRequest(...agrs)),
            SELECT_INPUT_DELAY,
        );
    }

    componentDidMount() {
        const {
            dispatch,
            id,

            meta,
            initial_value,
            selectType,

            file,
            expand,

            object,
            filters,
        } = this.props;
        const isSearch = selectType === SELECT_TYPES.search;
        const isEdge = selectType === SELECT_TYPES.edge;

        if (file && selectType === SELECT_TYPES.valueSet) {
            dispatch(getValuesRequest({ file }));
        }

        if (
            (isSearch || isEdge)
            && (
                initial_value
                && (
                    isString(initial_value)
                    || (isArray(initial_value) && initial_value.filter(isString).length)
                )
            )
        ) {
            dispatch(selectGetMissingValueRequest({
                selectId: id,
                graphId: initial_value,
                expand,
                form: meta.form,
                object,
                filters,
            }));
        }

        if (this.listAnchor) {
            const currentBounds = elemBoundingRectKeys.reduce((memo, key) => ({
                ...memo,
                [key]: this.listAnchor.getBoundingClientRect()[key],
            }), {});

            this.setState({ listAnchorBounds: currentBounds });
        }

        if (this.app) this.app.addEventListener('scroll', this.handleCloseList, false);
        else document.addEventListener('scroll', this.handleCloseList, false);
        if (this.app) this.app.addEventListener('click', this.handleClickOutside, false);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            dispatch,
            selectType,
            input,
            meta,
            id,

            file,

            object,
            filters,
            emptySearch,
            autoSelect,
            data,
            initial_value,
            expand,
            interlinearText,
        } = this.props;
        const { listOpened, inputValue } = this.state;
        const { inputValue: prevInputValue } = prevState;
        const currentBounds = elemBoundingRectKeys.reduce((memo, key) => ({
            ...memo,
            [key]: this.listAnchor.getBoundingClientRect()[key],
        }), {});
        const valueMutable = input.value && typeof input.value.toJS === 'function' ? input.value.toJS() : input.value;
        const isSearch = selectType === SELECT_TYPES.search;
        const isEdge = selectType === SELECT_TYPES.edge;

        if (
            selectType !== SELECT_TYPES.search && selectType !== SELECT_TYPES.edge
            && !isEqual(valueMutable, this.value)
        ) {
            // put an array instead of string, to always have the array type in store
            dispatch(change(meta.form, id, this.value));
        }

        // there is no way to request single npi entity, so we need to store npi like this
        if (valueMutable && typeof valueMutable === 'string' && object === 'npi_entity') {
            dispatch(change(meta.form, id, [{ npi: valueMutable }]));
        }

        if (
            (isSearch || isEdge)
            && (
                initial_value
                && (
                    isString(initial_value)
                    || (isArray(initial_value) && initial_value.filter(isString).length)
                )
            )
            && !isEqual(prevProps.initial_value, initial_value)
        ) {
            dispatch(selectGetMissingValueRequest({
                selectId: id,
                graphId: initial_value,
                expand,
                form: meta.form,
                object,
                filters,
            }));
        }

        if (selectType === SELECT_TYPES.valueSet && prevProps.file !== file) {
            dispatch(getValuesRequest({ file }));
        }

        if (!isEqual(prevState.listAnchorBounds, currentBounds)) {
            this.setState({ listAnchorBounds: currentBounds });
        }

        const listContainerRefHeight = safeGet(this.listContainerRef, 'offsetHeight');

        if (listOpened && this.getItems(prevProps, prevState).length !== this.getItems().length) {
            this.setState({ listContainerHeight: listContainerRefHeight });
        }

        if (selectType === SELECT_TYPES.search && !emptySearch && prevInputValue && !inputValue) {
            dispatch(selectSearchSuccess({
                selectId: id,
                data: [],
                maxItems: 0,
            }));
        }

        if (interlinearText && isFullArray(valueMutable)) {
            this.setState({ inputUnderlineText: interlinearText(valueMutable[0]) });
        }

        if (autoSelect
            && isArray(data)
            && data.length === 1
            && (!isArray(prevProps.data) || prevProps.data.length !== 1)) {
            this.selectItem(data[0]);
        }
    }

    componentWillUnmount() {
        const { dispatch, id, selectType, data } = this.props;

        if ((selectType === SELECT_TYPES.search || selectType === SELECT_TYPES.edge) && data) {
            dispatch(selectClear({ selectId: id }));
        }

        if (this.app) this.app.removeEventListener('scroll', this.handleCloseList, false);
        else document.removeEventListener('scroll', this.handleCloseList, false);
        if (this.app) this.app.removeEventListener('click', this.handleClickOutside, false);
    }

    static SELECT_TYPES = SELECT_TYPES;

    static SELECT_LIST_X_POSITION_START = SELECT_LIST_X_POSITION_START;

    listAnchor = null;

    listContainerRef = null;

    listRef = null;

    inputRef = null;

    app = document.querySelector('.c-app');

    handleFocus() {
        this.setState({ isFocused: true });
        this.handleOpenList();
    }

    handleChange(e, callback) {
        const {
            input,
            multi,

            id,
            selectType,
            object,
            fields,
            filters,
            sort,
            expand,
            emptySearch,
            noResultSearch,
            fullMatchIf,
            fullMatchBy,
        } = this.props;
        const { value } = e.target;

        if (selectType === SELECT_TYPES.search) {
            const params = {
                object,
                filters,
                sort,
                expand,
                fields: fields || searchFields[object],
            };

            if (isFunction(fullMatchIf) && fullMatchIf(value)) {
                params.filters = `${filters ? `(${filters})"AND"` : ''}${fullMatchBy}:${value}`;
            } else if (value) {
                params.q = value;
            }

            (emptySearch || (isFunction(fullMatchIf) && fullMatchIf(value)))
                ? this.debouncedSearch({ selectId: id, params, noResultSearch })
                : params.q && this.debouncedSearch({ selectId: id, params, noResultSearch });
        }

        this.setState({ inputValue: value, cleared: value === '' }, callback);

        if (!multi && typeof value === 'string' && !value && input?.onChange) { // empty string
            input.onChange('');
        }
    }

    handleKeyDown(e) {
        const { keyCode } = e;
        const { item } = this.suggestion;
        const { preSelectedId } = this.state;
        const { itemDisabled } = this.props;
        const listHeight = safeGet(this.listRef, 'clientHeight');
        const listScrollTop = safeGet(this.listRef, 'scrollTop');
        const itemKey = object => (object?.id || object?.code || object?.npi || object?.name);
        const items = this.getItems();
        const lastIndex = items.length - 1;
        const preSelectedIndex = items.findIndex(v => itemKey(v) === preSelectedId);
        const preSelectedObject = preSelectedIndex !== -1 ? items[preSelectedIndex] : null;
        const dataToSelect = preSelectedObject || item;

        if (keyCode === KEY_CODES.KEY_CODE_DOWN_ARROW && items.length > 1) {
            const resultIndex = preSelectedIndex === lastIndex || preSelectedIndex === -1 ? 0 : preSelectedIndex + 1;
            this.setState({ preSelectedId: itemKey(items[resultIndex]) }, () => {
                const resultElementId = this.getIdBasedOnSelectId(`item-${resultIndex}`);
                const resultElementTop = safeGet(document.getElementById(resultElementId), 'offsetTop');
                const resultElementHeight = safeGet(document.getElementById(resultElementId), 'offsetHeight');

                if (resultIndex === 0 || resultElementTop + resultElementHeight >= listHeight + listScrollTop) {
                    if (this.listRef && typeof this.listRef.scrollTo === 'function') {
                        this.listRef.scrollTo({ top: resultElementTop });
                    }
                }
            });
        }

        if (keyCode === KEY_CODES.KEY_CODE_UP_ARROW && items.length > 1) {
            const resultIndex = !preSelectedIndex || preSelectedIndex === -1 ? lastIndex : preSelectedIndex - 1;
            this.setState({ preSelectedId: itemKey(items[resultIndex]) }, () => {
                const resultElementId = this.getIdBasedOnSelectId(`item-${resultIndex}`);
                const resultElementTop = safeGet(document.getElementById(resultElementId), 'offsetTop');

                if (resultIndex === lastIndex || resultElementTop <= listScrollTop) {
                    this.listRef.scrollTo({ top: resultElementTop });
                }
            });
        }

        if (keyCode === KEY_CODES.KEY_CODE_ENTER && !dataToSelect) {
            this.handleCloseList();
        }

        if ((keyCode === KEY_CODES.KEY_CODE_ENTER || keyCode === KEY_CODES.KEY_CODE_TAB) && dataToSelect) {
            const selected = this.value && this.value.length > 0
                && typeof this.value.findIndex === 'function'
                && this.value
                    .findIndex(v => itemKey(v) === itemKey(dataToSelect)) !== -1;
            const disabled = dataToSelect.disabled || (itemDisabled && itemDisabled(dataToSelect));

            if (!selected && !disabled) {
                this.selectHandler(dataToSelect);
            }

            this.handleCloseList();
        } else if (keyCode === KEY_CODES.KEY_CODE_TAB) {
            this.handleCloseList();
        }

        if (keyCode === KEY_CODES.KEY_CODE_ENTER) {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    handleOpenList(toggle) {
        const {
            dispatch,

            data,
            loading,
            requestMeta,

            id,
            selectType,

            object,
            q,
            fields,
            filters,
            sort,
            expand,
            count,
            graphId,
            edge,
            input,
            emptySearch,
        } = this.props;
        const { listOpened, inputValue } = this.state;
        const { onFocus } = input;

        const currentSearchApiProps = { object, filters, sort, expand, q: inputValue || q };
        const currentEdgeApiProps = { graphId, edge, sort, expand };

        if (this.app) this.app.addEventListener('click', this.handleClickOutside, false);
        else document.addEventListener('click', this.handleClickOutside, false);

        if (toggle) {
            this.setState({ listOpened: !listOpened });
            !listOpened && (typeof onFocus === 'function') && onFocus();
        } else {
            this.setState({ listOpened: true });
            (typeof onFocus === 'function') && onFocus();
        }

        if (
            this.forceUpdateListOnNextOpen[selectType]
            || (!loading && selectType === SELECT_TYPES.search && (!data || !isEqual(currentSearchApiProps, requestMeta))) // eslint-disable-line
        ) {
            const params = {
                object,
                filters,
                sort,
                expand,
                count,
                q: inputValue || q,
                fields: fields || searchFields[object],
            };
            emptySearch
                ? dispatch(selectSearchRequest({ selectId: id, params }))
                : params.q && dispatch(selectSearchRequest({ selectId: id, params }));
            this.forceUpdateListOnNextOpen[selectType] = false;
        }

        if (
            this.forceUpdateListOnNextOpen[selectType]
            || (!loading && selectType === SELECT_TYPES.edge && (!data || !isEqual(currentEdgeApiProps, requestMeta)))
        ) {
            const params = {
                sort,
                expand,
            };
            dispatch(selectEdgeRequest({ selectId: id, graphId, edge, params }));
            this.forceUpdateListOnNextOpen[selectType] = false;
        }
    }

    handleCloseList() {
        const { listOpened } = this.state;

        if (listOpened) {
            const { input } = this.props;
            const { onBlur } = input;

            this.setState({ listOpened: false, inputValue: '', preSelectedId: null });
            if (typeof onBlur === 'function') onBlur();
            if (this.app) this.app.removeEventListener('click', this.handleClickOutside, false);
            else document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(e) {
        const { isFocused } = this.state;
        const clickedOnListChild = this.listContainerRef && this.listContainerRef.contains(e.target);
        const clickedOnInput = document.getElementById(this.getIdBasedOnSelectId('inputContainer'))?.contains(e.target);
        const clickedOnInputComponents = clickedOnInput || clickedOnListChild;

        if (!isFocused && !clickedOnInputComponents) this.handleCloseList();
    }

    selectItem(item, selected) {
        const { input, multi, selectType, preventClickOnSelected = true } = this.props;
        if (selected) {
            if (preventClickOnSelected) return;

            this.handleClearItem(undefined, item);
            return;
        }

        if (!input.onChange) return;
        if (multi && selectType !== SELECT_TYPES.boolean) {
            const value = Array.isArray(this.value) ? [...this.value, item] : [item];
            input.onChange(value);
        } else {
            input.onChange([item]);
            this.handleCloseList();
        }

        this.setState({ inputValue: '', cleared: false });
    }

    addGraphToEdge(item) {
        const {
            id,
            dispatch,
            input,

            graphId,
            edge,
            addGraphToEdgeSuccessMessage,
            addGraphToEdgeOnSuccess,
        } = this.props;

        dispatch(selectAddGraphRequest({
            selectId: id,
            graphId,
            edge,
            edgeId: item.id,
            successMessage: addGraphToEdgeSuccessMessage,
            onSuccess: addGraphToEdgeOnSuccess,
        }));
        this.handleCloseList();

        if (typeof input.onChange === 'function') input.onChange(item);
    }

    get selectHandler() {
        return this.props.addGraphToEdge ? this.addGraphToEdge : this.selectItem;
    }

    focusInput() {
        this.inputRef && this.inputRef.focus && this.inputRef.focus();
    }

    handleClearAll(e) {
        const { input, autoFocus } = this.props;

        e && e?.stopPropagation();
        autoFocus
            ? this.handleChange({ target: { value: '' } }, this.focusInput)
            : this.handleChange({ target: { value: '' } });

        if (isFunction(input.onChange)) input.onChange('');

        this.setState({ touched: true });
    }

    handleClearItem(event, item) {
        const { input } = this.props;
        if (isArray(this.value)) {
            event && event?.stopPropagation();
            const valueFiltered = this.value.filter(v => v?.id !== item?.id || v?.code !== item?.code);
            input.onChange(valueFiltered?.length ? valueFiltered : '');
        }
    }

    handleListScroll(e) {
        const {
            dispatch,
            id,
            selectType,

            maxItems,
            count,
            loading,
            data,

            object,
            fields,
            filters,
            q,
            sort,
            expand,

            graphId,
            edge,
        } = this.props;
        const isSearch = selectType === SELECT_TYPES.search;
        const isEdge = selectType === SELECT_TYPES.edge;

        if (!isSearch && !isEdge) {
            return null;
        }

        const { inputValue } = this.state;
        const current = data.length;
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const scrollPercentPosition = scrollTop / (scrollHeight - clientHeight) * 100;

        if (scrollPercentPosition >= SELECT_SCROLL_FIRE_ON_PERCENT && !loading && current < maxItems) {
            const after = (current / count) * count;

            if (isSearch) {
                const params = {
                    object,
                    filters,
                    fields: fields || searchFields[object],
                    sort,
                    expand,
                    count,
                    after,
                    q,
                };

                if (inputValue) params.q = inputValue;

                dispatch(selectSearchRequest({ selectId: id, params }));
            }

            if (isEdge) {
                const params = {
                    sort,
                    expand,
                    count,
                    after,
                };
                dispatch(selectEdgeRequest({ selectId: id, graphId, edge, params }));
            }
        }
    }

    getListItemText(item) {
        const { itemText, emptyItemText } = this.props;
        if (!item) return '';

        if ((item?.code || item) === SELECT_UNDEFINED_FILTER_ITEM.code) {
            const emptyItem = emptyItemText || SELECT_UNDEFINED_FILTER_ITEM;

            return emptyItem.display;
        }

        if (isFunction(itemText)) {
            const result = itemText(item);
            if (result) return `${result}`;
        }

        const result = isObject(item.name) ? humanName(item.name) : (item.name || item.display);
        return result ? `${result}` : '';
    }

    openCreateDialog() {
        const { dispatch, id } = this.props;

        this.setState({ dialogInitialValue: this.inputValue });
        dispatch(openFlowDialog({ id: this.createFormDialogId, data: { [id]: this.inputValue } }));
        this.handleCloseList();
    }

    handleViewClick(e, item) {
        const { dispatch } = this.props;

        e && e.stopPropagation();
        this.handleCloseList();
        this.setState({ viewItem: item });
        dispatch(openFlowDialog({ id: this.viewFormDialogId }));
    }

    getIdBasedOnSelectId(id) {
        return `${this.props.id}-${id}`; // needed for tests
    }

    getItems(props, state) {
        const {
            rawData, valueSet, data,
            selectType, itemHide, firstItem, secondItem,
            itemsSort, itemsFilter, filter_by: filterBy,
            showEmptyItem, searchByCodeAvailable, emptyItemText,
        } = props || this.props;
        const { inputValue } = state || this.state;
        const isSearch = selectType === SELECT_TYPES.search;
        let dataToUse = [];

        if (selectType === SELECT_TYPES.raw && rawData) {
            if (typeof itemsFilter === 'function' && isArray(rawData)) {
                dataToUse = rawData.filter(itemsFilter);
            } else {
                dataToUse = rawData;
            }
        }
        if (selectType === SELECT_TYPES.boolean) dataToUse = SELECT_BOOLEAN_ITEMS;
        if (selectType === SELECT_TYPES.valueSet && valueSet) {
            if (typeof itemsFilter === 'function' && isArray(valueSet)) {
                dataToUse = valueSet.filter(itemsFilter);
            } else {
                dataToUse = valueSet;
            }
        }
        if (selectType === SELECT_TYPES.edge && data) {
            dataToUse = typeof itemsFilter === 'function' && isArray(data)
                ? data.filter(itemsFilter)
                : data;
        }
        if (isSearch && data) {
            dataToUse = typeof itemsFilter === 'function' && isArray(data)
                ? data.filter(itemsFilter)
                : data;
        }

        if (!isSearch && inputValue && isArray(dataToUse)) {
            dataToUse = dataToUse.filter(item => {
                const text = (this.getListItemText(item) || '').toLowerCase();

                if (searchByCodeAvailable) {
                    const codeText = item?.code?.toLowerCase() || '';
                    return text.includes(inputValue.toLowerCase()) || codeText.includes(inputValue.toLowerCase());
                }

                return text.includes(inputValue.toLowerCase());
            });
        }

        if (secondItem && isFullArray(dataToUse) && dataToUse[1]?.id !== secondItem?.id && !isEmpty(firstItem)) {
            dataToUse = dataToUse.filter(item => item?.id !== secondItem?.id);
            dataToUse.unshift(secondItem);
        }

        if (firstItem && isFullArray(dataToUse) && dataToUse[0]?.id !== firstItem?.id) {
            dataToUse = dataToUse.filter(item => item?.id !== firstItem?.id);
            dataToUse.unshift(firstItem);
        }

        if (filterBy && !inputValue && showEmptyItem) {
            const emptyItem = emptyItemText || SELECT_UNDEFINED_FILTER_ITEM;
            dataToUse = [emptyItem, ...getArray(dataToUse)];
        }

        return getArray(dataToUse)
            .filter(item => {
                const hide = typeof itemHide === 'function' && itemHide(item);
                return !((item && item.hide) || hide);
            })
            .filter(i => !isEmpty(i))
            .sort(itemsSort);
    }

    get listContainerPosition() {
        const { listPositionStart, isPatientPortal } = this.props;
        const { listAnchorBounds, listContainerHeight } = this.state;
        const { scrollTop } = document.documentElement;

        let top = scrollTop + listAnchorBounds.top + listAnchorBounds.height + LIST_TOP_POSITION_OFFSET;

        if (top + listContainerHeight > (scrollTop + window.innerHeight)) {
            top = scrollTop + (listAnchorBounds.top - LIST_TOP_POSITION_OFFSET - listContainerHeight);
        }
        if (isPatientPortal) top -= LIST_TOP_POSITION_OFFSET_PATIENT_PORTAL;

        return {
            [listPositionStart]: listPositionStart === SELECT_LIST_X_POSITION_START.right
                ? window.innerWidth - listAnchorBounds.right
                : listAnchorBounds.left,
            top,
        };
    }

    get value() {
        const {
            selectType,

            missingValue,
            input,
            initial_value,

            valueSet,
        } = this.props;
        const { value } = input;
        const isSearch = selectType === SELECT_TYPES.search;
        const isEdge = selectType === SELECT_TYPES.edge;
        const valueMutable = value && typeof value.toJS === 'function' ? value.toJS() : value;
        const isValueString = valueMutable && typeof valueMutable === 'string';
        const isInitValueString = initial_value && typeof initial_value === 'string';
        if (selectType === SELECT_TYPES.valueSet && isArray(valueSet) && !isEmpty(valueSet)) {
            if (isValueString) {
                return valueSet.filter(vs => vs.code === valueMutable);
            }

            if (!valueMutable && isInitValueString && !this.state.cleared) {
                return valueSet.filter(vs => vs.code === initial_value);
            }

            if (isArray(valueMutable) && valueMutable.some(v => typeof v === 'string')) {
                return valueSet.filter(vs => valueMutable.find(vItem => vItem === vs.code || vItem.code === vs.code));
            }

            if (!valueMutable && isArray(initial_value) && initial_value.some(v => typeof v === 'string')) {
                return valueSet.filter(vs => initial_value.find(vItem => vItem === vs.code || vItem.code === vs.code));
            }
        }

        return (isSearch || isEdge) && isValueString && isInitValueString
            ? [missingValue]
            : valueMutable;
    }

    get inputValue() {
        const { multi, selectType, inputText, showInputText } = this.props;
        const { inputValue, cleared, listOpened } = this.state;

        if (multi && selectType !== SELECT_TYPES.boolean) {
            return inputValue;
        }

        const data = (inputText && inputText(this.value && this.value[0]))
            || this.getListItemText(this.value && this.value[0])
            || '';
        const dataString = data && `${data}`;

        if (cleared && listOpened) return inputValue;

        if (!listOpened && !cleared) return (showInputText ? dataString : '');

        return inputValue || (showInputText ? dataString : '');
    }

    get noInputValue() {
        const { inputPlaceholder } = this.props;

        if (this.value && this.value.length > 0) {
            return this.inputValue;
        }

        return inputPlaceholder || SELECT_NO_INPUT_DEFAULT_PLACEHOLDER;
    }

    get showInput() {
        const { showInput, selectType, showInputInDropDown } = this.props;
        return selectType !== SELECT_TYPES.boolean && showInput && !showInputInDropDown;
    }

    get createFormDialogId() {
        return getCreateFormDialogId(this.props.id);
    }

    get viewFormDialogId() {
        return getViewFormDialogId(this.props.id);
    }

    get inputWidth() {
        if (!document.body) return null;
        const dummy = document.createElement('span');
        dummy.innerHTML = this.inputValue
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
        document.body.appendChild(dummy);
        const dummyWidth = dummy.getBoundingClientRect().width;
        document.body.removeChild(dummy);

        if (!this.suggestion.text || !dummyWidth) {
            return SELECT_INPUT_DEFAULT_WIDTH;
        }

        return Math.floor(dummyWidth);
    }

    get suggestion() {
        const suggestion = this.getItems().find(item => {
            const itemText = this.getListItemText(item);

            if (!itemText || !this.inputValue) return;

            const itemTextLow = itemText.toLowerCase();
            const inputValueLow = this.inputValue.toLowerCase();

            return itemTextLow.startsWith(inputValueLow);
        });
        const suggestionDisplayText = this.getListItemText(suggestion);

        if (!this.inputValue || !suggestionDisplayText) return '';

        const regExp = new RegExp(escapeRegExp(this.inputValue), 'ig');
        return {
            text: suggestionDisplayText.replace(regExp, ''),
            item: suggestion,
        };
    }

    get hasFormValue() {
        return this.value && !!this.value.length;
    }

    get multiChips() {
        const { selectType, showChips, multi, sortChips } = this.props;
        if (selectType === SELECT_TYPES.boolean || !showChips || !multi || !this.hasFormValue) return null;
        const chips = isFunction(sortChips) ? sortChips(this.value) : this.value;
        return isArray(chips) ? chips.map(this.fetchMultiChips) : [chips].map(this.fetchMultiChips);
    }

    fetchMultiChips(item, key) {
        const {
            classes = {},
            chipClassName,
            chipTooltip,
            inputText,
            showClearableInPreview,
            disabled,
            clearable = true,
        } = this.props;
        const { listOpened } = this.state;
        const previewChipClearable = !showClearableInPreview ? listOpened : true;
        const component = (
            <div
                // needed for tests
                id={this.getIdBasedOnSelectId(`chip-${key}`)}
                key={key}
                className={cn(classes.chip, chipClassName)}
            >
                <span className={classes.chipText}>
                    {(inputText && inputText(item)) || this.getListItemText(item)}
                </span>
                {!disabled && clearable && previewChipClearable && (
                    <FlowIcon
                        // needed for tests
                        id={this.getIdBasedOnSelectId(`chipDelete-${key}`)}
                        className={classes.chipDeleteIcon}
                        type={ICONS_TYPES.close}
                        size={14}
                        disabled={disabled}
                        onClick={e => this.handleClearItem(e, item)}
                    />
                )}
            </div>
        );
        if (!chipTooltip || listOpened) return component;
        return (
            <Tooltip
                title={chipTooltip && chipTooltip(item)}
                placement="bottom"
                key={key}
            >
                {component}
            </Tooltip>
        );
    }

    render() {
        const {
            dispatch,
            className,

            classes,
            id,

            meta,
            syncErrors,
            required,
            disabled,
            preview,
            label,
            hint,
            listLabel,
            multi,

            showInputInDropDown,
            inputPreviewClass,

            itemDisabled,

            loading,
            loadingInput,
            missingValueLoading,
            addGraphLoading,
            componentLoading,
            itemSubtext,
            inputPlaceholder,
            showInputPlaceholder,
            clearable = true,
            showClearableInPreview,
            selectType,
            maxItems,
            listMaxWidth,
            listMinWidth,

            addGraphToEdge,
            component,
            createForm,
            viewForm,

            onListContainerRefAssigned,
            noItemsNode,
            fieldError,
            colorIndicator,
            autoFocus,
            hidden,
            itemComponent,

            createFormDialogWidth,

            dataTestId = PATIENT_PORTAL_TEST_ID,
            noLabelMargin,
            inputClassName,
            invalid,
        } = this.props;
        const {
            listOpened,
            isFocused,
            viewItem,
            listAnchorBounds,
            preSelectedId,
            dialogInitialValue,
            cleared,
            inputUnderlineText,
        } = this.state;
        const { error, touched } = meta;
        const errorString = syncErrors[id] || error || fieldError;
        const isTouched = touched || this.state.touched;
        const showError = ((errorString && isTouched) || fieldError || invalid) && !isFocused;
        const toggleOpenList = () => this.handleOpenList(true);
        const placeholder = showInputPlaceholder ? (inputPlaceholder || SELECT_INPUT_DEFAULT_PLACEHOLDER) : undefined;
        const inputComp = (
            <div className={classes.withSuggestion}>
                <input
                    ref={input => { this.inputRef = input; }}
                    id={this.getIdBasedOnSelectId('input')} // needed for tests
                    disabled={disabled}
                    className={cn(classes.input, {
                        [classes.inputPreview]: inputPreviewClass && preview && !listOpened,
                        [classes.inputDisabled]: disabled,
                        [classes.listOpened]: listOpened,
                    })}
                    style={{ width: this.inputWidth }}
                    type="text"
                    placeholder={placeholder}
                    autoComplete="off"
                    value={this.inputValue}
                    onKeyDown={this.handleKeyDown}
                    onChange={e => this.handleChange(e)} // such usage is made for tests
                    onFocus={e => this.handleFocus(e)} // such usage is made for tests
                    onBlur={() => this.setState({ isFocused: false })}
                    autoFocus={autoFocus} // eslint-disable-line

                    data-tid={dataTestId ? `${dataTestId}-input` : null}
                />
                {this.state.inputValue && this.suggestion.text && (
                    <span
                        onClick={() => {
                            this.inputRef && this.inputRef.focus();
                            this.handleFocus();
                        }}
                        className={classes.textSuggestion}
                    >
                        {this.suggestion.text.trim()}
                    </span>
                )}
            </div>
        );
        const listMaxWidthComputed = addGraphToEdge ? (listMaxWidth || SELECT_LIST_DEFAULT_MAX_WIDTH) : listMaxWidth;

        return (
            <div
                className={cn(classes.root, className, hidden)}
                ref={component ? ref => { this.listAnchor = ref; } : undefined}
                data-tid={`${dataTestId}-select-wrapper`}
            >
                {loadingInput && <Loading />}
                {component
                    ? (
                        React.cloneElement(component, {
                            className: cn(
                                classes.addGraphToEdgeComponent,
                                component.props.className,
                                listOpened && component.props.listOpenedClassName,
                            ),
                            disabled,
                            loading: addGraphLoading || missingValueLoading || componentLoading,
                            onClick: e => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (disabled) return;
                                this.handleOpenList(false);
                            },
                        })
                    )
                    : (
                        <>
                            <span
                                id={this.getIdBasedOnSelectId('label')} // needed for tests
                                className={cn('label', classes.label, {
                                    [classes.labelError]: showError,
                                    labelError: showError,
                                    'no-margins': !label || noLabelMargin,
                                })}
                            >
                                {
                                    required
                                        ? (
                                            <span>
                                                {label} <span className={classes.requiredMark}>*</span>
                                            </span>
                                        )
                                        : label
                                }
                            </span>
                            <Input
                                getIdBasedOnSelectId={this.getIdBasedOnSelectId}

                                onRootRef={ref => { this.listAnchor = ref; }}

                                disabled={disabled}
                                preview={preview}
                                multi={multi}
                                inputClassName={inputClassName}

                                hasFormValue={this.hasFormValue}
                                listOpened={listOpened}
                                clearable={clearable}
                                inputValue={this.inputValue}
                                noInputValue={this.noInputValue}
                                multiChips={this.multiChips}

                                showError={showError}
                                showInput={this.showInput}
                                showClearableInPreview={showClearableInPreview}

                                missingValueLoading={missingValueLoading}
                                toggleOpenList={toggleOpenList}
                                // such usage is made for tests
                                handleClearAll={e => this.handleClearAll(e)}

                                inputComp={inputComp}

                                dataTestId={`${dataTestId}-inputContainer`}
                            />
                            {inputUnderlineText && (
                                <span className={classes.inputUnderlineText}>
                                    {!cleared && !listOpened && inputUnderlineText}
                                </span>
                            )}
                        </>
                    )
                }
                {hint && !showError && (
                    <p className={classes.hint}>
                        {hint}
                    </p>
                )}
                {showError && (
                    <p
                        id={this.getIdBasedOnSelectId('errorString')} // needed for tests
                        className={classes.error}
                        data-tid={`${dataTestId}-errorMessage`}
                    >
                        {errorString}
                    </p>
                )}
                {ReactDOM.createPortal(
                    this.listAnchor && listOpened && (
                        <List
                            getIdBasedOnSelectId={this.getIdBasedOnSelectId}
                            onListContainerRef={ref => {
                                this.listContainerRef = ref;

                                if (typeof onListContainerRefAssigned === 'function') {
                                    onListContainerRefAssigned(this.listContainerRef);
                                }
                            }}
                            onListRef={ref => { this.listRef = ref; }}
                            onScroll={this.handleListScroll}
                            onSelect={this.selectHandler}
                            getItems={this.getItems}
                            listContainerPosition={this.listContainerPosition}
                            listAnchorBounds={listAnchorBounds}
                            listMaxWidthComputed={listMaxWidthComputed}
                            listMinWidth={listMinWidth}
                            listLabel={listLabel}
                            maxItems={maxItems}
                            preSelectedId={preSelectedId}
                            value={this.value}
                            inputValue={this.inputValue}
                            itemDisabled={itemDisabled}
                            itemSubtext={itemSubtext}
                            getListItemText={this.getListItemText}
                            showInputInDropDown={showInputInDropDown}
                            addGraphToEdge={addGraphToEdge}
                            inputComp={inputComp}
                            itemComponent={itemComponent}
                            noItemsNode={noItemsNode}
                            loading={loading}
                            colorIndicator={colorIndicator}
                            createForm={createForm}
                            openCreateDialog={this.openCreateDialog}
                            viewForm={viewForm}
                            handleViewClick={this.handleViewClick}
                            dataTestId={dataTestId ? `${dataTestId}-list` : null}
                        />
                    ),
                    document.body,
                )}
                {createForm && (
                    <FlowDialog id={this.createFormDialogId} width={createFormDialogWidth} confirmClose>
                        {React.cloneElement(createForm, {
                            additionalFormData: { [id]: dialogInitialValue },
                            onSuccess: createdObject => {
                                this.selectHandler(createdObject);
                                dispatch(closeFlowDialog({ id: this.createFormDialogId }));
                                const { onSuccess } = createForm.props;
                                if (onSuccess) onSuccess(createdObject);
                                this.forceUpdateListOnNextOpen = {
                                    [selectType]: true,
                                };
                            },
                        })}
                    </FlowDialog>
                )}
                {viewForm && (
                    <ViewFormWrapper
                        dialogId={this.viewFormDialogId}
                        viewForm={viewForm}
                        viewItem={viewItem}
                        onSelect={this.selectHandler}
                        getIdBasedOnSelectId={this.getIdBasedOnSelectId}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    data: (state, props) => selectFlowSelectData(props.id)(state, props),
    missingValue: (state, props) => selectFlowSelectMissingValue(props.id)(state, props),
    maxItems: (state, props) => selectFlowSelectMaxItems(props.id)(state, props),
    loading: (state, props) => selectFlowSelectLoading(props.id)(state, props),
    missingValueLoading: (state, props) => selectFlowSelectMissingValueLoading(props.id)(state, props),
    addGraphLoading: (state, props) => selectFlowSelectAddGraphLoading(props.id)(state, props),
    requestMeta: (state, props) => selectFlowSelectMeta(props.id)(state, props),
    isCreateDialogOpen: (state, props) => makeSelectDialogOpen(state, { id: getCreateFormDialogId(props.id) }),
    isViewDialogOpen: (state, props) => makeSelectDialogOpen(state, { id: getViewFormDialogId(props.id) }),
    valueSet: selectValueSet(),
});

const withConnect = connect(mapStateToProps);

export default compose(
    withStyles(styles),
    withConnect,
)(FlowSelect);
