import { all, call, put, takeEvery } from 'redux-saga/effects';
import API from '@flowhealth/api';
import { isFullArray } from "@flowhealth/utils";

import { searchAll } from 'utils/searchAll';

import * as ACTIONS from './actions';
import * as ACTION_TYPES from './constants';

function* fetchEventLocations({ payload }) {
    try {
        const { id } = payload;

        const makeRequestData = index => {
            const COUNT = 100;
            return {
                count: COUNT,
                after: index * COUNT,
                expand: 'collection_location'
            };
        }
        const request = index => API.getInstance().getGraphsOnEdge(id, 'locations', makeRequestData(index));

        const results = yield call(searchAll, request);
        let eventLocations = results;
        if (isFullArray(results) && results.some(location => !location?.address)) {
            eventLocations = results.map(location => {
                let address = location?.address;
                if (!address && location?.collection_location) {
                    const { address: { city, postal_code, line, state } } = location.collection_location;
                    address = `${line.join(' ')} ${city}, ${state} ${postal_code}`;
                }
                return { ...location, address };
            });
        }
        yield put(ACTIONS.fetchEventLocationsSuccess({ eventLocations }));
    } catch (error) {
        yield put(ACTIONS.fetchEventLocationsFailure());
    }
}

function* fetchLocations() {
    try {
        const makeRequestData = index => {
            const COUNT = 100;
            return {
                count: COUNT,
                after: index * COUNT,
                object: 'collection_location',
            };
        }
        const request = index => API.getInstance().search(makeRequestData(index));
        const results = yield call(searchAll, request);
        yield put(ACTIONS.fetchLocationsSuccess({ locations: results }));
    } catch (error) {
        yield put(ACTIONS.fetchLocationsFailure({ locationsError: error }));
    }
}

export default function* Saga() {
    yield all([
        takeEvery(ACTION_TYPES.FETCH_EVENT_LOCATIONS_REQUEST, fetchEventLocations),
        takeEvery(ACTION_TYPES.FETCH_LOCATIONS_REQUEST, fetchLocations),
    ]);
}
