export default ({ palette = {} } = {}) => ({
    root: {
        height: '70px',
        boxSizing: 'border-box',
        justifyContent: 'center',
        boxShadow: `0 1px 0 0 ${palette.ash[400]}`,
        border: 0,
        position: 'relative',
    },
    inner: {
        paddingLeft: '30px',
        paddingRight: '20px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    center: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        marginRight: 24,
    },
    right: {
        marginLeft: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    search: {
        display: 'flex',
        marginLeft: 'auto',
        maxHeight: '36px',
        maxWidth: '800px',
        marginRight: '30px',
    },
    logo: { height: 30 },
    logoLink: {
        textDecoration: 'none',
        color: palette.white.main,
    },
    logoLinkDisable: {
        pointerEvents: 'none',
    },
});
