import { DEFAULT_COUNTRY } from 'containers/Auth/constants';

import { ADD_PROFILE_FORM } from '../components/AddProfile/constants';


export default {
    loading: false,
    newProfile: undefined,
    [ADD_PROFILE_FORM]: {
        tab: {
            value: 1,
            valid: false,
            touched: false,
        },
        country: {
            value: DEFAULT_COUNTRY,
            valid: true,
            touched: false,
        },
        given: {
            value: '',
            valid: false,
            touched: false,
        },
        family: {
            value: '',
            valid: false,
            touched: false,
        },
        birth_date: {
            value: '',
            valid: false,
            touched: false,
        },
        gender: {
            value: '',
            valid: false,
            touched: false,
        },
        race: {
            value: '',
            valid: false,
            touched: false,
        },
        ethnicity: {
            value: '',
            valid: false,
            touched: false,
        },
        email: {
            value: '',
            valid: false,
            touched: false,
        },
        line1: {
            value: '',
            valid: false,
            touched: false,
        },
        line2: {
            value: '',
            valid: false,
            touched: false,
        },
        zip_code: {
            value: '',
            valid: false,
            touched: false,
        },
        city: {
            value: '',
            valid: false,
            touched: false,
        },
        state: {
            value: '',
            valid: false,
            touched: false,
        },
    },
};
