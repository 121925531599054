import React from 'react';
import dayjs from 'dayjs';
import { memoize, isFunction } from 'lodash';
import cn from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { toNum, safeGet } from '@flowhealth/utils';

import { formatStringToCase } from 'utils/formatters/formatStringToCase';
import chain from 'utils/chain';
import { TIME_FORMAT_SHORT_12, TIME_FORMAT_PLACEHOLDER, TIME_SEPARATOR } from 'utils/constants';


import styles from '../styles';
import { FIELD_TYPES } from '../constants';

import Input from './Input';


const compileTime = (hour, minute, prependZero = true) => {
    let res = '';
    if (hour) res += hour.length === 1 && prependZero ? `0${hour}${TIME_SEPARATOR}` : `${hour}${TIME_SEPARATOR}`;
    if (minute) res += minute.length === 1 && prependZero ? `0${minute}` : minute;
    return res;
};

const filter = value => value.replace(/[^\d\s0-9amp:]*$/g, '');

const preValidate = value => value.slice(0, 8);

const rearrange = (hour, minute) => {
    const fsMinute = chain(minute.charAt(0), toNum);
    if (fsMinute >= 6) {
        return compileTime(hour, minute);
    } else if (minute.length > 2) {
        return compileTime(hour, minute.slice(0, 2));
    }
    return compileTime(hour) + minute;
};

const separate = value => {
    const [time = '', ampm = ''] = value.split(/[ ]+/);
    if (time.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)) {
        return ampm ? value : `${value.trim()} `;
    }
    let [hour = '', minute = ''] = time.split(TIME_SEPARATOR);
    const fsHour = chain(hour.charAt(0), toNum);
    if (fsHour > 1) {
        minute = hour.slice(1) + minute;
        hour = hour.charAt(0);
        return rearrange(hour, minute);
    } else if (hour.length > 2) {
        minute = hour.slice(2) + minute;
        hour = hour.slice(0, 2);
        return rearrange(hour, minute);
    } else if (hour.length === 2) {
        return rearrange(hour, minute);
    }
    return value;
};


export class FlowTime extends React.PureComponent {
    state = {
        inputRef: undefined, // eslint-disable-line
        error: undefined,
        suggestion: undefined,
    };

    getInputRef = inputRef => {
        const { getInputRef } = this.props;
        this.setState({ inputRef }); // eslint-disable-line
        getInputRef && getInputRef(inputRef);
    };

    suggest = value => {
        if (value === '') {
            this.setState({ suggestion: undefined });
            return value;
        }
        const [time = '', ampm = ''] = value.split(/[ ]+/);
        const [hour = '', minute = ''] = time.split(TIME_SEPARATOR);
        const [sTime = '', sAmpm = ''] = TIME_FORMAT_PLACEHOLDER.split(/[ ]+/);
        const [sHour = '', sMinute = ''] = sTime.split(TIME_SEPARATOR);
        this.setState({ suggestion: `${compileTime(hour || sHour, minute || sMinute, false)} ${ampm || sAmpm}` });
        return value;
    };

    unsuggest = value => {
        value === '' && this.setState({ suggestion: undefined });
        return value;
    };

    deleteHandlers = [this.unsuggest];

    changeHandlers = [formatStringToCase('down'), filter, separate, preValidate, this.suggest];

    normalize = value => {
        const { onNormalize } = this.props;
        let [t = '', ampm = ''] = value.split(/[ ]+/);
        const [hour = '', minute = ''] = t.split(TIME_SEPARATOR);
        const time = compileTime(hour, minute);
        let result;
        if (!ampm) {
            const numHour = toNum(hour);
            if (numHour >= 8 && numHour < 12) ampm = 'am';
            if (numHour === 12 || (numHour >= 1 && numHour < 6)) ampm = 'pm';
            result = ampm ? `${time} ${ampm}` : value;
        } else {
            const fsAmpm = ampm.charAt(0);
            if (fsAmpm === 'a') ampm = 'am';
            if (fsAmpm === 'p') ampm = 'pm';
            result = `${time} ${ampm}`;
        }
        onNormalize && onNormalize(result);
        return result;
    };

    validate = value => {
        const { required, onValidTime } = this.props;
        const [time = '', ampm = ''] = value.split(/[ ]+/);
        let isValid = dayjs(`${time} ${ampm}`, TIME_FORMAT_SHORT_12).isValid();
        const [hour = '', minute = ''] = time.split(TIME_SEPARATOR);
        if (!hour || !minute || !ampm || (ampm && ampm !== 'am' && ampm !== 'pm')) isValid = false;
        if (value === '' && required && !isValid) this.setError('Invalid Time')();
        else if (value && !isValid) this.setError('Invalid Time')();
        else this.setError()();
        onValidTime && onValidTime(isValid, FIELD_TYPES.time);
        return value;
    };

    disadvise = value => {
        this.setState({ suggestion: undefined });
        return value;
    };

    blurHandlers = [this.normalize, this.validate, this.disadvise];

    setError = memoize(error => () => {
        const { onError } = this.props;
        this.setState({ error });
        onError && onError(error, FIELD_TYPES.time);
    });

    handleFocus = event => {
        const { onFocus } = this.props;
        this.setError(undefined)();
        onFocus && onFocus(event);
    };

    handleBlur = (value, event, options) => {
        const { onBlur } = this.props;
        onBlur && onBlur(value, event, options);
    };

    handleChange = (value, event, validate = false) => {
        const { onChange } = this.props;
        validate && this.validate(value);
        isFunction(onChange) && onChange(value, event);
    };

    render() {
        const { classes = {}, className, initialValue, showError, preview, disabled, onKeyDown } = this.props;
        const { suggestion } = this.state;
        const error = safeGet(this.state, 'error', safeGet(this.props, 'error', false));
        return (
            <div className={cn('time', classes.time, className)}>
                <Input
                    placeholder={TIME_FORMAT_PLACEHOLDER}
                    changeHandlers={this.changeHandlers}
                    deleteHandlers={this.deleteHandlers}
                    blurHandlers={this.blurHandlers}
                    getInputRef={this.getInputRef}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onKeyDown={onKeyDown}
                    initialValue={initialValue}
                    showError={showError}
                    error={error}
                    suggestion={suggestion}
                    preview={preview}
                    disabled={disabled}
                />
            </div>
        );
    }
}

export default withStyles(styles)(FlowTime);
