import isNil from 'lodash/isNil';

import {
    validatePassword,
    validatePhone,
    validateDate,
    validateCode,
} from 'components/Inputs/validators';

import chain from './chain';

const convertToBoolean = (res, val) => isNil(res) && !!val;

const validate = ({ id, value, complexity }) => {
    switch (id) {
    case 'phone': return chain(value, validatePhone, convertToBoolean);
    case 'password': return chain(value, validatePassword(complexity), convertToBoolean);
    case 'birth_date': return validateDate(value);
    case 'code': return validateCode(value);
    case 'patient':
    case 'policy_holder':
    case 'given':
    case 'family':
    case 'relation':
    case 'payor':
    case 'member_id': return !!value;
    default: return true;
    }
};

export default validate;
