import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './constants';


export const userRequest = (onSuccess, shouldRequestAvatar, shouldChangeLoading = true) => ({
    type: ACTION_TYPES.USER_REQUEST,
    payload: { onSuccess, shouldRequestAvatar, shouldChangeLoading },
});
export const userSuccess = createAction(ACTION_TYPES.USER_SUCCESS);
export const userFailure = createAction(ACTION_TYPES.USER_FAILURE);

export const userAddLabToStore = createAction(ACTION_TYPES.USER_ADD_LAB_TO_STORE);

export const userPatchRequest = createAction(ACTION_TYPES.USER_PATCH_REQUEST);
export const userPatchSuccess = createAction(ACTION_TYPES.USER_PATCH_SUCCESS);
export const userPatchFailure = createAction(ACTION_TYPES.USER_PATCH_FAILURE);

export const rolePatchRequest = createAction(ACTION_TYPES.ROLE_PATCH_REQUEST);
export const rolePatchSuccess = createAction(ACTION_TYPES.ROLE_PATCH_SUCCESS);
export const rolePatchFailure = createAction(ACTION_TYPES.ROLE_PATCH_FAILURE);

export const resetUserState = createAction(ACTION_TYPES.RESET_USER_STATE);

export const setAgreementsRequest = createAction(ACTION_TYPES.SET_AGREEMENTS_REQUEST);
export const setAgreementsSuccess = createAction(ACTION_TYPES.SET_AGREEMENTS_SUCCESS);
export const setAgreementsFailure = createAction(ACTION_TYPES.SET_AGREEMENTS_FAILURE);

export const setSignatureFile = createAction(ACTION_TYPES.SET_SIGNATURE_FILE);

export const userChangeRoleRequest = createAction(ACTION_TYPES.USER_CHANGE_ROLE_REQUEST);
export const userChangeRoleSuccess = createAction(ACTION_TYPES.USER_CHANGE_ROLE_SUCCESS);
export const userChangeRoleFailure = createAction(ACTION_TYPES.USER_CHANGE_ROLE_FAILURE);

export const userUploadAvatarRequest = createAction(ACTION_TYPES.USER_UPLOAD_AVATAR_REQUEST);
export const userUploadAvatarSuccess = createAction(ACTION_TYPES.USER_UPLOAD_AVATAR_SUCCESS);
export const userUploadAvatarFailure = createAction(ACTION_TYPES.USER_UPLOAD_AVATAR_FAILURE);
