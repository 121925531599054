import { normalizePhone } from 'components/Inputs/normalizators';

const format = ({ id, value }) => {
    switch (id) {
    case 'phone': return normalizePhone(value);
    default: return value;
    }
};

export default format;
