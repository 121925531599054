import React, { memo } from 'react';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { getTimeZoneAbbr } from '@flowhealth/utils';

import FlowChip from 'components/FlowChip';
import { selectValueSet } from 'components/ValueSet';


const styles = ({ palette = {} } = {}) => ({
    label: {
        color: palette.coal.tags,
        background: palette.white.main,
        height: 38,
    },
    emptyLabel: {
        padding: '4px 25px',
    },
});

const TimeZone = ({ classes = {}, usTimeZones = [], location = {}, date }) => {
    const timeZoneAbbr = getTimeZoneAbbr(date, location, usTimeZones);
    return (
        <FlowChip
            className={cn(classes.label, { [classes.emptyLabel]: !timeZoneAbbr })}
            label={timeZoneAbbr}
        />
    );
};

TimeZone.propTypes = {
    usTimeZones: PropTypes.array,
    location: PropTypes.object,
    date: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
    usTimeZones: selectValueSet('us_time_zones'),
});

const withConnect = connect(mapStateToProps);

export default compose(
    withStyles(styles),
    withConnect,
    memo,
)(TimeZone);
