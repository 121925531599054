/* eslint-disable max-len */
import React from 'react';


const CopyLinkV2 = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18.2387 1.76128C15.8904 -0.587093 12.0827 -0.587093 9.7344 1.76128L7.5191 3.9766C7.32384 4.17187 7.32384 4.48845 7.5191 4.68371L8.22922 5.39384C8.42448 5.5891 8.74107 5.5891 8.93633 5.39384L11.1516 3.17852C12.7182 1.61194 15.254 1.61194 16.8215 3.17852C18.3841 4.7421 18.3841 7.28491 16.8215 8.84849L14.6062 11.0638C14.411 11.2591 14.411 11.5757 14.6062 11.7709L15.3163 12.481C15.5116 12.6763 15.8282 12.6763 16.0235 12.481L18.2387 10.2657C20.5871 7.91736 20.5871 4.10965 18.2387 1.76128ZM8.84838 16.8207C8.09166 17.5785 7.08437 17.9954 6.01393 17.9954C4.9435 17.9954 3.9362 17.5785 3.17948 16.8207C1.61593 15.2582 1.61593 12.7143 3.17948 11.1518L5.39478 8.93643C5.59004 8.74117 5.59004 8.42459 5.39478 8.22933L4.68366 7.5182C4.48839 7.32293 4.17181 7.32293 3.97655 7.5182L1.76126 9.73352C-0.587086 12.0819 -0.587086 15.8896 1.76126 18.239C2.93593 19.4127 4.47443 20 6.01393 20C7.55343 20 9.09193 19.4127 10.2666 18.239L12.4819 16.0236C12.6772 15.8284 12.6772 15.5118 12.4819 15.3165L11.7708 14.6054C11.5755 14.4101 11.2589 14.4101 11.0637 14.6054L8.84838 16.8207ZM11.9096 6.67327C12.1049 6.478 12.4214 6.478 12.6167 6.67327L13.3268 7.3834C13.5221 7.57866 13.5221 7.89524 13.3268 8.0905L8.07537 13.342C7.88011 13.5373 7.56352 13.5373 7.36826 13.342L6.65814 12.6319C6.46288 12.4366 6.46288 12.12 6.65814 11.9248L11.9096 6.67327Z" />
    </svg>
);

export default CopyLinkV2;
