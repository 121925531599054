import React, { memo } from 'react';
import { compose } from 'redux';

import injectSaga from 'utils/injectSaga';

import { PATIENT_PROFILES_KEY } from './store/constants';
import saga from './store/saga';
import { AddProfile, SelectPractice } from './components';
import { PROFILE_ACTIONS } from './constants';


const ProfileActions = ({ type, ...props }) => {
    if (type === PROFILE_ACTIONS.AddPatient) {
        return <AddProfile {...props} />;
    } else if (type === PROFILE_ACTIONS.SelectPractice) {
        return <SelectPractice {...props} />;
    }
    throw new Error('This type does not available');
};

const withSaga = injectSaga({ key: PATIENT_PROFILES_KEY, saga });

export default compose(
    memo,
    withSaga,
)(ProfileActions);


