import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';


export const resetState = createAction(ACTION_TYPES.RESET_STATE);

export const setLoading = createAction(ACTION_TYPES.SET_LOADING);

export const searchOrderRequest = createAction(ACTION_TYPES.SEARCH_ORDER_REQUEST);
export const searchOrderSuccess = createAction(ACTION_TYPES.SEARCH_ORDER_SUCCESS);
export const searchOrderFailure = createAction(ACTION_TYPES.SEARCH_ORDER_FAILURE);

export const patchOrderRequest = createAction(ACTION_TYPES.PATCH_ORDER_REQUEST);
export const patchOrderSuccess = createAction(ACTION_TYPES.PATCH_ORDER_SUCCESS);
export const patchOrderFailure = createAction(ACTION_TYPES.PATCH_ORDER_FAILURE);
