/* eslint-disable max-len */
import React from 'react';


const NewArrowLeft = () => (
    <svg viewBox="0 0 20 20">
        <path d="M9.43854 9.93852L12.8604 6.46031C12.9903 6.33639 13.0615 6.16852 13.0615 5.99233C13.0615 5.81614 12.9903 5.64945 12.8604 5.52523L12.4478 5.13096C12.318 5.00704 12.1451 4.93852 11.9608 4.93852C11.7763 4.93852 11.6033 5.00655 11.4735 5.13047L7.13952 9.47146C7.00926 9.59587 6.93803 9.76159 6.93854 9.93787C6.93803 10.1151 7.00926 10.281 7.13952 10.4052L11.4735 14.7466C11.6033 14.8704 11.7762 14.9385 11.9608 14.9385C12.1451 14.9385 12.318 14.8703 12.4478 14.7466L12.8604 14.3523C12.9903 14.2286 13.0615 14.0634 13.0615 13.8872C13.0615 13.7111 12.9903 13.5546 12.8604 13.4307L9.43854 9.93852Z" />
    </svg>

);
export default NewArrowLeft;
