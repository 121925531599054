export const GRAPH_FILES_KEY = 'graphFiles';

export const FILES_SET_META = `FILES_SET_META__${GRAPH_FILES_KEY}`;
export const FILES_RESET = `FILES_RESET__${GRAPH_FILES_KEY}`;

export const FILES_GET_FILE_URL_REQUEST = `FILES_GET_FILE_URL_REQUEST__${GRAPH_FILES_KEY}`;
export const FILES_GET_FILE_URL_SUCCESS = `FILES_GET_FILE_URL_SUCCESS__${GRAPH_FILES_KEY}`;
export const FILES_GET_FILE_URL_FAILURE = `FILES_GET_FILE_URL_FAILURE__${GRAPH_FILES_KEY}`;

export const FILES_UPLOAD_FILE_REQUEST = `FILES_UPLOAD_FILE_REQUEST__${GRAPH_FILES_KEY}`;
export const FILES_UPLOAD_FILE_SUCCESS = `FILES_UPLOAD_FILE_SUCCESS__${GRAPH_FILES_KEY}`;
export const FILES_UPLOAD_FILE_FAILURE = `FILES_UPLOAD_FILE_FAILURE__${GRAPH_FILES_KEY}`;

export const FILES_ADD_FILE_REQUEST = `FILES_ADD_FILE_REQUEST__${GRAPH_FILES_KEY}`;
export const FILES_ADD_FILE_SUCCESS = `FILES_ADD_FILE_SUCCESS__${GRAPH_FILES_KEY}`;
export const FILES_ADD_FILE_FAILURE = `FILES_ADD_FILE_FAILURE__${GRAPH_FILES_KEY}`;

export const FILES_GET_FILE_REQUEST = `FILES_GET_FILE_REQUEST__${GRAPH_FILES_KEY}`;
export const FILES_GET_FILE_SUCCESS = `FILES_GET_FILE_SUCCESS__${GRAPH_FILES_KEY}`;
export const FILES_GET_FILE_FAILURE = `FILES_GET_FILE_FAILURE__${GRAPH_FILES_KEY}`;

export const FILES_GET_PRINT_IMAGES_REQUEST = `FILES_GET_PRINT_IMAGES_REQUEST__${GRAPH_FILES_KEY}`;
export const FILES_GET_PRINT_IMAGES_SUCCESS = `FILES_GET_PRINT_IMAGES_SUCCESS__${GRAPH_FILES_KEY}`;
export const FILES_GET_PRINT_IMAGES_FAILURE = `FILES_GET_PRINT_IMAGES_FAILURE__${GRAPH_FILES_KEY}`;

export const REQUESTS_ATTEMPTS_COUNT = 1;

export const REQUESTS_ATTEMPTS_DELAY = 500;

export const FILES_GET_ZIP_REQUEST = `FILES_GET_ZIP_REQUEST__${GRAPH_FILES_KEY}`;
export const FILES_GET_ZIP_SUCCESS = `FILES_GET_ZIP_SUCCESS__${GRAPH_FILES_KEY}`;
export const FILES_GET_ZIP_FAILURE = `FILES_GET_ZIP_FAILURE__${GRAPH_FILES_KEY}`;

export const FILES_ZIP_INCREMENT_READY = `FILES_ZIP_INCREMENT_READY__${GRAPH_FILES_KEY}`;

export const FILES_ZIP_INCREMENT_FAILED = `FILES_ZIP_INCREMENT_FAILED__${GRAPH_FILES_KEY}`;

export const FILES_RESET_ZIP_STATE = `FILES_RESET_ZIP_STATE__${GRAPH_FILES_KEY}`;

export const FILES_GET_ZIP_CANCEL = `FILES_GET_ZIP_CANCEL__${GRAPH_FILES_KEY}`;

export const FILES_ADD_TO_ZIP_REQUEST = `FILES_ADD_TO_ZIP_REQUEST__${GRAPH_FILES_KEY}`;
export const FILES_ADD_TO_ZIP_SUCCESS = `FILES_ADD_TO_ZIP_SUCCESS__${GRAPH_FILES_KEY}`;
export const FILES_ADD_TO_ZIP_FAILURE = `FILES_ADD_TO_ZIP_FAILURE__${GRAPH_FILES_KEY}`;
export const FILES_ADD_TO_ZIP_CANCEL = `FILES_ADD_TO_ZIP_CANCEL__${GRAPH_FILES_KEY}`;


export const FILES_DOWNLOAD_ZIP = `FILES_DOWNLOAD_ZIP__${GRAPH_FILES_KEY}`;
export const FILES_DOWNLOAD_ZIP_SUCCESS = `FILES_DOWNLOAD_ZIP_SUCCESS__${GRAPH_FILES_KEY}`;
export const FILES_DOWNLOAD_ZIP_FAILURE = `FILES_DOWNLOAD_ZIP_FAILURE__${GRAPH_FILES_KEY}`;
