/* eslint-disable max-len */
import React from 'react';


const Done = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M15.072 7.707l-5.888 5.888a1.503 1.503 0 0 1-2.124 0l-2.767-2.767a1 1 0 0 1 1.413-1.414l1.706 1.706a1.004 1.004 0 0 0 1.419 0l4.827-4.827a.999.999 0 1 1 1.414 1.414z" clipRule="evenodd" />
    </svg>
);

export default Done;
