import React from 'react';
import cn from 'classnames';
import { createUseStyles } from '@flowhealth/ui-components';

import { Panel } from 'components/Panel';

import { checkCorporateLocation } from 'utils/appointment';

import { checkClosedNow, checkClosedToday, formatDayOpenedHours } from '../../utils';


const useStyles = createUseStyles(({ palette, typography }) => ({
    info: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    openHours: {
        ...typography['16M'],
        color: palette.coal.main,
    },
    closed: {
        ...typography['16R'],
        color: palette.red[500],
    },
    opened: {
        ...typography['16R'],
        color: palette.naviBlue[300],
    },
    hidden: {
        display: 'none',
    },
}));

export const CollectionLocationInfo = ({ location }) => {
    const classes = useStyles();

    const isClosedToday = checkClosedToday(location);
    const isClosedNow = checkClosedNow(location);
    
    return (
        <div className={classes.info}>
            <div
                className={cn({
                    [classes.openHours]: !isClosedToday,
                    [classes.closed]: isClosedToday,
                })}
            >
                Today {isClosedToday ? 'Closed' : formatDayOpenedHours(location)}
            </div>
            <div
                className={cn({
                    [classes.opened]: !isClosedNow,
                    [classes.closed]: isClosedNow,
                    [classes.hidden]: isClosedToday,
                })}
            >
                {isClosedNow ? 'Closed Now' : 'Open Now'}
            </div>
        </div>
    )
};
