/* eslint-disable max-len */
import React from 'react';


const AlignCenter = () => (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3 4.59997C3 4.2686 3.26863 3.99997 3.6 3.99997H16.8C17.1314 3.99997 17.4 4.2686 17.4 4.59997V4.89997C17.4 5.23134 17.1314 5.49997 16.8 5.49997H3.6C3.26863 5.49997 3 5.23134 3 4.89997V4.59997ZM3.6 11C3.26863 11 3 11.2686 3 11.6V11.9C3 12.2313 3.26863 12.5 3.6 12.5H16.8C17.1314 12.5 17.4 12.2313 17.4 11.9V11.6C17.4 11.2686 17.1314 11 16.8 11H3.6ZM6.00002 14.5C5.66865 14.5 5.40002 14.7686 5.40002 15.1V15.4C5.40002 15.7313 5.66865 16 6.00002 16H14C14.3314 16 14.6 15.7313 14.6 15.4V15.1C14.6 14.7686 14.3314 14.5 14 14.5H6.00002ZM6.00002 7.49997C5.66865 7.49997 5.40002 7.7686 5.40002 8.09997V8.39997C5.40002 8.73134 5.66865 8.99997 6.00002 8.99997H14C14.3314 8.99997 14.6 8.73134 14.6 8.39997V8.09997C14.6 7.7686 14.3314 7.49997 14 7.49997H6.00002Z" fill="#788697" />
    </svg>
);

export default AlignCenter;
