/* eslint-disable max-len */
import React from 'react';


const NewDone = () => (
    <svg viewBox="0 0 28 28">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.70566 12.9703C9.05279 12.6232 9.61561 12.6232 9.96274 12.9703L12.634 15.6416L18.6052 9.67051C18.9523 9.32337 19.5151 9.32337 19.8622 9.67051C20.2094 10.0176 20.2094 10.5805 19.8622 10.9276L13.2626 17.5272L12.634 18.1558L12.0055 17.5272L8.70566 14.2274C8.35853 13.8803 8.35853 13.3175 8.70566 12.9703Z" fill="#419D4E" />
        <circle fillOpacity="0" cx="13.9993" cy="14.0013" r="11.9444" stroke="#419D4E" strokeWidth="1.77778" />
    </svg>
);
export default NewDone;
