import { set, setIn, removeIn } from 'immutable';
import { createReducer, safeGet } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';

const initialState = {
    batch: undefined,
    fields: undefined,
    specimenKitId: null,
    patient: null,
    copyPatientId: null,
    requisition: null,
    requisitionsProcessings: {},
    isLoading: false,
};

const resetState = () => ({ ...initialState });

const setIsLoading = value => state => set(state, 'isLoading', value);

const setPatient = (state, { payload = {} }) => set(state, 'patient', payload);

const setBatch = (state, { payload = {} }) => set(state, 'batch', payload?.batch);
const setFields = (state, { payload = {} }) => set(state, 'fields', payload?.fields);

const setCopiedPatientId = (state, { payload = {} } = {}) => set(
    state, 'copyPatientId', safeGet(payload, 'copyPatientId'),
);

const setRequisition = (state, { payload = {} } = {}) => set(
    state, 'requisition', safeGet(payload, 'requisition'),
);

const addRequisitionProcessing = (state, { payload = {} }) => (
    setIn(state, ['requisitionsProcessings', payload.id], payload)
);

const removeRequisitionProcessing = (state, { payload = {} }) => (
    removeIn(state, ['requisitionsProcessings', payload.id])
);

const setRequisitionProcessingStatus = (state, { payload = {} }) => (
    setIn(state, ['requisitionsProcessings', payload.id, 'status'], payload.status)
);

const setRequisitionProcessingLab = (state, { payload = {} }) => (
    setIn(state, ['requisitionsProcessings', payload.id, 'lab'], payload.lab)
);

const setRequisitionProcessingContainerType = (state, { payload = {} }) => (
    setIn(state, ['requisitionsProcessings', payload.id, 'containerType'], payload.containerType)
);

const setRequisitionProcessingCode = (state, { payload = {} }) => (
    setIn(state, ['requisitionsProcessings', payload.id, 'code'], payload.code)
);

const setSpecimenKitId = (state, { payload }) => set(state, 'specimenKitId', payload);


export default createReducer(initialState, {
    [ACTION_TYPES.RESET_STATE]: resetState,

    [ACTION_TYPES.SET_LOADING]: setIsLoading(),
    [ACTION_TYPES.SET_PATIENT]: setPatient,

    [ACTION_TYPES.SET_BATCH]: [setBatch, setFields],

    [ACTION_TYPES.SET_SPECIMEN_KIT_ID]: setSpecimenKitId,

    [ACTION_TYPES.ADD_REQUISITION_PROCESSING]: addRequisitionProcessing,
    [ACTION_TYPES.REMOVE_REQUISITION_PROCESSING]: removeRequisitionProcessing,
    [ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_STATUS]: setRequisitionProcessingStatus,
    [ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_LAB]: setRequisitionProcessingLab,
    [ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_CONTAINER_TYPE]: (
        setRequisitionProcessingContainerType
    ),
    [ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_CODE]: setRequisitionProcessingCode,

    [ACTION_TYPES.SEND_REQUISITION_REQUEST]: setIsLoading(true),
    [ACTION_TYPES.SEND_REQUISITION_SUCCESS]: setIsLoading(false),
    [ACTION_TYPES.SEND_REQUISITION_FAILURE]: setIsLoading(false),

    [ACTION_TYPES.COPY_TO_PRACTICE_SUCCESS]: setCopiedPatientId,
    [ACTION_TYPES.COPY_TO_PRACTICE_FINISHED]: setCopiedPatientId,

    [ACTION_TYPES.WS_SUCCESS]: [setRequisition, setIsLoading(false)],
    [ACTION_TYPES.WS_FAILURE]: setIsLoading(false),
});
