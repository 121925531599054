import { createUseStyles } from '@flowhealth/ui-components';


export const useStyles = createUseStyles(({ palette = {} } = {}) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    container: {
        '@media (min-width:800px)': {
            width: 660,
        },
        '& button': {
            '@media (min-width:441px)': {
                width: 327,
                margin: '0 auto',
            },
        },
    },
    practice: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 0',
        cursor: 'pointer',
    },
    practiceInfo: {
        fontSize: 16,
        lineHeight: '24px',
        color: palette.coal.main,
    },
    loading: {
        top: -44,
        height: '100vh',
    },
    checkboxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: 6,
        position: 'relative',
    },
    checkbox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 24,
        height: 24,
        boxShadow: 'unset',
        backgroundColor: palette.naviBlue[300],
        borderRadius: '50%',
    },
    checkmark: {
        '& svg': {
            fill: palette.white.main,
        },
    },
}));
