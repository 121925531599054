/* eslint-disable max-len */
import React from 'react';


const BackArrow = () => (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.09766 4.16666L2.5001 9.99999L8.09766 15.8333" stroke="#4F5862" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <line x1="0.75" y1="-0.75" x2="14.0275" y2="-0.75" transform="matrix(1 0 0 -1 2.72241 9.19312)" stroke="#4F5862" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
);

export default BackArrow;
