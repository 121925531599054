import { DataMatrixReader } from '@flowhealth/datamatrix';

import QrcodeDecoder from "./helper";

const qr = new QrcodeDecoder();

export const QRCodeReader = async canvas => {
    const canvasDataUrl = canvas.toDataURL();

    if (!canvasDataUrl) {
        throw new Error('Canvas Data url doen\'t exist');
    }

    const result = await qr.decodeFromImage(canvasDataUrl);

    if (!result.data) {
        throw new Error('QR is not scanned');
    }

    return result.data;
};

const dataMatrixCodeReader = new DataMatrixReader();

export const DataMatrixCodeReader = canvas => new Promise((resolve, reject) => {
    const result = dataMatrixCodeReader?.decodeFromCanvasImage(canvas);
    if (!result?.text) {
        reject(new Error('Data matrix is not scanned'));
        return;
    }
    resolve(result.text);
});
