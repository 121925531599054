import { select, call, put, takeEvery, all, takeLatest } from 'redux-saga/effects';
import { change } from 'redux-form/immutable';
import API from '@flowhealth/api';
import { isArray, isString } from 'lodash';

import { openSuccessMessage } from 'components/Message/actions';

import sortConfig from 'configuration/sort';

import { isUuid } from 'utils/isUuid';

import {
    SELECT_SEARCH_REQUEST,
    SELECT_EDGE_REQUEST,
    SELECT_ADD_GRAPH_REQUEST,
    SELECT_GET_MISSING_VALUE_REQUEST,
} from './constants';
import {
    selectSearchSuccess,
    selectSearchFailure,

    selectEdgeSuccess,
    selectEdgeFailure,

    selectAddGraphSuccess,
    selectAddGraphFailure,

    selectGetMissingValueSuccess,
    selectGetMissingValueFailure,
} from './actions';
import { selectFlowSelectData } from './selector';


export function* getFromSearch({ payload: { selectId, params, noResultSearch } }) {
    try {
        let configuredParams = null;
        if (!params.sort && sortConfig[params.object]) {
            configuredParams = {
                ...params,
                sort: `${sortConfig[params.object]}(asc)`,
            };
        }

        let response = yield call(API.getInstance().search, configuredParams || params);
        if (!response?.count && noResultSearch) {
            response = yield call(noResultSearch, params);
        }

        const { results, count } = response;
        let data = results;

        if (params.after) {
            const existingData = yield select(selectFlowSelectData(selectId));

            data = existingData.concat(...data);
        }

        yield put(selectSearchSuccess({ selectId, data, maxItems: count }));
    } catch (error) {
        yield put(selectSearchFailure({ selectId, error }));
    }
}

export function* getFromEdge({ payload: { selectId, graphId, edge, params } }) {
    try {
        const response = yield call(API.getInstance().getGraphsOnEdge, graphId, edge, params);
        const { results, count } = response;
        let data = results;

        if (params.after) {
            const existingData = yield select(selectFlowSelectData(selectId));

            data = existingData.concat(...data);
        }

        yield put(selectEdgeSuccess({ selectId, data, maxItems: count }));
    } catch (error) {
        yield put(selectEdgeFailure({ selectId, error }));
    }
}

function* getDataByGraphId({ graphId, expand }) {
    if (isArray(graphId)) {
        const data = [];

        const filteredGraphIds = graphId.reduce((filters, id) => {
            if (!isString(id)) {
                data.push(id);
                return filters;
            }
            return [...filters, id];
        }, []);

        for (const id of filteredGraphIds) {
            const response = yield call(API.getInstance().getGraph, id);
            data.push(response);
        }

        return data;
    }

    return yield call(API.getInstance().getGraph, graphId, expand);
}

function* getDataByCode({ graphId, object, filters }) {
    const codes = isArray(graphId) ? graphId : [graphId];
    const data = [];
    let count = 0;

    while (codes.length !== data.length) {
        const { results } = yield call(API.getInstance().search, { object, filters, count: 100, after: count });
        if (!results.length) break;

        const foundItems = results.filter(item => codes.includes(item.code));
        if (foundItems.length) data.push(...foundItems);
        count += 100;
    }

    return data;
}

export function* getMissingValue({ payload = {} }) {
    const { graphId, selectId, form } = payload;

    try {
        const data = isUuid(graphId) || (isArray(graphId) && graphId.some(isUuid))
            ? yield getDataByGraphId(payload)
            : yield getDataByCode(payload);

        yield put(selectGetMissingValueSuccess({ selectId, data }));
        if (form) yield put(change(form, selectId, isArray(data) ? data : [data]));
    } catch (error) {
        yield put(selectGetMissingValueFailure({ selectId, error }));
    }
}

export function* addGraphToEdge({ payload: { selectId, graphId, edge, edgeId, successMessage, onSuccess } }) {
    try {
        yield call(API.getInstance().addGraphToEdge, graphId, edge, edgeId);
        yield put(selectAddGraphSuccess({ selectId, graphId, edgeName: edge })); // GraphTable saga hooks it

        if (successMessage) yield put(openSuccessMessage(successMessage));
        if (typeof onSuccess === 'function') onSuccess();
    } catch (error) {
        yield put(selectAddGraphFailure({ selectId, error }));
    }
}

function* Saga() {
    yield all([
        takeLatest(SELECT_SEARCH_REQUEST, getFromSearch),
        takeEvery(SELECT_EDGE_REQUEST, getFromEdge),
        takeEvery(SELECT_ADD_GRAPH_REQUEST, addGraphToEdge),
        takeEvery(SELECT_GET_MISSING_VALUE_REQUEST, getMissingValue),
    ]);
}

export default Saga;
