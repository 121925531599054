import { fromJS } from 'immutable';

import {
    SELECT_SEARCH_REQUEST,
    SELECT_SEARCH_SUCCESS,
    SELECT_SEARCH_FAILURE,

    SELECT_EDGE_REQUEST,
    SELECT_EDGE_SUCCESS,
    SELECT_EDGE_FAILURE,

    SELECT_ADD_GRAPH_REQUEST,
    SELECT_ADD_GRAPH_SUCCESS,
    SELECT_ADD_GRAPH_FAILURE,

    SELECT_GET_MISSING_VALUE_REQUEST,
    SELECT_GET_MISSING_VALUE_SUCCESS,
    SELECT_GET_MISSING_VALUE_FAILURE,

    SELECT_CLEAR,
} from './constants';


const initialState = fromJS({});

const reducer = (state = initialState, { type, payload }) => {
    const { selectId, edge, graphId, params } = payload || {};
    const meta = edge ? { graphId, edge, ...params } : { ...params };

    switch (type) {
    case SELECT_SEARCH_REQUEST:
    case SELECT_EDGE_REQUEST:
        return state
            .setIn([selectId, 'loading'], true)
            .setIn([selectId, 'error'], null)
            .setIn([selectId, 'meta'], meta);

    case SELECT_SEARCH_SUCCESS:
    case SELECT_EDGE_SUCCESS:
        return state
            .setIn([selectId, 'loading'], false)
            .setIn([selectId, 'data'], payload.data)
            .setIn([selectId, 'maxItems'], payload.maxItems);

    case SELECT_SEARCH_FAILURE:
    case SELECT_EDGE_FAILURE:
        return state
            .setIn([selectId, 'loading'], false)
            .setIn([selectId, 'error'], payload.error);

    case SELECT_ADD_GRAPH_REQUEST:
        return state
            .setIn([selectId, 'addGraphLoading'], true)
            .setIn([selectId, 'error'], null);

    case SELECT_ADD_GRAPH_SUCCESS:
        return state
            .setIn([selectId, 'addGraphLoading'], false);

    case SELECT_ADD_GRAPH_FAILURE:
        return state
            .setIn([selectId, 'addGraphLoading'], false)
            .setIn([selectId, 'error'], payload.error);

    case SELECT_GET_MISSING_VALUE_REQUEST:
        return state
            .setIn([selectId, 'missingValueLoading'], true)
            .setIn([selectId, 'error'], null);

    case SELECT_GET_MISSING_VALUE_SUCCESS:
        return state
            .setIn([selectId, 'missingValueLoading'], false)
            .setIn([selectId, 'missingValue'], payload.data);

    case SELECT_GET_MISSING_VALUE_FAILURE:
        return state
            .setIn([selectId, 'missingValueLoading'], false)
            .setIn([selectId, 'error'], payload.error);

    case SELECT_CLEAR:
        return state
            .set(selectId, {});

    default:
        return state;
    }
};

export default reducer;
