/* eslint-disable max-len */
import React from 'react';

const Binax = () => (
    <svg viewBox="0 0 32 32" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.66732 26.5L19.334 26.5C19.6101 26.5 19.834 26.2761 19.834 26L19.834 8L4.16733 8L4.16732 26C4.16732 26.2761 4.39118 26.5 4.66732 26.5ZM19.334 4L17.3396 4L17.334 4L6.66732 4L6.66337 4L4.66733 4C3.56276 4 2.66733 4.89543 2.66733 6L2.66733 7.9922L2.66732 8L2.66732 26C2.66732 27.1046 3.56275 28 4.66732 28L19.334 28C20.4386 28 21.334 27.1046 21.334 26L21.334 6C21.334 4.89543 20.4386 4 19.334 4ZM15.334 15.8333L8.66732 15.8333C8.39118 15.8333 8.16732 15.6095 8.16732 15.3333L8.16732 11.3333C8.16732 11.0572 8.39118 10.8333 8.66732 10.8333L15.334 10.8333C15.6101 10.8333 15.834 11.0572 15.834 11.3333L15.834 15.3333C15.834 15.6095 15.6101 15.8333 15.334 15.8333ZM17.334 15.3333C17.334 16.4379 16.4386 17.3333 15.334 17.3333L8.66732 17.3333C7.56275 17.3333 6.66732 16.4379 6.66732 15.3333L6.66732 11.3333C6.66732 10.2288 7.56275 9.33333 8.66732 9.33333L15.334 9.33333C16.4386 9.33333 17.334 10.2288 17.334 11.3333L17.334 15.3333ZM27.334 25.6667C27.334 26.219 26.8863 26.6667 26.334 26.6667C25.7817 26.6667 25.334 26.219 25.334 25.6667L25.334 13.0658C24.7362 12.72 24.334 12.0736 24.334 11.3333L24.334 7.33333C24.334 6.22877 25.2294 5.33333 26.334 5.33333C27.4386 5.33333 28.334 6.22876 28.334 7.33333L28.334 11.3333C28.334 12.0736 27.9318 12.72 27.334 13.0658L27.334 25.6667ZM26.834 10.8004L26.834 7.33333C26.834 7.05719 26.6101 6.83333 26.334 6.83333C26.0578 6.83333 25.834 7.05719 25.834 7.33333L25.834 10.8005C25.9811 10.7154 26.1518 10.6667 26.334 10.6667C26.5161 10.6667 26.6869 10.7154 26.834 10.8004Z" fill="#2F8FE3" />
    </svg>
);

export default Binax;
