import { set, setIn, getIn } from 'immutable';
import isBoolean from 'lodash/isBoolean';
import { createReducer } from '@flowhealth/utils';

import { LOGOUT_REQUEST } from 'containers/Auth/actionTypes';

import validate from './utils/validate';
import format from './utils/format';
import checkComplexity from './utils/checkComplexity';
import * as ACTION_TYPES from './actionTypes';
import { ERROR_MESSAGES, REGISTER_FORM, HTK_CODE_FORM } from './constants';
import initialState from './initialState';


const setInputValue = (state, { payload: { form, data } }) => setIn(state, [form, data.id, 'value'], data.value);

const validateValue = (state, { payload: { form, data } }) => setIn(
    state,
    [form, data.id, 'valid'],
    validate({
        id: data.id,
        value: format(data),
        complexity: getIn(state, [form, data.id, 'complexity'], undefined),
    }),
);

const setComplexity = (state, { payload: { form, data } }) => {
    if (data.id !== 'password') return state;
    const password = getIn(state, [form, data.id, 'value'], undefined);
    return setIn(state, [form, data.id, 'complexity'], checkComplexity(password));
};

const setDisabled = (state, { payload: { form, data } }) => setIn(state, [form, data.id, 'disabled'], data.disabled);

const setInputTouched = (state, { payload: { form, data } }) => setIn(state, [form, data.id, 'touched'], true);

const setLoadingValue = value => (state, { payload = {} }) =>
    set(state, 'loading', isBoolean(value) ? value : (payload.value || false)); // eslint-disable-line

const setErrorMessage = (state, { payload: { form, data, error } }) => {
    let { message } = error;
    let updatedState = state;
    if (error.status === 404 || error.status === 403 || error.status === 503) {
        message = ERROR_MESSAGES.verifyCredentials;
        for (const key of Object.keys(data)) {
            updatedState = setIn(state, [form, key, 'valid'], false);
        }
    }
    return setIn(updatedState, [form, 'error', 'message'], message);
};

const resetError = (state, { payload: { form } }) => setIn(state, [form, 'error'], initialState[form].error);

const setField = field => (state, { payload }) => set(state, field, payload);

const resetState = (state, { payload: { keep, form } = {} }) => {
    if (keep) return { ...initialState, [keep]: state[keep] };
    if (form) return { ...state, [form]: initialState[form] };
    return initialState;
}

const setCodeVerify = (state, { payload: { form, code } }) => setIn(state, [form, 'code'], code);

const setRegisterFormValue = (state, { payload: { id, ...data } }) => setIn(state, [REGISTER_FORM, id], data);

const resetValue = value => state => setIn(state, [HTK_CODE_FORM, value], undefined);

const setSearchError = (state, { payload = {} } = {}) => setIn(
    state, [HTK_CODE_FORM, 'searchError'], payload?.searchError?.message,
);


export default createReducer(initialState, {
    [ACTION_TYPES.SET_INPUT_VALUE]: [setInputValue, setComplexity, validateValue, setDisabled],
    [ACTION_TYPES.SET_INPUT_TOUCHED]: [setInputTouched, validateValue, resetError],

    [ACTION_TYPES.SET_ERROR_MESSAGE]: setErrorMessage,

    [ACTION_TYPES.SET_PORTAL_USE_CASE]: setField('portalUseCase'),
    [ACTION_TYPES.SET_PATIENT_ID]: setField('patient_id'),
    [ACTION_TYPES.SET_PATIENT_NAME]: setField('patient_name'),
    [ACTION_TYPES.SET_PATIENT_PHONE]: setField('patient_phone'),
    [ACTION_TYPES.SET_REDIRECT_URL]: setField('redirect_url'),
    [ACTION_TYPES.SET_PRACTICE_NAME]: setField('practice_name'),
    [ACTION_TYPES.SET_PRACTICE_ID]: setField('practice_id'),
    [ACTION_TYPES.SET_PATIENT_REQUEST]: setField('patientRequest'),

    [LOGOUT_REQUEST]: resetState,
    [ACTION_TYPES.RESET_STATE]: resetState,

    [ACTION_TYPES.SET_FORM_LOADING]: setLoadingValue(),

    [ACTION_TYPES.VERIFY_PATIENT_REQUEST]: [resetError, setLoadingValue(true)],
    [ACTION_TYPES.VERIFY_PATIENT_REQUEST_FAILURE]: [setErrorMessage, setLoadingValue(false)],
    [ACTION_TYPES.VERIFY_PATIENT_REQUEST_SUCCESS]: [setField('reset_token'), setLoadingValue(false)],

    [ACTION_TYPES.SEND_CODE_REQUEST]: [resetError, setLoadingValue(true)],
    [ACTION_TYPES.SEND_CODE_SUCCESS]: [setField('patient_phone'), setLoadingValue(false)],
    [ACTION_TYPES.SEND_CODE_FAILURE]: [setErrorMessage, setLoadingValue(false)],

    [ACTION_TYPES.VERIFY_CODE_REQUEST]: [resetError, setLoadingValue(true)],
    [ACTION_TYPES.VERIFY_CODE_SUCCESS]: [setLoadingValue(false), setCodeVerify],
    [ACTION_TYPES.VERIFY_CODE_FAILURE]: [setErrorMessage, setLoadingValue(false)],

    [ACTION_TYPES.RESET_PASSWORD_REQUEST]: [resetError, setLoadingValue(true)],
    [ACTION_TYPES.RESET_PASSWORD_REQUEST_FAILURE]: [setErrorMessage, setLoadingValue(false)],
    [ACTION_TYPES.RESET_PASSWORD_REQUEST_SUCCESS]: setLoadingValue(false),

    [ACTION_TYPES.AUTH_REQUEST]: [resetError, setLoadingValue(true)],
    [ACTION_TYPES.AUTH_REQUEST_FAILURE]: [setErrorMessage, setLoadingValue(false)],

    [ACTION_TYPES.GET_ADDRESS_REQUEST]: setLoadingValue(true),
    [ACTION_TYPES.GET_ADDRESS_SUCCESS]: setLoadingValue(false),
    [ACTION_TYPES.GET_ADDRESS_FAILURE]: setLoadingValue(false),

    [ACTION_TYPES.REGISTER_PATIENT_REQUEST]: setLoadingValue(true),
    [ACTION_TYPES.REGISTER_PATIENT_SUCCESS]: [setField('reset_token'), setLoadingValue(false)],
    [ACTION_TYPES.REGISTER_PATIENT_FAILURE]: setLoadingValue(false),

    [ACTION_TYPES.SET_REGISTER_FORM_VALUE]: setRegisterFormValue,

    [ACTION_TYPES.RESET_SEARCH_ERROR]: resetValue('searchError'),
    [ACTION_TYPES.SEARCH_BATCH_REQUEST]: [setLoadingValue(true), setSearchError],
    [ACTION_TYPES.SEARCH_BATCH_SUCCESS]: [setLoadingValue(false), resetValue('searchError')],
    [ACTION_TYPES.SEARCH_BATCH_FAILURE]: [setSearchError, setLoadingValue(false)],

    [ACTION_TYPES.SET_EMPLOYEE_ID]: setField('employee_id'),
});
