import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';

export const initPage = createAction(ACTION_TYPES.INIT_PAGE);
export const resetPage = createAction(ACTION_TYPES.RESET_PAGE);

export const setIsLoading = createAction(ACTION_TYPES.SET_IS_LOADING);
export const setAnswers = createAction(ACTION_TYPES.SET_ANSWERS);
export const setPractice = createAction(ACTION_TYPES.SET_PRACTICE);
export const setPerson = createAction(ACTION_TYPES.SET_PERSON);
export const setPatient = createAction(ACTION_TYPES.SET_PATIENT);
export const setLocation = createAction(ACTION_TYPES.SET_LOCATION);
export const setDate = createAction(ACTION_TYPES.SET_DATE);

export const selectPatientByPractice = createAction(ACTION_TYPES.SELECT_PATIENT_BY_PRACTICE);

export const selectPatientWithMainPractice = createAction(ACTION_TYPES.SELECT_PATIENT_WITH_MAIN_PRACTICE);
export const selectPatientWithMainPracticeSuccess = createAction(
    ACTION_TYPES.SELECT_PATIENT_WITH_MAIN_PRACTICE_SUCCESS,
);
export const selectPatientWithMainPracticeFailure = createAction(
    ACTION_TYPES.SELECT_PATIENT_WITH_MAIN_PRACTICE_FAILURE,
);

export const copyToPracticeRequest = createAction(ACTION_TYPES.COPY_TO_PRACTICE_REQUEST);
export const copyToPracticeSuccess = createAction(ACTION_TYPES.COPY_TO_PRACTICE_SUCCESS);
export const copyToPracticeFailure = createAction(ACTION_TYPES.COPY_TO_PRACTICE_FAILURE);
export const copyToPracticeFinished = createAction(ACTION_TYPES.COPY_TO_PRACTICE_FINISHED);

export const createAppointmentRequest = createAction(ACTION_TYPES.CREATE_APPOINTMENT_REQUEST);
export const createAppointmentSuccess = createAction(ACTION_TYPES.CREATE_APPOINTMENT_SUCCESS);
export const createAppointmentFailure = createAction(ACTION_TYPES.CREATE_APPOINTMENT_FAILURE);

export const wsSubscribe = createAction(ACTION_TYPES.WS_SUBSCRIBE);
export const wsUnsubscribe = createAction(ACTION_TYPES.WS_UNSUBSCRIBE);
export const wsSuccess = createAction(ACTION_TYPES.WS_SUCCESS);
export const wsFailure = createAction(ACTION_TYPES.WS_FAILURE);

export const setCommonPractice = createAction(ACTION_TYPES.SET_COMMON_PRACTICE);
export const setInsurance = createAction(ACTION_TYPES.SET_INSURANCE);
