import respiratoryPathogensPanel from 'imgs/patientPortal/booking/respiratoryPathogensPanel.svg';
import COVIDTesting from 'imgs/patientPortal/booking/COVIDTesting.svg';
import AllTests from 'imgs/patientPortal/booking/AllTests.svg';

export const BOOKING_KEY = 'PPBooking';

export const KIND_OF_TEST_INSTRUCTIONS = {
    value1: {
        imgSrc: respiratoryPathogensPanel,
        title: 'Respiratory Pathogens Panel',
        // eslint-disable-next-line max-len
        text: 'Patients presenting with respiratory symptoms may have COVID-19 or a wide variety of other respiratory ailments that may have a similar presentation. RPP Test (Respiratory Pathogen Panel) detects respiratory tract microbiota (RTM) infections that can lead to diseases of the upper and lower respiratory tracts caused by viral, bacterial, and even fungal microbes.',

    },
    value2: {
        imgSrc: COVIDTesting,
        title: 'COVID Testing',
        // eslint-disable-next-line max-len
        text: 'COVID-19 tests can detect either SARS-CoV-2 or biomarkers of SARS-CoV-2, the virus that causes COVID-19, or antibodies that your body makes after getting COVID-19 or after getting vaccinated.\n\nTests for SARS-CoV-2 tell you if you have an infection at the time of the test. This type of test is called a “viral” test because it looks for viral infection. Antigen tests, Nucleic Acid Amplification Tests (NAATs) and other tests are viral tests.',
    },
};

export const TEST_TYPES_INSTRUCTIONS = {
    value1: {
        imgSrc: AllTests,
        title: 'PCR Test',
        // eslint-disable-next-line max-len
        text: 'Polymerase Chain Reaction. It\'s a test to detect genetic material from a specific organism, such as a virus. The test detects the presence of a virus if you have the virus at the time of the test. The test could also detect fragments of the virus even after you are no longer infected.',
    },
    value2: {
        imgSrc: AllTests,
        title: 'Rapid Antigen Test ',
        // eslint-disable-next-line max-len
        text: 'An RAT is designed to directly detect SARS-CoV-2 virus proteins (antigens) in respiratory specimens. It is intended for qualitative detection of antigens in specimens from individuals with suspected infections. Used in conjunction with the results of clinical interpretation and other laboratory tests. The tests requires nasal or nasopharyngeal web samples or deep throat saliva samples. ',
    },
    value3: {
        imgSrc: AllTests,
        title: 'Rapid Molecular Test',
        // eslint-disable-next-line max-len
        text: 'Molecular tests amplify bits of viral RNA so that viral infection can be detected using a specialized test. These tests also are referred to as nucleic acid amplification tests (NAAT). The procedure begins by taking a sample from a potentially infected person’s nose or mouth (saliva), where virus might be found. If SARS-CoV-2 is present in the sample, then even low levels of virus genomic material can be amplified into millions of copies detected during a molecular diagnostic assay.',
    },
};

export const BOOKING_TEST_QUESTINNARY_STEPS = {
    NEED_TEST: 'Why Do You Need a Test?',
    KIND_OF_TEST: 'What Kind of Test Do You Want to Take?',
    TYPE_OF_TEST: 'Test Type',
};

export const BOOKING_TEST_ANSWER_VALUES = {
    Personal: 'Personal Test',
    Employer: 'For Employer',

    Covid: 'COVID Testing',
    RPP: 'Respiratory Pathogens Panel',

    PCR: 'PCR Test',
    RTP: 'Rapid Antigen Test',
    RMT: 'Rapid Molecular Test',
};

export const BOOKING_TEST_CONFIG = {
    [BOOKING_TEST_QUESTINNARY_STEPS.NEED_TEST]: {
        text: [BOOKING_TEST_QUESTINNARY_STEPS.NEED_TEST],
        options: [{
            title: BOOKING_TEST_ANSWER_VALUES.Personal,
            subtitle: 'Your employer wouldn’t see this',
        }, {
            title: BOOKING_TEST_ANSWER_VALUES.Employer,
            subtitle: 'Your employer will receive this test',
        }],
    },
    [BOOKING_TEST_QUESTINNARY_STEPS.KIND_OF_TEST]: {
        text: BOOKING_TEST_QUESTINNARY_STEPS.KIND_OF_TEST,
        options: [{
            title: BOOKING_TEST_ANSWER_VALUES.Covid,
            instruction: {
                imgSrc: COVIDTesting,
                title: 'COVID Testing',
                // eslint-disable-next-line max-len
                text: 'COVID-19 tests can detect either SARS-CoV-2 or biomarkers of SARS-CoV-2, the virus that causes COVID-19, or antibodies that your body makes after getting COVID-19 or after getting vaccinated.\n\nTests for SARS-CoV-2 tell you if you have an infection at the time of the test. This type of test is called a “viral” test because it looks for viral infection. Antigen tests, Nucleic Acid Amplification Tests (NAATs) and other tests are viral tests.',
            },
        },
        {
            title: BOOKING_TEST_ANSWER_VALUES.RPP,
            instruction: {
                imgSrc: respiratoryPathogensPanel,
                title: 'Respiratory Pathogens Panel',
                // eslint-disable-next-line max-len
                text: 'Patients presenting with respiratory symptoms may have COVID-19 or a wide variety of other respiratory ailments that may have a similar presentation. RPP Test (Respiratory Pathogen Panel) detects respiratory tract microbiota (RTM) infections that can lead to diseases of the upper and lower respiratory tracts caused by viral, bacterial, and even fungal microbes.',
            },
        },
        ],
    },
    [BOOKING_TEST_QUESTINNARY_STEPS.TYPE_OF_TEST]: {
        text: BOOKING_TEST_QUESTINNARY_STEPS.TYPE_OF_TEST,
        options: [{
            title: BOOKING_TEST_ANSWER_VALUES.PCR,
            subtitle: 'Results in 8 hours',
            instruction: {
                imgSrc: AllTests,
                title: 'PCR Test',
                // eslint-disable-next-line max-len
                text: 'Polymerase Chain Reaction. It\'s a test to detect genetic material from a specific organism, such as a virus. The test detects the presence of a virus if you have the virus at the time of the test. The test could also detect fragments of the virus even after you are no longer infected.',
            },
        },
        {
            title: BOOKING_TEST_ANSWER_VALUES.RTP,
            subtitle: 'Results in 15-20 mins',
            instruction: {
                imgSrc: AllTests,
                title: 'Rapid Antigen Test ',
                // eslint-disable-next-line max-len
                text: 'An RAT is designed to directly detect SARS-CoV-2 virus proteins (antigens) in respiratory specimens. It is intended for qualitative detection of antigens in specimens from individuals with suspected infections. Used in conjunction with the results of clinical interpretation and other laboratory tests. The tests requires nasal or nasopharyngeal web samples or deep throat saliva samples. ',
            },
        },
        {
            title: BOOKING_TEST_ANSWER_VALUES.RMT,
            subtitle: 'Results in 30 mins',
            instruction: {
                imgSrc: AllTests,
                title: 'Rapid Molecular Test',
                // eslint-disable-next-line max-len
                text: 'Molecular tests amplify bits of viral RNA so that viral infection can be detected using a specialized test. These tests also are referred to as nucleic acid amplification tests (NAAT). The procedure begins by taking a sample from a potentially infected person’s nose or mouth (saliva), where virus might be found. If SARS-CoV-2 is present in the sample, then even low levels of virus genomic material can be amplified into millions of copies detected during a molecular diagnostic assay.',
            },
        },
        ],
    },
};

export const BOOKING_TEST = Object.values(BOOKING_TEST_CONFIG);
