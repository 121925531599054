import {
    LOGIN_FORM,
    VERIFICATION_FORM,
    CREATE_PASSWORD_FORM,
    ENTER_CODE_FORM,
    REGISTER_FORM,
    DEFAULT_COUNTRY,
    ADD_INSURANCE_FORM,
    HTK_CODE_FORM,
} from './constants';


const initialState = {
    [LOGIN_FORM]: {
        phone: {
            value: '',
            valid: false,
            touched: false,
            disabled: undefined,
        },
        password: {
            value: '',
            valid: false,
            touched: false,
        },
        error: {
            message: undefined,
        },
    },
    [VERIFICATION_FORM]: {
        birth_date: {
            value: '',
            valid: false,
            touched: false,
        },
        phone: {
            value: '',
            valid: false,
            touched: false,
        },
        error: {
            message: undefined,
        },
    },
    [ENTER_CODE_FORM]: {
        code: {
            value: '',
            valid: false,
            touched: false,
        },
        error: {
            message: undefined,
        },
    },
    [CREATE_PASSWORD_FORM]: {
        password: {
            value: '',
            valid: false,
            touched: false,
            complexity: 0,
        },
        passwordConfirm: {
            value: '',
            valid: false,
            touched: false,
        },
        error: {
            message: undefined,
        },
    },
    [REGISTER_FORM]: {
        code: undefined,
        tab: 1,
        country: DEFAULT_COUNTRY,
        given: '',
        family: '',
        birth_date: '',
        gender: undefined,
        race: {},
        ethnicity: {},
        email: '',
        line1: '',
        line2: '',
        zip_code: '',
        city: '',
        state: '',
    },
    [ADD_INSURANCE_FORM]: {
        loading: false,
        error: {},
        policy_holder: {
            value: [{ code: 'self', display: 'Self' }],
            valid: true,
            touched: true,
        },
        given: {
            value: '',
            valid: false,
            touched: false,
        },
        family: {
            value: '',
            valid: false,
            touched: false,
        },
        birth_date: {
            value: '',
            valid: false,
            touched: false,
        },
        relation: {
            value: '',
            valid: false,
            touched: false,
        },
        payor: {
            value: '',
            valid: false,
            touched: false,
        },
        member_id: {
            value: '',
            valid: false,
            touched: false,
        },
        group_number: {
            value: '',
            valid: false,
            touched: false,
        },
        photo_front: {
            value: '',
            valid: false,
            touched: false,
        },
        photo_back: {
            value: '',
            valid: false,
            touched: false,
        },
    },
    [HTK_CODE_FORM]: {
        searchError: undefined,
    },
    loading: false,
    employee_id: undefined,
    patient_id: undefined,
    redirect_url: undefined,
    practice_name: '',
    reset_token: undefined,
    portalUseCase: undefined,
    practice_id: undefined,
    patient_phone: undefined,
};

export default initialState;
