/* eslint-disable max-len */
import React from 'react';

const HomeTestKit = () => (
    <svg width="32" height="32" viewBox="0 0 32 32">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.334 11.5C13.0578 11.5 12.834 11.7239 12.834 12C12.834 12.2761 13.0578 12.5 13.334 12.5H18.6673C18.9435 12.5 19.1673 12.2761 19.1673 12C19.1673 11.7239 18.9435 11.5 18.6673 11.5H13.334Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.66667 7.9987C7.66667 7.07822 8.41286 6.33203 9.33333 6.33203H22.6667C23.5871 6.33203 24.3333 7.07822 24.3333 7.9987V17.332C24.3333 17.8843 24.781 18.332 25.3333 18.332C25.8856 18.332 26.3333 17.8843 26.3333 17.332V7.9987C26.3333 5.97365 24.6917 4.33203 22.6667 4.33203H9.33333C7.30829 4.33203 5.66667 5.97365 5.66667 7.9987V17.332C5.66667 17.8843 6.11438 18.332 6.66667 18.332C7.21895 18.332 7.66667 17.8843 7.66667 17.332V7.9987ZM5 21.332C5 21.1479 5.14924 20.9987 5.33333 20.9987H9V21.332C9 22.2525 9.74619 22.9987 10.6667 22.9987H21.3333C22.2538 22.9987 23 22.2525 23 21.332V20.9987H26.6667C26.8508 20.9987 27 21.1479 27 21.332V25.332C27 25.5161 26.8508 25.6654 26.6667 25.6654H5.33333C5.14924 25.6654 5 25.5161 5 25.332V21.332ZM5.33333 18.9987C4.04467 18.9987 3 20.0434 3 21.332V25.332C3 26.6207 4.04467 27.6654 5.33333 27.6654H26.6667C27.9553 27.6654 29 26.6207 29 25.332V21.332C29 20.0434 27.9553 18.9987 26.6667 18.9987H22.6667C21.7462 18.9987 21 19.7449 21 20.6654V20.9987H11V20.6654C11 19.7449 10.2538 18.9987 9.33333 18.9987H5.33333Z" />
    </svg>
);

export default HomeTestKit;
