import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { useMyLocation } from './useMyLocation';

export const useDistancesFromMyLocation = locations => {
    const shouldCalculateDistances = !isEmpty(locations);
    const { location: myLocation, loading: locationLoading } = useMyLocation(shouldCalculateDistances);
    const [distances, setDistances] = useState({});

    useMemo(() => {
        if (!myLocation || !shouldCalculateDistances) {
            return;
        }

        const distances = locations.reduce((acc, location) => {
            acc[location.id] = window.google.maps.geometry.spherical.computeDistanceBetween(
                myLocation,
                {
                    lat: location.geo_location.lat,
                    lng: location.geo_location.lon
                }
            )
            return acc;
        }, {});

        setDistances(distances);
    }, [myLocation, locations]);

    return {
        distances,
        loading: locationLoading,
        myLocation,
    };
};
