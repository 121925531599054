import { createAction, createSlice } from '@reduxjs/toolkit';

export const KEY_PP_STI_ORDER = 'PP_STI_ORDER';

const initialState = {
    order: null,
    isLoading: false,
};

export const emptySagaActions = {
    requestAndSetStiOrder: createAction(
        `${KEY_PP_STI_ORDER}/requestAndSetStiOrder`
    ),
};

const stiOrderSlice = createSlice({
    name: 'stiOrderSlice',
    initialState,
    reducers: {
        setIsLoading: (state, { payload }) => {
            state.isLoading = payload;
        },
        setOrder: (state, { payload }) => {
            state.order = payload;
        },
    },
});

const allActions = { ...emptySagaActions, ...stiOrderSlice.actions };

export const { requestAndSetStiOrder, setIsLoading, setOrder } = allActions;

export default stiOrderSlice.reducer;
