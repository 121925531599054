import isNumber from 'lodash/isNumber';
import { isObject } from '@flowhealth/utils';

export const pluralize = (config, count) => {
    if (!isNumber(count) || !isObject(config)) return;
    const rem100 = count % 100;
    const rem10 = count % 10;
    const createString = key => `${count} ${config[key]}`;

    if (rem100 === 11 || rem100 === 12 || rem100 === 13 || rem100 === 14) return createString('many');
    if (rem10 === 2 || rem10 === 3 || rem10 === 4) return createString('few');
    if (rem10 === 1) return createString('one');
    if (count === 0) return createString('zero');

    return createString('many');
};
