/* eslint-disable max-len */
import React from 'react';

const Copy = () => (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M18 12.917C18 13.469 17.55 14 16.998 14H16V5.92C16 4.814 15.101 4 13.995 4H6V2.917C6 2.365 6.446 2 6.998 2H16.998C17.55 2 18 2.365 18 2.917V12.917ZM14 17C14 17.552 13.552 18 13 18H3C2.448 18 2 17.552 2 17V7C2 6.447 2.448 6 3 6H13C13.552 6 14 6.447 14 7V17ZM17.995 0H6.002C4.895 0 4 0.814 4 1.92V4H2.002C0.895 4 0 4.814 0 5.92V17.914C0 19.02 0.895 20 2.002 20H13.995C15.101 20 16 19.02 16 17.914V16H17.995C19.101 16 20 15.02 20 13.914V1.92C20 0.814 19.101 0 17.995 0Z" />
    </svg>
);

export default Copy;
