export const SELECT_LOCATION_KEY = 'selectLocations';

export const FETCH_EVENT_LOCATIONS_REQUEST = `FETCH_EVENT_LOCATIONS_REQUEST__${SELECT_LOCATION_KEY}`;
export const FETCH_EVENT_LOCATIONS_SUCCESS = `FETCH_EVENT_LOCATIONS_SUCCESS__${SELECT_LOCATION_KEY}`;
export const FETCH_EVENT_LOCATIONS_FAILURE = `FETCH_EVENT_LOCATIONS_FAILURE__${SELECT_LOCATION_KEY}`;

export const FETCH_LOCATIONS_REQUEST = `FETCH_LOCATIONS_REQUEST__${SELECT_LOCATION_KEY}`;
export const FETCH_LOCATIONS_SUCCESS = `FETCH_LOCATIONS_SUCCESS__${SELECT_LOCATION_KEY}`;
export const FETCH_LOCATIONS_FAILURE = `FETCH_LOCATIONS_FAILURE__${SELECT_LOCATION_KEY}`;

export const RESET_STATE = `RESET_STATE__${SELECT_LOCATION_KEY}`;

