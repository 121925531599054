/* eslint-disable max-len */
import React from 'react';


const DeleteCircle = () => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.8284 13.3284C12.4381 13.7188 11.8045 13.7188 11.4142 13.3284L10 11.9142L8.58579 13.3284C8.19546 13.7188 7.5619 13.7188 7.17157 13.3284C6.78125 12.9381 6.78125 12.3045 7.17157 11.9142L8.58579 10.5L7.17157 9.08579C6.78125 8.69546 6.78125 8.0619 7.17157 7.67157C7.5619 7.28125 8.19546 7.28125 8.58579 7.67157L10 9.08579L11.4142 7.67157C11.8045 7.28125 12.4381 7.28125 12.8284 7.67157C13.2188 8.0619 13.2188 8.69546 12.8284 9.08579L11.4142 10.5L12.8284 11.9142C13.2188 12.3045 13.2188 12.9381 12.8284 13.3284ZM10 1.5C5.0293 1.5 1 5.5293 1 10.5C1 15.4707 5.0293 19.5 10 19.5C14.9707 19.5 19 15.4707 19 10.5C19 5.5293 14.9707 1.5 10 1.5Z" fill="#A8B2BD" />
    </svg>
);

export default DeleteCircle;
