import React from 'react';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import FlowIcon from 'components/FlowIcon';
import { ICONS_TYPES } from 'components/FlowIcon/constants';

import styles from '../styles';


const Input = ({
    classes,

    getIdBasedOnSelectId,

    onRootRef,

    disabled,
    preview,
    multi,

    hasFormValue,
    listOpened,
    clearable,
    inputValue,
    noInputValue,
    multiChips,

    showError,
    showInput,
    showClearableInPreview,

    missingValueLoading,
    toggleOpenList,
    handleClearAll,

    inputComp,

    dataTestId,
    inputClassName,
}) => (
    <div
        ref={onRootRef}
        id={getIdBasedOnSelectId('inputContainer')} // needed for tests
        className={cn(classes.inputContainer, inputClassName, {
            [classes.inputContainerDisabled]: disabled,
            [classes.inputContainerWithChips]: multi && hasFormValue,
            [classes.inputContainerPreview]: preview && !listOpened,
            [classes.inputContainerNonClearable]: !clearable,
            [classes.inputContainerFocused]: !showError && listOpened,
            [classes.inputContainerError]: showError,
            [classes.inputContainerNoInput]: !showInput,
            'non-clearable': !clearable,
        })}
        onClick={
            !disabled && !missingValueLoading && !showInput ? toggleOpenList : undefined
        }
        data-tid={dataTestId}
    >
        {missingValueLoading
            ? (
                <FlowIcon
                    className={classes.dots}
                    size={20}
                    type={ICONS_TYPES.threeDotsLoading}
                />
            ) : (
                <>
                    {multiChips}
                    {showInput
                        ? inputComp
                        : (
                            <span
                                // needed for tests
                                id={getIdBasedOnSelectId('noInputValueHolder')}
                                className={cn(
                                    classes.inputValueHolder,
                                    preview && !listOpened && classes.inputValueHolderPreview,
                                    !inputValue && classes.placeholder,
                                )}
                            >
                                {noInputValue}
                            </span>
                        )}
                    <div className={classes.inputIcons}>
                        {!disabled
                        && clearable
                        && showClearableInPreview
                        && (hasFormValue || inputValue) && (
                            <FlowIcon
                                id={getIdBasedOnSelectId('clear')} // needed for tests
                                className={classes.inputClear}
                                type={ICONS_TYPES.clear}
                                size={20}
                                onClick={handleClearAll}
                                disabled={disabled}
                            />
                        )}
                        {(!preview || listOpened) && (
                            <FlowIcon
                                // needed for tests
                                id={getIdBasedOnSelectId('triangle')}
                                className={classes.inputTriangle}
                                type={ICONS_TYPES.triangle}
                                size={14}
                                rotate={listOpened ? 270 : 90}
                                onClick={showInput ? toggleOpenList : undefined}
                                disabled={disabled}
                            />
                        )}
                    </div>
                </>
            )
        }
    </div>
);

export default withStyles(styles)(Input);
