import React from 'react';
import { Icon, useTheme } from '@flowhealth/ui-components';


export const ActionIcon = ({ type, onClick, color }) => {
    const theme = useTheme();

    return (
        <Icon
            type={type}
            size={24}
            onClick={onClick}
            color={color ?? theme.palette.coal[600]}
        />
    )
};
