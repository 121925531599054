import { theme } from '@flowhealth/ui-components';


export default {
    '@global': {
        'body, html, #root': {
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        body: {
            fontFamily: 'sans-serif',
            padding: 0,
            zIndex: 0,
            lineHeight: 1.231,
            position: 'relative',
            cursor: 'auto',

            '&.fontLoaded': { ...theme.commonFont },

            paddingBottom: 'env(safe-area-inset-bottom)',
        },
        'body[style]': {
            paddingRight: '0 !important',
            overflow: 'auto !important',
        },
        '.clearfix': {
            '&:before': {
                content: '',
                display: 'table',
            },

            '&:after': {
                clear: 'both',
                content: '',
                display: 'block',
                height: 0,
            },
        },
        '.no-list': {
            listStyle: 'none',
            margin: 0,
            padding: 0,
        },
        'input:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 30px white inset',
        },
        '.grecaptcha-badge': {
            visibility: 'hidden',
        },
        '.g-recaptcha > div': {
            margin: '8px auto',
        },
    },
};
