import { set } from 'immutable';
import { createReducer } from '@flowhealth/utils';

import * as ACTION_TYPES from './constants';


const initialState = {
    isLoadingLocations: false,
    eventLocations: undefined,
    locations: undefined,
};

const setIsLoadingLocations = (
    defaultValue = false
) => (state, { payload }) => set(state, 'isLoadingLocations', defaultValue || Boolean(payload?.isLoadingLocations));
const setEventLocations = (state, { payload }) => set(state, 'eventLocations', payload?.eventLocations);
const setLocations = (state, { payload }) => set(state, 'locations', payload?.locations);

const resetState = () => initialState;

export default createReducer(initialState, {
    [ACTION_TYPES.RESET_STATE]: resetState,

    [ACTION_TYPES.FETCH_LOCATIONS_REQUEST]: [setIsLoadingLocations(true)],
    [ACTION_TYPES.FETCH_LOCATIONS_SUCCESS]: [setIsLoadingLocations(false), setLocations],
    [ACTION_TYPES.FETCH_LOCATIONS_FAILURE]: [setIsLoadingLocations(false)],

    [ACTION_TYPES.FETCH_EVENT_LOCATIONS_REQUEST]: [setIsLoadingLocations(true)],
    [ACTION_TYPES.FETCH_EVENT_LOCATIONS_SUCCESS]: [setIsLoadingLocations(false), setEventLocations],
    [ACTION_TYPES.FETCH_EVENT_LOCATIONS_FAILURE]: [setIsLoadingLocations(false)],
});
