import { isString, isBoolean } from 'lodash';
import { isSafari } from 'react-device-detect';
import { isFullArray } from '@flowhealth/utils';


const CHECK_LOAD_INTERVAL = 1000;

const printImages = (urls = [], imageName = 'barcode', newWindow = true, width) => {
    if (!isFullArray(urls) || !isString(imageName) || !isBoolean(newWindow)) return;

    const printWin = newWindow && !isSafari ? window.open(undefined, undefined, 'rel="noopener"') : window.open();
    if (!printWin) {
        throw new Error(`Cannot display${imageName ? ` the ${imageName}` : ''}.`
            + 'To resolve the problem, allow pop-up windows in your browser settings.\n'
            + '\n'
            + 'For example, in Safari navigate to Preferences > Websites '
            + '> Pop-up Windows and select Allow for the Flow Health website. '
            + 'Alternatively, click on the notification in the address bar '
            + 'and allow to view the blocked window. This will resolve the problem '
            + 'for the duration of the current session.');
    }
    if (width) printWin.document.body.style.textAlign = 'center';

    const loaded = [];
    for (let i = 0; i < urls.length; i++) {
        const img = printWin.document.createElement('img');

        img.src = urls[i];
        img.style.width = width ?? '100%';
        img.alt = imageName;
        img.onload = () => loaded.push(urls[i]);
        printWin.document.body.appendChild(img);


        if (i < urls.length - 1) {
            const div = printWin.document.createElement('div');
            div.style.cssText = 'page-break-after: always !important;';
            printWin.document.body.appendChild(div);
        }
    }

    const interval = setInterval(() => {
        if (loaded.length === urls.length) {
            printWin.focus();
            printWin.print();
            clearInterval(interval);
        }
    }, CHECK_LOAD_INTERVAL);
};

export default printImages;
