export const elemBoundingRectKeys = ['x', 'y', 'width', 'height', 'top', 'right', 'bottom', 'left'];

export const LIST_TOP_POSITION_OFFSET = 19;

const LIST_POSITION_START_RIGHT = 'right';
const LIST_POSITION_START_LEFT = 'left';

export const LIST_POSITION_START = {
    [LIST_POSITION_START_RIGHT]: LIST_POSITION_START_RIGHT,
    [LIST_POSITION_START_LEFT]: LIST_POSITION_START_LEFT,
};

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const RESET_USER_STATE = 'RESET_USER_STATE';

export const SET_AGREEMENTS_REQUEST = 'SET_AGREEMENTS_REQUEST';
export const SET_AGREEMENTS_SUCCESS = 'SET_AGREEMENTS_SUCCESS';
export const SET_AGREEMENTS_FAILURE = 'SET_AGREEMENTS_FAILURE';

export const SET_SIGNATURE_FILE = 'SET_SIGNATURE_FILE';

export const USER_PATCH_REQUEST = 'USER_PATCH_REQUEST';
export const USER_PATCH_SUCCESS = 'USER_PATCH_SUCCESS';
export const USER_PATCH_FAILURE = 'USER_PATCH_FAILURE';

export const ROLE_PATCH_REQUEST = 'ROLE_PATCH_REQUEST';
export const ROLE_PATCH_SUCCESS = 'ROLE_PATCH_SUCCESS';
export const ROLE_PATCH_FAILURE = 'ROLE_PATCH_FAILURE';

export const USER_ADD_LAB_TO_STORE = 'USER_ADD_LAB_TO_STORE';

export const USER_CHANGE_ROLE_REQUEST = 'USER_CHANGE_ROLE_REQUEST';
export const USER_CHANGE_ROLE_SUCCESS = 'USER_CHANGE_ROLE_SUCCESS';
export const USER_CHANGE_ROLE_FAILURE = 'USER_CHANGE_ROLE_FAILURE';

export const USER_UPLOAD_AVATAR_REQUEST = 'USER_UPLOAD_AVATAR_REQUEST';
export const USER_UPLOAD_AVATAR_SUCCESS = 'USER_UPLOAD_AVATAR_SUCCESS';
export const USER_UPLOAD_AVATAR_FAILURE = 'USER_UPLOAD_AVATAR_FAILURE';
