import { fromJS } from 'immutable';
import { createReducer, safeGet } from '@flowhealth/utils';

import * as ACTION_TYPES from './constants';

const initialState = fromJS({});

const setOpen = (state, action) => {
    const id = safeGet(action, 'payload.id');
    const data = safeGet(action, 'payload.data');
    return state.setIn([id, 'data'], data).setIn([id, 'isOpen'], true);
};

const setClose = (state, action) => {
    const id = safeGet(action, 'payload.id');
    return state.setIn([id, 'data'], null).setIn([id, 'isOpen'], false);
};

const setToggleIsConfirmOpen = (state, action) => {
    const id = safeGet(action, 'payload.id');
    const isConfirmOpen = safeGet(action, 'payload.isConfirmOpen', false);
    return state.setIn([id, 'isConfirmOpen'], isConfirmOpen);
};

export default createReducer(initialState, {
    [ACTION_TYPES.OPEN_FLOW_DIALOG]: setOpen,
    [ACTION_TYPES.CLOSE_FLOW_DIALOG]: setClose,
    [ACTION_TYPES.TOGGLE_IS_CONFIRM_OPEN]: setToggleIsConfirmOpen,
});
