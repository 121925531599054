import { createSelector } from 'reselect';

import { FLOW_DIALOG_KEY } from './constants';

const selectDialog = state => state[FLOW_DIALOG_KEY];

export const selectCurrentDialog = createSelector(
    [
        selectDialog,
        (state, ownProps) => ownProps.id,
    ],
    (state, id) => state && state.get(id),
);

export const makeSelectDialogOpen = createSelector(
    selectCurrentDialog,
    state => state && state.get('isOpen'),
);

export const makeSelectIsConfirmOpen = createSelector(
    selectCurrentDialog,
    state => state && state.get('isConfirmOpen'),
);

export const makeSelectDialogData = createSelector(
    selectCurrentDialog,
    state => state && state.get('data'),
);
