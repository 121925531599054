import jsQR from "jsqr";

class QrcodeDecoder {
    constructor() {
        this.canvasElement = null;
        this.context = null;

        this.defaultOption = { inversionAttempts: "attemptBoth" };
    }

    _createImageData(target, width, height) {
        if (!this.canvasElement) {
            this._prepareCanvas(width, height);
        }

        this.context.clearRect(0, 0, width, height);
        this.context.drawImage(target, 0, 0, width, height);

        return this.context.getImageData(
            0,
            0,
            this.canvasElement.width,
            this.canvasElement.height
        );
    }

    _prepareCanvas(width, height) {
        if (!this.canvasElement) {
            this.canvasElement = document.createElement("canvas");
            this.canvasElement.style.width = `${width}px`;
            this.canvasElement.style.height = `${height}px`;
            this.canvasElement.width = width;
            this.canvasElement.height = height;
        }

        this.context = this.canvasElement.getContext("2d");
    }

    async decodeFromImage(img, options = {}) {
        let imgDom = null;
        const opts = {
            ...this.defaultOption,
            ...options,
        };

        if (typeof img === "string") {
            imgDom = document.createElement("img");
            if (options.crossOrigin) {
                imgDom.crossOrigin = options.crossOrigin;
            }
            imgDom.src = img;
            const waitForOnload = () =>
                new Promise(resolve => {
                    imgDom.onload = () => resolve(true);
                });
            await waitForOnload();
        } else if (+img.nodeType > 0) {
            if (!img.src) {
                throw new Error("The ImageElement must contain a src");
            }
            imgDom = img;
        }

        let code = null;
        if (imgDom) {
            code = this._decodeFromImageElm(imgDom, opts);
        }
        return code;
    }

    _decodeFromImageElm(imgObj, options = {}) {
        const providedOptions = {
            ...this.defaultOption,
            ...options,
        };
        const imageData = this._createImageData(
            imgObj,
            imgObj.width,
            imgObj.height
        );

        const code = jsQR(
            imageData.data,
            imageData.width,
            imageData.height,
            providedOptions
        );

        if (code) {
            return code;
        }

        return false;
    }
}

export default QrcodeDecoder;
