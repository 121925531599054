import { isNil, isUndefined } from 'lodash';

import { validateDate, validateEmail } from 'components/Inputs/validators';

import { US_ZIP_LENGTH, DEFAULT_COUNTRY } from 'containers/Auth/constants';

import { chainWithValue } from 'utils/chain';


const convertToBoolean = (res, val) => isNil(res) && !!val;

const isUSA = country => country === DEFAULT_COUNTRY;

const isValidZipCode = ({ value, valid }, formData) => {
    if (!isUndefined(valid)) return valid;
    if (!isUSA(formData.country.value)) return !!value;
    if (!formData.zip_code.valid) return false;
    return value.length === US_ZIP_LENGTH;
};

export default (formData, { id, value, valid }) => {
    switch (id) {
    case 'birth_date': return validateDate(value);
    case 'email': return chainWithValue(value, validateEmail, convertToBoolean);
    case 'zip_code': return isValidZipCode({ value, valid }, formData);
    case 'given':
    case 'family':
    case 'gender':
    case 'line1':
    case 'state':
    case 'city': return !!value;
    default: return true;
    }
};
