import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { App } from '@flowhealth/app';

import { makeSelectMainMenu, selectIsMobile } from 'containers/App/selectors';
import { makeSelectRole, makeSelectRoleId, makeSelectUser } from 'containers/User/selector';
import { userRequest } from 'containers/User/actions';

import {
    PRIVACY_POLICY_PATH,
    TERMS_OF_SERVICE_PATH,
    NOTICE_PRIVACE_PRACTICE_PATH,
    HEALTHCARE_PROVIDER_USER_AGREEMENT_PATH,
    ELECTRONIC_SIGNATURE_TERMS_PATH,
    SMS_TERMS_SERVICE_PATH,
    PORTAL_AGREEMENT_PATH,
} from 'configuration/paths';

import Logo from 'imgs/logo.svg';

import styles from './styles';

const linkList = [
    { id: 'privacyPolicyLinkId', text: 'Privacy Policy', path: PRIVACY_POLICY_PATH },
    { id: 'termsOfServiceLinkId', text: 'Terms of Service', path: TERMS_OF_SERVICE_PATH },
    {
        id: 'noticeOfPrivacyPracticesLinkId',
        text: 'Notice of Privacy Practices',
        path: NOTICE_PRIVACE_PRACTICE_PATH,
    },
    {
        id: 'healthcareProviderUserAgreementLinkId',
        text: 'Healthcare Provider User Agreement',
        path: HEALTHCARE_PROVIDER_USER_AGREEMENT_PATH,
    },
    {
        id: 'electronicSignatureTermsLinkId',
        text: 'Electronic Signature Terms',
        path: ELECTRONIC_SIGNATURE_TERMS_PATH,
    },
    {
        id: 'smsTermsOfServiceLinkId',
        text: 'SMS Terms of Service',
        path: SMS_TERMS_SERVICE_PATH,
    },
    {
        id: 'patientPortalAgreementLinkId',
        text: 'Patient Portal Agreement (Future)',
        path: PORTAL_AGREEMENT_PATH,
    },
];

class LinkComponent extends React.PureComponent {
    render() {
        const { text, path, id, classes } = this.props;
        return (
            <li className={classes.li}><Link id={id} className={classes.a} to={path}>{text}</Link></li>
        );
    }
}


class BlockingRoute extends React.Component {
    componentDidMount() {
        const { user, dispatch } = this.props;
        const token = App.getInstance().getToken();
        if (user && !user.data && token) {
            dispatch(userRequest());
        }
    }

    get logo() {
        const { role, showLogo } = this.props;
        if (role || !showLogo) return null;
        return <img src={Logo} alt="Flow Health" />;
    }

    render() {
        const Component = this.props.children;
        const { classes, role, hideNav } = this.props;
        const content = (
            <>
                {this.logo}
                {!hideNav && (
                    <div className={classes.list}>
                        <ul className={classes.ul}>
                            {linkList.map(
                                ({ text, path, id }) => (
                                    <LinkComponent
                                        id={id}
                                        key={path}
                                        text={text}
                                        path={path}
                                        classes={classes}
                                    />
                                ),
                            )}
                        </ul>
                    </div>
                )}
                <div className={classes.list}>
                    <Component />
                </div>
            </>
        );

        switch (role) {
            case 'patient': return content;
            default: return (
                <div className={cn('wrapper', classes.wrapper)}>
                    <div className={cn('inner', classes.inner)}>
                        {content}
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = createStructuredSelector({
    isMob: selectIsMobile,
    isMenuOpen: makeSelectMainMenu(),
    user: makeSelectUser(),
    role: makeSelectRole(),
    roleId: makeSelectRoleId(),
});

const withConnect = connect(mapStateToProps);

export default compose(
    withStyles(styles),
    withConnect,
)(BlockingRoute);
