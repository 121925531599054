import { createSelector } from 'reselect';


const selectFlowSelectState = state => state.flowSelect;
const selectProps = (_, props = {}) => props;

export const selectFlowSelectLoading = id => createSelector(
    selectFlowSelectState,
    state => state && state.getIn([id, 'loading']),
);

export const selectFlowSelectAddGraphLoading = id => createSelector(
    selectFlowSelectState,
    state => state && state.getIn([id, 'addGraphLoading']),
);

export const selectFlowSelectData = id => createSelector(
    selectFlowSelectState,
    state => state && state.getIn([id, 'data']),
);

export const selectFlowSelectMaxItems = id => createSelector(
    selectFlowSelectState,
    state => state && state.getIn([id, 'maxItems']),
);

export const selectFlowSelectMissingValue = id => createSelector(
    selectFlowSelectState,
    state => state && state.getIn([id, 'missingValue']),
);

export const selectFlowSelectMissingValueLoading = id => createSelector(
    selectFlowSelectState,
    selectProps,
    (state, props) => props.missingValueLoading || (state && state.getIn([id, 'missingValueLoading'])),
);

export const selectFlowSelectMeta = id => createSelector(
    selectFlowSelectState,
    state => state && state.getIn([id, 'meta']),
);
