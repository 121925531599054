import { FLOW_FLEX_KIT_KEY } from './constants';


export const RESET_STATE = `RESET_STATE__${FLOW_FLEX_KIT_KEY}`;
export const RESET_SCANNER_STATE = `RESET_SCANNER_STATE__${FLOW_FLEX_KIT_KEY}`;

export const SET_ERROR = `SET_ERROR__${FLOW_FLEX_KIT_KEY}`;
export const SET_LOADING = `SET_LOADING__${FLOW_FLEX_KIT_KEY}`;
export const SET_PATIENT = `SET_PATIENT__${FLOW_FLEX_KIT_KEY}`;
export const SET_BATCH = `SET_BATCH__${FLOW_FLEX_KIT_KEY}`;
export const SET_KIT_ID = `SET_KIT_ID__${FLOW_FLEX_KIT_KEY}`;
export const SET_IMG_SRC = `SET_IMG_SRC__${FLOW_FLEX_KIT_KEY}`;
export const SET_FILE = `SET_FILE__${FLOW_FLEX_KIT_KEY}`;
export const SET_TEST_RESULT = `SET_TEST_RESULT__${FLOW_FLEX_KIT_KEY}`;

export const SUBMIT_TEST_REQUEST = `SUBMIT_TEST_REQUEST__${FLOW_FLEX_KIT_KEY}`;
export const SUBMIT_TEST_SUCCESS = `SUBMIT_TEST_SUCCESS__${FLOW_FLEX_KIT_KEY}`;
export const SUBMIT_TEST_FAILURE = `SUBMIT_TEST_FAILURE__${FLOW_FLEX_KIT_KEY}`;

export const WS_SUBSCRIBE = `WS_SUBSCRIBE__${FLOW_FLEX_KIT_KEY}`;
export const WS_UNSUBSCRIBE = `WS_UNSUBSCRIBE__${FLOW_FLEX_KIT_KEY}`;

export const COPY_TO_PRACTICE_REQUEST = `COPY_TO_PRACTICE_REQUEST__${FLOW_FLEX_KIT_KEY}`;
export const COPY_TO_PRACTICE_SUCCESS = `COPY_TO_PRACTICE_SUCCESS__${FLOW_FLEX_KIT_KEY}`;
export const COPY_TO_PRACTICE_FAILURE = `COPY_TO_PRACTICE_FAILURE__${FLOW_FLEX_KIT_KEY}`;
export const COPY_TO_PRACTICE_FINISHED = `COPY_TO_PRACTICE_FINISHED__${FLOW_FLEX_KIT_KEY}`;
export const WS_COPY_TO_PRACTICE_SUCCESS = `WS_COPY_TO_PRACTICE_SUCCESS__${FLOW_FLEX_KIT_KEY}`;
export const WS_COPY_TO_PRACTICE_FAILED = `WS_COPY_TO_PRACTICE_FAILED__${FLOW_FLEX_KIT_KEY}`;

export const CREATE_POC_ACTION_WITH_PHOTO_REQUEST = `CREATE_POC_ACTION_WITH_PHOTO_REQUEST__${FLOW_FLEX_KIT_KEY}`;
export const CREATE_POC_ACTION_WITH_PHOTO_SUCCESS = `CREATE_POC_ACTION_WITH_PHOTO_SUCCESS__${FLOW_FLEX_KIT_KEY}`;
export const CREATE_POC_ACTION_WITH_PHOTO_FAILURE = `CREATE_POC_ACTION_WITH_PHOTO_FAILURE__${FLOW_FLEX_KIT_KEY}`;
export const WS_POC_ACTION_CHANGE_SUCCESS = `WS_POC_ACTION_CHANGE_SUCCESS__${FLOW_FLEX_KIT_KEY}`;
export const WS_POC_ACTION_CHANGE_FAILED = `WS_POC_ACTION_CHANGE_FAILED__${FLOW_FLEX_KIT_KEY}`;
