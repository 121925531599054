/* eslint-disable max-len */
import React from 'react';

const SortArrow = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.93981 7.87705L8.93981 6H16.4C16.7314 6 17 6.26863 17 6.6V7.4C17 7.73137 16.7314 8 16.4 8L9.74535 8C9.49136 7.9195 9.22325 7.87704 8.94864 7.87704L8.93981 7.87705ZM6.93981 8H6.6C6.26863 8 6 7.73137 6 7.4V6.6C6 6.26863 6.26863 6 6.6 6H6.93981V8ZM7.52374 10.9952L8.49221 10.0781C8.61396 9.95045 8.76732 9.87944 8.93981 9.8771L8.94864 9.87704C9.12483 9.87704 9.29006 9.94827 9.41378 10.0781L9.80805 10.4907C9.93178 10.6205 10 10.7934 10 10.9778C10 10.9852 9.99989 10.9926 9.99967 11H7.6C7.57416 11 7.54871 10.9984 7.52374 10.9952ZM11.9999 11C12 10.9926 12 10.9852 12 10.9778C12 10.2947 11.7443 9.62319 11.2555 9.11056L11.1498 9L16.4 9C16.7314 9 17 9.26863 17 9.6V10.4C17 10.7314 16.7314 11 16.4 11L11.9999 11ZM11 13.101L11.238 12.8635L11.2549 12.8458C11.4936 12.5957 11.6764 12.3081 11.8013 12H16.4C16.7314 12 17 12.2686 17 12.6V13.4C17 13.7314 16.7314 14 16.4 14H11.6C11.2686 14 11 13.7314 11 13.4V13.101Z" />
        <path d="M5.05586 4.70862C5.05746 7.36793 5.05836 8.8589 5.05997 11.5182L3.52178 10.0781C3.39786 9.94827 3.23 9.87704 3.05381 9.87704C2.87762 9.87704 2.71092 9.94827 2.58671 10.0781L2.19244 10.4907C2.06852 10.6205 2 10.7934 2 10.9778C2 11.1623 2.06803 11.3353 2.19195 11.465L5.53294 14.799C5.65735 14.9293 5.82306 15.0005 5.99935 15C6.17662 15.0005 6.34243 14.9293 6.46664 14.799L9.80805 11.465C9.93187 11.3353 10 11.1624 10 10.9778C10 10.7934 9.93178 10.6205 9.80805 10.4907L9.41378 10.0781C9.29006 9.94827 9.12483 9.87704 8.94864 9.87704C8.77255 9.87704 8.61604 9.94827 8.49221 10.0781L6.93981 11.5481L6.93981 4.69919C6.93981 4.31925 6.62707 4 6.26442 4L5.70678 4C5.34412 4 5.05586 4.32868 5.05586 4.70862Z" />
    </svg>

);

export default SortArrow;
