/* eslint-disable max-len */
import React from 'react';


const Search = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M18.293 16.88a1 1 0 1 1-1.414 1.413l-2.243-2.243a1 1 0 1 1 1.414-1.414l2.243 2.243zM8 13c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5zM8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1z" clipRule="evenodd" />
    </svg>
);

export default Search;
