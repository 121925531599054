import { useEffect, useRef } from 'react';

export const useInitializeCenter = (markers, map) => {
    const isCenterInitializedRef = useRef(false); 

    useEffect(() => {
        if (!isCenterInitializedRef.current && Array.isArray(markers) && map) {
            const bounds = new window.google.maps.LatLngBounds();
            for (const marker of markers) {
                bounds.extend(marker.position);
            }

            map.fitBounds(bounds);
            isCenterInitializedRef.current = true;
        }
    }, [map, markers])
}
