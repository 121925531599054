import { isObject } from '@flowhealth/utils';

const fetchValues = obj => {
    const res = {};
    for (const key of Object.keys(obj)) {
        res[key] = isObject(obj[key]) ? obj[key].value : obj[key];
    }
    return res;
};

export default fetchValues;
