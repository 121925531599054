import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';

import { bluredBG } from './styles';


const styles = ({ palette = {} } = {}) => ({
    root: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        background: palette.gray[85],
        overflow: 'hidden',
        zIndex: '1000',
    },
    inner: {
        padding: '25px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    innerScroll: {
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    head: {
        width: '128px',
        height: '36px',
        borderRadius: '4px',
        display: 'flex',
        marginBottom: '20px',
    },
    rowWrap: {
        display: 'flex',
        flexDirection: 'row',
        margin: '8px 0',
        width: '100%',
    },
    row: {
        width: '100%',
        height: '28px',
        borderRadius: '4px',
        display: 'flex',

        // margin: '15px',
    },
    button: {
        width: '80px',
        height: '36px',
        borderRadius: '4px',
        display: 'flex',
        marginTop: 'auto',
    },
    ...bluredBG,
    wrapper: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        zIndex: '1000',
        background: 'rgba(255, 255, 255, 0.25)',
    },
    defaultRoot: {
        width: '100%',
        position: 'absolute',
        left: '0',
        right: '0',
        background: palette.gray[85],
    },
});


class TableLoader extends React.PureComponent {
    static propTypes = {
        title: PropTypes.bool,
        action: PropTypes.bool,
    };

    static defaultProps = {
        title: false,
        action: true,
    };

    render() {
        const { classes, title, action, isSorting, infiniteScroll, pageForScroll } = this.props;
        if (infiniteScroll && pageForScroll > 0) {
            return (
                <div className={classes.defaultRoot} data-tid="table-loader">
                    <div className={classes.innerScroll}>
                        <div className={classes.rowWrap}>
                            <div className={cn(classes.animate, classes.row)} />
                        </div>
                    </div>
                </div>
            );
        }
        if (isSorting) {
            return <div className={classes.wrapper} data-tid="table-loader-sorting" />;
        }
        return (
            <div className={classes.root} data-tid="table-loader">
                <div className={classes.inner}>
                    {title && <div className={cn(classes.animate, classes.head)} />}

                    <div className={classes.rowWrap}>
                        <div className={cn(classes.animate, classes.row)} />
                    </div>
                    <div className={classes.rowWrap}>
                        <div className={cn(classes.animate, classes.row)} />
                    </div>

                    {action && <div className={cn(classes.animate, classes.button)} />}
                </div>
            </div>
        );
    }
}


export default withStyles(styles)(TableLoader);
