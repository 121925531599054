import { normalizePhone } from '@flowhealth/utils';
import React from 'react';

import FlowIcon from 'components/FlowIcon';
import { ICONS_TYPES } from 'components/FlowIcon/constants';

import { selectHotlinePhone, useRemoteConfig } from 'containers/Firebase';

import NoLocationsImage from 'imgs/noLocations.svg';

import { useStyles } from './styles';

const NoLocations = () => {
    const classes = useStyles();
    const hotline = useRemoteConfig(selectHotlinePhone);
    const formattedHotline = `+1 ${normalizePhone(hotline)}`;

    return (
        <div className={classes.container}>
            <img className={classes.image} src={NoLocationsImage} alt="No Locations" />
            <h2 className={classes.title}>No Locations</h2>
            <p className={classes.description}>
                We have not found any available locations for this appointment.
                Please, contact your Health and Safety team.
            </p>
            <div className={classes.hotlineContainer}>
                <FlowIcon className={classes.hotlineIcon} type={ICONS_TYPES.phone} size={24} />
                Hotline Number:
                <a
                    className={classes.hotline}
                    href={`tel:${formattedHotline}`}
                >
                    {formattedHotline}
                </a>
            </div>
        </div>
    );
};

export default NoLocations;
