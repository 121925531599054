import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { formatAddress, isFullArray, copyToClipboard } from '@flowhealth/utils';
import { Button, createUseStyles, Icon } from '@flowhealth/ui-components';
import { Call, BackArrow } from '@flowhealth/icons';
import isNil from 'lodash/isNil';

import { Container, ActionIcon } from 'components/Container';
import Modal from 'components/Modal';
import { openSuccessMessage } from 'components/Message/actions';

import { checkCorporateLocation } from 'utils/appointment';

import { formatDayOpenedHours } from '../../utils';

import { Item } from './Item';


const useStyles = createUseStyles(({ palette, typography }) => ({
    content: {
        '& > div:not(:last-child)': {
            borderBottom: `0.33px solid ${palette.ash[500]}`,
        },
    },
    mb16: {
        marginBottom: 16,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    title: {
        ...typography['18B'],
        color: palette.coal[700],
    },
    text: {
        ...typography['16R'],
        color: palette.coal[700],
    },
    workHours: {
        padding: '16px 0 24px',
    },
    closed: {
        color: palette.coal[500],
    },
    weekday: {
        ...typography['16M'],
        color: palette.coal[700],
        width: 40,
    },
    contacts: {
        padding: '16px 0',
    },
    callIcon: {
        transform: 'scale(-1, 1)',
    },
    selectButton: {
        borderColor: `${palette.naviBlue[300]} !important`,
        backgroundColor: `${palette.naviBlue[300]} !important`,
    },
    modalButton: {
        ...typography['16R'],
        border: 'none',
        background: 'none',
        color: palette.coal[700],
        cursor: 'pointer',
        textAlign: 'left',
        width: '100%',
        padding: '8px 0',

        '&:focus, &:hover': {
            background: palette.ash[200],
        }
    }
}));



const ConfirmSelected = ({ location, onBack, onSuccess }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const isCorporate = checkCorporateLocation(location);

    const collectionLocation = isCorporate ? location?.collection_location : location;
    const isGeoAvailable = Boolean(collectionLocation?.geo_location);

    const collectionLocationHasOpenDays = isNil(collectionLocation) || isFullArray(collectionLocation?.open_hours);

    const actions = (
        <Button
            disabled={!collectionLocationHasOpenDays}
            onClick={onSuccess}
            className={classes.selectButton}
            isFullWidth
            label="Select this Location"
        />
    );

    const isPhoneExist = Boolean(collectionLocation?.phone);
    const isFaxExist = Boolean(collectionLocation?.fax);

    const weekdays = useMemo(() => {
        const today = dayjs();
        return Array.from({ length: 7 }, (_, index) => {
            const day = today.weekday(index);
            return {
                day,
                name: day.format('ddd'),
            };
        });
    }, []);
    
    const address = isNil(collectionLocation) ? location?.address : formatAddress(collectionLocation?.address);

    const handleModalToggle = () => {
        setIsModalOpen(previous => !previous);
    };

    const handleOpenGoogleMap = () => {
        handleModalToggle();
        const geo = collectionLocation?.geo_location;
        const url = `https://www.google.com/maps/search/?api=1&query=${geo.lat},${geo.lon}`

        window.open(url, '_blank');
    }

    const handleCopyAddress = () => {
        handleModalToggle();
        copyToClipboard(address);
        dispatch(openSuccessMessage('Address was copied successfully'));
    }

    return (
        <>
            <Container
                title={collectionLocation?.name ?? location?.name}
                leftAction={<ActionIcon type={BackArrow} onClick={onBack} />}
                actions={actions}
            >
                <div className={classes.content}>
                    <Item
                        label="Address"
                        text={address}
                        onClick={handleModalToggle}
                    />
                    {!isNil(collectionLocation) && (
                        <div className={classes.workHours}>
                            <div className={cn(classes.title, classes.mb16)}>Working Hours</div>
                            {weekdays.map(weekday => {
                                const workHours = formatDayOpenedHours(collectionLocation, weekday.day);
                                const isClosed = !workHours;

                                return (
                                    <div className={classes.row} key={weekday.name}>
                                        <div className={classes.weekday}>{weekday.name}</div>
                                        <div
                                            className={cn(classes.text, { [classes.closed]: isClosed })}
                                        >
                                            {isClosed ? 'Closed' : workHours}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {(isPhoneExist || isFaxExist) && (
                        <div className={cn(classes.contacts, classes.title)}>
                            Contacts
                        </div>
                    )}
                    {isPhoneExist && (
                        <Item
                            label="Phone"
                            text={collectionLocation.phone}
                            href={`tel:${collectionLocation.phone}`}
                            icon={<Icon size={24} className={classes.callIcon} type={Call} />}
                        />
                    )}
                    {isFaxExist && (
                        <Item
                            label="Fax"
                            text={collectionLocation.fax}
                            href={`fax:${collectionLocation.fax}`}
                            icon={<Icon size={24} className={classes.callIcon} type={Call} />}
                        />
                    )}
                </div>
            </Container>
            {isModalOpen && (
                <Modal
                    onClose={handleModalToggle}
                >
                    {isGeoAvailable && (
                        <button
                            className={classes.modalButton}
                            onClick={handleOpenGoogleMap}
                        >
                            Open in Google Maps
                        </button>
                    )}
                    <button className={classes.modalButton} onClick={handleCopyAddress}>Copy Address</button>
                </Modal>
            )}
        </>
    );
};

export default ConfirmSelected;
