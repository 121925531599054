/* eslint-disable max-len */
import React from 'react';


const RowMenu = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M2 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm8 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm10-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" clipRule="evenodd" />
    </svg>
);

export default RowMenu;
