/* eslint-disable max-len */
import React from 'react';

const Insurance = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_11885:48337)">
            <path fillRule="evenodd" clipRule="evenodd" d="M25 -1H24V0H25V-1ZM7.37958 5.83663C6.43866 6.10596 5.75 6.97256 5.75 8V18C5.75 19.2426 6.75736 20.25 8 20.25H13.1575C13.2967 20.7908 13.5241 21.2962 13.8235 21.75H8C5.92893 21.75 4.25 20.0711 4.25 18V8C4.25 6.15492 5.58253 4.62106 7.33773 4.3083C7.64446 3.12433 8.72012 2.25 10 2.25H14C15.2799 2.25 16.3555 3.12434 16.6623 4.3083C18.4175 4.62106 19.75 6.15492 19.75 8V14.3148C19.2795 14.139 18.7754 14.032 18.25 14.0061V8C18.25 6.97256 17.5613 6.10597 16.6204 5.83663C16.2664 6.94632 15.2271 7.75 14 7.75H10C8.77293 7.75 7.73357 6.94632 7.37958 5.83663ZM18 23C20.2091 23 22 21.2091 22 19C22 16.7909 20.2091 15 18 15C15.7909 15 14 16.7909 14 19C14 21.2091 15.7909 23 18 23ZM20.3042 18.2723C20.5077 18.0396 20.4927 17.6781 20.2707 17.4649C20.0486 17.2516 19.7036 17.2674 19.5 17.5L17.8666 19.4184L16.8988 18.6462C16.6952 18.4135 16.3502 18.3978 16.1281 18.6111C15.906 18.8243 15.891 19.1858 16.0946 19.4184L17.4645 20.6502C17.5678 20.7683 17.7137 20.8355 17.8666 20.8355C18.0195 20.8355 18.1654 20.7683 18.2687 20.6502L20.3042 18.2723ZM8.75 5C8.75 4.30964 9.30964 3.75 10 3.75H14C14.6904 3.75 15.25 4.30964 15.25 5C15.25 5.69036 14.6904 6.25 14 6.25H10C9.30964 6.25 8.75 5.69036 8.75 5ZM8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12ZM9 14.25C8.58579 14.25 8.25 14.5858 8.25 15C8.25 15.4142 8.58579 15.75 9 15.75H13C13.4142 15.75 13.75 15.4142 13.75 15C13.75 14.5858 13.4142 14.25 13 14.25H9Z" />
        </g>
        <defs>
            <clipPath id="clip0_11885:48337">
                <rect width="24" height="24" />
            </clipPath>
        </defs>
    </svg>
);

export default Insurance;
