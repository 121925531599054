/* eslint-disable max-len */
import React from 'react';


const Link = () => (
    <svg xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.5166 2.28027C15.6607 0.424401 12.6518 0.424406 10.7959 2.28028L7.85281 5.22336C7.60053 5.47564 7.8084 5.86711 8.16485 5.85182L9.67631 5.78697C9.97287 5.77425 10.2539 5.65073 10.4638 5.44084L12.2101 3.69449C13.2849 2.61967 15.0276 2.61967 16.1024 3.69449C17.1772 4.76931 17.1772 6.51194 16.1024 7.58677L13.1593 10.5299C12.0845 11.6047 10.3418 11.6047 9.26702 10.5299C9.03689 10.2997 8.7358 10.1356 8.41063 10.1495L8.30138 10.1542C7.53389 10.1871 7.02945 10.9677 7.51211 11.5653C7.61772 11.6961 7.73129 11.8225 7.85281 11.9441C9.70868 13.7999 12.7176 13.7999 14.5735 11.9441L17.5166 9.00098C19.3725 7.14511 19.3725 4.13615 17.5166 2.28027Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.0305 9.77309C11.8485 9.74524 12.3658 8.89795 11.8069 8.30004C11.7694 8.25997 11.7311 8.22038 11.692 8.1813C9.80993 6.29919 6.75843 6.29919 4.87632 8.1813L2.02825 11.0294C0.146138 12.9115 0.14614 15.963 2.02825 17.8451C3.91036 19.7272 6.96187 19.7272 8.84397 17.8451L11.692 14.997C11.8874 14.8017 11.7315 14.4926 11.4555 14.502L9.73842 14.5605C9.44688 14.5704 9.16999 14.6906 8.96373 14.8969L7.42976 16.4309C6.3287 17.5319 4.54352 17.5319 3.44246 16.4309C2.3414 15.3298 2.3414 13.5446 3.44246 12.4436L6.29053 9.59552C7.39159 8.49445 9.17677 8.49445 10.2778 9.59551C10.3968 9.71445 10.5558 9.78925 10.7239 9.78352L11.0305 9.77309Z" />
    </svg>
);

export default Link;
