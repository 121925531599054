import myLocationMarker from 'icons/MyLocationMapMarker.svg';

export const useMyLocationMarker = (location, map) => {
    new window.google.maps.Marker({
        position: location,
        map,
        icon: {
            url: myLocationMarker,
            anchor: new window.google.maps.Point(20, 20),
        },
    });
};
