import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './constants';

export const filesSetMeta = createAction(ACTION_TYPES.FILES_SET_META);
export const filesReset = createAction(ACTION_TYPES.FILES_RESET);

export const filesGetFileUrlRequest = createAction(ACTION_TYPES.FILES_GET_FILE_URL_REQUEST);
export const filesGetFileUrlSuccess = createAction(ACTION_TYPES.FILES_GET_FILE_URL_SUCCESS);
export const filesGetFileUrlFailure = createAction(ACTION_TYPES.FILES_GET_FILE_URL_FAILURE);

export const filesUploadFileRequest = createAction(ACTION_TYPES.FILES_UPLOAD_FILE_REQUEST);
export const filesUploadFileSuccess = createAction(ACTION_TYPES.FILES_UPLOAD_FILE_SUCCESS);
export const filesUploadFileFailure = createAction(ACTION_TYPES.FILES_UPLOAD_FILE_FAILURE);

export const filesAddFileRequest = createAction(ACTION_TYPES.FILES_ADD_FILE_REQUEST);
export const filesAddFileSuccess = createAction(ACTION_TYPES.FILES_ADD_FILE_SUCCESS);
export const filesAddFileFailure = createAction(ACTION_TYPES.FILES_ADD_FILE_FAILURE);

export const filesGetFileRequest = createAction(ACTION_TYPES.FILES_GET_FILE_REQUEST);
export const filesGetFileSuccess = createAction(ACTION_TYPES.FILES_GET_FILE_SUCCESS);
export const filesGetFileFailure = createAction(ACTION_TYPES.FILES_GET_FILE_FAILURE);

export const filesGetPrintImagesRequest = createAction(ACTION_TYPES.FILES_GET_PRINT_IMAGES_REQUEST);
export const filesGetPrintImagesSuccess = createAction(ACTION_TYPES.FILES_GET_PRINT_IMAGES_SUCCESS);
export const filesGetPrintImagesFailure = createAction(ACTION_TYPES.FILES_GET_PRINT_IMAGES_FAILURE);

/**
 * filesGetZipRequest - action for fetching and zipping files
 * @param {Object} - can take the following keys: data {Array}, mimeTypes {Array}, zipName {String}
 * if data is an array of urls mimeTypes is required
 */
export const filesGetZipRequest = createAction(ACTION_TYPES.FILES_GET_ZIP_REQUEST);
export const filesGetZipSuccess = createAction(ACTION_TYPES.FILES_GET_ZIP_SUCCESS);
export const filesGetZipFailure = createAction(ACTION_TYPES.FILES_GET_ZIP_FAILURE);

export const filesZipIncrementReady = createAction(ACTION_TYPES.FILES_ZIP_INCREMENT_READY);

export const filesZipIncrementFailed = createAction(ACTION_TYPES.FILES_ZIP_INCREMENT_FAILED);

export const filesResetZipState = createAction(ACTION_TYPES.FILES_RESET_ZIP_STATE);

export const filesGetZipCancel = createAction(ACTION_TYPES.FILES_GET_ZIP_CANCEL);

export const filesAddToZipRequest = createAction(ACTION_TYPES.FILES_ADD_TO_ZIP_REQUEST);
export const filesAddToZipSuccess = createAction(ACTION_TYPES.FILES_ADD_TO_ZIP_SUCCESS);
export const filesAddToZipFailure = createAction(ACTION_TYPES.FILES_ADD_TO_ZIP_FAILURE);
export const filesAddToZipCancel = createAction(ACTION_TYPES.FILES_ADD_TO_ZIP_CANCEL);

export const filesDownloadZip = createAction(ACTION_TYPES.FILES_DOWNLOAD_ZIP);
export const filesDownloadZipSuccess = createAction(ACTION_TYPES.FILES_DOWNLOAD_ZIP_SUCCESS);
export const filesDownloadZipFailure = createAction(ACTION_TYPES.FILES_DOWNLOAD_ZIP_FAILURE);
