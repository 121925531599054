/* eslint-disable max-len */
import React from 'react';


const Plus = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M17 10a1 1 0 0 1-1 1h-5v5a1 1 0 0 1-2 0v-5H4a1 1 0 0 1 0-2h5V4a1 1 0 0 1 2 0v5h5a1 1 0 0 1 1 1z" clipRule="evenodd" />
    </svg>
);

export default Plus;
