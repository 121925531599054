import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';


export const resetState = createAction(ACTION_TYPES.RESET_STATE);

export const setField = createAction(ACTION_TYPES.SET_FIELD);

export const setInputValue = createAction(ACTION_TYPES.SET_INPUT_VALUE);
export const setInputTouched = createAction(ACTION_TYPES.SET_INPUT_TOUCHED);

export const addProfileRequest = createAction(ACTION_TYPES.ADD_PROFILE_REQUEST);
export const addProfileSuccess = createAction(ACTION_TYPES.ADD_PROFILE_SUCCESS);
export const addProfileFailure = createAction(ACTION_TYPES.ADD_PROFILE_FAILURE);

export const getAddressRequest = createAction(ACTION_TYPES.GET_ADDRESS_REQUEST);
export const getAddressSuccess = createAction(ACTION_TYPES.GET_ADDRESS_SUCCESS);
export const getAddressFailure = createAction(ACTION_TYPES.GET_ADDRESS_FAILURE);
