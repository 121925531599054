/* eslint-disable max-len */
import React from 'react';


const AlignRight = () => (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.7998 4.59997C2.7998 4.2686 3.06843 3.99997 3.3998 3.99997H16.5998C16.9312 3.99997 17.1998 4.2686 17.1998 4.59997V4.89997C17.1998 5.23134 16.9312 5.49997 16.5998 5.49997H3.3998C3.06843 5.49997 2.7998 5.23134 2.7998 4.89997V4.59997ZM3.3998 11C3.06843 11 2.7998 11.2686 2.7998 11.6V11.9C2.7998 12.2313 3.06843 12.5 3.3998 12.5H16.5998C16.9312 12.5 17.1998 12.2313 17.1998 11.9V11.6C17.1998 11.2686 16.9312 11 16.5998 11H3.3998ZM8.59976 14.5C8.26838 14.5 7.99976 14.7686 7.99976 15.1V15.4C7.99976 15.7313 8.26838 16 8.59976 16H16.5998C16.9311 16 17.1998 15.7313 17.1998 15.4V15.1C17.1998 14.7686 16.9311 14.5 16.5998 14.5H8.59976ZM8.59976 7.49997C8.26838 7.49997 7.99976 7.7686 7.99976 8.09997V8.39997C7.99976 8.73134 8.26838 8.99997 8.59976 8.99997H16.5998C16.9311 8.99997 17.1998 8.73134 17.1998 8.39997V8.09997C17.1998 7.7686 16.9311 7.49997 16.5998 7.49997H8.59976Z" fill="#788697" />
    </svg>
);

export default AlignRight;
