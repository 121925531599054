import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { sentry } from '@flowhealth/utils';

import { createReduxHistory, routerMiddleware } from './history';
import { createReducer } from './reducers';


function configureStore(initialState = {}) {

    const sagaMiddleware = createSagaMiddleware({
        onError: error => {
            sentry({ error });
        },
    });

    const middlewares = [
        sagaMiddleware,
        routerMiddleware,
    ];

    const enhancers = [
        applyMiddleware(...middlewares),
    ];

    const composeEnhancers = process.env.NODE_ENV !== 'production'
        && typeof window === 'object'
        && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  // eslint-disable-line
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ // eslint-disable-line
            shouldHotReload: false,
        })
        : compose;

    const store = createStore(
        createReducer(),
        initialState,
        composeEnhancers(...enhancers),
    );


    store.runSaga = sagaMiddleware.run;
    store.injectedReducers = {};
    store.injectedSagas = {};

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createReducer(store.injectedReducers));
        });
    }

    return store;
}

const initialState = {};
export const store = configureStore(initialState);
export const history = createReduxHistory(store);
