import { set, fromJS, updateIn } from 'immutable';
import { isBoolean } from 'lodash';
import { createReducer, safeGet } from '@flowhealth/utils';

import { LOGOUT_SUCCESS } from 'containers/Auth/actionTypes';

import * as CONSENT_ACTION_TYPES from '../components/Consent/store/actionTypes';

import * as ACTION_TYPES from './constants';

const getLocalStorage = () => {
    try {
        return localStorage;
    } catch {
        return undefined;
    }
};

const initialState = fromJS({
    loading: false,
    patients: undefined,
    persons: undefined,
    barcodeOpened: false,
    loggedInUser: getLocalStorage()?.getItem('PP_user'),
    consents: null,
    consentsLoading: false,
    consentsWasSigned: false,

    appointments: undefined,
    appointmentsIsLoading: false,
    appointmentsError: undefined,
});

const setLoading = value => (state, { payload = {} } = {}) => set(
    state,
    'loading',
    isBoolean(value) ? value : safeGet(payload, 'value', false),
);

const setConsentsLoading = value => state => set(state, 'consentsLoading', value);

const setBarcodeOpened = value => state => set(state, 'barcodeOpened', value);

const setPatients = (state, { payload = {} } = {}) => set(state, 'patients', safeGet(payload, 'patients'));

const setPersons = (state, { payload = {} } = {}) => set(state, 'persons', safeGet(payload, 'persons'));

const resetState = () => initialState;

const setLoggedInUser = (state, { payload = {} } = {}) => {
    getLocalStorage()?.setItem('PP_user', safeGet(payload, 'loggedInUser'));
    return set(state, 'loggedInUser', safeGet(payload, 'loggedInUser'));
};

const setConsents = (state, { payload = {} } = {}) => set(state, 'consents', payload);

const updateConsent = (state, { payload } = {}) => updateIn(state, ['consents'],
    (consents = []) => consents.filter(consent => consent.id !== payload));

const setConsentsWasSigned = value => state => set(state, 'consentsWasSigned', value);

const setAppointments = (state, { payload } = {}) => set(state, 'appointments', payload.appointments);
const setAppointmentsLoading = value => (state, { payload = {} } = {}) => set(
    state,
    'appointmentsIsLoading',
    isBoolean(value) ? value : safeGet(payload, 'value', false),
);
const setAppointmentsError = (state, { payload } = {}) => set(
    state, 
    'appointmentsError',
    safeGet(payload, 'appointmentsError')
);

const deleteAppointment = (state, { payload } = {}) => set(
    state,
    'appointments',
    [...state.get('appointments').filter(appointment => appointment.id !== payload.id)],
);

const updateAppointment = (state, { payload } = {}) => set(
    state,
    'appointments',
    [...state.get('appointments').filter(appointment => appointment.id !== payload.id), payload]);

export default createReducer(initialState, {
    [ACTION_TYPES.SET_LOADING]: setLoading(),
    [ACTION_TYPES.SET_PATIENTS]: setPatients,

    [ACTION_TYPES.GET_PATIENTS_REQUEST]: setLoading(true),
    [ACTION_TYPES.GET_PATIENTS_SUCCESS]: [setPatients, setLoading(false)],
    [ACTION_TYPES.GET_PATIENTS_FAILURE]: setLoading(false),

    [ACTION_TYPES.GET_PERSONS_REQUEST]: setLoading(true),
    [ACTION_TYPES.GET_PERSONS_SUCCESS]: [setPersons, setLoading(false)],
    [ACTION_TYPES.GET_PERSONS_FAILURE]: setLoading(false),

    [ACTION_TYPES.OPEN_BARCODE]: setBarcodeOpened(true),
    [ACTION_TYPES.CLOSE_BARCODE]: setBarcodeOpened(false),

    [ACTION_TYPES.SET_LOGGED_IN_USER]: setLoggedInUser,

    [LOGOUT_SUCCESS]: resetState,

    [ACTION_TYPES.GET_CONSENTS_REQUEST]: setConsentsLoading(true),
    [ACTION_TYPES.CHECK_ASSIGN_CONSENTS_SUCCESS]: [setConsents, setConsentsLoading(false), setConsentsWasSigned(false)],
    [CONSENT_ACTION_TYPES.UPLOAD_SIGN_FILE_REQUEST]: setLoading(true),
    [CONSENT_ACTION_TYPES.CREATE_SIGN_CONSENT_FAILURE]: setLoading(false),
    [CONSENT_ACTION_TYPES.UPLOAD_SIGN_FILE_REQUEST_FAILURE]: setLoading(false),
    [CONSENT_ACTION_TYPES.CREATE_SIGN_CONSENT_SUCCESS]: [setLoading(false), updateConsent, setConsentsWasSigned(true)],
    [ACTION_TYPES.GET_APPOINTMENTS_REQUEST]: setAppointmentsLoading(true),
    [ACTION_TYPES.GET_APPOINTMENT_REQUEST]: setAppointmentsLoading(true),
    [ACTION_TYPES.GET_APPOINTMENTS_SUCCESS]: [setAppointments, setAppointmentsLoading(false)],
    [ACTION_TYPES.GET_APPOINTMENTS_FAILURE]: [setAppointmentsError, setAppointmentsLoading(false)],
    [ACTION_TYPES.CHANGE_APPOINTMENT_DATE_REQUEST]: setAppointmentsLoading(true),
    [ACTION_TYPES.CHANGE_APPOINTMENT_DATE_SUCCESS]: [setAppointmentsLoading(false), updateAppointment],
    [ACTION_TYPES.PATCH_APPOINTMENT_REQUEST]: setAppointmentsLoading(true),
    [ACTION_TYPES.PATCH_APPOINTMENT_SUCCESS]: [setAppointmentsLoading(false), updateAppointment],
    [ACTION_TYPES.CANCEL_APPOINTMENT_REQUEST]: setAppointmentsLoading(true),
    [ACTION_TYPES.CANCEL_APPOINTMENT_SUCCESS]: [setAppointmentsLoading(false), deleteAppointment],
    [ACTION_TYPES.APPROVE_APPOINTMENT_REQUEST]: setAppointmentsLoading(true),
    [ACTION_TYPES.APPROVE_APPOINTMENT_SUCCESS]: setAppointmentsLoading(false),
    [ACTION_TYPES.APPROVE_APPOINTMENT_FAILURE]: setAppointmentsLoading(false),
    [ACTION_TYPES.DELETE_ACCOUNT_REQUEST]: setLoading(true),
    [ACTION_TYPES.DELETE_ACCOUNT_SUCCESS]: setLoading(false),
    [ACTION_TYPES.DELETE_ACCOUNT_FAILURE]: setLoading(false),
});
