/* eslint-disable max-len */
import React from 'react';


const Option = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M18 4V0h-2v4h-2v2h2v14h2V6h2V4h-2zm-7-4H9v9H7v2h2v9h2v-9h2V9h-2V0zM4 14h2v2H4v4H2v-4H0v-2h2V0h2v14z" clipRule="evenodd" />
    </svg>
);

export default Option;
