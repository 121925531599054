import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './constants';


export const getLogoRequest = createAction(ACTION_TYPES.GET_LOGO_REQUEST);
export const getLogoSuccess = createAction(ACTION_TYPES.GET_LOGO_SUCCESS);
export const getLogoFailure = createAction(ACTION_TYPES.GET_LOGO_FAILURE);

export const switchMobile = createAction(ACTION_TYPES.SWITCH_MOBILE);

export * from './Camera/actions';
