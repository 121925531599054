import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './constants';


export const setLoading = createAction(ACTION_TYPES.SET_LOADING);
export const setPatients = createAction(ACTION_TYPES.SET_PATIENTS);

export const getPatientsRequest = createAction(ACTION_TYPES.GET_PATIENTS_REQUEST);
export const getPatientsSuccess = createAction(ACTION_TYPES.GET_PATIENTS_SUCCESS);
export const getPatientsFailure = createAction(ACTION_TYPES.GET_PATIENTS_FAILURE);

export const getPersonsRequest = createAction(ACTION_TYPES.GET_PERSONS_REQUEST);
export const getPersonsSuccess = createAction(ACTION_TYPES.GET_PERSONS_SUCCESS);
export const getPersonsFailure = createAction(ACTION_TYPES.GET_PERSONS_FAILURE);

export const openBarcode = createAction(ACTION_TYPES.OPEN_BARCODE);
export const closeBarcode = createAction(ACTION_TYPES.CLOSE_BARCODE);

export const setLoggedInUser = createAction(ACTION_TYPES.SET_LOGGED_IN_USER);

export const consentsRequest = createAction(ACTION_TYPES.GET_CONSENTS_REQUEST);
export const consentsRequestSuccess = createAction(ACTION_TYPES.GET_CONSENTS_SUCCESS);
export const consentsRequestFailure = createAction(ACTION_TYPES.GET_CONSENTS_FAILURE);

export const checkAssignConsentsRequest = createAction(ACTION_TYPES.CHECK_ASSIGN_CONSENTS_REQUEST);
export const checkAssignConsentsSuccess = createAction(ACTION_TYPES.CHECK_ASSIGN_CONSENTS_SUCCESS);
export const checkAssignConsentsFailure = createAction(ACTION_TYPES.CHECK_ASSIGN_CONSENTS_FAILURE);

export const getAppointmentsRequest = createAction(ACTION_TYPES.GET_APPOINTMENTS_REQUEST);
export const getAppointmentsSuccess = createAction(ACTION_TYPES.GET_APPOINTMENTS_SUCCESS);
export const getAppointmentsFailure = createAction(ACTION_TYPES.GET_APPOINTMENTS_FAILURE);
export const getAppointmentRequest = createAction(ACTION_TYPES.GET_APPOINTMENT_REQUEST);

export const changeAppointmentDateRequest = createAction(ACTION_TYPES.CHANGE_APPOINTMENT_DATE_REQUEST);
export const changeAppointmentDateFailure = createAction(ACTION_TYPES.CHANGE_APPOINTMENT_DATE_FAILURE);
export const changeAppointmentDateSuccess = createAction(ACTION_TYPES.CHANGE_APPOINTMENT_DATE_SUCCESS);

export const cancelAppointmentRequest = createAction(ACTION_TYPES.CANCEL_APPOINTMENT_REQUEST);
export const cancelAppointmentSuccess = createAction(ACTION_TYPES.CANCEL_APPOINTMENT_SUCCESS);
export const cancelAppointmentFailure = createAction(ACTION_TYPES.CANCEL_APPOINTMENT_FAILURE);

export const patchAppointmentRequest = createAction(ACTION_TYPES.PATCH_APPOINTMENT_REQUEST);
export const patchAppointmentSuccess = createAction(ACTION_TYPES.PATCH_APPOINTMENT_SUCCESS);
export const patchAppointmentFailure = createAction(ACTION_TYPES.PATCH_APPOINTMENT_FAILURE);

export const approveAppointmentRequest = createAction(ACTION_TYPES.APPROVE_APPOINTMENT_REQUEST);
export const approveAppointmentSuccess = createAction(ACTION_TYPES.APPROVE_APPOINTMENT_SUCCESS);
export const approveAppointmentFailure = createAction(ACTION_TYPES.APPROVE_APPOINTMENT_FAILURE);

export const deleteAccountRequest = createAction(ACTION_TYPES.DELETE_ACCOUNT_REQUEST);
export const deleteAccountSuccess = createAction(ACTION_TYPES.DELETE_ACCOUNT_SUCCESS);
export const deleteAccountFailure = createAction(ACTION_TYPES.DELETE_ACCOUNT_FAILURE);
