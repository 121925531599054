export const MAP_OPTIONS = {
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoom: 4,
    zoomControl: true,
    maxZoom: 17,
};

export const MAP_CENTER = {
    lat: 42,
    lng: -97,
};

export const POPUP_CONTINUE_BUTTON_ID = 'continue-button';
