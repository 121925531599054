import {
    validateDate,
} from 'components/Inputs/validators';


export const validate = ({ id, value }) => {
    switch (id) {
    case 'birth_date': return validateDate(value);
    case 'person':
    case 'policy_holder':
    case 'given':
    case 'family':
    case 'relation':
    case 'payor':
    case 'member_id': return Boolean(value);
    default: return true;
    }
};

export default validate;
