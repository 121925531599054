export const CAMERA_ACCESS_ERROR_MSG = 'To scan QR Codes, enable access to the camera';

export const SCANNER_WARNING = 'Do not close the app while scanning';

export const ERROR_KIT_ALREADY_USED = 'Request failed with status code 409';

export const FULL_HD_DIMENSIONS = {
    width: 1920,
    height: 1080,
};
