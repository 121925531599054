/* eslint-disable max-len */
import React from 'react';


const DeleteIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7 15.5C7 15.7761 7.22386 16 7.5 16H8.5C8.77614 16 9 15.7761 9 15.5V8.5C9 8.22386 8.77614 8 8.5 8H7.5C7.22386 8 7 8.22386 7 8.5V15.5ZM11 15.5C11 15.7761 11.2239 16 11.5 16H12.5C12.7761 16 13 15.7761 13 15.5V8.5C13 8.22386 12.7761 8 12.5 8H11.5C11.2239 8 11 8.22386 11 8.5V15.5ZM5 17.5C5 17.7761 5.22386 18 5.5 18H14.5C14.7761 18 15 17.7761 15 17.5V6.5C15 6.22386 14.7761 6 14.5 6H5.5C5.22386 6 5 6.22386 5 6.5V17.5ZM7 4H13V2.5C13 2.22386 12.7761 2 12.5 2H7.5C7.22386 2 7 2.22386 7 2.5V4ZM15.5 4C15.2239 4 15 3.77614 15 3.5V0.5C15 0.223858 14.7761 0 14.5 0H5.5C5.22386 0 5 0.223858 5 0.5V3.5C5 3.77614 4.77614 4 4.5 4H0.5C0.223858 4 0 4.22386 0 4.5V5.5C0 5.77614 0.223858 6 0.5 6H2.5C2.77614 6 3 6.22386 3 6.5V19C3 19.5523 3.44772 20 4 20H16C16.5523 20 17 19.5523 17 19V6.5C17 6.22386 17.2239 6 17.5 6H19.5C19.7761 6 20 5.77614 20 5.5V4.5C20 4.22386 19.7761 4 19.5 4H15.5Z" />
    </svg>
);

export default DeleteIcon;
