/* eslint-disable max-len */
import React from 'react';


const CopyLink = () => (
    <svg viewBox="0 0 20 20">
        <g clipPath="url(#clip0)">
            <path fillRule="evenodd" d="M18.239 1.761c-2.349-2.348-6.156-2.348-8.505 0L7.52 3.977c-.195.195-.195.511 0 .707l.71.71c.195.195.512.195.707 0l2.216-2.215c1.566-1.567 4.102-1.567 5.67 0 1.562 1.563 1.562 4.106 0 5.67l-2.216 2.215c-.195.195-.195.512 0 .707l.71.71c.196.195.512.195.707 0l2.216-2.215c2.348-2.349 2.348-6.156 0-8.505zm-9.39 15.06c-.757.757-1.765 1.174-2.835 1.174s-2.078-.417-2.835-1.174c-1.563-1.563-1.563-4.107 0-5.67l2.216-2.215c.195-.195.195-.511 0-.707l-.711-.71c-.196-.196-.512-.196-.707 0L1.76 9.733c-2.348 2.348-2.348 6.156 0 8.505C2.936 19.413 4.474 20 6.014 20s3.078-.587 4.253-1.761l2.215-2.215c.195-.196.195-.512 0-.708l-.711-.71c-.196-.196-.512-.196-.707 0L8.848 16.82zm3.06-10.148c.196-.195.512-.195.708 0l.71.71c.195.196.195.512 0 .708l-5.252 5.251c-.195.195-.511.195-.707 0l-.71-.71c-.195-.195-.195-.512 0-.707l5.252-5.252z" clipRule="evenodd" />
        </g>
        <defs>
            <clipPath id="clip0">
                <path d="M0 0H20V20H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default CopyLink;
