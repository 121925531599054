export default ({ palette = {} } = {}) => ({
    header: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '12px',
        position: 'relative',
        outline: '0',
        cursor: 'pointer',
        '&:hover svg': {
            fill: palette.coal.third,
        },
    },
    userInfo: {
        display: 'flex',
        alignItems: 'center',
        '@media (max-width:400px)': {
            display: 'none',
        },
    },
    userIcon: {
        '& svg': {
            fill: palette.ash[800],
        },
        '@media (min-width:400px)': {
            display: 'none',
        },
    },
    patientUserInfo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '34px',
        height: '34px',
    },
    patientUserIcon: {
        '& svg': {
            fill: palette.ash[800],
        },
    },
    userName: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginRight: '12px',
        fontSize: 14,
        '&:hover': {
            color: palette.coal[500],
        },
        '@media (max-width:400px)': {
            display: 'none',
        },
    },
    avatar: {
        marginRight: '12px',
        cursor: 'pointer',
        '@media (max-width:400px)': {
            marginRight: 0,
        },
    },
    link: {
        textDecoration: 'none',
        outline: 'none',
        color: palette.coal.main,
        cursor: 'pointer',
    },
    icon: {
        '& svg': {
            fill: palette.coal[400],
        },
    },
    iconTriangle: {
        '& svg': {
            fontSize: 15,
            fontWeight: '400',
            fill: palette.coal[400],
        },
        '@media (max-width:400px)': {
            display: 'none',
        },
    },
    menuProfile: {
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 16px 12px',
    },
    divider: {
        height: '1px',
        margin: '0 16px 8px',
        backgroundColor: palette.gray.main,
    },
    menuProfileHead: {
        fontSize: 14,
        fontWeight: 500,
        color: palette.coal.main,
    },
    menuProfileSubHead: {
        fontSize: 12,
        color: palette.coal[500],
        '&:hover': {
            color: palette.coal[500],
            textDecoration: 'unset',
        },
    },
    listItem: {
        padding: '8px 16px',
        cursor: 'pointer',
        '&:hover': { background: palette.ash[400] },
    },
    alignCenter: {
        display: 'flex',
        alignItems: 'center',
    },
    disabledMenuItem: {
        opacity: 0.5,
        cursor: 'not-allowed',
        '&:hover': { background: 'unset' },
    },
    itemText: {
        fontSize: 14,
        lineHeight: 1.43,
        color: palette.coal.tags,
    },
    popup: {
        maxWidth: 224,
        paddingBottom: '8px',
        position: 'absolute',
        zIndex: 999999,
        boxShadow: `0 1px 2px 0 ${palette.gray['08']}`,
        backgroundColor: palette.white.main,
        border: `solid 1px ${palette.gray.main}`,
        borderRadius: '4px',
        overflow: 'hidden',
        '@media (max-width:400px)': {
            padding: '12px 8px',
        },
    },
    tooltip: {
        padding: '2px 8px',
        borderRadius: 3,
        color: palette.white.main,
        backgroundColor: palette.coal.main,
    },
    appVersion: {
        padding: '8px 16px',
        fontSize: 12,
        color: palette.coal[500],
    },
    btnIcon: {
        marginRight: 12,

        '& svg, & svg *': {
            fill: palette.coal[700],
        }
     },
    roles: {
        padding: '8px 16px',
        fontSize: 12,
        color: palette.coal[500],
    },
});
