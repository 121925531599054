import { isString, isFunction, isNil } from 'lodash';
import { isFullObject } from '@flowhealth/utils';
import API from '@flowhealth/api';


export const BULK_COUNT = 100;

export const getAll = async (actions, id, string, params) => {
    if (!isFunction(actions) || isNil(id) || !isString(string)) return;
    let { results, count } = await actions(id, string, { ...params, count: BULK_COUNT });
    if (count > BULK_COUNT) {
        let res = []; // eslint-disable-line
        for (let i = 1; i < Math.ceil(count / BULK_COUNT); i++) {
            res.push(actions(id, string, { ...params, count: BULK_COUNT, after: i * BULK_COUNT }));
        }
        return Promise.all(res)
            .then(data => {
                data.forEach(item => {
                    results = results.concat(item.results);
                });
                return results;
            });
    }

    return results;
};

export const getAllSearch = async (params = {}) => {
    if (!isFullObject(params)) return;
    const limit = params.count;
    const factCount = limit && limit <= BULK_COUNT ? limit : BULK_COUNT;

    const { results, count } = await API.getInstance().search({ ...params, count: factCount, after: 0 });
    if (count < factCount) return { results, count };

    const condition = i => (
        limit <= count
            ? i < Math.ceil(limit / factCount)
            : i < Math.ceil(count / factCount)
    );

    const res = [];
    for (let i = 1; condition(i); i++) {
        res.push(API.getInstance().search({ ...params, count: factCount, after: i * BULK_COUNT }));
    }

    const data = await Promise.all(res);
    data.forEach(item => results.push(...item.results));

    return { results, count };
};

export const getNextSearch = async (params = {}) => {
    if (!isFullObject(params)) return;

    const limit = params.count;
    const factCount = limit && limit <= BULK_COUNT ? limit : BULK_COUNT;
    const response = await API.getInstance().search({ ...params, count: factCount, after: params.after });

    return response;
};

export const asyncForEach = async ({ count } = {}, callback) => {
    if (!count || !isFunction(callback)) return;
    for (let index = 1; index < Math.ceil(count / BULK_COUNT); index++) {
        await callback(index); //eslint-disable-line
    }
};

export const getAllFromEdge = async (graphId, edge, params) => {
    if (!isString(graphId) || !isString(edge)) return;
    const { results, count } = await API.getInstance()
        .getGraphsOnEdge(graphId, edge, { ...params, count: BULK_COUNT, after: 0 });
    if (count < BULK_COUNT) return { results, count };

    let res = []; // eslint-disable-line
    for (let i = 1; i < Math.ceil(count / BULK_COUNT); i++) {
        res.push(
            API.getInstance().getGraphsOnEdge(graphId, edge, { ...params, count: BULK_COUNT, after: i * BULK_COUNT }),
        );
    }

    const data = await Promise.all(res);
    data.forEach(item => results.push(...item.results));

    return { results, count };
};
