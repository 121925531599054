import { put, takeLatest } from 'redux-saga/effects';
import { App } from '@flowhealth/app';

import { UNIQ_ERROR_CODE } from 'API/constants';

import { openErrorMessage } from 'components/Message/actions';

import { PATIENT_PORTAL_AUTH_KEY } from 'containers/Auth/constants';
import { errorMessage } from 'containers/Errors/handleErrors';
import { resetUserState } from 'containers/User/actions';


function* errorHandler({ type, payload, customMessage }) {
    if (new RegExp(`^${PATIENT_PORTAL_AUTH_KEY}_*`).test(type)) return;

    const error = payload && payload.error;
    if (!error) return;
    const { message, code } = error.response ? errorMessage(error) : error;

    switch (code) {
    case 'SessionExpired':
    case 'Unauthorized':
    case 'InvalidSession':
    case 'TokenNotFound': {
        // case 'ObjectDeleted':
        // case 'AccessForbidden':
        const token = App.getInstance().getToken();
        if (!token) break; // reset authToken only once
        yield put(resetUserState());
        App.getInstance().redirectToLoginPortal(window.location.href);

        break;
    }
    case 'FailedToParseQuery': {
        yield put(openErrorMessage('Wrong search request')); // todo add additional check for type
        return null;
    }
    // eslint-disable-next-line max-len
    case !payload.showMessageIfUniqError && UNIQ_ERROR_CODE: // This error is redefined with FE text and is shown as an input message
        break;
    case 'ObjectNotExists':
    case 'ObjectWasDeleted':
    default:
        yield put(openErrorMessage(customMessage || message)); // show all errors by default
        break;
    }
}

function* Saga() {
    yield takeLatest(action => /_FAILURE.*/.test(action.type), errorHandler);
}

export default Saga;
