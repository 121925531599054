/* eslint-disable max-len */
import React from 'react';


const Desktop = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.8764 1C17.9786 1 18.9361 1.99313 18.9361 2.95913L19 13.25C19 14.2125 18.1014 15 17.0032 15L12 15V17H16.5C16.7761 17 17 17.2239 17 17.5V18.5C17 18.7761 16.7761 19 16.5 19H3.5C3.22386 19 3 18.7761 3 18.5V17.5C3 17.2239 3.22386 17 3.5 17H8V15L3.0418 15C1.9205 15 1.00454 14.0792 1.00513 12.9579C1.00658 10.1936 1.00222 5.4315 1.00059 3.65036L1.00059 3.64717L1.00058 3.64274L1.00058 3.63811L1 2.95912C1 1.89337 1.79872 1 2.90196 1H16.8764ZM17 9.75L17.0032 9.75V12.4004C17.0032 12.8694 16.5689 13.25 16.0337 13.25L4.02716 13.25C3.47604 13.25 3.02876 12.858 3.02876 12.375L3.02876 9.75L3 9.75L3 3.91667C3 3.275 3.4501 2.75 4.00023 2.75L15.9998 2.75C16.5519 2.75 17 3.27267 17 3.91667V9.75ZM5 9.75V10.5C5 10.7761 5.22386 11 5.5 11H9.5C9.77614 11 10 10.7761 10 10.5V9.75L10 9.5C10 9.22385 9.77614 9 9.5 9H5.5C5.22386 8.99999 5 9.22385 5 9.5V9.75ZM12 5.5V6.5C12 6.77614 11.7761 7 11.5 7L5.5 7C5.22386 6.99999 5 6.77614 5 6.49999V5.49999C5 5.22385 5.22386 4.99999 5.5 4.99999L11.5 5C11.7761 5 12 5.22385 12 5.5Z" />
    </svg>
);

export default Desktop;
