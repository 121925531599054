export default (palette = {}) => ({
    MuiAppBar: {
        colorPrimary: {
            color: 'rgba(51,51,51,.85)',
            backgroundColor: palette.white.main,
        },
        root: {
            boxShadow: 'none',
            borderBottom: `1px solid ${palette.ash[600]}`,
        },
    },
    MuiToolbar: {
        root: {
            '@media (min-width: 600px)': {
                minHeight: '5px !important',
                maxHeight: '65px',
            },
        },
    },

    // Typogrpahy
    MuiTypography: {
        body1: {
            fontSize: '14px',
        },
        subtitle1: {
            fontWeight: 'inherit',
            fontSize: '13px',
        },
        h5: {
            fontSize: '17px',
        },
        title: {
            fontSize: '20px',
        },
    },

    MuiAvatar: {
        root: {
            width: '34px',
            height: '34px',
        },
    },

    MuiListItem: {
        root: {
            paddingTop: '8px',
            paddingBottom: '8px',
        },
        button: {
            '&:hover': {
                backgroundColor: palette.ash[300],
            },
        },
    },

    MuiListItemText: {
        primary: {
            lineHeight: '1.5em',
        },
    },

    MuiMenuItem: {
        root: {
            fontSize: '13px',
            height: '19px',
        },
    },

    MuiPaper: {
        elevation1: {
            boxShadow: `0 1px 2px ${palette.gray['05']}`,
            border: `1px solid ${palette.gray[125]}`,
        },
        elevation2: {
            boxShadow: `0 1px 2px ${palette.gray['05']}`,
            border: `1px solid ${palette.gray[125]}`,
        },

        elevation8: {
            boxShadow: `0 4px 8px ${palette.gray.main}`,
            border: `1px solid ${palette.gray[125]}`,
        },
        rounded: {
            borderRadius: '3px',
        },
    },

    MuiExpansionPanel: {
        root: {
            marginBottom: '-1px',
            '&:before': {
                display: 'none',
            },
        },
    },
    MuiExpansionPanelActions: {
        root: {
            justifyContent: 'flex-start',
            paddingLeft: '24px',
        },
    },

    MuiCollapse: {
        entered: {
            overflow: 'initial',
        },
    },

    // Table

    MuiTableRow: {
        head: {
            height: '44px',
        },
        root: {
            height: 'auto',
            boxSizing: 'border-box',
            borderBottom: `1px solid ${palette.ash[400]}`,
        },
        footer: {
            height: '80px',
        },
    },
    MuiTableCell: {
        root: {
            padding: '15px',
            borderBottom: 0,
            fontSize: '13px',
            lineHeight: '20px',
            position: 'relative',
            '&:first-child': {
                paddingLeft: '32px',
                position: 'relative',
                borderColor: palette.transparent,
                overflow: 'visible',
            },
            '&:last-child': {
                paddingRight: '32px !important',
                position: 'relative',
                borderColor: palette.transparent,
                overflow: 'visible',
            },
        },
        paddingNone: {
            padding: '15px 3px',

            '&:last-child': {
                padding: '15px 3px',
            },
        },
        head: {
            color: palette.coal[400],
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '1.5',
            wordBreak: 'keep-all',
            textTransform: 'uppercase',

            '& svg': {
                fontSize: '12px',
            },
        },
        body: {
            display: 'table-cell',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '1.57',
            color: palette.coal.main,
            wordBreak: 'normal',
            whiteSpace: 'normal',
            letterSpacing: 'normal',
            maxWidth: '250px',
        },
    },
    MuiTablePagination: {
        selectIcon: {
            top: '7px',
            right: '1px',
        },
        select: {
            minHeight: '19px !important',
            lineHeight: '19px',
        },
    },

    // Buttons
    MuiButton: {
        root: {
            textTransform: 'none',
            fontSize: '13px',
            lineHeight: '1.45em',
            fontWeight: 400,
            minHeight: '32px',
        },

        text: {
            padding: '6px 16px',
        },

        contained: {
            boxShadow: 'none',
        },
        sizeLarge: {
            padding: '12px 32px',
            fontSize: '16px',
            maxHeight: '44px',
            lineHeight: 1,
        },
    },
    MuiIconButton: {
        root: {
            width: '45px',
            height: '45px',
        },
        label: {
            alignItems: 'center',
        },
    },

    // Input
    MuiInput: {
        underline: {
            '&:before': {
                display: 'none',
            },
            '&:after': {
                display: 'none',
            },
        },
        input: {
            border: `1px solid ${palette.ash[400]}`,
            borderRadius: '3px',
            fontSize: '13px',
            padding: '4px 14px',
            marginTop: '2px',
            '&:focus': {
                borderColor: palette.ash[600],
            },
            minHeight: '32px',
            boxSizing: 'border-box',
        },
        error: {
            color: palette.red.main,
            borderColor: palette.red.main,
            '& $input': {
                borderColor: `${palette.red.main} !important`,
            },
        },
        multiline: {
            padding: '0 0 2px',
        },
        inputMultiline: {
            padding: '5px 14px', // decreased to avoid scroll in open hours field
            lineHeight: '16px',

        },
        root: {
            minHeight: '32px',
            marginTop: '0px !important',
        },
    },
    MuiInputAdornment: {
        root: {
            height: 'inherit',
            marginTop: '5px',
            maxHeight: '38px',
            alignItems: 'initial',
        },
    },

    MuiInputLabel: {
        formControl: {
            position: 'relative',
        },
    },

    MuiFormLabel: {
        root: {},
    },

    MuiFormHelperText: {
        root: {
            fontSize: '10.4px',
        },
    },

    MuiFormControlLabel: {
        label: {
            fontSize: '13px',
        },
    },

    MuiSelect: {
        select: {
            border: `solid 1px ${palette.gray.main}`,
            padding: '8px 14px',
            fontSize: 14,
            marginTop: 5,
            minHeight: '38px !important',
            boxSizing: 'border-box',
            borderRadius: 4,
            backgroundColor: palette.white.main,
            color: palette.coal.main,
            '&:focus': {
                borderRadius: 4,
            },
        },

    },

    MuiChip: {
        root: {
            height: '20px',
            fontSize: '13px',
            margin: '0 3px 3px 0',
        },
        deleteIcon: {
            fontSize: '18px',
            marginRight: '2px',
        },
        label: {
            paddingLeft: '8px',
            paddingRight: '8px',
        },
    },

    MuiSwitch: {
        root: {
            '&$checked': {
                transform: 'translateX(18px)',
                '& + $bar': {
                    backgroundColor: 'rgb(100, 189, 99)',
                    borderColor: 'rgb(100, 189, 99)',
                    boxShadow: 'rgb(100, 189, 99) 0px 0px 0px 10px inset',
                    transition: 'border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s',
                    opacity: '1',
                },
            },
        },
        icon: {
            backgroundColor: palette.white.main,
            width: '18px',
            height: '18px',
            boxShadow: '0 1px 3px rgba(0,0,0,.4)',
        },
        bar: {
            width: '38px',
            height: '20px',
            border: `1px solid ${palette.ash[400]}`,
            borderRadius: '16px',
            marginTop: '-10px',
            opacity: '1',
            backgroundColor: 'rgb(255, 255, 255)',
            borderColor: 'rgb(223, 223, 223)',
            boxShadow: 'rgb(223, 223, 223) 0px 0px 0px 0px inset',
            transition: 'border 0.4s ease 0s, box-shadow 0.4s ease 0s',
        },
        checked: {},
    },

    MuiPrivateTabScrollButton: {
        root: {
            width: '30px',
        },
    },

    // Tab
    MuiTab: {
        labelContainer: {
            textAlign: 'center',
            padding: '0 !important',
        },

        label: {
            fontSize: '14px',
            fontWeight: 400,
            '@media (min-width: 960px)': {
                fontSize: '14px',
            },

        },

        root: {
            height: '100%',
            minHeight: '40px',
            textTransform: 'inherit',
            color: palette.coal[500],
            padding: '0 4px',
            margin: '0 30px',
            '&$selected': {
                border: 'none',
                boxSizing: 'border-box',
                borderBottom: `2px solid ${palette.naviBlue.dark}`,
                paddingTop: '2px',
                color: palette.naviBlue.dark,
            },
            '@media (min-width: 960px)': {
                minWidth: 'auto',
            },
        },
        textColorPrimary: {
            lineHeight: 1.43,
            color: palette.coal[500],
            '&:first-child': {
                marginLeft: '6px',
            },
            '&:hover': {
                color: palette.naviBlue.dark,
            },
            '&$selected': {
                color: palette.naviBlue.dark,
            },
        },
    },

    MuiTabs: {
        root: {
            backgroundColor: palette.white.main,
            borderBottom: `1px solid ${palette.ash[400]}`,
            borderRadius: '3px 3px 0 0',
        },
        flexContainer: {
            height: '47px',
        },
        indicator: {
            display: 'none !important',
        },
    },

    MuiTouchRipple: {
        root: {
            display: 'none',
        },
    },

    // Grid
    MuiGrid: {
        container: {
            '&$spacing-xs-24': {
                margin: '-6px -12px',
                '& > $item': {
                    padding: '6px 12px',

                },
            },
        },
    },
});
