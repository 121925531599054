import { createSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form/immutable';
import { safeGet } from '@flowhealth/utils';

import { convertToMutable } from 'utils/select';


export const selectSyncErrors = createSelector(
    (state, ownProps) => getFormSyncErrors(safeGet(ownProps, 'meta.form'))(state),
    state => convertToMutable(state),
);
