/* eslint-disable max-len */
import React from 'react';


const Triangle = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M8.536 6.672c0-.446.538-.669.853-.354l2.975 2.975c.39.39.39 1.024 0 1.414l-2.975 2.975c-.315.315-.853.092-.853-.354V6.672z" clipRule="evenodd" />
    </svg>
);

export default Triangle;
