import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as Integrations from '@sentry/integrations';
import { safeGet } from '@flowhealth/utils';

const SENTRY_SKIP_EVENT_MESSAGES = [ // FS-6793
    {
        type: 'UnhandledRejection',
        match: 'Non-Error promise rejection captured with value: Malformed message. Error: ',
    },
    {
        type: 'Event',
        match: 'Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type',
    },
    {
        type: 'Event',
        match: 'Non-Error exception captured with keys: currentTarget, isTrusted, target, type',
    },
];

const SENTRY_ENVS = ['dev-minor', 'stage', 'preprod', 'prod'];

if (SENTRY_ENVS.includes(process.env.REACT_APP_ENV)) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        blacklistUrls: [
            /www\.googletagmanager\.com/,
            /www\.google-analytics\.com/,
        ],
        beforeSend: event => {
            const skip = safeGet(event, 'exception.values', [])
                .some(value => {
                    const type = safeGet(value, 'type');
                    const text = safeGet(value, 'value');
                    return SENTRY_SKIP_EVENT_MESSAGES
                        .some(config => config.type === type && text.includes(config.match));
                });
            if (skip) return null;
            return event;
        },
        environment: process.env.REACT_APP_ENV,
        integrations: [new Integrations.Dedupe(), new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}
