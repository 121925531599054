import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import withStyles from '@material-ui/core/styles/withStyles';
import { safeGet } from '@flowhealth/utils';

import { makeSelectDialogOpen } from './selector';
import { DIALOG_WIDTH } from './constants';

const styles = ({ palette = {} } = {}) => ({
    root: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1300,
        position: 'fixed',
    },
    backDrop: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 0,
        position: 'fixed',
        touchAction: 'none',
        backgroundColor: palette.gray[500],
        '-webkit-tap-highlight-color': palette.transparent,
        opacity: 1,
        transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    scrollPaper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        outline: 'none',
        zIndex: 1,
        opacity: 1,
        transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 1 auto',
        maxHeight: 'calc(100% - 96px)',
        margin: '48px',
        position: 'relative',
        overflowY: 'auto',
        backgroundColor: palette.white.main,
        // eslint-disable-next-line max-len
        boxShadow: '0 11px 15px -7px rgba(0,0,0,0.2), 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12)',
        borderRadius: '3px',
        '&[id^=lab] .country-list': {
            position: 'fixed',
            width: 'min(calc((100% - 96px) / 3 - 24px), 240px)',
        },
    },
    mobilePaper: {
        marginLeft: 0,
        marginRight: 0,
    },
    big: {
        maxWidth: '800px',
        width: '100%',
    },
    medium: {
        maxWidth: '600px',
        width: '100%',
    },
    small: {
        maxWidth: '480px',
        width: '100%',
    },
    hide: {
        display: 'none',
    },
    isAdaptive: {
        backgroundColor: palette.white.main,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
});


class Dialog extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        className: PropTypes.string,
        width: PropTypes.oneOf(Object.values(DIALOG_WIDTH)),
        isOpen: PropTypes.bool,
        disableBackdropClick: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
        isAdaptive: PropTypes.bool,
        onCloseByBackdrop: PropTypes.func,
        dispatch: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
        contentClassName: PropTypes.string,

        // e2e test
        dataTestId: PropTypes.string,
    };

    state = {
        app: null,
    };

    componentDidMount() {
        this.setState({ app: document.querySelector('.c-app') || document.body });
    }

    handleClickBackdrop = () => {
        const { onCloseByBackdrop } = this.props;
        const isBackdropClickDisabled = safeGet(this.props, 'disableBackdropClick', false);
        if (isBackdropClickDisabled) return null;
        onCloseByBackdrop();
    };

    render() {
        const {
            id, className, isOpen, classes, children, width, isAdaptive, dataTestId, contentClassName,
        } = this.props;
        const { app } = this.state;
        if (!isOpen || !app) return <React.Fragment />;
        if (isAdaptive && isMobile) {
            return <div id={id} className={classes.isAdaptive}>{children}</div>;
        }
        return createPortal(
            <div
                id={id}
                role="dialog"
                className={cn('c-dialog', classes.root, className, !isOpen && classes.hide)}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                data-tid={dataTestId}
            >
                <div className={classes.backDrop} aria-hidden="true" onClick={this.handleClickBackdrop} />
                <div className={classes.scrollPaper} role="document" tabIndex="-1">
                    <div
                        className={cn(
                            'c-dialog__paper',
                            classes.paper,
                            classes[width],
                            isMobile && classes.mobilePaper,
                            contentClassName,
                        )}
                    >
                        {children}
                    </div>
                </div>
            </div>,
            app,
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isOpen: makeSelectDialogOpen,
});

const withConnect = connect(mapStateToProps);

export default compose(
    withStyles(styles),
    withConnect,
)(Dialog);
