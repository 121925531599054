export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const DATE_MONTH_FORMAT = 'MMM DD';
export const DATE_SEPARATOR = '/';
export const DATE_FORMAT = `MM${DATE_SEPARATOR}DD${DATE_SEPARATOR}YYYY`;
export const DATE_RANGE_FORMAT = `${DATE_FORMAT} - ${DATE_FORMAT}`;
export const DOWNLOAD_REPORTS_FORMAT = 'YYYY-MM-DD h:mm a';
export const TIME_MILLISECONDS_FORMAT = 'HH:mm:ss.SSS';
export const GRAPH_SYSTEM_PROPS = ['object_type', 'id', 'created_at', 'created_by', 'modified_at', 'modified_by'];

export const DATE_FILTER_FORMAT = 'YYYY-MM-DD';
export const TIME_SEPARATOR = ':';
export const TIME_FORMAT_PLACEHOLDER = `HH${TIME_SEPARATOR}MM am`;
export const TIME_FORMAT_12 = `hh${TIME_SEPARATOR}mm a`;
export const TIME_FORMAT_SHORT_12 = `h${TIME_SEPARATOR}mm a`;
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT_12}`;
export const DATE_TIME_FORMAT_SHORT = `${DATE_FORMAT} ${TIME_FORMAT_SHORT_12}`;
