/* eslint-disable max-depth */
import React, { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withStyles } from '@material-ui/core/styles';
import { SpinLoader } from '@flowhealth/ui-components';
import { isMobile, isIOS } from 'react-device-detect';
import { IMAGE_PNG, BINAX_NOW_CODE, CARE_START, FLOW_FLEX_CODE } from '@flowhealth/constants';
import Webcam from 'react-webcam';
import onScanCompleteSound from 'audios/onScanComplete.wav';
import cn from 'classnames';
import isFunction from 'lodash/isFunction';
import { safeGet } from '@flowhealth/utils';

import FlowIcon from 'components/FlowIcon';
import FlowButton from 'components/Button';
import { ICONS_TYPES } from 'components/FlowIcon/constants';
import {
    closeCustomMessage as closeCustomMessageAction,
    openCustomMessage as openCustomMessageAction,
} from 'components/Message/actions';
import { selectMessage } from 'components/Message/selector';
import Message, { MESSAGE_TYPES } from 'components/Message';

import { selectCamera } from 'containers/App/selectors';

import { ErrorScreen, IssuesHint } from './components';
import { CAMERA_ACCESS_ERROR_MSG, SCANNER_WARNING, FULL_HD_DIMENSIONS } from './constants';
import { DataMatrixCodeReader, QRCodeReader } from './codeReaders';
import { drawVideoFrame } from './utils';


const styles = ({ palette = {} } = {}) => ({
    root: {
        width: '100vw',
        position: 'absolute',
        top: 0,
        height: isMobile && isIOS ? '-webkit-fill-available' : '100vh',
        overflow: 'hidden',
        background: palette.black[100],

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        '& video': {
            width: '100%',
        },
    },
    captured: {
        border: `4px solid ${palette.green.main}`,
    },
    error: {
        border: `4px solid ${palette.red.main}`,
    },
    canvas: {
        visibility: 'hidden',
        position: 'absolute',
        zIndex: 99999,
    },
    close: {
        position: 'absolute',
        zIndex: 3000,

        top: 20,
        right: 20,
        cursor: 'pointer',

        width: 20,
        height: 20,
        background: palette.transparent,
        '& svg': {
            fill: palette.white.main,
        },
        '&:hover': {
            background: palette.ash[400],
        },
    },
    webcam: {
        background: palette.black[100],
        height: '100%',
        width: '100%',
    },
});

const codeReader = canvas => Promise.any([
    QRCodeReader(canvas),
    DataMatrixCodeReader(canvas),
]);

const FIVE_SECONDS_MS = 5000;

const CameraScanner = ({
    classes,
    className,
    error,
    setError,
    isLoading,
    message,

    codeCropConfig,
    imgCropConfig,

    onCaptured,
    onClose,

    openCustomMessage,
    closeCustomMessage,

    LensComponent,
    camera,
    onTakePhoto,
    batch,
}) => {
    const isScanningRef = useRef(false);
    const [showHint, setShowHint] = useState(false);

    const canvasForFrameRef = useRef(null);
    const canvasForCodeRef = useRef(null);
    const canvasForFullImage = useRef(null);
    const webcamRef = useRef(null);

    const scanIntervalRef = useRef(null);
    const [captured, setCaptured] = useState(false);

    const audio = useMemo(() => new Audio(onScanCompleteSound), []);

    const customMessage = safeGet(message, MESSAGE_TYPES.custom, {});

    const setCameraAccessError = useCallback(() => {
        setError({ error: CAMERA_ACCESS_ERROR_MSG });
    }, [setError]);

    useEffect(() => {
        if ([BINAX_NOW_CODE, CARE_START, FLOW_FLEX_CODE].includes(batch?.panel_code)) {
            setTimeout(() => {
                setShowHint(true)
            }, FIVE_SECONDS_MS);
        }
    }, []);

    const capture = useCallback(async () => {
        const isScanning = isScanningRef.current;
        const webcam = webcamRef?.current;
        const canvasForCroppedImage = canvasForFrameRef.current;
        const canvasForCode = canvasForCodeRef.current;
        const canvasForFullImg = canvasForFullImage.current;

        if (isLoading || !webcam || isScanning || !canvasForCroppedImage || !canvasForCode || camera.isOpen) return;
        isScanningRef.current = true;
        try {
            drawVideoFrame(webcam.video, canvasForCroppedImage, imgCropConfig);
            drawVideoFrame(webcam.video, canvasForCode, codeCropConfig);
            drawVideoFrame(webcam.video, canvasForFullImg);

            const text = await codeReader(canvasForCode);
            openCustomMessage({
                type: MESSAGE_TYPES.custom,
                text: SCANNER_WARNING,
            });

            const croppedImgSrc = canvasForCroppedImage.toDataURL();
            const imgFullSrc = canvasForFullImg.toDataURL();

            if (!imgFullSrc) {
                throw new Error('Image src doen\'t exist');
            }

            if (scanIntervalRef.current) {
                clearInterval(scanIntervalRef.current);
                scanIntervalRef.current = null;
            }
            const playAudio = () => {
                try {
                    audio.play();
                } catch (e) {
                    return e;
                }
            };

            setTimeout(playAudio, 500);
            if (onCaptured) {
                onCaptured({
                    kitId: text,
                    croppedImgSrc,
                    imgFullSrc,
                });
            }
        } catch (error) {
            console.error(error); // eslint-disable-line no-console
        }
        isScanningRef.current = false;
    }, [audio, isLoading, onCaptured, openCustomMessage, codeCropConfig, imgCropConfig]);

    const retryAction = useCallback(() => setError({ error: null }), [setError]);

    useEffect(() => {
        if (error && scanIntervalRef.current) {
            clearInterval(scanIntervalRef.current);
            scanIntervalRef.current = null;
        }
        if (!error && !scanIntervalRef.current) {
            setCaptured(false);
            scanIntervalRef.current = setInterval(capture, 200);
        }

        return () => clearInterval(scanIntervalRef.current);
    }, [capture, error]);

    useEffect(() => {
        closeCustomMessage();
        return () => { closeCustomMessage(); };
    }, [closeCustomMessage]);

    const closeIcon = useMemo(() => {
        if (!isFunction(onClose)) return;
        return (
            <FlowIcon
                id="ScannerCloseId"
                className={classes.close}
                type={ICONS_TYPES.close}
                color={FlowButton.BUTTON_COLORS.white}
                disabled={isLoading}
                onClick={onClose}
            />
        );
    }, [classes.close, isLoading, onClose]);

    return (
        <div className={cn(classes.root, className)}>
            {!error ? (
                <>
                    <Webcam
                        ref={webcamRef}
                        className={classes.webcam}
                        screenshotFormat={IMAGE_PNG}
                        videoConstraints={{
                            focusMode: 'auto',
                            focusDistance: undefined,
                            facingMode: { ideal: 'environment' },
                            width: { ideal: FULL_HD_DIMENSIONS.width },
                            height: { ideal: FULL_HD_DIMENSIONS.height },
                        }}
                        audio={false}
                        screenshotQuality={1.00}
                        onUserMediaError={setCameraAccessError}
                        forceScreenshotSourceSize
                    />
                    {LensComponent && <LensComponent captured={captured} error={error} />}
                    {isLoading && <SpinLoader />}
                    <canvas className={classes.canvas} ref={canvasForFrameRef} />
                    <canvas className={classes.canvas} ref={canvasForCodeRef} />
                    <canvas className={classes.canvas} ref={canvasForFullImage} />
                    {closeIcon}
                </>
            ) : (
                <ErrorScreen
                    title={(error?.message || error) === CAMERA_ACCESS_ERROR_MSG
                        ? 'Access Needed'
                        : 'Something went wrong'}
                    subtitle={error?.message || error}
                    retryAction={retryAction}
                />
            )}
            {customMessage?.type && (
                <Message
                    id={customMessage.type}
                    variant={customMessage.type}
                    vertical="top"
                    cancelAutoHide
                    horizontal={customMessage.type === MESSAGE_TYPES.error ? 'right' : 'center'}
                    customComponent={<div>{customMessage.text}</div>}
                />
            )}
            {(!isLoading && showHint) && <IssuesHint onTakePhoto={onTakePhoto} LensComponent={LensComponent} />}
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    message: selectMessage,
    camera: selectCamera,
});

const mapDispatchToProps = {
    openCustomMessage: openCustomMessageAction,
    closeCustomMessage: closeCustomMessageAction,
};

const withStore = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withStyles(styles),
    memo,
    withStore,
)(CameraScanner);
