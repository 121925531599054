export const code2display = (set, code) => {
    if (!set || !Array.isArray(set)) return;
    const item = set.find(item => item.code === code);
    return item ? item.display : code;
};

export const code2groupName = (set, code) => {
    if (!set || !Array.isArray(set)) return;
    const item = set.find(item => item.code === code);
    return item?.group_name ?? code;
};
