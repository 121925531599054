export const INSURANCE_FORM = 'insuranceForm';

export const INITIAL_FORM_STATE = {
    person: {
        value: '',
        valid: false,
        touched: false,
    },
    policy_holder: {
        value: '',
        valid: false,
        touched: false,
    },

    given: {
        value: '',
        valid: false,
        touched: false,
    },
    family: {
        value: '',
        valid: false,
        touched: false,
    },
    birth_date: {
        value: '',
        valid: false,
        touched: false,
    },
    relation: {
        value: '',
        valid: false,
        touched: false,
    },

    payor: {
        value: '',
        valid: false,
        touched: false,
    },
    member_id: {
        value: '',
        valid: false,
        touched: false,
    },
    group_number: {
        value: '',
        valid: false,
        touched: false,
    },

    photoFrontInitial: undefined,
    photoBackInitial: undefined,

    loading: false,
    error: {},
};
