import { createUseStyles } from '@flowhealth/ui-components';


export const useStyles = createUseStyles(() => ({
    wrapper: {
        height: 'unset',
        boxShadow: 'unset',
        paddingBottom: 10,
    },
    form: {
        width: 'unset',
    },
    description: {
        margin: '20px 0px 36px',
    },
}));
