export const KEY_PATIENT_PORTAL = 'patientPortal';
export const KEY_USER_CONSENTS = 'userConsents';

export const PATIENT_PORTAL_QR_DIALOG_ID = 'patientPortalQRDialog';
export const MAX_PATIENTS = 100;

export const ACTIVATE_KIT_DIALOG_ID = 'activateKitDialog';

export const APPOINTMENTS_OBJECTS_EXPAND = 'event,created_by,testing_location{collection_location},insurance,patient,practice';

export const SET_LOADING = `SET_LOADING__${KEY_PATIENT_PORTAL}`;
export const SET_PATIENTS = `SET_PATIENTS__${KEY_PATIENT_PORTAL}`;

export const GET_PATIENTS_REQUEST = `GET_PATIENTS_REQUEST__${KEY_PATIENT_PORTAL}`;
export const GET_PATIENTS_SUCCESS = `GET_PATIENTS_SUCCESS__${KEY_PATIENT_PORTAL}`;
export const GET_PATIENTS_FAILURE = `GET_PATIENTS_FAILURE__${KEY_PATIENT_PORTAL}`;

export const GET_PERSONS_REQUEST = `GET_PERSONS_REQUEST__${KEY_PATIENT_PORTAL}`;
export const GET_PERSONS_SUCCESS = `GET_PERSONS_SUCCESS__${KEY_PATIENT_PORTAL}`;
export const GET_PERSONS_FAILURE = `GET_PERSONS_FAILURE__${KEY_PATIENT_PORTAL}`;

export const OPEN_BARCODE = `OPEN_BARCODE__${KEY_PATIENT_PORTAL}`;
export const CLOSE_BARCODE = `CLOSE_BARCODE__${KEY_PATIENT_PORTAL}`;

export const SET_LOGGED_IN_USER = `SET_LOGGED_IN_USER__${KEY_PATIENT_PORTAL}`;

export const GET_CONSENTS_REQUEST = `GET_CONSENT_REQUEST__${KEY_USER_CONSENTS}`;
export const GET_CONSENTS_SUCCESS = `GET_CONSENTS_SUCCESS__${KEY_USER_CONSENTS}`;
export const GET_CONSENTS_FAILURE = `GET_CONSENTS_FAILURE__${KEY_USER_CONSENTS}`;

export const CHECK_ASSIGN_CONSENTS_REQUEST = `CHECK_ASSIGN_CONSENTS_REQUEST__${KEY_USER_CONSENTS}`;
export const CHECK_ASSIGN_CONSENTS_SUCCESS = `CHECK_ASSIGN_CONSENTS_SUCCESS__${KEY_USER_CONSENTS}`;
export const CHECK_ASSIGN_CONSENTS_FAILURE = `CHECK_ASSIGN_CONSENTS_FAILURE__${KEY_USER_CONSENTS}`;

export const GET_APPOINTMENTS_REQUEST = `GET_APPOINTMENTS_REQUEST__${KEY_PATIENT_PORTAL}`;
export const GET_APPOINTMENTS_SUCCESS = `GET_APPOINTMENTS_SUCCESS__${KEY_PATIENT_PORTAL}`;
export const GET_APPOINTMENTS_FAILURE = `GET_APPOINTMENTS_FAILURE__${KEY_PATIENT_PORTAL}`;
export const GET_APPOINTMENT_REQUEST = `GET_APPOINTMENT_REQUEST__${KEY_PATIENT_PORTAL}`;

export const CHANGE_APPOINTMENT_DATE_REQUEST = `CHANGE_APPOINTMENT_DATE_REQUEST__${KEY_PATIENT_PORTAL}`;
export const CHANGE_APPOINTMENT_DATE_FAILURE = `CHANGE_APPOINTMENT_DATE_FAILURE__${KEY_PATIENT_PORTAL}`;
export const CHANGE_APPOINTMENT_DATE_SUCCESS = `CHANGE_APPOINTMENT_DATE_SUCCESS__${KEY_PATIENT_PORTAL}`;

export const CANCEL_APPOINTMENT_REQUEST = `CANCEL_APPOINTMENT_REQUEST__${KEY_PATIENT_PORTAL}`;
export const CANCEL_APPOINTMENT_SUCCESS = `CANCEL_APPOINTMENT_SUCCESS__${KEY_PATIENT_PORTAL}`;
export const CANCEL_APPOINTMENT_FAILURE = `CANCEL_APPOINTMENT_FAILURE__${KEY_PATIENT_PORTAL}`;

export const PATCH_APPOINTMENT_REQUEST = `PATCH_APPOINTMENT_REQUEST__${KEY_PATIENT_PORTAL}`;
export const PATCH_APPOINTMENT_SUCCESS = `PATCH_APPOINTMENT_SUCCESS__${KEY_PATIENT_PORTAL}`;
export const PATCH_APPOINTMENT_FAILURE = `PATCH_APPOINTMENT_FAILURE__${KEY_PATIENT_PORTAL}`;

export const APPROVE_APPOINTMENT_REQUEST = `APPROVE_APPOINTMENT_REQUEST__${KEY_PATIENT_PORTAL}`;
export const APPROVE_APPOINTMENT_SUCCESS = `APPROVE_APPOINTMENT_SUCCESS__${KEY_PATIENT_PORTAL}`;
export const APPROVE_APPOINTMENT_FAILURE = `APPROVE_APPOINTMENT_FAILURE__${KEY_PATIENT_PORTAL}`;

export const DELETE_ACCOUNT_REQUEST = `DELETE_ACCOUNT_REQUEST__${KEY_PATIENT_PORTAL}`;
export const DELETE_ACCOUNT_SUCCESS = `DELETE_ACCOUNT_SUCCESS__${KEY_PATIENT_PORTAL}`;
export const DELETE_ACCOUNT_FAILURE = `DELETE_ACCOUNT_FAILURE__${KEY_PATIENT_PORTAL}`;
