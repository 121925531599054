import { useEffect, useState } from 'react';

export const useMyLocation = shouldUseLocation => {
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState();

    useEffect(() => {
        if (!shouldUseLocation) {
            return;
        }

        if (navigator.geolocation) {
            setLoading(true);

            navigator.geolocation.getCurrentPosition(
                geolocation => {
                    setLoading(false);
                    setLocation({
                        lat: geolocation.coords.latitude,
                        lng: geolocation.coords.longitude,
                    });
                },
                () => setLoading(false),
            );
        }
    }, []);

    return {
        location,
        loading,
    };
};
