/* eslint-disable max-len */
import React from 'react';


const Play = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M14.57 9.143a1 1 0 0 1 0 1.714l-7.056 4.234A1 1 0 0 1 6 14.234V5.766a1 1 0 0 1 1.514-.857l7.057 4.234z" clipRule="evenodd" />
    </svg>
);

export default Play;
