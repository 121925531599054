import React, { useContext, useEffect, useMemo } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { ReactReduxContext } from 'react-redux';

import { useComponentWillMount } from 'hooks';

import getInjectors from './sagaInjectors';

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * @param key A key of the saga
 * @param saga A root saga that will be injected
 * @param mode By default (constants.RESTART_ON_REMOUNT) the saga will be started on component mount and
 * cancelled with `task.cancel()` on component un-mount for improved performance. Another two options:
 *   - constants.DAEMON—starts the saga on component mount and never cancels it or starts again,
 *   - constants.ONCE_TILL_UNMOUNT—behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 *
 */

export default ({ key, saga, mode } = {}) => WrappedComponent => {
    if (!WrappedComponent) return;

    const InjectSaga = props => {
        const contextType = useContext(ReactReduxContext);
        const { injectSaga, ejectSaga } = useMemo(() => getInjectors(contextType.store), [contextType.store]);

        useComponentWillMount(() => {
            if (key && saga) {
                injectSaga(key, { saga, mode }, props);
            }
        });

        useEffect(() => () => {
            if (key) ejectSaga(key);
        }, [ejectSaga]);

        return <WrappedComponent {...props} />;
    }

    InjectSaga.displayName = `withSaga(${(WrappedComponent.displayName || WrappedComponent.name || 'Component')})`;

    return hoistNonReactStatics(InjectSaga, WrappedComponent);
};
