const date = 'date';
const time = 'time';
const datetime = 'datetime';

export const FIELD_TYPES = {
    [date]: date,
    [time]: time,
    [datetime]: datetime,
};

export const DATE_SEPARATOR = '/';

export const DATE_FORMAT = `MM${DATE_SEPARATOR}DD${DATE_SEPARATOR}YYYY`;

export const DATE_FILTER_FORMAT = 'YYYY-MM-DD';

export const TIME_SEPARATOR = ':';

export const TIME_FORMAT = `HH${TIME_SEPARATOR}MM am`;

export const MOMENT_TIME_FORMAT = 'hh:mm a';

export const MOMENT_TIME_FORMAT_SHORT = 'h:mm a';

export const MOMENT_DATE_TIME_FORMAT = `${DATE_FORMAT} ${MOMENT_TIME_FORMAT}`;
export const MOMENT_DATE_TIME_FORMAT_SHORT = `${DATE_FORMAT} ${MOMENT_TIME_FORMAT_SHORT}`;

export const AUTOFILL_DELAY = 100;

export const SUBMIT_FORM_DELAY = AUTOFILL_DELAY * 2;

export const PREVIOUS_CENTURY_YEAR = 19;

export const CURRENT_CENTURY_YEAR = 20;

export const EMPTY_VALUE = 'EMPTY_VALUE';
