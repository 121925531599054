/* eslint-disable max-len */
import React from 'react';


const Close = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M6.629 5.28a.954.954 0 1 0-1.35 1.348L8.652 10 5.28 13.372a.954.954 0 0 0 1.349 1.349L10 11.348l3.372 3.373a.954.954 0 1 0 1.349-1.35L11.349 10l3.371-3.372a.954.954 0 1 0-1.349-1.349l-3.37 3.372-3.372-3.372z" clipRule="evenodd" />
    </svg>
);

export default Close;
