import React from 'react';
import cn from 'classnames';
import { createUseStyles } from '@flowhealth/ui-components';


const useStyles = createUseStyles(({ palette, typography }) => ({
    button: {
        ...typography['16R'],
        color: palette.naviBlue[300],
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        padding: 0,

        '&$disabled': {
            color: palette.coal[200],
        }
    },
    disabled: {},
}))

export const ActionButton = ({ children, onClick, disabled }) => {
    const classes = useStyles();

    return (
        <button
            type="button"
            className={cn(classes.button, {
                [classes.disabled]: disabled
            })}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    )
};
