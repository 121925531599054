import { all, call, put, takeEvery } from 'redux-saga/effects';
import API from '@flowhealth/api';

import { openSuccessMessage } from 'components/Message/actions';

import { graphChangePriorityFailure, graphChangePrioritySuccess } from './actions';
import { GRAPH_CHANGE_PRIORITY_REQUEST } from './constants';


function* patchGraph({ table, payload: { id, data }, onSuccessAction, updateOnSuccess }) {
    try {
        yield call(API.getInstance().patchGraph, id, data);

        yield put(openSuccessMessage('Position was changed successfully!'));
        if (table && updateOnSuccess) yield put(graphChangePrioritySuccess(table));
        if (onSuccessAction) yield put(onSuccessAction());
    } catch (error) {
        yield put(graphChangePriorityFailure({ error }));
    }
}

function* Saga() {
    yield all([
        takeEvery(GRAPH_CHANGE_PRIORITY_REQUEST, patchGraph),
    ]);
}

export default Saga;
