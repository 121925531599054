import React from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import { createUseStyles, useTheme, Icon } from '@flowhealth/ui-components';

import { useMobileBreakpoint, useTabletBreakpoint } from 'utils/breakpoints';

import { checkActive } from '../utils';
import { NAVIGATION_ITEMS } from '../constants';


const useStyles = createUseStyles(({ palette, typography }) => ({
    navItem: ({ isMobile, isTablet }) => {
        const styles = {};

        if (isMobile) {
            const flexBasis = 100 / NAVIGATION_ITEMS.length;
            styles['flexBasis'] = `${flexBasis}%`;
        } else if (isTablet) {
            styles['height'] =  48;
            styles['width'] =  56;
            styles['marginBottom'] =  4;
        } else {
            styles['height'] =  48;
            styles['width'] =  228;
            styles['marginBottom'] =  4;
        }

        if (!isMobile) {
            styles['&:hover'] = {
                background: palette.naviBlue[50],
                borderRadius: 8,
            };
        }

        return styles;
    },
    navLink: ({ isMobile }) => {
        const styles = {
            ...typography[isMobile ? '12R' : '16M'],
            alignItems: 'center',
            color: palette.ash[800],
            display: 'flex',
            textDecoration: 'none',
        };
        if (isMobile) {
            styles['padding'] = '8px 0';
            styles['flexDirection'] = 'column';
        } else {
            styles['padding'] = '12px 16px';
        }

        styles['&$navLinkActive'] = {
            color: palette.naviBlue[300],
        };

        return styles;
    },
    navLinkActive: {},
    navIcon: ({ isMobile }) => ({
        marginRight: isMobile ? 0 : 16,
        marginBottom: isMobile ? 2 : 0,
    }),
    content: ({ isMobile }) => {
        const styles = {};
        if (isMobile) {
            styles['padding'] = '32px 20px 56px';
        } else {
            styles['padding'] = '40px 0 0';
        }

        return styles;
    }
}));

export const NavigationItem = ({ path, title, iconType }) => {
    const location = useLocation();
    const isMobile = useMobileBreakpoint();
    const isTablet = useTabletBreakpoint();

    const classes = useStyles({ isMobile, isTablet });
    const theme = useTheme();

    const isActive = checkActive(path, location);


    return (
        <li className={classes.navItem}>
            <Link
                className={cn(classes.navLink, { [classes.navLinkActive]: isActive })}
                to={path}
            >
                <Icon
                    className={classes.navIcon}
                    type={iconType}
                    size={24}
                    color={isActive ? theme.palette.naviBlue[300] : theme.palette.ash[800]}
                />
                {!isTablet && (
                    <span>{title}</span>  
                )}
            </Link>
        </li>
    );
};
