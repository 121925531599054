import { ROLES } from 'constants/roles';

import { call, put, takeLatest, all } from 'redux-saga/effects';
import { change } from 'redux-form/immutable';
import { push } from 'redux-first-history';
import isEmpty from 'lodash/isEmpty';
import { safeGet } from '@flowhealth/utils';
import API from '@flowhealth/api';
import { App } from '@flowhealth/app';

import { openFlowLoader, closeFlowLoader } from 'components/FlowLoader/actions';

import { logoutRequest } from 'containers/Auth/actions';

import { USER_FORM } from 'configuration/constants/forms';

import imgToBase64 from 'utils/imgToBase64';
import { getAllFromEdge } from 'utils/getAll';
import { checkTokenExistence } from 'utils/checkTokenExistence';

import * as ACTION_TYPES from './constants';
import * as ACTIONS from './actions';
import { findRoleByType } from './utils';

const USER_EXPAND = 'user{avatar}';
const PATIENT_ROLE_USER_EXPAND = 'user{avatar,agreements_signed_at},patients{practice{group},insurances}';

function* getUser({ payload: { onSuccess, shouldRequestAvatar = true } }) {
    try {
        const roleCode = App.getInstance().getRole();

        if (!checkTokenExistence()) {
            // skip on logout
            yield put(ACTIONS.userFailure());
            return;
        }

        const { results } = yield call(
            API.getInstance().roles,
            { expand: USER_EXPAND },
        );
        if (isEmpty(results)) throw new Error('Empty user object');
        let role = findRoleByType(results, roleCode);

        if (role?.object_type === ROLES.PATIENT_ROLE) {
            const response = yield call(API.getInstance().roles, { expand: PATIENT_ROLE_USER_EXPAND });
            role = findRoleByType(response?.results, ROLES.PATIENT_ROLE);
        }

        if (shouldRequestAvatar) {
            const avatarUrl = safeGet(role, 'user.avatar.url', '');
            if (avatarUrl) {
                role.user.avatar.url = yield imgToBase64(avatarUrl);
            }
        }

        yield put(ACTIONS.userSuccess({ role, results }));
        if (onSuccess) yield onSuccess(role.user);
    } catch (error) {
        yield put(logoutRequest());
        yield put(ACTIONS.userFailure({ error }));
    }
}

function* patchUser({ payload: { data, onSuccess, onFailure } }) {
    try {
        const avatar = {};
        if (data?.avatar) {
            const { id, upload_url, url } = yield call(API.getInstance().newFile, {
                mime_type: safeGet(data.avatar, 'type', 'undefined'),
                title: `avatar_${Date.now()}`,
                kind: '',
            });

            yield call(API.getInstance().uploadFile, upload_url, data.avatar);
            avatar.url = url;
            data.avatar = id;
        }
        const response = yield call(API.getInstance().patchUserData, data);
        if (response?.avatar) {
            const { url } = yield call(API.getInstance().getGraph, response.avatar);
            response.avatar = yield imgToBase64(url);
        }
        yield put(ACTIONS.userPatchSuccess(response));
        onSuccess?.();
    } catch (error) {
        console.log(error);
        yield put(ACTIONS.userPatchFailure({ patchError: error }));
        onFailure?.(error)
    }
}

function* patchRole({ payload: { roleId, data } }) {
    try {
        const response = yield call(API.getInstance().patchGraph, roleId, data);
        yield put(ACTIONS.rolePatchSuccess(response));
    } catch (error) {
        yield put(ACTIONS.rolePatchFailure({ error }));
    }
}

function* setAgreements({ payload: { graphId, redirectUrl = '/' } }) {
    try {
        const response = yield call(
            API.getInstance().patchGraph,
            graphId,
            { agreements_signed_at: new Date().toISOString() },
        );
        yield put(ACTIONS.setAgreementsSuccess(response.agreements_signed_at));
        yield put(push(redirectUrl));
    } catch (error) {
        yield put(ACTIONS.setAgreementsFailure({ error }));
    }
}

function* changeRole({ payload: { graphId, edge, data, params, path, form } }) {
    if (form) yield put(openFlowLoader({ id: form }));
    try {
        const { results = [] } = yield getAllFromEdge(graphId, edge);
        const response = yield call(API.getInstance().createGraphOnEdge, graphId, edge, data, params);
        yield call(API.getInstance().removeGraphFromEdgeBulk, graphId, edge, results.map(r => safeGet(r, 'id')));
        yield put(ACTIONS.userChangeRoleSuccess(response));
        yield put(push(`/${path}/${response.id}`));
    } catch (error) {
        yield put(ACTIONS.userChangeRoleFailure({ error }));
    }
    if (form) yield put(closeFlowLoader({ id: form }));
}

function* uploadAvatar(action) {
    try {
        const file = safeGet(action, 'payload.file');
        const response = yield call(API.getInstance().newFile, {
            mime_type: safeGet(file, 'type', 'undefined'),
            title: safeGet(file, 'name', ''),
            kind: '',
        });
        const uploadUrl = safeGet(response, 'upload_url');
        yield call(API.getInstance().uploadFile, uploadUrl, file);

        yield put(change(USER_FORM, 'avatar', response));
        yield put(ACTIONS.userUploadAvatarSuccess(response));
    } catch (error) {
        yield put(ACTIONS.userUploadAvatarFailure({ error }));
    }
}

function* Saga() {
    yield all([
        takeLatest(ACTION_TYPES.USER_REQUEST, getUser),
        takeLatest(ACTION_TYPES.USER_PATCH_REQUEST, patchUser),
        takeLatest(ACTION_TYPES.ROLE_PATCH_REQUEST, patchRole),
        takeLatest(ACTION_TYPES.SET_AGREEMENTS_REQUEST, setAgreements),
        takeLatest(ACTION_TYPES.USER_CHANGE_ROLE_REQUEST, changeRole),
        takeLatest(ACTION_TYPES.USER_UPLOAD_AVATAR_REQUEST, uploadAvatar),
    ]);
}

export default Saga;
