import _, { isNumber, isString, last, sortBy, partial } from 'lodash';
import { safeGet, isFullArray, toNum } from '@flowhealth/utils';
import { compose } from 'redux';


const CONTAINER_CODE_INDEX_LENGTH = 2;

const getNewContainerIndex = lastContainerIndex => `0${lastContainerIndex + 1}`.slice(-CONTAINER_CODE_INDEX_LENGTH);

// eslint-disable-next-line max-len
const getNewContainerCodeGenerator = accession => index => `${index}${accession.slice(-accession.length + CONTAINER_CODE_INDEX_LENGTH)}`.slice(-10);

const getContainerCode = compose(code => (isString(code) ? code : undefined), container => safeGet(container, 'code'));

const getContainerCodeIndex = code => toNum(code.slice(0, CONTAINER_CODE_INDEX_LENGTH));

const getContainerIndex = compose(getContainerCodeIndex, getContainerCode);
const getLastContainerCode = compose(getContainerCode, last, partial(sortBy, _, getContainerIndex));

export const generateContainerCode = (
    accession,
    index,
    containers = [],
) => {
    if (!accession || !isNumber(index)) {
        return;
    }

    const getNewContainerCode = compose(getNewContainerCodeGenerator(accession), getNewContainerIndex);

    const defaultCode = getNewContainerCode(index);

    if (!isFullArray(containers)) {
        return defaultCode;
    }

    const lastContainerCode = getLastContainerCode(containers);

    if (!isString(lastContainerCode) || lastContainerCode.length < CONTAINER_CODE_INDEX_LENGTH) return defaultCode;

    const lastContainerIndex = getContainerCodeIndex(lastContainerCode);

    if (!isNumber(lastContainerIndex)) return defaultCode;

    return getNewContainerCode(lastContainerIndex);
};

export const isInvalid = (config = {}) => config.fields?.some(
    ({ fieldArray } = {}) => !fieldArray.every(field => {
        if (!field.config.required) {
            return !field.value || field.valid;
        }
        return field.valid;
    }),
);
