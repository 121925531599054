export default {
    accession_role: 'name_sort',
    admin: 'name_sort',

    batch: null,
    billing_lab: null,
    billing_role: 'name_sort',

    claim: null,
    code: 'display_sort',
    connection_request: 'practice_name_sort',
    container_type: 'name_sort',
    cs_role: 'name_sort',

    dev_role: 'name_sort',
    device_role: null,
    device_test: null,
    distributor_contract: null,

    event: 'user_email_sort',

    insurance: null,

    lab_role: 'name_sort',
    lab_test: 'name_sort',
    lab_test_panel: 'name_sort',
    lab_test_position: null,
    laboratory: 'fh_laboratory(desc),name_alias_sort(asc)',
    location: null,
    log_line: null,

    marketing_company: 'name_sort',

    panel_category: null,
    patient: 'name_sort',
    patient_role: null,
    payer_mapping: 'payor_name_sort',
    payor: 'name_sort',
    payor_plan: 'name_sort',
    plan_prefix: 'text_sort',
    plate: null,
    practice: 'name_alias_sort',
    practitioner: 'alias_sort',

    recommended_diagnoses: null,
    rejection: 'test_name_sort',
    req_plate: null,
    req_report: null,
    req_test: null,
    requisition: 'acc_sort',
    result_upload: 'laboratory_sort',

    sales_rep: 'name_sort',
    skipped_events: null,

    task: null,
    test: 'name_sort',
    test_panel: 'name_sort',
    test_panel_container: null,
    test_set: null,
    tests_and_panels: 'name_sort',

    user: 'name_sort',

    worker_comp: null,

    zip_code: null,
};
