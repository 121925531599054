import { QUICKVUE_KIT_KEY } from './constants';


export const RESET_STATE = `RESET_STATE__${QUICKVUE_KIT_KEY}`;

export const SET_LOADING = `SET_LOADING__${QUICKVUE_KIT_KEY}`;
export const SET_PATIENT = `SET_PATIENT__${QUICKVUE_KIT_KEY}`;
export const SET_TEST_RESULT = `SET_TEST_RESULT__${QUICKVUE_KIT_KEY}`;
export const SET_BATCH = `SET_BATCH__${QUICKVUE_KIT_KEY}`;

export const GET_REF_RANGES_REQUEST = `GET_REF_RANGES_REQUEST__${QUICKVUE_KIT_KEY}`;
export const GET_REF_RANGES_SUCCESS = `GET_REF_RANGES_SUCCESS__${QUICKVUE_KIT_KEY}`;
export const GET_REF_RANGES_FAILURE = `GET_REF_RANGES_FAILURE__${QUICKVUE_KIT_KEY}`;

export const SUBMIT_TEST_REQUEST = `SUBMIT_TEST_REQUEST__${QUICKVUE_KIT_KEY}`;
export const SUBMIT_TEST_SUCCESS = `SUBMIT_TEST_SUCCESS__${QUICKVUE_KIT_KEY}`;
export const SUBMIT_TEST_FAILURE = `SUBMIT_TEST_FAILURE__${QUICKVUE_KIT_KEY}`;

export const WS_SUBSCRIBE = `WS_SUBSCRIBE__${QUICKVUE_KIT_KEY}`;
export const WS_UNSUBSCRIBE = `WS_UNSUBSCRIBE__${QUICKVUE_KIT_KEY}`;

export const COPY_TO_PRACTICE_REQUEST = `COPY_TO_PRACTICE_REQUEST__${QUICKVUE_KIT_KEY}`;
export const COPY_TO_PRACTICE_SUCCESS = `COPY_TO_PRACTICE_SUCCESS__${QUICKVUE_KIT_KEY}`;
export const COPY_TO_PRACTICE_FAILURE = `COPY_TO_PRACTICE_FAILURE__${QUICKVUE_KIT_KEY}`;
export const COPY_TO_PRACTICE_FINISHED = `COPY_TO_PRACTICE_FINISHED__${QUICKVUE_KIT_KEY}`;
