/* eslint-disable max-len */
import React from 'react';


const AttentionIcon = () => (
    <svg viewBox="0 0 24 24">
        <path d="M12 15.75L12 15.25" stroke="#788697" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M12 12.75L12 8" stroke="#788697" strokeWidth="1.5" strokeLinecap="round" />
        <circle cx="12" cy="12" r="10.25" stroke="#788697" strokeWidth="1.5" />
    </svg>
);

export default AttentionIcon;
