import React from 'react';
import cn from 'classnames';
import DialogContent from '@material-ui/core/DialogContent';
import {
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import FlowIcon from '../FlowIcon';
import { ICONS_TYPES } from '../FlowIcon/constants';

const styles = ({ palette = {} } = {}) => ({
    actions: {
        padding: '0 10px 10px 10px',
    },
    text: {
        fontSize: '13px',
    },
    button: {
        fontSize: '13px',
        textTransform: 'none',
        boxShadow: 'none',
    },
    closeIcon: {
        position: 'absolute',
        right: '32px',
        top: '32px',
        '& svg': {
            fill: palette.coal[500],
        },
        '&:hover': {
            backgroundColor: palette.ash.main,
        },
    },
});


export class Confirm extends React.Component {
    static defaultProps = {
        stopPropagation: true,
        preventDefault: false,
        open: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: props.open,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.open && !state.open) {
            return { dialogOpen: true };
        }

        return { dialogOpen: false };
    }

    handleCancel = event => {
        const { onRequestClose, stopPropagation, preventDefault } = this.props;
        if (event && preventDefault) event.preventDefault();
        if (event && stopPropagation) event.stopPropagation();
        this.setState({ dialogOpen: false });
        if (typeof onRequestClose === 'function') onRequestClose(event);
    };

    handleApprove = event => {
        const { handleApprove, stopPropagation, preventDefault } = this.props;
        if (event && preventDefault) event.preventDefault();
        if (event && stopPropagation) event.stopPropagation();
        this.setState({ dialogOpen: false });
        if (typeof handleApprove === 'function') handleApprove();
    };

    render() {
        const {
            title,
            text,
            cancel,
            approve,
            classes,
            className,
            approveButtonClassName,
            cancelButtonClassName,
            showCross,
            contentClassName,
        } = this.props;
        return (
            <Dialog
                className={cn('c-dialog', 'c-confirm', className)}
                open={this.state.dialogOpen}
                onClose={event => this.handleCancel(event)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {title && (
                    <DialogTitle id="alert-dialog-title" className={cn('title', classes.title)}>
                        {title}
                    </DialogTitle>
                )}
                {showCross && (
                    <FlowIcon
                        type={ICONS_TYPES.close}
                        size={16}
                        className={classes.closeIcon}
                        onClick={event => this.handleCancel(event)}
                    />
                )}
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        className={cn('text', classes.text, contentClassName)}
                    >
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={cn('actions', classes.actions)}>
                    {cancel && (
                        <Button
                            id="alert-dialog-cancel"
                            onClick={event => this.handleCancel(event)}
                            color="primary"
                            className={cn('cancel', classes.button, cancelButtonClassName)}
                        >
                            {cancel}
                        </Button>
                    )}
                    { typeof approve === 'string' ? (
                        <Button
                            id="alert-dialog-approve"
                            onClick={event => this.handleApprove(event)}
                            color="primary"
                            variant="contained"
                            autoFocus
                            className={cn('approve', classes.button, approveButtonClassName)}
                        >
                            {approve}
                        </Button>
                    ) : approve }
                </DialogActions>
            </Dialog>
        );
    }
}


export default withStyles(styles)(Confirm);
