import React from 'react';
import ReactDOM from 'react-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
    Snackbar,
    SnackbarContent,
    IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

import {
    closeSuccessMessage,
    closeErrorMessage,
    closeWarningMessage,
    closeCustomMessage,
} from './actions';
import { selectMessage } from './selector';
import { MESSAGE_TYPES } from './constants';
import normalize from './normalize';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
    custom: ErrorOutline,
};

const styles = ({ palette = {} } = {}) => ({
    root: {
        marginTop: '12px',
        zIndex: 99999,
    },
    content: {
        color: palette.white.main,
        fontSize: '0.9rem',
        whiteSpace: 'break-spaces',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
    },
    '@media (max-width: 959.95px)': {
        content: {
            flexGrow: 0,
        },
    },
    '@media (max-width: 480px)': {
        content: {
            flexGrow: 1,
        },
    },
    success: {
        backgroundColor: palette.green.main,
    },
    warning: {
        backgroundColor: palette.gold.main,
    },
    error: {
        backgroundColor: palette.red[700],
    },
    info: {
        backgroundColor: palette.naviBlue.dark,
    },
    custom: {
        background: '#C8498F',
        borderRadius: 8,
    },
    iconVariant: {
        marginRight: '8px',
        opacity: '0.9',
    },
    icon: {
        fontSize: '18px',
    },
    iconClose: {
        position: 'absolute',
        top: '3px',
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    close: {
        width: '24px',
        height: '24px',
    },
});

const AUTO_HIDE_DURATION = 3000;
const AUTO_HIDE_ERROR_DURATION = 60000;


export class Message extends React.PureComponent {
    handleClose = id => () => {
        const { dispatch, customComponent } = this.props;
        if (customComponent) {
            dispatch(closeCustomMessage());
            return;
        }

        if (id === MESSAGE_TYPES.success) dispatch(closeSuccessMessage());
        if (id === MESSAGE_TYPES.error) dispatch(closeErrorMessage());
        if (id === MESSAGE_TYPES.warning) dispatch(closeWarningMessage());
    };

    get content() {
        const {
            message,
            id,
            customComponent,
        } = this.props;
        if (customComponent) return customComponent;
        if (message[id]?.component) return message[id]?.component;
        if (normalize(message[id])) return normalize(message[id]);
        return undefined;
    }

    render() {
        const {
            vertical,
            horizontal,
            autoHideDuration,
            cancelAutoHide,
            variant,
            id,
            classes,
        } = this.props;

        const open = !!this.content;
        const IconComponent = variantIcon[variant];

        const defaultHideDuration = id === MESSAGE_TYPES.error ? AUTO_HIDE_ERROR_DURATION : AUTO_HIDE_DURATION;
        const root = (
            <Snackbar
                className={classes.root}
                anchorOrigin={{
                    vertical: vertical || 'top',
                    horizontal: horizontal || 'center',
                }}
                open={open}
                autoHideDuration={
                    cancelAutoHide === true
                        ? null
                        : (autoHideDuration || defaultHideDuration)
                }
                onClose={this.handleClose(id)}
            >
                <SnackbarContent
                    className={cn(classes.content, classes[variant])}
                    aria-describedby="client-snackbar"
                    message={(
                        <span id="client-snackbar" className={classes.message}>
                            <IconComponent className={cn(classes.icon, classes.iconVariant)} />
                            {this.content}
                        </span>
                    )}
                    action={[
                        <IconButton
                            className={classes.close}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.handleClose(id)}
                        >
                            <CloseIcon className={cn(classes.icon, classes.iconClose)} />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        );

        return ReactDOM.createPortal(root, document.body);
    }
}

Message.propTypes = {
    id: PropTypes.oneOf(Object.values(MESSAGE_TYPES)).isRequired,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
    vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
    autoHideDuration: PropTypes.number,
    cancelAutoHide: PropTypes.bool,
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = createStructuredSelector({
    message: selectMessage,
});

const withConnect = connect(mapStateToProps);

export { MESSAGE_TYPES };

export default compose(
    withStyles(styles),
    withConnect,
)(Message);
