/* eslint-disable max-len */
import React from 'react';


const Signature = () => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_20840_5935)">
            <path fillRule="evenodd" clipRule="evenodd" d="M25 -1H24V0H25V-1ZM22.2852 2.72743C20.5438 0.985976 18.5451 1.89628 18.5451 1.89628L6.90901 13.5323L6.43729 17.4633C6.36008 18.1068 6.90588 18.6526 7.54931 18.5754L11.4803 18.1036L23.1164 6.46758C23.1164 6.46758 24.0267 4.46887 22.2852 2.72743ZM8.00243 17.0102L8.33652 14.2261L16.2732 6.28948L18.7232 8.73946L10.7865 16.6761L8.00243 17.0102ZM19.7838 7.6788L17.3338 5.22882L19.3524 3.21024C19.467 3.18413 19.6151 3.16098 19.7828 3.15867C20.1678 3.15336 20.6878 3.2513 21.2246 3.78808C21.7613 4.32487 21.8593 4.84487 21.854 5.22986C21.8517 5.39753 21.8285 5.54566 21.8024 5.66022L19.7838 7.6788ZM3 17.25C1.48122 17.25 0.25 18.4812 0.25 20C0.25 21.5188 1.48122 22.75 3 22.75H19C19.4142 22.75 19.75 22.4142 19.75 22C19.75 21.5858 19.4142 21.25 19 21.25H3C2.30964 21.25 1.75 20.6904 1.75 20C1.75 19.3096 2.30964 18.75 3 18.75H5C5.41421 18.75 5.75 18.4142 5.75 18C5.75 17.5858 5.41421 17.25 5 17.25H3Z" />
        </g>
    </svg>

);

export default Signature;
