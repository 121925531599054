import { PRIVACY_POLICY_PATH, TERMS_OF_SERVICE_PATH, PORTAL_AGREEMENT_PATH } from 'configuration/paths';

/* eslint-disable max-len */
export const PATIENT_PORTAL_AUTH_KEY = 'patientPortalAuth';

export const LOGIN_FORM = `loginForm__${PATIENT_PORTAL_AUTH_KEY}`;
export const VERIFICATION_FORM = `verificationForm__${PATIENT_PORTAL_AUTH_KEY}`;
export const ENTER_CODE_FORM = `enterCodeForm__${PATIENT_PORTAL_AUTH_KEY}`;
export const CREATE_PASSWORD_FORM = `createPasswordForm__${PATIENT_PORTAL_AUTH_KEY}`;
export const LINKED_FORM = `linkedForm__${PATIENT_PORTAL_AUTH_KEY}`;
export const REGISTER_FORM = `registerForm__${PATIENT_PORTAL_AUTH_KEY}`;
export const ADD_INSURANCE_FORM = `addInsuranceForm__${PATIENT_PORTAL_AUTH_KEY}`;
export const HTK_CODE_FORM = `htkCodeForm__${PATIENT_PORTAL_AUTH_KEY}`;

export const PATIENT_PORTAL_USE_CASES = {
    patientRegister: `patient_register__${PATIENT_PORTAL_AUTH_KEY}`,
    patientLink: `patient_link__${PATIENT_PORTAL_AUTH_KEY}`,
    newReportLogin: `new_report_login__${PATIENT_PORTAL_AUTH_KEY}`,
    newReportLink: `new_report_link__${PATIENT_PORTAL_AUTH_KEY}`,
    newReportRegister: `new_report_register__${PATIENT_PORTAL_AUTH_KEY}`,
    selfPatientRegister: `self_patient_register__${PATIENT_PORTAL_AUTH_KEY}`,
    homeTestKit: `home_test_kit__${PATIENT_PORTAL_AUTH_KEY}`,
};

export const RIGHTS = {
    text: [
        'By continuing you agree to the ',
        'Flow Health Terms',
        ', ',
        'Privacy Policy',
        ' and ',
        'Patient User Agreement',
        '.',
    ],
    link: [
        {
            text: 'Flow Health Terms',
            to: TERMS_OF_SERVICE_PATH,
        },
        {
            text: 'Privacy Policy',
            to: PRIVACY_POLICY_PATH,
        },
        {
            text: 'Patient User Agreement',
            to: PORTAL_AGREEMENT_PATH,
        },
    ],
};

export const COMPLEXITY_LEVELS = {
    tooWeak: 'Too weak',
    notSecure: 'Not secure',
    good: 'Good',
    perfect: 'Perfect',
};

export const ERROR_MESSAGES = {
    verifyCredentials: 'We could not verify your credentials. Please double-check and try again.',
};

export const US_ZIP_LENGTH = 5;

export const DEFAULT_COUNTRY = 'us';
export const US_COUNTRY_CODE = 'us';

export const ERROR_CODES = {
    insuranceRequired: 'InsuranceRequired',
    employeeIdRequired: 'EmployeeIdRequired',
};
