import { eventChannel, END } from 'redux-saga';
import {
    call,
    put,
    take,
    all,
    select,
    takeEvery,
    actionChannel,
    race,
} from 'redux-saga/effects';
import API from '@flowhealth/api';

import {
    WEBSOCKET_CONNECT_REQUEST,
    WEBSOCKET_CONNECT_SUCCESS,
    WEBSOCKET_DISCONNECT,

    WEBSOCKET_GET_MESSAGE,
    WEBSOCKET_SEND_MESSAGE,

    WEBSOCKET_SUBSCRIBE,
    WEBSOCKET_UNSUBSCRIBE,
} from 'API/websocket/constants';
import {
    webSocketConnectRequest,
    webSocketConnectSuccess,
    webSocketConnectFailure,

    webSocketFailure,
    webSocketGetMessage,
    webSocketSendMessage,
} from 'API/websocket/actions';
import { selectWebsocketOpen, selectWebsocketScheduleOpen } from 'API/websocket/selector';


function* getSocketOpen() {
    return yield select(selectWebsocketOpen);
}

function* getSocketScheduleOpen() {
    return yield select(selectWebsocketScheduleOpen);
}

function createSocketChannel(socket) {
    return eventChannel(emit => {
        socket.onmessage = event => {
            const msg = JSON.parse(event.data); // todo try catch

            emit(msg);
        };

        socket.onclose = () => {
            emit(END);
        };

        return () => {
            socket.onmessage = null;
        };
    });
}

function* internalListener(socket) {
    const requestChannel = yield actionChannel(WEBSOCKET_SEND_MESSAGE);
    try {
        while (true) {
            const { payload } = yield take(requestChannel);
            socket.send(JSON.stringify(payload));
        }
    } catch (error) {
        yield put(webSocketFailure({ payload: { error } }));
    }
}

function* externalListener(socketChannel) {
    while (true) {
        const payload = yield take(socketChannel);

        const isWsOpen = yield getSocketOpen();
        const { message } = payload;
        if (message === 'connect' && !isWsOpen) {
            yield put(webSocketConnectSuccess({}));
        }

        yield put(webSocketGetMessage({ payload: { message } }));
    }
}

function* openConnection() {
    try {
        while (true) {
            const socket = yield call(API.getInstance().createWebSocketConnection);
            const socketChannel = yield call(createSocketChannel, socket);

            const { cancel } = yield race({
                task: [
                    call(externalListener, socketChannel),
                    call(internalListener, socket),
                ],
                cancel: take(WEBSOCKET_DISCONNECT),
            });
            if (cancel && socket.readyState !== 3 && socket.readyState !== 2) {
                socketChannel.close();
            }
        }
    } catch (error) {
        yield put(webSocketConnectFailure({ error }));
    }
}

function* callbackHandler({ group, type, onMessage }) {
    while (true) {
        const { payload } = yield take(WEBSOCKET_GET_MESSAGE);
        const { message = {} } = payload;
        if (message.group === group
            && type.includes(message.type)
            && onMessage
        ) {
            yield call(onMessage, payload);
        }
    }
}

function* subscribeHandler({ payload, meta }) {
    const isSocketScheduleOpen = yield getSocketScheduleOpen();
    const isSocketOpen = yield getSocketOpen();
    const subscribe = payload.map(({ group, type }) => ({ group, type }));
    if (isSocketScheduleOpen) {
        yield take(WEBSOCKET_CONNECT_SUCCESS);
    } else if (!isSocketOpen) {
        yield put(webSocketConnectRequest({}));
        yield take(WEBSOCKET_CONNECT_SUCCESS);
    }
    yield put(webSocketSendMessage({ payload: { subscribe }, meta }));

    yield all(payload.map(subscription => call(callbackHandler, subscription)));
}

function* unSubscribeHandler({ payload, meta }) {
    const isWsOpen = yield getSocketOpen();
    if (isWsOpen) {
        yield put(webSocketSendMessage({ payload: { unsubscribe: payload }, meta }));
    }
}

function* Saga() {
    yield all([
        takeEvery(WEBSOCKET_SUBSCRIBE, subscribeHandler),
        takeEvery(WEBSOCKET_CONNECT_REQUEST, openConnection),
        takeEvery(WEBSOCKET_UNSUBSCRIBE, unSubscribeHandler),
    ]);
}

export default Saga;
