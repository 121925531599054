import { put, takeLatest, call, all, takeEvery, select, take } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { safeGet, isFullObject } from '@flowhealth/utils';
import API from '@flowhealth/api';

import { webSocketSubscribe, webSocketUnsubscribe } from 'API/websocket/actions';
import { WEBSOCKET_GET_MESSAGE } from 'API/websocket/constants';

import { openErrorMessage } from 'components/Message/actions';

import { HTK_PATH, ORDERS_PATH } from 'configuration/paths';

import storeSelect from 'utils/select';

import {
    COPY_TO_PRACTICE_ERROR,
    COPY_TO_PRACTICE_WS_TYPE,
    POC_ACTION_CHANGED_WS_TYPE,
} from '../../constants';

import { FLOW_FLEX_KIT_KEY } from './constants';
import * as ACTION_TYPES from './actionTypes';
import * as ACTIONS from './actions';
import {
    selectCopyPatientId,
    selectKitBatch,
    selectKitBatchPractice,
    selectKitBatchPatient,
    selectKitId,
    selectFile,
    selectFields,
} from './selectors';


function* submitTest({ payload }) {
    const { testKit, isMatch, onSuccess, isTypeNormal } = payload;
    try {
        if (isTypeNormal) {
            if (isMatch) {
                yield call(API.getInstance().patchGraph, testKit.id, { status: 'apFoved_by_model' });
                yield put(ACTIONS.submitTestSuccess());
                yield put(push('/'));
            } else {
                yield put(ACTIONS.submitTestSuccess());
                onSuccess?.();
            }
        } else {
            yield put(ACTIONS.submitTestSuccess());
            yield put(push(HTK_PATH));
        }
    } catch (error) {
        yield put(ACTIONS.submitTestFailure({ error }));
    }
}

function* copyToPractice({ payload = {} } = {}) {
    try {
        const { patient, practiceId } = payload;
        if (!patient?.practice) return;

        const copyPatient = yield call(API.getInstance().createGraph, {
            object_type: 'copy_patient',
            patient: patient.id,
            practice: patient.practice.id,
        });

        yield put(ACTIONS.copyToPracticeSuccess({ copyPatientId: copyPatient.id }));

        yield put(ACTIONS.wsSubscribe({ group: copyPatient.id, type: COPY_TO_PRACTICE_WS_TYPE }));

        yield call(API.getInstance().addGraphToEdgeBulk, copyPatient.id, 'to_practices', [practiceId]);

        yield call(API.getInstance().patchGraph, copyPatient.id, { status: 'processing' });
    } catch (error) {
        yield put(ACTIONS.copyToPracticeFailure({ error }));
    }
}

function* wsSubscribe({ payload: { group, type } = {} } = {}) {
    if (!group) return;

    yield put(webSocketSubscribe({
        payload: [{
            group,
            type: [type],
        }],
    }));
}

function* wsUnsubscribe({ payload: { group, type } = {} } = {}) {
    if (!group) return;

    yield put(webSocketUnsubscribe({
        payload: [{
            group,
            type: [type],
        }],
    }));
}

function* onWSCopyToPractice(message) {
    const { group, delta, type } = message || {};
    if (type !== COPY_TO_PRACTICE_WS_TYPE || !delta) return;

    try {
        const copyPatientId = yield select(selectCopyPatientId);
        const batchPractice = yield select(selectKitBatchPractice);

        if (copyPatientId !== group) return;

        const { failed_practices } = delta;
        const isFailed = failed_practices.includes(batchPractice);

        if (!isFailed) {
            const copiedPatientResponse = yield call(API.getInstance().getGraph, group, 'copy_patients');
            const copiedPatient = safeGet(copiedPatientResponse, 'copy_patients.results[0]');

            yield put(ACTIONS.setPatient(copiedPatient));
            yield put(ACTIONS.wsCopyToPracticeSuccess(copiedPatient));
            yield put(ACTIONS.copyToPracticeFinished());
        } else {
            yield put(openErrorMessage(COPY_TO_PRACTICE_ERROR));
        }
    } catch (error) {
        yield put(ACTIONS.wsCopyToPracticeFailed({ error }));
    }

    yield put(ACTIONS.wsUnsubscribe({ group, type }));
}

function* onWSPocActionChanged(message) {
    const { group, delta, type } = message || {};
    if (type !== POC_ACTION_CHANGED_WS_TYPE || !delta) return;

    try {
        const { requisition, test_kit } = delta;
        const testResult = yield call(API.getInstance().getGraph, test_kit?.result);
        yield call(API.getInstance().patchGraph, test_kit.id, { status: 'pending' });

        yield put(ACTIONS.wsPocActionChangeSuccess({
            requisition,
            testKit: { ...test_kit, result: testResult },
            testResult: testResult?.range,
        }));
    } catch (error) {
        yield put(ACTIONS.wsPocActionChangeFailed({ error }));
    }

    yield put(ACTIONS.wsUnsubscribe({ group, type }));
}

function* onWS({ payload: { message } } = {}) {
    const { delta, type } = message || {};
    if (!delta) return;

    if (type === COPY_TO_PRACTICE_WS_TYPE) {
        yield call(onWSCopyToPractice, message);
    } else if (type === POC_ACTION_CHANGED_WS_TYPE) {
        yield call(onWSPocActionChanged, message);
    }
}

function* createPocActionWithPhoto({ payload } = {}) {
    const { onSuccess, onFailed } = payload;
    let socketData;
    try {
        const kit_id = yield select(selectKitId);
        const file = yield select(selectFile);

        const kitBatchPatient = yield select(selectKitBatchPatient);
        const batch = yield select(selectKitBatch);
        const { appointment } = yield select(selectFields) ?? {};
        const copyPatientId = yield select(selectCopyPatientId);

        const { role: collector } = kitBatchPatient;
        const { practice, location, kit_batch, panel_code } = batch;

        if (copyPatientId) {
            yield take(ACTION_TYPES.COPY_TO_PRACTICE_FINISHED);

            const currentCopyPatientId = yield select(selectCopyPatientId);

            if (currentCopyPatientId) {
                yield put(ACTIONS.createPocActionWithPhotoFailure({ error: COPY_TO_PRACTICE_ERROR }));
                yield put(openErrorMessage(COPY_TO_PRACTICE_ERROR));
                return;
            }
        }

        const patient = yield select(selectKitBatchPatient);

        const { results } = yield call(API.getInstance().search, {
            object: 'test_panel',
            filters: `code_sort:${panel_code}`,
        });

        const fileObj = yield call(API.getInstance().newFile, {
            mime_type: safeGet(file, 'type', 'undefined'),
            title: `flowFlex_${Date.now()}`,
            kind: '',
        });
        const uploadUrl = fileObj?.upload_url;

        yield call(API.getInstance().uploadFile, uploadUrl, file);

        const panelId = safeGet(results, '[0].id');
        const data = {
            patient: patient?.id || patient,
            practice,
            location,
            panel: panelId,
            collector,
            kit_batch: kit_batch.id,
            file: fileObj?.id,
        };
        if (appointment) {
            data.appointment = appointment.id;
        } else {
            data.kit_batch = kit_batch.id;
        }

        if (kit_id) {
            data.kit_id = kit_id;
            data.object_type = 'poc_action';
        } else {
            data.object_type = 'collector_action';
            if (panelId) {
                const refRange = yield call(API.getInstance().getReferenceRange, {
                    practice_id: practice,
                    test_panel_id: panelId,
                });

                // eslint-disable-next-line max-depth
                if (refRange?.id) {
                    data.range = refRange.ranges.find(range => range.code === 'invalid');
                    data.reference_range = refRange.id;
                    yield put(ACTIONS.setTestResult({ testResult: data.range }));
                }
            }
        }

        const action = yield call(API.getInstance().createGraph, data);

        socketData = { group: action.id, type: POC_ACTION_CHANGED_WS_TYPE };
        yield put(ACTIONS.wsSubscribe(socketData));

        if (onSuccess) {
            if (kit_id) {
                yield take([
                    ACTION_TYPES.WS_POC_ACTION_CHANGE_SUCCESS,
                    ACTION_TYPES.WS_POC_ACTION_CHANGE_FAILED,
                ]);

                const testKit = yield select(storeSelect(FLOW_FLEX_KIT_KEY, 'testKit'));

                // eslint-disable-next-line max-depth
                if (isFullObject(testKit)) {
                    onSuccess();
                } else {
                    throw new Error('testKit doesn\'t exist');
                }
            }

            onSuccess();
        }
        yield put(ACTIONS.createPocActionWithPhotoSuccess({ action }));
    } catch (error) {
        if (socketData) {
            yield put(ACTIONS.wsUnsubscribe(socketData));
        }
        yield put(ACTIONS.createPocActionWithPhotoFailure({ error }));
        if (onFailed) {
            onFailed();
        }
    }
}

export default function* Saga() {
    yield all([
        takeLatest(ACTION_TYPES.SUBMIT_TEST_REQUEST, submitTest),
        takeLatest(ACTION_TYPES.COPY_TO_PRACTICE_REQUEST, copyToPractice),
        takeLatest(ACTION_TYPES.CREATE_POC_ACTION_WITH_PHOTO_REQUEST, createPocActionWithPhoto),

        takeEvery(WEBSOCKET_GET_MESSAGE, onWS),
        takeEvery(ACTION_TYPES.WS_SUBSCRIBE, wsSubscribe),
        takeEvery(ACTION_TYPES.WS_UNSUBSCRIBE, wsUnsubscribe),
    ]);
}
