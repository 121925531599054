import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isPristine } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';
import isFunction from 'lodash/isFunction';
import { KEY_CODES } from '@flowhealth/constants';
import { safeGet } from '@flowhealth/utils';

import Confirm from 'components/Confirm';


import Dialog from './dialog';
import { closeFlowDialog, toggleIsConfirmOpen } from './actions';
import { DIALOG_WIDTH } from './constants';
import { makeSelectDialogOpen, makeSelectIsConfirmOpen } from './selector';

export class FlowDialog extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        className: PropTypes.string,
        form: PropTypes.string,
        width: PropTypes.oneOf(Object.values(DIALOG_WIDTH)),
        isOpen: PropTypes.bool,
        confirmClose: PropTypes.bool,
        isConfirmOpen: PropTypes.bool,
        pristine: PropTypes.bool,
        stopPropagationOnClose: PropTypes.bool,
        disableBackdropClick: PropTypes.bool,
        disableEscapeKeyDown: PropTypes.bool,
        isAdaptive: PropTypes.bool,
        onClose: PropTypes.func,
        doBeforeOpen: PropTypes.func,
        dispatch: PropTypes.func,
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
        contentClassName: PropTypes.string,
        onKeyPress: PropTypes.func,

        // e2e test
        dataTestId: PropTypes.string,
    };

    static defaultProps = {
        width: DIALOG_WIDTH.medium,
        isOpen: false,
        confirmClose: false,
        isConfirmOpen: false,
        stopPropagationOnClose: false,
        disableBackdropClick: false,
        disableEscapeKeyDown: false,
        isAdaptive: false,
    };

    componentDidUpdate(prevProps) {
        const doBeforeOpen = safeGet(this.props, 'doBeforeOpen');
        if (this.props.isOpen && !prevProps.isOpen) {
            if (isFunction(doBeforeOpen)) doBeforeOpen();
            document.addEventListener('keyup', this.escEventListener);
        } else if (!this.props.isOpen && prevProps.isOpen) {
            if (isFunction(this.props.onClose)) this.props.onClose();
            document.removeEventListener('keyup', this.escEventListener);
        }
    }

    componentWillUnmount() {
        this.closeFlowDialog();
    }

    closeFlowDialog = () => {
        const { dispatch, id } = this.props;
        dispatch(closeFlowDialog({ id }));
    };

    escEventListener = ({ keyCode }) => {
        const { onKeyPress } = this.props;
        if (onKeyPress) onKeyPress(keyCode);

        const isEscapeKeyDisabled = safeGet(this.props, 'disableEscapeKeyDown', false);
        if (keyCode !== KEY_CODES.KEY_CODE_ESCAPE || isEscapeKeyDisabled) return null;
        this.handleOnClose();
    };

    handleApprove = e => {
        const { onClose } = this.props;
        if (e && this.props.stopPropagationOnClose) e.stopPropagation();
        this.closeFlowDialog();
        isFunction(onClose) && onClose();
        this.props.dispatch(toggleIsConfirmOpen({ id: this.props.id, isConfirmOpen: false }));
    };

    handleOnClose = () => {
        const confirmClose = safeGet(this.props, 'confirmClose', false);
        const pristine = safeGet(this.props, 'pristine', false);
        if (confirmClose && !pristine) {
            this.props.dispatch(
                toggleIsConfirmOpen({ id: this.props.id, isConfirmOpen: !this.props.isConfirmOpen }),
            );
        } else {
            this.handleApprove();
        }
    };

    onRequestClose = ({ keyCode }) => {
        if (keyCode !== KEY_CODES.KEY_CODE_ESCAPE) {
            this.props.dispatch(
                toggleIsConfirmOpen({
                    id: this.props.id,
                    isConfirmOpen: false,
                }),
            );
        }
    };

    get confirmDialog() {
        const confirmClose = safeGet(this.props, 'confirmClose');
        if (!confirmClose) return null;
        return (
            <Confirm
                id="FlowConfirm"
                open={this.props.isConfirmOpen}
                title="Are you sure?"
                text="Closing this dialog, you will lose all entered data"
                cancel="Cancel"
                approve="Close"
                handleApprove={this.handleApprove}
                onRequestClose={this.onRequestClose}
            />
        );
    }

    render() {
        const { ...rest } = this.props;
        return (
            <React.Fragment>
                <Dialog onCloseByBackdrop={this.handleOnClose} {...rest} />
                {this.confirmDialog}
            </React.Fragment>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isOpen: makeSelectDialogOpen,
    isConfirmOpen: makeSelectIsConfirmOpen,
    pristine: (state, ownProps) => (ownProps.form ? isPristine(ownProps.form)(state) : false),
});

const withConnect = connect(mapStateToProps);

export { DIALOG_WIDTH } from './constants';

export default compose(withConnect)(FlowDialog);
