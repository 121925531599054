import React from 'react';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import memoize from 'lodash/memoize';
import cn from 'classnames';
import PropTypes from 'prop-types';

import arrow_right from 'imgs/arrow-right.svg';


const styles = ({ palette = {} } = {}) => ({
    rootWrap: {
        display: 'flex',
        alignItems: 'center',
    },
    root: {
        position: 'relative',
        color: palette.coal.main,
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.43,
        letterSpacing: 'normal',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        cursor: 'pointer',
        '&.bold': { fontWeight: 500 },
        '&:not($nonHoverable):hover': {
            color: palette.naviBlue.dark,
            textDecoration: 'underline',

            '& + $arrow': {
                opacity: 1,
            },
        },
    },
    arrow: {
        opacity: 0,
        marginLeft: 5,
    },
    nonHoverable: {},
});


const isEllipsisActive = elem => elem && elem.offsetWidth < elem.scrollWidth;


class FlowLink extends React.PureComponent {
    static propTypes = {
        to: PropTypes.string,
        children: PropTypes.string,
        classes: PropTypes.object.isRequired,
        bold: PropTypes.bool,
        className: PropTypes.string,
        linkClassName: PropTypes.string,
        rightArrow: PropTypes.bool,
        target: PropTypes.string,
        useLink: PropTypes.bool,
        dataTestId: PropTypes.string,
    };

    static defaultProps = {
        rightArrow: false,
        target: '_self',
        useLink: false,
    };

    state = { wrapTooltip: false };

    componentDidMount() {
        this.node
        && this.node.current
        && isEllipsisActive(this.node.current)
        && this.setState({ wrapTooltip: true });
    }

    node = React.createRef();

    getLink = memoize(to => event => {
        const { target, history } = this.props;
        if (!to) return;
        event.preventDefault();
        event.stopPropagation();
        if (target === '_blank') window.open(to);
        else history.push(to);
    });

    get link() {
        const {
            classes,
            className,
            linkClassName,
            children,
            bold,
            to,
            rightArrow,
            target,
            useLink,
            dataTestId,
        } = this.props;
        if (!children || children === '') return null;
        const link = useLink ? (
            <Link
                className={cn(classes.root, { bold }, linkClassName, !to && classes.nonHoverable)}
                onClick={e => e.stopPropagation()}
                innerRef={ref => {
                    this.node = ref;
                }}
                to={to}
                target={target}
                data-tid={dataTestId}
            >
                {children}
            </Link>
        ) : (
            <div
                className={cn(classes.root, { bold }, linkClassName, !to && classes.nonHoverable)}
                onClick={this.getLink(to)}
                ref={this.node}
                data-tid={dataTestId}
            >
                {children}
            </div>
        );
        return (
            <div className={cn(classes.rootWrap, className)}>
                {link}
                {rightArrow && (
                    <img
                        className={classes.arrow}
                        src={arrow_right}
                        alt="title hover icon"
                    />
                )}
            </div>
        );
    }

    render() {
        const { classes, className, children } = this.props;
        const { wrapTooltip } = this.state;

        if (!wrapTooltip) return this.link;

        return (
            <Tooltip className={cn(classes.rootWrap, className)} title={children} placement="bottom">
                {this.link}
            </Tooltip>
        );
    }
}

export default compose(
    withRouter,
    withStyles(styles),
)(FlowLink);
