import { set } from 'immutable';
import { createReducer, safeGet } from '@flowhealth/utils';

import { BOOKING_TEST } from '../constants';

import * as ACTION_TYPES from './actionTypes';


const initialState = {
    isLoading: false,
    answers: undefined,
    questions: BOOKING_TEST,
    locations: undefined,
    selectedLocation: undefined,
    date: undefined,
    person: undefined,
    patient: undefined,
    practice: undefined,
    location: undefined,
    copyPatientId: undefined,
    insurance: undefined,
    workingDays: undefined,
    commonPractice: undefined,
};

const resetPage = () => initialState;

const setIsLoading = (defaultValue = false) => (
    state,
    { payload },
) => set(state, 'isLoading', safeGet(payload, 'isLoading') || defaultValue);

const setAnswers = (state, { payload = {} } = {}) => set(state, 'answers', payload);
const setDate = (state, { payload = {} } = {}) => set(state, 'date', safeGet(payload, 'date'));
const setPerson = (state, { payload }) => set(state, 'person', safeGet(payload, 'person'));
const setPatient = (state, { payload }) => set(state, 'patient', safeGet(payload, 'patient'));
const setCommonPractice = (state, { payload }) => set(state, 'commonPractice', payload?.commonPracticeId);
const setCopyPatientId = (state, { payload }) => set(state, 'copyPatientId', payload?.copyPatientId);
const setInsurance = (state, { payload }) => set(state, 'insurance', payload?.insurance);
const setPractice = (state, { payload }) => set(state, 'practice', safeGet(payload, 'practice'));
const setLocation = (state, { payload }) => set(state, 'location', safeGet(payload, 'location'));

export default createReducer(initialState, {
    [ACTION_TYPES.INIT_PAGE]: [setIsLoading(true)],
    [ACTION_TYPES.RESET_PAGE]: [resetPage],

    [ACTION_TYPES.SET_IS_LOADING]: [setIsLoading()],
    [ACTION_TYPES.SET_ANSWERS]: setAnswers,
    [ACTION_TYPES.SET_PRACTICE]: [setInsurance, setPractice],
    [ACTION_TYPES.SET_PERSON]: setPerson,
    [ACTION_TYPES.SET_PATIENT]: setPatient,
    [ACTION_TYPES.SET_INSURANCE]: [setInsurance, setPractice],
    [ACTION_TYPES.SET_LOCATION]: setLocation,

    [ACTION_TYPES.SET_DATE]: setDate,

    [ACTION_TYPES.SELECT_PATIENT_WITH_MAIN_PRACTICE]: [setIsLoading(true)],
    [ACTION_TYPES.SELECT_PATIENT_WITH_MAIN_PRACTICE_SUCCESS]: [setIsLoading(false), setPatient],
    [ACTION_TYPES.SELECT_PATIENT_WITH_MAIN_PRACTICE_FAILURE]: [setIsLoading(false)],


    [ACTION_TYPES.SET_COMMON_PRACTICE]: setCommonPractice,
    [ACTION_TYPES.COPY_TO_PRACTICE_SUCCESS]: setCopyPatientId,

    [ACTION_TYPES.CREATE_APPOINTMENT_REQUEST]: setIsLoading(true),
    [ACTION_TYPES.CREATE_APPOINTMENT_SUCCESS]: setIsLoading(false),
    [ACTION_TYPES.CREATE_APPOINTMENT_FAILURE]: setIsLoading(false),
});
