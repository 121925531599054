/* eslint-disable max-len */
import React from 'react';

const ConnectToPractice = () => (
    <svg width="208" height="208" viewBox="0 0 208 208">
        <rect x="86.6386" y="49.6386" width="108.723" height="108.723" rx="54.3614" fill="#E2E7EE" stroke="#CDD6E1" strokeWidth="1.27723" />
        <path d="M175.387 103.616L145.628 74.8502C143.076 72.3832 138.924 72.3833 136.372 74.8501L106.613 103.616C105.805 104.397 105.794 105.674 106.588 106.469C107.382 107.263 108.681 107.274 109.488 106.494L111.804 104.255V127.221C111.804 132.062 115.808 136 120.73 136H161.27C166.192 136 170.196 132.062 170.196 127.221V104.255L172.512 106.494C172.911 106.88 173.43 107.072 173.949 107.072C174.48 107.072 175.01 106.871 175.412 106.469C176.206 105.674 176.195 104.397 175.387 103.616Z" fill="#B4BFCE" />
        <path fillRule="evenodd" clipRule="evenodd" d="M141 123C139.344 123 138 121.656 138 120V114H132C130.344 114 129 112.656 129 111C129 109.344 130.344 108 132 108H138V102C138 100.344 139.344 99 141 99C142.656 99 144 100.344 144 102V108H150C151.656 108 153 109.344 153 111C153 112.656 151.656 114 150 114H144V120C144 121.656 142.656 123 141 123Z" fill="#E2E7EE" />
        <rect x="19.6386" y="48.6386" width="108.723" height="108.723" rx="54.3614" fill="#F5F7F9" stroke="#E2E7EE" strokeWidth="1.27723" />
        <path d="M92.8306 85.6119C92.8306 75.3346 84.4073 67 74.0125 67C63.6227 67 55.1994 75.3346 55.1994 85.6119C55.1994 95.8917 63.6227 104.224 74.0125 104.224C84.4073 104.224 92.8306 95.8917 92.8306 85.6119Z" fill="#B4BFCE" />
        <path d="M46.8136 138.084C54.1374 144.265 63.6327 148 74 148C84.3698 148 93.8676 144.265 101.189 138.081C102.976 136.576 104 134.374 104 132.055C104 121.632 95.5043 113.29 84.962 113.29H63.068C52.5282 113.29 44 121.632 44 132.055C44 134.371 45.0295 136.578 46.8136 138.084Z" fill="#B4BFCE" />
        <circle cx="103.529" cy="146.705" r="11.5" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M103.627 154.028C102.744 154.028 102.027 153.312 102.027 152.428V149.228H98.827C97.9438 149.228 97.227 148.512 97.227 147.628C97.227 146.745 97.9438 146.028 98.827 146.028L102.027 146.028V142.828C102.027 141.945 102.744 141.228 103.627 141.228C104.51 141.228 105.227 141.945 105.227 142.828V146.028H108.427C109.31 146.028 110.027 146.745 110.027 147.628C110.027 148.512 109.31 149.228 108.427 149.228L105.227 149.228V152.428C105.227 153.312 104.51 154.028 103.627 154.028ZM113.809 137.446C108.186 131.822 99.0683 131.822 93.4446 137.446C87.8209 143.07 87.8209 152.187 93.4446 157.811C99.0683 163.434 108.186 163.434 113.809 157.811C119.433 152.187 119.433 143.07 113.809 137.446Z" fill="#419D4E" />
    </svg>
);

export default ConnectToPractice;
