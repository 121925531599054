import { all, fork } from 'redux-saga/effects';

import websocketSaga from 'API/websocket/saga';

import priorityCellSaga from 'components/PriorityCell/saga';
import valueSetSaga from 'components/ValueSet/saga';
import flowSelectSaga from 'components/FlowSelect/saga';
import graphFilesSaga from 'components/GraphFiles/saga';

import errorsSaga from 'containers/Errors/saga';
import userSaga from 'containers/User/saga';
import appSaga from 'containers/App/saga';
import patientPortalSaga from 'containers/PatientPortal/store/saga';
import patientPortalInsuranceSaga from 'containers/PatientPortal/Insurance/form/store/saga';

import stiOrderSaga from './containers/PatientPortal/orderInfo/StiOrderInfo/store/saga';


function* Saga() {
    yield all([
        fork(appSaga),
        fork(userSaga),
        fork(errorsSaga),

        fork(graphFilesSaga),
        fork(priorityCellSaga),

        // common components
        fork(valueSetSaga),
        fork(flowSelectSaga),

        fork(websocketSaga),
        fork(patientPortalSaga),
        fork(patientPortalInsuranceSaga),
        fork(stiOrderSaga),
    ]);
}

export default Saga;
