import { fromJS } from 'immutable';

import {
    OPEN_SUCCESS_MESSAGE, CLOSE_SUCCESS_MESSAGE,
    OPEN_ERROR_MESSAGE, CLOSE_ERROR_MESSAGE,
    OPEN_WARNING_MESSAGE, CLOSE_WARNING_MESSAGE,
    OPEN_CUSTOM_MESSAGE, CLOSE_CUSTOM_MESSAGE,
    MESSAGE_TYPES,
} from './constants';

const initialState = fromJS({
    success: null,
    error: null,
    warning: null,
});


const messageReducer = (state = initialState, { type, payload }) => {
    switch (type) {
    case OPEN_SUCCESS_MESSAGE: {
        return state
            .set(MESSAGE_TYPES.success, payload);
    }

    case CLOSE_SUCCESS_MESSAGE: {
        return state
            .set(MESSAGE_TYPES.success, null);
    }


    case OPEN_ERROR_MESSAGE: {
        return state
            .set(MESSAGE_TYPES.error, payload);
    }

    case CLOSE_ERROR_MESSAGE: {
        return state
            .set(MESSAGE_TYPES.error, null);
    }

    case OPEN_WARNING_MESSAGE: {
        return state
            .set(MESSAGE_TYPES.warning, payload);
    }

    case CLOSE_WARNING_MESSAGE: {
        return state
            .set(MESSAGE_TYPES.warning, null);
    }

    case OPEN_CUSTOM_MESSAGE: {
        return state
            .set(MESSAGE_TYPES.custom, payload);
    }

    case CLOSE_CUSTOM_MESSAGE: {
        return state
            .set(MESSAGE_TYPES.custom, null);
    }

    default:
        return state;
    }
};

export default messageReducer;
