export const appConfig = {
    appConfig: {
        host: window.location.host,
        portalSubdomain: 'patient',
    },
    apiConfig: {
        apiHost: process.env.REACT_APP_API_HOST,
        wsHost: process.env.REACT_APP_WS_HOST,
        corsProxy: process.env.REACT_APP_CORS_PROXY,
        version: 'v2',
    },
};
