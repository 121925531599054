import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './constants';

export const fetchEventLocationsRequest = createAction(ACTION_TYPES.FETCH_EVENT_LOCATIONS_REQUEST);
export const fetchEventLocationsSuccess = createAction(ACTION_TYPES.FETCH_EVENT_LOCATIONS_SUCCESS);
export const fetchEventLocationsFailure = createAction(ACTION_TYPES.FETCH_EVENT_LOCATIONS_FAILURE);

export const fetchLocationsRequest = createAction(ACTION_TYPES.FETCH_LOCATIONS_REQUEST);
export const fetchLocationsSuccess = createAction(ACTION_TYPES.FETCH_LOCATIONS_SUCCESS);
export const fetchLocationsFailure = createAction(ACTION_TYPES.FETCH_LOCATIONS_FAILURE);

export const resetState = createAction(ACTION_TYPES.RESET_STATE);
