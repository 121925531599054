import { fromJS } from 'immutable';

import { LOGOUT_SUCCESS } from 'containers/Auth/actionTypes';

import * as ACTION_TYPES from './constants';


const initialState = fromJS({
    error: null,
    data: null,
    primaryLaboratory: null,
    labLogoIsLoading: false,
    labLogo: '',
    labLogoShort: '',
    practicesLoading: false,
    practices: [],
    getUserLoading: false,
    uploadingAvatar: false,
    userPatchLoading: false,
    rolePatchLoading: false,
});

const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
    case ACTION_TYPES.USER_REQUEST: {
        return state
            .set('getUserLoading', payload?.shouldChangeLoading)
            .set('error', null);
    }

    case ACTION_TYPES.USER_SUCCESS: {
        return state
            .set('data', payload.role)
            .set('roles', payload.results)
            .set('getUserLoading', false)
            .set('error', null);
    }

    case ACTION_TYPES.USER_PATCH_REQUEST: {
        return state.set('userPatchLoading', true);
    }

    case ACTION_TYPES.USER_PATCH_SUCCESS: {
        return state
            .set('userPatchLoading', false)
            .setIn(['data', 'user'], payload);
    }

    case ACTION_TYPES.USER_PATCH_FAILURE: {
        return state
            .set('error', payload?.patchError)
            .set('userPatchLoading', false);
    }

    case ACTION_TYPES.ROLE_PATCH_REQUEST: {
        return state.set('rolePatchLoading', true);
    }

    case ACTION_TYPES.ROLE_PATCH_SUCCESS: {
        return state.set('rolePatchLoading', false);
    }

    case ACTION_TYPES.ROLE_PATCH_FAILURE: {
        return state
            .set('error', payload)
            .set('rolePatchLoading', false);
    }

    case ACTION_TYPES.USER_ADD_LAB_TO_STORE: {
        return state.set('primaryLaboratory', payload);
    }

    case ACTION_TYPES.SET_AGREEMENTS_SUCCESS: {
        return state
            .setIn(['data', 'agreements_signed_at'], payload);
    }
    case ACTION_TYPES.USER_FAILURE: {
        return state
            .set('error', payload)
            .set('getUserLoading', false);
    }

    case ACTION_TYPES.USER_UPLOAD_AVATAR_REQUEST: {
        return state
            .set('uploadingAvatar', true);
    }

    case ACTION_TYPES.USER_UPLOAD_AVATAR_SUCCESS: {
        return state
            .set('uploadingAvatar', false);
    }

    case ACTION_TYPES.USER_UPLOAD_AVATAR_FAILURE: {
        return state
            .set('error', payload)
            .set('uploadingAvatar', false);
    }

    case ACTION_TYPES.RESET_USER_STATE:
    case LOGOUT_SUCCESS: {
        return initialState;
    }

    default:
        return state;
    }
};

export default userReducer;
