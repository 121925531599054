import mime from 'mime-types';
import { isString, isEmpty } from 'lodash';
import { toNum, formatFileName } from '@flowhealth/utils';

export const getFileExtension = (mimeType = '') => {
    const extension = mime.extension(mimeType) || '';
    return extension ? `.${extension}` : extension;
};

const extRegex = /\.[0-9a-z]+$/i;

export const hasNameExtension = (filename = '', mimeType) => {
    if (!isString(filename) || isEmpty(filename)) return false;
    if (!mimeType) return extRegex.test(filename);
    const extension = getFileExtension(mimeType);
    if (!isString(extension) || isEmpty(extension)) return true;
    if (filename.length < extension.length) return false;
    return filename.slice(-extension.length) === extension;
};

export const getFileNameFactory = () => {
    let counter = 0;
    return ({ name, mimeType }) => {
        if (!isString(name) || isEmpty(name)) {
            counter += 1;
            return `file_${toNum(counter)}${getFileExtension(mimeType)}`;
        }
        if (hasNameExtension(name, mimeType)) return formatFileName(name);
        return formatFileName(`${name}${getFileExtension(mimeType)}`);
    };
};
