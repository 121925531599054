import isEmpty from 'lodash/isEmpty';
import { isArray, makeDuplicates } from '@flowhealth/utils';

import printImages from './printImages';

export const printBarcodes = (
    printManager,
    urls,
    copyNumber = 1,
) => {
    if (isEmpty(urls)) {
        return;
    }

    const duplicatedUrls = makeDuplicates(isArray(urls) ? urls : [urls], copyNumber);

    if (isEmpty(duplicatedUrls)) {
        return;
    }

    if (printManager?.initialized && printManager?.isOpen()) {
        printManager.print(duplicatedUrls ?? []);
    } else {
        printImages(duplicatedUrls);
    }
};
