import React, { PureComponent } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { bluredBG } from './styles';


const styles = ({ palette = {} } = {}) => ({
    root: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '100%',
        display: 'flex',
        overflow: 'hidden',
        zIndex: '1000',
        background: palette.ash[200],
    },
    inner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        paddingTop: 66,
        '@media (max-width:600px)': {
            paddingTop: 40,
            margin: '12px',
            background: palette.white.main,
        },
    },
    ...bluredBG,
    header: {
        '@media (max-width:800px)': {
            paddingLeft: 24,
        },
    },
    order: {
        background: palette.white.main,
        borderRadius: 4,
        boxShadow: `0 1px 3px 0 ${palette.gray['04']}`,
        border: `1px solid ${palette.ash[400]}`,
        padding: '24px 30px',
        '@media (max-width:800px)': {
            margin: '0 24px',
        },
        '@media (max-width:600px)': {
            margin: 0,
        },
    },
    section: {
        marginBottom: 78,
        '@media (max-width:600px)': {
            marginBottom: 40,
        },
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    label: {
        width: 103,
        height: 8,
        borderRadius: 4,
        '@media (max-width:600px)': {
            width: '20%',
        },
    },
    field: {
        width: 286,
        height: 8,
        borderRadius: 4,
        '@media (max-width:600px)': {
            width: '40%',
        },
        '&.big': {
            width: 396,
            height: 32,
            '@media (max-width:600px)': {
                width: '60%',
                height: 24,
            },
        },
    },
    img: {
        height: 150,
        width: 150,
    },
    mt16: { marginTop: 16 },
    mt32: { marginTop: 32 },
    mb32: { marginBottom: 32 },
});


class PatientPortalPopupLoader extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        const { classes, className } = this.props;
        return (
            <div className={cn(className, classes.root)}>
                <div className={classes.inner}>
                    <div className={cn(classes.animate, classes.field, 'big', classes.mt16)} />
                    <div className={cn(classes.animate, classes.field, classes.mt16)} />
                    <div className={cn(classes.animate, classes.img, classes.mt32)} />
                    <div className={cn(classes.animate, classes.field, classes.mt32)} />
                    <div className={cn(classes.animate, classes.field, classes.mt16)} />
                </div>
            </div>
        );
    }
}


export default withStyles(styles)(PatientPortalPopupLoader);
