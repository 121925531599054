import dayjs from 'dayjs';
import weekdayPlugin from 'dayjs/plugin/weekday';
import isNil from 'lodash/isNil';

import { checkCorporateLocation } from 'utils/appointment';


dayjs.extend(weekdayPlugin);

export const sortLocations = (locations, patient) => {
    const newLocations = [...locations];

    newLocations.sort(location => {
        const isCorporate = checkCorporateLocation(location);
        const collectionLocation = isCorporate ? location.collection_location : location;
        if (isNil(collectionLocation)) {
            return -1;
        }

        const { address } = collectionLocation ?? {};
        const isSameCountry = patient?.address?.country === address?.country;
        const isSameCity = patient?.address?.city === address?.city;

        if (isSameCountry && isSameCity) {
            return 1;
        }
        return 0;
    });

    return newLocations;
};

const getDateByTimezone = time_zone => {
    let day = dayjs();
    try {
        day = day.tz(time_zone);
    // eslint-disable-next-line no-empty
    } catch (error) {}

    return day;
}


export const findDayOpenHours = (
    location,
    weekday = getDateByTimezone(location?.time_zone).weekday(),
) => location?.open_hours?.find(({ day_of_week }) => day_of_week === weekday);

export const checkClosedToday = location => !findDayOpenHours(location);

export const checkClosedNow = location => {
    const todayOpenHours = findDayOpenHours(location);
    if (!todayOpenHours) {
        return true;
    }
    if (todayOpenHours.is_all_day) {
        return false;
    }
    const todayHours = getDateByTimezone(location?.time_zone);
    const hour = todayHours.hour();
    return todayOpenHours.start_hour < hour && todayOpenHours.end_hour > hour;
};

export const formatDayOpenedHours = (
    location,
    day = getDateByTimezone(location?.time_zone),
) => {
    const todayOpenHours = findDayOpenHours(location, day.weekday() + 1);
    if (!todayOpenHours) {
        return undefined;
    }

    const openHours = day.hour(todayOpenHours.start_hour).format('ha');
    const endHours = day.hour(todayOpenHours.end_hour).format('ha');

    return `${openHours} — ${endHours}`;
};

export const filterLocationsWithGeoCoordinates = locations => locations.filter(location => {
    const collectionsLocation = checkCorporateLocation(location) ? location?.collection_location : location;
    return !isNil(collectionsLocation?.geo_location);
});

export const convertMetersToMiles = meters => {
    const MILES_IN_KILOMETER = 0.621371;
    const METERS_IN_KILOMETER = 1000;

    return meters / METERS_IN_KILOMETER * MILES_IN_KILOMETER;
};

export const formatDistanceText = distance => `${distance.toFixed(1)} mi`;
