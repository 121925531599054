import { set, setIn, removeIn } from 'immutable';
import { createReducer, safeGet } from '@flowhealth/utils';

import {
    INITIAL_FORM_STATE,
    INSURANCE_FORM,
    setFormFieldData,
    setFormFieldValue,
    setFormFieldTouched,
    validateValue,
} from '../../../components/AddInsurance';

import * as ACTION_TYPES from './actionTypes';

const initialState = {
    patient: null,
    person: null,
    practice: null,
    kitOrder: null,
    questions: null,
    answers: null,

    specimenKitId: null,
    copyPatientId: null,
    requisition: null,
    requisitionsProcessings: {},
    isLoading: false,

    insurance: null,
    insuranceFormData: null,
    commonPractice: null,
    // form
    [INSURANCE_FORM]: INITIAL_FORM_STATE,
};

const resetState = () => ({ ...initialState });

const setIsLoading = value => state => set(state, 'isLoading', value);

const setPatient = (state, { payload = {} }) => set(state, 'patient', payload);
const setPerson = (state, { payload } = {}) => set(state, 'person', safeGet(payload, 'person'));
const setPractice = (state, { payload = {} }) => set(state, 'practice', safeGet(payload, 'practice'));
const setKitOrder = (state, { payload = {} }) => set(state, 'kitOrder', safeGet(payload, 'kitOrder'));

const setCopiedPatientId = (state, { payload = {} } = {}) => set(
    state, 'copyPatientId', safeGet(payload, 'copyPatientId'),
);

const setRequisition = (state, { payload = {} } = {}) => set(
    state, 'requisition', safeGet(payload, 'requisition'),
);

const addRequisitionProcessing = (state, { payload = {} }) => (
    setIn(state, ['requisitionsProcessings', payload.id], payload)
);

const removeRequisitionProcessing = (state, { payload = {} }) => (
    removeIn(state, ['requisitionsProcessings', payload.id])
);

const setRequisitionProcessingStatus = (state, { payload = {} }) => (
    setIn(state, ['requisitionsProcessings', payload.id, 'status'], payload.status)
);

const setRequisitionProcessingLab = (state, { payload = {} }) => (
    setIn(state, ['requisitionsProcessings', payload.id, 'lab'], payload.lab)
);

const setRequisitionProcessingContainerType = (state, { payload = {} }) => (
    setIn(state, ['requisitionsProcessings', payload.id, 'containerType'], payload.containerType)
);

const setRequisitionProcessingCode = (state, { payload = {} }) => (
    setIn(state, ['requisitionsProcessings', payload.id, 'code'], payload.code)
);

const setSpecimenKitId = (state, { payload }) => set(state, 'specimenKitId', payload);

const setQuestions = (state, { payload }) => set(state, 'questions', payload);

const setAnswers = (state, { payload }) => set(state, 'answers', payload);
const setInsurance = (state, { payload }) => set(state, 'insurance', payload);
const setInsuranceFormData = (state, { payload }) => set(state, 'insuranceFormData', payload);

const resetFormState = state => set(state, INSURANCE_FORM, initialState[INSURANCE_FORM]);

const setCommonPractice = (state, { payload }) => set(state, 'commonPractice', payload.commonPracticeId);

export default createReducer(initialState, {
    [ACTION_TYPES.INIT_PAGE]: setIsLoading(true),
    [ACTION_TYPES.RESET_STATE]: resetState,

    [ACTION_TYPES.SET_LOADING]: setIsLoading(),
    [ACTION_TYPES.SET_PATIENT]: setPatient,
    [ACTION_TYPES.SET_PERSON]: setPerson,
    [ACTION_TYPES.SET_PRACTICE]: setPractice,
    [ACTION_TYPES.SET_KIT_ORDER]: setKitOrder,
    [ACTION_TYPES.SET_COMMON_PRACTICE]: setCommonPractice,

    [ACTION_TYPES.SET_SPECIMEN_KIT_ID]: setSpecimenKitId,

    [ACTION_TYPES.SELECT_PATIENT_BY_PERSON]: setIsLoading(true),

    [ACTION_TYPES.ADD_REQUISITION_PROCESSING]: addRequisitionProcessing,
    [ACTION_TYPES.REMOVE_REQUISITION_PROCESSING]: removeRequisitionProcessing,
    [ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_STATUS]: setRequisitionProcessingStatus,
    [ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_LAB]: setRequisitionProcessingLab,
    [ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_CONTAINER_TYPE]: (
        setRequisitionProcessingContainerType
    ),
    [ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_CODE]: setRequisitionProcessingCode,

    [ACTION_TYPES.SEND_REQUISITION_REQUEST]: setIsLoading(true),
    [ACTION_TYPES.SEND_REQUISITION_SUCCESS]: setIsLoading(false),
    [ACTION_TYPES.SEND_REQUISITION_FAILURE]: setIsLoading(false),

    [ACTION_TYPES.COPY_TO_PRACTICE_SUCCESS]: setCopiedPatientId,
    [ACTION_TYPES.COPY_TO_PRACTICE_FINISHED]: setCopiedPatientId,

    [ACTION_TYPES.GET_QUESTIONS_REQUEST]: setIsLoading(true),
    [ACTION_TYPES.GET_QUESTIONS_SUCCESS]: [setIsLoading(false), setQuestions],
    [ACTION_TYPES.SET_ANSWERS]: setAnswers,

    [ACTION_TYPES.WS_SUCCESS]: [setRequisition, setIsLoading(false)],
    [ACTION_TYPES.WS_FAILURE]: setIsLoading(false),

    [ACTION_TYPES.SET_INSURANCE]: setInsurance,
    [ACTION_TYPES.SET_INSURANCE_FORM_DATA]: setInsuranceFormData,
    // form
    [ACTION_TYPES.RESET_FORM_STATE]: resetFormState,
    [ACTION_TYPES.SET_FORM_FIELD_DATA]: setFormFieldData,
    [ACTION_TYPES.SET_FORM_FIELD_VALUE]: [setFormFieldValue, validateValue],
    [ACTION_TYPES.SET_FORM_FIELD_TOUCHED]: [setFormFieldTouched, validateValue],
});
