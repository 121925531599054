export default ({ palette = {} } = {}) => ({
    wrapper: {
        display: 'flex',
    },
    inner: {
        display: 'flex',
        maxWidth: 994,
        padding: '48px 25px 25px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& > img': {
            marginBottom: 32,
        },
    },
    innerSignature: {
        padding: 25,
    },
    rootContainer: {
        marginTop: 80,
        marginBottom: 80,
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 32,
        position: 'relative',
        '&:first-child': {
            marginBottom: 32,
        },
    },
    ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    listContent: {
        marginTop: 24,
        marginBottom: 0,
        listStyle: 'none',
    },
    li: {
        marginTop: 20,
        color: palette.naviBlue.dark,
        fontSize: 18,
        lineHeight: 1.13,
        fontWeight: '400',
        letterSpacing: 'normal',
        '&:first-child': {
            marginTop: 3,
        },
    },
    titlePage: {
        color: palette.coal.main,
        fontSize: 32,
        fontWeight: 900,
        lineHeight: 1.25,
        marginTop: 0,
        marginBottom: 24,
    },
    title: {
        marginTop: 32,
        marginBottom: 16,
        color: palette.coal.main,
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 1.56,
    },
    paragraph: {
        marginTop: 0,
        marginBottom: 16,
        color: palette.coal.tags,
        fontSize: 16,
        lineHeight: 1.5,
        '& a': {
            textDecoration: 'none',
            color: palette.naviBlue.dark,
        },
        '& b': {
            fontStyle: 'italic',
        },
    },
    paragraphLi: {
        marginTop: 8,
        '&:first-child': {
            marginTop: 0,
        },
        '& div': {
            marginTop: 24,
        },
        '& div:last-child': {
            marginBottom: 16,
        },
        '&$paragraph': {
            marginBottom: 0,
        },
        '&:before': {
            marginTop: 8,
            content: '" "',
            backgroundColor: palette.coal.tags,
            width: 9,
            height: 9,
            borderRadius: '50%',
            display: 'flex',
            textAlign: 'center',
            left: 0,
            position: 'absolute',
        },
    },
    blockIndentation: {
        marginTop: 24,
    },
    button: {
        marginLeft: 'auto',
    },
    footer: {
        marginTop: 40,
        marginBottom: 40,
    },
    block: {
        width: '100%',
    },
    a: {
        textDecoration: 'none',
        color: palette.naviBlue.dark,
    },
    footerRoot: {
        position: 'fixed',
        bottom: -7,
        width: 'calc(100% - 114px)',
        backgroundColor: palette.white.main,
        maxWidth: 944,
        '& > hr': {
            marginTop: 0,
        },
    },
    footerWithMenuOpen: { width: 'calc(100% - 314px)' },
    footerOnMobile: { width: 'calc(100% - 50px)' },
});
