import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import AdaptiveHeader from 'components/AdaptiveHeader';
import Message from 'components/Message';


const styles = ({ palette = {} } = {}) => ({
    root: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        minHeight: '100%',
        flexDirection: 'column',
        '@media (min-width:441px)': {
            height: '100%',
            padding: '0 24px',
        },
    },
    content: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '100%',
        marginTop: 24,
        background: palette.white.main,

        '@media (min-width: 441px)': {
            marginTop: 52,
        },
    },
});


const AdaptivePopup = ({
    id,
    title,
    onClose,
    onBackIconClick,
    hasHeader = true,
    classes,
    className,
    children,
    iconType,
    link,
    hasBackIcon,
    hasErrorMessage = true,
    hasDivider,
    headerClassname,
    contentClassName,
}) => (
    <div className={cn(classes.root, className)}>
        {hasHeader && (
            <AdaptiveHeader
                {...{
                    id,
                    title,
                    onClose,
                    onBackIconClick,
                    link,
                    iconType,
                    hasBackIcon,
                    className: headerClassname,
                    hasDivider,
                }}
            />
        )}
        <div className={cn(classes.content, contentClassName)}>
            {children}
        </div>
        {hasErrorMessage && <Message id="error" variant="error" vertical="top" horizontal="center" />}
    </div>
);

AdaptivePopup.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    onBackIconClick: PropTypes.func,
    iconType: PropTypes.string,
    hasBackIcon: PropTypes.bool,
    hasErrorMessage: PropTypes.bool,
};


export default compose(
    withStyles(styles),
    memo,
)(AdaptivePopup);
