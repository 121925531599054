import { getButtonThemeClasses, BUTTON_SIZES } from 'theme/buttonThemes';

const styles = ({ palette = {} } = {}) => ({
    withLeftIcon: {},
    withRightIcon: {},
    withBothIcons: {},
    loading: {},
    fullWidth: { width: '100%' },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 4,
        cursor: 'default',
        border: 0,
        outline: 0,
        userSelect: 'none',
        whiteSpace: 'nowrap',
    },
    inLink: {
        height: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    submit: {
        '&:not($loading)': {
            cursor: 'pointer',
        },
    },
    withClick: {
        '&:not($loading)': {
            cursor: 'pointer',
        },
    },
    ...getButtonThemeClasses(),
    [BUTTON_SIZES.large]: {
        padding: '0 44px',
        lineHeight: '44px',
        height: '44px',
    },
    [`${BUTTON_SIZES.large}-withUpperCase`]: {
        textTransform: 'uppercase',
    },
    [`${BUTTON_SIZES.large}-withLeftIcon`]: {
        paddingLeft: '16px',
        paddingRight: '24px',
    },
    [`${BUTTON_SIZES.large}-withRightIcon`]: {
        paddingLeft: '24px',
        paddingRight: '16px',
    },
    [`${BUTTON_SIZES.large}-withBothIcons`]: {
        paddingLeft: '16px',
        paddingRight: '8px',
    },
    [BUTTON_SIZES.regular]: {
        textTransform: 'capitalize',
        padding: '0 32px',
        lineHeight: '36px',
        height: '36px',
    },
    [`${BUTTON_SIZES.regular}-withLeftIcon`]: {
        paddingLeft: '16px',
        paddingRight: '24px',
    },
    [`${BUTTON_SIZES.regular}-withRightIcon`]: {
        paddingLeft: '16px',
        paddingRight: '12px',
    },
    [`${BUTTON_SIZES.regular}-withBothIcons`]: {
        paddingLeft: '12px',
        paddingRight: '8px',
    },
    [BUTTON_SIZES.small]: {
        textTransform: 'capitalize',
        padding: '0 20px',
        lineHeight: '24px',
        height: '24px',
    },
    [`${BUTTON_SIZES.small}-withLeftIcon`]: {
        paddingLeft: '16px',
        paddingRight: '24px',
    },
    [`${BUTTON_SIZES.small}-withRightIcon`]: {
        paddingLeft: '12px',
        paddingRight: '8px',
    },
    [`${BUTTON_SIZES.small}-withBothIcons`]: {
        paddingLeft: '8px',
        paddingRight: '4px',
    },
    leftIcon: {
        marginRight: '8px',
    },
    rightIcon: {
        marginLeft: '8px',
    },
    disabled: {
        backgroundColor: palette.naviBlue.main,
        opacity: 0.35,
        color: palette.white.main,
        cursor: 'not-allowed !important',

        '& svg': {
            fill: palette.white.main,
        },
    },
});

export default styles;
