import { TEST_TYPES } from '@flowhealth/constants';

export const KEY_PP_ORDERS = 'PPOrders';

export const SCROLL_CONTAINER_ID = 'patientPortal-orders';

export const RESULT_STATUS = {
    not_detected: 'complete',
    detected: 'failure',
    negative: 'complete',
    positive: 'failure',
    positive_good: 'complete',
    negative_bad: 'failure',
};

export const COMMON_PASS_QR_DIALOG_ID = `commonPassQRDialog__${KEY_PP_ORDERS}`;
export const COMMON_PASS_VALID_TEST_TYPES = [TEST_TYPES.rpp, TEST_TYPES.poc, 'naat'];
export const COMMON_PASS_VALID_TEST_RESULTS = ['detected', 'not_detected', 'positive', 'negative'];

export const COMMON_PASS_VALID_TEST_CODES = ['T00688', 'T01340', 'T01341'];

export const ORDER_COUNT = 10;

export const STEPS_OF_FINAL_ORDER_STATUS = [4, 5];
