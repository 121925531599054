export const HTK_KEY = 'HTK';

export const SOCKET_WAIT_TIME = 180000;

export const COPY_TO_PRACTICE_WS_TYPE = 'copy_patient_changed';
export const POC_ACTION_CHANGED_WS_TYPE = 'poc_action_changed';

export const SCANNER_STEP = 'scanner';
export const REVIEW_RESULT_STEP = 'review-result';
export const ALREADY_USED_STEP = 'already-used';

export const COPY_TO_PRACTICE_ERROR = (
    'The Patient has not been copied to the practice. Please contact the support or try again later.'
);

export const SCANNER_WARNING = 'Do not close the app while scanning';

export const CID_VALUE_FILTER = /[^a-zA-Z0-9]/g;

export const CAMERA_ACCESS_ERROR_MSG = 'To scan QR Codes, enable access to the camera';

export const HOTLINE_NUMBER = '+1 (323) 652-4696';

export const DESCRIPTIONS_FOR_TEST_RESULTS = {
    // eslint-disable-next-line max-len
    invalid: 'Please save a clear photo of this test for additional review. You may receive a follow-up call or text in the next 30 minutes; be prepared to answer.',
    positive: 'Your Test has been sent for secondary review.',
};

