import React, { memo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

import Message from 'components/Message';

import MetaData from 'containers/App/metaData';


const styles = () => ({
    root: {
        height: '100%',
    },
    inner: {
        height: '100%',
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
});


const PatientLayout = ({
    classes,
    children,
}) => (
    <div className={classes.root}>
        <MetaData />
        <div className={classes.inner}>
            <div className={classes.content}>
                {children}
                <Message id="success" variant="success" vertical="top" horizontal="center" />
                <Message id="error" variant="error" vertical="top" horizontal="right" />
            </div>
        </div>
    </div>
);

const withConnect = connect();

export default compose(
    withConnect,
    withStyles(styles),
    memo,
)(PatientLayout);
