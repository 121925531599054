import React, { useRef } from 'react';


export default callback => {
    const willMount = useRef(true);

    if (willMount.current) callback();

    willMount.current = false;
};
