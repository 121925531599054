/* eslint-disable max-len */
import React from 'react';


const AllergyLab = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M3.75 12.043c0-.7.322-1.685.93-2.856.595-1.142 1.4-2.348 2.23-3.46a40.968 40.968 0 0 1 2.307-2.811c.158-.176.303-.333.431-.469.213.225.47.506.754.83l.217.248c.749.852 1.537 1.748 2.381 2.975a1.016 1.016 0 0 0 1.673-1.152c-.907-1.318-1.753-2.281-2.549-3.187l-.194-.222c-.426-.486-.801-.89-1.085-1.177a8.067 8.067 0 0 0-.372-.358 2.371 2.371 0 0 0-.177-.144 1.294 1.294 0 0 0-.148-.094l-.003-.001c-.033-.018-.23-.126-.497-.126-.244 0-.424.09-.463.11h-.003c-.067.034-.12.068-.15.089-.063.043-.12.09-.162.126-.09.077-.194.175-.304.284-.225.222-.52.532-.858.908a43 43 0 0 0-2.424 2.956C4.417 5.672 3.54 6.975 2.878 8.25c-.648 1.247-1.16 2.589-1.16 3.793 0 4.375 3.553 7.918 7.93 7.918 4.378 0 7.93-3.543 7.93-7.917a1.016 1.016 0 0 0-2.031 0c0 3.249-2.64 5.886-5.899 5.886a5.892 5.892 0 0 1-5.898-5.886zm8.69.968l6.234-6.24a1.06 1.06 0 0 0-1.497-1.5l-5.11 5.117a1.062 1.062 0 0 1-1.501 0L8.76 8.58a1.059 1.059 0 1 0-1.497 1.498l2.93 2.933a1.59 1.59 0 0 0 2.248 0z" clipRule="evenodd" />
    </svg>
);

export default AllergyLab;
