import React from 'react';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import durationPlugin from 'dayjs/plugin/duration';
import isoWeekPlugin from 'dayjs/plugin/isoWeek';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
import timezonePlugin from 'dayjs/plugin/timezone';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import FontFaceObserver from 'fontfaceobserver';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { initGenerator } from '@flowhealth/generators/src/barcode/utils';
import { loadFont } from '@flowhealth/utils';
import { AppProvider } from '@flowhealth/app';

import routes from 'configuration/routes';
import { appConfig } from 'configuration/appConfig';

import { getRoot } from 'utils/getRoot';
import { PUBLIC_URLS } from 'utils/getPublicURL';

import 'react-app-polyfill/ie11';
import 'core-js/features/array/flat';
import 'core-js/features/array/flat-map';
import 'core-js/features/global-this';

// init Sentry firstly
import './sentry';

// Service Worker
import * as serviceWorker from './serviceWorker';
// Styles
import 'sanitize.css';
import theme from './theme';
import App from './containers/App';
import ErrorBoundary from './containers/ErrorBoundary';
import { store, history } from './configureStore';

dayjs.extend(utcPlugin);
dayjs.extend(customParseFormatPlugin);
dayjs.extend(durationPlugin);
dayjs.extend(isoWeekPlugin);
dayjs.extend(relativeTimePlugin);
dayjs.extend(timezonePlugin);


initGenerator();

const gilroyObserver = new FontFaceObserver('Gilroy', {});
loadFont(gilroyObserver, 'fontLoaded');

export const GAIdentifier = process.env.REACT_APP_GA;

window.ver = process.env.REACT_APP_VERSION;

serviceWorker.register();

const renderApp = () => {
    render(
        <AppProvider config={appConfig} publicUrls={PUBLIC_URLS}>
            <ErrorBoundary>
                    <Provider store={store}>
                        <Router history={history}>
                            <MuiThemeProvider theme={theme}>
                                <App />
                            </MuiThemeProvider>
                        </Router>
                    </Provider>
            </ErrorBoundary>
        </AppProvider>,
    getRoot(),
    );
};
renderApp();

console.info(`v${window.ver}`); // eslint-disable-line

if (GAIdentifier) {
    ReactGA.initialize(GAIdentifier, { testMode: process.env.NODE_ENV === 'test' });
}

if (process.env.NODE_ENV === 'development') {
    console.log({  // eslint-disable-line
        ...process.env,
    });
}

// Listen Before install prompt to call install app dialog
window.addEventListener('beforeinstallprompt', e => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    window.deferredPrompt = e;
});
