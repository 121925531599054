import chain from './chain';

export const hasNumber = str => /\d/.test(str);

export const hasLowerLetter = str => /[a-z]/.test(str);

export const hasUpperLetter = str => /[A-Z]/.test(str);

export const hasSpecialChar = str => /[!@#$%^&*]/.test(str);

export const hasRequiredLength = (str, min = 8, max = 20) => str.length >= min && str.length <= max;

const checkRule = (val, rule) => complexity => (rule(val) ? chain(complexity, (val => val + 20)) : complexity);

const checkComplexity = val => (val
    ? chain(
        0,
        checkRule(val, hasNumber),
        checkRule(val, hasLowerLetter),
        checkRule(val, hasUpperLetter),
        checkRule(val, hasSpecialChar),
        checkRule(val, hasRequiredLength),
    )
    : 0);

export default checkComplexity;
