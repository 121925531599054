import { isObject } from 'lodash';
import posthog from 'posthog-js';

// TODO: use posthog from "@flowhealth/analytics" package
const defaultConfig = {
    api_host: 'https://app.posthog.com',
    mask_all_text: true,
    disable_session_recording: true,
    autocapture: false,
    capture_pageview: false,
};

export class Posthog {
    static instance = null;

    static getInstance = () => {
        const token = process.env.REACT_APP_ENV_POSTHOG_TOKEN;

        if (!Posthog.instance) {
            Posthog.instance = new Posthog(token);
        }
        return Posthog.instance;
    };

    constructor(token) {
        this.queueEvents = [];
        this.isInitialized = false;
        this.token = token;
        this.properties = {};
    };

    init = () => {
        if (!this.token) return;

        console.info('Posthog init');
        this.isInitialized = true;
        posthog.init(this.token, {
            ...defaultConfig,
            disable_session_recording: true,
            autocapture: false,
        });
        posthog.debug(false);

        // process all events in queue
        for (const event of this.queueEvents) {
            this.sendEvent(event);
        }
        this.queueEvents = [];
    }

    identify = id => {
        this.appendData({ id });
        this.reset();
        if (this.isInitialized) {
            posthog.identify(id);
        }
    }

    reset = () => {
        if (this.isInitialized) {
            posthog.reset(true);
        }
    }

    setData = properties => {
        this.properties = properties
    };

    appendData = properties => {
        this.properties = { ...this.properties, ...properties };
    }

    clearData = () => {
        this.properties = {};
        this.reset();
    }

    sendEvent = event => {
        if (!this.token) return;

        if (this.isInitialized) {
            const { name, additionalProperties = {} } = event;

            const properties = {
                ...additionalProperties,
                '$set': this.properties,
            };
            posthog.capture(name, properties);
        } else {
            // is posthog isn't initialized we add event to queue
            this.queueEvents.push(event)
        }
    };
}
