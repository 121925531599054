export default ({ palette = {} } = {}) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
        '&.hidden': { display: 'none' },
    },
    label: {
        color: palette.coal[500],
        fontSize: 12,
        letterSpacing: 'normal',
        fontWeight: 500,
        fontStretch: 'normal',
        marginBottom: 5,
        '&.no-margins': { margin: 0 },
    },
    labelError: {
        color: palette.red.main,
    },
    inputContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '8px 16px',
        paddingRight: 55,
        border: `1px solid ${palette.ash[400]}`,
        borderRadius: 4,
        position: 'relative',
        '&.non-clearable': { paddingRight: 36 },
        backgroundColor: palette.white.main,
    },
    inputContainerNonClearable: {
        paddingRight: 35,
    },
    inputContainerFocused: {
        borderColor: palette.white.main,
        boxShadow: `0 0 0 2px ${palette.naviBlue.dark}`,
    },
    inputContainerError: {
        border: `2px solid ${palette.red.main}`,
        boxShadow: 'none',
    },
    inputContainerNoInput: {
        cursor: 'pointer',
    },
    inputContainerDisabled: {
        cursor: 'not-allowed',
        '&:not($inputContainerWithChips)': {
            backgroundColor: palette.ash[300],
        },
        color: palette.coal.disable,
    },
    inputContainerWithChips: {},
    inputContainerPreview: {
        backgroundColor: 'inherit! important',
        border: 'none',
        padding: '8px 0',
    },
    withSuggestion: {
        flex: 1,
        display: 'inline',
    },
    input: {
        color: palette.coal.main,
        fontSize: 14,
        minHeight: 20,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        backgroundColor: 'inherit',
        border: 0,
        outline: 0,
        padding: 0,
        margin: 0,
        lineHeight: 1.43,

        '&::placeholder': {
            color: palette.coal[500],
        },

        '&:focus': { cursor: 'auto !important' },
    },
    textSuggestion: {
        fontSize: 14,
        lineHeight: 1.43,
        color: palette.coal.third,
    },
    inputDisabled: {
        color: palette.coal.disable,
        '-webkit-text-fill-color': palette.coal.disable,
    },
    inputPreview: {
        fontSize: 16,
        color: palette.coal.main,
        '-webkit-text-fill-color': palette.coal.main,
    },
    inputValueHolder: {
        color: palette.coal.main,
        fontSize: 14,
        lineHeight: 1.5,
        minHeight: 22,
        whiteSpace: 'nowrap',
        backgroundColor: 'inherit',
        flex: 1,
        width: '100%',
        minWidth: 50,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    inputValueHolderPreview: {
        fontSize: 16,
    },
    placeholder: {
        color: palette.coal[500],
    },
    inputIcons: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        minHeight: 20,
        right: 12,
        top: 10,
    },
    inputClear: {
        marginRight: 8,

        '& svg': {
            fill: palette.coal.disable,
        },
    },
    inputTriangle: {
        '& svg': {
            fill: palette.coal[500],
        },
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        borderRadius: 4,
        boxShadow: `0 8px 16px 0 ${palette.gray.main}`,
        border: `1px solid ${palette.ash[300]}`,
        backgroundColor: palette.white.main,
        wordBreak: 'keep-all',
        zIndex: 999999,
    },
    listLabel: {
        padding: '8px 16px',
        fontSize: 12,
        lineHeight: 1.5,
        textTransform: 'uppercase',
        color: palette.coal[500],
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 320,
        overflowY: 'auto',
    },
    listItemContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        flexShrink: 0,
        padding: '8px 16px',

        '&:not($listItemDisabled)': {
            cursor: 'pointer',
        },

        '&:not($listItemDisabled):hover': {
            background: palette.ash[300],

            '& $viewFormIcon': {
                display: 'flex',
            },
        },
    },
    listItemDisabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
    },
    listItemWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    listItemSelected: {
        background: palette.ash.main,
    },
    separator: {
        margin: '8px 16px',
        borderBottom: `1px solid ${palette.ash[400]}`,
    },
    listOpened: {
        backgroundColor: `${palette.white.main} !important`,
    },
    emptyItem: {
        textAlign: 'center',
        padding: '8px 16px',
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        lineHeight: 1.43,
        color: palette.coal.main,
    },
    colorIndicator: {
        width: 16,
        height: 16,
        borderRadius: 6,
        marginRight: 14,
        border: `1px solid ${palette.gray[200]}`,
        flexShrink: 0,
    },
    listSubItem: {
        fontSize: 12,
        lineHeight: 1.43,
        color: palette.coal.third,
    },
    dots: {
        justifyContent: 'center',

        '& svg': {
            fill: palette.coal[500],
        },
    },
    withPadding: {
        padding: 8,
    },
    chip: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: palette.ash[300],
        margin: 3,
        padding: '1px 6px',
    },
    chipText: {
        fontSize: 12,
        lineHeight: 1.43,
        color: palette.coal.tags,
    },
    chipDeleteIcon: {
        marginLeft: 5,
        marginTop: 1,

        '& svg': {
            fill: palette.coal[500],
        },
    },
    listInputContainer: {
        display: 'flex',
        padding: '8px 16px',
    },
    searchIcon: {
        marginRight: 13,

        '& svg': {
            fill: palette.coal[500],
        },
    },
    addGraphToEdgeComponent: {
        alignSelf: 'flex-start',
    },
    createFormIcon: {
        margin: '5px 0',
        padding: '8px 16px',
        color: palette.naviBlue.dark,

        '& svg': {
            fill: palette.naviBlue.dark,
        },

        '&:hover': {
            color: palette.naviBlue[150],

            '& svg': {
                fill: palette.naviBlue[150],
            },
        },
    },
    createFormIconLabel: {
        marginLeft: 10,
    },
    viewFormIcon: {
        display: 'none',
        padding: 8,
        borderRadius: '50%',

        '& svg': {
            fill: palette.coal[500],
        },

        '&:hover': {
            background: palette.ash[700],
        },
    },
    error: {
        margin: '10px 0 0 0',
        fontSize: 12,
        lineHeight: 1.43,
        color: palette.red.main,
    },
    hint: {
        margin: '4px 0 0 0',
        fontSize: 12,
        lineHeight: 1.43,
        color: palette.coal[500],
    },
    viewDialogActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 10,
    },
    viewCancelButton: {
        marginLeft: 20,
    },
    textColorPart: {
        color: palette.naviBlue.dark,
    },
    requiredMark: {
        color: palette.red[700],
    },
    inputUnderlineText: {
        color: palette.coal[500],
        fontSize: 12,
        marginTop: 4,
    },
});
