/* eslint-disable max-len */
import React from 'react';

const CardResults = () => (
    <svg width="32" height="29" viewBox="0 0 32 29">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.00138 0.667969C7.4492 0.667969 7.00157 1.11568 7.00157 1.66797C7.00157 2.22025 7.4492 2.66797 8.00138 2.66797H23.9984C24.5505 2.66797 24.9982 2.22025 24.9982 1.66797C24.9982 1.11568 24.5505 0.667969 23.9984 0.667969H8.00138ZM4.3354 5.0013C4.3354 4.44902 4.78304 4.0013 5.33522 4.0013H26.6645C27.2167 4.0013 27.6643 4.44902 27.6643 5.0013C27.6643 5.55359 27.2167 6.0013 26.6645 6.0013H5.33522C4.78304 6.0013 4.3354 5.55359 4.3354 5.0013ZM0.542708 11.2625C0.391129 9.14003 2.07185 7.33463 4.19937 7.33463H27.8004C29.9279 7.33463 31.6086 9.14003 31.457 11.2625L30.5048 24.5959C30.3678 26.5147 28.7715 28.0013 26.8482 28.0013H5.15157C3.22825 28.0013 1.63194 26.5147 1.49491 24.5959L0.542708 11.2625ZM4.19937 9.33463C3.23231 9.33463 2.46835 10.1553 2.53725 11.12L3.48945 24.4534C3.55174 25.3256 4.27734 26.0013 5.15157 26.0013H26.8482C27.7224 26.0013 28.448 25.3256 28.5103 24.4534L29.4625 11.12C29.5314 10.1553 28.7674 9.33463 27.8004 9.33463H4.19937Z" />
    </svg>
);

export default CardResults;
