export const ON_BOARDING_PHONE_CONTINUE = 'Onboarding_Phone_Continue_Click';
export const ON_BOARDING_SMS_VALIDATION = 'Onboarding_SMS-validation_Confirm_Click';
export const ON_BOARDING_ACCESS_CODE = 'Onboarding_Access-code_Confirm_Click';
export const OPEN_RESULTS = 'Results_Open';
export const SETTINGS_OPENED = 'Settings_Opened';
export const HEALTH_PASSPORT_OPEN = 'Health-passport_Opened';
export const ONBOARDING_WELCOME_SIGH_UP_CLICK = 'Onboarding_Welcome_Sign-up_Click';

export const HTK_ACCESS_CONFIRM_CLICK = 'HTK_Access_Confirm_Click';
export const HTK_PATIENT_NEXT_CLICK= 'HTK_Patient_Next_Click';
export const HTK_CONDUCT_YOUR_TEST_START_CLICK = 'HTK_Conduct-your-test_Start_Click';
export const HTK_SAMPLE_COLLECTED_SUBMIT_CLICK = 'HTK_Sample-collected_Submit_Click';
export const HTK_ADD_TEST_RESULT_TEST_RECOGNIZED = 'HTK_Add-test-result_Test_Recognized';
export const HTK_INSTRUCTION_SKIP_CLICK = 'HTK_Instruction_Skip_Click';
export const HTK_INSTRUCTION_GOT_IT_CLICK = 'HTK_Instruction_Got-it_Click';
export const HTK_CONDUCT_TEST_RESULT_GOT_IT_CLICK = 'HTK_Conduct-test-result_Got-it_Click';
export const HTK_RESULT_SENT_TO_REVIEW_CLICK = 'HTK_Result_Sent-to-review_Click';
export const HTK_ORDER_REGISTERED_DONE_CLICK = 'HTK_Order-registered_Done_Click';
export const HTK_CONDUCT_TEST_CLOSE_CLICK = 'HTK_Conduct-test_Close_Click';
export const HTK_RESULT_COMPLETED = 'HTK_Result_Completed';
export const HTK_RESULT_CANCELLED = 'HTK_Result_Cancelled';
export const HTK_RESULT_FAILED = 'HTK_Result_Failed';
