export const HEALTH_PASSPORT_PATH = '/health-passport';

export const VACCINATION_STATUS = {
    vaccinated: 'vaccinated',
    notVaccinated: 'not_vaccinated',
    partiallyVaccinated: 'partially_vaccinated',
};

export const COVID_STATUS = {
    negative: 'Cleared',
    positive: 'Positive',
    notCleared: 'Not Cleared',
};

export const COVID_TEST_TYPES = {
    PCR: 'pcr',
    POC: 'poc',
    RPP: 'rpp',
    NAAT: 'naat',
};

export const POSITIVE_TEST_CODES = ['positive', 'detected'];

export const NEGATIVE_TEST_CODES = ['negative', 'not_detected'];

export const INVALID_TEST_CODES = ['invalid'];
