import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import cn from 'classnames';
import dayjs from 'dayjs';
import isFunction from 'lodash/isFunction';
import withStyles from '@material-ui/core/styles/withStyles';
import { KEY_CODES } from '@flowhealth/constants';
import { safeGet, getArray, isFullArray, isFullObject } from '@flowhealth/utils';
import { BUTTON_COLORS } from 'theme/buttonThemes';
import { App } from '@flowhealth/app';

import Button from 'components/Button';
import Loading from 'components/Loading';
import FlowIcon from 'components/FlowIcon';
import FlowPhone from 'components/FlowPhone';
import FlowSelect from 'components/FlowSelect';
import RadioField from 'components/Inputs/Radio';
import FlowDateTime from 'components/FlowDateTime';
import HtmlSafe from 'components/HtmlSafe';
import { ICONS_TYPES } from 'components/FlowIcon/constants';
import { DEFAULT_PHONE_ERROR_MESSAGE } from 'components/Inputs/validators';

import { resetUserState } from 'containers/User/actions';
import { makeSelectRole } from 'containers/User/selector';

import ReCAPTCHA from 'utils/ReCaptcha';
import { DATE_FORMAT, DATE_FILTER_FORMAT } from 'utils/constants';

import logo from 'imgs/fh_logo.svg';
import icon_done from 'imgs/icon_done2.svg';

import Input from './Input';
import UploadPhotoField from './UploadPhotoField';
import Milestone from './Milestone';


const styles = ({ palette = {} } = {}) => ({
    root: {
        width: 320,
        '@media (max-width:480px)': {
            maxWidth: 500,
            margin: 'auto',
        },
    },
    logo: {
        display: 'flex',
        marginBottom: 32,
    },
    hideLogo: {
        '@media (max-width:1048px)': { display: 'none' },
    },
    mainIcon: {
        justifyContent: 'center',
        marginBottom: 12,
        '& svg': {
            fill: palette.ash[800],
        },
    },
    title: {
        fontSize: 28,
        fontWeight: 900,
        color: palette.coal.main,
        margin: '12px 0',
    },
    message: {
        margin: '12px 0',
        padding: '8px 5px',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '1.43',
        textAlign: 'center',
        color: palette.white.main,
        border: `solid 1px ${palette.gray[200]}`,
        borderRadius: 4,
        '&.success': { backgroundColor: palette.green.main },
        '&.hidden': { visibility: 'hidden' },
    },
    errorMessage: {
        color: palette.red.main,
        fontSize: 12,
        lineHeight: 1.33,
        width: '283px',
        fontWeight: 500,
        textAlign: 'left',
        display: 'flex',
    },
    errorIcon: {
        position: 'relative',
        top: '-4px',
        paddingRight: '8px',
    },
    fieldsContainer: {
        '& > div': { marginBottom: 16 },
    },
    flowDateTime: { position: 'relative' },
    iconDone: {
        position: 'absolute',
        top: '50%',
        right: 12,
    },
    link: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '1.43',
        cursor: 'pointer',
        textDecoration: 'none',
        color: palette.naviBlue.dark,

        '&:hover': { color: palette.naviBlue[400] },
    },
    forgotPassword: {
        display: 'block',
        margin: '12px 0',
    },
    requirements: {
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: '1.43',
        color: palette.coal[400],
    },
    submitButton: {
        width: '100%',
        height: '44px',
        marginTop: 24,
        textTransform: 'none',
        cursor: 'pointer',
        '&:focus': { outline: 'none' },
        '&.disabled': { cursor: 'not-allowed' },
    },
    question: {
        display: 'flex',
        justifyContent: 'center',
        margin: '16px 0',

        '& > span': {
            marginRight: 6,
            lineHeight: 1.43,
        },
    },
    secondaryText: {
        display: 'flex',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.5,
        color: palette.coal[500],
    },
    description: {
        margin: '12px 0',
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: '1.43',
        color: palette.coal.tags,
    },
    main: { position: 'relative' },
    footer: {
        fontSize: 14,
        color: palette.coal.third,
        lineHeight: '1.43',
        textAlign: 'center',

        '& a': {
            textDecoration: 'none',
            color: palette.coal[500],
            fontWeight: 500,

            '&:hover': { color: palette.coal[500] },
        },
    },
    fieldSetContainer: {},
    fieldSetHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    fieldSetBody: {
        display: 'flex',
        flexDirection: 'column',
        transition: '0.4s 0.4s ease-in',
        maxHeight: 1000,
        padding: 2,
        overflow: 'hidden',
        '&.hidden': {
            maxHeight: 0,
            transition: '0.4s ease',
        },
        '& > *': {
            marginTop: 16,
        },
        '&:last-child': {
            marginBottom: 16,
        },
    },
    phoneLabel: {
        fontSize: 16,
        fontWeight: 600,
    },
});

const handleChangeFlowDateTime = (id, value, onChange) => {
    if (isFunction(onChange)) {
        onChange({ id, value: value ? dayjs(value).format(DATE_FILTER_FORMAT) : '' });
    }
};

const handleFocusFlowDateTime = (event, id, value, onFocus) => {
    if (isFunction(onFocus)) {
        onFocus({ id, value: safeGet(event, 'target.value') || value });
    }
};

const handleChangeFlowPhone = (id, value, onChange) => {
    if (isFunction(onChange)) onChange({ id, value });
}

const handleFocusFlowPhone = (id, value, onFocus) => {
    if (isFunction(onFocus)) onFocus({ id, value });
};


class Form extends React.PureComponent {
    static propTypes = {
        config: PropTypes.object.isRequired,
        dataTestId: PropTypes.string,
        hideLogo: PropTypes.bool,
        hasCaptcha: PropTypes.bool,
        onCaptchaChange: PropTypes.func,
        onCaptchaSuccess: PropTypes.func,
    };

    state = {
        captchaToken: null,
    };

    componentDidMount() {
        this.initializeCaptcha();
    }

    componentDidUpdate(prevProps) {
        if (this.props.hasCaptcha !== prevProps.hasCaptcha) {
            this.initializeCaptcha();
        }
    }

    initializeCaptcha = () => {
        const captchaElement = this.captchaContainer?.current;

        if (!captchaElement) {
            return;
        }

        try {
            const appSiteKey = process.env.REACT_APP_SITEKEY;
            if (!appSiteKey) {
                throw new Error('process.env.REACT_APP_SITEKEY doesn\'t exist');
            }
            ReCAPTCHA.getInstance(
                appSiteKey,
                captchaElement,
                captchaToken => {
                    const { onCaptchaChange } = this.props;

                    this.setState({ captchaToken });
                    if (isFunction(onCaptchaChange)) {
                        onCaptchaChange(captchaToken);
                    }
                },
                this.props.onCaptchaSuccess,
            );
        } catch (err) {
            Sentry.captureException(err);
        }
    };

    handleKeyDown = event => {
        if (event.keyCode !== KEY_CODES.KEY_CODE_ENTER) return event;
        event.preventDefault();
        const { config: { submitButton }, onSubmit } = this.props;
        if (submitButton?.disabled) return;
        onSubmit && onSubmit();
    };

    get mainIcon() {
        const { config: { mainIcon }, classes } = this.props;
        if (!mainIcon) return null;
        return (
            <FlowIcon
                className={classes.mainIcon}
                type={mainIcon.type}
                size={mainIcon.size}
            />
        );
    }

    get title() {
        const { config: { title, titleClassName }, classes } = this.props;
        if (!title) return null;
        return (
            <h1 className={cn(classes.title, titleClassName, { message: !!this.message })}>
                <HtmlSafe>{title}</HtmlSafe>
            </h1>
        );
    }

    valid = field => {
        const { classes = { } } = this.props;
        const { touched, valid } = field;
        if (!touched || !valid) return null;
        return <img className={classes.iconDone} src={icon_done} alt="done" />;
    };

    fieldsMap = (field, index) => {
        const { classes, config: { showArrows, expandFields } } = this.props;
        const {
            config: { id, type, label, selectType, file, fieldType, placeholder,
                className, useNativeSelect, required, clearable = true, index: idx,
                inputPlaceholder, object, expand, rawData, itemsFilter, title, previewOnly,
                itemHide,
            },
            touched, valid, disabled, hidden, notVisible, actionLabel,
            onChange, onFocus, value, dataTestId, actionOnClick, noRearrange,
        } = field;

        if (notVisible) return;

        switch (type) {
        case 'fieldsSet': {
            return (
                <div key={index} className={classes.fieldSetContainer}>
                    <span className={classes.fieldSetHeader} onClick={onChange}>
                        <Milestone
                            index={idx}
                            label={label}
                            required={required}
                            filled={field.fieldArray.some(f => f.touched)}
                            checked={field.fieldArray.some(f => f.config.required)
                                && field.fieldArray.every(f => (f.config.required ? f.valid : true))}
                        />
                        {showArrows && (
                            <FlowIcon
                                type={ICONS_TYPES.simpleArrow}
                                rotate={idx !== value ? -90 : 90}
                                size={20}
                            />
                        )}
                    </span>
                    <div className={cn(classes.fieldSetBody, { hidden: !expandFields && idx !== value })}>
                        {field.fieldArray.map(this.fieldsMap)}
                    </div>
                </div>
            );
        }
        case 'flowDateTime': {
            return (
                <div className={classes.flowDateTime} key={index}>
                    <FlowDateTime
                        key={index}
                        id={id}
                        label={label}
                        className={className}
                        fieldType={fieldType}
                        input={{ onChange: value => handleChangeFlowDateTime(id, value, onChange) }}
                        initial_value={value ? dayjs(value).format(DATE_FORMAT) : ''}
                        onFocus={event => handleFocusFlowDateTime(event, id, value, onFocus)}
                        onKeyDown={this.handleKeyDown}
                        disabled={disabled}
                        touched={touched}
                        valid={valid}
                        required={required}
                        invalid={touched && !valid}
                        hidden={hidden}
                        dataTestId={dataTestId}
                        noRearrange={noRearrange}
                    />
                    {this.valid(field)}
                </div>
            );
        }
        case 'flowPhone': {
            return (
                <FlowPhone
                    key={index}
                    id={id}
                    label={label}
                    labelClassName={classes.phoneLabel}
                    className={className}
                    input={{
                        value: value || '',
                        onChange: value => handleChangeFlowPhone(id, value, onChange),
                    }}
                    onFocus={() => handleFocusFlowPhone(id, value, onFocus)}
                    meta={{ error: touched && !valid && DEFAULT_PHONE_ERROR_MESSAGE }}
                    invalid={touched && !valid}
                    disabled={disabled}
                    required={required}
                    placeholder={placeholder}
                    useNativeSelect={useNativeSelect}
                    dataTestId={dataTestId}
                />
            );
        }
        case 'flowSelect':
            return (
                <FlowSelect
                    key={index}
                    id={id}
                    label={label}
                    className={className}
                    selectType={selectType}
                    file={file}
                    object={object}
                    expand={expand}
                    rawData={rawData}
                    input={{
                        value: value || '',
                        onChange: value => handleChangeFlowPhone(id, value, onChange),
                        onFocus: () => handleFocusFlowPhone(id, value, onFocus),
                    }}
                    itemsFilter={itemsFilter}
                    invalid={touched && !valid}
                    disabled={disabled}
                    required={required}
                    clearable={clearable}
                    placeholder={placeholder}
                    hidden={hidden}
                    dataTestId={dataTestId}
                    inputPlaceholder={inputPlaceholder}
                    isPatientPortal
                    itemHide={itemHide}
                />
            );
        case 'radio':
            return (
                <RadioField
                    key={index}
                    id={id}
                    label={label}
                    className={className}
                    input={{
                        onChange: value => handleChangeFlowPhone(id, value, onChange),
                    }}
                    meta={{ initial: value }}
                    disabled={disabled}
                    required={required}
                    invalid={touched && !valid}
                    {...field}
                />
            );
        case 'photoUpload':
            return (
                <UploadPhotoField
                    key={index}
                    id={id}
                    className={className}
                    value={value}
                    label={label}
                    title={title}
                    file={file}
                    required={required}
                    previewOnly={previewOnly}
                    onChange={onChange}
                    {...field}
                />
            );
        default: return (
            <Input
                key={index}
                className={className}
                actionLabel={actionLabel}
                actionOnclick={actionOnClick}
                onKeyDown={this.handleKeyDown}
                required={required}
                {...field}
            />
        );
        }
    };

    get fields() {
        const { config: { fields, fieldsClassName }, classes } = this.props;
        if (!isFullArray(fields)) return null;
        return (
            <div className={cn(classes.fieldsContainer, fieldsClassName)}>
                {fields.map(this.fieldsMap)}
            </div>
        );
    }

    handleLinkClick = link => e => {
        e.preventDefault();
        const { dispatch, history, role } = this.props;
        if (role) dispatch(resetUserState());
        history.push(link.to);
    };

    get link() {
        const { classes, config: { link } } = this.props;
        if (!link) return null;
        return link.nativeLink ? (
            <a href={link.to} className={cn(classes.link, classes.forgotPassword)}>{link.text}</a>
        ) : (
            <Link
                className={cn(classes.link, classes.forgotPassword)}
                to={link.to}
                onClick={this.handleLinkClick}
            >
                {link.text}
            </Link>
        );
    }

    get requirements() {
        const { classes, config: { requirements, requirementsClassName } } = this.props;
        if (!requirements) return null;

        if (requirements.subject) {
            return (
                <div className={cn(classes.requirements, requirementsClassName)}>
                    <span>{requirements.subject}:</span>
                    <ul className={classes.requirementsList}>
                        {getArray(requirements.list).map((requirement, index) => <li key={index}>{requirement}</li>)}
                    </ul>
                </div>
            );
        }

        return (
            <div className={cn(classes.requirements, requirementsClassName)}>
                {requirements}
            </div>
        );
    }

    handleSubmit = () => {
        const { config: { submitButtonOnClick }, onSubmit } = this.props;
        if (isFunction(onSubmit)) return onSubmit();
        if (isFunction(submitButtonOnClick)) return submitButtonOnClick();
    };

    get submit() {
        const { classes, config, hasCaptcha } = this.props;
        const { submitButton } = config;
        if (!submitButton) return null;

        const { text, disabled, dataTestId, className, loading } = submitButton;
        return (
            <Button
                className={cn(classes.submitButton, className)}
                onClick={this.handleSubmit}
                disabled={isFunction(disabled)
                    ? disabled(config)
                    : disabled
                    || (hasCaptcha && !this.state.captchaToken)}
                dataTestId={dataTestId}
                loadgin={loading}
            >
                {text}
            </Button>
        );
    }

    get oppositeActionBtn() {
        const { classes, config: { oppositeActionBtn } } = this.props;
        if (!isFullObject(oppositeActionBtn)) return null;
        return (
            <Button
                className={cn(classes.submitButton, oppositeActionBtn.className)}
                type={BUTTON_COLORS.transparentBlue}
                onClick={oppositeActionBtn.onClick}
                disabled={oppositeActionBtn.disabled}
                dataTestId={oppositeActionBtn.dataTestId}
            >
                {oppositeActionBtn.text}
            </Button>
        );
    }

    get question() {
        const { classes, config: { question } } = this.props;

        return question?.map((data, idx) => {
            if (!data) return null;
            const { text, link, className } = data;

            return (
                <div
                    key={idx}
                    className={cn(classes.question, className)}
                >
                    {text && <span className={classes.secondaryText}>{text}</span>}
                    {link.nativeLink ? (
                        <a href={link.to} className={classes.link}>{link.text}</a>
                    ) : (
                        <Link className={classes.link} to={link.to} onClick={link.onClick}>
                            {link.text}
                        </Link>
                    )}
                </div>
            );
        });
    }

    get loading() {
        const { loading } = this.props;
        return loading ? <Loading type="patientAuth" /> : null;
    }

    get successMessage() {
        const { success = {}, classes } = this.props;
        return success.message && (
            <div className={classes.messageContainer}>
                <div
                    className={cn(classes.message, {
                        success: success.message,
                        hidden: !success.message,
                    })}
                >
                    {success.message}
                </div>
            </div>
        );
    }

    get errorMessage() {
        const { error = {}, classes, dataTestId } = this.props;
        return error.message && (
            <div className={classes.messageContainer}>
                <div
                    className={cn(classes.errorMessage, {
                        error: error.message,
                    })}
                    data-tid={`${dataTestId}-error`}
                >
                    <FlowIcon size={20} type={ICONS_TYPES.attention} className={classes.errorIcon} />
                    {error.message}
                </div>
            </div>
        );
    }

    get description() {
        const { classes, config: { description, descriptionClassName } } = this.props;
        if (!description) return null;
        return <div className={cn(classes.description, descriptionClassName)}>{description}</div>;
    }

    get footer() {
        const { classes, config: { footer } } = this.props;
        if (!footer) return null;
        return (
            <div className={cn(classes.footer)}>
                {footer.text.map((chunkText, index) => {
                    const footerLink = footer.link.find(
                        ({ text }) => text === chunkText,
                    );
                    return footerLink ? (
                        <Link className={classes.link} key={index} to={footerLink.to}>
                            {footerLink.text}
                        </Link>
                    ) : (
                        <span key={index}>{chunkText}</span>
                    );
                })}
            </div>
        );
    }

    get logo() {
        const { classes, showLogo = true, hideLogo } = this.props;
        if (!showLogo) return null;

        const loginPortalURL = App.getInstance().buildPortalUrl('login');

        return (
            <div className={cn(classes.logo, hideLogo && classes.hideLogo)}>
                <a href={loginPortalURL}>
                    <img src={logo} alt="logo" />
                </a>
            </div>
        );
    }

    captchaContainer = React.createRef();

    render() {
        const { classes, className, hasCaptcha } = this.props;

        return (
            <div className={cn(classes.root, className)}>
                {this.logo}
                <div className={classes.main}>
                    {this.mainIcon}
                    {this.title}
                    {this.description}
                    {this.successMessage}
                    {this.fields}
                    {this.errorMessage}
                    {this.link}
                    {this.requirements}
                    {this.submit}
                    {this.oppositeActionBtn}
                    {this.question}
                    {this.loading}
                    {hasCaptcha && (
                        <div
                            ref={this.captchaContainer}
                            id="recaptcha-auth"
                            className="g-recaptcha"
                        />
                    )}
                </div>
                {this.footer}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    role: makeSelectRole(),
});

const withConnect = connect(mapStateToProps);

export default compose(
    withStyles(styles),
    withRouter,
    withConnect,
)(Form);
