import {
    PATIENT_PORTAL_AUTH_KEY,
    VERIFICATION_FORM,
    LOGIN_FORM,
    CREATE_PASSWORD_FORM,
    ENTER_CODE_FORM,
    REGISTER_FORM,
} from './constants';


export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SET_PORTAL_USE_CASE = `${PATIENT_PORTAL_AUTH_KEY}__SET_PORTAL_USE_CASE`;
export const SET_INPUT_VALUE = `${PATIENT_PORTAL_AUTH_KEY}__SET_INPUT_VALUE`;
export const SET_INPUT_TOUCHED = `${PATIENT_PORTAL_AUTH_KEY}__SET_INPUT_TOUCHED`;
export const SET_REDIRECT_URL = `${PATIENT_PORTAL_AUTH_KEY}__SET_REDIRECT_URL`;
export const SET_ERROR_MESSAGE = `${PATIENT_PORTAL_AUTH_KEY}__SET_ERROR_MESSAGE`;
export const SET_FORM_LOADING = `${PATIENT_PORTAL_AUTH_KEY}__SET_FORM_LOADING`;

export const RESET_STATE = `${PATIENT_PORTAL_AUTH_KEY}__RESET_STATE`;

export const SET_PATIENT_ID = `${VERIFICATION_FORM}__SET_PATIENT_ID`;
export const SET_PATIENT_PHONE = `${VERIFICATION_FORM}__SET_PATIENT_PHONE`;
export const SET_PATIENT_NAME = `${VERIFICATION_FORM}__SET_PATIENT_NAME`;
export const SET_PRACTICE_NAME = `${VERIFICATION_FORM}__SET_PRACTICE_NAME`;
export const SET_PRACTICE_ID = `${VERIFICATION_FORM}__SET_PRACTICE_ID`;

export const VERIFY_PATIENT_REQUEST = `${VERIFICATION_FORM}__VERIFY_PATIENT_REQUEST`;
export const VERIFY_PATIENT_REQUEST_SUCCESS = `${VERIFICATION_FORM}__VERIFY_PATIENT_REQUEST_SUCCESS`;
export const VERIFY_PATIENT_REQUEST_FAILURE = `${VERIFICATION_FORM}__VERIFY_PATIENT_REQUEST_FAILURE`;

export const AUTH_REQUEST = `${LOGIN_FORM}__AUTH_REQUEST`;
export const AUTH_REQUEST_SUCCESS = `${LOGIN_FORM}__AUTH_REQUEST_SUCCESS`;
export const AUTH_REQUEST_FAILURE = `${LOGIN_FORM}__AUTH_REQUEST_FAILURE`;

export const SEND_CODE_REQUEST = `${VERIFICATION_FORM}__SEND_CODE_REQUEST`;
export const SEND_CODE_SUCCESS = `${VERIFICATION_FORM}__SEND_CODE_SUCCESS`;
export const SEND_CODE_FAILURE = `${VERIFICATION_FORM}__SEND_CODE_FAILURE`;

export const VERIFY_CODE_REQUEST = `${ENTER_CODE_FORM}__VERIFY_CODE_REQUEST`;
export const VERIFY_CODE_SUCCESS = `${ENTER_CODE_FORM}__VERIFY_CODE_SUCCESS`;
export const VERIFY_CODE_FAILURE = `${ENTER_CODE_FORM}__VERIFY_CODE_FAILURE`;

export const RESET_PASSWORD_REQUEST = `${CREATE_PASSWORD_FORM}__RESET_PASSWORD_REQUEST`;
export const RESET_PASSWORD_REQUEST_SUCCESS = `${CREATE_PASSWORD_FORM}__RESET_PASSWORD_REQUEST_SUCCESS`;
export const RESET_PASSWORD_REQUEST_FAILURE = `${CREATE_PASSWORD_FORM}__RESET_PASSWORD_REQUEST_FAILURE`;

export const SET_REGISTER_FORM_VALUE = `${REGISTER_FORM}__SET_REGISTER_FORM_VALUE`;

export const GET_ADDRESS_REQUEST = `${REGISTER_FORM}__GET_ADDRESS_REQUEST`;
export const GET_ADDRESS_SUCCESS = `${REGISTER_FORM}__GET_ADDRESS_SUCCESS`;
export const GET_ADDRESS_FAILURE = `${REGISTER_FORM}__GET_ADDRESS_FAILURE`;

export const REGISTER_PATIENT_REQUEST = `${REGISTER_FORM}__REGISTER_PATIENT_REQUEST`;
export const REGISTER_PATIENT_SUCCESS = `${REGISTER_FORM}__REGISTER_PATIENT_SUCCESS`;
export const REGISTER_PATIENT_FAILURE = `${REGISTER_FORM}__REGISTER_PATIENT_FAILURE`;

export const SET_PATIENT_REQUEST = `${PATIENT_PORTAL_AUTH_KEY}__SET_PATIENT_REQUEST`;

export const SEARCH_BATCH_REQUEST = `${PATIENT_PORTAL_AUTH_KEY}__SEARCH_BATCH_REQUEST`;
export const SEARCH_BATCH_SUCCESS = `${PATIENT_PORTAL_AUTH_KEY}__SEARCH_BATCH_SUCCESS`;
export const SEARCH_BATCH_FAILURE = `${PATIENT_PORTAL_AUTH_KEY}__SEARCH_BATCH_FAILURE`;
export const RESET_SEARCH_ERROR = `${PATIENT_PORTAL_AUTH_KEY}__RESET_SEARCH_ERROR`;

export const SET_EMPLOYEE_ID = `${PATIENT_PORTAL_AUTH_KEY}__SET_EMPLOYEE_ID`;
