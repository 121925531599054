import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './actionTypes';

export const initPage = createAction(ACTION_TYPES.INIT_PAGE);
export const resetState = createAction(ACTION_TYPES.RESET_STATE);

export const setLoading = createAction(ACTION_TYPES.SET_LOADING);
export const setPatient = createAction(ACTION_TYPES.SET_PATIENT);
export const setPractice = createAction(ACTION_TYPES.SET_PRACTICE);
export const setKitOrder = createAction(ACTION_TYPES.SET_KIT_ORDER);

export const wsSubscribe = createAction(ACTION_TYPES.WS_SUBSCRIBE);
export const wsUnsubscribe = createAction(ACTION_TYPES.WS_UNSUBSCRIBE);
export const wsSuccess = createAction(ACTION_TYPES.WS_SUCCESS);
export const wsFailure = createAction(ACTION_TYPES.WS_FAILURE);

export const selectPatientByPerson = createAction(ACTION_TYPES.SELECT_PATIENT_BY_PERSON);
export const selectPatientByPersonSuccess = createAction(ACTION_TYPES.SELECT_PATIENT_BY_PERSON_SUCCESS);
export const selectPatientByPersonFailure = createAction(ACTION_TYPES.SELECT_PATIENT_BY_PERSON_FAILURE);

export const copyToPracticeRequest = createAction(ACTION_TYPES.COPY_TO_PRACTICE_REQUEST);
export const copyToPracticeSuccess = createAction(ACTION_TYPES.COPY_TO_PRACTICE_SUCCESS);
export const copyToPracticeFailure = createAction(ACTION_TYPES.COPY_TO_PRACTICE_FAILURE);
export const copyToPracticeFinished = createAction(ACTION_TYPES.COPY_TO_PRACTICE_FINISHED);

export const setCommonPractice = createAction(ACTION_TYPES.SET_COMMON_PRACTICE);

export const setSpecimenKitId = createAction(ACTION_TYPES.SET_SPECIMEN_KIT_ID);

export const sendRequisitionRequest = createAction(ACTION_TYPES.SEND_REQUISITION_REQUEST);
export const sendRequisitionSuccess = createAction(ACTION_TYPES.SEND_REQUISITION_SUCCESS);
export const sendRequisitionFailure = createAction(ACTION_TYPES.SEND_REQUISITION_FAILURE);

export const addRequisitionProcessing = createAction(ACTION_TYPES.ADD_REQUISITION_PROCESSING);
export const removeRequisitionProcessing = createAction(ACTION_TYPES.REMOVE_REQUISITION_PROCESSING);
export const setRequisitionProcessingStatus = createAction(
    ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_STATUS,
);
export const setRequisitionProcessingLab = createAction(
    ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_LAB,
);
export const setRequisitionProcessingContainerType = createAction(
    ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_CONTAINER_TYPE,
);
export const setRequisitionProcessingCode = createAction(
    ACTION_TYPES.UPDATE_REQUISITION_PROCESSING_CODE,
);

export const getQuestions = createAction(ACTION_TYPES.GET_QUESTIONS_REQUEST);

export const getQuestionsSuccess = createAction(ACTION_TYPES.GET_QUESTIONS_SUCCESS);

export const getQuestionsFailure = createAction(ACTION_TYPES.GET_QUESTIONS_FAILURE);

export const setAnswers = createAction(ACTION_TYPES.SET_ANSWERS);

export const setInsurance = createAction(ACTION_TYPES.SET_INSURANCE);
export const setInsuranceFormData = createAction(ACTION_TYPES.SET_INSURANCE_FORM_DATA);

export const setPerson = createAction(ACTION_TYPES.SET_PERSON);
// Form

export const resetFormState = createAction(ACTION_TYPES.RESET_FORM_STATE);
export const setFormFieldData = createAction(ACTION_TYPES.SET_FORM_FIELD_DATA);
export const setFormFieldValue = createAction(ACTION_TYPES.SET_FORM_FIELD_VALUE);
export const setFormFieldTouched = createAction(ACTION_TYPES.SET_FORM_FIELD_TOUCHED);
