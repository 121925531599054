/* eslint-disable max-len */
import React from 'react';


const Barcode = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M4.875 3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h1.25a.5.5 0 0 0 .5-.5v-10a.5.5 0 0 0-.5-.5h-1.25zM1.5 3a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h1.25a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5H1.5zm6.49.5a.5.5 0 0 1 .5-.5h.125a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H8.49a.5.5 0 0 1-.5-.5v-10zm2.83-.5a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5h1.25a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5h-1.25zm2.996.5a.5.5 0 0 1 .5-.5h.125a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5h-.125a.5.5 0 0 1-.5-.5v-10zm2.309-.5a.5.5 0 0 0-.5.5v12a.5.5 0 0 0 .5.5H18.5a.5.5 0 0 0 .5-.5v-12a.5.5 0 0 0-.5-.5h-2.375z" clipRule="evenodd" />
    </svg>
);

export default Barcode;
