import { createAction } from '@flowhealth/utils';

import * as ACTION_TYPES from './constants';

export const setInputValue = createAction(ACTION_TYPES.SET_INPUT_VALUE);
export const setInputTouched = createAction(ACTION_TYPES.SET_INPUT_TOUCHED);
export const setInputValid = createAction(ACTION_TYPES.SET_INPUT_VALID);
export const setInsuranceData = createAction(ACTION_TYPES.SET_INSURANCE_DATA);
export const setPerson = createAction(ACTION_TYPES.SET_PERSON);

export const resetState = createAction(ACTION_TYPES.RESET_STATE);

export const addPatientInsuranceRequest = createAction(ACTION_TYPES.INSURANCE_REQUEST);
export const addPatientInsuranceRequestSuccess = createAction(ACTION_TYPES.INSURANCE_REQUEST_SUCCESS);
export const addPatientInsuranceRequestFailure = createAction(ACTION_TYPES.INSURANCE_REQUEST_FAILURE);

export const getPhotosRequest = createAction(ACTION_TYPES.GET_PHOTOS_REQUEST);
export const getPhotosRequestSuccess = createAction(ACTION_TYPES.GET_PHOTOS_REQUEST_SUCCESS);
export const getPhotosRequestFailure = createAction(ACTION_TYPES.GET_PHOTOS_REQUEST_FAILURE);
