import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import cn from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { BUTTON_COLORS, BUTTON_SIZES } from 'theme/buttonThemes';

import FlowIcon from '../FlowIcon';
import { ICONS_TYPES, ROTATE_TYPES } from '../FlowIcon/constants';

import { HTML_BUTTON_TYPES } from './constants';
import styles from './styles';


class Button extends React.PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        className: PropTypes.string,
        toClassName: PropTypes.string,
        id: PropTypes.string,

        color: PropTypes.oneOf(Object.values(BUTTON_COLORS)),
        type: PropTypes.oneOf(Object.values(HTML_BUTTON_TYPES)),
        size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
        invariant: PropTypes.bool,

        onClick: PropTypes.func,
        withClick: PropTypes.bool,
        disabled: PropTypes.bool,
        loading: PropTypes.bool,
        tabIndex: PropTypes.number,

        leftIcon: PropTypes.oneOf(Object.values(ICONS_TYPES)),
        leftIconSize: PropTypes.number,
        leftIconRotate: PropTypes.oneOfType([
            PropTypes.oneOf(Object.values(ROTATE_TYPES)),
            PropTypes.number,
        ]),
        rightIcon: PropTypes.oneOf(Object.values(ICONS_TYPES)),
        rightIconSize: PropTypes.number,

        noUpperCase: PropTypes.bool,
        rightIconRotate: PropTypes.oneOfType([
            PropTypes.oneOf(Object.values(ROTATE_TYPES)),
            PropTypes.number,
        ]),
        fullWidth: PropTypes.bool,
        children: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ]),

        // e2e test
        dataTestId: PropTypes.string,
    };

    static defaultProps = {
        color: BUTTON_COLORS.blue,
        type: HTML_BUTTON_TYPES.button,
        size: BUTTON_SIZES.regular,
        buttonRef: this.buttonRef,
        invariant: false,
        withClick: false,

        leftIconSize: 20,
        rightIconSize: 20,
    };

    constructor(props) {
        super(props);
        this.buttonRef = React.createRef();
    }

    static BUTTON_COLORS = BUTTON_COLORS;

    static BUTTON_SIZES = BUTTON_SIZES;

    static HTML_BUTTON_TYPES = HTML_BUTTON_TYPES;

    get loading() {
        const {
            children,
            loading,
            leftIcon,
            rightIcon,
        } = this.props;

        if (loading) {
            if (leftIcon || rightIcon) {
                return 'Loading';
            }

            return (
                <FlowIcon
                    size={20}
                    type={ICONS_TYPES.threeDotsLoading}
                />
            );
        }

        return children;
    }

    get content() {
        const {
            leftIconSize,
            rightIconSize,
            leftIconRotate,
            rightIconRotate,
            classes,
            loading,
            leftIcon,

            rightIcon,
        } = this.props;
        return (
            <>
                {leftIcon && (
                    <FlowIcon
                        className={classes.leftIcon}
                        size={leftIconSize}
                        rotate={leftIconRotate}
                        type={
                            ICONS_TYPES[loading ? 'threeDotsLoading' : leftIcon]
                        }
                    />
                )}
                {this.loading}
                {rightIcon && (
                    <FlowIcon
                        className={classes.rightIcon}
                        size={rightIconSize}
                        rotate={rightIconRotate}
                        type={
                            ICONS_TYPES[
                                loading && !leftIcon
                                    ? 'threeDotsLoading'
                                    : rightIcon
                            ]
                        }
                    />
                )}
            </>
        );
    }

    render() {
        const {
            classes,
            className,
            toClassName,
            id,

            color,
            type,
            size,
            noUpperCase,
            invariant,

            to,
            linkProps,
            onClick,
            withClick,
            disabled,
            loading,
            tabIndex,

            buttonRef,

            leftIcon,
            rightIcon,
            div,
            style,
            fullWidth,

            dataTestId,
        } = this.props;

        const props = {
            id,
            className: cn(classes.root, classes[size], classes[type], {
                [classes.loading]: loading,
                [classes.disabled]: disabled,
                [classes[color]]: !disabled && !invariant,
                [classes[`${color}_invariant`]]: !disabled && invariant,
                [classes.withClick]: typeof onClick === 'function' || to || withClick,
                [classes[`${size}-withUpperCase`]]: !noUpperCase,
                [classes[`${size}-withLeftIcon`]]: leftIcon && !rightIcon,
                [classes[`${size}-withRightIcon`]]: rightIcon && !leftIcon,
                [classes[`${size}-withBothIcons`]]: rightIcon && leftIcon,
                [classes.inLink]: to,
                [toClassName]: to && toClassName,
                [className]: !to,
                [classes.fullWidth]: fullWidth,
            }),
            onClick,
            disabled: disabled || loading,
            ref: buttonRef || this.buttonRef,
            style,
            tabIndex,
            'data-tid': dataTestId,
        };

        const component = div
            ? <div {...props}>{this.content}</div>
            // eslint-disable-next-line react/button-has-type
            : <button {...{ ...props, type }}>{this.content}</button>;

        return to ? (
            <Link
                to={to}
                {...linkProps}
                className={cn(classes.link, className)}
            >
                {component}
            </Link>
        ) : (
            component
        );
    }
}

export default compose(withStyles(styles))(Button);
