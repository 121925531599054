/* eslint-disable max-len */
import React from 'react';


const SimpleCalendar = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.6668 4C11.6668 3.44772 11.2191 3 10.6668 3C10.1145 3 9.66683 3.44772 9.66683 4V5.66667H9.3335C6.57207 5.66667 4.3335 7.90524 4.3335 10.6667V12V22.6667C4.3335 25.4281 6.57207 27.6667 9.3335 27.6667H22.6668C25.4283 27.6667 27.6668 25.4281 27.6668 22.6667V12V10.6667C27.6668 7.90524 25.4283 5.66667 22.6668 5.66667H22.3335V4C22.3335 3.44772 21.8858 3 21.3335 3C20.7812 3 20.3335 3.44772 20.3335 4V5.66667H11.6668V4ZM25.6668 11V10.6667C25.6668 9.00981 24.3236 7.66667 22.6668 7.66667H21.3335H10.6668H9.3335C7.67664 7.66667 6.3335 9.00981 6.3335 10.6667V11H25.6668ZM6.3335 13H25.6668V22.6667C25.6668 24.3235 24.3236 25.6667 22.6668 25.6667H9.3335C7.67664 25.6667 6.3335 24.3235 6.3335 22.6667V13ZM9.66683 17.3333C9.66683 16.7811 10.1145 16.3333 10.6668 16.3333H13.3335C13.8858 16.3333 14.3335 16.7811 14.3335 17.3333C14.3335 17.8856 13.8858 18.3333 13.3335 18.3333H10.6668C10.1145 18.3333 9.66683 17.8856 9.66683 17.3333ZM18.6668 16.3333C18.1146 16.3333 17.6668 16.7811 17.6668 17.3333C17.6668 17.8856 18.1146 18.3333 18.6668 18.3333H21.3335C21.8858 18.3333 22.3335 17.8856 22.3335 17.3333C22.3335 16.7811 21.8858 16.3333 21.3335 16.3333H18.6668ZM9.66683 21.3333C9.66683 20.7811 10.1145 20.3333 10.6668 20.3333H13.3335C13.8858 20.3333 14.3335 20.7811 14.3335 21.3333C14.3335 21.8856 13.8858 22.3333 13.3335 22.3333H10.6668C10.1145 22.3333 9.66683 21.8856 9.66683 21.3333ZM18.6668 20.3333C18.1146 20.3333 17.6668 20.7811 17.6668 21.3333C17.6668 21.8856 18.1146 22.3333 18.6668 22.3333H21.3335C21.8858 22.3333 22.3335 21.8856 22.3335 21.3333C22.3335 20.7811 21.8858 20.3333 21.3335 20.3333H18.6668Z" fill="#2F8FE3" />
    </svg>
);

export default SimpleCalendar;
