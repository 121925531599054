import { MONKEY_POX_KEY } from './constants';


export const RESET_STATE = `RESET_STATE__${MONKEY_POX_KEY}`;

export const SET_LOADING = `SET_LOADING__${MONKEY_POX_KEY}`;
export const SET_PATIENT = `SET_PATIENT__${MONKEY_POX_KEY}`;
export const SET_BATCH = `SET_BATCH__${MONKEY_POX_KEY}`;

export const WS_SUBSCRIBE = `WS_SUBSCRIBE__${MONKEY_POX_KEY}`;
export const WS_UNSUBSCRIBE = `WS_UNSUBSCRIBE__${MONKEY_POX_KEY}`;
export const WS_SUCCESS = `WS_SUCCESS__${MONKEY_POX_KEY}`;
export const WS_FAILURE = `WS_FAILURE__${MONKEY_POX_KEY}`;

export const COPY_TO_PRACTICE_REQUEST = `COPY_TO_PRACTICE_REQUEST__${MONKEY_POX_KEY}`;
export const COPY_TO_PRACTICE_SUCCESS = `COPY_TO_PRACTICE_SUCCESS__${MONKEY_POX_KEY}`;
export const COPY_TO_PRACTICE_FAILURE = `COPY_TO_PRACTICE_FAILURE__${MONKEY_POX_KEY}`;
export const COPY_TO_PRACTICE_FINISHED = `COPY_TO_PRACTICE_FINISHED__${MONKEY_POX_KEY}`;

export const SET_SPECIMEN_KIT_ID = `SET_SPECIMEN_KIT_ID__${MONKEY_POX_KEY}`;

export const SEND_REQUISITION_REQUEST = `SEND_REQUISITION_REQUEST__${MONKEY_POX_KEY}`;
export const SEND_REQUISITION_SUCCESS = `SEND_REQUISITION_SUCCESS__${MONKEY_POX_KEY}`;
export const SEND_REQUISITION_FAILURE = `SEND_REQUISITION_FAILURE__${MONKEY_POX_KEY}`;

export const ADD_REQUISITION_PROCESSING = `ADD_REQUISITION_PROCESSING__${MONKEY_POX_KEY}`;
export const REMOVE_REQUISITION_PROCESSING = (
    `REMOVE_REQUISITION_PROCESSING__${MONKEY_POX_KEY}`
);
export const UPDATE_REQUISITION_PROCESSING_STATUS = (
    `UPDATE_REQUISITION_PROCESSING_STATUS__${MONKEY_POX_KEY}`
);
export const UPDATE_REQUISITION_PROCESSING_LAB = (
    `UPDATE_REQUISITION_PROCESSING_LAB__${MONKEY_POX_KEY}`
);
export const UPDATE_REQUISITION_PROCESSING_CONTAINER_TYPE = (
    `UPDATE_REQUISITION_PROCESSING_CONTAINER_TYPE__${MONKEY_POX_KEY}`
);
export const UPDATE_REQUISITION_PROCESSING_CODE = (
    `UPDATE_REQUISITION_PROCESSING_CODE__${MONKEY_POX_KEY}`
);
