import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import {
    useMobileBreakpoint,
    useTabletBreakpoint,
    useDesktopBreakpoint,
    useBigDesktopBreakpoint,
} from 'utils/breakpoints';

import Logo from 'imgs/patientPortal/navigation/Logo.png';
import LogoIcon from 'imgs/patientPortal/navigation/LogoIcon.png';

import { NAVIGATION_ITEMS } from './constants';
import { useStyles } from './styles';
import { NavigationItem } from './components/NavigationItem';


const NavigationLayout = ({ children }) => {
    const isMobile = useMobileBreakpoint();
    const isTablet = useTabletBreakpoint();
    const isDesktop = useDesktopBreakpoint();
    const isBigDesktop = useBigDesktopBreakpoint();

    const classes = useStyles({
        isMobile,
        isTablet,
        isDesktop,
        isBigDesktop,
    });

    return (
        <div className={classes.container}>
            <aside className={classes.sidebar}>
                <nav className={classes.navigation}>
                    {!isMobile && (
                        <Link to="/" className={classes.logoLink}>
                            <img src={isTablet ? LogoIcon : Logo} alt="Flow Health" />
                        </Link>
                    )}
                    <ul className={classes.navList}>
                        {NAVIGATION_ITEMS.map(item => (
                            <NavigationItem key={`${item.path}_${item.title}`} {...item} />
                        ))}
                    </ul>
                </nav>
            </aside>
            <div className={classes.content}>            
                {children}
            </div>
        </div>
    )
};

export default memo(NavigationLayout);
