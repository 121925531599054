/* eslint-disable max-len */
import React from 'react';


const Settings = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3657 2.05489C12.1851 1.9817 11.983 1.9817 11.8024 2.05489L4.46837 5.02632C4.18526 5.14103 4 5.41597 4 5.72144V13.15C4 17.3144 7.14167 20.4933 11.8508 22.0343C12.0023 22.0839 12.1658 22.0839 12.3173 22.0343C17.0481 20.4862 20.1681 16.9258 20.1681 13.15V5.72144C20.1681 5.41597 19.9828 5.14103 19.6997 5.02632L12.3657 2.05489ZM5.5 13.15V6.22679L12.084 3.55922L18.6681 6.22679V13.15C18.6681 16.0077 16.2881 19.0731 12.0835 20.5303C7.85905 19.0788 5.5 16.3556 5.5 13.15ZM16.1613 9.53477C16.4212 9.21226 16.3705 8.74011 16.048 8.48018C15.7255 8.22026 15.2533 8.271 14.9934 8.59351L11.3429 13.123L9.83742 11.5218C9.55368 11.2201 9.07903 11.2054 8.77726 11.4892C8.47549 11.7729 8.46088 12.2476 8.74462 12.5493L10.8401 14.7779C10.9886 14.9359 11.1983 15.0219 11.4151 15.0136C11.6318 15.0053 11.8343 14.9037 11.9704 14.7348L16.1613 9.53477Z" fill="#4F5862" />
    </svg>
);

export default Settings;
