import { createSlice } from "@reduxjs/toolkit";

import { configuration } from './constants';


const initialState = Object.entries(configuration).reduce((acc, [key, value]) => {
    acc[key] = {
        data: value.initialState ?? null,
        status: 'notFetched',
        error: null,
    };

    return acc;
}, {});

const slice = createSlice({
    name: 'sharedStorageKey',
    initialState,
    reducers: {
        fetchDataRequest: (state, { payload: { key } }) => {
            state[key].status = 'loading';
        },
        fetchDataSuccess: (state, { payload: { key, data } }) => {
            state[key].status = 'success';
            state[key].data = data;
        },
        fetchDataFailure: (state, { payload: { key, error } }) => {
            state[key].status = 'failure';
            state[key].error = error;
        }
    },
});

export const { actions, reducer, name: reducerName } = slice;
