import React from 'react';
import PropTypes from 'prop-types';
import { escapeRegExp, isFunction } from 'lodash';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { isFullArray } from '@flowhealth/utils';

import FlowIcon from 'components/FlowIcon';
import { ICONS_TYPES } from 'components/FlowIcon/constants';

import styles from '../styles';


const List = ({
    classes,

    getIdBasedOnSelectId,

    onListContainerRef,
    onListRef,
    onScroll,
    onSelect,
    getItems,
    listContainerPosition,

    listAnchorBounds,
    listMaxWidthComputed,
    listMinWidth,
    listLabel,
    maxItems,

    preSelectedId,
    value,
    inputValue,
    itemDisabled,
    itemSubtext,
    getListItemText,

    showInputInDropDown,
    addGraphToEdge,

    inputComp,
    itemComponent,
    noItemsNode,

    loading,

    colorIndicator,

    createForm,
    openCreateDialog,
    viewForm,
    handleViewClick,

    dataTestId,
}) => (
    <div
        ref={onListContainerRef}
        className={classes.listContainer}
        style={{
            ...listContainerPosition,
            width: listAnchorBounds.width,
            maxWidth: listMaxWidthComputed,
            minWidth: listMinWidth,
        }}
        data-tid={dataTestId}
    >
        {(showInputInDropDown || addGraphToEdge) && (
            <div className={classes.listInputContainer}>
                <FlowIcon
                    className={classes.searchIcon}
                    type={ICONS_TYPES.search}
                    size={18}
                />
                {inputComp}
            </div>
        )}
        {createForm && (
            <FlowIcon
                id={getIdBasedOnSelectId('createFormButton')} // needed for tests
                className={classes.createFormIcon}
                labelClassName={classes.createFormIconLabel}
                type={ICONS_TYPES.plus}
                onClick={openCreateDialog}
                label="Create New"
                size={18}
            />
        )}
        {listLabel && <span className={classes.listLabel}>{listLabel}</span>}
        <div
            ref={onListRef}
            key={maxItems}
            className={classes.list}
            onScroll={onScroll}
        >
            {getItems().length > 0 && getItems().map((item, key) => {
                if (!item) return;
                const itemKey = object => object?.id || object?.code || object?.npi || object?.name;
                const selected = preSelectedId === itemKey(item)
                    || (isFullArray(value) && value.findIndex(v => itemKey(v) === itemKey(item)) !== -1);
                const disabled = item.disabled || (itemDisabled && itemDisabled(item));

                let text;
                let parts;
                let textHighlighted;
                if (!isFunction(itemComponent)) {
                    text = getListItemText(item);
                    parts = inputValue
                        ? text.split(new RegExp(`(${escapeRegExp(inputValue)})`, 'gi'))
                        : [text];
                    textHighlighted = (
                        <span>
                            {parts.map((part, i) => {
                                const match = part.toLowerCase() === inputValue.toLowerCase();

                                return (
                                    <span
                                        key={i}
                                        className={cn({ [classes.textColorPart]: match })}
                                    >
                                        {part}
                                    </span>
                                );
                            })}
                        </span>
                    );
                }

                return (
                    <>
                        <div
                            id={getIdBasedOnSelectId(`item-${key}`)} // needed for tests
                            key={key}
                            className={cn(
                                classes.listItemContainer,
                                selected && classes.listItemSelected,
                                disabled && classes.listItemDisabled,
                            )}
                            onClick={disabled
                                ? undefined
                                : e => {
                                    e.stopPropagation();
                                    onSelect(item, selected);
                                }
                            }
                            data-tid={`${dataTestId}-item-${key}`}
                        >
                            {isFunction(itemComponent)
                                ? itemComponent(item)
                                : (
                                    <div className={classes.listItemWrapper}>
                                        <div className={classes.listItem}>
                                            {item.color && colorIndicator && (
                                                <div
                                                    style={{ backgroundColor: `#${item.color}` }}
                                                    className={classes.colorIndicator}
                                                />
                                            )}
                                            {textHighlighted}
                                        </div>
                                        {itemSubtext && (
                                            <div className={classes.listSubItem}>
                                                {itemSubtext(item)}
                                            </div>
                                        )}
                                    </div>
                                )}
                            {viewForm && (
                                <FlowIcon
                                    className={classes.viewFormIcon}
                                    type={ICONS_TYPES.viewForm}
                                    onClick={e => handleViewClick(e, item)}
                                    size={18}
                                />
                            )}
                        </div>
                        {item.hasSeparator && (
                            <div className={classes.separator} />
                        )}
                    </>
                );
            })}
            {!loading && getItems().length === 0 && (
                <div className={cn(classes.listItem, classes.emptyItem)}>
                    {noItemsNode}
                </div>
            )}
            {loading && (
                <FlowIcon
                    className={cn(classes.dots, classes.withPadding)}
                    size={20}
                    type={ICONS_TYPES.threeDotsLoading}
                />
            )}
        </div>
    </div>
);

List.propTypes = {
    // e2e test
    dataTestId: PropTypes.string,
};

export default withStyles(styles)(List);
