import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form/immutable';

import websocketReducer from 'API/websocket/reducer';

import valueSetReducer from 'components/ValueSet/reducer';
import messageReducer from 'components/Message/reducer';
import flowDialogReducer from 'components/FlowDialog/reducer';
import loaderReducer from 'components/FlowLoader/reducer';
import flowSelectReducer from 'components/FlowSelect/reducer';
import graphFilesReducer from 'components/GraphFiles/reducer';
import { GRAPH_FILES_KEY } from 'components/GraphFiles/constants';
import { PATIENT_PROFILES_KEY, reducer as profilesActionsReducer } from 'components/ProfilesActions';
import { FLOW_DIALOG_KEY } from 'components/FlowDialog/constants';
import { SELECT_LOCATION_KEY, reducer as selectLocationsReducer } from 'components/SelectLocation';

import { PATIENT_PORTAL_AUTH_KEY } from 'containers/Auth/constants';
import patientPortalAuthReducer from 'containers/Auth/reducer';
import userReducer from 'containers/User/reducer';
import globalReducer from 'containers/App/reducer';
import { KEY_PATIENT_PORTAL } from 'containers/PatientPortal/store/constants';
import patientPortalReducer from 'containers/PatientPortal/store/reducer';
import { PP_INSURANCE_KEY } from 'containers/PatientPortal/Insurance/form/store/constants';
import patientPortalInsuranceReducer from 'containers/PatientPortal/Insurance/form/store/reducer';
import { BINAX_KIT_KEY, reducer as patientPortalBinaxReducer } from 'containers/PatientPortal/HTK/BinaxNowKit/store';
import { CARESTART_KIT_KEY, reducer as patientPortalCareStartReducer } from 'containers/PatientPortal/HTK/CareStartKit/store';
import { QUICKVUE_KIT_KEY, reducer as patientPortalQuickVueReducer } from 'containers/PatientPortal/HTK/QuickVueKit/store';
import { LUCIRA_KIT_KEY, reducer as patientPortalLuciraReducer } from 'containers/PatientPortal/HTK/LuciraKit/store';
import { COVID19_PCR_KEY, reducer as patientPortalCovid19PCRReducer } from 'containers/PatientPortal/HTK/Covid19PCR/store';
import { PCR_ORDER_KIT, reducer as patientPortalPCROrderReducer } from 'containers/PatientPortal/HTK/PCROrderKit/store';
import { MONKEY_POX_KEY, reducer as patientPortalMonkeyPoxReducer } from 'containers/PatientPortal/HTK/MonkeyPox/store';
import { MAILING_KIT_KEY, reducer as patientPortalMailingKitReducer } from 'containers/PatientPortal/HTK/MailingKit/store';
import { FLOW_FLEX_KIT_KEY, reducer as patientPortalFlowFlexReducer } from 'containers/PatientPortal/HTK/FlowFlexKit/store';
import { BOOKING_KEY, reducer as patientPortalBookingReducer } from 'containers/PatientPortal/Booking';
import  patientPortalStiOrderReducer  from 'containers/PatientPortal/orderInfo/StiOrderInfo/store/slice'
import { KEY_PP_ORDERS } from 'containers/PatientPortal/Orders/constants'
import orderReducer from 'containers/PatientPortal/Orders/store/reducer';

import {
    reducerName as SHARED_STORAGE_KEY,
    reducer as sharedStorageReducer,
} from 'sharedStorage/slice';

import  { KEY_PP_STI_ORDER }  from './containers/PatientPortal/orderInfo/StiOrderInfo/store/slice'
import { routerReducer } from './history';


export function createReducer(injectedReducers) {
    return combineReducers({
        router: routerReducer,
        global: globalReducer,
        user: userReducer,

        websocket: websocketReducer,

        // Redux-form
        form: formReducer,
        [GRAPH_FILES_KEY]: graphFilesReducer,

        flowSelect: flowSelectReducer,

        // common components
        valuesets: valueSetReducer,
        message: messageReducer,
        [FLOW_DIALOG_KEY]: flowDialogReducer,
        loader: loaderReducer,

        [PATIENT_PORTAL_AUTH_KEY]: patientPortalAuthReducer,
        [KEY_PATIENT_PORTAL]: patientPortalReducer,
        [PP_INSURANCE_KEY]: patientPortalInsuranceReducer,
        [BINAX_KIT_KEY]: patientPortalBinaxReducer,
        [CARESTART_KIT_KEY]: patientPortalCareStartReducer,
        [QUICKVUE_KIT_KEY]: patientPortalQuickVueReducer,
        [COVID19_PCR_KEY]: patientPortalCovid19PCRReducer,
        [PCR_ORDER_KIT]: patientPortalPCROrderReducer,
        [MONKEY_POX_KEY]: patientPortalMonkeyPoxReducer,
        [MAILING_KIT_KEY]: patientPortalMailingKitReducer,
        [FLOW_FLEX_KIT_KEY]: patientPortalFlowFlexReducer,
        [LUCIRA_KIT_KEY]: patientPortalLuciraReducer,
        [PATIENT_PROFILES_KEY]: profilesActionsReducer,

        [BOOKING_KEY]: patientPortalBookingReducer,
        [SELECT_LOCATION_KEY]: selectLocationsReducer,
        [SHARED_STORAGE_KEY]: sharedStorageReducer,
        [KEY_PP_ORDERS]: orderReducer,
        [KEY_PP_STI_ORDER]: patientPortalStiOrderReducer,
        ...injectedReducers,
    });
}
