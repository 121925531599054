import { fromJS } from 'immutable';

import * as ACTION_TYPES from './constants';
import * as CAMERA_ACTION_TYPES from './Camera/actionTypes';


const initialState1 = fromJS({
    loading: false,
    success: false,
    error: false,
    logoUrl: null,
    mobile: true,
    isMainMenuOpen: window.innerWidth >= ACTION_TYPES.LARGE_WINDOW_RESOLUTION,
    menuClickedOn: null,
    camera: {
        isOpen: false,
        file: undefined,
    },
});


function appReducer(state = initialState1, action) {
    const { type, payload } = action;

    switch (type) {
    case ACTION_TYPES.TOGGLE_MENU:
        return state.merge({
            isMainMenuOpen: payload,
        });

    case ACTION_TYPES.SET_MENU_CLICKED_ON:
        return state.merge({ menuClickedOn: payload });

    case ACTION_TYPES.OPEN_LOADER:
        return state.merge({ loading: payload || true });

    case ACTION_TYPES.CLOSE_LOADER:
        return state.merge({ loading: false });

    case ACTION_TYPES.OPEN_SUCCESS:
        return state.merge({ success: true });

    case ACTION_TYPES.CLOSE_SUCCESS:
        return state.merge({ success: false });

    case ACTION_TYPES.GET_LOGO_REQUEST:
        return state.merge({ logoIsLoading: true });

    case ACTION_TYPES.GET_LOGO_SUCCESS:
        return state.merge({ logoUrl: payload, logoIsLoading: false });

    case ACTION_TYPES.GET_LOGO_FAILURE:
        return state.merge({ error: payload, logoIsLoading: false });

    case ACTION_TYPES.SWITCH_MOBILE:
        return state.merge({ mobile: payload });

    case CAMERA_ACTION_TYPES.OPEN_CAMERA:
        return state.merge({ camera: { ...state.camera, isOpen: true } });

    case CAMERA_ACTION_TYPES.CLOSE_CAMERA:
        return state.merge({ camera: { ...state.camera, isOpen: false } });

    default:
        return state;
    }
}

export default appReducer;
