import React from 'react';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { bluredBG } from './styles';

const styles = ({ palette = {} } = {}) => ({
    root: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        background: palette.gray[85],
        overflow: 'hidden',
        zIndex: '1000',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    rowWrap: {
        display: 'flex',
        flexDirection: 'row',
        margin: '8px 0',
        width: '100%',
    },
    field: {
        width: '50%',
        height: '12px',
        borderRadius: '4px',
        display: 'flex',
        margin: 5,
    },
    filedBig: {
        width: '75%',
    },
    fieldSmall: {
        width: '25%',
    },
    ...bluredBG,
});


class SignatureLoader extends React.PureComponent {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.row}>
                    <div className={cn(classes.animate, classes.field, classes.filedBig)} />
                </div>
                <div className={classes.row}>
                    <div className={cn(classes.animate, classes.field)} />
                </div>
                <div className={classes.rowWrap} />
                <div className={classes.row}>
                    <div className={cn(classes.animate, classes.field, classes.fieldSmall)} />
                    <div className={cn(classes.animate, classes.field, classes.fieldSmall)} />
                </div>
            </div>
        );
    }
}


export default withStyles(styles)(SignatureLoader);
