import dayjs from 'dayjs';
import { isString, isFunction } from 'lodash';
import parsePhoneNumber from 'libphonenumber-js';

import { DEFAULT_COUNTRY, US_COUNTRY_CODE } from 'containers/Auth/constants';

export { validateEmail } from './Text/validate';
export { validateURL } from './Text/validate';
export const DEFAULT_PHONE_ERROR_MESSAGE = 'Phone number is invalid';

export const validatePhone = phone => {
    if (!phone) return;

    const phoneNumber = phone.replace(/\D+/g, '');
    const parsedNumber = parsePhoneNumber(phoneNumber.length === 10 ? `+1${phoneNumber}` : `+${phoneNumber}`);
    if (parsedNumber?.isValid && isFunction(parsedNumber.isValid) && parsedNumber.isValid()) return;

    return DEFAULT_PHONE_ERROR_MESSAGE;
};

export const validatePostalCode = (postalCode, countryCode = DEFAULT_COUNTRY) => {
    const regex = /^\d{5}$/;
    const isUS = countryCode === US_COUNTRY_CODE;
    if (!isUS) {
        return '';
    }

    if (!regex.test(postalCode)) return 'Zip Code should be 5 digits';
};

export const validateDomain = domain => {
    // eslint-disable-next-line
    const regex = /^[a-zA-Z0-9-\.]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(domain)) return 'Incorrect domain name';
    return '';
};

export const validatePassword = complexity => password => {
    if (!password) return;
    if (complexity && complexity < 66) return 'Password too weak';
    if (password.length < 8) return 'Password too short';
    if (password.length > 20) return 'Password too long';
};

export const validateDate = dateString => {
    if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString)) return false;
    const parts = dateString.split('-');
    const day = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[0], 10);
    if (year < 1850 || year > dayjs().year() || month === 0 || month > 12) return false;
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;
    return day > 0 && day <= monthLength[month - 1];
};

export const validateCode = code => code.length > 0;

export const validateAccession = accession => {
    if (!isString(accession) || accession.length < 5) return false;
    return /(<=\s|^)\w+(?=\s|$)/g.test(accession);
};

export const validateTaxId = tax_id => {
    if (tax_id.replace('-', '').length !== 9) return 'Tax ID should be 9 symbols';
};
