import { theme } from '@flowhealth/ui-components';


export default ({ palette } = {}) => ({
    root: {
        width: '100%',
        display: 'flex',
        padding: '16px 20px',
        justifyContent: 'center',
        alignItems: 'center',

        '@media (min-width:441px)': {
            position: 'relative',
            width: '100%',
            height: 60,
            marginTop: 16,
            justifyContent: 'center',
            alignItems: 'flex-end',
            zIndex: 1,
        },
    },
    fixed: { position: 'fixed' },
    titleContainer: {
        display: 'flex',
    },
    backIcon: {
        display: 'flex',
        marginRight: 24,
        flex: 0,
        position: 'absolute',
        left: 20,

        '& svg': {
            fill: palette.coal.tags,
        },

    },
    title: {
        ...theme.typography['16M'],
        letterSpacing: 0.5,
        color: palette.coal.main,
        position: 'relative',
    },
    actionBtn: {
        marginRight: 26,
        cursor: 'pointer',
        position: 'absolute',

        '@media (max-width:800px)': {
            marginRight: 10,
        },

        '@media (max-width:600px)': {
            marginRight: 2,
        },

        '@media (max-width:440px)': {
            marginRight: 6,
            right: 14,
        },
    },
    leftActionBtn: {
        left: 24,
    },
    rightActionBtn: {
        right: 24,
    },
    link: {
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '28px',
        color: palette.naviBlue.dark,
        textDecoration: 'unset',
    },
    close: {
        width: 20,
        height: 20,
        background: palette.transparent,
        '& svg': {
            fill: palette.coal.tags,
        },
    },
    hidden: { visibility: 'hidden' },
    divider: {
        boxShadow: `inset 0px -1px 0px ${palette.ash[400]}`,
    },
});
