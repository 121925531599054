/* eslint-disable max-len */
import React from 'react';


const SimpleClock = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15.9989" cy="16.0001" r="11.7879" stroke="#2F8FE3" strokeWidth="2" />
        <path d="M16 10.761V17.2328L18.6195 19.9294" stroke="#2F8FE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default SimpleClock;
