/* eslint-disable max-len */
import React from 'react';


const Clear = () => (
    <svg viewBox="0 0 20 20">
        <path fillRule="evenodd" d="M12.828 12.828a1 1 0 0 1-1.414 0L10 11.414l-1.414 1.414a1 1 0 0 1-1.414-1.414L8.586 10 7.172 8.586a1 1 0 0 1 1.414-1.414L10 8.586l1.414-1.414a1 1 0 0 1 1.414 1.414L11.414 10l1.414 1.414a1 1 0 0 1 0 1.414zM10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16z" clipRule="evenodd" />
    </svg>
);

export default Clear;
