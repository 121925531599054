import { useMobileBreakpoint } from '@flowhealth/utils';
import React, { useEffect, useState } from 'react';

import { useMap, useMarkers, useLocationPopup, useMyLocationMarker } from './hooks';
import { findMarkerByLocationId } from './utils';
import { Location } from './Location';
import { useStyles } from './styles';

const LocationsMap = ({ locations, myLocation, onSelect }) => {
    const { ref, map } = useMap(myLocation);
    useMyLocationMarker(myLocation, map);

    const isMobile = useMobileBreakpoint();
    const classes = useStyles({ isMobile });
    const [selectedLocation, setSelectedLocation] = useState();

    const { markers } = useMarkers({
        locations,
        map,
        selectedLocation,
        setSelectedLocation,
    });
    const { onPopupOpen, onPopupClose } = useLocationPopup({
        map,
        onContinueClick: () => onSelect(selectedLocation),
    });

    useEffect(() => {
        if (selectedLocation) {
            const marker = findMarkerByLocationId({ markers, location: selectedLocation })?.marker;

            if (marker && !isMobile) {
                onPopupOpen(selectedLocation, marker);
            }
        }
    }, [map, markers, selectedLocation]);

    useEffect(() => {
        const handleClickOutside = () => {
            onPopupClose();
            setSelectedLocation(undefined);
        }
        const listener = map?.addListener('click', handleClickOutside);

        return () => {
            listener?.remove();
        };
    }, [map]);

    useEffect(() => {
        if (isMobile) {
            onPopupClose();
        }
    }, [isMobile]);

    return (
        <div className={classes.mapContainer}>
            {isMobile && selectedLocation && (
                <div className={classes.popup}>
                    <Location location={selectedLocation} onSelect={onSelect}/>
                </div>
            )}
            <div ref={ref} className={classes.map}/>
        </div>
    );
};

export default LocationsMap;
